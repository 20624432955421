<template>
  <div>
    <table class="table table-nowrap mb-0">
      <tbody>
        <tr>
          <th scope="row">Form :</th>
          <td>
            {{ form.name }}
          </td>
        </tr>
        <tr>
          <th scope="row">{{ $t('tables.created_at') }} :</th>
          <td>{{ moment(form.created_at).format('Do MMMM YYYY HH:ss') }}</td>
        </tr>
        <tr>
          <th scope="row">{{ $t('form.number_of_questions') }} :</th>
          <td>
            {{ total_questions.aggregate.count }}
          </td>
        </tr>
        <tr>
          <th scope="row">{{ $t('form.number_of_answers') }} :</th>
          <td>{{ total_answers.aggregate.count }}</td>
        </tr>
      </tbody>
    </table>
    <div class="button-items mt-4">
      <button
        v-if="form.editable === true"
        type="button"
        class="btn btn-secondary waves-effect waves-light"
        @click="updateModal = true"
      >
        <i class="bx bx-pencil font-size-16 align-middle mr-2"></i>
        {{ $t('buttons.edit') }}
      </button>
      <button
        v-if="form.deletable === true"
        type="button"
        class="btn btn-danger waves-effect waves-light"
        @click="remove"
      >
        <i class="bx bx-trash font-size-16 align-middle mr-2"></i>
        {{ $t('buttons.delete') }}
      </button>
      <b-modal v-model="updateModal" :title="$t('form.update_form')" hide-footer>
        <form @submit.prevent="update()">
          <div class="row">
            <div class="col-12">
              <div class="mb-3">
                <label for="form_name">{{ $t('form.form_name') }}</label>
                <input id="form_name" v-model="form.name" type="text" class="form-control" />
              </div>
            </div>
          </div>
          <div class="text-end mt-3">
            <b-button variant="light" @click="updateModal = false">{{
              $t('buttons.close')
            }}</b-button>
            <b-button type="submit" variant="success" class="ms-1">{{
              $t('buttons.save')
            }}</b-button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  GET_FORM,
  UPDATE_FORM,
  DELETE_FORM,
  GET_TOTAL_ANSWERS_COUNT,
  GET_TOTAL_QUESTIONS_COUNT,
} from '@/router/queries'
import moment from 'moment'

export default {
  name: 'Question',
  data() {
    return {
      moment,
      updateModal: false,
    }
  },
  created() {
    this.$_loading()
  },
  apollo: {
    form: {
      fetchPolicy: 'no-cache',
      query: GET_FORM,
      variables() {
        return {
          id: this.$route.params.key,
        }
      },
    },
    total_questions: {
      fetchPolicy: 'no-cache',
      query: GET_TOTAL_QUESTIONS_COUNT,
      variables() {
        return {
          where: {
            form_id: {
              _eq: this.$route.params.key,
            },
          },
        }
      },
    },
    total_answers: {
      fetchPolicy: 'no-cache',
      query: GET_TOTAL_ANSWERS_COUNT,
      variables() {
        return {
          where: {
            form_id: {
              _eq: this.$route.params.key,
            },
          },
        }
      },
    },
  },
  methods: {
    update() {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_FORM,
        variables: {
          id: this.$route.params.key,
          form: this.$_ER(this.form, ['__typename']),
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
      this.updateModal = false
    },
    remove() {
      const self = this
      this.$_C({
        confirmed(result) {
          if (result) {
            self.$loading(true)
            self.$apollo.mutate({
              mutation: DELETE_FORM,
              variables: {
                id: self.$route.params.key,
              },
              update: () => {
                try {
                  self.$_N({
                    type: 'success',
                    position: 'center',
                  })
                  self.$router.push({ name: 'form.list' })
                  self.$loading(false)
                } catch (e) {
                  self.$_N({
                    type: 'error',
                    position: 'center',
                  })
                }
              },
            })
          }
        },
      })
    },
  },
}
</script>
