<template>
  <div v-if="locations">
    <label v-if="!hideLabel" for="name">{{ label ?? $t('title.locations') }}</label>
    <multiselect
      v-model="inputValue"
      :placeholder="$t('fields.choose')"
      :show-labels="false"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="true"
      track-by="id"
      label="name"
      :options="locations"
      :class="{
        'is-invalid': isInvalid,
      }"
      @input="selectHandler"
    >
      <span slot="noResult">
        {{ $t('lists.no_record') }}
      </span>
      <span slot="noOptions">
        {{ $t('lists.no_record') }}
      </span>
    </multiselect>
    <div v-if="isInvalid" class="invalid-feedback">
      {{ $t('fields.required') }}
    </div>
  </div>
</template>

<script>
import { GET_LOCATIONS, GET_PERMISSION } from '@/router/queries'
import { mapState } from 'vuex'

export default {
  props: {
    inputData: {
      default: '',
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      default: null,
    },
  },
  data: () => ({
    inputValue: [],
    staff_locations: undefined,
  }),
  computed: {
    ...mapState(['auth']),
  },
  watch: {
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  created() {
    if (this.inputData.length > 0) {
      this.inputData.forEach((element) => {
        this.inputValue.push(element.location)
      })
    }
  },
  methods: {
    selectHandler(val) {
      this.$emit('setLocations', val)
    },
  },
  apollo: {
    locations: {
      fetchPolicy: 'no-cache',
      query: GET_LOCATIONS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            is_disable: { _eq: false },
            id: {
              _in: this.staff_locations,
            },
          },
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
