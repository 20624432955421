<template>
  <div>
    <div v-click-outside="config" class="right-bar">
      <simplebar class="h-100">
        <div class="rightbar-title px-3 py-4">
          <h5 class="m-0">Tema Ayarları</h5>
        </div>

        <div class="p-3">
          <h6 class="mb-0">Menü Yerleşimi</h6>
          <hr class="mt-1" />

          <div class="form-check form-check-inline">
            <input
              id="layout-radio1"
              v-model="layout"
              class="form-check-input"
              type="radio"
              name="layout"
              value="vertical"
            />
            <label class="form-check-label" for="layout-radio1">Dikey</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              id="layout-radio2"
              v-model="layout"
              disabled
              class="form-check-input"
              type="radio"
              name="layout"
              value="horizontal"
            />
            <label class="form-check-label" for="layout-radio2">Yatay(Yakında)</label>
          </div>

          <!-- Width -->
          <h6 class="mt-3">Genişlik</h6>
          <hr class="mt-1" />

          <div class="form-check">
            <input
              id="width-radio1"
              v-model="width"
              class="form-check-input"
              type="radio"
              name="widthradio"
              value="fluid"
            />
            <label class="form-check-label" for="width-radio1"> Uyumlu </label>
          </div>
          <div class="form-check">
            <input
              id="width-radio2"
              v-model="width"
              class="form-check-input"
              type="radio"
              name="widthradio"
              value="boxed"
            />
            <label class="form-check-label" for="width-radio2"> Mini </label>
          </div>
          <!--<div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="widthradio"
              id="width-radio3"
              value="scrollable"
              v-model="width"
            />
            <label class="form-check-label" for="width-radio3">
              Scrollable
            </label>
          </div>-->

          <!-- Sidebar -->
          <div v-if="layout === 'vertical'">
            <h6 class="mt-3">Menü</h6>
            <hr class="mt-1" />

            <div class="form-check">
              <input
                id="sidebar-dark"
                v-model="sidebarType"
                class="form-check-input"
                type="radio"
                name="sidebar-radio"
                value="dark"
              />
              <label class="form-check-label" for="sidebar-dark"> Karanlık </label>
            </div>
            <div class="form-check">
              <input
                id="sidebar-light"
                v-model="sidebarType"
                class="form-check-input"
                type="radio"
                name="sidebar-radio"
                value="light"
              />
              <label class="form-check-label" for="sidebar-light"> Aydınlık </label>
            </div>
            <div class="form-check">
              <input
                id="sidebar-compact"
                v-model="sidebarType"
                class="form-check-input"
                type="radio"
                name="sidebar-radio"
                value="compact"
              />
              <label class="form-check-label" for="sidebar-compact"> Sıkı </label>
            </div>
            <div class="form-check">
              <input
                id="sidebar-icon"
                v-model="sidebarType"
                class="form-check-input"
                type="radio"
                name="sidebar-radio"
                value="icon"
              />
              <label class="form-check-label" for="sidebar-icon"> İkon </label>
            </div>
            <div class="form-check">
              <input
                id="sidebar-colored"
                v-model="sidebarType"
                class="form-check-input"
                type="radio"
                name="sidebar-radio"
                value="colored"
              />
              <label class="form-check-label" for="sidebar-colored"> Renkli </label>
            </div>
          </div>

          <!-- Topbar -->
          <div v-if="layout === 'horizontal'">
            <h6 class="mt-3">Topbar</h6>
            <hr class="mt-1" />
            <div class="form-check">
              <input
                id="topbar-dark"
                v-model="topbar"
                class="form-check-input"
                type="radio"
                name="sidebar-radio"
                value="dark"
              />
              <label class="form-check-label" for="topbar-dark"> Dark </label>
            </div>
            <div class="form-check">
              <input
                id="topbar-light"
                v-model="topbar"
                class="form-check-input"
                type="radio"
                name="topbar-radio"
                value="light"
              />
              <label class="form-check-label" for="topbar-light"> Light </label>
            </div>
            <div class="form-check">
              <input
                id="topbar-colored"
                v-model="topbar"
                class="form-check-input"
                type="radio"
                name="topbar-radio"
                value="colored"
              />
              <label class="form-check-label" for="topbar-colored"> Colored </label>
            </div>
          </div>
        </div>
      </simplebar>
    </div>

    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>
  </div>
</template>

<script>
import { layoutMethods, layoutComputed } from '@/state/helpers'
import simplebar from 'simplebar-vue'

/**
 * Right sidebar component
 */
export default {
  components: {
    simplebar,
  },
  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ['click'],
      },
    }
  },
  computed: {
    ...layoutComputed,
    layout: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {}
      },
      set(layout) {
        this.changeLayoutType({
          layoutType: layout,
        })
      },
    },
    width: {
      get() {
        return this.$store ? this.$store.state.layout.layoutWidth : {} || {}
      },
      set(width) {
        this.changeLayoutWidth({
          layoutWidth: width,
        })

        if (width == 'boxed') {
          this.changeLeftSidebarType({
            leftSidebarType: 'icon',
          })
        } else if (width == 'fluid' || width == 'scrollable') {
          this.changeLeftSidebarType({
            leftSidebarType: 'dark',
          })
        }
      },
    },
    topbar: {
      get() {
        return this.$store ? this.$store.state.layout.topbar : {} || {}
      },
      set(topbar) {
        this.changeTopbar({
          topbar: topbar,
        })
      },
    },
    sidebarType: {
      get() {
        return this.$store ? this.$store.state.layout.leftSidebarType : {} || {}
      },
      set(type) {
        return this.changeLeftSidebarType({
          leftSidebarType: type,
        })
      },
    },
    loader: {
      get() {
        return this.$store ? this.$store.state.layout.loader : {} || {}
      },
      set(value) {
        return this.changeLoaderValue({
          loader: value,
        })
      },
    },
  },
  methods: {
    ...layoutMethods,
    hide() {
      this.$parent.toggleRightSidebar()
    },
    // eslint-disable-next-line no-unused-vars
    handleRightBarClick(e, el) {
      this.$parent.hideRightSidebar()
    },
    // eslint-disable-next-line no-unused-vars
    middleware(event, el) {
      if (event.target.classList) return !event.target.classList.contains('toggle-right')
    },
  },
}
</script>
