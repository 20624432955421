<template>
  <div
    v-if="
      event &&
      event_statuses &&
      users &&
      services_total_price &&
      products_total_price &&
      vault_total &&
      vault_activities
    "
  >
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-xl-4">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <a @click="$router.push({ name: 'contact.show', params: { id: event.contact.id } })">
              <div id="title" class="row" style="cursor: pointer">
                <b-popover target="title" triggers="hover" placement="top">
                  <template #title>{{ $t('appointment.go_to_customer_card') }}</template>
                </b-popover>
                <div class="col-6">
                  <div v-if="event" class="p-3">
                    <h5 v-if="event.contact">
                      <span v-if="event.contact.first_name">
                        {{ (event.contact.first_name || '') + ' ' }}
                      </span>
                      <span v-if="event.contact.last_name">
                        {{ event.contact.last_name || '' }}
                      </span>
                    </h5>
                  </div>
                </div>
                <div class="col-6 align-self-end">
                  <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
                </div>
              </div>
            </a>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-6">
                <div class="avatar-md profile-user-wid mb-4">
                  <img
                    src="@/assets/images/users/avatar-1.jpg"
                    alt
                    class="img-thumbnail rounded-circle"
                  />
                </div>
              </div>

              <div class="col-sm-12">
                <div class="pt-1">
                  <div class="row">
                    <div class="col-6">
                      <h5 class="font-size-15">{{ $t('appointment.date') }}</h5>
                      <p class="text-muted mb-0">
                        {{
                          moment(event.start, 'YYYY-MM-DDTHH:mm:ssz').format(
                            'Do MMMM YYYY - HH:mm:ss'
                          )
                        }}
                      </p>
                    </div>
                    <div class="col-6 mb-3">
                      <h5 class="font-size-15">{{ $t('appointment.notes') }}</h5>
                      <p class="text-muted mb-0">{{ event.notes }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <h5
                v-if="appointmentStatus === 'came'"
                class="bg-success font-18 mt-2 p-2 rounded text-center text-white"
              >
                {{ $t('appointment.completed').toUpperCase() }}
              </h5>
              <h5
                v-if="appointmentStatus === 'not_came'"
                class="bg-warning font-18 mt-2 p-2 rounded text-center text-white"
              >
                {{ $t('appointment.no_show').toUpperCase() }}
              </h5>
              <h5
                v-if="appointmentStatus === 'cancel'"
                class="bg-danger font-18 mt-2 p-2 rounded text-center text-white"
              >
                {{ $t('appointment.canceled').toUpperCase() }}
              </h5>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <div class="card">
          <div class="card-body">
            <form>
              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <label for="date">{{ $t('appointment.location') }}</label>
                    <input
                      id="date"
                      v-model="event.location.name"
                      disabled
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label for="name">{{ $t('appointment.customer') }}</label>
                    <input
                      disabled
                      class="form-control"
                      :value="
                        (event.contact.first_name || '') + ' ' + (event.contact.last_name || '')
                      "
                    />
                  </div>
                </div>
                <div class="col-12">
                  <label class="control-label">{{ $t('customerCard.notes') }}</label>
                  <textarea
                    v-model="event.notes"
                    :disabled="appointmentStatus !== 'unknown'"
                    class="form-control"
                    rows="2"
                  ></textarea>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="row">
          <div class="col-md-4">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <p class="text-muted fw-medium mb-2">{{ $t('appointment.overall_total') }}</p>
                    <h4 class="mb-0">{{ formatPrice(totalPrice) }}</h4>
                  </div>
                  <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                    <span class="avatar-title" style="background-color: #556ee6">
                      <i class="bx bx-money font-size-24`"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <p class="text-muted fw-medium mb-2">{{ $t('appointment.paid') }}</p>
                    <h4 class="mb-0">
                      {{
                        vault_total.aggregate.sum.price === null
                          ? formatPrice(0)
                          : formatPrice(vault_total.aggregate.sum.price)
                      }}
                    </h4>
                  </div>
                  <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                    <span class="avatar-title" style="background-color: #279b3a">
                      <i class="bx bx-money font-size-24`"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <p class="text-muted fw-medium mb-2">{{ $t('appointment.remaining') }}</p>
                    <h4 class="mb-0">{{ formatPrice(totalVault) }}</h4>
                  </div>
                  <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                    <span class="avatar-title" style="background-color: #ff0000">
                      <i class="bx bx-money font-size-24`"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-md-9">
                <h4 class="card-title mb-2">{{ $t('service.services') }}</h4>
              </div>
              <div class="col-md-3 text-end">
                <b-button variant="success btn-sm" @click="addService">
                  {{ $t('appointment.add_services') }}
                </b-button>
              </div>
              <div v-for="(service, key) in event.event_services" :key="key" class="col-md-12 my-3">
                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('appointment.staff') }}</label>
                    <multiselect
                      v-model="service.staff_id"
                      :show-labels="false"
                      :placeholder="$t('appointment.staff')"
                      :options="
                        users
                          .filter(
                            (x) =>
                              x.locationIds.includes(event.location_id) && x.status === 'ACTIVE'
                          )
                          .map((x) => x.id)
                      "
                      :custom-label="
                        (opt) =>
                          users.find((x) => x.id == opt).first_name +
                          ' ' +
                          users.find((x) => x.id == opt).last_name
                      "
                      :class="{
                        'is-invalid': submitted && !service.staff_id,
                      }"
                    >
                      <span slot="noResult">
                        {{ $t('lists.no_record') }}
                      </span>
                      <span slot="noOptions">
                        {{ $t('lists.no_record') }}
                      </span>
                    </multiselect>
                    <div v-if="submitted && !service.staff_id" class="invalid-feedback">
                      {{ $t('fields.required') }}
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label>{{ $t('service.service') }}</label>
                    <multiselect
                      v-if="services"
                      v-model="service.service_id"
                      :placeholder="$t('appointment.services')"
                      :show-labels="false"
                      :options="
                        services
                          .filter(
                            (x) =>
                              x.locationIds.includes(event.location_id) &&
                              (service.staff_id
                                ? x.groupIds.includes(
                                    users.find((u) => u.id === service.staff_id).user_group.id
                                  )
                                : false)
                          )
                          .map((x) => x.id)
                      "
                      :custom-label="(opt) => services.find((x) => x.id == opt).name"
                      :class="{
                        'is-invalid': submitted && !service.service_id,
                      }"
                      @input="setServicePriceAndDuration(service.service_id, key)"
                    >
                      <span slot="noResult">
                        {{ $t('lists.no_record') }}
                      </span>
                      <span slot="noOptions">
                        {{ $t('lists.no_record') }}
                      </span>
                    </multiselect>
                    <div v-if="submitted && !service.service_id" class="invalid-feedback">
                      {{ $t('fields.required') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label>{{ $t('appointment.price') }}</label>
                    <input
                      id="service-price"
                      v-model="service.price"
                      :disabled="
                        bundle_services.find((x) => x.service_id === service.service_id) &&
                        bundle_services.find((x) => x.service_id === service.service_id).amount !==
                          0
                      "
                      type="text"
                      :placeholder="$t('appointment.price')"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && (service.price === '' || service.price === null),
                      }"
                    />
                    <div
                      v-if="submitted && (service.price === '' || service.price === null)"
                      class="invalid-feedback"
                    >
                      {{ $t('fields.required') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label>{{ $t('service.duration') }}</label>
                    <input
                      id="service-duration"
                      v-model="service.duration"
                      type="text"
                      :placeholder="$t('appointment.time')"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && (service.duration === '' || service.duration === null),
                      }"
                    />
                    <div
                      v-if="submitted && (service.duration === '' || service.duration === null)"
                      class="invalid-feedback"
                    >
                      {{ $t('fields.required') }}
                    </div>
                  </div>
                  <!-- <div class="col-md-2">
                    <date-picker
                      :id="`serice-time-` + key"
                      v-model="service.time"
                      type="time"
                      format="hh:mm A"
                      value-type="HH:mm"
                      :minute-step="5"
                      placeholder="Select time"
                      :time-picker-options="{
                        start: '00:00',
                        step: '00:15',
                        end: '23:55',
                        format: 'hh:mm A',
                      }"
                      :class="{
                        'is-invalid': submitted && !service.time,
                      }"
                    />
                    <div v-if="submitted && !service.time" class="invalid-feedback">
                      {{ $t('fields.required') }}
                    </div>
                  </div> -->
                  <div class="col-md-2">
                    <button
                      :disabled="event.event_services.length <= 1"
                      type="button"
                      class="btn btn-danger btn-md"
                      style="margin-left: 5px; margin-top: 27px"
                      @click="event.event_services.splice(-1, key)"
                    >
                      <i class="bx bx-trash font-size-16 align-middle me-1"></i
                      >{{ $t('buttons.delete') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <services-component
          :event="event"
          :total-price="vault_total"
          @updated="$apollo.queries.services_total_price.refresh()"
          @checkData="checkData($event)"
        /> -->
        <product-component
          :event="event"
          @updated="$apollo.queries.products_total_price.refresh()"
          @checkData="checkDataP($event)"
        />
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-md-9">
                <h4 class="card-title mb-2">{{ $t('tip.tips') }}</h4>
              </div>
              <div class="col-md-3 text-end">
                <b-button variant="success btn-sm" @click="addTip">
                  {{ $t('tip.add_tip') }}
                </b-button>
              </div>
              <div v-for="(tip, key) in event.tips" :key="key" class="col-md-12 my-3">
                <div class="row" :data-key="key">
                  <div class="col-md-3">
                    <label>{{ $t('appointment.staff') }}</label>
                    <multiselect
                      v-model="tip.staff_id"
                      :show-labels="false"
                      :placeholder="$t('appointment.staff')"
                      :options="
                        users
                          .filter(
                            (x) =>
                              x.locationIds.includes(event.location_id) &&
                              !event.tips.map((tip) => tip.staff_id).includes(x.id) &&
                              event.event_services.map((service) => service.staff_id).includes(x.id)
                          )
                          .map((x) => x.id)
                      "
                      :custom-label="
                        (opt) =>
                          users.find((x) => x.id == opt).first_name +
                          ' ' +
                          users.find((x) => x.id == opt).last_name
                      "
                      :class="{
                        'is-invalid': submitted && !tip.staff_id,
                      }"
                    >
                      <span slot="noResult">
                        {{ $t('lists.no_record') }}
                      </span>
                      <span slot="noOptions">
                        {{ $t('lists.no_record') }}
                      </span>
                    </multiselect>
                    <div v-if="submitted && !tip.staff_id" class="invalid-feedback">
                      {{ $t('fields.required') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label>{{ $t('appointment.price') }}</label>
                    <input
                      id="tip-price"
                      v-model="tip.price"
                      type="text"
                      :placeholder="$t('appointment.price')"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && (tip.price === '' || tip.price === null),
                      }"
                    />
                    <div
                      v-if="submitted && (tip.price === '' || tip.price === null)"
                      class="invalid-feedback"
                    >
                      {{ $t('fields.required') }}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <button
                      type="button"
                      class="btn btn-danger btn-md"
                      style="margin-left: 5px; margin-top: 27px"
                      @click="event.tips.splice(key)"
                    >
                      <i class="bx bx-trash font-size-16 align-middle me-1"></i
                      >{{ $t('buttons.delete') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="appointmentStatus === 'unknown'" class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body row" style="gap: 10px">
                <button
                  type="button"
                  class="btn w-xs btn-outline-success event-action-button"
                  @click="update()"
                >
                  {{ $t('buttons.update').toUpperCase() }}
                </button>
                <button
                  class="btn btn-md event-action-button"
                  style="border: 1px solid rgb(73 80 87)"
                  @click.prevent="editAppointmentModal = true"
                >
                  <!-- <i class="bx bx-pencil font-size-14 align-middle"></i> -->
                  <span class="mx-1">{{ $t(`appointment.edit_date`).toUpperCase() }} </span>
                </button>
                <button
                  v-if="totalVault !== 0"
                  type="button"
                  class="btn w-xs btn-success event-action-button"
                  @click="newVault()"
                >
                  {{ $t(`appointment.checkout`).toUpperCase() }}
                </button>
                <button
                  v-if="totalVault === 0"
                  style="flex: 1"
                  class="btn w-xs btn-success event-action-button"
                  @click="changeEventTypes('came')"
                >
                  {{ $t('appointment.complete').toUpperCase() }}
                </button>
                <button
                  type="button"
                  class="btn w-xs btn-danger event-action-button"
                  @click="changeEventTypes('cancel')"
                >
                  {{ $t(`appointment.status_type.cancel`).toUpperCase() }}
                </button>
                <button
                  type="button"
                  class="btn w-xs btn-warning event-action-button"
                  @click="changeEventTypes('not_came')"
                >
                  {{ $t(`appointment.status_type.not_came`).toUpperCase() }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <h4 class="card-title mb-4">{{ $t('appointment.collection') }}</h4>
                <p class="text-muted">{{ $t('appointment.to_be_charged') }}</p>
                <h3>{{ formatPrice(totalVault) }}</h3>
                <div class="mt-4">
                  <b-modal
                    v-if="methodModal === true"
                    v-model="methodModal"
                    :title="
                      $t('staff.details.add_collection') +
                      ' ' +
                      $t('staff.details.remaining_amount') +
                      '(' +
                      formatPrice(totalVault) +
                      ')'
                    "
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                  >
                    <ul class="payment-methods">
                      <li
                        v-for="(item, key) in paymentMethods"
                        :key="item.key + key"
                        :class="{
                          'd-none':
                            (item.key === 'stripe' &&
                              (!event.location.stripe_location_id ||
                                !event.location.stripe_account_id)) ||
                            (item.key === 'credit_card' &&
                              event.location.stripe_location_id &&
                              event.location.stripe_account_id),
                        }"
                        @click="
                          methodModal = false
                          addVault = true
                          vaultData.payment_method = item.key
                        "
                      >
                        <i class="bx" :class="item.icon"></i>
                        {{ item.name }}
                      </li>
                    </ul>
                  </b-modal>
                  <b-modal
                    v-if="addVault === true"
                    v-model="addVault"
                    :title="
                      $t('staff.details.add_collection') +
                      ' ' +
                      $t('staff.details.remaining_amount') +
                      '(' +
                      formatPrice(totalVault) +
                      ')'
                    "
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                  >
                    <div class="row">
                      <div v-if="vaultData.payment_method !== 'stripe'" class="col-md-6 mb-2">
                        <label>{{ $t('appointment.date') }}</label>
                        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                          <date-picker
                            v-model="vaultData.date"
                            type="datetime"
                            value-type="YYYY-MM-DD HH:mm"
                            format="DD/MM/YYYY HH:mm"
                            :class="{
                              'is-invalid': submitted && $v.vaultData.date.$error,
                            }"
                            :disabled="vaultData.payment_method === 'stripe'"
                          ></date-picker>
                          <div
                            v-if="submitted && !$v.vaultData.date.required"
                            class="invalid-feedback"
                          >
                            {{ $t('fields.required') }}
                          </div>
                        </b-input-group>
                      </div>
                      <div v-if="vaultData.payment_method !== 'stripe'" class="col-md-6 mb-2">
                        <label>{{ $t('appointment.explanation') }}</label>
                        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                          <b-form-input v-model="vaultData.description"></b-form-input>
                        </b-input-group>
                      </div>
                      <div class="col-md-6 mb-2">
                        <label>{{ $t('appointment.payment_method') }}</label>
                        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                          <b-form-input
                            v-model="
                              paymentMethods.find((x) => x.key == vaultData.payment_method).name
                            "
                            :class="{
                              'is-invalid': submitted && $v.vaultData.payment_method.$error,
                            }"
                            disabled
                          ></b-form-input>
                        </b-input-group>
                        <div
                          v-if="submitted && !$v.vaultData.payment_method.required"
                          class="invalid-feedback"
                        >
                          {{ $t('fields.required') }}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label>{{ $t('appointment.amount') }}</label>
                        <b-input-group :prepend="companyCurrency" class="mb-2 mr-sm-2 mb-sm-0">
                          <b-form-input
                            v-model="vaultData.price"
                            :class="{
                              'is-invalid': submitted && $v.vaultData.price.$error,
                            }"
                            type="number"
                            step="0.01"
                            :max="totalVault"
                          ></b-form-input>
                          <div
                            v-if="submitted && !$v.vaultData.price.required"
                            class="invalid-feedback"
                          >
                            {{ $t('fields.required') }}
                          </div>
                        </b-input-group>
                      </div>
                      <div class="col-12">
                        <b-alert
                          v-if="vaultData.payment_method === 'stripe'"
                          show
                          variant="warning"
                          class="my-1"
                        >
                          {{ formatPrice(event.teams.processing_fee / 100) }}
                          {{ $t('utility.processing_fee_description') }}
                        </b-alert>
                      </div>
                      <b-alert
                        v-if="vaultData.price != totalVault"
                        show
                        variant="warning"
                        class="my-1"
                      >
                        <span v-if="vaultData.price > totalVault">
                          {{ $t('appointment.more_than_price') }}
                        </span>
                        <span v-else>
                          {{ $t('appointment.less_than_price') }}
                        </span>
                      </b-alert>
                      <b-button
                        v-if="vaultData.payment_method === 'stripe'"
                        class="mt-3"
                        variant="success"
                        @click="initTerminal"
                        >{{ $t('buttons.pay') }}</b-button
                      >
                      <b-button v-else class="mt-3" variant="success" @click="saveVaultActivity">{{
                        $t('buttons.save')
                      }}</b-button>
                    </div>
                  </b-modal>
                  <b-modal
                    v-if="terminalErrorModal === true"
                    v-model="terminalErrorModal"
                    :title="'Error'"
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <b-alert show variant="warning">
                          {{ terminalErrorMessage }}
                        </b-alert>
                      </div>
                    </div>
                  </b-modal>
                  <b-modal
                    v-if="terminalSelect === true"
                    v-model="terminalSelect"
                    :title="'Stripe Terminal'"
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                  >
                    <div v-if="terminalStep === 'device'" class="row">
                      <div class="col-md-12">
                        Connect with:
                        <a
                          href="javascript:void(0)"
                          @click.prevent="
                            terminalSelect = false
                            createTerminal(true)
                          "
                          >Simulated device</a
                        >
                      </div>
                      <div class="col-md-12">
                        Connect with:
                        <a
                          href="javascript:void(0)"
                          @click.prevent="
                            terminalSelect = false
                            createTerminal(false)
                          "
                          >Real device</a
                        >
                      </div>
                    </div>
                    <div v-if="terminalStep === 'select'" class="row">
                      <div class="col-md-12">
                        <ul>
                          <li v-for="item in discoveredReaders" :key="item.id">
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                terminalSelect = false
                                connectTerminal(item)
                              "
                            >
                              {{ item.label }} - {{ item.id }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div v-if="terminalStep === 'card'" class="row">
                      <div class="col-md-12">
                        <ul>
                          <li v-for="card in testCards" :key="card.number">
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                terminalSelect = false
                                testCardNumber = card.number
                                connectTerminal(discoveredReaders[0])
                              "
                            >
                              {{ card.label }} - {{ card.number }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </b-modal>
                </div>
              </div>
              <div class="col-sm-6">
                <!-- Chart -->
                <apexchart
                  class="apex-charts"
                  type="radialBar"
                  height="200"
                  dir="ltr"
                  :series="percent"
                  :options="chartOptions"
                ></apexchart>
              </div>
            </div>
            <p v-if="vault_activities.length > 0" class="text-muted mb-0">
              {{ $t('appointment.collections_made') }}
            </p>
            <div v-if="vault_activities.length > 0" class="table-responsive mt-4">
              <table class="table table-centered">
                <tbody>
                  <tr v-for="(vault, key) in vault_activities" :key="key">
                    <td style="width: 140px">
                      {{ $t(`payment_method.${vault.payment_method}`) }}
                    </td>
                    <td style="width: 120px">{{ formatPrice(vault.price) }}</td>
                    <td>{{ moment(vault.date).format('DD/MM/YYYY HH:mm') }}</td>
                    <td>
                      <b-button size="sm" variant="danger" @click="removeVault(vault)"
                        ><i class="bx bx-trash"></i
                      ></b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <contact-debt
          :refresh-data="refreshData"
          :event="event"
          @close="
            $apollo.queries.bundle_paid.refresh()
            $apollo.queries.vault_total.refresh()
            $apollo.queries.vault_activities.refresh()
          "
        ></contact-debt>
        <!-- <event-bundle :event="event" /> -->
      </div>
    </div>

    <b-modal
      v-if="editAppointmentModal"
      v-model="editAppointmentModal"
      :title="$t('appointment.edit_appointment')"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
      size="xl"
    >
      <agenda :mode="'edit'" :event-date-data="event" />
    </b-modal>
  </div>
</template>

<script>
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import { required } from 'vuelidate/lib/validators'
// import ServicesComponent from './components/services'
import ProductComponent from './components/products'
// import eventBundle from './components/bundle-event'
import Agenda from './agenda.vue'
import contactDebt from './components/contact-debt'
import { mapState, mapGetters } from 'vuex'
import {
  GET_EVENT_SINGLE,
  GET_EVENT_PRODUCTS_TOTAL_PRICE,
  GET_EVENT_SERVICES_TOTAL_PRICE,
  GET_VAULT_ACTIVITY_AGENDA,
  GET_EVENT_STATUS,
  UPDATE_PAYMENT,
  UPDATE_EVENT,
  GET_EVENT_BUNDLE_PAID,
  INSERT_VAULT_ACTIVITY,
  INSERT_VAULT_ACTIVITIES_MULTI,
  UPDATE_VAULT_ACTIVITY,
  GET_USERS_AGENDA,
  GET_VAULT_TOTAL,
  GET_SERVICES_AGENDA,
  GET_BUNDLE_SERVICES,
  UPDATE_EVENT_SERVICES,
  UPDATE_TIPS,
  GET_TIPS_TOTAL_PRICE,
} from '@/router/queries'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
import { loadStripeTerminal } from '@stripe/terminal-js'

export default {
  page: {
    title: 'appointment_details',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    PageHeader,
    Multiselect,
    DatePicker,
    contactDebt,
    // eventBundle,
    // ServicesComponent,
    ProductComponent,
    Agenda,
  },
  data() {
    return {
      title: this.$t('appointment.appointment_details'),
      moment,
      addVault: false,
      updateModal: false,
      submitted: false,
      vaultData: null,
      methodModal: false,
      terminalSelect: false,
      terminalErrorModal: false,
      terminalErrorMessage: null,
      testCardNumber: null,
      testCards: [
        { label: 'Payment succeeds', number: '4242424242424242' },
        { label: 'Payment requires authentication', number: '4000002500003155' },
        { label: 'Payment is declined', number: '4000000000009995' },
      ],
      terminalStep: 'device',
      discoveredReaders: [],
      items: [
        {
          text: this.$t('customerForm.name') + this.$t('customerForm.surname'),
          href: '/',
        },
        {
          text: this.$t('appointment.appointment_details'),
          active: true,
        },
      ],
      bundle_services: [],
      chartOptions: {
        chart: {
          height: 200,
          type: 'radialBar',
          offsetY: -10,
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '13px',
                color: undefined,
                offsetY: 60,
              },
              value: {
                offsetY: 22,
                fontSize: '16px',
                color: undefined,
                formatter: function (val) {
                  return val + '%'
                },
              },
            },
          },
        },
        colors: ['#556ee6'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 4,
        },
        labels: [this.$t('appointment.complete')],
      },
      test: 6,
      checkDatas: false,
      checkDatasP: false,
      series: [12],
      servicesEmit: null,
      refreshData: false,
      editAppointmentModal: false,
      stripeAccountId: null,
    }
  },
  computed: {
    ...mapState(['auth']),
    ...mapGetters({
      companyCurrency: 'currency/companyCurrency',
    }),
    nodeEnv() {
      return process.env.NODE_ENV
    },
    totalVault() {
      const total =
        this.products_total_price.aggregate.sum.price +
        this.services_total_price.aggregate.sum.price +
        this.tips_total_price.aggregate.sum.price -
        (this.vault_total.aggregate.sum.price - this.bundle_paid.aggregate.sum.price)
      return parseFloat(total)
    },
    totalPrice() {
      const total =
        this.products_total_price.aggregate.sum.price +
        this.services_total_price.aggregate.sum.price +
        this.bundle_paid.aggregate.sum.price +
        this.tips_total_price.aggregate.sum.price
      return total
    },
    // eslint-disable-next-line vue/return-in-computed-property
    percent() {
      if (this.totalPrice !== 0) {
        const percent = (this.totalVault / this.totalPrice) * 100
        const percentRound = Math.round(percent)
        const sonuc = 100 - percentRound
        return [sonuc]
      }
      return [0]
    },
    paymentMethods() {
      return [
        {
          key: 'stripe',
          name: this.$t('case.stripe_terminal'),
          icon: 'bx-credit-card',
        },
        {
          key: 'credit_card',
          name: this.$t('case.credit_card'),
          icon: 'bx-credit-card-alt',
        },
        {
          key: 'voucher',
          name: this.$t('case.voucher'),
          icon: 'bxs-discount',
        },
        {
          key: 'transfer',
          name: this.$t('case.transfer'),
          icon: 'bxs-bank',
        },
        {
          key: 'cash',
          name: this.$t('case.cash'),
          icon: 'bx-money',
        },
      ]
    },
    appointmentStatus() {
      return this.event.event_status.name
    },
  },
  validations: {
    event: {
      contact_id: { required },
      location_id: { required },
      user_id: { required },
      start: { required },
    },
    vaultData: {
      date: { required },
      price: { required },
      payment_method: { required },
    },
  },
  watch: {
    'product.product_id': function (val) {
      this.product.name = this.products.find((x) => x.id === val).name
      this.product.price = this.products.find((x) => x.id === val).price
    },
    'service.service_id': function (val) {
      this.service.price = this.services.find((x) => x.id === val).price
      this.service.duration = this.services.find((x) => x.id === val).duration
    },
  },
  created() {
    this.$_loading()
  },
  methods: {
    update() {
      this.$loading(true)
      this.$v.event.$touch()
      let serviceIsValid = true
      this.submitted = true
      this.event.event_services.forEach((el) => {
        if (!el.service_id || !el.price || !el.staff_id || !el.duration || !el.time)
          serviceIsValid = false
      })
      if (this.$v.event.$invalid || !serviceIsValid) {
        console.log(this.$v.event.$invalid, !serviceIsValid)
        /*this.$loading(false)
        return*/
      }
      this.event.duration = 0
      this.event.event_services.forEach((service) => {
        this.event.duration += parseInt(service.duration)
      })
      this.event.end = moment(this.event.start, 'YYYY-MM-DDTHH:mm:ssz')
        .add(this.event.duration, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ssz')
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            id: this.event.id,
            event: this.$_ER(this.event, [
              '__typename',
              'contact',
              'event_type',
              'event_status',
              'event_services',
              'tips',
              'location',
              'user',
              'tips',
              'teams',
            ]),
          },
        })
        .then(() => {
          this.updateServices(this.event.id)
          this.updateTips(this.event.id)
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
          this.$loading(false)
        })
      this.updateModal = false
      this.submitted = false
    },
    updateServices(event_id) {
      const services = [...this.event.event_services]
      services.forEach((service) => {
        service.event_id = event_id
        service.contact_id = this.event.contact_id
        service.start =
          moment(this.event.start, moment.HTML5_FMT.DATETIME_LOCAL).format('YYYY-MM-DD') +
          ' ' +
          service.time
        service.end = moment(service.start, 'YYYY-MM-DD HH:mm')
          .add(service.duration, 'minutes')
          .format('YYYY-MM-DD HH:mm')
        delete service.time
        delete service['service']
        delete service['__typename']
      })
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT_SERVICES,
          variables: {
            where: {
              event_id: {
                _eq: event_id,
              },
            },
            objects: services,
          },
        })
        .then(() => {
          this.$loading(false)
          this.$_N({
            type: 'success',
            position: 'center',
          })
          this.$apollo.queries.event.refresh()
          this.$apollo.queries.services_total_price.refresh()
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
          this.$loading(false)
        })
      this.updateModal = false
    },
    updateTips(event_id) {
      const tips = [...this.event.tips]
      tips.forEach((tip) => {
        tip.contact_id = this.event.contact_id
        delete tip['__typename']
      })
      this.$apollo
        .mutate({
          mutation: UPDATE_TIPS,
          variables: {
            where: {
              event_id: {
                _eq: event_id,
              },
            },
            objects: tips,
          },
        })
        .then(() => {
          this.$loading(false)
          this.$_N({
            type: 'success',
            position: 'center',
          })
          this.$apollo.queries.event.refresh()
          this.$apollo.queries.tips_total_price.refresh()
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
          this.$loading(false)
        })
      this.updateModal = false
    },
    getBundle(id) {
      this.$apollo
        .query({
          query: GET_BUNDLE_SERVICES,
          variables: {
            where: {
              team_id: {
                _eq: this.auth.user.team_id,
              },
              bundle: {
                contact_id: {
                  _eq: id,
                },
              },
            },
          },
        })
        .then((response) => {
          this.bundle_services = response.data.bundle_services
        })
    },
    setServicePriceAndDuration(service_id, key) {
      if (
        this.bundle_services.find((x) => x.service_id === service_id) &&
        this.bundle_services.find((x) => x.service_id === service_id).amount !== 0
      ) {
        this.event.event_services[key].duration = this.services.find(
          (x) => x.id === service_id
        ).duration
        this.event.event_services[key].price = 0
        this.event.duration = this.durationTime
      } else {
        this.event.event_services[key].duration = this.services.find(
          (x) => x.id === service_id
        ).duration
        this.event.event_services[key].price = this.services.find((x) => x.id === service_id).price
        this.event.duration = this.durationTime
      }
    },
    changeEventTypes(type) {
      const statusId = this.event_statuses.filter((el) => el.name === type)[0].id
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_EVENT,
        variables: {
          id: this.event.id,
          event: (this.event = {
            event_status_id: statusId,
          }),
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$apollo.queries.event.refresh()
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$loading(false)
          }
        },
      })
      this.updateModal = false
    },
    newVault() {
      this.vaultData = {
        contact_id: this.event.contact_id,
        date: moment().format('YYYY-MM-DD HH:mm'),
        description: null,
        event_id: this.$route.params.key,
        price: parseFloat(this.totalVault),
        price_type: this.companyCurrency,
        payment_method: null,
        team_id: this.auth.user.team_id,
        type: 'income',
        user_id: this.auth.user.id,
        vault_id: null,
      }
      this.methodModal = true
    },
    saveVaultActivity() {
      this.$loading(true)
      this.submitted = true
      this.$v.vaultData.$touch()
      if (this.$v.vaultData.$invalid) {
        this.$loading(false)
        return
      } else {
        this.vaultData.date = this.convertUtc(this.vaultData.date || new Date())
        if (!this.vaultData.net_price) this.vaultData.net_price = this.vaultData.price
        this.$apollo.mutate({
          mutation: INSERT_VAULT_ACTIVITY,
          variables: {
            vault: this.vaultData,
          },
          update: () => {
            try {
              this.$loading(false)
              this.$apollo.queries.vault_activities.refresh()
              this.$apollo.queries.vault_total.refresh()
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.checkCompletelyPaid()
            } catch (e) {
              this.$loading(false)
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          },
        })
        this.addVault = false
      }
      this.submitted = false
    },
    checkCompletelyPaid() {
      if (this.totalVault === parseFloat(this.vaultData.price)) {
        this.changeEventTypes('came')
      }
    },
    removeVault(vault) {
      const date = moment().format('YYYY-MM-DD HH:mm')
      const self = this
      this.$_C({
        text: this.$t('appointment.delete_title'),
        title: this.$t('appointment.delete_confirm'),
        confirmed(result) {
          if (result) {
            if (vault.bundle_payment_id !== null) {
              self.$_C({
                text: this.$t('appointment.delete_title'),
                title: this.$t('appointment.archive_payment'),
                confirmed(result) {
                  if (result) {
                    self.$loading(true)
                    self.$apollo.mutate({
                      mutation: UPDATE_PAYMENT,
                      variables: {
                        id: vault.bundle_payment_id,
                        payment: {
                          status: 'PENDING',
                        },
                      },
                      update: () => {
                        self.$apollo.mutate({
                          mutation: UPDATE_VAULT_ACTIVITY,
                          variables: {
                            id: vault.id,
                            vault: {
                              deleted_at: date,
                              deleted_by: self.auth.user.id,
                            },
                          },
                          update: () => {
                            self.refreshData = !self.refreshData
                            self.$apollo.queries.vault_activities.refresh()
                            self.$apollo.queries.vault_total.refresh()
                            self.$_N({
                              type: 'success',
                              position: 'center',
                            })
                            self.$loading(false)
                          },
                        })
                      },
                    })
                  }
                },
              })
            } else {
              self.$loading(true)
              self.$apollo.mutate({
                mutation: UPDATE_VAULT_ACTIVITY,
                variables: {
                  id: vault.id,
                  vault: {
                    deleted_at: date,
                    deleted_by: self.auth.user.id,
                  },
                },
                update: () => {
                  try {
                    self.$apollo.queries.vault_activities.refresh()
                    self.$apollo.queries.vault_total.refresh()
                    self.$_N({
                      type: 'success',
                      position: 'center',
                    })
                    self.$loading(false)
                  } catch (e) {
                    self.$_N({
                      type: 'error',
                      position: 'center',
                    })
                  }
                },
              })
            }
          }
        },
      })
    },
    addService() {
      const lastService = this.event.event_services.slice(-1).pop()
      this.event.event_services.push({
        service_id: null,
        price: 0,
        staff_id: null,
        duration: 30,
        team_id: this.auth.user.team_id,
        user_id: this.auth.user.id,
        time: moment(lastService.time, 'HH:mm').add(lastService.duration, 'm').format('HH:mm'),
      })
    },
    addTip() {
      this.event.tips.push({
        event_id: this.event.id,
        price: 0,
        staff_id: null,
        team_id: this.auth.user.team_id,
      })
    },
    checkData(data) {
      if (data.length) {
        this.checkDatasP = true
      } else {
        this.checkDatasP = false
      }
    },
    checkDataP(data) {
      if (data.length) {
        this.checkDatas = true
      } else {
        this.checkDatas = false
      }
    },
    async initTerminal() {
      this.addVault = false
      if (!this.event.location.stripe_location_id || !this.event.location.stripe_account_id) {
        this.$bvToast.toast('No stripe locations assigned', {
          autoHideDelay: 3500,
          variant: 'danger',
          toaster: 'b-toaster-bottom-left',
          appendToast: true,
          noCloseButton: true,
        })
        return false
      }
      if (this.nodeEnv === 'development') {
        this.terminalStep = 'device'
        this.terminalSelect = true
      } else {
        this.createTerminal(false)
      }
    },
    fetchConnectionToken() {
      const apiURL = process.env.VUE_APP_API_URL
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          team_id: this.auth.user.team_id,
          location: this.event.location.stripe_location_id,
          account_id: this.event.location.stripe_account_id,
          step: 'token',
        }),
      }

      return fetch(`${apiURL}/stripe-terminal`, requestOptions)
        .then(function (response) {
          return response.json()
        })
        .then(function (data) {
          if (data.code === 'no_account') {
            this.terminalErrorMessage = this.$t('stripe.onboard_message')
            this.terminalErrorModal = true
          }
          return data.secret
        })
    },
    async createTerminal(simulated = true) {
      const StripeTerminal = await loadStripeTerminal()
      this.terminalObject = await StripeTerminal.create({
        onFetchConnectionToken: this.fetchConnectionToken,
        onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
      })
      this.$loading(true)
      const terminal = this.terminalObject
      // Discovering the readers
      this.$bvToast.toast('Discovering the readers...', {
        autoHideDelay: 3500,
        toaster: 'b-toaster-bottom-left',
        appendToast: true,
        noCloseButton: true,
      })
      let discoveredReaders
      const config = { simulated, location: this.event.location.stripe_location_id }
      const discoverResult = await terminal.discoverReaders(config)
      if (discoverResult.error) {
        this.$bvToast.toast('Failed to discover', {
          autoHideDelay: 3500,
          variant: 'danger',
          toaster: 'b-toaster-bottom-left',
          appendToast: true,
          noCloseButton: true,
        })
        console.log('Failed to discover: ', discoverResult.error)
      } else if (discoverResult.discoveredReaders.length === 0) {
        this.$bvToast.toast('No available readers.', {
          autoHideDelay: 3500,
          toaster: 'b-toaster-bottom-left',
          appendToast: true,
          noCloseButton: true,
        })
        console.log('No available readers.')
      } else {
        discoveredReaders = discoverResult.discoveredReaders
        this.$bvToast.toast(`${discoveredReaders.length} Readers found`, {
          autoHideDelay: 3500,
          toaster: 'b-toaster-bottom-left',
          appendToast: true,
          noCloseButton: true,
        })
        console.log('terminal.discoverReaders', discoveredReaders)
        this.$loading(false)
        this.terminalStep = 'select'
        this.discoveredReaders = discoveredReaders
        this.terminalSelect = true
        // return this.connectTerminal(discoveredReaders)
      }
      this.$loading(false)
    },
    connectTerminal(selectedReader) {
      if (selectedReader.id === 'SIMULATOR' && this.terminalStep === 'select') {
        this.terminalStep = 'card'
        this.terminalSelect = true
        return
      }
      this.$loading(true)
      const terminal = this.terminalObject
      terminal.disconnectReader()
      // Connecting to reader
      // Just selecting the first reader here.
      this.$bvToast.toast('Connecting to reader...', {
        autoHideDelay: 3500,
        toaster: 'b-toaster-bottom-left',
        appendToast: true,
        noCloseButton: true,
      })
      // const selectedReader = discoveredReaders[0]
      terminal.connectReader(selectedReader).then((connectResult) => {
        this.$loading(false)
        if (connectResult.error) {
          this.$bvToast.toast('Failed to connect', {
            autoHideDelay: 3500,
            variant: 'danger',
            toaster: 'b-toaster-bottom-left',
            appendToast: true,
            noCloseButton: true,
          })
          console.log('Failed to connect: ', connectResult.error)
        } else {
          this.$bvToast.toast(`Connected to reader: ${connectResult.reader.label}`, {
            autoHideDelay: 3500,
            toaster: 'b-toaster-bottom-left',
            appendToast: true,
            noCloseButton: true,
          })
          console.log('Connected to reader: ', connectResult.reader.label)
          console.log('terminal.connectReader', connectResult)
          this.collectPayment()
        }
      })
    },
    unexpectedDisconnect() {
      // In this function, your app should notify the user that the reader disconnected.
      // You can also include a way to attempt to reconnect to a reader.
      this.$bvToast.toast('Disconnected from reader', {
        autoHideDelay: 5000,
        variant: 'danger',
        toaster: 'b-toaster-bottom-left',
        appendToast: true,
        noCloseButton: true,
      })
      this.$loading(false)
      console.log('Disconnected from reader')
    },
    fetchPaymentIntentClientSecret(amount) {
      const apiURL = process.env.VUE_APP_API_URL
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          team_id: this.auth.user.team_id,
          location: this.event.location.stripe_location_id,
          account_id: this.event.location.stripe_account_id,
          amount: parseInt(amount),
          currency: this.companyCurrency,
          description: `${this.event.location.name} - ${this.event.contact.first_name} ${this.event.contact.last_name} - ${this.event.id}`,
          metadata: { location: this.event.location.id, event: this.event.id },
          step: 'create',
        }),
      }

      return fetch(`${apiURL}/stripe-terminal`, requestOptions)
        .then(function (response) {
          return response.json()
        })
        .then(function (data) {
          return data.client_secret
        })
    },
    collectPayment() {
      this.$loading(true)
      const terminal = this.terminalObject
      this.$bvToast.toast('Collecting payment...', {
        autoHideDelay: 3500,
        toaster: 'b-toaster-bottom-left',
        appendToast: true,
        noCloseButton: true,
      })
      let paymentIntentId
      this.fetchPaymentIntentClientSecret(this.vaultData.price * 100).then((client_secret) => {
        this.$loading(false)
        if (this.testCardNumber)
          terminal.setSimulatorConfiguration({ testCardNumber: this.testCardNumber })
        this.testCardNumber = null
        this.$loading(true)
        terminal.collectPaymentMethod(client_secret).then((result) => {
          this.$loading(false)
          if (result.error) {
            // Placeholder for handling result.error
            this.$bvToast.toast('Collect payment error', {
              autoHideDelay: 3500,
              variant: 'danger',
              toaster: 'b-toaster-bottom-left',
              appendToast: true,
              noCloseButton: true,
            })
            console.log('collect payment error', result)
          } else {
            this.$bvToast.toast('Processing payment...', {
              autoHideDelay: 3500,
              toaster: 'b-toaster-bottom-left',
              appendToast: true,
              noCloseButton: true,
            })
            this.$loading(true)
            console.log('terminal.collectPaymentMethod', result.paymentIntent)
            terminal.processPayment(result.paymentIntent).then((result) => {
              this.$loading(false)
              if (result.error) {
                this.$bvToast.toast('Process payment error', {
                  autoHideDelay: 3500,
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-left',
                  appendToast: true,
                  noCloseButton: true,
                })
                this.terminalErrorMessage = result.error.message
                this.terminalErrorModal = true
                console.log(result.error)
              } else if (result.paymentIntent) {
                paymentIntentId = result.paymentIntent.id
                console.log('terminal.processPayment', result.paymentIntent)
                this.capturePayment(paymentIntentId)
              }
            })
          }
        })
      })
    },
    capturePayment(paymentIntentId) {
      this.$loading(true)
      const apiURL = process.env.VUE_APP_API_URL
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          team_id: this.auth.user.team_id,
          location: this.event.location.stripe_location_id,
          account_id: this.event.location.stripe_account_id,
          payment_intent_id: paymentIntentId,
          step: 'capture',
        }),
      }

      return fetch(`${apiURL}/stripe-terminal`, requestOptions)
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          this.$loading(false)
          this.vaultData = {
            contact_id: this.event.contact_id,
            date: moment.unix(data.created).format('YYYY-MM-DD HH:mm'),
            description: data.id,
            event_id: this.$route.params.key,
            price: (data.amount_received - this.event.teams.processing_fee) / 100,
            net_price: data.transfer_data.amount / 100,
            price_type: this.companyCurrency,
            payment_method: 'credit_card',
            team_id: this.auth.user.team_id,
            type: 'income',
            user_id: this.auth.user.id,
          }
          this.saveStripeVaultAvtivity()
          console.log('server.capture', data)
        })
    },
    saveStripeVaultAvtivity() {
      this.$loading(true)
      this.submitted = true
      this.$v.vaultData.$touch()
      if (this.$v.vaultData.$invalid) {
        this.$loading(false)
        return
      } else {
        this.vaultData.date = this.convertUtc(this.vaultData.date || new Date())
        const activities = []
        activities.push(this.vaultData)
        activities.push({
          ...this.vaultData,
          price: this.event.teams.processing_fee / 100,
          net_price: this.event.teams.processing_fee / 100,
          description: 'Processing Fee',
          payment_method: 'fees',
          type: 'expense',
        })
        activities.push({
          ...this.vaultData,
          price: this.event.teams.card_flat_fee / 100,
          net_price: this.event.teams.card_flat_fee / 100,
          description: 'Card Flat Fee',
          payment_method: 'fees',
          type: 'expense',
        })
        const CardPctFee = (this.vaultData.price * this.event.teams.card_pct_fee) / 100
        activities.push({
          ...this.vaultData,
          price: Math.round(CardPctFee * 100) / 100,
          net_price: Math.round(CardPctFee * 100) / 100,
          description: `Card Percentage Fee: ${this.event.teams.card_pct_fee}%`,
          payment_method: 'fees',
          type: 'expense',
        })
        const VAT =
          (CardPctFee + this.event.teams.card_flat_fee / 100) * (this.event.teams.card_vat / 100)
        activities.push({
          ...this.vaultData,
          price: Math.round(VAT * 100) / 100,
          net_price: Math.round(VAT * 100) / 100,
          description: `Card VAT: ${this.event.teams.card_vat}%`,
          payment_method: 'fees',
          type: 'expense',
        })
        this.$apollo.mutate({
          mutation: INSERT_VAULT_ACTIVITIES_MULTI,
          variables: {
            activities,
          },
          update: () => {
            try {
              this.$loading(false)
              this.$apollo.queries.vault_activities.refresh()
              this.$apollo.queries.vault_total.refresh()
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.checkCompletelyPaid()
            } catch (e) {
              this.$loading(false)
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          },
        })
        this.addVault = false
      }
      this.submitted = false
    },
  },
  apollo: {
    event: {
      fetchPolicy: 'no-cache',
      query: GET_EVENT_SINGLE,
      variables() {
        return {
          id: this.$route.params.key,
        }
      },
      update(results) {
        results?.event?.event_services.forEach((service) => {
          service.time = moment(service.start, moment.HTML5_FMT.DATETIME_LOCAL).format('HH:mm')
        })
        return results.event
      },
    },
    services: {
      fetchPolicy: 'no-cache',
      query: GET_SERVICES_AGENDA,
      variables() {
        return {
          where: {
            is_disabled: {
              _eq: false,
            },
            team_id: {
              _eq: this.auth.user.team_id,
            },
          },
        }
      },
      update(results) {
        results.services.forEach((service) => {
          service.locationIds = service.location_services.map((item) => item.location_id)
          service.groupIds = service.service_groups.map((item) => item.group.id)
        })
        return results.services
      },
    },
    event_statuses: {
      fetchPolicy: 'no-cache',
      query: GET_EVENT_STATUS,
    },
    users: {
      fetchPolicy: 'no-cache',
      query: GET_USERS_AGENDA,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            role: {
              _eq: 'user',
            },
          },
        }
      },
      update(results) {
        results.users.forEach((user) => {
          user.locationIds = user.location_staffs.map((item) => item.location_id)
        })
        return results.users
      },
    },
    services_total_price: {
      fetchPolicy: 'no-cache',
      query: GET_EVENT_SERVICES_TOTAL_PRICE,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            event_id: {
              _eq: this.$route.params.key,
            },
          },
        }
      },
    },
    bundle_paid: {
      fetchPolicy: 'no-cache',
      query: GET_EVENT_BUNDLE_PAID,
      variables() {
        return {
          where: {
            event_id: {
              _eq: this.$route.params.key,
            },
            deleted_by: { _is_null: true },
            event_payment: {
              _eq: true,
            },
          },
        }
      },
    },
    products_total_price: {
      fetchPolicy: 'no-cache',
      query: GET_EVENT_PRODUCTS_TOTAL_PRICE,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            event_id: {
              _eq: this.$route.params.key,
            },
          },
        }
      },
    },
    vault_total: {
      fetchPolicy: 'no-cache',
      query: GET_VAULT_TOTAL,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            event_id: {
              _eq: this.$route.params.key,
            },
            type: {
              _eq: 'income',
            },
            deleted_by: { _is_null: true },
          },
        }
      },
    },
    vault_activities: {
      fetchPolicy: 'no-cache',
      query: GET_VAULT_ACTIVITY_AGENDA,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            event_id: {
              _eq: this.$route.params.key,
            },
            type: {
              _eq: 'income',
            },
            deleted_by: { _is_null: true },
          },
        }
      },
    },
    tips_total_price: {
      fetchPolicy: 'no-cache',
      query: GET_TIPS_TOTAL_PRICE,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            event_id: {
              _eq: this.$route.params.key,
            },
          },
        }
      },
    },
  },
}
</script>

<style>
ul.payment-methods {
  padding: 0 2rem;
  /* text-align: center; */
}

ul.payment-methods > li {
  list-style: none;
  color: #04756c;
  font-size: 1rem;
  padding: 1rem;
  border: 1px solid #04756c;
  border-radius: 1rem;
  margin: 2rem 0;
  transition: 0.25s;
}

ul.payment-methods i {
  font-size: 1.4rem;
  vertical-align: sub;
  margin-right: 0.3rem;
}

ul.payment-methods > li:hover {
  cursor: pointer;
  background-color: rgb(178 237 216 / 33%);
}

.event-action-button {
  flex: 1;
}

@media screen and (max-width: 1024px) {
  .event-action-button {
    flex: 1 0 50%;
  }
}
</style>
