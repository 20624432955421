<template>
  <div>
    <vue-tel-input
      v-if="showInput"
      v-model="telValue"
      :input-options="{ placeholder: null, showDialCode: true }"
      :class="{ 'is-invalid': isInvalid || (!invalidFormat && !!nationalNumber) }"
      :default-country="defaultCountry"
      @input="telInputHandler"
    >
    </vue-tel-input>
    <div v-if="!invalidFormat && !!nationalNumber" class="invalid-feedback">
      {{ $t('customerForm.invalid_phone_number') }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    value: {
      default: '',
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    telValue: '',
    nationalNumber: '',
    invalidFormat: false,
    defaultCountry: null,
    showInput: false,
  }),
  watch: {
    value(newVal) {
      if (newVal) {
        const val = newVal.toString()
        if (val.charAt(0) !== '+') this.telValue = '+' + val
        else this.telValue = val
      }
    },
  },
  computed: {
    ...mapState(['auth']),
  },
  mounted() {
    this.initialize()
    if (this.value && !this.telValue) {
      const val = this.value.toString()
      if (val.charAt(0) !== '+') this.telValue = '+' + val
      else this.telValue = val
    }
  },
  methods: {
    telInputHandler(val, inputObject) {
      this.nationalNumber = inputObject.nationalNumber
      this.invalidFormat = inputObject.valid
      if (inputObject.number) this.$emit('input', inputObject.number)
    },
    async initialize() {
      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder()
      // eslint-disable-next-line no-undef
      const address = this.auth?.user?.team_address ?? 'Ireland'
      geocoder.geocode(
        {
          address: address,
        },
        (raw) => {
          const cities = [
            ...new Set(
              raw.map(
                (c) =>
                  c.address_components.filter((a) => {
                    return a.types.includes('country')
                  })[0]
              )
            ),
          ]
          this.defaultCountry = cities[0]?.short_name ?? 'ie'
          this.showInput = true
        }
      )
    },
  },
}
</script>
