<script>
import router from '@/router'
import { layoutComputed } from '@/state/helpers'

import NavBar from '@/components/nav-bar'
import SideBar from '@/components/side-bar'
import RightBar from '@/components/right-bar'
import Footer from '@/components/footer'
import { mapState } from 'vuex'
import { GET_TEAM } from '@/router/queries'

/**
 * Vertical layout
 */
export default {
  components: { NavBar, SideBar, RightBar, Footer },
  data() {
    return {
      type: this.$store ? this.$store.state.layout.leftSidebarType : null || null,
      width: this.$store ? this.$store.state.layout.layoutWidth : null || null,
      isMenuCondensed: false,
      stripeAlert: false,
    }
  },
  computed: {
    ...layoutComputed,
    ...mapState(['loader', 'auth']),
  },
  watch: {
    $route(to) {
      if (to.name === 'team.setup') this.checkStripe()
    },
    team() {
      this.checkStripe()
    },
  },
  created: () => {
    document.body.removeAttribute('data-layout', 'horizontal')
    document.body.removeAttribute('data-topbar', 'dark')
    document.body.removeAttribute('data-layout-size', 'boxed')
    document.body.classList.remove('auth-body-bg')
  },
  mounted() {
    if (this.loader === true) {
      document.getElementById('preloader').style.display = 'block'
      document.getElementById('status').style.display = 'block'
      setTimeout(function () {
        document.getElementById('preloader').style.display = 'none'
        document.getElementById('status').style.display = 'none'
      }, 300)
    } else {
      document.getElementById('preloader').style.display = 'none'
      document.getElementById('status').style.display = 'none'
    }
    this.checkStripe()
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle('sidebar-enable')

      if (window.innerWidth >= 1240) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable')
          document.body.classList.remove('vertical-collpsed')
        })
        document.body.classList.toggle('vertical-collpsed')
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable')
        })
        document.body.classList.remove('vertical-collpsed')
      }
      this.isMenuCondensed = !this.isMenuCondensed
    },
    toggleRightSidebar() {
      document.body.classList.toggle('right-bar-enabled')
    },
    hideRightSidebar() {
      document.body.classList.remove('right-bar-enabled')
    },
    async stripeOnboard() {
      this.$loading(true)
      const apiURL = process.env.VUE_APP_API_URL
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          team_id: this.auth.user.team_id,
          user_id: this.auth.user.id,
        }),
      }
      const response = await fetch(`${apiURL}/stripe-onboard`, requestOptions)
      const data = await response.json()
      this.$loading(false)
      window.open(data.url, 'stripeTab')
      localStorage.setItem('stripe-alert', 'false')
      console.log(data)
    },
    checkStripe() {
      const alert = localStorage.getItem('stripe-alert')
      const routeMatch = ['team.setup'].includes(this.$route.name)
      if (alert === 'false' || routeMatch) return (this.stripeAlert = false)
      if (!alert) this.$apollo.queries.team.skip = false
      if (this.team && !this.team.stripe_account_id) {
        localStorage.setItem('stripe-alert', 'true')
      }
      if (!alert || alert === 'true') this.stripeAlert = true
    },
  },
  apollo: {
    team: {
      fetchPolicy: 'no-cache',
      query: GET_TEAM,
      skip: true,
      variables() {
        return {
          id: this.auth.user.team_id,
        }
      },
    },
  },
}
</script>

<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
      </div>
    </div>
    <div id="layout-wrapper">
      <NavBar />
      <SideBar :is-condensed="isMenuCondensed" :type="leftSidebarType" :width="layoutWidth" />

      <div class="main-content">
        <div class="page-content">
          <!-- Start Content-->
          <div class="container-fluid">
            <!-- <b-alert :show="stripeAlert" variant="warning">
              {{ $t('stripe.onboard_message') }}
              <b-button type="button" variant="primary" size="sm" @click="stripeOnboard">{{
                $t('buttons.stripeOnboard')
              }}</b-button>
            </b-alert> -->
            <router-view></router-view>
          </div>
        </div>
        <Footer />
      </div>
      <RightBar />
    </div>
  </div>
</template>
