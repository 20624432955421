var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{attrs:{"id":"test"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('locations.inputs.name'),"for":"location_name"}},[_c('Address',{class:{
              'is-invalid': _vm.submitted && _vm.$v.location.name.$error,
            },attrs:{"id":"location_name","is-location-name":"","is-invalid":_vm.submitted && _vm.$v.location.name.$error},model:{value:(_vm.location.name),callback:function ($$v) {_vm.$set(_vm.location, "name", $$v)},expression:"location.name"}}),(_vm.submitted && !_vm.$v.location.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('locations.inputs.email'),"for":"location_email"}},[_c('b-form-input',{class:{
              'is-invalid': _vm.submitted && _vm.$v.location.email.$error,
            },attrs:{"id":"location_email"},model:{value:(_vm.location.email),callback:function ($$v) {_vm.$set(_vm.location, "email", $$v)},expression:"location.email"}}),(_vm.submitted && !_vm.$v.location.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('locations.inputs.phone'),"for":"location_phone"}},[_c('tel-input',{class:{ 'is-invalid': _vm.submitted && _vm.$v.location.phone.$error },attrs:{"is-invalid":_vm.submitted && _vm.$v.location.phone.$error},model:{value:(_vm.location.phone),callback:function ($$v) {_vm.$set(_vm.location, "phone", $$v)},expression:"location.phone"}}),(_vm.submitted && !_vm.$v.location.phone.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('locations.inputs.place'),"for":"location_placeId"}},[_c('b-form-input',{class:{
              'is-invalid': _vm.submitted && _vm.$v.location.place_id.$error,
            },attrs:{"id":"location_placeId"},model:{value:(_vm.location.place_id),callback:function ($$v) {_vm.$set(_vm.location, "place_id", $$v)},expression:"location.place_id"}}),(_vm.submitted && !_vm.$v.location.place_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-12 col-lg-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('locations.inputs.address'),"for":"location_address"}},[_c('b-form-input',{class:{
              'is-invalid': _vm.submitted && _vm.$v.location.address.$error,
            },attrs:{"id":"location_address"},model:{value:(_vm.location.address),callback:function ($$v) {_vm.$set(_vm.location, "address", $$v)},expression:"location.address"}}),(_vm.submitted && !_vm.$v.location.address.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-12 col-lg-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('locations.inputs.image'),"for":"fileinput"}},[_c('b-form-file',{attrs:{"id":"fileinput","state":Boolean(_vm.imageFile),"accept":"image/jpeg, image/png, image/jpg","placeholder":"Choose an image or drop it here...","drop-placeholder":"Drop file here..."},on:{"input":_vm.fileUpload},model:{value:(_vm.imageFile),callback:function ($$v) {_vm.imageFile=$$v},expression:"imageFile"}})],1)],1)]),_c('working-hours',{attrs:{"working-hours-data":_vm.location.location_working_hours,"location-id":_vm.location.id},on:{"setWorkingHours":(el) => {
          _vm.location.location_working_hours = { data: [] }
          _vm.location.location_working_hours.data = el
        }}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideSaveButton),expression:"!hideSaveButton"}]},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.$t('buttons.save')))])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }