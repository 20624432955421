<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">{{ $t('forgot_password.title') }}</h5>
                  <p>{{ $t('forgot_password.sub_title') }}</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link tag="a" to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/images/logo_gray_mini.png" alt height="34" />
                  </span>
                </div>
              </router-link>
            </div>

            <div class="p-2">
              <b-alert v-model="isResetError" class="mb-4" variant="danger" dismissible>{{
                error
              }}</b-alert>
              <div v-if="notification.message" :class="'alert ' + notification.type">
                {{ notification.message }}
              </div>
              <form @submit.prevent="tryToReset">
                <div class="mb-3">
                  <label for="user_email">{{ $t('forgot_password.email') }}</label>
                  <input
                    id="user_email"
                    v-model="email"
                    type="email"
                    class="form-control"
                    :placeholder="$t('forgot_password.email_placeholder')"
                    :class="{ 'is-invalid': submitted && $v.email.$error }"
                  />
                  <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                    <span v-if="!$v.email.required">
                      {{ $t('forgot_password.email_required') }}
                    </span>
                    <span v-if="!$v.email.email">{{ $t('forgot_password.wrong_email') }}</span>
                  </div>
                </div>
                <div class="mb-3 row mb-0">
                  <div class="col-12 text-end">
                    <button class="btn btn-primary w-md" type="submit">
                      {{ $t('forgot_password.submit') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            {{ $t('forgot_password.did_you_remember_your_password') }}
            <router-link tag="a" to="/login" class="fw-medium text-primary">{{
              $t('forgot_password.login')
            }}</router-link>
          </p>
          <p>
            © {{ new Date().getFullYear() }} {{ $t('general.brand_name') }}
            <i class="mdi mdi-heart text-danger"></i> {{ $t('general.country') }}
          </p>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from '../../layouts/auth'
import { authMethods, notificationMethods } from '@/state/helpers'
import appConfig from '@/app.config'

import { required, email } from 'vuelidate/lib/validators'

/**
 * Forgot Password component
 */
export default {
  page: {
    title: 'forgot_password',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      email: '',
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
    }
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null
    },
  },
  created() {
    this.$_loading()
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.$_loading(true)
      this.submitted = true
      // stop here if form is invalid
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$_loading(false)
        return
      }
      this.tryingToReset = true
      // Reset the authError if it existed.
      this.error = null
      return (
        this.forgetPassword({
          email: this.email,
        })
          // eslint-disable-next-line no-unused-vars
          .then((token) => {
            this.tryingToReset = false
            this.isResetError = false
            this.$_loading(false)
          })
          .catch((error) => {
            this.tryingToReset = false
            this.error = error ? error : ''
            this.isResetError = true
            this.$_loading(false)
          })
      )
    },
  },
}
</script>

<style lang="scss" module></style>
