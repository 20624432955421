var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"title":_vm.isUpdate ? _vm.$t('staff.edit_staff') : _vm.$t('staff.add_staff'),"hide-footer":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('Loader',{attrs:{"loading":_vm.user_groups === undefined}},[(_vm.user_groups)?_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"first_name"}},[_vm._v(_vm._s(_vm.$t('customerForm.name')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.first_name),expression:"staff.first_name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.staff.first_name.$error,
                  },attrs:{"id":"first_name","type":"text"},domProps:{"value":(_vm.staff.first_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.staff, "first_name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.staff.first_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"last_name"}},[_vm._v(_vm._s(_vm.$t('customerForm.surname')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.last_name),expression:"staff.last_name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.staff.last_name.$error,
                  },attrs:{"id":"last_name","type":"text"},domProps:{"value":(_vm.staff.last_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.staff, "last_name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.staff.last_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"title"}},[_vm._v(_vm._s(_vm.$t('customerForm.title')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.title),expression:"staff.title"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.staff.title.$error,
                  },attrs:{"id":"title","type":"text"},domProps:{"value":(_vm.staff.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.staff, "title", $event.target.value)}}}),(_vm.submitted && !_vm.$v.staff.title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('customerForm.phone'),"label-for":"formrow-password-input"}},[_c('tel-input',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.staff.phone.$error,
                    },attrs:{"is-invalid":_vm.submitted && _vm.$v.staff.phone.$error},model:{value:(_vm.staff.phone),callback:function ($$v) {_vm.$set(_vm.staff, "phone", $$v)},expression:"staff.phone"}}),(_vm.submitted && !_vm.$v.staff.phone.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"user_group_id"}},[_vm._v(_vm._s(_vm.$t('staff.staff_group')))]),_c('multiselect',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.staff.user_group_id.$error,
                },attrs:{"id":"user_group_id","show-labels":false,"placeholder":_vm.$t('fields.choose'),"options":_vm.user_groups.map((x) => x.id),"custom-label":(opt) => _vm.user_groups.find((x) => x.id == opt).name},model:{value:(_vm.staff.user_group_id),callback:function ($$v) {_vm.$set(_vm.staff, "user_group_id", $$v)},expression:"staff.user_group_id"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(" "+_vm._s(_vm.$t('lists.no_record'))+" ")]),_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" "+_vm._s(_vm.$t('lists.no_record'))+" ")])]),(_vm.submitted && !_vm.$v.staff.user_group_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t('customerForm.email')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.email),expression:"staff.email"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.staff.email.$error,
                  },attrs:{"id":"email","type":"email"},domProps:{"value":(_vm.staff.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.staff, "email", $event.target.value)}}}),(_vm.submitted && !_vm.$v.staff.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t('staff.color')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.color),expression:"staff.color"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.staff.color.$error,
                  },attrs:{"id":"email","type":"color"},domProps:{"value":(_vm.staff.color)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.staff, "color", $event.target.value)}}}),(_vm.submitted && !_vm.$v.staff.color.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"instagram"}},[_vm._v(_vm._s(_vm.$t('customerForm.instagram')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.instagram),expression:"staff.instagram"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.staff.instagram.$error,
                  },attrs:{"id":"instagram","type":"text","placeholder":"https://instagram.com/username"},domProps:{"value":(_vm.staff.instagram)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.staff, "instagram", $event.target.value)}}})])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"tiktok"}},[_vm._v(_vm._s(_vm.$t('customerForm.tiktok')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.tiktok),expression:"staff.tiktok"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.staff.tiktok.$error,
                  },attrs:{"id":"tiktok","type":"text","placeholder":"https://tiktok.com/username"},domProps:{"value":(_vm.staff.tiktok)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.staff, "tiktok", $event.target.value)}}})])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"facebook"}},[_vm._v(_vm._s(_vm.$t('customerForm.facebook')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.staff.facebook),expression:"staff.facebook"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.staff.facebook.$error,
                  },attrs:{"id":"facebook","type":"text","placeholder":"https://facebook.com/username"},domProps:{"value":(_vm.staff.facebook)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.staff, "facebook", $event.target.value)}}})])]),_c('div',{staticClass:"col-12"},[_c('locations-input',{attrs:{"label":_vm.$t('customerForm.works_at'),"input-data":_vm.staff.location_staffs,"is-invalid":_vm.submitted && !_vm.locationIsValid},on:{"setLocations":_vm.setLocations}})],1),_c('div',{staticClass:"col-12 mt-2"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('staff.image'),"for":"fileinput"}},[_c('b-form-file',{attrs:{"id":"fileinput","state":Boolean(_vm.imageFile),"accept":"image/jpeg, image/png, image/jpg","placeholder":"Choose an image or drop it here...","drop-placeholder":"Drop file here..."},on:{"input":_vm.fileUpload},model:{value:(_vm.imageFile),callback:function ($$v) {_vm.imageFile=$$v},expression:"imageFile"}})],1)],1),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mb-3 mt-3"},[_c('label',{attrs:{"for":"notes"}},[_vm._v(_vm._s(_vm.$t('customerForm.notes')))]),_c('b-form-textarea',{attrs:{"id":"notes","placeholder":_vm.$t('customerForm.notes_description'),"rows":"3"},model:{value:(_vm.staff.notes),callback:function ($$v) {_vm.$set(_vm.staff, "notes", $$v)},expression:"staff.notes"}})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"isActive"}},[_vm._v(_vm._s(_vm.$t('customerForm.status')))]),_c('multiselect',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.staff.user_group_id.$error,
                  },attrs:{"id":"isActive","show-labels":false,"placeholder":_vm.$t('fields.choose'),"options":_vm.valueType.map((x) => x.key),"custom-label":(opt) => _vm.valueType.find((x) => x.key == opt).name},model:{value:(_vm.staff.status),callback:function ($$v) {_vm.$set(_vm.staff, "status", $$v)},expression:"staff.status"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(" "+_vm._s(_vm.$t('lists.no_record'))+" ")]),_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" "+_vm._s(_vm.$t('lists.no_record'))+" ")])]),(_vm.submitted && !_vm.$v.staff.status.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"col-12"},[_c('b-checkbox',{attrs:{"inline":"","plain":""},model:{value:(_vm.staff.show_online),callback:function ($$v) {_vm.$set(_vm.staff, "show_online", $$v)},expression:"staff.show_online"}},[_vm._v(" "+_vm._s(_vm.$t('service.show_online'))+" ")])],1)]),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.emailError),callback:function ($$v) {_vm.emailError=$$v},expression:"emailError"}},[_vm._v(" "+_vm._s(_vm.$t('customerCard.email_registered'))+" ")]),_c('div',{staticClass:"text-end mt-3"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){_vm.showModal = false}}},[_vm._v(_vm._s(_vm.$t('buttons.close')))]),_c('b-button',{staticClass:"ms-1",attrs:{"type":"submit","variant":"success"}},[_vm._v(_vm._s(_vm.$t('buttons.save')))])],1)],1)]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }