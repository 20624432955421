<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="sort_by" class="form-select">
              <b-form-select-option :value="{ created_at: 'desc' }">{{
                $t('sort.newest')
              }}</b-form-select-option>
              <b-form-select-option :value="{ created_at: 'asc' }">{{
                $t('sort.oldest')
              }}</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto">
            <b-input
              id="inlineFormInputName2"
              v-model="search_input"
              :placeholder="$t('fields.search')"
            ></b-input>
          </div>
        </b-form>

        <button class="btn btn-success ms-auto mb-2" @click="groupModal = true">
          {{ $t('staff.group.add_group') }}
        </button>
        <b-modal v-model="groupModal" hide-footer>
          <form @submit.prevent="add">
            <b-form-group class="mb-3" :label="$t('staff.group.group_name')" for="name">
              <b-form-input
                id="name"
                v-model="group.name"
                type="text"
                :class="{
                  'is-invalid': submitted && $v.group.name.$error,
                }"
              >
              </b-form-input>
              <div v-if="submitted && !$v.group.name.required" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
            </b-form-group>
            <div>
              <b-button type="submit" variant="primary">{{ $t('buttons.save') }}</b-button>
            </div>
          </form>
        </b-modal>
      </div>
    </div>
    <div class="col-md-12 col-xs-12">
      <div class="card">
        <div class="card-body">
          <div style="overflow-x: auto">
            <table class="table">
              <thead>
                <tr>
                  <th colspan="4">{{ $t('staff.group.group_name') }}</th>
                  <th colspan="4">{{ $t('tables.created_at') }}</th>
                  <th colspan="4">{{ $t('tables.action') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(groupItem, key) in user_groups" :key="key">
                  <td colspan="4">{{ groupItem.name }}</td>
                  <td colspan="4">
                    {{ moment(groupItem.created_at).from() }}
                  </td>
                  <td colspan="4">
                    <button
                      type="button"
                      class="btn btn-warning btn-sm"
                      @click="
                        updateModal[key] = !updateModal[key]
                        $forceUpdate()
                      "
                    >
                      <i class="bx bx-edit font-size-16 align-middle me-1"></i
                      >{{ $t('buttons.edit') }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      style="margin-left: 5px"
                      @click="remove(group)"
                    >
                      <i class="bx bx-trash font-size-16 align-middle me-1"></i
                      >{{ $t('buttons.delete') }}
                    </button>
                  </td>
                  <b-modal v-model="updateModal[key]" hide-footer>
                    <form @submit.prevent="update(groupItem)">
                      <b-form-group
                        class="mb-3"
                        :label="$t('service.service_name')"
                        label-for="formrow-inputZip"
                      >
                        <b-form-input
                          id="formrow-password-input"
                          v-model="groupItem.name"
                          type="text"
                        ></b-form-input>
                      </b-form-group>
                      <div>
                        <b-button type="submit" variant="primary">{{
                          $t('buttons.save')
                        }}</b-button>
                      </div>
                    </form>
                  </b-modal>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center my-3">
      <p v-if="user_groups">
        {{ $t('customerForm.total') }} <b>{{ user_groups.length }}</b>
      </p>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import {
  GET_USER_GROUPS,
  UPDATE_USER_GROUP,
  INSERT_USER_GROUP,
  DELETE_USER_GROUP,
} from '@/router/queries'
import { mapState } from 'vuex'
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
export default {
  page: {
    title: 'staff_groups',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { PageHeader },
  data() {
    return {
      title: this.$t('staff.staff_groups'),
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      groupModal: false,
      updateModal: {},
      group: null,
      submitted: false,
      search_input: '',
      moment,
      items: [
        {
          text: this.$t('staff.staff_groups'),
          href: '/',
        },
        {
          text: this.$t('staff.groups'),
          active: true,
        },
      ],
    }
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
    totalCount() {
      return this.user_groups.length
    },
  },
  validations: {
    group: {
      name: { required },
    },
  },
  created() {
    this.$_loading()
    this.group = {
      name: null,
      user_id: this.auth.user.id,
      team_id: this.auth.user.team_id,
    }
  },
  methods: {
    add() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$loading(false)
        return
      } else {
        this.$apollo.mutate({
          mutation: INSERT_USER_GROUP,
          variables: {
            group: this.group,
          },
          update: () => {
            try {
              this.$apollo.queries.user_groups.refresh()
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$loading(false)
            } catch (e) {
              this.$_N({
                type: 'error',
                position: 'center',
              })
              this.$loading(false)
            }
          },
        })
        this.groupModal = false
      }
      this.submitted = false
    },
    update(group) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_USER_GROUP,
        variables: {
          group: this.$_ER(group, ['__typename']),
          id: group.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$loading(false)
          }
        },
      })
      this.updateModal = {}
    },
    remove(group) {
      this.$_loading(true)
      this.$apollo.mutate({
        mutation: DELETE_USER_GROUP,
        variables: {
          id: group.id,
        },
        update: () => {
          try {
            this.$apollo.queries.user_groups.refresh()
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$loading(false)
          }
        },
      })
    },
  },
  apollo: {
    user_groups: {
      fetchPolicy: 'no-cache',
      query: GET_USER_GROUPS,
      variables() {
        return {
          offset: this.offset,
          limit: this.per_page,
          order_by: this.sort_by,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.auth.user.id,
            },
            _or: [
              {
                name: this.search,
              },
            ],
          },
        }
      },
    },
  },
}
</script>
