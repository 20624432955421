<template>
  <div>
    <div class="mb-2 d-flex justify-content-end align-items-center">
      <b-button
        size="sm"
        variant="success"
        @click="
          locationModal = true
          $forceUpdate()
        "
        >Add Location</b-button
      >
      <b-modal v-model="locationModal" hide-header hide-footer>
        <b-form-group label="Locations">
          <div v-for="(location, key) in locations" :key="key" class="d-inline me-2">
            <b-form-checkbox
              v-if="!location_contacts.find((x) => x.location.id === location.id)"
              v-model="locationContacts"
              button
              :value="{
                contact_id: $route.params.id,
                location_id: location.id,
                team_id: auth.user.team_id,
              }"
              button-variant="outline-danger"
            >
              {{ location.name }}</b-form-checkbox
            >
          </div>
        </b-form-group>
        <b-button variant="success" @click="addLocationContacts">Add</b-button>
      </b-modal>
    </div>
    <div v-for="(location, key) in location_contacts" :key="key" class="mb-2">
      <div class="d-flex align-items-center">
        <span class="mb-0 mt-1 me-3"> - {{ location.location.name }} </span>
        <i
          role="button"
          class="bx bx-trash font-size-16 align-middle"
          @click="removeLocationContact(location)"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import {
  GET_LOCATION_CONTACTS,
  GET_LOCATIONS,
  DELETE_LOCATION_CONTACTS,
  ADD_LOCATION_CONTACTS,
} from '@/router/queries'

export default {
  name: 'TagsTab',
  data() {
    return {
      locationModal: false,
      locationContacts: [],
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  created() {},
  methods: {
    addLocationContacts() {
      this.$loading(true)
      this.$apollo
        .mutate({
          mutation: ADD_LOCATION_CONTACTS,
          variables: {
            locationContacts: this.locationContacts,
          },
        })
        .then(() => {
          this.locationModal = false
          this.locationContacts = []
          this.$_N({
            type: 'success',
            position: 'center',
          })
          this.$apollo.queries.location_contacts.refresh()
          this.$loading(false)
          this.$emit('refresh')
        })
        .catch(() => {
          this.locationModal = false
          this.locationContacts = []
          this.$_N({
            type: 'error',
            position: 'center',
          })
        })
    },
    removeLocationContact(location) {
      const self = this
      this.$_C({
        confirmed(result) {
          if (result) {
            self.$loading(true)
            self.$apollo.mutate({
              mutation: DELETE_LOCATION_CONTACTS,
              variables: {
                id: location.id,
              },
              update: () => {
                try {
                  self.$_N({
                    type: 'success',
                    position: 'center',
                  })
                  self.$loading(false)
                  self.$apollo.queries.location_contacts.refresh()
                  self.$apollo.queries.locations.refresh()
                  self.$emit('refresh')
                } catch (e) {
                  self.$_N({
                    type: 'error',
                    position: 'center',
                  })
                }
              },
            })
          }
        },
      })
    },
  },
  apollo: {
    locations: {
      fetchPolicy: 'no-cache',
      query: GET_LOCATIONS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            is_disable: { _eq: false },
          },
        }
      },
    },
    location_contacts: {
      query: GET_LOCATION_CONTACTS,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          where: {
            contact_id: {
              _eq: this.$route.params.id,
            },
          },
        }
      },
    },
  },
}
</script>
