<template>
  <div>
    <div v-if="vault_activities && income && expense && permission === 'administrator'">
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-md-4">
              <div class="card mini-stats-wid">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body">
                      <p class="text-muted font-weight-medium">{{ $t('case.total_revenue') }}</p>
                      <h4 class="mb-0">
                        {{
                          income.aggregate.sum.price !== null
                            ? formatPrice(income.aggregate.sum.price)
                            : formatPrice(0)
                        }}
                      </h4>
                    </div>

                    <div
                      class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                    >
                      <span class="avatar-title" style="background-color: #279b3a">
                        <i class="bx bx-down-arrow-alt font-size-24"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card mini-stats-wid">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body">
                      <p class="text-muted font-weight-medium">{{ $t('case.total_expense') }}</p>
                      <h4 class="mb-0">
                        {{
                          expense.aggregate.sum.price !== null
                            ? formatPrice(expense.aggregate.sum.price)
                            : formatPrice(0)
                        }}
                      </h4>
                    </div>

                    <div
                      class="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon"
                    >
                      <span class="avatar-title" style="background-color: #ff0000">
                        <i class="bx bx-up-arrow-alt font-size-24"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card mini-stats-wid">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body">
                      <p class="text-muted font-weight-medium">{{ $t('case.total_profit') }}</p>
                      <h4 class="mb-0">
                        {{
                          income.aggregate && expense.aggregate
                            ? formatPrice(income.aggregate.sum.price - expense.aggregate.sum.price)
                            : 'N/A'
                        }}
                      </h4>
                    </div>

                    <div
                      class="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon"
                    >
                      <span class="avatar-title">
                        <i class="bx bx-briefcase-alt-2 font-size-24"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- table -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="text-end" style="margin-top: 30px; margin-right: 30px">
              <button class="btn btn-success ms-auto mb-2" @click="showModal = true">
                <i class="bx bx-plus-medical"></i> {{ $t('case.add_transaction') }}
              </button>
            </div>
            <div class="d-flex justify-content-between" style="padding-left: 10px">
              <b-form inline class="row gy-2 gx-3 align-items-cente">
                <div class="col-sm-auto pt-1">
                  <b-form-select v-model="per_page" class="form-select">
                    <b-form-select-option :value="null">{{
                      $t('lists.per_page')
                    }}</b-form-select-option>
                    <b-form-select-option :value="1">1</b-form-select-option>
                    <b-form-select-option :value="10">10</b-form-select-option>
                    <b-form-select-option :value="25">25</b-form-select-option>
                    <b-form-select-option :value="50">50</b-form-select-option>
                    <b-form-select-option :value="100">100</b-form-select-option>
                  </b-form-select>
                </div>
                <div class="col-sm-auto pt-1">
                  <div class="dataTables_filter text-end">
                    <b-input
                      v-model="search_input"
                      type="search"
                      :placeholder="$t('buttons.search')"
                    ></b-input>
                  </div>
                </div>
                <div class="col-sm-auto pt-1">
                  <multiselect
                    v-if="locations"
                    v-model="locationFilter"
                    :placeholder="$t('calendar.location_search')"
                    :show-labels="false"
                    :options="locations.map((x) => x.id)"
                    :custom-label="(opt) => locations.find((x) => x.id === opt).name"
                    @input="
                      userFilter = false
                      filter()
                    "
                  >
                    <span slot="noResult">{{ $t('lists.no_record') }}</span>
                    <span slot="noOptions">{{ $t('lists.no_record') }}</span>
                  </multiselect>
                </div>
                <div class="col-sm-auto pt-1">
                  <b-form-select v-model="date" class="form-select">
                    <b-form-select-option value="month">{{
                      $t('lists.date_range.this_month')
                    }}</b-form-select-option>
                    <b-form-select-option value="today">{{
                      $t('lists.date_range.today')
                    }}</b-form-select-option>
                    <b-form-select-option value="yesterday">{{
                      $t('lists.date_range.yesterday')
                    }}</b-form-select-option>
                    <b-form-select-option value="last_month">{{
                      $t('lists.date_range.last_month')
                    }}</b-form-select-option>
                    <b-form-select-option value="week">{{
                      $t('lists.date_range.this_week')
                    }}</b-form-select-option>
                    <b-form-select-option value="last_week">{{
                      $t('lists.date_range.last_week')
                    }}</b-form-select-option>
                    <b-form-select-option value="year">{{
                      $t('lists.date_range.this_year')
                    }}</b-form-select-option>
                    <b-form-select-option value="last_year">{{
                      $t('lists.date_range.last_year')
                    }}</b-form-select-option>
                    <b-form-select-option value="custom">{{
                      $t('lists.date_range.custom')
                    }}</b-form-select-option>
                  </b-form-select>
                </div>
                <div class="col-sm-auto pt-1">
                  <b-form-select v-model="type" class="form-select">
                    <b-form-select-option :value="false">{{ $t('case.all') }}</b-form-select-option>
                    <b-form-select-option value="income">{{
                      $t('case.income')
                    }}</b-form-select-option>
                    <b-form-select-option value="expense">{{
                      $t('case.expense')
                    }}</b-form-select-option>
                  </b-form-select>
                </div>
                <div class="col-sm-auto pt-1">
                  <b-form-select v-model="boolenType" class="form-select">
                    <b-form-select-option :value="undefined">{{
                      $t('case.all')
                    }}</b-form-select-option>
                    <b-form-select-option :value="true">{{
                      $t('case.archived')
                    }}</b-form-select-option>
                    <b-form-select-option :value="false">{{
                      $t('case.not_archived')
                    }}</b-form-select-option>
                  </b-form-select>
                </div>
                <div v-if="date === 'custom'" class="col-sm-auto pt-1">
                  <date-picker
                    v-model="start"
                    value-type="YYYY-MM-DD"
                    format="DD/MM/YYYY"
                    :first-day-of-week="1"
                    lang="en"
                  ></date-picker>
                </div>
                <div v-if="date === 'custom'" class="col-sm-auto pt-1">
                  <date-picker
                    v-model="end"
                    value-type="YYYY-MM-DD"
                    format="DD/MM/YYYY"
                    :first-day-of-week="1"
                    lang="en"
                  ></date-picker>
                </div>
              </b-form>
            </div>
            <div class="card-body">
              <b-tabs content-class="p-3" nav-class="nav-tabs-custom">
                <b-tab active>
                  <div id="tickets-table_filter" class="dataTables_filter text-end"></div>
                  <invoice-modal
                    :add-invoice-modal="showModal"
                    :locations-prop="locations"
                    @close="
                      showModal = false
                      $apollo.queries.vault_activities.refresh()
                      $apollo.queries.expense.refresh()
                      $apollo.queries.income.refresh()
                      $apollo.queries.locations.refresh()
                    "
                  />
                  <b-row>
                    <b-col class="text-end mb-2 mt-2">
                      <b-checkbox
                        v-for="field in fields"
                        :key="field.key"
                        v-model="field.visible"
                        :disabled="visibleFields.length == 1 && field.visible"
                        inline
                        plain
                      >
                        {{ field.label }}
                      </b-checkbox>
                    </b-col>
                  </b-row>
                  <div class="table-responsive mt-3 mb-0">
                    <b-table
                      :items="vault_activities"
                      :fields="visibleFields"
                      responsive="sm"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="search_input"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template #cell(voucher_date)="data">
                        {{ moment(data.value).format('LLL') }}
                      </template>
                      <template #cell(created_at)="data">
                        {{ moment(data.value).format('Do MMMM YYYY, h:mm') }}
                      </template>
                      <template #cell(price)="data">
                        {{ formatPrice(data.value) }}
                      </template>
                      <template #cell(payment_method)="data">
                        {{ $t(`payment_method.${data.value}`) }}
                      </template>
                      <template #cell(type)="data">
                        {{ $t(`case.${data.value}`) }}
                      </template>
                      <template #cell(name)="row">
                        {{
                          row.item.contact_id !== null
                            ? row.item.contact?.first_name + ' ' + row.item.contact?.last_name
                            : $t('case.till')
                        }}
                      </template>
                      <template #cell(location)="{ item }">
                        {{ item.event ? item.event.location.name : item.location?.name }}
                      </template>
                      <template #cell(userName)="row">
                        <span
                          v-b-tooltip.hover
                          :title="row.item.user?.first_name + row.item.user?.last_name"
                          >{{ row.item.user?.first_name + ' ' + row.item.user?.last_name }}</span
                        >
                      </template>
                      <template #cell(actions)="row">
                        <button
                          v-if="row.item.deleted_at === null"
                          type="button"
                          class="btn btn-danger btn-sm waves-effect waves-light"
                          @change="row"
                          @click="remove(row.item)"
                        >
                          <i class="bx bx-minus-circle font-size-16 align-middle me-1"></i
                          >{{ $t('case.archive') }}
                        </button>
                        <button
                          v-if="row.item.deleted_at !== null"
                          type="button"
                          class="btn btn-info btn-sm waves-effect waves-light"
                          @change="row"
                          @click="remove(row.item)"
                        >
                          {{ $t('case.undo_from_archive') }}
                        </button>
                      </template>
                    </b-table>
                  </div>
                  <div
                    v-if="
                      vault_activities_aggregate.aggregate &&
                      vault_activities_aggregate.aggregate.count
                    "
                    class="row"
                  >
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="vault_activities_aggregate.aggregate.count"
                            :per-page="per_page"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="permission != 'administrator'" class="container">
      <!-- end row -->
      <div class="row justify-content-center account-pages my-5 pt-sm-5">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card">
            <div class="card-body">
              <div class="p-2">
                <div class="text-center">
                  <div class="avatar-md mx-auto">
                    <div class="avatar-title rounded-circle bg-light">
                      <i class="bx bx-error-alt h1 mb-0 text-warning"></i>
                    </div>
                  </div>
                  <div class="p-2 mt-4">
                    <h4>{{ $t('permission.check') }}</h4>
                    <p class="text-muted">
                      {{ $t('permission.not_authorized_text') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/tr'
import invoiceModal from '@/components/widgets/invoice'

import {
  GET_VAULT_ACTIVITIES_AGGREGATE,
  GET_VAULT_ACTIVITIES,
  UPDATE_VAULT_ACTIVITY,
  VAULT_INCOME,
  VAULT_EXPENSE,
  UPDATE_PAYMENT,
  GET_PERMISSION,
  GET_LOCATIONS,
} from '@/router/queries'
export default {
  page: {
    title: 'accounting',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },

  components: {
    PageHeader,
    invoiceModal,
    DatePicker,
  },

  data() {
    return {
      title: this.$t('case.accounting'),
      items: [
        {
          text: this.$t('case.accounting'),
          href: '/',
        },
        {
          text: this.$t('case.detail'),
          active: true,
        },
      ],
      moment,
      submitted: false,
      showModal: false,
      activities: null,
      addInvoiceCheck: false,
      currentPage: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      search_input: null,
      type: false,
      date: 'month',
      sortBy: 'value',
      sortDesc: false,
      fields: [
        {
          key: 'userName',
          sortable: true,
          label: this.$t('case.user'),
          visible: false,
        },
        {
          key: 'voucher_date',
          label: this.$t('case.date'),
          sortable: true,
          visible: true,
        },
        {
          key: 'name',
          sortable: true,
          label: this.$t('case.customer'),
          visible: true,
        },
        {
          key: 'location',
          sortable: true,
          label: this.$t('case.location'),
          visible: true,
        },
        {
          key: 'description',
          sortable: true,
          label: this.$t('case.description'),
          visible: true,
        },
        {
          key: 'price',
          sortable: true,
          label: this.$t('case.price'),
          visible: true,
        },
        {
          key: 'payment_method',
          sortable: true,
          label: this.$t('case.payment_method'),
          visible: true,
        },
        {
          key: 'type',
          sortable: false,
          label: this.$t('case.type'),
          visible: true,
        },
        {
          label: this.$t('case.actions'),
          key: 'actions',
          visible: true,
        },
      ],
      types: [
        { text: this.$t('case.income'), value: 'income' },
        { text: this.$t('case.expense'), value: 'expense' },
      ],
      sort_by: { created_at: 'desc' },
      per_page: 10,
      boolenType: true,
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      locationFilter: '0',
      staff_locations: undefined,
    }
  },

  validations: {
    activities: {
      voucher_date: { required },
      price: { required },
      description: { required },
      price_type: { required },
      payment_method: { required },
      type: { required },
    },
  },

  computed: {
    ...mapState(['auth']),
    // eslint-disable-next-line vue/return-in-computed-property
    permission() {
      if (this.user_permissions) {
        const permission_name = this.user_permissions.length
          ? this.user_permissions.find(
              (x) => x.permission_id === 'd441bef4-e019-4346-ba96-0ded106da760'
            ).name
          : null
        return permission_name
      } else {
        return null
      }
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible)
    },
    offset() {
      return (this.currentPage - 1) * this.per_page
    },
    rows() {
      return this.vault_activities.length
    },
    // eslint-disable-next-line vue/return-in-computed-property
    request() {
      const date = new Date()
      if (this.date === 'today') {
        const _and = [
          { created_at: { _gte: moment().format('YYYY-MM-DD') } },
          {
            created_at: {
              _lte: moment().add(1, 'days').format('YYYY-MM-DD'),
            },
          },
        ]
        return _and
      }
      if (this.date === 'yesterday') {
        const _and = [
          {
            created_at: {
              _gte: moment().add(-1, 'days').format('YYYY-MM-DD'),
            },
          },
          { created_at: { _lte: moment().format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'month') {
        const _and = [
          {
            created_at: {
              _gte: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD'),
            },
          },
          {
            created_at: {
              _lte: moment().add(1, 'days').format('YYYY-MM-DD'),
            },
          },
        ]
        return _and
      }
      if (this.date === 'last_month') {
        const _and = [
          {
            created_at: {
              _gte: moment().month(date.getMonth()).date(1).add(-1, 'month').format('YYYY-MM-DD'),
            },
          },
          {
            created_at: {
              _lte: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD'),
            },
          },
        ]
        return _and
      }
      if (this.date === 'week') {
        const _and = [
          {
            created_at: {
              _gte: moment().day(1).format('YYYY-MM-DD'),
            },
          },
          {
            created_at: {
              _lte: moment().add(1, 'days').format('YYYY-MM-DD'),
            },
          },
        ]
        return _and
      }
      if (this.date === 'last_week') {
        const _and = [
          {
            created_at: {
              _gte: moment().day(1).add(-7, 'days').format('YYYY-MM-DD'),
            },
          },
          {
            created_at: {
              _lte: moment().day(1).format('YYYY-MM-DD'),
            },
          },
        ]
        return _and
      }
      if (this.date === 'year') {
        const _and = [
          {
            created_at: {
              _gte: moment().month(0).date(1).format('YYYY-MM-DD'),
            },
          },
          {
            created_at: {
              _lte: moment().add(1, 'days').format('YYYY-MM-DD'),
            },
          },
        ]
        return _and
      }
      if (this.date === 'last_year') {
        const _and = [
          {
            created_at: {
              _gte: moment().month(0).date(1).add(-1, 'year').format('YYYY-MM-DD'),
            },
          },
          {
            created_at: {
              _lte: moment().month(0).date(1).format('YYYY-MM-DD'),
            },
          },
        ]
        return _and
      }
      if (this.date === 'custom') {
        const _and = [
          { created_at: { _gte: moment(this.start).format('YYYY-MM-DD') } },
          {
            created_at: {
              _lte: moment(this.end).add(1, 'days').format('YYYY-MM-DD'),
            },
          },
        ]
        return _and
      }
    },
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
  },
  watch: {
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  created() {
    this.$_loading()
    this.$apollo.queries.vault_activities.start()
    this.activities = {
      price: null,
      type: null,
      voucher_date: moment().format('YYYY-MM-DD HH:mm'),
      price_type: null,
      description: null,
      invoice_id: null,
      contact_id: null,
      user_id: this.auth.user.id,
      team_id: this.auth.user.team_id,
    }
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    remove(item) {
      if (item.deleted_at === null) {
        const date = moment().format('YYYY-MM-DD HH:mm')
        const self = this
        this.$_C({
          text: this.$t('case.archive'),
          title: this.$t('case.warning.archive_title'),
          confirmed(result) {
            if (result) {
              if (item.bundle_payment_id !== null) {
                self.$_C({
                  text: this.$t('case.archive'),
                  title: this.$t('case.warning.has_bundle_payment'),
                  confirmed(result) {
                    if (result) {
                      self.$loading(true)
                      self.$apollo.mutate({
                        mutation: UPDATE_PAYMENT,
                        variables: {
                          id: item.bundle_payment_id,
                          payment: {
                            status: 'PENDING',
                          },
                        },
                        update: () => {
                          self.$apollo.mutate({
                            mutation: UPDATE_VAULT_ACTIVITY,
                            variables: {
                              id: item.id,
                              vault: {
                                deleted_at: date,
                                deleted_by: self.auth.user.id,
                              },
                            },
                            update: () => {
                              self.$apollo.queries.income.refresh()
                              self.$apollo.queries.expense.refresh()
                              self.$apollo.queries.vault_activities.refresh()
                              self.$_N({
                                type: 'success',
                                position: 'center',
                              })
                              self.$loading(false)
                            },
                          })
                        },
                      })
                    }
                  },
                })
              } else {
                self.$loading(true)
                self.$apollo.mutate({
                  mutation: UPDATE_VAULT_ACTIVITY,
                  variables: {
                    id: item.id,
                    vault: {
                      deleted_at: date,
                      deleted_by: self.auth.user.id,
                    },
                  },
                  update: () => {
                    try {
                      self.$apollo.queries.income.refresh()
                      self.$apollo.queries.expense.refresh()
                      self.$apollo.queries.vault_activities.refresh()
                      self.$_N({
                        type: 'success',
                        position: 'center',
                      })
                      self.$loading(false)
                    } catch (e) {
                      self.$_N({
                        type: 'error',
                        position: 'center',
                      })
                    }
                  },
                })
              }
            }
          },
        })
      } else {
        const self = this
        this.$_C({
          text: this.$t('case.warning.undo'),
          title: this.$t('case.warning.undo_title'),
          confirmed(result) {
            if (result) {
              if (item.bundle_payment_id !== null) {
                self.$_C({
                  text: this.$t('case.warning.undo'),
                  title: this.$t('case.warning.has_bundle_payment_undo'),
                  confirmed(result) {
                    if (result) {
                      self.$loading(true)
                      self.$apollo.mutate({
                        mutation: UPDATE_PAYMENT,
                        variables: {
                          id: item.bundle_payment_id,
                          payment: {
                            status: 'DONE',
                          },
                        },
                        update: () => {
                          self.$apollo.mutate({
                            mutation: UPDATE_VAULT_ACTIVITY,
                            variables: {
                              id: item.id,
                              vault: {
                                deleted_at: null,
                                deleted_by: null,
                              },
                            },
                            update: () => {
                              self.$apollo.queries.income.refresh()
                              self.$apollo.queries.expense.refresh()
                              self.$apollo.queries.vault_activities.refresh()
                              self.$_N({
                                type: 'success',
                                position: 'center',
                              })
                              self.$loading(false)
                            },
                          })
                        },
                      })
                    }
                  },
                })
              } else {
                self.$loading(true)
                self.$apollo.mutate({
                  mutation: UPDATE_VAULT_ACTIVITY,
                  variables: {
                    id: item.id,
                    vault: {
                      deleted_at: null,
                      deleted_by: null,
                    },
                  },
                  update: () => {
                    try {
                      self.$apollo.queries.income.refresh()
                      self.$apollo.queries.expense.refresh()
                      self.$apollo.queries.vault_activities.refresh()
                      self.$_N({
                        type: 'success',
                        position: 'center',
                      })
                      self.$loading(false)
                    } catch (e) {
                      self.$_N({
                        type: 'error',
                        position: 'center',
                      })
                    }
                  },
                })
              }
            }
          },
        })
      }
    },
  },

  apollo: {
    vault_activities_aggregate: {
      fetchPolicy: 'no-cache',
      query: GET_VAULT_ACTIVITIES_AGGREGATE,
      variables() {
        return {
          where: {
            deleted_by: { _is_null: true },
            _or: [
              {
                event: {
                  location_id:
                    this.locationFilter && this.locationFilter !== '0'
                      ? { _eq: this.locationFilter }
                      : {},
                },
              },
              {
                location_id:
                  this.locationFilter && this.locationFilter !== '0'
                    ? { _eq: this.locationFilter }
                    : {},
              },
            ],
          },
        }
      },
    },
    vault_activities: {
      fetchPolicy: 'no-cache',
      query: GET_VAULT_ACTIVITIES,
      variables() {
        return {
          limit: this.per_page,
          offset: this.offset,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            type: {
              _eq: this.type || undefined,
            },
            deleted_by: {
              _is_null: this.boolenType,
            },
            _or: [
              {
                event: {
                  location_id:
                    this.locationFilter && this.locationFilter !== '0'
                      ? { _eq: this.locationFilter }
                      : {},
                },
              },
              {
                location_id:
                  this.locationFilter && this.locationFilter !== '0'
                    ? { _eq: this.locationFilter }
                    : {},
              },
            ],
            _and: this.request,
          },
        }
      },
    },
    income: {
      fetchPolicy: 'no-cache',
      query: VAULT_INCOME,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            type: {
              _eq: 'income',
            },
            _or: [
              {
                event: {
                  location_id:
                    this.locationFilter && this.locationFilter !== '0'
                      ? { _eq: this.locationFilter }
                      : {},
                },
              },
              {
                location_id:
                  this.locationFilter && this.locationFilter !== '0'
                    ? { _eq: this.locationFilter }
                    : {},
              },
            ],
            deleted_by: {
              _is_null: this.boolenType,
            },
            _and: this.request,
          },
        }
      },
    },
    expense: {
      fetchPolicy: 'no-cache',
      query: VAULT_EXPENSE,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            type: {
              _eq: 'expense',
            },
            _or: [
              {
                event: {
                  location_id:
                    this.locationFilter && this.locationFilter !== '0'
                      ? { _eq: this.locationFilter }
                      : {},
                },
              },
              {
                location_id:
                  this.locationFilter && this.locationFilter !== '0'
                    ? { _eq: this.locationFilter }
                    : {},
              },
            ],
            deleted_by: {
              _is_null: this.boolenType,
            },
            _and: this.request,
          },
        }
      },
    },
    locations: {
      fetchPolicy: 'no-cache',
      query: GET_LOCATIONS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            is_disable: { _eq: false },
            id: {
              _in: this.staff_locations,
            },
          },
        }
      },
      update({ locations }) {
        return [{ name: 'All Locations', id: '0' }, ...locations]
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
