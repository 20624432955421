<template>
  <div>
    <PageHeader :title="title" />
    <div v-if="activities_aggregate" class="row">
      <div class="col-lg-12">
        <div v-if="activities_aggregate.aggregate.count > 0" class="card">
          <div class="card-body">
            <h4 class="card-title mb-3"></h4>
            <div class="mb-3">
              <ul class="verti-timeline list-unstyled">
                <li v-for="activity in activities" :key="activity.id" class="event-list">
                  <div class="event-timeline-dot">
                    <i class="bx bx-right-arrow-circle"></i>
                  </div>
                  <div class="media">
                    <div class="me-3">
                      <i class="bx bx-copy-alt h2 text-primary"></i>
                    </div>
                    <div class="media-body">
                      <div>
                        <!--<h5>{{ activity.name }}</h5>-->
                        <h5 class="text-dark font-size-16">
                          <span>
                            {{ setActivityTitle(activity) }}
                          </span>
                          <span class="text-muted mx-2 font-size-11">
                            {{ setNotificationDate(activity.created_at) }}
                          </span>
                        </h5>
                        <div class="font-size-13 text-muted">
                          <div v-if="activity.events" class="mb-2">
                            <p class="mb-2">
                              {{
                                activity.events.contact.first_name +
                                ' ' +
                                activity.events.contact.last_name
                              }}
                            </p>
                            <p
                              v-for="(service, index) in activity.events.event_services"
                              :key="index"
                              class="mb-2"
                            >
                              {{
                                service.service.name +
                                ' - ' +
                                service.user.first_name +
                                ' ' +
                                service.user.last_name
                              }}
                            </p>
                            <p class="mb-2">
                              {{ activity.events.location.name }}
                            </p>
                            <p class="mb-2">
                              {{ setEventTime(activity.events) }}
                            </p>
                            <p class="mb-2">
                              {{ setEventDate(activity.events) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div
              v-show="activities_aggregate.aggregate.count > offset * 15"
              class="row justify-content-center"
            >
              <div class="col-8 my-3">
                <button class="btn btn-outline-success w-100" @click="showMore">
                  {{ $t('activity.more_activities') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <h5 v-else class="mb-3">
          {{ $t('activity.no_activities') }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import moment from 'moment'
import { GET_ACTIVITIES, GET_ACTIVITIES_AGGREGATE, GET_PERMISSION } from '@/router/queries'
export default {
  name: 'Personal',
  page: {
    title: 'activities',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { PageHeader },
  data() {
    return {
      title: this.$t('menuitems.general.list.activities'),
      moment,
      offset: 0,
      activities: [],
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  watch: {
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  methods: {
    setNotificationDate(date) {
      const format = 'YYYY-MM-DDTHH:mm:ssz'
      return moment(date, format).format('D MMM HH:mm')
    },
    setActivityTitle(activity) {
      return this.$t(`activity.${activity.activity_types.code}`)
    },
    setActivityDetails(activity) {
      if (!activity.events) return ''
      return ` <br>
      ${activity.events.location.name}`
    },
    setEventTime(event) {
      const format = 'YYYY-MM-DDTHH:mm:ssz'
      return (
        moment(event.start, format).format('HH:mm') +
        '-' +
        moment(event.end, format).format('HH:mm') +
        ' / ' +
        moment(event.start, format).format('dddd')
      )
    },
    setEventDate(event) {
      const format = 'YYYY-MM-DDTHH:mm:ssz'
      return moment(event.start, format).format('DD MMMM YYYY')
    },
    showMore() {
      this.offset = (this.offset + 1) * 15
    },
  },
  apollo: {
    activitiesList: {
      fetchPolicy: 'no-cache',
      query: GET_ACTIVITIES,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            location_id: {
              _eq: this.staff_locations,
            },
          },
          offset: this.offset,
        }
      },
      update(res) {
        console.log('res :>> ', res)
        this.activities.push(...res.activities)
        console.log('this.activities :>> ', this.activities)
      },
    },
    activities_aggregate: {
      fetchPolicy: 'no-cache',
      query: GET_ACTIVITIES_AGGREGATE,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            location_id: {
              _eq: this.staff_locations,
            },
          },
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
