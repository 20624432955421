<template>
  <div v-if="stripe_accounts">
    <PageHeader :title="title" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="sort_by" class="form-select">
              <b-form-select-option :value="{ created_at: 'desc' }">{{
                $t('sort.newest')
              }}</b-form-select-option>
              <b-form-select-option :value="{ created_at: 'asc' }">{{
                $t('sort.oldest')
              }}</b-form-select-option>
            </b-form-select>
          </div>
        </b-form>

        <button class="btn btn-success ms-auto mb-2" @click="stripeOnboard">
          Create New Account
        </button>
      </div>
    </div>

    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <div class="col-md-12 col-xs-12">
          <div class="card p-2 table-responsive">
            <table class="table mb-0 table-striped">
              <thead class="thead-light">
                <tr>
                  <th>Account ID</th>
                  <th>{{ $t('tables.created_at') }}</th>
                  <th>{{ $t('tables.action') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(accItem, key) in stripe_accounts" :key="key">
                  <td>{{ accItem.account_id }}</td>
                  <td>
                    {{ moment(accItem.created_at).from() }}
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      @click="$router.push('/stripe/' + accItem.account_id)"
                    >
                      {{ $t('buttons.view') }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-alert :show="stripe_accounts.length === 0" variant="light" class="text-center my-5">
          <p class="mb-0"><i class="mdi mdi-rocket-outline display-4"></i></p>
          Sorry, no records found
        </b-alert>

        <div class="text-center my-3">
          <p>
            Total: <b>{{ stripe_accounts.length }}</b>
          </p>
        </div>
      </div>
      <!-- end col-->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import moment from 'moment'
import { GET_STRIPE_ACCOUNTS } from '@/router/queries'

export default {
  name: 'StripeAccounts',
  page: {
    title: 'stripe',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { PageHeader },
  data() {
    return {
      title: 'Stripe Accounts',
      countries: [],
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      moment,
    }
  },
  computed: {
    ...mapState(['auth']),
    ...mapGetters({
      companyCurrency: 'currency/companyCurrency',
    }),
    offset() {
      return (this.page - 1) * this.per_page
    },
  },
  created() {
    this.$_loading()
  },
  methods: {
    async stripeOnboard() {
      this.$loading(true)
      const apiURL = process.env.VUE_APP_API_URL
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          team_id: this.auth.user.team_id,
          user_id: this.auth.user.id,
          default_currency: this.companyCurrency,
        }),
      }
      const response = await fetch(`${apiURL}/stripe-onboard`, requestOptions)
      const data = await response.json()
      this.$loading(false)
      window.open(data.url, '_self')
    },
  },
  apollo: {
    stripe_accounts: {
      fetchPolicy: 'no-cache',
      query: GET_STRIPE_ACCOUNTS,
      variables() {
        return {
          limit: this.per_page,
          offset: this.offset,
          order_by: this.sort_by,
          where: {},
        }
      },
    },
  },
}
</script>
