<template>
  <div>
    <div class="row">
      <div class="col-md-4 col-xs-12 mb-3">
        <b-form-group class="mb-2" :label="$t('form.settings.guidance')" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="form.redirect"
            type="text"
            placeholder="https://barespace.app"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-4 col-xs-12 mb-3">
        <b-form-group class="mb-2" label="CSS" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="form.css_class"
            type="text"
            placeholder=".."
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-4 col-xs-12 mb-3">
        <b-form-group class="mb-2" :label="$t('form.settings.button_name')" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="form.submit_text"
            type="text"
            placeholder="ör: Gönder"
          ></b-form-input>
        </b-form-group>
      </div>
      <!--      <div class="col-md-4 col-xs-12 mb-3">
        <b-form-group
            class="mb-2"
            label="Bildirim"
            description="Form doldurulduktan sonra kişilere bildirim at"
            label-for="input-1"
        >
          <multiselect
              v-model="form.notify_recipients"
              tag-placeholder="Add this as new tag"
              placeholder="Kişiler"
              label="name" track-by="id"
              :options="users.map(x => { return { name: x.first_name + ' ' + x.last_name, id: x.id}})"
              :multiple="true"
              :taggable="true"
          ></multiselect>
        </b-form-group>
      </div>-->
      <div class="col-md-4 col-xs-12 mb-3">
        <b-form-group
          class="mb-2"
          :label="$t('form.settings.message')"
          :description="$t('form.settings.message_desc')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.inline_message"
            type="text"
            :description="$t('form.settings.message_example')"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <!--      <div class="col-md-3">
        <b-form-group
            class="mb-2"
            label="Hatırlama"
            description="Daha önceden form dolduran kullanıcının bilgilerini otamatik getir"
            label-for="input-1"
        >
          <div class="form-check form-switch form-switch-md mb-3">
            <input class="form-check-input" v-model="form.ignore_current_values " type="checkbox" id="flexSwitchCheckDefault">
            <label class="form-check-label">{{ form.ignore_current_values === true ? $t('form.questions.active') : $t('form.questions.passive') }}</label>
          </div>
        </b-form-group>
      </div>-->
      <div class="col-md-3">
        <b-form-group class="mb-2" :label="$t('form.settings.not_robot')" label-for="input-1">
          <div class="form-check form-switch form-switch-md mb-3">
            <input
              id="flexSwitchCheckDefault"
              v-model="form.captcha_enabled"
              class="form-check-input"
              type="checkbox"
            />
            <label class="form-check-label">{{
              form.captcha_enabled === true
                ? $t('form.questions.active')
                : $t('form.questions.passive')
            }}</label>
          </div>
        </b-form-group>
      </div>
    </div>
    <b-button variant="success" class="mt-3" @click="update">{{ $t('buttons.save') }}</b-button>
  </div>
</template>

<script>
import { GET_FORM, UPDATE_FORM, GET_USER_AGENDA } from '@/router/queries'
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  name: 'Settings',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Multiselect,
  },
  data() {
    return {
      moment,
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  created() {
    this.$_loading()
  },
  apollo: {
    form: {
      fetchPolicy: 'no-cache',
      query: GET_FORM,
      variables() {
        return {
          id: this.$route.params.key,
        }
      },
    },
    users: {
      fetchPolicy: 'no-cache',
      query: GET_USER_AGENDA,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
          },
        }
      },
    },
  },
  methods: {
    update() {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_FORM,
        variables: {
          id: this.$route.params.key,
          form: this.$_ER(this.form, ['__typename']),
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
      this.updateModal = false
    },
  },
}
</script>
