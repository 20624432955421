<template>
  <div>
    <multiselect
      v-model="inputValue"
      :show-labels="false"
      :placeholder="$t('buttons.placeHolderSelect')"
      :options="timezones"
      :multiple="false"
    >
      <span slot="noResult">
        {{ $t('lists.no_record') }}
      </span>
      <span slot="noOptions">
        {{ $t('lists.no_record') }}
      </span>
    </multiselect>
  </div>
</template>

<script>
import momentTz from 'moment-timezone'
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },
  props: {
    value: {
      default: null,
    },
  },
  data: () => ({
    inputValue: null,
  }),
  computed: {
    timezones() {
      return momentTz.tz.names()
    },
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.inputValue = newVal
      }
    },
    inputValue(newVal) {
      this.$emit('input', newVal)
    },
  },
  mounted() {
    if (this.value && !this.inputValue) {
      this.inputValue = this.value
    }
  },
}
</script>
