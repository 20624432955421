<template>
  <div>
    <div>
      <b-button variant="success" @click="createModal = true">{{
        $t('form.add_question')
      }}</b-button>
      <b-modal v-model="createModal" :title="$t('form.add_question')" hide-footer>
        <div>
          <b-tabs pills card>
            <b-tab :title="$t('form.questions.question')">
              <b-form-group
                class="mb-2"
                :label="$t('form.questions.question_desc')"
                :description="$t('form.questions.add_question_desc')"
                label-for="input-1"
              >
                <b-form-input id="input-1" v-model="question.name" type="text"></b-form-input>
              </b-form-group>
              <label>{{ $t('form.questions.select_question') }}</label>
              <multiselect
                v-model="question.field_type_id"
                :placeholder="$t('form.questions.question_type')"
                class="mb-2"
                :show-labels="false"
                :options="form_field_types.map((x) => x.id)"
                :custom-label="
                  (opt) => {
                    return $t(`form_field_types.${form_field_types.find((x) => x.id == opt).name}`)
                  }
                "
              >
              </multiselect>
              <div v-for="(type, key) in selectedType.properties" :key="key">
                <b-form-group
                  v-if="type.key === 'label'"
                  class="mb-2"
                  :label="type.label"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="question.label"
                    type="text"
                    :placeholder="type.label"
                    required
                  ></b-form-input>
                </b-form-group>
                <!--                <b-form-group
                    class="mb-2"
                    v-if="type.key === 'placeholder'"
                    :label="type.label"
                    label-for="input-1"
                >
                  <b-form-input
                      id="input-1"
                      v-model="question.placeholder"
                      type="text"
                      :placeholder="type.label"
                      required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                    class="mb-2"
                    v-if="type.key === 'description'"
                    :label="type.label"
                    label-for="input-1"
                >
                  <b-form-input
                      id="input-1"
                      v-model="question.description"
                      type="text"
                      :placeholder="type.label"
                      :description="type.label"
                      required
                  ></b-form-input>
                </b-form-group>-->
                <div
                  v-if="
                    type.input_type === 'select' ||
                    type.input_type === 'checkbox' ||
                    type.input_type === 'radio'
                  "
                  class="border p-2"
                >
                  <b-button variant="success" @click="addOption">{{
                    $t('form.question.add_option')
                  }}</b-button>
                  <div
                    v-for="(option, optionKey) in question.options"
                    :key="optionKey"
                    class="row p-2"
                  >
                    <div class="col-md-10">
                      <b-form-input
                        id="input-1"
                        v-model="option.value"
                        :placeholder="option.placeholder"
                        type="text"
                      ></b-form-input>
                    </div>
                    <div class="col-md-2">
                      <b-button variant="danger" @click="question.options.splice(optionKey, 1)">
                        <i class="bx bxs-trash-alt"></i>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </b-tab>
            <b-tab :title="$t('form.settings.settings')">
              <div>
                <div class="row">
                  <div class="col-md-3 col-xs-12">
                    <b-form-group
                      class="mb-2"
                      :label="$t('form.questions.status')"
                      label-for="input-1"
                    >
                      <div class="form-check form-switch form-switch-md mb-3">
                        <input
                          id="flexSwitchCheckDefault"
                          v-model="question.enabled"
                          class="form-check-input"
                          type="checkbox"
                        />
                        <label class="form-check-label">{{
                          question.enabled === true
                            ? $t('form.questions.active')
                            : $t('form.questions.passive')
                        }}</label>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <b-form-group
                      class="mb-2"
                      :label="$t('form.questions.required_field')"
                      label-for="input-1"
                    >
                      <div class="form-check form-switch form-switch-md mb-3">
                        <input
                          id="flexSwitchCheckDefault"
                          v-model="question.required"
                          class="form-check-input"
                          type="checkbox"
                        />
                        <label class="form-check-label">{{
                          question.required === true
                            ? $t('form.questions.active')
                            : $t('form.questions.passive')
                        }}</label>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <b-form-group
                      class="mb-2"
                      :label="$t('form.questions.hide')"
                      label-for="input-1"
                    >
                      <div class="form-check form-switch form-switch-md mb-3">
                        <input
                          id="flexSwitchCheckDefault"
                          v-model="question.hidden"
                          class="form-check-input"
                          type="checkbox"
                        />
                        <label class="form-check-label">{{
                          question.hidden === true
                            ? $t('form.questions.active')
                            : $t('form.questions.passive')
                        }}</label>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-3 col-xs-12">
                    <b-form-group
                      class="mb-2"
                      :label="$t('form.questions.hide_label')"
                      label-for="input-1"
                    >
                      <div class="form-check form-switch form-switch-md mb-3">
                        <input
                          id="flexSwitchCheckDefault"
                          v-model="question.label_hidden"
                          class="form-check-input"
                          type="checkbox"
                        />
                        <label class="form-check-label">{{
                          question.label_hidden === true
                            ? $t('form.questions.active')
                            : $t('form.questions.passive')
                        }}</label>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-2"
                      :label="$t('form.questions.arrangement')"
                      label-for="input-1"
                      :description="$t('form.questions.do_arrangement')"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="question.display_order"
                        type="number"
                        placeholder="..."
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
          <b-button variant="success" @click="save()">{{ $t('buttons.save') }}</b-button>
        </div>
      </b-modal>
    </div>
    <div>
      <div class="row justify-content-center">
        <div
          v-if="form_questions.length"
          class="col-md-6 col-xs-12"
          style="background-color: #eff2f7; padding: 10px 25px"
        >
          <div
            v-for="(questionItem, questionKey) in form_questions"
            :key="questionKey"
            class="justify-content-center"
            style="margin-top: 25px"
          >
            <div class="row">
              <div class="col-md-8 col-xs-12">
                <b-form-group
                  v-if="questionItem.type === 'text'"
                  :description="questionItem.description == !null ? questionItem.description : ' '"
                  class="mb-1"
                  :label="questionItem.label !== null ? questionItem.label : ' '"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    :placeholder="questionItem.placeholder !== null ? questionItem.placeholder : ''"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="questionItem.type === 'select_box'"
                  class="mb-1"
                  :description="questionItem.description == !null ? questionItem.description : ' '"
                  :label="questionItem.label !== null ? questionItem.label : ' '"
                  label-for="input-1"
                >
                  <multiselect
                    :placeholder="questionItem.placeholder !== null ? questionItem.placeholder : ''"
                    class="mb-2"
                    :show-labels="false"
                    :options="questionItem.options.map((x) => x.value)"
                    :custom-label="(opt) => questionItem.options.find((x) => x.value == opt).value"
                  >
                  </multiselect>
                </b-form-group>
                <b-form-group
                  v-if="questionItem.type === 'checkbox'"
                  class="mb-1"
                  :label="questionItem.label !== null ? questionItem.label : ' '"
                  label-for="input-1"
                >
                  <div class="row">
                    <div
                      v-for="(option, optionKey) in questionItem.options"
                      :key="optionKey"
                      class="col-md-3"
                    >
                      <b-form-checkbox
                        id="checkbox-1"
                        name="checkbox-1"
                        value="true"
                        unchecked-value="false"
                      >
                        <span style="margin-left: 5px">{{ option.value }}</span>
                      </b-form-checkbox>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group
                  v-if="questionItem.type === 'radio'"
                  class="mb-1"
                  :label="questionItem.label !== null ? questionItem.label : ' '"
                  label-for="input-1"
                >
                  <div class="row">
                    <div
                      v-for="(option, optionKey) in questionItem.options"
                      :key="optionKey"
                      class="col-md-3"
                    >
                      <b-form-radio name="some-radios" :value="option.value">{{
                        option.value
                      }}</b-form-radio>
                    </div>
                  </div>
                </b-form-group>
              </div>
              <div class="col-md-4 col-xs-12">
                <div style="margin-top: 18px">
                  <b-button
                    variant="success"
                    class="mt-2"
                    style="margin-right: 5px"
                    @click="
                      editModal[questionKey] = !editModal[questionKey]
                      $forceUpdate()
                    "
                    ><i class="bx bx-edit-alt"></i
                  ></b-button>
                  <b-button
                    variant="danger"
                    class="mt-2"
                    style="margin-right: 5px"
                    @click="remove(question)"
                    ><i class="bx bx-trash"></i
                  ></b-button>
                </div>
              </div>
            </div>
            <b-modal v-model="editModal[questionKey]" :title="$t('buttons.edit')" hide-footer>
              <div>
                <b-tabs pills card>
                  <b-tab :title="$t('form.questions.question')">
                    <b-form-group
                      class="mb-2"
                      :label="$t('form.questions.question_desc')"
                      :description="$t('form.questions.add_question_desc')"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="questionItem.name"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                    <label>{{ $t('form.questions.select_question') }}</label>
                    <multiselect
                      v-model="questionItem.field_type"
                      :placeholder="$t('form.questions.question_type')"
                      class="mb-2"
                      :show-labels="false"
                      :options="form_field_types.map((x) => x.id)"
                      :custom-label="(opt) => form_field_types.find((x) => x.id == opt).name"
                    >
                    </multiselect>
                    <div
                      v-for="(type, typeKey) in form_field_types.find(
                        (x) => x.id === questionItem.field_type_id
                      ).properties"
                      :key="typeKey"
                    >
                      <b-form-group
                        v-if="type.key === 'label'"
                        class="mb-2"
                        :label="type.label"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          v-model="questionItem.label"
                          type="text"
                          :placeholder="type.label"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <!--                      <b-form-group
                          class="mb-2"
                          v-if="type.key === 'placeholder'"
                          :label="type.label"
                          label-for="input-1"
                      >
                        <b-form-input
                            id="input-1"
                            v-model="questionItem.placeholder"
                            type="text"
                            :placeholder="type.label"
                            required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                          class="mb-2"
                          v-if="type.key === 'description'"
                          :label="type.label"
                          label-for="input-1"
                      >
                        <b-form-input
                            id="input-1"
                            v-model="questionItem.description"
                            type="text"
                            :placeholder="type.label"
                            :description="type.label"
                            required
                        ></b-form-input>
                      </b-form-group>-->
                      <div
                        v-if="
                          type.input_type === 'select' ||
                          type.input_type === 'checkbox' ||
                          type.input_type === 'radio'
                        "
                        class="border p-2"
                      >
                        <b-button
                          variant="success"
                          @click="
                            questionItem.options.push({
                              key: moment().format(),
                              value: null,
                              placeholder: 'Ör: evet&hayır vb.',
                            })
                            $forceUpdate()
                          "
                          >{{ $t('form.question.add_option') }}</b-button
                        >
                        <div
                          v-for="(option, optionKey) in questionItem.options"
                          :key="optionKey"
                          class="row p-2"
                        >
                          <div class="col-md-10">
                            <b-form-input
                              id="input-1"
                              v-model="option.value"
                              :placeholder="option.placeholder"
                              type="text"
                            ></b-form-input>
                          </div>
                          <div class="col-md-2">
                            <b-button
                              variant="danger"
                              @click="questionItem.options.splice(optionKey, 1)"
                            >
                              <i class="bx bxs-trash-alt"></i>
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </b-tab>
                  <b-tab :title="$t('form.settings.settings')">
                    <div>
                      <div class="row">
                        <div class="col-md-3 col-xs-12">
                          <b-form-group
                            class="mb-2"
                            :label="$t('form.questions.status')"
                            label-for="input-1"
                          >
                            <div class="form-check form-switch form-switch-md mb-3">
                              <input
                                id="flexSwitchCheckDefault"
                                v-model="questionItem.enabled"
                                class="form-check-input"
                                type="checkbox"
                              />
                              <label class="form-check-label">{{
                                questionItem.enabled === true
                                  ? $t('form.questions.active')
                                  : $t('form.questions.passive')
                              }}</label>
                            </div>
                          </b-form-group>
                        </div>
                        <div class="col-md-3 col-xs-12">
                          <b-form-group
                            class="mb-2"
                            :label="$t('form.questions.required_field')"
                            label-for="input-1"
                          >
                            <div class="form-check form-switch form-switch-md mb-3">
                              <input
                                id="flexSwitchCheckDefault"
                                v-model="questionItem.required"
                                class="form-check-input"
                                type="checkbox"
                              />
                              <label class="form-check-label">{{
                                questionItem.required === true
                                  ? $t('form.questions.active')
                                  : $t('form.questions.passive')
                              }}</label>
                            </div>
                          </b-form-group>
                        </div>
                        <div class="col-md-3 col-xs-12">
                          <b-form-group
                            class="mb-2"
                            :label="$t('form.questions.hide')"
                            label-for="input-1"
                          >
                            <div class="form-check form-switch form-switch-md mb-3">
                              <input
                                id="flexSwitchCheckDefault"
                                v-model="questionItem.hidden"
                                class="form-check-input"
                                type="checkbox"
                              />
                              <label class="form-check-label">{{
                                questionItem.hidden === true
                                  ? $t('form.questions.active')
                                  : $t('form.questions.passive')
                              }}</label>
                            </div>
                          </b-form-group>
                        </div>
                        <div class="col-md-3 col-xs-12">
                          <b-form-group
                            class="mb-2"
                            :label="$t('form.questions.hide_label')"
                            label-for="input-1"
                          >
                            <div class="form-check form-switch form-switch-md mb-3">
                              <input
                                id="flexSwitchCheckDefault"
                                v-model="questionItem.label_hidden"
                                class="form-check-input"
                                type="checkbox"
                              />
                              <label class="form-check-label">{{
                                questionItem.label_hidden === true
                                  ? $t('form.questions.active')
                                  : $t('form.questions.passive')
                              }}</label>
                            </div>
                          </b-form-group>
                        </div>
                        <div class="col-md-12">
                          <b-form-group
                            class="mb-2"
                            :label="$t('form.questions.arrangement')"
                            label-for="input-1"
                            :description="$t('form.questions.do_arrangement')"
                          >
                            <b-form-input
                              id="input-1"
                              v-model="questionItem.display_order"
                              type="number"
                              placeholder="..."
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
                <b-button style="margin-right: 8px" variant="secondary" @click="editModal = {}">{{
                  $t('buttons.close')
                }}</b-button>
                <b-button variant="success" @click="update(question)">{{
                  $t('buttons.save')
                }}</b-button>
              </div>
            </b-modal>
          </div>
          <b-button class="mt-3" variant="success">{{ form.submit_text }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  GET_FORM_FIELD_TYPES,
  INSERT_FORM_QUESTION,
  GET_QUESTIONS,
  UPDATE_FORM_QUESTION,
  DELETE_FORM_QUESTION,
  GET_FORM,
} from '@/router/queries'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
export default {
  name: 'Question',
  components: {
    Multiselect,
  },
  data() {
    return {
      createModal: false,
      question: null,
      moment,
      editModal: {},
    }
  },
  computed: {
    ...mapState(['auth']),
    selectedType() {
      return this.form_field_types.find((x) => x.id === this.question.field_type_id) || []
    },
  },
  watch: {
    'question.field_type_id': function () {
      if (['phone', 'first_name', 'last_name', 'email'].includes(this.selectedType.code)) {
        this.question.type = 'text'
      }
      if (['select_box'].includes(this.selectedType.code)) {
        this.question.type = 'select_box'
      }
      if (['radio'].includes(this.selectedType.code)) {
        this.question.type = 'radio'
      }
      if (['checkbox'].includes(this.selectedType.code)) {
        this.question.type = 'checkbox'
      }
    },
    createModal: function () {
      if (this.createModal === false) {
        this.question = {
          default_value: null,
          field_type_id: null,
          hidden: false,
          enabled: true,
          label_hidden: false,
          required: false,
          display_order: null,
          label: null,
          name: null,
          placeholder: null,
          type: null,
          options: [],
          selected_options: null,
          description: null,
          form_id: this.$route.params.key,
          team_id: this.auth.user.team_id,
          user_id: this.auth.user.id,
        }
      }
    },
  },
  created() {
    this.$_loading()
    this.question = {
      default_value: null,
      field_type_id: null,
      hidden: false,
      enabled: true,
      label_hidden: false,
      required: false,
      display_order: null,
      label: null,
      name: null,
      placeholder: null,
      type: null,
      options: [],
      selected_options: null,
      description: null,
      form_id: this.$route.params.key,
      team_id: this.auth.user.team_id,
      user_id: this.auth.user.id,
    }
  },
  apollo: {
    form_field_types: {
      fetchPolicy: 'no-cache',
      query: GET_FORM_FIELD_TYPES,
    },
    form_questions: {
      fetchPolicy: 'no-cache',
      query: GET_QUESTIONS,
      variables() {
        return {
          where: {
            form_id: {
              _eq: this.$route.params.key,
            },
          },
        }
      },
    },
    form: {
      fetchPolicy: 'no-cache',
      query: GET_FORM,
      variables() {
        return {
          id: this.$route.params.key,
        }
      },
    },
  },
  methods: {
    save() {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: INSERT_FORM_QUESTION,
        variables: {
          question: this.question,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$apollo.queries.form_questions.refresh()
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
      this.createModal = false
    },
    update(question) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_FORM_QUESTION,
        variables: {
          id: question.id,
          question: this.$_ER(question, ['__typename', 'field_type']),
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$apollo.queries.form_questions.refresh()
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
      this.editModal = {}
    },
    remove(question) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: DELETE_FORM_QUESTION,
        variables: {
          id: question.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$apollo.queries.form_questions.refresh()
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
      this.editModal = {}
    },
    addOption() {
      this.question.options.push({
        key: moment().format(),
        value: null,
        placeholder: 'Ör: evet&hayır vb.',
      })
      this.$forceUpdate()
    },
  },
}
</script>
