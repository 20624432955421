<template>
  <div>
    <form id="test" @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-6">
          <b-form-group class="mb-3" :label="$t('locations.inputs.name')" for="location_name">
            <Address
              id="location_name"
              v-model="location.name"
              :class="{
                'is-invalid': submitted && $v.location.name.$error,
              }"
              is-location-name
              :is-invalid="submitted && $v.location.name.$error"
            />
            <div v-if="submitted && !$v.location.name.required" class="invalid-feedback">
              {{ $t('fields.required') }}
            </div>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group class="mb-3" :label="$t('locations.inputs.email')" for="location_email">
            <b-form-input
              id="location_email"
              v-model="location.email"
              :class="{
                'is-invalid': submitted && $v.location.email.$error,
              }"
            ></b-form-input>
            <div v-if="submitted && !$v.location.email.required" class="invalid-feedback">
              {{ $t('fields.required') }}
            </div>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group class="mb-3" :label="$t('locations.inputs.phone')" for="location_phone">
            <tel-input
              v-model="location.phone"
              :is-invalid="submitted && $v.location.phone.$error"
              :class="{ 'is-invalid': submitted && $v.location.phone.$error }"
            />
            <div v-if="submitted && !$v.location.phone.required" class="invalid-feedback">
              {{ $t('fields.required') }}
            </div>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group class="mb-3" :label="$t('locations.inputs.place')" for="location_placeId">
            <b-form-input
              id="location_placeId"
              v-model="location.place_id"
              :class="{
                'is-invalid': submitted && $v.location.place_id.$error,
              }"
            ></b-form-input>
            <div v-if="submitted && !$v.location.place_id.required" class="invalid-feedback">
              {{ $t('fields.required') }}
            </div>
          </b-form-group>
        </div>
        <div class="col-12 col-lg-6">
          <b-form-group class="mb-3" :label="$t('locations.inputs.address')" for="location_address">
            <b-form-input
              id="location_address"
              v-model="location.address"
              :class="{
                'is-invalid': submitted && $v.location.address.$error,
              }"
            />
            <div v-if="submitted && !$v.location.address.required" class="invalid-feedback">
              {{ $t('fields.required') }}
            </div>
          </b-form-group>
        </div>
        <div class="col-12 col-lg-6">
          <b-form-group class="mb-3" :label="$t('locations.inputs.image')" for="fileinput">
            <b-form-file
              id="fileinput"
              v-model="imageFile"
              :state="Boolean(imageFile)"
              accept="image/jpeg, image/png, image/jpg"
              placeholder="Choose an image or drop it here..."
              drop-placeholder="Drop file here..."
              @input="fileUpload"
            ></b-form-file>
          </b-form-group>
        </div>
      </div>
      <working-hours
        :working-hours-data="location.location_working_hours"
        :location-id="location.id"
        @setWorkingHours="
          (el) => {
            location.location_working_hours = { data: [] }
            location.location_working_hours.data = el
          }
        "
      />
      <div v-show="!hideSaveButton">
        <b-button type="submit" variant="primary">{{ $t('buttons.save') }}</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapState, mapGetters } from 'vuex'
import {
  ADD_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION_WORKING_HOURS_BY_LOCATION_ID,
  ADD_LOCATION_WORKING_HOURS,
} from '@/router/queries'
import WorkingHours from '@/components/widgets/workingHours.vue'
import { uploadService } from '@/helpers/upload.service.js'

export default {
  components: {
    WorkingHours,
  },
  props: {
    locationModal: {
      type: Boolean,
    },
    hideSaveButton: {
      type: Boolean,
    },
    updateItemData: {
      type: Object,
      default: () => {},
    },
    placeIdRequired: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      location: {},
      submitted: false,
      isUpdate: false,
      imageFile: null,
      createdId: null,
    }
  },
  computed: {
    ...mapState(['auth']),
    ...mapGetters({
      locationDetails: 'location/locationDetails',
    }),
    showModal: {
      get() {
        return this.addProductModal
      },
      set() {
        this.$emit('close')
      },
    },
  },
  watch: {
    updateItemData: {
      handler(val) {
        if (val.id) {
          this.location = { ...val }
          this.isUpdate = true
        } else this.initData()
      },
      immediate: true,
    },
    locationDetails(val) {
      this.location.name = val.name
      this.location.place_id = val.place_id
      this.location.phone = val.international_phone_number
      this.location.address = val.formatted_address
      if (val.opening_hours) this.setWeekday(val.opening_hours)
    },
  },
  validations: {
    location: {
      name: { required },
      phone: { required },
      email: { required },
      address: { required },
      place_id: {
        required: requiredIf(function () {
          return this.placeIdRequired
        }),
      },
    },
  },
  methods: {
    initData() {
      this.isUpdate = false
      this.location = {
        name: null,
        email: null,
        phone: null,
        address: null,
        place_id: null,
        team_id: this.auth.user.team_id,
        location_working_hours: {
          data: [],
        },
      }
    },
    async handleSubmit() {
      if (this.isUpdate) {
        await this.removeLocationWorkingHours()
        await this.addLocationWorkingHours()
        this.update()
        return
      }
      this.create()
    },
    create() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$loading(false)
        return
      } else {
        this.$apollo.mutate({
          mutation: ADD_LOCATION,
          variables: {
            location: this.location,
          },
          update: (cache, { data: { insert_locations_one } }) => {
            try {
              if (!this.hideSaveButton) {
                this.$_N({
                  type: 'success',
                  position: 'center',
                })
              }
              this.createdId = insert_locations_one.id
              this.$loading(false)
              this.$emit('close')
              this.$emit('submitted')
            } catch {
              this.$_N({
                type: 'error',
                position: 'center',
              })
              this.$loading(false)
            }
          },
        })
      }
      this.submitted = false
    },
    update() {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_LOCATION,
        variables: {
          location: this.$_ER(this.location, ['__typename', 'location_working_hours']),
          id: this.location.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
            this.$emit('close')
            this.$emit('submitted')
          } catch {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$loading(false)
          }
        },
      })
      this.updateModal = {}
    },
    setWeekday(workingHours) {
      const openDays = []
      workingHours.periods.forEach((el) => {
        const openingHour = el.open
        const closingHour = el.close
        this.location.location_working_hours.data.forEach((item) => {
          if (!openDays.includes(item.day)) {
            if (item.day === openingHour.day) {
              item.work_start = openingHour.time.slice(0, 2) + ':' + openingHour.time.slice(2)
              openDays.push(openingHour.day)
            }
            if (item.day === closingHour.day) {
              item.work_end = closingHour.time.slice(0, 2) + ':' + closingHour.time.slice(2)
            }
            item.rest_start = null
            item.rest_end = null
            item.active = true
          } else {
            if (item.day === openingHour.day)
              item.rest_end = openingHour.time.slice(0, 2) + ':' + openingHour.time.slice(2)
            if (item.day === closingHour.day) {
              item.rest_start = item.work_end
              item.work_end = closingHour.time.slice(0, 2) + ':' + closingHour.time.slice(2)
            }
          }
        })
      })
      this.location.location_working_hours.data.forEach((item) => {
        if (!openDays.includes(item.day)) {
          item.active = false
          item.work_start = null
          item.work_end = null
          item.rest_start = null
          item.rest_end = null
        }
      })
    },
    async removeLocationWorkingHours() {
      this.$loading(true)
      await this.$apollo
        .mutate({
          mutation: DELETE_LOCATION_WORKING_HOURS_BY_LOCATION_ID,
          variables: {
            location_id: this.location.id,
          },
        })
        .then(() => {
          return
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
        })
    },
    async addLocationWorkingHours() {
      this.$loading(true)
      const data = this.location.location_working_hours.data
      data.forEach((el) => {
        if (el['__typename']) delete el['__typename']
      })
      await this.$apollo
        .mutate({
          mutation: ADD_LOCATION_WORKING_HOURS,
          variables: {
            location_working_hours: data,
          },
        })
        .then(() => {
          this.location.location_working_hours.data = []
          return
        })
        .catch(() => {
          this.location.location_working_hours.data = []
          this.$_N({
            type: 'error',
            position: 'center',
          })
        })
    },
    async fileUpload(file) {
      this.$loading(true)
      try {
        const image = await uploadService({
          file: file,
          name: this.location.name + '-location-image',
          team_id: this.auth.user.team_id,
          user_id: this.auth.user.id,
        })
        this.location.image = image
      } catch (err) {
        this.$_N({
          type: 'error',
          position: 'center',
        })
      }
      this.$loading(false)
    },
  },
}
</script>
