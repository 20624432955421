<template>
  <div>
    <PageHeader :title="$t(title)" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto pt-1">
            <div class="dataTables_filter text-end">
              <b-input
                v-model="filter_b"
                type="search"
                :placeholder="$t('fields.search')"
              ></b-input>
            </div>
          </div>
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="date" class="form-select">
              <b-form-select-option value="all">{{
                $t('lists.date_range.all')
              }}</b-form-select-option>
              <b-form-select-option value="today">{{
                $t('lists.date_range.today')
              }}</b-form-select-option>
              <b-form-select-option value="tomorrow">{{
                $t('lists.date_range.tomorrow')
              }}</b-form-select-option>
              <b-form-select-option value="yesterday">{{
                $t('lists.date_range.yesterday')
              }}</b-form-select-option>
              <b-form-select-option value="month">{{
                $t('lists.date_range.this_month')
              }}</b-form-select-option>
              <b-form-select-option value="last_month">{{
                $t('lists.date_range.last_month')
              }}</b-form-select-option>
              <b-form-select-option value="next_month">{{
                $t('lists.date_range.next_month')
              }}</b-form-select-option>
              <b-form-select-option value="custom">{{
                $t('lists.date_range.custom')
              }}</b-form-select-option>
            </b-form-select>
          </div>
          <div v-if="date === 'custom'" class="col-sm-auto pt-1">
            <date-picker
              v-model="start"
              value-type="YYYY-MM-DD"
              format="DD/MM/YYYY"
              :first-day-of-week="1"
            ></date-picker>
          </div>
          <div v-if="date === 'custom'" class="col-sm-auto pt-1">
            <date-picker
              v-model="end"
              value-type="YYYY-MM-DD"
              format="DD/MM/YYYY"
              :first-day-of-week="1"
            ></date-picker>
          </div>
        </b-form>
      </div>
    </div>
    <div class="col-md-12 col-xs-12">
      <div class="card">
        <div class="card-body">
          <div v-if="events && events_aggregate" class="table-responsive mb-0">
            <div style="overflow-x: auto">
              <b-table
                :items="events"
                :fields="fields"
                hover="hover"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter_b"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(start)="data">
                  {{ moment(data.value).format('LLL') }}
                </template>
                <template #cell(name)="row">
                  {{ row.item.contact.first_name + ' ' + row.item.contact.last_name }}
                </template>
                <template #cell(user)="row">
                  {{ row.item.user.first_name + ' ' + row.item.user.last_name }}
                </template>
                <template #cell(status)="row">
                  <span
                    class="badge badge-pill badge-soft-success font-size-12"
                    :class="
                      row.item.event_status.code === 'DONE'
                        ? 'badge-soft-success'
                        : 'badge-soft-danger'
                    "
                    >{{ row.item.event_status.name }}</span
                  >
                </template>
                <template #cell(actions)="row">
                  <button
                    type="button"
                    class="btn btn-success btn-sm waves-effect waves-light"
                    @change="row"
                    @click="
                      $router.push({
                        name: 'agenda.detail',
                        params: { key: row.item.id },
                      })
                    "
                  >
                    <i class="bx bx-show font-size-14 align-middle mr-2"></i>
                  </button>
                </template>
              </b-table>
            </div>
            <b-alert :show="events.count === 0" class="mt-3 mb-3 text-center" variant="warning">
              <p class="mb-0"></p>
              {{ $t('appointment.empty_list') }}
            </b-alert>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="page"
                    :total-rows="events_aggregate ? events_aggregate.aggregate.count : '0'"
                    :per-page="per_page"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="text-center my-3">
          <p>
            {{ $t('appointment.total_appointments') }}
            <b>{{ events_aggregate ? events_aggregate.aggregate.count : 'N/A' }}</b>
          </p>
        </div>
      </div>
      <!-- end col-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/tr'

import PageHeader from '@/components/page-header'
import { GET_EVENTS_LIST, GET_EVENTS_AGGREGATE } from '../../queries'
import moment from 'moment'

export default {
  components: {
    PageHeader,
    DatePicker,
  },
  data() {
    return {
      title: 'title.appointments',
      items: [
        {
          text: this.$t('title.appointments'),
        },
        {
          text: this.$t('calendar.list'),
          active: true,
        },
      ],
      moment,
      page: 1,
      date: 'today',
      currentPage: 1,
      per_page: 10,
      filterOn: [],
      sortBy: 'value',
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      filter_b: null,
      totalRows: 1,
      fields: [
        {
          key: 'start',
          label: this.$t('appointment.appointment_date'),
          sortable: true,
        },
        {
          key: 'name',
          sortable: true,
          label: this.$t('appointment.customer'),
        },
        {
          key: 'title',
          sortable: false,
          label: this.$t('appointment.title'),
        },
        {
          key: 'user',
          sortable: true,
          label: this.$t('appointment.staff'),
        },
        {
          key: 'status',
          sortable: true,
          label: this.$t('appointment.status'),
        },
        {
          key: 'actions',
          sortable: true,
          label: this.$t('dashboards.actions'),
        },
      ],
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    }
  },
  computed: {
    ...mapState(['auth']),
    rows() {
      return this.events.length
    },
    offset() {
      return (this.page - 1) * this.per_page
    },
    // eslint-disable-next-line vue/return-in-computed-property
    request() {
      if (this.date === 'today') {
        const _and = [
          { start: { _gte: moment().format('YYYY-MM-DD') } },
          { start: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'all') {
        const _and = [{ start: { _gte: undefined } }, { start: { _lte: undefined } }]
        return _and
      }
      if (this.date === 'yesterday') {
        const _and = [
          { start: { _gte: moment().add(-1, 'days').format('YYYY-MM-DD') } },
          { start: { _lte: moment().format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'tomorrow') {
        const _and = [
          { start: { _gte: moment().add(1, 'days').format('YYYY-MM-DD') } },
          { start: { _lte: moment().add(2, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'month') {
        const _and = [
          { start: { _gte: moment().add(-30, 'days').format('YYYY-MM-DD') } },
          { start: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_month') {
        const _and = [
          { start: { _gte: moment().add(-59, 'days').format('YYYY-MM-DD') } },
          { start: { _lte: moment().add(-31, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'next_month') {
        const _and = [
          { start: { _gte: moment().format('YYYY-MM-DD') } },
          { start: { _lte: moment().add(31, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'custom') {
        const _and = [
          { start: { _gte: moment(this.start).format('YYYY-MM-DD') } },
          { start: { _lte: moment(this.end).add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  apollo: {
    events: {
      fetchPolicy: 'no-cache',
      query: GET_EVENTS_LIST,
      variables() {
        return {
          limit: this.per_page,
          offset: this.offset,
          order_by: this.sort_by,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.user_select_id || undefined,
            },
            online: {
              _eq: this.is_online || undefined,
            },
            _or: [
              {
                contact: {
                  first_name: this.search,
                },
              },
              {
                contact: {
                  last_name: this.search,
                },
              },
            ],
            _and: this.request,
          },
        }
      },
    },
    events_aggregate: {
      fetchPolicy: 'no-cache',
      query: GET_EVENTS_AGGREGATE,
      variables() {
        return {
          where: {},
        }
      },
    },
  },
}
</script>
