<template>
  <b-modal v-model="showModal" hide-footer :title="$t('navbar.dropdown.quick_menu.add_form')">
    <form @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label for="form_name">{{ $t('form.form_name') }}</label>
            <input
              id="form_name"
              v-model="form.name"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': submitted && $v.form.name.$error,
              }"
            />
            <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">
              {{ $t('fields.required') }}
            </div>
          </div>
        </div>
      </div>
      <div class="text-end mt-3">
        <b-button variant="light" @click="$emit('close')">{{ $t('buttons.close') }}</b-button>
        <b-button type="submit" variant="success" class="ms-1">{{ $t('buttons.save') }}</b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import { INSERT_FORM } from '@/router/queries'
export default {
  name: 'AddForm',
  props: {
    addFormModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      moment,
      form: null,
      submitted: false,
    }
  },
  computed: {
    ...mapState(['auth']),
    showModal: {
      get() {
        return this.addFormModal
      },
      set() {
        this.$emit('closeModal')
      },
    },
  },
  created() {
    this.$_loading()
    this.form = {
      name: null,
      user_id: this.auth.user.id,
      team_id: this.auth.user.team_id,
    }
  },
  validations: {
    form: {
      name: { required },
    },
  },
  methods: {
    handleSubmit() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$loading(false)
        return
      } else {
        this.$apollo.mutate({
          mutation: INSERT_FORM,
          variables: {
            form: this.form,
          },
          update: (cache, { data: { insert_forms_one } }) => {
            try {
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$router.push({
                name: 'form.show',
                params: { key: insert_forms_one.id },
              })
              this.$loading(false)
            } catch (e) {
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          },
        })
        this.$emit('close')
      }
      this.submitted = false
    },
  },
}
</script>
