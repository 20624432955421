<template>
  <div>
    <b-modal v-model="showModal" :title="$t('navbar.dropdown.quick_menu.sms')" hide-footer>
      <b-alert v-if="data" variant="warning" :show="team.sms_count <= 0">
        {{ $t('marketing.sms.purchase_sms_package') }}
      </b-alert>
      <form v-if="tags && team.sms_count > 0" @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">{{ $t('marketing.marketing_name') }}</label>
              <input
                id="name"
                v-model="marketing.name"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.marketing.name.$error,
                }"
              />
              <div v-if="submitted && !$v.marketing.name.required" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="schedule">{{ $t('marketing.date') }}</label>
              <VueCtkDateTimePicker
                v-model="marketing.schedule"
                color="#2a3042"
                button-color="#2a3042"
                :button-now-translation="$t('buttons.now')"
                :label="$t('marketing.date')"
                :error="submitted && $v.marketing.schedule.$error"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label>{{ $t('marketing.tags') }}</label>
              <multiselect
                v-model="marketing.filter.tags"
                :show-labels="false"
                :tag-placeholder="$t('marketing.sms.tag_placeholder')"
                :placeholder="$t('buttons.placeHolderSelect')"
                label="name"
                track-by="id"
                :options="
                  tags.map((x) => {
                    return { name: x.name, id: x.id }
                  })
                "
                :multiple="true"
                :taggable="true"
              >
                <span slot="noResult">
                  {{ $t('lists.no_record') }}
                </span>
                <span slot="noOptions">
                  {{ $t('lists.no_record') }}
                </span>
              </multiselect>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="content">{{ $t('marketing.content') }}</label>
              <textarea
                id="content"
                v-model="marketing.content"
                cols="30"
                rows="5"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.marketing.content.$error,
                }"
              ></textarea>
              <div v-if="submitted && !$v.marketing.content.required" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="$emit('close')">{{ $t('buttons.close') }}</b-button>
          <b-button type="submit" variant="success" class="ms-1" :disabled="team.sms_count <= 0">
            {{ $t('buttons.send') }}
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
import { GET_TAGS, INSERT_MARKETING, GET_TEAM } from '@/router/queries'
export default {
  name: 'Sms',
  components: {
    Multiselect,
    VueCtkDateTimePicker,
  },
  props: {
    addSmsMarketingModal: {
      type: Boolean,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      submitted: false,
      moment,
      marketing: null,
      team: {
        sms_count: null,
      },
    }
  },
  computed: {
    ...mapState(['auth']),
    showModal: {
      get() {
        return this.addSmsMarketingModal
      },
      set() {
        this.$emit('close')
      },
    },
  },
  created() {
    this.$_loading()
    if (this.data) {
      this.marketing = {
        name: this.data.name,
        content: this.data.content,
        filter: this.data.filter,
        schedule: null,
        type: 'sms',
        user_id: this.auth.user.id,
        team_id: this.auth.user.team_id,
      }
    } else {
      this.marketing = {
        name: null,
        content: null,
        schedule: moment().format('YYYY-MM-DD HH:mm'),
        filter: {},
        type: 'sms',
        user_id: this.auth.user.id,
        team_id: this.auth.user.team_id,
      }
    }
  },
  validations: {
    marketing: {
      name: { required },
      content: { required },
      schedule: { required },
    },
  },
  methods: {
    handleSubmit() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$loading(false)
        return
      } else {
        this.marketing.schedule = this.convertUtc(this.marketing.schedule)
        this.$apollo.mutate({
          mutation: INSERT_MARKETING,
          variables: {
            marketing: this.marketing,
          },
          update: (cache, { data: { insert_marketings_one } }) => {
            try {
              this.$_N({
                position: 'center',
                type: 'success',
              })
              this.$router.push({
                name: 'marketing.sms.show',
                params: { id: insert_marketings_one.id },
              })
              this.$loading(false)
            } catch (e) {
              this.$_N({
                position: 'center',
                type: 'error',
              })
            }
          },
        })
        this.$emit('close')
      }
      this.submitted = false
    },
  },
  apollo: {
    team: {
      fetchPolicy: 'no-cache',
      query: GET_TEAM,
      variables() {
        return {
          id: this.auth.user.team_id,
        }
      },
    },
    tags: {
      fetchPolicy: 'no-cache',
      query: GET_TAGS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
