<template>
  <div v-if="bundle" class="row">
    <PageHeader :title="title" :items="items" />
    <div class="col-md-4">
      <div class="row">
        <div class="col-md-12 mb-3">
          <div class="card" style="height: 100%">
            <div class="card-body">
              <h4 class="card-title">{{ $t('bundle.bundle_detail') }}</h4>
              <div class="table-responsive">
                <table class="table table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">{{ $t('bundle.title') }} :</th>
                      <td>
                        {{ bundle.name }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('bundle.bundle_detail') }} :</th>
                      <td>{{ bundle.description }}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('bundle.price') }} :</th>
                      <td>{{ formatPrice(bundle.price) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('bundle.amount_of_use') }} :</th>
                      <td v-if="event_bundles">
                        <p class="text-success m-0">
                          {{ event_bundles.length ? event_bundles.length : 0 }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('bundle.created_at') }} :</th>
                      <td>
                        {{ moment(bundle.created_at).format('Do MMMM YYYY HH:ss') }}
                      </td>
                    </tr>
                    <!--<tr>
                      <th scope="row">{{ $t('bundle.agreement') }} :</th>
                      <td>
                        <button
                    type="button"
                    @click="
                      $router.push({
                        name: 'bundle.print',
                        params: { key: $route.params.key },
                      })
                    "
                    class="btn btn-warning btn-sm waves-effect waves-light"
                  >
                    <i class='bx bx-printer font-size-16 align-middle me-1'></i> {{ $t('buttons.print') }}
                  </button>
                      </td>
                    </tr>-->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-3">
          <div class="card" style="height: 100%">
            <div class="card-body">
              <h4 class="card-title">{{ $t('bundle.customer') }}</h4>
              <div class="table-responsive">
                <table class="table table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">{{ $t('bundle.customer_detail.name') }} :</th>
                      <td>
                        {{ bundle.contact.first_name + ' ' + bundle.contact.last_name }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('bundle.customer_detail.email') }} :</th>
                      <td>{{ bundle.contact.email !== null ? bundle.contact.email : '-' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('bundle.customer_detail.phone') }} :</th>
                      <td>
                        {{
                          bundle.contact.phone !== null
                            ? '(' + bundle.contact.phone_code + ')' + ' ' + bundle.contact.phone
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('bundle.created_at') }} :</th>
                      <td>
                        {{ moment(bundle.created_at).format('Do MMMM YYYY HH:ss') }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="card" style="height: 100%">
            <div class="card-body">
              <h4 class="card-title">{{ $t('bundle.usage_detail') }}</h4>
              <table class="table mb-3 table-striped">
                <thead>
                  <tr>
                    <th>{{ $t('bundle.date') }}</th>
                    <th>{{ $t('bundle.amount_of_use') }}</th>
                    <th>{{ $t('bundle.user') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(bundle, key) in event_bundles" :key="key">
                    <td>{{ moment(bundle.created_at).format('DD/MM/YYYY HH:mm') }}</td>
                    <td>{{ bundle.amount }}</td>
                    <td>{{ bundle.user.first_name + ' ' + bundle.user.last_name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-4">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <p class="text-muted fw-medium mb-2">{{ $t('bundle.total') }}</p>
                  <h4 class="mb-0">{{ formatPrice(bundle.price) }}</h4>
                </div>
                <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                  <span class="avatar-title" style="background-color: #556ee6">
                    <i class="bx bx-money font-size-24`"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <p class="text-muted fw-medium mb-2">{{ $t('bundle.paid') }}</p>
                  <h4 class="mb-0">
                    {{
                      bundle.paid.aggregate.sum.price !== null
                        ? formatPrice(bundle.paid.aggregate.sum.price)
                        : formatPrice(0)
                    }}
                  </h4>
                </div>
                <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                  <span class="avatar-title" style="background-color: #279b3a">
                    <i class="bx bx-money font-size-24`"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <p class="text-muted fw-medium mb-2">{{ $t('bundle.pending') }}</p>
                  <h4 class="mb-0">
                    {{
                      bundle.debt.aggregate.sum.price !== null
                        ? formatPrice(bundle.debt.aggregate.sum.price)
                        : formatPrice(0)
                    }}
                  </h4>
                </div>
                <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                  <span class="avatar-title" style="background-color: #ff0000">
                    <i class="bx bx-money font-size-24`"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ $t('bundle.bundle_detail') }}</h4>
              <table class="table mb-3 table-striped">
                <thead>
                  <tr>
                    <th>{{ $t('bundle.service') }}</th>
                    <th>{{ $t('bundle.session') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(service, key) in bundle.bundle_services" :key="key">
                    <td>{{ service.service.name }}</td>
                    <td>{{ service.amount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div id="printMe" class="col-md-12 mt-3">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ $t('bundle.payment_detail') }}</h4>
              <div style="overflow-x: auto">
                <table class="table mb-3 table-striped">
                  <thead>
                    <tr>
                      <th>{{ $t('bundle.date') }}</th>
                      <th>{{ $t('bundle.payment') }}</th>
                      <th>{{ $t('bundle.status') }}</th>
                      <th>{{ $t('bundle.action') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(payment, key) in bundle.bundle_payments" :key="key">
                      <td>{{ moment(payment.date).format('DD/MM/YYYY') }}</td>
                      <td>{{ formatPrice(payment.price) }}</td>
                      <td>
                        <p v-if="payment.status === 'PENDING'">
                          {{ $t('bundle.pending') }}
                          <span
                            v-if="
                              moment().format('YYYY-MM-DD') >
                              moment(payment.date).format('YYYY-MM-DD')
                            "
                            class="text-danger"
                            style="font-weight: bold"
                          >
                            - {{ $t('bundle.passed') }}
                            <i class="bx bxs-info-circle bx-flashing bx-flip-vertical"></i
                          ></span>
                        </p>
                        <p v-if="payment.status === 'DONE'" class="text-success">
                          {{ $t('bundle.paid') }}
                        </p>
                      </td>
                      <td>
                        <div class="button-items">
                          <button
                            v-if="payment.status === 'PENDING'"
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click="
                              addListPayment[key] = !addListPayment[key]
                              $forceUpdate()
                            "
                          >
                            <i class="bx bx-lira font-size-16 align-middle me-1"></i
                            >{{ $t('buttons.pay') }}
                          </button>
                          <button
                            v-if="payment.status === 'PENDING'"
                            type="button"
                            class="btn btn-warning btn-sm"
                            @click="
                              updateModal[key] = !updateModal[key]
                              $forceUpdate()
                            "
                          >
                            <i class="bx bx-edit font-size-16 align-middle me-1"></i>
                            {{ $t('buttons.edit') }}
                          </button>
                        </div>
                      </td>
                      <b-modal
                        v-model="updateModal[key]"
                        hide-footer
                        :title="$t('bundle.update_payment')"
                      >
                        <b-form-group :label="$t('bundle.payment_date')">
                          <date-picker
                            v-model="payment.date"
                            type="date"
                            value-type="YYYY-MM-DD"
                            format="DD/MM/YYYY"
                          ></date-picker>
                        </b-form-group>
                        <b-form-group :label="$t('bundle.installment_amount')" class="mt-3">
                          <b-form-input v-model="payment.price"></b-form-input>
                        </b-form-group>
                        <div class="button-groups text-end mt-3">
                          <b-button style="margin-right: 10px" @click="updateModal = {}">{{
                            $t('buttons.close')
                          }}</b-button>
                          <b-button variant="success" @click="update(payment)">{{
                            $t('buttons.update')
                          }}</b-button>
                        </div>
                      </b-modal>
                      <b-modal
                        v-model="addListPayment[key]"
                        hide-footer
                        :title="$t('buttons.add_payment')"
                      >
                        <invoice-component
                          :key-i-d="payment.id"
                          :data="payment"
                          @updated="paymentUpdate($event)"
                          @close="addListPayment = {}"
                        ></invoice-component>
                      </b-modal>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table class="table table-nowrap mb-0">
                <tbody>
                  <tr>
                    <th scope="row">{{ $t('bundle.total') }} :</th>
                    <td>{{ formatPrice(bundle.price) }}</td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t('bundle.paid') }} :</th>
                    <td>
                      {{
                        bundle.paid.aggregate.sum.price !== null
                          ? formatPrice(bundle.paid.aggregate.sum.price)
                          : formatPrice(0)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{{ $t('bundle.pending') }} :</th>
                    <td>
                      {{
                        bundle.debt.aggregate.sum.price !== null
                          ? formatPrice(bundle.debt.aggregate.sum.price)
                          : formatPrice(0)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/page-header'
import moment from 'moment'
import { mapState } from 'vuex'
import { UPDATE_PAYMENT, GET_BUNDLE_SINGLE, BUNDLE_SHOW_DETAIL } from '@/router/queries'
import invoiceComponent from '@/components/widgets/invoice-payment'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'Show',
  components: {
    PageHeader,
    invoiceComponent,
    DatePicker,
  },
  data() {
    return {
      items: [
        {
          text: this.$t('bundle.bundles'),
          href: '/',
        },
        {
          text: this.$t('bundle.bundle_detail'),
          active: true,
        },
      ],
      moment,
      title: this.$t('bundle.bundle_detail'),
      addListPayment: {},
      updateModal: {},
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  methods: {
    paymentUpdate(id) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_PAYMENT,
        variables: {
          payment: {
            status: 'DONE',
          },
          id: id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$apollo.queries.bundle.refresh()
            this.$forceUpdate()
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
    },
    update(data) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_PAYMENT,
        variables: {
          payment: this.$_ER(data, ['__typename']),
          id: data.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$apollo.queries.bundle.refresh()
            this.$forceUpdate()
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
      this.updateModal = {}
    },
  },
  apollo: {
    bundle: {
      fetchPolicy: 'no-cache',
      query: GET_BUNDLE_SINGLE,
      variables() {
        return {
          id: this.$route.params.key,
        }
      },
    },
    event_bundles: {
      query: BUNDLE_SHOW_DETAIL,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          bundle_id: this.$route.params.id,
        }
      },
    },
  },
}
</script>
