<template>
  <div v-if="contact && events && isReady" class="row">
    <PageHeader
      :title="(contact.first_name || '') + ' ' + (contact.last_name || '')"
      :items="items"
    />
    <div class="col-md-4">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-nowrap mb-0">
                  <tbody v-if="contact">
                    <tr>
                      <th scope="row">{{ $t('customerCard.name') }} :</th>
                      <td>
                        {{ (contact.first_name || '') + ' ' + (contact.last_name || '') }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('customerCard.phone') }} :</th>
                      <td>
                        {{
                          contact.phone
                            ? contact.phone.toString().charAt(0) === '+'
                              ? contact.phone
                              : '+' + contact.phone
                            : ''
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('customerCard.email') }} :</th>
                      <td>{{ contact.email }}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('customerCard.address') }} :</th>
                      <td>{{ contact.address }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="button-items mt-4 mb-2">
                <!-- add event -->
                <b-button variant="success btn-sm" @click="addEventModal = true">
                  <i class="bx bx-time-five font-size-16 align-middle me-1" />
                  {{ $t('buttons.createMeet') }}
                </b-button>
                <add-event
                  :add-event-modal="addEventModal"
                  :appointment-data="eventData"
                  :contact="contact"
                  @closeModal="addEventModal = false"
                />
                <!-- edit contact -->
                <b-button variant="warning btn-sm" @click="contactInfo = true">
                  <i class="bx bx-edit font-size-16 align-middle me-1" />
                  {{ $t('buttons.editCustomer') }}
                </b-button>
                <b-modal
                  v-if="contactInfo"
                  v-model="contactInfo"
                  hide-footer
                  :title="$t('customerCard.edit_customer')"
                  size="xl"
                >
                  <contact-form
                    :customer="contact"
                    :is-update="true"
                    @close="
                      contactInfo = false
                      $apollo.queries.contact_vault_paid.refresh()
                    "
                  />
                </b-modal>
              </div>
              <div class="card-body border-top">
                <p class="text-muted mb-4">
                  {{ $t('customerCard.statistics') }}
                </p>
                <div class="text-center">
                  <div class="row">
                    <div class="col-sm-4">
                      <div>
                        <div class="font-size-24 text-primary mb-2">
                          <i class="bx bx-send" style="color: #018c5c"></i>
                        </div>

                        <p class="text-muted mb-2">
                          {{ $t('dashboard.appointment') }}
                        </p>
                        <h5>{{ total_event_count.aggregate.count }}</h5>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mt-4 mt-sm-0">
                        <div class="font-size-24 text-primary mb-2">
                          <i class="bx bx-menu"></i>
                        </div>

                        <p class="text-muted mb-2">
                          {{ $t('customerCard.totalServices') }}
                        </p>
                        <h5>{{ total_services_count.aggregate.count }}</h5>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mt-4 mt-sm-0">
                        <div class="font-size-24 text-primary mb-2">
                          <i class="bx bx-menu"></i>
                        </div>

                        <p class="text-muted mb-2">
                          {{ $t('customerCard.totalProducts') }}
                        </p>
                        <h5>{{ total_product_count.aggregate.count }}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-2">{{ $t('customerCard.tag') }}</h4>
              <tag-tabs></tag-tabs>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-2">{{ $t('title.locations') }}</h4>
              <contactLocations @refresh="$apollo.queries.contact_vault_paid.refresh()" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-4">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <p class="text-muted fw-medium mb-2">
                    {{ $t('customerCard.priceTotal') }}
                  </p>
                  <h4 class="mb-0">{{ formatPrice(totalPrice) }}</h4>
                </div>
                <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                  <span class="avatar-title">
                    <i class="bx bx-money font-size-24`"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <p class="text-muted fw-medium mb-2">
                    {{ $t('customerCard.paymentTotal') }}
                  </p>
                  <h4 class="mb-0">
                    {{
                      contact_vault_paid.aggregate.sum.price !== null
                        ? formatPrice(totalPaid)
                        : formatPrice(0)
                    }}
                  </h4>
                </div>
                <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                  <span class="avatar-title" style="background-color: #279b3a">
                    <i class="bx bx-money font-size-24`"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <p class="text-muted fw-medium mb-2">
                    {{ $t('customerCard.remaining') }}
                  </p>
                  <h4 class="mb-0">{{ formatPrice(totalDept) }}</h4>
                </div>
                <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                  <span class="avatar-title" style="background-color: #ff0000">
                    <i class="bx bx-money font-size-24`"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <ContactNotes
                :customer="contact"
                :remarks="notes"
                @updated="$apollo.queries.contact_vault_paid.refresh()"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-tabs nav-class="nav-tabs-custom mb-3">
            <b-tab active>
              <template #title>
                <span class="d-inline-block d-sm-none">
                  <i class="bx bxs-bar-chart-alt-2"></i>
                </span>
                <span class="d-none d-sm-inline-block">{{
                  $t('menuitems.dashboards.actions')
                }}</span>
              </template>
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-2">
                    {{ $t('menuitems.dashboards.booking') }}
                  </h4>
                  <ContactAppointment
                    :contact="contact"
                    @updated="$apollo.queries.events.refresh()"
                  />
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-2">
                    {{ $t('customerCard.payments') }}
                  </h4>
                  <ContactPayment />
                </div>
              </div>
              <bundleList @updated="$apollo.queries.contact_vault_paid.refresh()" />
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-2">
                    {{ $t('menuitems.systems.list.forms') }}
                  </h4>
                  <forms :contact="contact"></forms>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template #title>
                <span class="d-inline-block d-sm-none">
                  <i class="bx bx-message-dots"></i>
                </span>
                <span class="d-none d-sm-inline-block">{{
                  $t('menuitems.systems.list.notifications')
                }}</span>
              </template>
              <div v-if="outgoings" class="card">
                <div class="card-body">
                  <ContactEmail
                    :customer="contact"
                    :outgoings="outgoings"
                    :email="email_marketing"
                    @updated="$apollo.queries.contact_vault_paid.refresh()"
                  />
                </div>
              </div>
              <div v-if="outgoings" class="card">
                <div class="card-body">
                  <ContactSms
                    :customer="contact"
                    :outgoings="outgoings"
                    :sms="sms_marketing"
                    @updated="$apollo.queries.contact_vault_paid.refresh()"
                  />
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { mapState } from 'vuex'
import AddEvent from '@/components/widgets/addEvent'
import ContactAppointment from './components/appointment.vue'
import ContactPayment from './components/payment.vue'
import ContactSms from './components/sms.vue'
import ContactLocations from './components/locations'
import ContactEmail from './components/email.vue'
import ContactNotes from './components/notes.vue'
import ContactForm from '@/components/widgets/contactForm'
import TagTabs from './components/tags'
import Forms from './components/Forms'
import bundleList from './components/bundle'

import { GET_CONTACT_DETAIL, GET_EVENTS_CONTACT_DETAIL } from '@/router/queries'

/**
 * Contacts-Profile component
 */
export default {
  page: {
    title: 'customer',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    PageHeader,
    ContactAppointment,
    ContactPayment,
    AddEvent,
    ContactSms,
    ContactEmail,
    bundleList,
    ContactNotes,
    Forms,
    ContactForm,
    TagTabs,
    ContactLocations,
  },
  data() {
    return {
      title: this.$t('customerCard.customer_detail'),
      items: [
        {
          text: this.$t('contacts.title'),
          href: '/contacts',
        },
        {
          text: this.$t('contacts.detail'),
          active: true,
        },
      ],
      statData: [
        {
          icon: 'bx bx-check-circle',
          title: 'Completed Projects',
          value: '125',
        },
        {
          icon: 'bx bx-hourglass',
          title: 'Pending Projects',
          value: '12',
        },
        {
          icon: 'bx bx-package',
          title: 'Total Revenue',
          value: '$36,524',
        },
      ],
      sort_by: { created_at: 'desc' },
      per_page: 10,
      page: 1,
      addEventModal: false,
      contactInfo: false,
      contact_bundle_paid: null,
      contact_bundle_dept: null,
      total_services_count: null,
      total_product_count: null,
      total_event_count: null,
      contact: null,
      addServiceCheck: true,
      genders: null,
      outgoings: null,
      notes: null,
      isReady: true,
      eventData: null,
    }
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    totalPrice() {
      const price =
        this.contact_vault_paid.aggregate.sum.price +
        this.contact_bundle_dept.aggregate.sum.price +
        this.totalEventDept
      return price
    },
    totalPaid() {
      const price = this.contact_vault_paid.aggregate.sum.price
      return price
    },
    totalEventDept() {
      let totals = 0
      for (const event of this.events) {
        const productPrice = event.total_products_price.aggregate.sum.price
        const servicesPrice = event.total_services_price.aggregate.sum.price
        const tipsPrice = event.total_tips_price.aggregate.sum.price
        const paid = event.paid.aggregate.sum.price
        const bPaid = event.bundle_payment.aggregate.sum.price
        totals += productPrice + servicesPrice + tipsPrice - (paid - bPaid)
      }
      return totals
    },
    totalDept() {
      const price = this.totalPrice - this.totalPaid
      return price
    },
    email_marketing: function () {
      const emails = this.outgoings.filter((email) => email.type === 'EMAIL')
      return emails
    },
    sms_marketing: function () {
      const sms = this.outgoings.filter((sms) => sms.type === 'SMS')
      return sms
    },
  },
  created() {
    this.$_loading()
    this.eventData = {
      duration: 0,
      contact_id: this.contact.id || null,
      start: null,
      color: null,
      title: null,
      notes: null,
      event_type_id: null,
      end: null,
      event_services: {
        data: [
          {
            service_id: null,
            price: 0,
            staff_id: null,
            duration: 30,
            team_id: this.auth.user.team_id,
            user_id: this.auth.user.id,
          },
        ],
      },
      user_id: this.auth.user.id,
      team_id: this.auth.user.team_id,
    }
  },
  methods: {
    refreshComponent() {
      this.isReady = false
      setTimeout(() => {
        this.isReady = true
      }, 50)
    },
  },
  apollo: {
    contact_vault_paid: {
      fetchPolicy: 'no-cache',
      query: GET_CONTACT_DETAIL,
      variables() {
        return {
          contact_paid: {
            deleted_by: { _is_null: true },
            contact_id: {
              _eq: this.$route.params.id,
            },
          },
          bundle_paid: {
            contact_id: {
              _eq: this.$route.params.id,
            },
            status: {
              _eq: 'DONE',
            },
          },
          bundle_dept: {
            contact_id: {
              _eq: this.$route.params.id,
            },
            status: {
              _eq: 'PENDING',
            },
          },
          services_total: {
            contact_id: {
              _eq: this.$route.params.id,
            },
          },
          product_total: {
            contact_id: {
              _eq: this.$route.params.id,
            },
          },
          event_total: {
            contact_id: {
              _eq: this.$route.params.id,
            },
          },
          outgoings_where: {
            contact_id: {
              _eq: this.$route.params.id,
            },
          },
          notes_where: {
            contact_id: {
              _eq: this.$route.params.id,
            },
          },
          notes_order_by: this.sort_by,
          outgoings_offset: this.offset,
          outgoings_limit: this.per_page,
          outgoings_order_by: this.sort_by,
          id: this.$route.params.id,
        }
      },
      result({ data }) {
        this.contact_bundle_paid = data.contact_bundle_paid
        this.contact_bundle_dept = data.contact_bundle_dept
        this.total_services_count = data.total_services_count
        this.total_product_count = data.total_product_count
        this.total_event_count = data.total_event_count
        this.contact = data.contact
        this.genders = data.genders
        this.outgoings = data.outgoings
        this.notes = data.notes
        this.refreshComponent()
      },
    },
    events: {
      fetchPolicy: 'no-cache',
      query: GET_EVENTS_CONTACT_DETAIL,
      variables() {
        return {
          limit: this.per_page,
          offset: this.offset,
          order_by: this.sort_by,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            contact_id: {
              _eq: this.$route.params.id,
            },
            event_status: {
              code: {
                _eq: 'DONE',
              },
            },
          },
        }
      },
    },
  },
}
</script>
