<template>
  <div>
    <b-modal v-model="emailModal" hide-footer :title="$t('marketing.email.send_email')">
      <form class="mt-3" @submit.prevent="handleSubmit">
        <div class="mb-3">
          <input type="email" class="form-control" :placeholder="customer.email" disabled />
        </div>

        <div class="mb-3">
          <input
            v-model="outgoing.subject"
            type="text"
            class="form-control"
            :placeholder="$t('marketing.subject')"
          />
        </div>
        <div class="mb-3">
          <ckeditor v-model="outgoing.content" :editor="editor"></ckeditor>
        </div>

        <div class="form-group">
          <div class="col-sm-10">
            <button type="submit" class="btn btn-primary w-md">>{{ $t('buttons.send') }}</button>
          </div>
        </div>
      </form>
    </b-modal>
    <div>
      <div>
        <div class="row">
          <div class="col-md-7">
            <h4 class="card-title mb-2">{{ $t('customerCard.sendEmail') }}</h4>
          </div>
          <div class="col-md-5 text-end">
            <b-button
              v-if="customer.email !== null"
              variant="success btn-sm"
              @click="emailModal = true"
              ><i class="bx bx-mail-send font-size-16 align-middle me-1"></i
              >{{ $t('buttons.email') }}</b-button
            >
          </div>
        </div>
      </div>
      <hr class="mt-2" />
      <div class="table-responsive">
        <table class="table align-middle table-nowrap table-hover mb-0">
          <thead>
            <tr>
              <th scope="col">{{ $t('tables.smsTo') }}</th>
              <th scope="col">{{ $t('tables.schedule') }}</th>
              <th scope="col">{{ $t('menuitems.general.list.status') }}</th>
              <th scope="col" colspan="2">{{ $t('menuitems.dashboards.actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(emailItem, key) in email" :key="key">
              <td>{{ emailItem.contact.email }}</td>
              <td>{{ moment(emailItem.created_at).format('LLL') }}</td>
              <td>
                <span class="badge badge-soft-success font-size-13">
                  {{ emailItem.status === 'DONE' ? $t('tables.done') : $t('tables.pending') }}</span
                >
              </td>
              <td>
                <b-dropdown
                  toggle-class="font-size-16 text-muted p-0"
                  menu-class="dropdown-menu-end"
                  variant="white"
                  right
                >
                  <template #button-content>
                    <i class="mdi mdi-dots-horizontal"></i>
                  </template>

                  <b-dropdown-item
                    href="#"
                    @click="
                      updateModal[key] = !updateModal[key]
                      $forceUpdate()
                    "
                    >{{ $t('lists.no_record') }}</b-dropdown-item
                  >
                </b-dropdown>
              </td>
              <b-modal
                v-model="updateModal[key]"
                :title="$t('marketing.email.email_display')"
                hide-footer
              >
                <div>
                  <b-form-group
                    class="mb-3"
                    :label="$t('marketing.title')"
                    label-for="formrow-email-input"
                  >
                    <b-form-input v-model="emailItem.subject" disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="mb-3"
                    :label="$t('marketing.contents')"
                    label-for="formrow-email-input"
                  >
                    <ckeditor v-model="emailItem.content" disabled :editor="editor"></ckeditor>
                  </b-form-group>
                </div>
                <div class="text-end mt-3">
                  <b-button variant="light" @click="updateModal = {}">{{
                    $t('buttons.close')
                  }}</b-button>
                </div>
              </b-modal>
            </tr>
          </tbody>
        </table>
        <b-alert v-if="customer.email === null" show variant="danger">{{
          $t('tableInfo.noEmail')
        }}</b-alert>
        <b-alert v-if="!email.length" show variant="warning">{{ $t('tableInfo.noData') }}</b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { INSERT_OUTGOING } from '@/router/queries'
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
export default {
  name: 'EMAIL',
  components: { ckeditor: CKEditor.component },
  props: ['customer', 'outgoings', 'email'],
  data() {
    return {
      title: 'EMAIL',
      outgoing: {
        content: '',
        type: 'EMAIL',
        subject: '',
        team_id: this.customer.team_id,
        contact_id: this.$route.params.id,
      },
      moment,
      editor: ClassicEditor,
      updateModal: {},
      emailModal: false,
    }
  },
  validations: {
    outgoing: {
      content: { required },
    },
  },
  computed: {
    ...mapState(['auth']),
  },
  created() {
    this.$_loading()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$loading(false)
        return
      } else {
        this.$apollo.mutate({
          mutation: INSERT_OUTGOING,
          variables: {
            outgoing: this.outgoing,
          },
          update: () => {
            try {
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$emit('updated')
              this.$loading(false)
            } catch (e) {
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          },
        })
        this.emailModal = false
      }
      this.submitted = false
    },
  },
  apollo: {},
}
</script>
