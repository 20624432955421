<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <div class="row w-100 gy-2 gx-3 align-items-center">
          <div class="col-12 col-sm-3">
            <multiselect
              v-if="locations"
              v-model="locationFilter"
              :placeholder="$t('calendar.location_search')"
              :show-labels="false"
              :options="locations.map((x) => x.id)"
              :custom-label="(opt) => locations.find((x) => x.id === opt).name"
              @input="
                userFilter = false
                filter()
              "
            >
              <span slot="noResult">{{ $t('lists.no_record') }}</span>
              <span slot="noOptions">{{ $t('lists.no_record') }}</span>
            </multiselect>
          </div>
          <div class="col-12 col-sm-3">
            <multiselect
              v-if="users"
              v-model="userFilter"
              :placeholder="$t('calendar.staff_search')"
              :show-labels="false"
              :options="
                users
                  .filter((x) =>
                    !locationFilter || locationFilter === '0'
                      ? true
                      : x.locationIds.includes(locationFilter)
                  )
                  .map((x) => x.id)
              "
              :custom-label="
                (opt) =>
                  users.find((x) => x.id === opt).first_name +
                  ' ' +
                  users.find((x) => x.id === opt).last_name
              "
              @input="filter()"
            >
              <span slot="noResult">{{ $t('lists.no_record') }}</span>
              <span slot="noOptions">{{ $t('lists.no_record') }}</span>
            </multiselect>
          </div>
          <div class="col-sm-auto">
            <b-form-select v-model="date" class="form-select">
              <b-form-select-option value="today">{{
                $t('lists.date_range.today')
              }}</b-form-select-option>
              <b-form-select-option value="yesterday">{{
                $t('lists.date_range.yesterday')
              }}</b-form-select-option>
              <b-form-select-option value="month">{{
                $t('lists.date_range.this_month')
              }}</b-form-select-option>
              <b-form-select-option value="last_month">{{
                $t('lists.date_range.last_month')
              }}</b-form-select-option>
              <b-form-select-option value="week">{{
                $t('lists.date_range.this_week')
              }}</b-form-select-option>
              <b-form-select-option value="last_week">{{
                $t('lists.date_range.last_week')
              }}</b-form-select-option>
              <b-form-select-option value="year">{{
                $t('lists.date_range.this_year')
              }}</b-form-select-option>
              <b-form-select-option value="custom">{{
                $t('lists.date_range.custom')
              }}</b-form-select-option>
            </b-form-select>
          </div>
          <div v-if="date === 'custom'" class="col-12 col-sm-6 col-lg-auto">
            <date-picker
              v-model="start"
              value-type="YYYY-MM-DD"
              format="DD/MM/YYYY"
              :first-day-of-week="1"
              lang="en"
            ></date-picker>
          </div>
          <div v-if="date === 'custom'" class="col-12 col-sm-6 col-lg-auto">
            <date-picker
              v-model="end"
              value-type="YYYY-MM-DD"
              format="DD/MM/YYYY"
              :first-day-of-week="1"
              lang="en"
            ></date-picker>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="appointment_metrics && online_appointment_metrics && occupancy_metrics && sales_metrics"
      class="row"
    >
      <div class="col-12 col-sm-6 col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4>Total Appointments</h4>
            <h1>{{ appointments.total.count }}</h1>
            <div v-if="date !== 'custom'" class="mb-2">
              <span :class="parseInt(appointments.prev) < 0 ? 'text-danger' : 'text-success'">
                <i
                  class="bx"
                  :class="parseInt(appointments.prev) < 0 ? 'bxs-chevron-down' : 'bxs-chevron-up'"
                ></i>
                {{ isNaN(parseInt(appointments.prev)) ? '-' : parseInt(appointments.prev) }}%
                previous
                <span v-if="['today', 'yesterday'].includes(date)">day</span>
                <span v-else-if="['week', 'last_week'].includes(date)">week</span>
                <span v-else-if="['month', 'last_month'].includes(date)">month</span>
                <span v-else>year</span>
              </span>
            </div>
            <div>
              Completed
              <span class="fw-bolder"
                >{{ appointments.completed.count }} ({{
                  parseInt(appointments.completed.pct)
                }}%)</span
              >
            </div>
            <div>
              Not Completed
              <span class="fw-bolder"
                >{{ appointments.notCompleted.count }} ({{
                  parseInt(appointments.notCompleted.pct)
                }}%)</span
              >
            </div>
            <div>
              Cancelled
              <span class="fw-bolder"
                >{{ appointments.cancelled.count }} ({{
                  parseInt(appointments.cancelled.pct)
                }}%)</span
              >
            </div>
            <div>
              No-show
              <span class="fw-bolder"
                >{{ appointments.noshow.count }} ({{ parseInt(appointments.noshow.pct) }}%)</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4>Online Appointments</h4>
            <h1>{{ onlineAppointments.total.count }}</h1>
            <div v-if="date !== 'custom'" class="mb-2">
              <span :class="parseInt(onlineAppointments.prev) < 0 ? 'text-danger' : 'text-success'">
                <i
                  class="bx"
                  :class="
                    parseInt(onlineAppointments.prev) < 0 ? 'bxs-chevron-down' : 'bxs-chevron-up'
                  "
                ></i>
                {{
                  isNaN(parseInt(onlineAppointments.prev))
                    ? '-'
                    : parseInt(onlineAppointments.prev)
                }}% previous
                <span v-if="['today', 'yesterday'].includes(date)">day</span>
                <span v-else-if="['week', 'last_week'].includes(date)">week</span>
                <span v-else-if="['month', 'last_month'].includes(date)">month</span>
                <span v-else>year</span>
              </span>
            </div>
            <div>
              Completed
              <span class="fw-bolder"
                >{{ onlineAppointments.completed.count }} ({{
                  parseInt(onlineAppointments.completed.pct)
                }}%)</span
              >
            </div>
            <div>
              Not Completed
              <span class="fw-bolder"
                >{{ onlineAppointments.notCompleted.count }} ({{
                  parseInt(onlineAppointments.notCompleted.pct)
                }}%)</span
              >
            </div>
            <div>
              Cancelled
              <span class="fw-bolder"
                >{{ onlineAppointments.cancelled.count }} ({{
                  parseInt(onlineAppointments.cancelled.pct)
                }}%)</span
              >
            </div>
            <div>
              No-show
              <span class="fw-bolder"
                >{{ onlineAppointments.noshow.count }} ({{
                  parseInt(onlineAppointments.noshow.pct)
                }}%)</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4>Occupancy</h4>
            <h1>{{ parseInt(occupancyPct) }}%</h1>
            <div v-if="date !== 'custom'" class="mb-2">
              <span :class="parseInt(prevOccupancy) < 0 ? 'text-danger' : 'text-success'">
                <i
                  class="bx"
                  :class="parseInt(prevOccupancy) < 0 ? 'bxs-chevron-down' : 'bxs-chevron-up'"
                ></i>
                {{ isNaN(parseInt(prevOccupancy)) ? '-' : parseInt(prevOccupancy) }}% previous
                <span v-if="['today', 'yesterday'].includes(date)">day</span>
                <span v-else-if="['week', 'last_week'].includes(date)">week</span>
                <span v-else-if="['month', 'last_month'].includes(date)">month</span>
                <span v-else>year</span>
              </span>
            </div>
            <div>
              Working Hours
              <span class="fw-bolder"
                >{{ parseInt(occupancy_metrics?.total_working_hours / 60) }}h
                {{
                  occupancy_metrics?.total_working_hours % 60 > 0
                    ? (occupancy_metrics?.total_working_hours % 60) + 'min'
                    : ''
                }}</span
              >
            </div>
            <div>
              Booked Hours
              <span class="fw-bolder"
                >{{ parseInt(occupancy_metrics?.booked_hours.aggregate.sum.duration / 60) }}h
                {{
                  occupancy_metrics?.booked_hours.aggregate.sum.duration % 60 > 0
                    ? (occupancy_metrics?.booked_hours.aggregate.sum.duration % 60) + 'min'
                    : ''
                }}
                ({{ parseInt(occupancyPct) }}%)</span
              >
            </div>
            <div>
              Unbooked Hours
              <span class="fw-bolder"
                >{{ parseInt(occupancy_metrics?.unbooked_hours / 60) }}h
                {{
                  occupancy_metrics?.unbooked_hours % 60 > 0
                    ? (occupancy_metrics?.unbooked_hours % 60) + 'min'
                    : ''
                }}
                ({{ 100 - parseInt(occupancyPct) }}%)</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4>Total Sales</h4>
            <h1>{{ formatPrice(sales_metrics?.total_income.aggregate.sum.net_price || 0) }}</h1>
            <div v-if="date !== 'custom'" class="mb-2">
              <span :class="parseInt(prevSales.total) < 0 ? 'text-danger' : 'text-success'">
                <i
                  class="bx"
                  :class="parseInt(prevSales.total) < 0 ? 'bxs-chevron-down' : 'bxs-chevron-up'"
                ></i>
                {{ isNaN(parseInt(prevSales.total)) ? '-' : parseInt(prevSales.total) }}% previous
                <span v-if="['today', 'yesterday'].includes(date)">day</span>
                <span v-else-if="['week', 'last_week'].includes(date)">week</span>
                <span v-else-if="['month', 'last_month'].includes(date)">month</span>
                <span v-else>year</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4>Average Sale</h4>
            <h1>{{ formatPrice(sales_metrics?.total_income.aggregate.avg.net_price || 0) }}</h1>
            <div v-if="date !== 'custom'" class="mb-2">
              <span :class="parseInt(prevSales.avg) < 0 ? 'text-danger' : 'text-success'">
                <i
                  class="bx"
                  :class="parseInt(prevSales.avg) < 0 ? 'bxs-chevron-down' : 'bxs-chevron-up'"
                ></i>
                {{ isNaN(parseInt(prevSales.avg)) ? '-' : parseInt(prevSales.avg) }}% previous
                <span v-if="['today', 'yesterday'].includes(date)">day</span>
                <span v-else-if="['week', 'last_week'].includes(date)">week</span>
                <span v-else-if="['month', 'last_month'].includes(date)">month</span>
                <span v-else>year</span>
              </span>
            </div>
            <p>
              Sales Count
              <span class="fw-bolder">{{ sales_metrics?.total_income.aggregate.count }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="col-12 col-sm-6 col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4>Client Retention</h4>
            <h1>69</h1>
            <p>completed <span>8 (12%)</span></p>
            <p>completed <span>8 (12%)</span></p>
            <p>completed <span>8 (12%)</span></p>
            <p>completed <span>8 (12%)</span></p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import {
  APPOINTMENTS_METRICS,
  ONLINE_APPOINTMENTS_METRICS,
  OCCUPANCY_METRICS,
  SALES_METRICS,
  GET_LOCATIONS,
  GET_USER_AGENDA,
} from '../../queries'
import { mapState } from 'vuex'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/tr'

export default {
  page: {
    title: 'metrics',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: { PageHeader, DatePicker },
  data() {
    return {
      title: this.$t('reports.metrics.title'),
      items: [
        {
          text: this.$t('reports.title'),
          href: '/',
        },
        {
          text: this.$t('reports.metrics.title'),
          active: true,
        },
      ],
      locationFilter: '0',
      moment,
      date: 'today',
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      staff_locations: undefined,
      userFilter: false,
    }
  },
  computed: {
    ...mapState(['auth']),
    appointments() {
      return {
        total: { count: this.appointment_metrics?.total_appointments.aggregate.count },
        completed: {
          count: this.appointment_metrics?.completed_appointments.aggregate.count,
          pct: this.pct(
            this.appointment_metrics?.completed_appointments.aggregate.count,
            this.appointment_metrics?.total_appointments.aggregate.count
          ),
        },
        notCompleted: {
          count: this.appointment_metrics?.not_completed_appointments.aggregate.count,
          pct: this.pct(
            this.appointment_metrics?.not_completed_appointments.aggregate.count,
            this.appointment_metrics?.total_appointments.aggregate.count
          ),
        },
        cancelled: {
          count: this.appointment_metrics?.cancelled_appointments.aggregate.count,
          pct: this.pct(
            this.appointment_metrics?.cancelled_appointments.aggregate.count,
            this.appointment_metrics?.total_appointments.aggregate.count
          ),
        },
        noshow: {
          count: this.appointment_metrics?.noshow_appointments.aggregate.count,
          pct: this.pct(
            this.appointment_metrics?.noshow_appointments.aggregate.count,
            this.appointment_metrics?.total_appointments.aggregate.count
          ),
        },
        prev:
          ((this.appointment_metrics?.total_appointments.aggregate.count -
            this.appointment_metrics?.prev_appointments.aggregate.count) /
            this.appointment_metrics?.prev_appointments.aggregate.count) *
          100,
      }
    },
    onlineAppointments() {
      return {
        total: { count: this.online_appointment_metrics?.total_appointments.aggregate.count },
        completed: {
          count: this.online_appointment_metrics?.completed_appointments.aggregate.count,
          pct: this.pct(
            this.online_appointment_metrics?.completed_appointments.aggregate.count,
            this.online_appointment_metrics?.total_appointments.aggregate.count
          ),
        },
        notCompleted: {
          count: this.online_appointment_metrics?.not_completed_appointments.aggregate.count,
          pct: this.pct(
            this.online_appointment_metrics?.not_completed_appointments.aggregate.count,
            this.online_appointment_metrics?.total_appointments.aggregate.count
          ),
        },
        cancelled: {
          count: this.online_appointment_metrics?.cancelled_appointments.aggregate.count,
          pct: this.pct(
            this.online_appointment_metrics?.cancelled_appointments.aggregate.count,
            this.online_appointment_metrics?.total_appointments.aggregate.count
          ),
        },
        noshow: {
          count: this.online_appointment_metrics?.noshow_appointments.aggregate.count,
          pct: this.pct(
            this.online_appointment_metrics?.noshow_appointments.aggregate.count,
            this.online_appointment_metrics?.total_appointments.aggregate.count
          ),
        },
        prev:
          ((this.online_appointment_metrics?.total_appointments.aggregate.count -
            this.online_appointment_metrics?.prev_appointments.aggregate.count) /
            this.online_appointment_metrics?.prev_appointments.aggregate.count) *
          100,
      }
    },
    rows() {
      if (this.bundles_aggregate && this.bundles_aggregate.aggregate)
        return this.bundles_aggregate.aggregate.count
      return 0
    },
    // eslint-disable-next-line vue/return-in-computed-property
    dateFilter() {
      const date = new Date()
      if (this.date === 'today') {
        const dates = {
          start: moment().format('YYYY-MM-DD'),
          end: moment().add(1, 'days').format('YYYY-MM-DD'),
          prev: moment().add(-1, 'days').format('YYYY-MM-DD'),
        }
        return dates
      }
      if (this.date === 'yesterday') {
        const dates = {
          start: moment().add(-1, 'days').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
          prev: moment().add(-2, 'days').format('YYYY-MM-DD'),
        }
        return dates
      }
      if (this.date === 'month') {
        const dates = {
          start: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD'),
          end: moment().add(1, 'days').format('YYYY-MM-DD'),
          prev: moment().month(date.getMonth()).date(1).add(-1, 'month').format('YYYY-MM-DD'),
        }
        return dates
      }
      if (this.date === 'last_month') {
        const dates = {
          start: moment().month(date.getMonth()).date(1).add(-1, 'month').format('YYYY-MM-DD'),
          end: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD'),
          prev: moment().month(date.getMonth()).date(1).add(-2, 'month').format('YYYY-MM-DD'),
        }
        return dates
      }
      if (this.date === 'week') {
        const dates = {
          start: moment().day(1).format('YYYY-MM-DD'),
          end: moment().add(1, 'days').format('YYYY-MM-DD'),
          prev: moment().day(1).add(-7, 'days').format('YYYY-MM-DD'),
        }
        return dates
      }
      if (this.date === 'last_week') {
        const dates = {
          start: moment().day(1).add(-7, 'days').format('YYYY-MM-DD'),
          end: moment().day(1).format('YYYY-MM-DD'),
          prev: moment().day(1).add(-14, 'days').format('YYYY-MM-DD'),
        }
        return dates
      }
      if (this.date === 'year') {
        const dates = {
          start: moment().month(0).date(1).format('YYYY-MM-DD'),
          end: moment().add(1, 'days').format('YYYY-MM-DD'),
          prev: moment().month(0).date(1).add(-1, 'year').format('YYYY-MM-DD'),
        }
        return dates
      }
      if (this.date === 'custom') {
        const dates = {
          start: moment(this.start).format('YYYY-MM-DD'),
          end: moment(this.end).add(1, 'days').format('YYYY-MM-DD'),
          prev: moment().add(-1, 'days').format('YYYY-MM-DD'),
        }
        return dates
      }
    },
    totalPrice() {
      let result = 0
      for (const q of this.bundles) {
        const price = parseInt(q.price)
        result += price
      }
      return result
    },
    prevSales() {
      const total =
        ((this.sales_metrics.total_income.aggregate.sum.net_price -
          this.sales_metrics.prev_total_income.aggregate.sum.net_price) /
          this.sales_metrics.prev_total_income.aggregate.sum.net_price) *
        100
      const avg =
        ((this.sales_metrics.total_income.aggregate.avg.net_price -
          this.sales_metrics.prev_total_income.aggregate.avg.net_price) /
          this.sales_metrics.prev_total_income.aggregate.avg.net_price) *
        100
      return {
        total,
        avg,
      }
    },
    prevOccupancy() {
      return (
        ((this.occupancy_metrics?.booked_hours.aggregate.sum.duration -
          this.occupancy_metrics?.prev_booked_hours.aggregate.sum.duration) /
          this.occupancy_metrics?.prev_booked_hours.aggregate.sum.duration) *
        100
      )
    },
    occupancyPct() {
      return this.pct(
        this.occupancy_metrics?.booked_hours.aggregate.sum.duration,
        this.occupancy_metrics?.total_working_hours
      )
    },
  },
  watch: {
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  methods: {
    pct(partialValue, totalValue) {
      return (100 * partialValue) / totalValue || 0
    },
    filter() {
      this.$apollo.queries.appointment_metrics.refresh()
      this.$apollo.queries.online_appointment_metrics.refresh()
      this.$apollo.queries.occupancy_metrics.refresh()
      this.$apollo.queries.sales_metrics.refresh()
    },
  },
  apollo: {
    appointment_metrics: {
      fetchPolicy: 'no-cache',
      query: APPOINTMENTS_METRICS,
      variables() {
        return {
          team_id: this.auth.user.team_id,
          start: this.dateFilter.start,
          end: this.dateFilter.end,
          prev: this.dateFilter.prev,
          location_id:
            this.locationFilter && this.locationFilter !== '0' ? { _eq: this.locationFilter } : {},
          event_services: this.userFilter ? { staff_id: { _eq: this.userFilter } } : {},
        }
      },
      update(data) {
        return data
      },
    },
    online_appointment_metrics: {
      fetchPolicy: 'no-cache',
      query: ONLINE_APPOINTMENTS_METRICS,
      variables() {
        return {
          team_id: this.auth.user.team_id,
          start: this.dateFilter.start,
          end: this.dateFilter.end,
          prev: this.dateFilter.prev,
          location_id:
            this.locationFilter && this.locationFilter !== '0' ? { _eq: this.locationFilter } : {},
          event_services: this.userFilter ? { staff_id: { _eq: this.userFilter } } : {},
        }
      },
      update(data) {
        return data
      },
    },
    occupancy_metrics: {
      fetchPolicy: 'no-cache',
      query: OCCUPANCY_METRICS,
      variables() {
        return {
          team_id: this.auth.user.team_id,
          date: this.dateFilter.start,
          start: this.dateFilter.start,
          end: this.dateFilter.end,
          prev: this.dateFilter.prev,
          location_id:
            this.locationFilter && this.locationFilter !== '0' ? { _eq: this.locationFilter } : {},
          day: new Date(this.dateFilter.start).getDay().toString() || '7',
          user_id: this.userFilter ? { _eq: this.userFilter } : {},
        }
      },
      update(data) {
        data.total_working_hours = 0
        data.user_working_hours.forEach((item) => {
          data.total_working_hours =
            data.total_working_hours +
            moment('2022-02-02 ' + item.shift_end_time).diff(
              moment('2022-02-02 ' + item.shift_start_time),
              'minutes'
            )
          if (item.second_shift_start_time && item.second_shift_end_time) {
            data.total_working_hours =
              data.total_working_hours +
              moment('2022-02-02 ' + item.second_shift_end_time).diff(
                moment('2022-02-02 ' + item.second_shift_start_time),
                'minutes'
              )
          }
        })
        data.unbooked_hours = data.total_working_hours - data.booked_hours.aggregate.sum.duration
        return data
      },
    },
    sales_metrics: {
      fetchPolicy: 'no-cache',
      query: SALES_METRICS,
      variables() {
        return {
          team_id: this.auth.user.team_id,
          start: this.dateFilter.start,
          end: this.dateFilter.end,
          prev: this.dateFilter.prev,
          event: {
            location_id:
              this.locationFilter && this.locationFilter !== '0'
                ? { _eq: this.locationFilter }
                : {},
            event_services: this.userFilter ? { staff_id: { _eq: this.userFilter } } : {},
          },
        }
      },
      update(data) {
        return data
      },
    },
    locations: {
      fetchPolicy: 'no-cache',
      query: GET_LOCATIONS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            is_disable: { _eq: false },
            id: {
              _in: this.staff_locations,
            },
          },
        }
      },
      update({ locations }) {
        return [{ name: 'All Locations', id: '0' }, ...locations]
      },
    },
    users: {
      fetchPolicy: 'no-cache',
      query: GET_USER_AGENDA,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            role: {
              _eq: 'user',
            },
          },
        }
      },
      update(results) {
        results.users.forEach((user) => {
          user.locationIds = user.location_staffs.map((item) => item.location_id)
        })
        return results.users
      },
    },
  },
}
</script>
