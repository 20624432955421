<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto pt-1">
            <div class="dataTables_filter text-end">
              <b-input
                v-model="filter_b"
                type="search"
                :placeholder="$t('fields.search')"
              ></b-input>
            </div>
          </div>
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="date" class="form-select">
              <b-form-select-option value="today">{{
                $t('lists.date_range.today')
              }}</b-form-select-option>
              <b-form-select-option value="yesterday">{{
                $t('lists.date_range.yesterday')
              }}</b-form-select-option>
              <b-form-select-option value="month">{{
                $t('lists.date_range.this_month')
              }}</b-form-select-option>
              <b-form-select-option value="last_month">{{
                $t('lists.date_range.last_month')
              }}</b-form-select-option>
              <b-form-select-option value="week">{{
                $t('lists.date_range.this_week')
              }}</b-form-select-option>
              <b-form-select-option value="last_week">{{
                $t('lists.date_range.last_week')
              }}</b-form-select-option>
              <b-form-select-option value="year">{{
                $t('lists.date_range.this_year')
              }}</b-form-select-option>
              <b-form-select-option value="last_year">{{
                $t('lists.date_range.last_year')
              }}</b-form-select-option>
              <b-form-select-option value="custom">{{
                $t('lists.date_range.custom')
              }}</b-form-select-option>
            </b-form-select>
          </div>
          <div v-if="date === 'custom'" class="col-sm-auto pt-1">
            <date-picker
              v-model="start"
              value-type="YYYY-MM-DD"
              format="DD/MM/YYYY"
              :first-day-of-week="1"
              lang="en"
            ></date-picker>
          </div>
          <div v-if="date === 'custom'" class="col-sm-auto pt-1">
            <date-picker
              v-model="end"
              value-type="YYYY-MM-DD"
              format="DD/MM/YYYY"
              :first-day-of-week="1"
              lang="en"
            ></date-picker>
          </div>
        </b-form>
      </div>
    </div>
    <b-alert show dismissible variant="secondary">{{ $t('reports.information') }}</b-alert>
    <div v-if="reviews" class="col-md-12 col-xs-12">
      <div class="card table-responsive">
        <div class="card-body">
          <div style="overflow-x: auto">
            <b-table
              :items="reviews"
              :fields="fields"
              responsive="sm"
              hover="hover"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter_b"
              :filter-included-fields="filterOn"
              data-show-export
              @filtered="onFiltered"
            >
              <template #cell(name)="row">
                {{ row.item.contact.first_name + ' ' + row.item.contact.last_name }}
              </template>
              <template #cell(time)="row">
                {{ moment(row.item.event.start).format('LLL') }}
              </template>
              <template #cell(comment)="row">
                <b-button size="sm" class="mr-2" @click="row.toggleDetails">
                  {{ row.detailsShowing ? $t('buttons.hide') : $t('buttons.show') }}
                </b-button>
              </template>
              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"
                      ><b>{{ $t('reports.review.customer_review') }}:</b></b-col
                    >
                    <b-col>{{ row.item.comment }}</b-col>
                  </b-row>
                </b-card>
              </template>
            </b-table>
          </div>
          <b-alert class="mt-3 mb-3 text-center" :show="reviews.length == 0" variant="warning">{{
            $t('reports.no_report')
          }}</b-alert>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="page"
                    :total-rows="reviews.length"
                    :per-page="per_page"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="extensions/export/bootstrap-table-export.js"></script>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import { GET_PERMISSION, GET_REVIEWS } from '../../queries'
import { mapState } from 'vuex'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/tr'

export default {
  page: {
    title: 'reviews',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: { PageHeader, DatePicker },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    rows() {
      return this.appointment_reports.length
    },
    // eslint-disable-next-line vue/return-in-computed-property
    request() {
      const date = new Date()
      if (this.date === 'today') {
        const _and = [
          { created_at: { _gte: moment().format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'yesterday') {
        const _and = [
          { created_at: { _gte: moment().add(-1, 'days').format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'month') {
        const _and = [
          { created_at: { _gte: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_month') {
        const _and = [
          {
            created_at: {
              _gte: moment().month(date.getMonth()).date(1).add(-1, 'month').format('YYYY-MM-DD'),
            },
          },
          { created_at: { _lte: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'week') {
        const _and = [
          { created_at: { _gte: moment().day(1).format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_week') {
        const _and = [
          { created_at: { _gte: moment().day(1).add(-7, 'days').format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().day(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'year') {
        const _and = [
          { created_at: { _gte: moment().month(0).date(1).format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_year') {
        const _and = [
          { created_at: { _gte: moment().month(0).date(1).add(-1, 'year').format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().month(0).date(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'custom') {
        const _and = [
          { created_at: { _gte: moment(this.start).format('YYYY-MM-DD') } },
          { created_at: { _lte: moment(this.end).add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
    },
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
  },
  data() {
    return {
      title: this.$t('reports.review.title'),
      invoiceModal: {},
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      items: [
        {
          text: this.$t('reports.title'),
          href: '/',
        },
        {
          text: this.$t('reports.review.title'),
          active: true,
        },
      ],
      currentPage: 1,
      filterOn: [],
      sortBy: 'value',
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      filter_b: null,
      totalRows: 1,
      fields: [
        {
          key: 'name',
          label: this.$t('tables.customer'),
          sortable: true,
        },
        {
          key: 'time',
          sortable: true,
          label: this.$t('tables.date'),
        },
        {
          key: 'rating',
          sortable: true,
          label: this.$t('tables.rating'),
        },
        {
          key: 'comment',
          sortable: true,
          label: this.$t('tables.comment'),
        },
      ],
      moment,
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      date: 'today',
      servicesModal: {},
      productsModal: {},
      bundlesModal: {},
      notesModal: {},
      search_input: '',
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      staff_locations: undefined,
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  watch: {
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  mounted() {},
  apollo: {
    reviews: {
      fetchPolicy: 'no-cache',
      query: GET_REVIEWS,
      variables() {
        return {
          offset: this.offset,
          limit: this.per_page,
          order_by: this.sort_by,
          where: {
            _and: this.request,
            contact: {
              location_contacts: {
                location_id: {
                  _in: this.staff_locations,
                },
              },
            },
          },
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
