<template>
  <div>
    <b-modal
      v-model="showModal"
      no-close-on-backdrop
      :title="$t('navbar.dropdown.quick_menu.add_appointment')"
      hide-footer
      size="xl"
    >
      <template #modal-header>
        <div class="w-100">
          <div class="row">
            <div class="col-md-6">
              <h4 class="card-title">
                {{ $t('navbar.dropdown.quick_menu.add_appointment') }}
              </h4>
            </div>
          </div>
        </div>
      </template>
      <Loader :loading="contacts === undefined || users === undefined">
        <div v-if="contacts && users">
          <form @submit.prevent="handleSubmit">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="col-12">
                  <div v-if="locations" class="mb-3">
                    <label for="location">{{ $t('appointment.location') }}</label>
                    <multiselect
                      v-model="eventData.location_id"
                      :placeholder="$t('fields.location')"
                      :show-labels="false"
                      :options="locations.map((x) => x.id)"
                      :custom-label="(opt) => locations.find((x) => x.id === opt).name"
                      @input="
                        eventData.event_services.data = []
                        addService()
                      "
                    >
                      <span slot="noResult">{{ $t('lists.no_record') }}</span>
                      <span slot="noOptions">{{ $t('lists.no_record') }}</span>
                    </multiselect>
                    <div
                      v-if="submitted && !$v.eventData.location_id.required"
                      class="invalid-feedback"
                    >
                      {{ $t('fields.required') }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label for="name">{{ $t('appointment.customer') }}</label>
                    <multiselect
                      v-model="selectedContact"
                      :placeholder="$t('fields.search_customer')"
                      :show-labels="false"
                      :options="contacts"
                      track-by="id"
                      :custom-label="setContactsLabel"
                      :class="{
                        'is-invalid': submitted && $v.eventData.contact_id.$error,
                      }"
                      :searchable="true"
                      :internal-search="false"
                      :options-limit="300"
                      :clear-on-select="false"
                      @search-change="filterContacts"
                    >
                      <span slot="noResult">
                        {{ $t('lists.no_record') }}
                      </span>
                      <span slot="noOptions">
                        {{ $t('lists.no_record') }}
                      </span>
                    </multiselect>
                    <div
                      v-if="submitted && !$v.eventData.contact_id.required"
                      class="invalid-feedback"
                    >
                      {{ $t('fields.required') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row mb-3">
                    <div class="col-12">
                      <label class="control-label">{{ $t('appointment.date') }}</label>
                      <VueCtkDateTimePicker
                        id="event-date"
                        v-model="eventData.start"
                        only-date
                        formatted="LL"
                        color="#2a3042"
                        button-color="#2a3042"
                        :button-now-translation="$t('buttons.now')"
                        :label="$t('fields.choose')"
                        :error="submitted && $v.eventData.start.$error"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <b-checkbox v-model="isRepeating" inline plain>
                    {{ $t('appointment.is_repeating') }}
                  </b-checkbox>
                </div>
                <div v-if="isRepeating" class="col-md-12">
                  <div class="row mb-3">
                    <div class="col-12">
                      <label class="control-label">{{
                        $t('appointment.number_of_repetitions')
                      }}</label>
                      <b-form-input
                        v-model="eventData.repetitions"
                        type="number"
                        min="1"
                        max="24"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-12">
                      <label class="control-label">{{ $t('appointment.repetition_range') }}</label>
                      <multiselect
                        v-model="eventData.repeating_range"
                        :placeholder="$t('appointment.repetition_range')"
                        :show-labels="false"
                        :options="ranges.map((x) => x.id)"
                        :custom-label="
                          (opt) => {
                            return ranges.find((x) => x.id == opt).name
                          }
                        "
                        :multiple="false"
                      >
                        <span slot="noResult">{{ $t('lists.no_record') }}</span>
                        <span slot="noOptions">{{ $t('lists.no_record') }}</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-8">
                <div class="col-md-12">
                  <div class="row mb-2">
                    <div class="col-md-4">
                      <h4 class="card-title">{{ $t('appointment.services') }}</h4>
                    </div>
                    <div class="col-md-8 text-end">
                      <b-button
                        class="me-2"
                        variant="warning btn-sm"
                        @click="contactModal = true"
                        >{{ $t('buttons.addCustomer') }}</b-button
                      >
                      <b-button variant="success btn-sm" @click="addService">{{
                        $t('appointment.add_services')
                      }}</b-button>
                    </div>
                  </div>
                </div>
                <div
                  v-for="(service, key) in eventData.event_services.data"
                  :key="key"
                  class="col-md-12"
                >
                  <div class="row">
                    <div class="col-md-3">
                      <label for="staff">{{ $t('appointment.staff') }}</label>
                      <multiselect
                        v-model="service.staff_id"
                        :show-labels="false"
                        :placeholder="$t('appointment.staff')"
                        :options="
                          users
                            .filter((x) => x.locationIds.includes(eventData.location_id))
                            .map((x) => x.id)
                        "
                        :custom-label="
                          (opt) =>
                            users.find((x) => x.id == opt).first_name +
                            ' ' +
                            users.find((x) => x.id == opt).last_name
                        "
                        :class="{
                          'is-invalid': submitted && !service.staff_id,
                        }"
                      >
                        <span slot="noResult">
                          {{ $t('lists.no_record') }}
                        </span>
                        <span slot="noOptions">
                          {{ $t('lists.no_record') }}
                        </span>
                      </multiselect>
                      <div v-if="submitted && !service.staff_id" class="invalid-feedback">
                        {{ $t('fields.required') }}
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label for="service">{{ $t('service.service') }}</label>
                      <multiselect
                        v-if="services"
                        v-model="service.service_id"
                        :placeholder="$t('appointment.services')"
                        :show-labels="false"
                        :options="
                          services
                            .filter(
                              (x) =>
                                x.locationIds.includes(eventData.location_id) &&
                                (service.staff_id
                                  ? x.groupIds.includes(
                                      users.find((u) => u.id === service.staff_id).user_group.id
                                    )
                                  : false)
                            )
                            .map((x) => x.id)
                        "
                        :custom-label="(opt) => services.find((x) => x.id == opt).name"
                        :class="{
                          'is-invalid': submitted && !service.service_id,
                        }"
                        @input="setServicePriceAndDuration(service.service_id, key)"
                      >
                        <span slot="noResult">
                          {{ $t('lists.no_record') }}
                        </span>
                        <span slot="noOptions">
                          {{ $t('lists.no_record') }}
                        </span>
                      </multiselect>
                      <div v-if="submitted && !service.service_id" class="invalid-feedback">
                        {{ $t('fields.required') }}
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label for="price">{{ $t('appointment.price') }}</label>
                      <input
                        id="title"
                        v-model="service.price"
                        :disabled="
                          bundle_services.find((x) => x.service_id === service.service_id) &&
                          bundle_services.find((x) => x.service_id === service.service_id)
                            .amount !== 0
                        "
                        type="text"
                        :placeholder="$t('appointment.price')"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            submitted && (service.price === '' || service.price === null),
                        }"
                      />
                      <div
                        v-if="submitted && (service.price === '' || service.price === null)"
                        class="invalid-feedback"
                      >
                        {{ $t('fields.required') }}
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label for="duration">{{ $t('service.duration') }}</label>
                      <input
                        id="duration"
                        v-model="service.duration"
                        type="text"
                        :placeholder="$t('service.duration')"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            submitted && (service.duration === '' || service.duration === null),
                        }"
                      />
                      <div
                        v-if="submitted && (service.duration === '' || service.duration === null)"
                        class="invalid-feedback"
                      >
                        {{ $t('fields.required') }}
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label for="time">{{ $t('service.time') }}</label>
                      <date-picker
                        :id="`serice-time-` + key"
                        v-model="service.time"
                        type="time"
                        format="hh:mm A"
                        value-type="HH:mm"
                        :minute-step="5"
                        placeholder="Select time"
                        :time-picker-options="{
                          start: '00:00',
                          step: '00:15',
                          end: '23:55',
                          format: 'hh:mm A',
                        }"
                      />
                    </div>
                    <div class="col-md-12 mt-1">
                      <div class="row">
                        <div class="col-md-6">
                          <b-button
                            v-if="
                              eventData.event_services.data[0] !==
                              eventData.event_services.data[key]
                            "
                            variant="danger btn-sm"
                            @click="eventData.event_services.data.splice(-1, key)"
                          >
                            {{ $t('buttons.delete') }}
                          </b-button>
                        </div>
                        <div class="col-md-6 text-end">
                          <p
                            v-if="
                              bundle_services.find((x) => x.service_id === service.service_id) &&
                              bundle_services.find((x) => x.service_id === service.service_id)
                                .amount !== 0
                            "
                            class="text-info mb-0"
                          >
                            {{ $t('appointment.no_package_for_the_customer') }}
                          </p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <label class="control-label">{{ $t('appointment.notes') }}</label>
                  <textarea v-model="eventData.notes" class="form-control" rows="5"></textarea>
                </div>
              </div>
            </div>

            <div class="text-end mt-3">
              <b-button variant="light" @click="$emit('closeModal')">
                {{ $t('buttons.close') }}
              </b-button>
              <b-button type="submit" variant="success" class="ms-1">
                {{ $t('buttons.submit') }}
              </b-button>
            </div>
          </form>
          <!-- add customer -->
          <add-contact :add-contact-modal="contactModal" @close="closeContactModal" />
        </div>
      </Loader>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  INSERT_EVENT,
  GET_CONTACTS_AGENDA,
  GET_USER_AGENDA,
  GET_BUNDLE_SERVICES,
  GET_SERVICES_AGENDA,
  GET_LOCATIONS,
  GET_PERMISSION,
} from '@/router/queries'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import 'vue2-datepicker/locale/tr'
import Loader from './loader'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import DatePicker from 'vue2-datepicker'
import AddContact from '@/components/widgets/addContact'

export default {
  name: 'Appointment',
  components: { Multiselect, Loader, VueCtkDateTimePicker, DatePicker, AddContact },
  props: {
    addEventModal: {
      type: Boolean,
    },
    appointmentData: {
      type: Object,
      default: () => {},
    },
    contact: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      submitted: false,
      appointmentID: null,
      eventData: null,
      contactModal: false,
      bundle_services: [],
      events: [],
      filters: [],
      locations: [],
      searchContactInput: '',
      selectedContact: this.contact || null,
      isRepeating: false,
      ranges: [
        {
          id: 1,
          name: '1 ' + this.$t('appointment.weeks'),
        },
        {
          id: 2,
          name: '2 ' + this.$t('appointment.weeks'),
        },
        {
          id: 3,
          name: '3 ' + this.$t('appointment.weeks'),
        },
        {
          id: 5,
          name: '5 ' + this.$t('appointment.weeks'),
        },
        {
          id: 6,
          name: '6 ' + this.$t('appointment.weeks'),
        },
      ],
    }
  },
  computed: {
    ...mapState(['auth']),
    showModal: {
      get() {
        return this.addEventModal
      },
      set() {
        this.$emit('closeModal')
      },
    },
    durationTime() {
      let result = 0
      for (const q of this.eventData.event_services.data) {
        const duration = q.duration
        result += duration
      }
      return result
    },
    searchContact() {
      return {
        _ilike: this.searchContactInput ? '%' + this.searchContactInput + '%' : undefined,
      }
    },
    searchFullName() {
      return {
        _in: this.searchContactInput.split(' '),
      }
    },
  },
  watch: {
    appointmentData(val) {
      this.eventData = val
    },
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.filters.location_filter = {
          team_id: {
            _eq: this.auth.user.team_id,
          },
          is_disable: { _eq: false },
        }
      } else {
        this.filters.location_filter = {
          location_staffs: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
          is_disable: { _eq: false },
        }
      }
      this.getLocations()
    },
    selectedContact(contact) {
      this.eventData.contact_id = contact.id
    },
  },
  created() {
    this.eventData = {
      duration: 0,
      contact_id: this.$route.params.id || null,
      start: null,
      color: null,
      notes: null,
      event_type_id: null,
      end: null,
      event_services: {
        data: [
          {
            service_id: null,
            price: 0,
            staff_id: null,
            duration: 30,
            team_id: this.auth.user.team_id,
            user_id: this.auth.user.id,
            start: null,
            time: null,
          },
        ],
      },
      location_id: null,
      team_id: this.auth.user.team_id,
      user_id: this.auth.user.id,
    }
    if (this.appointmentData && Object.keys(this.appointmentData).length)
      this.eventData = { ...this.appointmentData }
    if (this.eventData.contact_id) this.getBundle(this.eventData.contact_id)
  },
  validations: {
    eventData: {
      duration: { required },
      contact_id: { required },
      location_id: { required },
      start: { required },
    },
  },
  methods: {
    datesSet(e) {
      this.start = e.startStr
      this.end = e.endStr
    },
    handleSubmit() {
      console.log('submit')
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      let serviceIsValid = true
      this.eventData.event_services.data.forEach((el) => {
        // el.start = moment(el.start)
        //   .set('date', moment(this.eventData.start).date())
        //   .set('month', moment(this.eventData.start).month())
        //   .set('year', moment(this.eventData.start).year())
        //   .format('YYYY-MM-DD hh:mm a')
        if (!el.service_id || !el.staff_id || !el.duration || !el.time) serviceIsValid = false
        if (!el.price) el.price = 0
      })
      if (this.$v.$invalid || !serviceIsValid) {
        this.$loading(false)
        return
      } else {
        this.eventData.event_services.data.forEach((el) => {
          el.start = moment(this.eventData.start).format('YYYY-MM-DD') + ' ' + el.time
          el.end = moment(el.start, 'YYYY-MM-DD HH:mm')
            .add(el.duration, 'minutes')
            .format('YYYY-MM-DD HH:mm')
          delete el.time
        })
        this.eventData.group_id = moment().format('x')
        this.$apollo.mutate({
          mutation: INSERT_EVENT,
          variables: {
            event: {
              ...this.eventData,
            },
          },
          update: (cache, { data: { insert_events_one } }) => {
            try {
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$loading(false)
              this.appointmentID = insert_events_one.id
              this.$emit('close', insert_events_one.id)
              this.$emit('closeModal')
            } catch (e) {
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          },
        })
        this.createModal = false
      }
      this.submitted = false
    },
    addService() {
      const lastService = this.eventData.event_services.data.slice(-1).pop()
      const time = lastService
        ? moment(lastService.time, 'HH:mm').add(lastService.duration, 'm').format('HH:mm')
        : null
      this.eventData.event_services.data.push({
        service_id: null,
        price: 0,
        staff_id: null,
        duration: 30,
        team_id: this.auth.user.team_id,
        user_id: this.auth.user.id,
        time,
      })
    },
    filterContacts(searchKey) {
      if (searchKey) this.searchContactInput = searchKey
    },
    getBundle(id) {
      this.$apollo
        .query({
          query: GET_BUNDLE_SERVICES,
          variables: {
            where: {
              team_id: {
                _eq: this.auth.user.team_id,
              },
              bundle: {
                contact_id: {
                  _eq: id,
                },
              },
            },
          },
        })
        .then((response) => {
          this.bundle_services = response.data.bundle_services
        })
    },
    setServicePriceAndDuration(service_id, key) {
      if (
        this.bundle_services.find((x) => x.service_id === service_id) &&
        this.bundle_services.find((x) => x.service_id === service_id).amount !== 0
      ) {
        this.eventData.event_services.data[key].duration = this.services.find(
          (x) => x.id === service_id
        ).duration
        this.eventData.event_services.data[key].price = 0
        this.eventData.duration = this.durationTime
      } else {
        this.eventData.event_services.data[key].duration = this.services.find(
          (x) => x.id === service_id
        ).duration
        this.eventData.event_services.data[key].price = this.services.find(
          (x) => x.id === service_id
        ).price
        this.eventData.duration = this.durationTime
      }
    },
    async closeContactModal(id) {
      this.contactModal = false
      await this.$apollo.queries.contacts.refresh()
      if (id) this.eventData.contact_id = id
    },
    getLocations() {
      this.$apollo
        .query({
          query: GET_LOCATIONS,
          variables: {
            where: this.filters.location_filter,
          },
        })
        .then((response) => {
          this.locations = response.data.locations
        })
    },
    setContactsLabel(opt) {
      const item = this.contacts.find((x) => x.id == opt.id)
      return item
        ? (item.first_name || '') + ' ' + (item.last_name || '') + ' | ' + (item.phone || '-')
        : 'N/A'
    },
  },
  apollo: {
    contacts: {
      fetchPolicy: 'no-cache',
      query: GET_CONTACTS_AGENDA,
      variables() {
        return {
          limit: 15,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            _or: [
              {
                first_name: this.searchContact,
              },
              {
                last_name: this.searchContact,
              },
              {
                email: this.searchContact,
              },
              {
                phone: this.searchContact,
              },
              {
                first_name: this.searchFullName,
              },
              {
                last_name: this.searchFullName,
              },
            ],
          },
        }
      },
      update(results) {
        if (this.selectedContact) {
          results.contacts.push(this.selectedContact)
        }
        return results.contacts
      },
    },
    users: {
      fetchPolicy: 'no-cache',
      query: GET_USER_AGENDA,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            role: {
              _eq: 'user',
            },
            status: {
              _eq: 'ACTIVE',
            },
          },
        }
      },
      update(results) {
        results.users.forEach((user) => {
          user.locationIds = user.location_staffs.map((item) => item.location_id)
        })
        return results.users
      },
    },
    services: {
      fetchPolicy: 'no-cache',
      query: GET_SERVICES_AGENDA,
      variables() {
        return {
          where: {
            is_disabled: {
              _eq: false,
            },
            team_id: {
              _eq: this.auth.user.team_id,
            },
          },
        }
      },
      update(results) {
        results.services.forEach((service) => {
          service.locationIds = service.location_services.map((item) => item.location_id)
          service.groupIds = service.service_groups.map((item) => item.group.id)
        })
        return results.services
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
