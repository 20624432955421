<template>
  <div v-if="users && event_products && products" class="card">
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-md-9">
          <h4 class="card-title mb-4">{{ $t('product.products') }}</h4>
        </div>
        <div class="col-md-3 text-end">
          <button type="button" class="btn btn-success btn-sm" @click="newProduct">
            <i class="bx bx-plus font-size-16 align-middle me-1"></i>{{ $t('product.add_product') }}
          </button>
          <b-modal
            v-if="addProduct === true"
            v-model="addProduct"
            :title="$t('product.add_product')"
            title-class="text-black font-18"
            body-class="p-3"
            hide-footer
          >
            <form @submit.prevent="saveProduct">
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="name">{{ $t('product.product') }}</label>
                    <multiselect
                      v-model="product.product_id"
                      :show-labels="false"
                      :placeholder="$t('buttons.placeHolderSelect')"
                      :options="products.map((x) => x.id)"
                      :custom-label="(opt) => products.find((x) => x.id == opt).name"
                      :class="{
                        'is-invalid': submitted && $v.product.product_id.$error,
                      }"
                    >
                      <span slot="noResult"> {{ $t('product.product_not_found') }}... </span>
                      <span slot="noOptions"> {{ $t('product.product_not_found') }}... </span>
                    </multiselect>
                    <div
                      v-if="submitted && !$v.product.product_id.required"
                      class="invalid-feedback"
                    >
                      {{ $t('fields.required') }}
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label for="date">{{ $t('product.product_name') }}</label>
                    <input
                      id="date"
                      v-model="product.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.product.name.$error,
                      }"
                    />
                    <div v-if="submitted && !$v.product.name.required" class="invalid-feedback">
                      {{ $t('fields.required') }}
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label for="date">{{ $t('product.product_price') }}</label>
                    <input
                      id="date"
                      v-model="product.price"
                      type="number"
                      step="0.01"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.product.price.$error,
                      }"
                    />
                    <div v-if="submitted && !$v.product.price.required" class="invalid-feedback">
                      {{ $t('fields.required') }}
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label for="name">{{ $t('product.sales_staff') }}</label>
                    <multiselect
                      v-model="product.staff_id"
                      :show-labels="false"
                      :placeholder="$t('buttons.placeHolderSelect')"
                      :options="users.map((x) => x.id)"
                      :custom-label="
                        (opt) =>
                          users.find((x) => x.id == opt).first_name +
                          ' ' +
                          users.find((x) => x.id == opt).last_name
                      "
                    >
                      <span slot="noResult"> {{ $t('staff.staff_not_found') }}... </span>
                      <span slot="noOptions"> {{ $t('staff.staff_not_found') }}... </span>
                    </multiselect>
                    <div v-if="submitted && !$v.product.staff_id.required" class="invalid-feedback">
                      {{ $t('fields.required') }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-end pt-5 mt-3">
                <b-button variant="light" @click="addProduct = !addProduct">{{
                  $t('buttons.close')
                }}</b-button>
                <b-button type="submit" variant="success" class="ms-1">
                  {{ $t('buttons.save') }}
                </b-button>
              </div>
            </form>
          </b-modal>
        </div>
      </div>
      <div v-for="(data, key) in event_products" :key="key" class="row mt-4">
        <div class="col-md-5 border" style="padding: 0.4em !important">
          <b-input-group>
            <b-form-input v-model="data.name"></b-form-input>
          </b-input-group>
        </div>
        <div class="col-md-5 border" style="padding: 0.4em !important">
          <b-form-input v-model="data.price" disabled></b-form-input>
        </div>
        <div class="col-md-2 border" style="padding: 0.5em !important">
          <div class="btn-group" style="padding: 0.4em 0.4em 0.4em 0.4em !important">
            <button
              type="button"
              style="margin-right: 3px"
              class="btn btn-info btn-sm waves-effect waves-light rounded-pill"
              @click="
                updateProductModal[key] = !updateProductModal[key]
                $forceUpdate()
              "
            >
              <i class="bx bx-show align-middle"></i>
            </button>
            <button
              type="button"
              class="btn btn-danger btn-sm waves-effect waves-light rounded-pill"
              @click="removeProduct(data)"
            >
              <i class="far fa-trash-alt"></i>
            </button>
          </div>
          <b-modal
            v-model="updateProductModal[key]"
            :title="$t('appointment.edit_appointment')"
            title-class="text-black font-18"
            body-class="p-3"
            hide-footer
          >
            <form @submit.prevent="updateProduct(data)">
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="date">{{ $t('product.product_name') }}</label>
                    <input id="date" v-model="data.name" type="text" class="form-control" />
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label for="date">{{ $t('product.product_price') }}</label>
                    <input id="date" v-model="data.price" type="text" class="form-control" />
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label for="name">{{ $t('product.product') }}</label>
                    <multiselect
                      v-model="data.product_id"
                      :show-labels="false"
                      :placeholder="$t('buttons.placeHolderSelect')"
                      :options="products.map((x) => x.id)"
                      :custom-label="(opt) => products.find((x) => x.id == opt).name"
                    >
                      <span slot="noResult"> {{ $t('product.product_not_found') }}... </span>
                      <span slot="noOptions"> {{ $t('product.product_not_found') }}... </span>
                    </multiselect>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label for="name">{{ $t('product.sales_staff') }}</label>
                    <multiselect
                      v-model="data.staff_id"
                      :show-labels="false"
                      :placeholder="$t('buttons.placeHolderSelect')"
                      :options="users.map((x) => x.id)"
                      :custom-label="(opt) => users.find((x) => x.id == opt).first_name"
                    >
                      <span slot="noResult"> {{ $t('staff.staff_not_found') }}... </span>
                      <span slot="noOptions"> {{ $t('staff.staff_not_found') }}... </span>
                    </multiselect>
                  </div>
                </div>
              </div>

              <div class="text-end pt-5 mt-3">
                <b-button variant="light" @click="updateProductModal = {}">{{
                  $t('buttons.close')
                }}</b-button>
                <b-button type="submit" variant="success" class="ms-1">
                  {{ $t('buttons.save') }}
                </b-button>
              </div>
            </form>
          </b-modal>
        </div>
      </div>
      <b-alert v-if="!event_products.length" show variant="warning">{{
        $t('product.customer_product_not_specified')
      }}</b-alert>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import {
  DELETE_EVENT_PRODUCTS,
  UPDATE_EVENT_PRODUCTS,
  GET_EVENT_PRODUCTS,
  INSERT_EVENT_PRODUCT,
  GET_PRODUCTS_AGENDA,
  GET_USERS_AGENDA,
} from '@/router/queries'
import moment from 'moment'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },
  props: ['event'],
  data() {
    return {
      moment,
      updateProductModal: {},
      addProduct: false,
      submitted: false,
      product: null,
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  validations: {
    product: {
      name: { required },
      product_id: { required },
      price: { required },
      staff_id: { required },
    },
  },
  watch: {
    'product.product_id': function (val) {
      this.product.name = this.products.find((x) => x.id === val).name
      this.product.price = this.products.find((x) => x.id === val).price
    },
  },
  created() {
    this.$_loading()
  },
  methods: {
    newProduct() {
      this.product = {
        product_id: null,
        staff_id: this.auth.user.id,
        name: null,
        price: null,
        contact_id: this.event.contact_id,
        event_id: this.$route.params.key,
        team_id: this.auth.user.team_id,
        user_id: this.auth.user.id,
      }
      this.addProduct = true
    },
    removeProduct(data) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: DELETE_EVENT_PRODUCTS,
        variables: {
          id: data.id,
        },
        update: () => {
          try {
            this.$loading(false)
            this.$apollo.queries.event_products.refresh()
            this.$emit('updated')
            this.$_N({
              type: 'success',
              position: 'center',
            })
          } catch (e) {
            this.$loading(false)
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
    },
    updateProduct(data) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_EVENT_PRODUCTS,
        variables: {
          id: data.id,
          product: this.$_ER(data, ['__typename']),
        },
        update: () => {
          try {
            this.$loading(false)
            this.$apollo.queries.event_products.refresh()
            this.$emit('updated')
            this.$_N({
              type: 'success',
              position: 'center',
            })
          } catch (e) {
            this.$loading(false)
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
      this.updateProductModal = {}
    },
    saveProduct() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        // eslint-disable-next-line no-console
        console.log('hata')
        this.$loading(false)
        return
      } else {
        this.$apollo.mutate({
          mutation: INSERT_EVENT_PRODUCT,
          variables: {
            product: this.product,
          },
          update: () => {
            try {
              this.$loading(false)
              this.$apollo.queries.event_products.refresh()
              this.$emit('updated')
              this.$_N({
                type: 'success',
                position: 'center',
              })
            } catch (e) {
              this.$loading(false)
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          },
        })
        this.addProduct = false
      }
      this.submitted = false
    },
  },
  apollo: {
    users: {
      fetchPolicy: 'no-cache',
      query: GET_USERS_AGENDA,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            role: {
              _eq: 'user',
            },
          },
        }
      },
    },
    event_products: {
      fetchPolicy: 'no-cache',
      query: GET_EVENT_PRODUCTS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            event_id: {
              _eq: this.$route.params.key,
            },
          },
        }
      },
      result({ data }) {
        this.$emit('checkData', data.event_products)
      },
    },
    products: {
      fetchPolicy: 'no-cache',
      query: GET_PRODUCTS_AGENDA,
      variables() {
        return {
          where: {
            is_disabled: {
              _eq: false,
            },
            team_id: {
              _eq: this.auth.user.team_id,
            },
          },
        }
      },
    },
  },
}
</script>
