const pieChart = {
  series: [90, 10],
  chartOptions: {
    labels: ['Başarılı', 'Başarısız'],
    colors: ['#018c5c', '#FF0000'],
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: false,
      fontSize: '14px',
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  },
}

const activitiesData = [
  {
    id: '#1',
    date: '03 Mart, 2021',
    email: 'ufuk@ufukcam.com',
    status: 'Başarılı',
  },
  {
    id: '#2',
    date: '03 Mart, 2021',
    email: 'mustafa@bilpp.com',
    status: 'Başarılı',
  },
  {
    id: '#3',
    date: '03 Mart, 2021',
    email: 'yasin@ergun.com',
    status: 'Başarılı',
  },
]

export { pieChart, activitiesData }
