import store from '@/state/store'

export default [
  {
    path: '/',
    meta: {
      authRequired: true,
    },
    component: require('@/router/layouts/main').default,
    children: [
      {
        path: '',
        name: 'default',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/dashboards/default').default,
      },
      {
        path: 'forms',
        name: 'form.list',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/forms/list').default,
      },
      {
        path: 'form/:key/',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/forms/details/index').default,
        children: [
          {
            path: '',
            name: 'form.show',
            meta: {
              authRequired: true,
            },
            component: require('@/router/views/forms/details/detail').default,
          },
          {
            path: 'questions',
            name: 'form.questions',
            meta: {
              authRequired: true,
            },
            component: require('@/router/views/forms/details/questions.vue').default,
          },
          {
            path: 'settings',
            name: 'form.settings',
            meta: {
              authRequired: true,
            },
            component: require('@/router/views/forms/details/settings.vue').default,
          },
          {
            path: 'answers',
            name: 'form.answers',
            meta: {
              authRequired: true,
            },
            component: require('@/router/views/forms/details/answers.vue').default,
          },
        ],
      },
      {
        path: 'contacts',
        name: 'contact.lists',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'clients'],
        },
        component: require('@/router/views/contacts/list').default,
      },
      {
        path: 'contact/create',
        name: 'contact.create',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'clients'],
        },
        component: require('@/router/views/contacts/create').default,
      },
      {
        path: 'contacts/:id/',
        name: 'contact.show',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'clients', 'clients.show'],
        },
        component: require('@/router/views/contacts/showV2.vue').default,
      },
      {
        path: '/agenda',
        name: 'agenda.show',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/staff/agenda').default,
      },
      {
        path: '/agenda/:key',
        name: 'agenda.detail',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/staff/agenda_detail').default,
      },
      {
        path: '/calendar',
        name: 'calendar.show',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/staff/agenda').default,
      },
      {
        path: '/calendar/list',
        name: 'calendar.list',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/staff/list-agenda').default,
      },
      {
        path: 'marketings/email',
        name: 'marketing.email.lists',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'marketing'],
        },
        component: require('@/router/views/marketings/email/list').default,
      },
      {
        path: 'marketings/email/:id/show',
        name: 'marketing.email.show',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'marketing'],
        },
        component: require('@/router/views/marketings/email/show').default,
      },
      {
        path: '/marketings/sms',
        name: 'marketing.sms.lists',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'marketing'],
        },
        component: require('@/router/views/marketings/sms/list').default,
      },
      {
        path: 'marketings/sms/:id/show',
        name: 'marketing.sms.show',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'marketing'],
        },
        component: require('@/router/views/marketings/sms/show').default,
      },
      {
        path: '/source-types',
        name: 'source-type.index',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/source-types/list').default,
      },
      {
        path: '/services',
        name: 'service.index',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'system.settings'],
        },
        component: require('@/router/views/services/list').default,
      },
      {
        path: '/bookings',
        name: 'booking.index',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/bookings/list').default,
      },
      {
        path: '/invoices',
        name: 'invoices',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'accounting.case'],
        },
        component: require('@/router/views/accounting/invoices').default,
      },
      // {
      //   path: '/booking-settings',
      //   name: 'booking.settings',
      //   meta: {
      //     authRequired: true,
      //   },
      //   component: require('@/router/views/bookings/settings').default,
      // },
      {
        path: '/actions',
        name: 'actions.index',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/actions/list').default,
      },
      {
        path: '/products',
        name: 'products.list',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'system.settings'],
        },
        component: require('@/router/views/products/list').default,
      },
      {
        path: '/reporting/appointment',
        name: 'reporting.appointment',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'reporting'],
        },
        component: require('@/router/views/reporting/appointment').default,
      },
      {
        path: '/reporting/due-payment',
        name: 'reporting.due',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'reporting'],
        },
        component: require('@/router/views/reporting/duePayment').default,
      },
      {
        path: '/reporting/marketing',
        name: 'reporting.marketing',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'reporting'],
        },
        component: require('@/router/views/reporting/marketing').default,
      },
      {
        path: '/reporting/credit',
        name: 'reporting.credit',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'reporting'],
        },
        component: require('@/router/views/reporting/credit').default,
      },
      {
        path: '/reporting/bundles',
        name: 'reporting.bundles',
        meta: {
          authRequired: true,
          permissions: ['administrator'],
        },
        component: require('@/router/views/reporting/bundles.vue').default,
      },
      {
        path: '/reporting/metrics',
        name: 'reporting.metrics',
        meta: {
          authRequired: true,
          permissions: ['administrator'],
        },
        component: require('@/router/views/reporting/metrics.vue').default,
      },
      {
        path: '/reporting/customer',
        name: 'reporting.customer',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'reporting'],
        },
        component: require('@/router/views/reporting/customer').default,
      },
      {
        path: '/reporting/reviews',
        name: 'reporting.reviews',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'reporting'],
        },
        component: require('@/router/views/reporting/reviews').default,
      },
      {
        path: '/reporting/sales',
        name: 'reporting.sales',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'reporting'],
        },
        component: require('@/router/views/reporting/sales').default,
      },
      {
        path: '/teams/profile',
        name: 'team.profile',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'system.settings'],
        },
        component: require('@/router/views/teams/profile').default,
      },
      {
        path: '/guide',
        name: 'team.setup',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/setup/setup').default,
      },
      {
        path: '/teams/kvkk',
        name: 'team.kvkk',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/teams/kvkk').default,
      },
      {
        path: '/staff/:key/profile',
        name: 'staff.show',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'system.settings'],
        },
        component: require('@/router/views/staff/Profile.vue').default,
      },
      {
        path: '/staff',
        name: 'staff.lists',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'system.settings'],
        },
        component: require('@/router/views/staff/list').default,
      },
      {
        path: '/locations',
        name: 'locations.lists',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'system.settings'],
        },
        component: require('@/router/views/locations/list').default,
      },
      {
        path: '/close-dates',
        name: 'menuitems.systems.list.close_dates',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'system.settings'],
        },
        component: require('@/router/views/close-dates/list').default,
      },
      {
        path: '/activities',
        name: 'activities.lists',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/staff/activities').default,
      },
      {
        path: '/staff-groups',
        name: 'staff-group.lists',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'system.settings'],
        },
        component: require('@/router/views/staff-groups/list').default,
      },
      {
        path: '/working-hours',
        name: 'working-hour.update',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'system.settings'],
        },
        component: require('@/router/views/working-hours/update').default,
      },
      {
        path: '/accounting/case',
        name: 'accounting.case',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'accounting.case'],
        },
        component: require('@/router/views/accounting/case/show').default,
      },
      {
        path: '/notifications',
        name: 'notification.update',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'system.settings'],
        },
        component: require('@/router/views/notifications/update').default,
      },
      {
        path: '/packages',
        name: 'package.lists',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'packages', 'general'],
        },
        component: require('@/router/views/packages/list').default,
      },
      {
        path: '/packages/sms',
        name: 'package.sms',
        meta: {
          authRequired: true,
          permissions: ['administrator', 'packages'],
        },
        component: require('@/router/views/packages/smsPackage').default,
      },
      {
        path: '/invoices',
        name: 'invoice.lists',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/invoices/list').default,
      },
      {
        path: '/invoices/:id/show',
        name: 'invoice.show',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/invoices/show').default,
      },
      {
        path: '/status',
        name: 'status.lists',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/status/default').default,
      },
      {
        path: 'bundles',
        name: 'bundles.list',
        meta: {
          authRequired: true,
          permissions: ['administrator'],
        },
        component: require('@/router/views/bundles/list').default,
      },
      {
        path: 'bundle/:key',
        name: 'bundle.show',
        meta: {
          authRequired: true,
          permissions: ['administrator'],
        },
        component: require('@/router/views/bundles/show').default,
      },
      {
        path: 'bundle/print/:key',
        name: 'bundle.print',
        meta: {
          authRequired: true,
          permissions: ['administrator'],
        },
        component: require('@/router/views/bundles/print').default,
      },
      {
        path: 'stripe',
        name: 'stripe',
        meta: {
          authRequired: true,
          permissions: ['administrator'],
        },
        component: require('@/router/views/stripe/list').default,
      },
      {
        path: 'stripe/:id/',
        name: 'stripe.details',
        meta: {
          authRequired: true,
          permissions: ['administrator'],
        },
        component: require('@/router/views/stripe/index.vue').default,
      },
      {
        path: 'tracking',
        name: 'tracking',
        meta: {
          authRequired: true,
        },
        component: require('@/router/views/tracking/index').default,
      },
    ],
  },
  {
    path: '/book',
    name: 'book',
    component: require('@/router/views/bookings/public').default,
  },
  {
    path: '/review/:key',
    name: 'review',
    component: require('@/router/views/review/review').default,
  },
  {
    path: '/login',
    name: 'login',
    component: require('@/router/views/account/login').default,
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn']) {
          next({ name: 'default' })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: require('@/router/views/account/register').default,
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn']) {
          next({ name: 'default' })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: require('@/router/views/account/forgot-password').default,
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn']) {
          next({ name: 'default' })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/reset-password',
    name: 'Reset password',
    component: require('@/router/views/account/reset-password').default,
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn']) {
          next({ name: 'default' })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
          store.dispatch('auth/logOut')
        } else {
          store.dispatch('authfack/logout')
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) => route.push('/login'))
        next(authRequiredOnPreviousRoute ? { name: 'default' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/expired',
    name: 'expired',
    component: require('@/router/views/utility/expired').default,
  },
  {
    path: '/payment-success',
    name: 'success',
    component: require('@/router/views/utility/success').default,
  },
  {
    path: '/payment-cancel',
    name: 'cancel',
    component: require('@/router/views/utility/cancel').default,
  },
  {
    path: '/404',
    name: '404',
    component: require('@/router/views/utility/404').default,
  },
  {
    path: '/*',
    redirect: '404',
  },
]
