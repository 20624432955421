<template>
  <div>
    <b-modal
      v-model="showModal"
      :title="isUpdate ? $t('staff.edit_staff') : $t('staff.add_staff')"
      hide-footer
    >
      <Loader :loading="user_groups === undefined">
        <div v-if="user_groups">
          <form @submit.prevent="handleSubmit">
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="first_name">{{ $t('customerForm.name') }}</label>
                  <input
                    id="first_name"
                    v-model="staff.first_name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.staff.first_name.$error,
                    }"
                  />
                  <div v-if="submitted && !$v.staff.first_name.required" class="invalid-feedback">
                    {{ $t('fields.required') }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="last_name">{{ $t('customerForm.surname') }}</label>
                  <input
                    id="last_name"
                    v-model="staff.last_name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.staff.last_name.$error,
                    }"
                  />
                  <div v-if="submitted && !$v.staff.last_name.required" class="invalid-feedback">
                    {{ $t('fields.required') }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="title">{{ $t('customerForm.title') }}</label>
                  <input
                    id="title"
                    v-model="staff.title"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.staff.title.$error,
                    }"
                  />
                  <div v-if="submitted && !$v.staff.title.required" class="invalid-feedback">
                    {{ $t('fields.required') }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <b-form-group
                    class="mb-3"
                    :label="$t('customerForm.phone')"
                    label-for="formrow-password-input"
                  >
                    <tel-input
                      v-model="staff.phone"
                      :is-invalid="submitted && $v.staff.phone.$error"
                      :class="{
                        'is-invalid': submitted && $v.staff.phone.$error,
                      }"
                    />
                    <div v-if="submitted && !$v.staff.phone.required" class="invalid-feedback">
                      {{ $t('fields.required') }}
                    </div>
                  </b-form-group>
                </div>
              </div>
              <div class="col-6">
                <label for="user_group_id">{{ $t('staff.staff_group') }}</label>
                <multiselect
                  id="user_group_id"
                  v-model="staff.user_group_id"
                  :show-labels="false"
                  :placeholder="$t('fields.choose')"
                  :class="{
                    'is-invalid': submitted && $v.staff.user_group_id.$error,
                  }"
                  :options="user_groups.map((x) => x.id)"
                  :custom-label="(opt) => user_groups.find((x) => x.id == opt).name"
                >
                  <span slot="noResult">
                    {{ $t('lists.no_record') }}
                  </span>
                  <span slot="noOptions">
                    {{ $t('lists.no_record') }}
                  </span>
                </multiselect>
                <div v-if="submitted && !$v.staff.user_group_id.required" class="invalid-feedback">
                  {{ $t('fields.required') }}
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="email">{{ $t('customerForm.email') }}</label>
                  <input
                    id="email"
                    v-model="staff.email"
                    type="email"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.staff.email.$error,
                    }"
                  />
                  <div v-if="submitted && !$v.staff.email.required" class="invalid-feedback">
                    {{ $t('fields.required') }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="email">{{ $t('staff.color') }}</label>
                  <input
                    id="email"
                    v-model="staff.color"
                    type="color"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.staff.color.$error,
                    }"
                  />
                  <div v-if="submitted && !$v.staff.color.required" class="invalid-feedback">
                    {{ $t('fields.required') }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="instagram">{{ $t('customerForm.instagram') }}</label>
                  <input
                    id="instagram"
                    v-model="staff.instagram"
                    type="text"
                    class="form-control"
                    placeholder="https://instagram.com/username"
                    :class="{
                      'is-invalid': submitted && $v.staff.instagram.$error,
                    }"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="tiktok">{{ $t('customerForm.tiktok') }}</label>
                  <input
                    id="tiktok"
                    v-model="staff.tiktok"
                    type="text"
                    class="form-control"
                    placeholder="https://tiktok.com/username"
                    :class="{
                      'is-invalid': submitted && $v.staff.tiktok.$error,
                    }"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="facebook">{{ $t('customerForm.facebook') }}</label>
                  <input
                    id="facebook"
                    v-model="staff.facebook"
                    type="text"
                    class="form-control"
                    placeholder="https://facebook.com/username"
                    :class="{
                      'is-invalid': submitted && $v.staff.facebook.$error,
                    }"
                  />
                </div>
              </div>
              <div class="col-12">
                <locations-input
                  :label="$t('customerForm.works_at')"
                  :input-data="staff.location_staffs"
                  :is-invalid="submitted && !locationIsValid"
                  @setLocations="setLocations"
                />
              </div>
              <div class="col-12 mt-2">
                <b-form-group class="mb-3" :label="$t('staff.image')" for="fileinput">
                  <b-form-file
                    id="fileinput"
                    v-model="imageFile"
                    :state="Boolean(imageFile)"
                    accept="image/jpeg, image/png, image/jpg"
                    placeholder="Choose an image or drop it here..."
                    drop-placeholder="Drop file here..."
                    @input="fileUpload"
                  ></b-form-file>
                </b-form-group>
              </div>
              <div class="col-12">
                <div class="mb-3 mt-3">
                  <label for="notes">{{ $t('customerForm.notes') }}</label>
                  <b-form-textarea
                    id="notes"
                    v-model="staff.notes"
                    :placeholder="$t('customerForm.notes_description')"
                    rows="3"
                  ></b-form-textarea>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="isActive">{{ $t('customerForm.status') }}</label>
                  <multiselect
                    id="isActive"
                    v-model="staff.status"
                    :show-labels="false"
                    :placeholder="$t('fields.choose')"
                    :class="{
                      'is-invalid': submitted && $v.staff.user_group_id.$error,
                    }"
                    :options="valueType.map((x) => x.key)"
                    :custom-label="(opt) => valueType.find((x) => x.key == opt).name"
                  >
                    <span slot="noResult">
                      {{ $t('lists.no_record') }}
                    </span>
                    <span slot="noOptions">
                      {{ $t('lists.no_record') }}
                    </span>
                  </multiselect>
                  <div v-if="submitted && !$v.staff.status.required" class="invalid-feedback">
                    {{ $t('fields.required') }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <b-checkbox v-model="staff.show_online" inline plain>
                  {{ $t('service.show_online') }}
                </b-checkbox>
              </div>
            </div>
            <b-alert v-model="emailError" variant="danger" class="mt-3" dismissible>
              {{ $t('customerCard.email_registered') }}
            </b-alert>
            <div class="text-end mt-3">
              <b-button variant="light" @click="showModal = false">{{
                $t('buttons.close')
              }}</b-button>
              <b-button type="submit" variant="success" class="ms-1">{{
                $t('buttons.save')
              }}</b-button>
            </div>
          </form>
        </div>
      </Loader>
    </b-modal>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import {
  ADD_USER,
  GET_USER_GROUP,
  CHANGE_LOCATION_STAFFS,
  UPDATE_PERSONAL,
  CHECK_USER,
} from '@/router/queries'
import Loader from './loader'
import Multiselect from 'vue-multiselect'
import { uploadService } from '@/helpers/upload.service.js'

export default {
  components: {
    Loader,
    Multiselect,
  },
  props: {
    addStaffModal: {
      type: Boolean,
    },
    updateItemData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      submitted: false,
      staff: null,
      isUpdate: null,
      locationChanged: false,
      locationIsValid: true,
      emailError: false,
      imageFile: null,
      valueType: [
        {
          key: 'ACTIVE',
          name: this.$t('customerForm.active'),
        },
        {
          key: 'INACTIVE',
          name: this.$t('customerForm.inactive'),
        },
      ],
    }
  },
  validations: {
    staff: {
      first_name: { required },
      last_name: { required },
      phone: { required },
      email: { required, email },
      user_group_id: { required },
      color: { required },
      status: { required },
      title: { required },
      facebook: {
        reg: (v) =>
          // eslint-disable-next-line
          v === null || v === ''
            ? true
            : new RegExp(/(?:https?:\/\/)?(?:www.)?(facebook)(?:.com\/)?([@a-zA-Z0-9-_]+)/im).test(
                v
              ),
      },
      instagram: {
        reg: (v) =>
          // eslint-disable-next-line
          v === null || v === ''
            ? true
            : new RegExp(/(?:https?:\/\/)?(?:www.)?(instagram)(?:.com\/)?([@a-zA-Z0-9-_]+)/im).test(
                v
              ),
      },
      tiktok: {
        reg: (v) =>
          // eslint-disable-next-line
          v === null || v === ''
            ? true
            : new RegExp(/(?:https?:\/\/)?(?:www.)?(tiktok)(?:.com\/)?([@a-zA-Z0-9-_]+)/im).test(v),
      },
    },
  },
  computed: {
    ...mapState(['auth']),
    showModal: {
      get() {
        return this.addStaffModal
      },
      set() {
        this.$emit('close')
      },
    },
  },
  watch: {
    updateItemData: {
      handler(val) {
        if (val.id) {
          this.staff = { ...val }
          this.isUpdate = true
        } else this.initData()
      },
    },
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.isUpdate = false
      this.staff = {
        first_name: null,
        last_name: null,
        phone: null,
        user_group_id: null,
        team_id: this.auth.user.team_id,
        email: null,
        color: '#FFFFFF',
        location_staffs: {
          data: [],
        },
        staff_working_hours: {
          data: [],
        },
        status: null,
        title: null,
        facebook: null,
        instagram: null,
        tiktok: null,
        notes: null,
        show_online: true,
      }
    },
    async handleSubmit() {
      if (this.isUpdate) {
        this.update()
        return
      }
      this.create()
    },
    setLocations(val) {
      this.locationChanged = true
      const locations = []
      val.forEach((el) => {
        locations.push({
          location_id: el.id,
          user_id: this.staff.id,
          team_id: this.auth.user.team_id,
        })
      })
      this.staff.location_staffs.data = locations
    },
    async create() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.staff.location_staffs.data.length === 0) {
        this.locationIsValid = false
      } else this.locationIsValid = true
      if (this.$v.$invalid || !this.locationIsValid) {
        this.$loading(false)
        return
      } else {
        if ((await this.checkUser()) === false) return this.$loading(false)
        this.staff.password = (Math.random() + 1).toString(36).substring(2)
        this.staff.need_update_profile = true
        this.$apollo.mutate({
          mutation: ADD_USER,
          variables: {
            user: this.staff,
          },
          update: () => {
            try {
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$loading(false)
              this.$emit('close')
              this.$emit('submitted')
            } catch {
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          },
        })
      }
      this.submitted = false
    },
    async checkUser() {
      let status = false
      await this.$apollo
        .mutate({
          mutation: CHECK_USER,
          variables: {
            email: this.staff.email,
          },
        })
        .then((res) => {
          if (res.data.user.aggregate.count > 0) {
            status = false
            this.emailError = true
          } else status = true
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
          status = false
        })
      return status
    },
    async update() {
      this.$loading(true)
      let user = this.staff
      this.submitted = true
      this.$v.$touch()
      if (
        this.staff.location_staffs.data &&
        this.staff.location_staffs.data.length === 0 &&
        this.staff.location_staffs.length === 0
      )
        this.locationIsValid = false
      else this.locationIsValid = true
      if (this.$v.$invalid || !this.locationIsValid) {
        this.$loading(false)
        return
      } else {
        if (this.locationChanged) {
          await this.changeLocationStaffs()
        }
        this.locationChanged = false
        this.$apollo.mutate({
          mutation: UPDATE_PERSONAL,
          variables: {
            user: this.$_ER(user, [
              '__typename',
              'user_group',
              'location_staffs',
              'staff_working_hours',
            ]),
            id: user.id,
          },
          update: () => {
            try {
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$emit('close')
              this.$emit('submitted')
              this.$loading(false)
            } catch (e) {
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          },
        })
      }
    },
    async changeLocationStaffs() {
      this.$loading(true)
      await this.$apollo
        .mutate({
          mutation: CHANGE_LOCATION_STAFFS,
          variables: {
            locationStaffs: this.staff.location_staffs.data,
            where: { user_id: { _eq: this.staff.id } },
          },
        })
        .then(() => {
          this.staff.location_staffs.data = []
          return
        })
        .catch(() => {
          this.staff.location_staffs.data = []
          this.$_N({
            type: 'error',
            position: 'center',
          })
        })
    },
    async fileUpload(file) {
      this.$loading(true)
      try {
        const image = await uploadService({
          file: file,
          name: this.staff.last_name + '-contact-image',
          team_id: this.auth.user.team_id,
          user_id: this.auth.user.id,
        })
        this.staff.image = image
      } catch (err) {
        this.$_N({
          type: 'error',
          position: 'center',
        })
      }
      this.$loading(false)
    },
  },
  apollo: {
    user_groups: {
      fetchPolicy: 'no-cache',
      query: GET_USER_GROUP,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
          },
        }
      },
    },
  },
}
</script>
