<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto pt-1">
            <date-picker
              v-model="start"
              :placeholder="start"
              :first-day-of-week="1"
              lang="en"
            ></date-picker>
          </div>
          <div class="col-sm-auto">
            <date-picker
              v-model="end"
              :placeholder="end"
              :first-day-of-week="1"
              lang="en"
            ></date-picker>
          </div>
          <div class="col-sm-auto">
            <div class="dataTables_filter text-end">
              <b-input
                v-model="filter_b"
                type="search"
                :placeholder="$t('fields.search')"
              ></b-input>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <b-alert show dismissible variant="secondary"
      >Tarih filtrelemesini kullanarak istediğiniz tarih aralığında rapor
      oluşturabilirsiniz!</b-alert
    >
    <div v-if="outgoings" class="row">
      <div class="d-flex justify-content-between mb-3">
        <div class="col-md-12 col-xs-12">
          <div class="card p-2 table-responsive">
            <b-table
              :items="outgoings"
              :fields="fields"
              responsive="sm"
              hover="hover"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter_b"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(status)="data">
                {{
                  data.value === 'DONE'
                    ? $t('marketing.sms.successful')
                    : $t('marketing.sms.unsuccessful')
                }}
              </template>
              <template #cell(marketing_id)="data">
                {{ !data.value ? 'Tekil' : 'Toplu Pazarlama' }}
              </template>
              <template #cell(actions)="row">
                <button
                  type="button"
                  class="btn btn-success btn-sm waves-effect waves-light"
                  @change="row"
                  @click="
                    $router.push({
                      name: 'marketing.email.show',
                      params: { id: row.item.id },
                    })
                  "
                >
                  <i class="bx bx-show font-size-14 align-middle mr-2"></i>
                </button>
              </template>
            </b-table>
            <b-alert class="mt-3 mb-3 text-center" :show="outgoings.length == 0" variant="warning"
              >Bugüne ait rapor oluşturulamadı. Tarih aralığını değiştirebilirsiniz.</b-alert
            >
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="page"
                    :total-rows="outgoings.length"
                    :per-page="per_page"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
          <!--<div class="card p-2 table-responsive" v-if="this.$apollo.queries.contacts_reports.refresh()">
            <h3 class="mb-3 mt-3 text-center"><small class="text-muted">Kayıt Bulunamadı!</small></h3>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import { GET_OUTGOINGS_REPORT, GET_PERMISSION } from '../../queries'
import { mapState } from 'vuex'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'

export default {
  page: {
    title: 'Pazarlama Raporları',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: { PageHeader, DatePicker },
  data() {
    return {
      title: 'Pazarlama Raporları',
      items: [
        {
          text: 'Raporlar',
          href: '/',
        },
        {
          text: 'Pazarlama Raporları',
          active: true,
        },
      ],
      currentPage: 1,
      filterOn: [],
      sortBy: 'value',
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      filter_b: null,
      totalRows: 1,
      fields: [
        {
          key: 'contact.first_name',
          label: 'Müşter Adı',
          sortable: true,
        },
        {
          key: 'contact.last_name',
          sortable: true,
          label: 'Müşteri Soyadı',
        },
        {
          key: 'type',
          sortable: true,
          label: 'Pazarlama Türü',
        },
        {
          key: 'marketing_id',
          sortable: true,
          label: 'Gönderim Şekli',
        },
        {
          key: 'status',
          sortable: true,
          label: 'Durumu',
        },
        {
          key: 'actions',
          sortable: true,
          label: 'İşlemler',
        },
      ],
      moment,
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      servicesModal: {},
      productsModal: {},
      bundlesModal: {},
      notesModal: {},
      search_input: '',
      start: moment(this.today).subtract(1, 'days').format('YYYY-MM-DD'),
      end: moment(this.today).add(0, 'days').format('YYYY-MM-DD'),
      staff_locations: undefined,
    }
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    rows() {
      return this.outgoings.length
    },
  },
  watch: {
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  mounted() {},
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  apollo: {
    outgoings: {
      fetchPolicy: 'no-cache',
      query: GET_OUTGOINGS_REPORT,
      variables() {
        return {
          offset: this.offset,
          limit: this.per_page,
          order_by: this.sort_by,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            created_at: {
              _gte: this.start,
              _lte: this.end,
            },
          },
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
