<script>
import { authMethods, notificationMethods } from '@/state/helpers'
import { mapState } from 'vuex'

import appConfig from '@/app.config'
import { required, email } from 'vuelidate/lib/validators'

/**
 * Login component
 */
export default {
  page: {
    title: 'login',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      loading: false,
    }
  },

  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    ...mapState('auth', ['status']),
    notification() {
      return this.$store ? this.$store.state.notification : null
    },
    getItem() {
      return localStorage.getItem('user')
    },
  },
  created() {
    this.$_loading()
    this.loginCheck()
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    loginCheck() {
      if (localStorage.getItem('user')) {
        /* eslint-disable no-console */
        this.$router.push('/')
      }
    },
    async tryToLogIn() {
      this.submitted = true
      this.loading = true
      this.$loading(false)
      this.$v.$touch()
      if (this.$v.$invalid) {
        return (this.loading = false)
      } else {
        const { email, password } = this.user
        if (email && password) {
          this.login({
            input: this.user,
          }).then((res) => {
            console.log('finish', res)
            this.loading = false
          })
        }
      }
    },
  },
}
</script>

<template>
  <div v-if="!getItem">
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col-xl-9 login-barespace d-none d-xl-block">
          <div class="auth-full-bg pt-lg-5 p-4">
            <div class="w-100">
              <div class="bg-overlay"></div>
              <!--
              <div class="d-flex h-100 flex-column">
                <div class="p-4 mt-auto">
                  <div class="row justify-content-center">
                    <div class="col-lg-7">
                      <div class="text-center">
                        <h4 class="mb-3">
                          <i
                            class="
                              bx
                              bxs-quote-alt-left
                              text-primary
                              h1
                              align-middle
                              mr-3
                              text-white
                            "
                          ></i
                          ><span class="text-primary text-white"
                            >{{ $t('login.testimonial.title') }}
                          </span>
                        </h4>

                        <div dir="ltr" class="owl-theme">
                          <b-carousel
                            id="carousel-1"
                            :interval="4000"
                            indicators
                          >
                            <b-carousel-slide>
                              <p class="font-size-16 mb-4 text-white">
                                {{ $t('login.testimonial.comments.comment_1') }}
                              </p>
                              <div>
                                <h4
                                  class="font-size-16 text-primary text-white"
                                >
                                  {{ $t('login.testimonial.comments.comment_1_name') }}
                                </h4>
                              </div>
                            </b-carousel-slide>

                            <b-carousel-slide>
                              <p class="font-size-16 mb-4 text-white">
                                {{ $t('login.testimonial.comments.comment_2') }}
                              </p>
                              <div>
                                <h4
                                  class="font-size-16 text-primary text-white"
                                >
                                  {{ $t('login.testimonial.comments.comment_2_name') }}
                                </h4>
                              </div>
                            </b-carousel-slide>
                          </b-carousel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-xl-3">
          <div class="auth-full-page-content p-md-5 p-4">
            <div class="w-100">
              <div class="d-flex flex-column h-100">
                <div class="mb-4 mb-md-4">
                  <router-link to="/" class="d-block auth-logo">
                    <img
                      src="@/assets/images/logo_gray_mini.png"
                      alt=""
                      height="37"
                      class="auth-logo-dark"
                    />
                    <img
                      src="@/assets/images/logo_gray_mini.png"
                      alt=""
                      height="37"
                      class="auth-logo-light"
                    />
                  </router-link>
                </div>
                <div class="my-auto">
                  <div>
                    <h5 class="text-primary">
                      {{ $t('login.text') }} <strong>{{ $t('login.text_2') }}</strong>
                    </h5>
                    <p class="text-muted">
                      {{ $t('login.sub_text') }}
                    </p>
                  </div>
                  <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{
                    authError
                  }}</b-alert>

                  <div v-if="notification.message" :class="'alert ' + notification.type">
                    {{ notification.message }}
                  </div>
                  <div class="mt-4">
                    <form @submit.prevent="tryToLogIn">
                      <b-form-group
                        id="email-group"
                        class="mb-3"
                        :label="$t('login.labels.email')"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="user.email"
                          type="email"
                          :class="{
                            'is-invalid': submitted && $v.user.email.$error,
                          }"
                        ></b-form-input>
                        <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                          <span v-if="!$v.user.email.required">{{
                            $t('login.email_required')
                          }}</span>
                          <span v-if="!$v.user.email.email">{{ $t('login.email_required') }}</span>
                        </div>
                      </b-form-group>

                      <b-form-group
                        id="password-group"
                        class="mb-3"
                        :label="$t('login.labels.password')"
                        label-for="password"
                      >
                        <b-form-input
                          id="password"
                          v-model="user.password"
                          type="password"
                          :class="{
                            'is-invalid': submitted && $v.user.password.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitted && !$v.user.password.required"
                          class="invalid-feedback"
                        >
                          {{ $t('login.password_required') }}
                        </div>
                      </b-form-group>

                      <div class="mt-3 d-grid">
                        <b-overlay
                          :show="loading"
                          rounded
                          opacity="0.6"
                          spinner-small
                          spinner-variant="primary"
                          class="d-inline-block"
                        >
                          <b-button type="submit" variant="primary" class="w-100">{{
                            $t('login.submit')
                          }}</b-button>
                        </b-overlay>
                      </div>
                      <div class="mt-4 text-center">
                        <router-link tag="a" to="/forgot-password" class="text-muted">
                          <i class="mdi mdi-lock me-1"></i> {{ $t('login.forgot_password') }}
                        </router-link>
                      </div>
                    </form>
                    <!-- <div class="mt-5 text-center">
                      <p>
                        {{ $t('login.no_account_text') }}
                        <router-link to="/register" class="fw-medium text-primary">
                          {{ $t('login.no_account_link') }}
                        </router-link>
                      </p>
                    </div> -->
                  </div>
                </div>

                <div class="mt-4 mt-md-5 text-center">
                  <p class="mb-0">
                    ©
                    {{ new Date().getFullYear() }} {{ $t('general.brand_name') }}
                    <i class="mdi mdi-heart text-danger"></i> {{ $t('general.country') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container-fluid -->
  </div>
</template>

<style lang="scss" scoped>
::v-deep .carousel-caption {
  position: relative !important;
  right: 0;
  bottom: 1.25rem;
  left: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #495057;
  text-align: center;
}
@media only screen and (max-width: 700px) {
  .login-barespace {
    display: none;
  }
}

::v-deep .carousel-indicators li {
  background-color: rgba(85, 110, 230, 0.25);
}
</style>
