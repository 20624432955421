<template>
  <div>
    <PageHeader :title="title" :items="items" />

    <div class="col-md-12 col-xs-12">
      <div class="card">
        <div class="card-body">
          <div class="overflow-auto">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>{{ $t('locations.inputs.name') }}</th>
                  <th>{{ $t('locations.inputs.createdAt') }}</th>
                  <th>{{ $t('locations.inputs.email') }}</th>
                  <th>{{ $t('locations.inputs.phone') }}</th>
                  <th>{{ $t('locations.inputs.address') }}</th>
                  <th>{{ $t('locations.inputs.actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(location, key) in locations" :key="key">
                  <td>{{ location.name }}</td>
                  <td>
                    {{ moment(location.created_at).format('Do MMMM YYYY HH:ss') }}
                  </td>
                  <td>{{ location.email }}</td>
                  <td>{{ location.phone }}</td>
                  <td>
                    {{ location.address }}
                  </td>
                  <td>
                    <div class="button-items">
                      <button
                        type="button"
                        class="btn btn-warning btn-sm"
                        @click="
                          updateModal[key] = !updateModal[key]
                          $forceUpdate()
                        "
                      >
                        <i class="bx bx-edit font-size-16 align-middle me-1"></i>
                        {{ $t('buttons.edit') }}
                      </button>
                    </div>
                  </td>
                  <b-modal v-model="updateModal[key]" hide-footer size="xl" :title="location.name">
                    <div v-for="(work, workKey) in location.location_working_hours" :key="workKey">
                      <div class="row">
                        <div class="col-md-2 col-xs-12">
                          <h5 class="font-size-15 mt-2">
                            {{ work.name }}
                            <i class="mdi mdi-arrow-right text-primary"></i>
                          </h5>
                          <div class="form-check form-switch form-switch-md mb-3">
                            <input
                              id="SwitchCheckSizemd"
                              v-model="work.active"
                              class="form-check-input"
                              type="checkbox"
                              @change="update(work)"
                            />
                          </div>
                        </div>
                        <div class="col-md-10 col-xs-12">
                          <div class="row">
                            <div class="col-lg-3">
                              <b-form-group
                                id="example-time"
                                class="mb-3"
                                :label="$t('locations.inputs.workingHours.workStart')"
                                label-for="time"
                              >
                                <b-form-input
                                  id="time"
                                  v-model="work.work_start"
                                  type="time"
                                  @focusout="update(work)"
                                ></b-form-input>
                              </b-form-group>
                            </div>
                            <div class="col-lg-3">
                              <b-form-group
                                id="input-group-1"
                                class="mb-3"
                                :label="$t('locations.inputs.workingHours.workEnd')"
                                label-for="time"
                              >
                                <b-form-input
                                  id="time"
                                  v-model="work.work_end"
                                  type="time"
                                  @focusout="update(work)"
                                ></b-form-input>
                              </b-form-group>
                            </div>
                            <div class="col-lg-3">
                              <b-form-group
                                class="mb-3"
                                :label="$t('locations.inputs.workingHours.restStart')"
                                label-for="formrow-inputCity"
                              >
                                <b-form-input
                                  id="formrow-inputCity"
                                  v-model="work.rest_start"
                                  type="time"
                                  @focusout="update(work)"
                                ></b-form-input>
                              </b-form-group>
                            </div>
                            <div class="col-lg-3">
                              <b-form-group
                                id="input-group-1"
                                class="mb-3"
                                :label="$t('locations.inputs.workingHours.restEnd')"
                                label-for="formrow-inputState"
                              >
                                <b-form-input
                                  id="formrow-inputState"
                                  v-model="work.rest_end"
                                  type="time"
                                  @focusout="update(work)"
                                ></b-form-input>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-modal>
                </tr>
              </tbody>
            </table>
            <b-alert :show="locations.length === 0" variant="warning" class="text-center mt-3">
              Not found locations
            </b-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import { mapState } from 'vuex'
import moment from 'moment'
import { UPDATE_WORK_HOURS, GET_LOCATIONS_WORKING_HOURS } from '@/router/queries'
import PageHeader from '@/components/page-header'

export default {
  name: 'WorkHours',
  page: {
    title: 'working_hours',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: { PageHeader },
  data() {
    return {
      title: this.$t('working_hours.working_hours'),
      items: [
        {
          text: this.$t('working_hours.working_hours'),
          href: '/',
        },
        {
          text: '',
          active: true,
        },
      ],
      updateModal: {},
      showModal: false,
      moment,
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  created() {
    this.$_loading()
  },
  methods: {
    update(work) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_WORK_HOURS,
        variables: {
          work_hours: this.$_ER(work, ['__typename', 'name']),
          id: work.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$apollo.queries.locations.refresh()
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$apollo.queries.locations.refresh()
            this.$loading(false)
          }
        },
      })
    },
  },
  apollo: {
    locations: {
      fetchPolicy: 'no-cache',
      query: GET_LOCATIONS_WORKING_HOURS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            is_disable: { _eq: false },
          },
        }
      },
    },
  },
}
</script>
