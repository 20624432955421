<template>
  <div v-if="products">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="sort_by" class="form-select">
              <b-form-select-option :value="{ created_at: 'desc' }">
                {{ $t('sort.newest') }}
              </b-form-select-option>
              <b-form-select-option :value="{ created_at: 'asc' }">
                {{ $t('sort.oldest') }}
              </b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto">
            <b-input
              id="inlineFormInputName2"
              v-model="search_input"
              :placeholder="$t('fields.search')"
              @input="$apollo.queries.products.stop()"
            ></b-input>
          </div>
          <div class="col-sm-auto">
            <b-button variant="success" @click="filter">
              {{ $t('buttons.placeHolderSearch') }}
            </b-button>
          </div>
          <div class="col-sm-auto">
            <b-button variant="danger" @click="clear"> {{ $t('buttons.reset') }}</b-button>
          </div>
        </b-form>

        <button class="btn btn-warning ms-auto mb-2" @click="categoriesModal = true">
          {{ $t('buttons.categories') }}
        </button>
        <button class="btn btn-success ms-auto mb-2" @click="addProductModal = true">
          {{ $t('product.add_product') }}
        </button>
      </div>
    </div>
    <add-product
      :add-product-modal="addProductModal"
      :update-item-data="updateItem"
      :categories="categories_products"
      @close="
        addProductModal = false
        updateItem = {}
      "
      @submitted="$apollo.queries.products.refresh()"
    />
    <categories
      :show="categoriesModal"
      mode="products"
      @close="categoriesModal = false"
      @submitted="
        $apollo.queries.categories_products.refresh()
        $apollo.queries.products.refresh()
      "
    />

    <div class="col-md-12 col-xs-12 col-lg-12">
      <div class="card">
        <div class="card-body">
          <div style="overflow-x: auto">
            <table class="table">
              <thead>
                <tr>
                  <th>{{ $t('product.product_name') }}</th>
                  <th>{{ $t('tables.created_at') }}</th>
                  <th>{{ $t('tables.price') }}</th>
                  <th>{{ $t('tables.locations') }}</th>
                  <th>{{ $t('tables.categories') }}</th>
                  <th>{{ $t('tables.action') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(productItem, productsKey) in products" :key="productsKey">
                  <td>{{ productItem.name }}</td>
                  <td>
                    {{ moment(productItem.created_at).format('Do MMMM YYYY HH:ss') }}
                  </td>
                  <td>{{ formatPrice(productItem.price) }}</td>
                  <td>
                    <p
                      v-for="(location, locationProductsKey) in productItem.location_products"
                      :key="locationProductsKey"
                      class="mb-0"
                    >
                      {{ location.location.name }}
                    </p>
                  </td>
                  <td>
                    <p
                      v-for="(category, categoryProductsKey) in productItem.product_categories"
                      :key="categoryProductsKey"
                      class="mb-0"
                    >
                      {{ category.categories.name }}
                    </p>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-warning btn-sm"
                      @click="setUpdateModal(productItem)"
                    >
                      <i class="bx bx-edit font-size-16 align-middle me-1"></i
                      >{{ $t('buttons.edit') }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      style="margin-left: 5px"
                      @click="remove(productItem)"
                    >
                      <i class="bx bx-trash font-size-16 align-middle me-1"></i
                      >{{ $t('buttons.delete') }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <b-alert :show="products.length === 0" variant="warning" class="text-center my-1">
            <p class="mb-0"></p>
            {{ $t('product.no_product_added') }}
          </b-alert>
        </div>
      </div>
      <div class="text-center my-3">
        <p>
          {{ $t('customerForm.total') }} <b>{{ products.length }}</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import moment from 'moment'
import PageHeader from '@/components/page-header'
import AddProduct from '@/components/widgets/addProduct'
import Categories from '@/components/widgets/categories.vue'
import { GET_PRODUCTS, UPDATE_PRODUCTS, GET_CATEGORIES_PRODUCTS } from '@/router/queries'
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

export default {
  page: {
    title: 'products',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    PageHeader,
    AddProduct,
    Categories,
  },
  data() {
    return {
      title: this.$t('product.products'),
      items: [
        {
          text: this.$t('product.products'),
          href: '/',
        },
        {
          text: this.$t('product.product_list'),
          active: true,
        },
      ],
      moment,
      product: null,
      addProductModal: false,
      categoriesModal: false,
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      search_input: '',
      locationProducts: [],
      updateItem: {},
    }
  },
  validations: {
    product: {
      name: { required },
      price: { required },
    },
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
  },
  created() {
    this.$_loading()
    this.product = {
      name: null,
      price: null,
      team_id: this.auth.user.team_id,
      user_id: this.auth.user.id,
      location_products: {
        data: [],
      },
    }
  },
  methods: {
    setUpdateModal(product) {
      this.addProductModal = true
      this.updateItem = { ...product, price: product.price.toFixed(2) }
    },
    remove(product) {
      const self = this
      this.$_C({
        confirmed(result) {
          if (result) {
            self.$loading(true)
            self.$apollo.mutate({
              mutation: UPDATE_PRODUCTS,
              variables: {
                id: product.id,
                product: {
                  is_disabled: true,
                },
              },
              update: () => {
                try {
                  self.$_N({
                    type: 'success',
                    position: 'center',
                  })
                  self.$apollo.queries.products.refresh()
                  self.$loading(false)
                } catch (e) {
                  self.$_N({
                    type: 'error',
                    position: 'center',
                  })
                  self.$loading(false)
                }
              },
            })
          }
        },
      })
    },
    filter() {
      this.$apollo.queries.products.start()
      this.$_loading()
    },
    clear() {
      this.per_page = 10
      this.sort_by = { created_at: 'desc' }
      this.search_input = ''
      this.$apollo.queries.products.refresh()
      this.$_loading()
    },
  },
  apollo: {
    products: {
      fetchPolicy: 'no-cache',
      query: GET_PRODUCTS,
      variables() {
        return {
          limit: this.per_page,
          offset: this.offset,
          order_by: this.sort_by,
          where: {
            is_disabled: {
              _eq: false,
            },
            team_id: {
              _eq: this.auth.user.team_id,
            },
            _or: [
              {
                name: this.search,
              },
            ],
          },
        }
      },
    },
    categories_products: {
      fetchPolicy: 'no-cache',
      query: GET_CATEGORIES_PRODUCTS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
          },
        }
      },
    },
  },
}
</script>
