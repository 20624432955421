import gql from 'graphql-tag'

export const ADD_CONTACT = gql`
  mutation InsertContacts($contact: contacts_insert_input!) {
    insert_contacts_one(object: $contact) {
      id
    }
  }
`

export const ADD_LOCATION = gql`
  mutation InsertLocation($location: locations_insert_input!) {
    insert_locations_one(object: $location) {
      id
    }
  }
`

export const GET_LOCATIONS = gql`
  query GET_LOCATIONS(
    $limit: Int
    $offset: Int
    $order_by: [locations_order_by!]
    $where: locations_bool_exp
  ) {
    locations(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      address
      created_at
      email
      id
      is_disable
      name
      phone
      place_id
      updated_at
      team_id
      image
      no_preference
      stripe_location_id
      stripe_account_id
      location_working_hours(order_by: { day: asc }) {
        day
        work_end
        work_start
      }
    }
  }
`

export const GET_LOCATIONS_WORKING_HOURS = gql`
  query GET_LOCATIONS_WORKING_HOURS(
    $limit: Int
    $offset: Int
    $order_by: [locations_order_by!]
    $where: locations_bool_exp
  ) {
    locations(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      address
      created_at
      email
      id
      name
      phone
      place_id
      updated_at
      team_id
      image
      no_preference
      stripe_location_id
      stripe_account_id
      location_working_hours(order_by: { day: asc }) {
        active
        created_at
        day
        id
        location_id
        name
        rest_end
        rest_start
        updated_at
        work_end
        work_start
      }
    }
  }
`

export const UPDATE_LOCATION = gql`
  mutation update_location($id: uuid!, $location: locations_set_input!) {
    update_locations_by_pk(pk_columns: { id: $id }, _set: $location) {
      id
    }
  }
`

export const UPDATE_LOCATIONS = gql`
  mutation update_locations($id: uuid_comparison_exp!, $location: locations_set_input!) {
    update_locations(where: { id: $id }, _set: $location) {
      affected_rows
    }
  }
`

export const DELETE_LOCATION_WORKING_HOURS_BY_LOCATION_ID = gql`
  mutation deleteLocationWorkingHoursByLocationID($location_id: uuid) {
    delete_working_hours(where: { location_id: { _eq: $location_id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const ADD_LOCATION_WORKING_HOURS = gql`
  mutation addLocationWorkingHours($location_working_hours: [working_hours_insert_input!]!) {
    insert_working_hours(objects: $location_working_hours) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const GET_LOCATION_CONTACTS = gql`
  query getLocationContacts($where: location_contacts_bool_exp!) {
    location_contacts(where: $where) {
      contact_id
      created_at
      id
      location_id
      team_id
      updated_at
      location {
        id
        email
        created_at
        address
        name
      }
    }
  }
`

export const DELETE_LOCATION = gql`
  mutation DELETE_LOCATION($id: uuid!) {
    update_locations_by_pk(pk_columns: { id: $id }, _set: { is_disable: true }) {
      id
      is_disable
    }
  }
`

export const GET_CONTACTS = gql`
  query getContacts(
    $offset: Int
    $limit: Int
    $where: contacts_bool_exp
    $order_by: [contacts_order_by!]
  ) {
    contacts(offset: $offset, where: $where, limit: $limit, order_by: $order_by) {
      id
      identification_number
      first_name
      last_name
      phone_code
      event_services(limit: 1, order_by: { created_at: desc }) {
        event {
          start
        }
      }
      created_at
      phone
      email
      subscriptions
      image
      location_contacts {
        id
        created_at
        location_id
        team_id
        updated_at
        contact_id
        location {
          address
          email
          created_at
          id
          name
        }
      }
    }
  }
`

export const GET_CONTACTS_AGENDA = gql`
  query GET_CONTACTS_AGENDA($limit: Int, $where: contacts_bool_exp!) {
    contacts(limit: $limit, where: $where) {
      id
      identification_number
      first_name
      last_name
      phone_code
      created_at
      phone
      email
      location_contacts {
        location_id
      }
    }
  }
`

export const GET_CONTACTS_AGGREGATE = gql`
  query GET_CONTACTS_AGGREGATE($where: contacts_bool_exp!) {
    contacts_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_EVENTS_AGGREGATE = gql`
  query GET_EVENTS_AGGREGATE($where: events_bool_exp!) {
    events_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_VAULT_ACTIVITIES_AGGREGATE = gql`
  query GET_VAULT_ACTIVITIES_AGGREGATE($where: vault_activities_bool_exp) {
    vault_activities_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_GENDERS = gql`
  query GET_GENDERS {
    genders {
      name
      id
      code
    }
  }
`

export const GET_CONTACT_STATUSES = gql`
  query GET_CONTACT_STATUSES {
    alcohol_statuses: attributes(where: { group: { _eq: "alcohol" } }) {
      group
      id
      name
    }
    smoking_statuses: attributes(where: { group: { _eq: "smoking" } }) {
      group
      id
      name
    }
    drugs_statuses: attributes(where: { group: { _eq: "drugs" } }) {
      group
      id
      name
    }
    marital_statuses: attributes(where: { group: { _eq: "marital_statuses" } }) {
      group
      id
      name
    }
    blood_groups: attributes(where: { group: { _eq: "blood_groups" } }) {
      group
      id
      name
    }
  }
`

export const GET_CONTACT = gql`
  query GET_CONTACT($id: uuid!) {
    contact: contacts_by_pk(id: $id) {
      phone
      mother_name
      last_name
      job
      identification_number
      id
      gender_id
      first_name
      image
      father_name
      email_notification_status
      email
      alcohol_id
      birth_date
      blood_group_id
      city_id
      contracted_institution_id
      country_id
      created_at
      description
      drug_id
      address
      phone_code
      marital_status_id
      reference
      smoking_id
      sms_notification_status
      team_id
      updated_at
      user_id
      where_to_reach
      vault_activities {
        id
        price
        price_type
        created_at
        description
        type
        created_at
      }
    }
  }
`

export const UPDATE_CONTACT = gql`
  mutation UPDATE_CONTACT($id: uuid!, $contact: contacts_set_input!) {
    update_contacts(where: { id: { _eq: $id } }, _set: $contact) {
      returning {
        id
      }
    }
  }
`

export const INSERT_EVENT_TYPE = gql`
  mutation INSERT_EVENT_TYPE($protocol: event_types_insert_input!) {
    insert_event_types_one(object: $protocol) {
      id
    }
  }
`

export const INSERT_SOURCE = gql`
  mutation INSERT_SOURCE($source: sources_insert_input!) {
    insert_sources_one(object: $source) {
      id
    }
  }
`

export const ADD_USER = gql`
  mutation InsertUsers($user: users_insert_input!) {
    insert_users_one(object: $user) {
      id
    }
  }
`

export const CHECK_USER = gql`
  query oldUserCheck($email: String) {
    user: users_aggregate(where: { email: { _eq: $email } }) {
      aggregate {
        count
      }
      nodes {
        id
        team_id
        first_name
        last_name
        phone
        email
        role
        status
        password
      }
    }
  }
`

export const CREATE_USER_WITH_CONTACT = gql`
  mutation insertUser(
    $need_update_profile: Boolean
    $team_id: uuid
    $first_name: String
    $last_name: String
    $mother_name: String
    $father_name: String
    $tags: contact_tags_arr_rel_insert_input
    $job: String
    $alcohol_id: uuid
    $birth_date: date
    $blood_group_id: uuid
    $city_id: uuid
    $country_id: uuid
    $address: String
    $marital_status_id: uuid
    $reference: String
    $smoking_id: uuid
    $sms_notification_status: Boolean
    $where_to_reach: String
    $gender_id: uuid
    $image: jsonb
    $email: String
    $password: String
    $phone: String
    $location_contacts: location_contacts_arr_rel_insert_input
    $subscriptions: jsonb
    $user_id: uuid
  ) {
    user: insert_users_one(
      object: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        password: $password
        phone: $phone
        team_id: $team_id
        need_update_profile: $need_update_profile
        role: "contact"
        contact: {
          data: {
            email: $email
            first_name: $first_name
            last_name: $last_name
            mother_name: $mother_name
            father_name: $father_name
            team_id: $team_id
            phone: $phone
            birth_date: $birth_date
            job: $job
            alcohol_id: $alcohol_id
            blood_group_id: $blood_group_id
            city_id: $city_id
            country_id: $country_id
            address: $address
            marital_status_id: $marital_status_id
            reference: $reference
            smoking_id: $smoking_id
            sms_notification_status: $sms_notification_status
            where_to_reach: $where_to_reach
            gender_id: $gender_id
            image: $image
            location_contacts: $location_contacts
            tags: $tags
            subscriptions: $subscriptions
            user_id: $user_id
          }
        }
      }
    ) {
      id
      team_id
      first_name
      last_name
      phone
      role
      status
      email
      contact {
        id
      }
    }
  }
`

export const DELETE_LOCATION_STAFF = gql`
  mutation DeleteLocationStaff($id: uuid!) {
    delete_location_staff_by_pk(id: $id) {
      id
    }
  }
`

export const DELETE_LOCATION_SERVICE_BY_SERVICE_ID = gql`
  mutation DeleteLocationServicesByServiceID($service_id: uuid) {
    delete_location_services(where: { service_id: { _eq: $service_id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const DELETE_LOCATION_PRODUCT_BY_PRODUCT_ID = gql`
  mutation DeleteLocationProductsByProductID($product_id: uuid) {
    delete_location_products(where: { product_id: { _eq: $product_id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const DELETE_LOCATION_CONTACTS = gql`
  mutation deleteLocationContacts($id: uuid!) {
    delete_location_contacts_by_pk(id: $id) {
      id
    }
  }
`

export const DELETE_LOCATION_CONTACT_BY_CONTACT_ID = gql`
  mutation DeleteLocationContactsByContactID($contact_id: uuid) {
    delete_location_contacts(where: { contact_id: { _eq: $contact_id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const ADD_LOCATION_STAFFS = gql`
  mutation addLocationStaffs($locationStaffs: [location_staff_insert_input!]!) {
    insert_location_staff(objects: $locationStaffs) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const CHANGE_LOCATION_STAFFS = gql`
  mutation changeLocationStaffs(
    $locationStaffs: [location_staff_insert_input!]!
    $where: location_staff_bool_exp!
  ) {
    delete_location_staff(where: $where) {
      affected_rows
    }
    insert_location_staff(objects: $locationStaffs) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const ADD_LOCATION_CONTACTS = gql`
  mutation addLocationContacts($locationContacts: [location_contacts_insert_input!]!) {
    insert_location_contacts(objects: $locationContacts) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const ADD_LOCATION_PRODUCTS = gql`
  mutation addLocationProducts($locationProducts: [location_products_insert_input!]!) {
    insert_location_products(objects: $locationProducts) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const ADD_SERVICES_LOCATION = gql`
  mutation AddLocationServices($locationServices: [location_services_insert_input!]!) {
    insert_location_services(objects: $locationServices) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const GET_USERS = gql`
  query getUsers($limit: Int, $offset: Int, $where: users_bool_exp!, $order_by: [users_order_by!]) {
    users(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      id
      email
      phone
      phone_code
      image
      color
      staff_working_hours(order_by: { day: asc }) {
        active
        created_at
        day
        id
        location_id
        name
        rest_end
        rest_start
        team_id
        updated_at
        user_id
        work_end
        work_start
      }
      points
      first_name
      user_group_id
      user_group {
        id
        name
      }
      location_staffs {
        id
        created_at
        location_id
        team_id
        updated_at
        user_id
        location {
          address
          email
          created_at
          id
          name
        }
      }
      last_name
      status
      title
      facebook
      tiktok
      instagram
      notes
      show_online
    }
  }
`

export const GET_USERS_AGGREGATE = gql`
  query GET_USERS_AGGREGATE($where: users_bool_exp!) {
    users_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_EVENT_STATUS = gql`
  query GET_EVENT_STATUS {
    event_statuses {
      name
      type
      id
      code
      created_at
    }
  }
`

export const GET_PROTOCOL = gql`
  query GET_PROTOCOL($where: event_types_bool_exp!) {
    event_types(where: $where) {
      name
      team_id
      id
      created_at
      code
    }
  }
`

export const GET_SOURCES = gql`
  query GET_SOURCES(
    $limit: Int!
    $offset: Int!
    $where: sources_bool_exp!
    $order_by: [sources_order_by!]
  ) {
    sources(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      name
      team_id
      id
      created_at
      code
    }
  }
`

export const INSERT_MARKETING = gql`
  mutation INSERT_MARKETING($marketing: marketings_insert_input!) {
    insert_marketings_one(object: $marketing) {
      id
    }
  }
`

export const GET_MARKETINGS = gql`
  query GET_MARKETINGS(
    $limit: Int!
    $offset: Int!
    $where: marketings_bool_exp!
    $order_by: [marketings_order_by!]
  ) {
    marketings(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      name
      team_id
      filter
      schedule
      id
      status
      type
      subject
      content
      created_at
    }
  }
`

export const UPDATE_EVENT_TYPE = gql`
  mutation UPDATE_EVENT_TYPE($id: uuid!, $event: event_types_set_input!) {
    update_event_types(where: { id: { _eq: $id } }, _set: $event) {
      returning {
        id
      }
    }
  }
`

export const INSERT_TAG = gql`
  mutation INSERT_TAG($newTag: tags_insert_input!) {
    insert_tags_one(object: $newTag) {
      id
    }
  }
`

export const INSERT_CONTACT_TAG = gql`
  mutation INSERT_CONTACT_TAG($newContactTag: contact_tags_insert_input!) {
    insert_contact_tags_one(object: $newContactTag) {
      id
    }
  }
`

export const GET_TAGS = gql`
  query GET_TAGS($where: tags_bool_exp!) {
    tags(where: $where) {
      name
      id
      team_id
      user_id
    }
  }
`

export const GET_CONTACT_TAGS = gql`
  query GET_CONTACT_TAGS($where: contact_tags_bool_exp!) {
    contact_tags(where: $where) {
      contact_id
      created_at
      id
      tag_id
      tag {
        name
        id
      }
    }
  }
`

export const DELETE_CONTACT_TAGS = gql`
  mutation DELETE_CONTACT_TAGS($id: uuid!) {
    delete_contact_tags_by_pk(id: $id) {
      id
    }
  }
`

export const INSERT_VAULT = gql`
  mutation INSERT_VAULT($case: vault_insert_input!) {
    insert_vault_one(object: $case) {
      id
    }
  }
`

export const INSERT_VAULT_ACTIVITIES = gql`
  mutation INSERT_VAULT_ACTIVITIES($vault_activities: vault_activities_insert_input!) {
    insert_vault_activities_one(object: $vault_activities) {
      id
    }
  }
`

export const GET_VAULT_ACTIVITIES = gql`
  query GET_VAULT_ACTIVITIES($where: vault_activities_bool_exp!, $limit: Int, $offset: Int) {
    vault_activities(
      where: $where
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      id
      team_id
      user_id
      price_type
      payment_method
      voucher_date
      price
      deleted_at
      deleted_by
      type
      bundle_payment_id
      contact_id
      description
      created_at
      contact {
        first_name
        id
        last_name
      }
      user {
        id
        first_name
        last_name
        email
      }
      event {
        id
        location {
          id
          name
        }
      }
      location {
        id
        name
      }
    }
  }
`

export const DELETE_VAULT_ACTIVITIES = gql`
  mutation DELETE_VAULT_ACTIVITIES($id: uuid!) {
    delete_vault_activities_by_pk(id: $id) {
      id
    }
  }
`

export const GET_VAULTS = gql`
  query GET_VAULTS(
    $limit: Int!
    $offset: Int!
    $where: vault_bool_exp!
    $order_by: [vault_order_by!]
  ) {
    vault(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      id
      name
      created_at
      income: vault_activities_aggregate(where: { type: { _eq: "Gelir" } }) {
        aggregate {
          sum {
            price
          }
        }
      }
      expense: vault_activities_aggregate(where: { type: { _eq: "Gider" } }) {
        aggregate {
          sum {
            price
          }
        }
      }
      description
    }
  }
`

export const GET_VAULTS_AGGREGATE = gql`
  query GET_VAULTS_AGGREGATE($where: vault_bool_exp) {
    vault_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const VAULT_INCOME = gql`
  query VAULT_INCOME($where: vault_activities_bool_exp!) {
    income: vault_activities_aggregate(where: $where) {
      aggregate {
        sum {
          price
        }
      }
    }
  }
`

export const VAULT_EXPENSE = gql`
  query VAULT_EXPENSE($where: vault_activities_bool_exp!) {
    expense: vault_activities_aggregate(where: $where) {
      aggregate {
        sum {
          price
        }
      }
    }
  }
`

export const GET_VAULT = gql`
  query GET_VAULT($id: uuid!) {
    vault: vault_by_pk(id: $id) {
      id
      name
      description
      team_id
      vault_activities(where: { vault: { id: { _eq: $id } } }) {
        contact {
          id
          first_name
          last_name
        }
        contact_id
        created_at
        description
        id
        invoice_id
        price
        price_type
        team_id
        type
        updated_at
        user_id
        user {
          first_name
          last_name
          id
          email
        }
      }
    }
  }
`

export const GET_TEAM = gql`
  query GET_TEAM($id: uuid!) {
    team: teams_by_pk(id: $id) {
      email
      created_at
      tax_name
      stripe_subscription_id
      website
      subscription_expires
      subscription_type
      tax_title
      agreement
      address
      logo
      sms_count
      phone
      tax_number
      id
      organization_type
      name
      senders
      notification_control
      currency
      timezone
      slug
      fb_pixel_id
    }
  }
`

export const GET_USER = gql`
  query GET_USER($id: uuid!) {
    user: users_by_pk(id: $id) {
      email
      phone
      first_name
      last_name
      phone_code
      is_owner
      id
      status
      need_update_profile
      staff_working_hours(order_by: { day: asc }) {
        active
        created_at
        day
        id
        location_id
        name
        rest_end
        rest_start
        team_id
        updated_at
        user_id
        work_end
        work_start
      }
    }
  }
`

export const UPDATE_TEAM = gql`
  mutation UPDATE_TEAM($id: uuid!, $team: teams_set_input!) {
    update_teams(where: { id: { _eq: $id } }, _set: $team) {
      returning {
        id
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation UPDATE_USER($id: uuid!, $user: users_set_input!) {
    update_users(where: { id: { _eq: $id } }, _set: $user) {
      returning {
        id
      }
    }
  }
`

export const INSERT_EVENT = gql`
  mutation INSERT_EVENT($event: events_insert_input!) {
    insert_events_one(object: $event) {
      id
    }
  }
`

export const UPDATE_EVENT = gql`
  mutation UPDATE_EVENT($id: uuid!, $event: events_set_input!) {
    update_events(where: { id: { _eq: $id } }, _set: $event) {
      returning {
        id
      }
    }
  }
`

export const GET_USER_AGENDA = gql`
  query GET_USER_AGENDA($where: users_bool_exp!) {
    users(where: $where) {
      email
      phone
      color
      first_name
      last_name
      phone_code
      image
      id
      location_staffs {
        location_id
      }
      user_group {
        id
        name
      }
      working_hours {
        id
        location_id
        shift_end_time
        shift_start_time
        second_shift_end_time
        second_shift_start_time
        date_end
        date_start
        day_of_week
      }
    }
  }
`

export const GET_EVENTS = gql`
  query GET_EVENTS($where: events_bool_exp!) {
    events(where: $where) {
      id
      start
      end
      className: class_name
      contact_id
      created_at
      contact {
        id
        first_name
        last_name
      }
      event_services {
        id
        service {
          name
          id
        }
        price
        start
        duration
        user {
          id
          first_name
          last_name
        }
      }
      event_status {
        id
        name
        code
        color_class
      }
      duration
      event_type_id
      location_id
      online
      team_id
      updated_at
      user_id
    }
  }
`

export const SUBSCRIBE_TO_EVENTS = gql`
  subscription SUBSCRIBE_TO_EVENTS($where: events_bool_exp!) {
    events(where: $where) {
      id
      start
      end
      className: class_name
      contact_id
      created_at
      contact {
        id
        first_name
        last_name
      }
      event_services {
        id
        service {
          name
          id
        }
        start
        duration
        user {
          id
          first_name
          last_name
        }
      }
      event_status {
        id
        name
        code
        color_class
      }
      duration
      event_type_id
      location_id
      online
      team_id
      updated_at
      user_id
    }
  }
`

export const INSERT_OUTGOING = gql`
  mutation INSERT_OUTGOING($outgoing: outgoings_insert_input!) {
    insert_outgoings_one(object: $outgoing) {
      id
    }
  }
`

export const UPDATE_OUTGOINGS = gql`
  mutation UPDATE_OUTGOINGS($id: uuid!, $outgoings: outgoings_set_input!) {
    update_outgoings(where: { id: { _eq: $id } }, _set: $outgoings) {
      returning {
        id
      }
    }
  }
`

export const GET_OUTGOINS = gql`
  query GET_OUTGOINS(
    $limit: Int!
    $offset: Int!
    $where: outgoings_bool_exp!
    $order_by: [outgoings_order_by!]
  ) {
    outgoings(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      id
      type
      team_id
      status
      subject
      content
      created_at
    }
  }
`

export const INSERT_NOTES = gql`
  mutation INSERT_NOTES($note: notes_insert_input!) {
    insert_notes_one(object: $note) {
      id
    }
  }
`

export const GET_NOTES = gql`
  query GET_NOTES($where: notes_bool_exp!, $order_by: [notes_order_by!]) {
    notes(where: $where, order_by: $order_by) {
      id
      user_id
      created_at
      content
      contact_id
      team_id
      updated_at
    }
  }
`

export const INSERT_SERVICES = gql`
  mutation INSERT_SERVICES($services: services_insert_input!) {
    insert_services_one(object: $services) {
      id
    }
  }
`

export const GET_SERVICES = gql`
  query GET_SERVICES(
    $limit: Int
    $offset: Int
    $where: services_bool_exp!
    $order_by: [services_order_by]
  ) {
    services(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      id
      team_id
      form {
        id
        name
      }
      form_id
      created_at
      name
      duration
      price
      show_online
      location_services {
        id
        created_at
        location_id
        team_id
        updated_at
        service_id
        location {
          address
          email
          created_at
          id
          name
        }
      }
      service_categories {
        id
        categories {
          name
          id
        }
      }
      service_groups {
        id
        group {
          name
          id
        }
      }
    }
  }
`

export const UPDATE_SERVICE = gql`
  mutation UPDATE_SERVICE($id: uuid!, $service: services_set_input!) {
    update_services_by_pk(pk_columns: { id: $id }, _set: $service) {
      id
    }
  }
`

export const DELETE_SERVICES = gql`
  mutation DELETE_SERVICES($id: uuid!) {
    delete_services_by_pk(id: $id) {
      id
    }
  }
`

export const UPDATE_WORK_HOURS = gql`
  mutation UPDATE_WORK_HOURS($id: uuid!, $work_hours: working_hours_set_input!) {
    update_working_hours_by_pk(pk_columns: { id: $id }, _set: $work_hours) {
      id
    }
  }
`

export const UPDATE_SOURCE = gql`
  mutation UPDATE_SOURCE($id: uuid!, $source: sources_set_input!) {
    update_sources_by_pk(pk_columns: { id: $id }, _set: $source) {
      id
    }
  }
`

export const UPDATE_NOTE = gql`
  mutation UPDATE_NOTE($id: uuid!, $note: notes_set_input!) {
    update_notes_by_pk(pk_columns: { id: $id }, _set: $note) {
      id
    }
  }
`

export const DELETE_NOTE = gql`
  mutation DELETE_NOTE($id: uuid!) {
    delete_notes_by_pk(id: $id) {
      id
    }
  }
`

export const DELETE_SOURCE = gql`
  mutation DELETE_SOURCE($id: uuid!) {
    delete_sources_by_pk(id: $id) {
      id
    }
  }
`
export const GET_USER_GROUPS = gql`
  query GET_USER_GROUPS(
    $limit: Int
    $offset: Int
    $where: user_groups_bool_exp!
    $order_by: [user_groups_order_by!]
  ) {
    user_groups(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      id
      team_id
      created_at
      name
    }
  }
`

export const DELETE_EVENT_TYPE = gql`
  mutation DELETE_EVENT_TYPE($id: uuid!) {
    delete_event_types_by_pk(id: $id) {
      id
    }
  }
`
export const UPDATE_USER_GROUP = gql`
  mutation UPDATE_USER_GROUP($id: uuid!, $group: user_groups_set_input!) {
    update_user_groups_by_pk(pk_columns: { id: $id }, _set: $group) {
      id
    }
  }
`

export const GET_NOTIFICATIONS = gql`
  query GET_NOTIFICATIONS($where: notifications_bool_exp!) {
    notifications(where: $where, order_by: { created_at: desc }) {
      active
      created_at
      hint
      id
      message
      name
      notification_type_id
      team_id
      updated_at
      user_id
      variables
    }
  }
`

export const INSERT_USER_GROUP = gql`
  mutation INSERT_USER_GROUP($group: user_groups_insert_input!) {
    insert_user_groups_one(object: $group) {
      id
    }
  }
`

export const UPDATE_NOTIFICATION = gql`
  mutation UPDATE_NOTIFICATION($id: uuid!, $notification: notifications_set_input!) {
    update_notifications_by_pk(pk_columns: { id: $id }, _set: $notification) {
      id
    }
  }
`

export const UPDATE_SENDERS = gql`
  mutation UPDATE_SENDERS($id: uuid!, $senders: teams_set_input!) {
    update_teams_by_pk(pk_columns: { id: $id }, _set: $senders) {
      id
    }
  }
`

export const DELETE_USER_GROUP = gql`
  mutation DELETE_USER_GROUP($id: uuid!) {
    delete_user_groups_by_pk(id: $id) {
      id
    }
  }
`

export const GET_USER_GROUP = gql`
  query GET_USER_GROUP($where: user_groups_bool_exp!) {
    user_groups(where: $where) {
      name
      team_id
      updated_at
      user_id
      id
      created_at
    }
  }
`

export const UPDATE_PERSONAL = gql`
  mutation UPDATE_PERSONAL($id: uuid!, $user: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $user) {
      id
    }
  }
`

export const INSERT_PRODUCTS = gql`
  mutation INSERT_PRODUCTS($product: products_insert_input!) {
    insert_products_one(object: $product) {
      id
    }
  }
`

export const UPDATE_PRODUCTS = gql`
  mutation UPDATE_PRODUCTS($id: uuid!, $product: products_set_input!) {
    update_products(where: { id: { _eq: $id } }, _set: $product) {
      returning {
        id
      }
    }
  }
`

export const DELETE_PRODUCTS = gql`
  mutation DELETE_PRODUCTS($id: uuid!) {
    delete_products_by_pk(id: $id) {
      id
    }
  }
`

export const GET_PRODUCTS = gql`
  query GET_PRODUCTS(
    $limit: Int
    $offset: Int
    $where: products_bool_exp!
    $order_by: [products_order_by!]
  ) {
    products(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      created_at
      id
      name
      price
      team_id
      user_id
      location_products {
        id
        created_at
        location_id
        team_id
        updated_at
        product_id
        location {
          address
          email
          created_at
          id
          name
        }
      }
      product_categories {
        categories {
          name
          id
        }
      }
    }
  }
`

export const GET_EVENT_SINGLE = gql`
  query GET_EVENT_SINGLE($id: uuid!) {
    event: events_by_pk(id: $id) {
      all_day
      border_color
      class_name
      event_status_id
      color
      event_type {
        code
        name
        id
      }
      contact_id
      created_at
      description
      duration
      event_status {
        name
        code
      }
      contact {
        id
        first_name
        email
        last_name
        phone_code
        phone
      }
      duration_editable
      end
      event_type_id
      group_id
      id
      notes
      online
      remote_id
      rrule
      source
      user {
        first_name
        last_name
      }
      start
      status
      team_id
      location_id
      text_color
      title
      updated_at
      user_id
      location {
        id
        name
        stripe_location_id
        stripe_account_id
      }
      event_services {
        id
        price
        service_id
        staff_id
        duration
        team_id
        user_id
        start
        service {
          id
          name
          price
          points
          duration
          form_id
          location_services {
            location_id
          }
        }
      }
      teams {
        processing_fee
        card_flat_fee
        card_pct_fee
        card_vat
      }
      tips {
        id
        price
        staff_id
        team_id
        event_id
        contact_id
      }
    }
  }
`

export const GET_EVENT_TYPES_AGENDA = gql`
  query GET_EVENT_TYPES_AGENDA($where: event_types_bool_exp!) {
    event_types(where: $where) {
      code
      name
      team_id
      id
    }
  }
`

export const GET_USERS_AGENDA = gql`
  query GET_USERS_AGENDA($where: users_bool_exp!) {
    users(where: $where) {
      id
      email
      phone
      phone_code
      first_name
      user_group_id
      status
      user_group {
        id
        name
      }
      last_name
      location_staffs {
        location_id
      }
      user_group {
        id
        name
      }
      working_hours {
        id
        location_id
        shift_end_time
        shift_start_time
        second_shift_end_time
        second_shift_start_time
        date_end
        date_start
        day_of_week
      }
    }
  }
`

export const GET_SERVICES_AGENDA = gql`
  query GET_SERVICES_AGENDA($where: services_bool_exp!) {
    services(where: $where) {
      id
      team_id
      created_at
      form_id
      price
      duration
      name
      location_services {
        location_id
      }
      service_groups {
        id
        group {
          name
          id
        }
      }
    }
  }
`

export const INSERT_EVENT_SERVICES = gql`
  mutation INSERT_EVENT_SERVICES($service: event_services_insert_input!) {
    insert_event_services_one(object: $service) {
      id
    }
  }
`

export const GET_EVENT_SERVICES = gql`
  query GET_EVENT_SERVICES($where: event_services_bool_exp!) {
    event_services(where: $where, order_by: { created_at: desc }) {
      contact_id
      created_at
      duration
      event_id
      form_id
      id
      price
      service_id
      staff_id
      team_id
      updated_at
      user_id
    }
  }
`

export const UPDATE_EVENT_SERVICES = gql`
  mutation UPDATE_EVENT_SERVICES(
    $objects: [event_services_insert_input!]!
    $where: event_services_bool_exp!
  ) {
    delete_event_services(where: $where) {
      affected_rows
    }
    insert_event_services(objects: $objects) {
      affected_rows
    }
  }
`

export const UPDATE_EVENT_SERVICE = gql`
  mutation UPDATE_EVENT_SERVICE($id: uuid!, $service: event_services_set_input!) {
    update_event_services_by_pk(pk_columns: { id: $id }, _set: $service) {
      id
    }
  }
`

export const DELETE_EVENT_SERVICE = gql`
  mutation DELETE_EVENT_SERVICE($id: uuid!) {
    delete_event_services_by_pk(id: $id) {
      id
    }
  }
`

export const GET_PRODUCTS_AGENDA = gql`
  query GET_PRODUCTS_AGENDA($where: products_bool_exp!) {
    products(where: $where) {
      created_at
      id
      name
      price
      team_id
      user_id
    }
  }
`

export const GET_EVENT_PRODUCTS = gql`
  query GET_EVENT_PRODUCTS($where: event_products_bool_exp!) {
    event_products(where: $where, order_by: { created_at: desc }) {
      contact_id
      created_at
      event_id
      id
      name
      price
      product_id
      staff_id
      team_id
      updated_at
      user_id
    }
  }
`

export const INSERT_EVENT_PRODUCT = gql`
  mutation INSERT_EVENT_PRODUCT($product: event_products_insert_input!) {
    insert_event_products_one(object: $product) {
      id
    }
  }
`

export const UPDATE_EVENT_PRODUCTS = gql`
  mutation UPDATE_EVENT_PRODUCTS($id: uuid!, $product: event_products_set_input!) {
    update_event_products_by_pk(pk_columns: { id: $id }, _set: $product) {
      id
    }
  }
`

export const DELETE_EVENT_PRODUCTS = gql`
  mutation DELETE_EVENT_PRODUCTS($id: uuid!) {
    delete_event_products_by_pk(id: $id) {
      id
    }
  }
`

export const GET_EVENT_SERVICES_TOTAL_PRICE = gql`
  query GET_EVENT_SERVICES_TOTAL_PRICE($where: event_services_bool_exp!) {
    services_total_price: event_services_aggregate(where: $where) {
      aggregate {
        sum {
          price
        }
      }
    }
  }
`

export const GET_EVENT_PRODUCTS_TOTAL_PRICE = gql`
  query GET_EVENT_PRODUCTS_TOTAL_PRICE($where: event_products_bool_exp!) {
    products_total_price: event_products_aggregate(where: $where) {
      aggregate {
        sum {
          price
        }
      }
    }
  }
`

export const INSERT_VAULT_ACTIVITY = gql`
  mutation INSERT_VAULT_ACTIVITY($vault: vault_activities_insert_input!) {
    insert_vault_activities_one(object: $vault) {
      id
    }
  }
`

export const UPDATE_VAULT_ACTIVITY = gql`
  mutation UPDATE_VAULT_ACTIVITY($id: uuid!, $vault: vault_activities_set_input!) {
    update_vault_activities_by_pk(pk_columns: { id: $id }, _set: $vault) {
      id
    }
  }
`

export const DELETE_VAULT_ACTIVITY = gql`
  mutation DELETE_VAULT_ACTIVITY($id: uuid!) {
    delete_vault_activities_by_pk(id: $id) {
      id
    }
  }
`

export const GET_VAULT_ACTIVITY_AGENDA = gql`
  query GET_VAULT_ACTIVITY_AGENDA($where: vault_activities_bool_exp!) {
    vault_activities(where: $where) {
      contact_id
      invoice_id
      event_id
      price
      bundle_payment_id
      price_type
      created_at
      description
      date
      team_id
      user_id
      vault_id
      payment_method
      type
      id
      updated_at
    }
  }
`

export const GET_VAULT_TOTAL = gql`
  query GET_VAULT_TOTAL($where: vault_activities_bool_exp!) {
    vault_total: vault_activities_aggregate(where: $where) {
      aggregate {
        sum {
          price
        }
      }
    }
  }
`

export const GET_VAULT_ACTIVITY_CONTACT = gql`
  query GET_VAULT_ACTIVITY_CONTACT($where: vault_activities_bool_exp!) {
    vault_activities(where: $where) {
      contact_id
      invoice_id
      event_id
      price
      price_type
      created_at
      description
      date
      team_id
      user_id
      vault_id
      type
      id
      updated_at
    }
  }
`

export const GET_EVENTS_CONTACT_DETAIL = gql`
  query GET_EVENTS_CONTACT_DETAIL(
    $where: events_bool_exp!
    $offset: Int
    $limit: Int
    $order_by: [events_order_by!]
  ) {
    events(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      duration
      title
      start
      id
      notes
      status
      event_bundles {
        id
        amount
        created_at
        bundle {
          id
          name
        }
        bundle_service {
          service {
            name
            id
          }
        }
      }
      event_services {
        created_at
        price
        id
        start
        duration
        user {
          first_name
          last_name
        }
        service {
          id
          name
        }
      }
      contact {
        first_name
        last_name
        id
      }
      event_products {
        created_at
        user {
          first_name
          last_name
        }
        product {
          id
          name
        }
        price
      }
      event_status {
        code
        id
        name
      }
      total_services_price: event_services_aggregate {
        aggregate {
          sum {
            price
          }
        }
      }
      bundle_payment: vault_activities_aggregate(
        where: { event_payment: { _eq: true }, deleted_by: { _is_null: true } }
      ) {
        aggregate {
          sum {
            price
          }
        }
      }
      total_products_price: event_products_aggregate {
        aggregate {
          sum {
            price
          }
        }
      }
      paid: vault_activities_aggregate(where: { deleted_by: { _is_null: true } }) {
        aggregate {
          sum {
            price
          }
        }
      }
      tips {
        created_at
        price
        id
      }
      total_tips_price: tips_aggregate {
        aggregate {
          sum {
            price
          }
        }
      }
    }
  }
`

export const GET_CATEGORIES_PRODUCTS = gql`
  query GET_CATEGORIES_PRODUCTS($where: categories_products_bool_exp!) {
    categories_products(where: $where) {
      name
      id
    }
  }
`

export const INSERT_CATEGORIES_PRODUCTS = gql`
  mutation INSERT_CATEGORIES_PRODUCTS($categories: [categories_products_insert_input!]!) {
    insert_categories_products(objects: $categories) {
      affected_rows
    }
  }
`

export const DELETE_CATEGORIES_PRODUCTS = gql`
  mutation GET_CATEGORIES_PRODUCTS($where: categories_products_bool_exp!) {
    delete_categories_products(where: $where) {
      affected_rows
    }
  }
`

export const INSERT_PRODUCT_CATEGORIES = gql`
  mutation INSERT_PRODUCT_CATEGORIES($objects: [product_categories_insert_input!]!) {
    insert_product_categories(objects: $objects) {
      affected_rows
    }
  }
`

export const UPDATE_PRODUCT_CATEGORIES = gql`
  mutation UPDATE_PRODUCT_CATEGORIES(
    $objects: [product_categories_insert_input!]!
    $where: product_categories_bool_exp!
  ) {
    delete_product_categories(where: $where) {
      affected_rows
    }
    insert_product_categories(objects: $objects) {
      affected_rows
    }
  }
`

export const GET_FORMS = gql`
  query GET_FORMS($limit: Int, $offset: Int, $where: forms_bool_exp!, $order_by: [forms_order_by]) {
    forms(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      actions
      alias
      captcha_enabled
      cloneable
      created_at
      css_class
      deletable
      editable
      form_type
      id
      ignore_current_values
      inline_message
      method
      name
      redirect
      submit_text
      team_id
      updated_at
      user_id
    }
  }
`

export const GET_CATEGORIES_SERVICES = gql`
  query GET_CATEGORIES_SERVICES($where: categories_services_bool_exp!) {
    categories_services(where: $where) {
      name
      id
    }
  }
`

export const INSERT_CATEGORIES_SERVICES = gql`
  mutation INSERT_CATEGORIES_SERVICES($categories: [categories_services_insert_input!]!) {
    insert_categories_services(objects: $categories) {
      affected_rows
    }
  }
`

export const DELETE_CATEGORIES_SERVICES = gql`
  mutation GET_CATEGORIES_SERVICES($where: categories_services_bool_exp!) {
    delete_categories_services(where: $where) {
      affected_rows
    }
  }
`

export const INSERT_SERVICE_CATEGORIES = gql`
  mutation INSERT_SERVICE_CATEGORIES($objects: [service_categories_insert_input!]!) {
    insert_service_categories(objects: $objects) {
      affected_rows
    }
  }
`

export const UPDATE_SERVICE_CATEGORIES = gql`
  mutation INSERT_SERVICE_CATEGORIES(
    $objects: [service_categories_insert_input!]!
    $where: service_categories_bool_exp!
  ) {
    delete_service_categories(where: $where) {
      affected_rows
    }
    insert_service_categories(objects: $objects) {
      affected_rows
    }
  }
`

export const INSERT_SERVICE_GROUPS = gql`
  mutation INSERT_SERVICE_GROUPS($objects: [service_groups_insert_input!]!) {
    insert_service_groups(objects: $objects) {
      affected_rows
    }
  }
`

export const UPDATE_SERVICE_GROUPS = gql`
  mutation INSERT_SERVICE_GROUPS(
    $objects: [service_groups_insert_input!]!
    $where: service_groups_bool_exp!
  ) {
    delete_service_groups(where: $where) {
      affected_rows
    }
    insert_service_groups(objects: $objects) {
      affected_rows
    }
  }
`

export const INSERT_FORM = gql`
  mutation INSERT_FORM($form: forms_insert_input!) {
    insert_forms_one(object: $form) {
      id
    }
  }
`

export const GET_FORM = gql`
  query GET_FORM($id: uuid!) {
    form: forms_by_pk(id: $id) {
      actions
      alias
      captcha_enabled
      cloneable
      created_at
      css_class
      deletable
      notify_recipients
      editable
      form_type
      id
      ignore_current_values
      inline_message
      method
      name
      redirect
      submit_text
      team_id
      updated_at
      user_id
    }
  }
`

export const GET_FORM_FIELD_TYPES = gql`
  query GET_FORM_FIELD_TYPES {
    form_field_types {
      code
      description
      id
      name
      properties
    }
  }
`

export const INSERT_FORM_QUESTION = gql`
  mutation INSERT_FORM_QUESTION($question: form_questions_insert_input!) {
    insert_form_questions_one(object: $question) {
      id
    }
  }
`

export const GET_QUESTIONS = gql`
  query GET_QUESTIONS($where: form_questions_bool_exp!) {
    form_questions(order_by: { display_order: asc }, where: $where) {
      created_at
      default_value
      description
      display_order
      enabled
      field_type_id
      field_type {
        code
        id
      }
      form_id
      hidden
      id
      label
      label_hidden
      name
      options
      placeholder
      required
      team_id
      type
      user_id
      selected_options
    }
  }
`

export const UPDATE_FORM_QUESTION = gql`
  mutation UPDATE_FORM_QUESTION($id: uuid!, $question: form_questions_set_input!) {
    update_form_questions_by_pk(pk_columns: { id: $id }, _set: $question) {
      id
    }
  }
`
export const UPDATE_FORM = gql`
  mutation UPDATE_FORM($id: uuid!, $form: forms_set_input!) {
    update_forms_by_pk(pk_columns: { id: $id }, _set: $form) {
      id
    }
  }
`

export const DELETE_FORM_QUESTION = gql`
  mutation DELETE_FORM_QUESTION($id: uuid!) {
    delete_form_questions_by_pk(id: $id) {
      id
    }
  }
`

export const DELETE_FORM = gql`
  mutation DELETE_FORM($id: uuid!) {
    delete_forms_by_pk(id: $id) {
      id
    }
  }
`

export const INSERT_UPLOAD = gql`
  mutation INSERT_UPLOAD($user_id: uuid!, $name: String, $base64str: String, $type: String) {
    upload(base64str: $base64str, name: $name, team_id: $team_id, type: $type, user_id: $user_id) {
      affected_rows
    }
  }
`

export const DELETE_EVENT = gql`
  mutation DELETE_EVENT($id: uuid!) {
    delete_events_by_pk(id: $id) {
      id
    }
  }
`

export const GET_CONTACTS_REPORT = gql`
  query GET_CONTACTS_REPORT(
    $limit: Int
    $offset: Int
    $where: contacts_bool_exp
    $order_by: [contacts_order_by!]
  ) {
    contacts_reports: contacts(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      id
      email
      first_name
      last_name
      created_at
    }
  }
`

export const GET_DASHBOARD_DATA = gql`
  query GET_DASHBOARD_DATA(
    $today_limit: Int
    $today_offset: Int!
    $today_where: events_bool_exp!
    $tomorrow_where: events_bool_exp!
    $today_order_by: [events_order_by!]
    $vault_activities_where: vault_activities_bool_exp
    $vault_activities_expense_where: vault_activities_bool_exp
    $vault_order_by: [vault_activities_order_by!]
    $bundle_where: bundle_payments_bool_exp
    $income_where: vault_activities_bool_exp
    $expense_where: vault_activities_bool_exp
    $user_where: users_bool_exp
    $contact_where: contacts_bool_exp
    $event_where: events_bool_exp
    $id: uuid!
  ) {
    today_appointment: events(
      limit: $today_limit
      offset: $today_offset
      where: $today_where
      order_by: $today_order_by
    ) {
      id
      start
      contact {
        id
        first_name
        last_name
        email
      }
      event_status {
        code
        id
        name
      }
      event_type {
        id
        name
      }
    }
    tomorrow_appointment: events(
      limit: $today_limit
      offset: $today_offset
      where: $tomorrow_where
      order_by: $today_order_by
    ) {
      id
      start
      contact {
        id
        first_name
        last_name
        email
      }
      event_status {
        code
        id
        name
      }
      event_type {
        id
        name
      }
    }
    today_total_vault: vault_activities(
      where: $vault_activities_where
      order_by: $vault_order_by
      limit: $today_limit
    ) {
      contact_id
      created_at
      date
      description
      event_id
      invoice_id
      id
      payment_method
      price
      price_type
      team_id
      type
      updated_at
      user_id
      vault_id
      voucher_date
      contact {
        first_name
        last_name
        id
      }
    }
    today_expense_vault: vault_activities(
      where: $vault_activities_expense_where
      limit: $today_limit
    ) {
      contact_id
      created_at
      date
      description
      event_id
      invoice_id
      id
      payment_method
      price
      price_type
      team_id
      type
      updated_at
      user_id
      vault_id
      voucher_date
      contact {
        first_name
        last_name
        id
      }
    }
    today_total_price: vault_activities_aggregate(where: $vault_activities_where) {
      aggregate {
        sum {
          price
        }
      }
    }
    today_expense_price: vault_activities_aggregate(where: $vault_activities_expense_where) {
      aggregate {
        sum {
          price
        }
      }
    }
    bundle_payments(where: $bundle_where) {
      bundle_id
      contact_id
      created_at
      date
      id
      price
      status
      team_id
      contact {
        first_name
        last_name
      }
      updated_at
      user_id
      bundle {
        name
        id
        price
        done: bundle_payments_aggregate(where: { status: { _eq: "DONE" } }) {
          aggregate {
            sum {
              price
            }
          }
        }
        pending: bundle_payments_aggregate(where: { status: { _eq: "PENDING" } }) {
          aggregate {
            sum {
              price
            }
          }
        }
      }
    }
    total_bundle_price: bundle_payments_aggregate(where: $bundle_where) {
      aggregate {
        sum {
          price
        }
      }
    }
    contacts_aggregate: contacts_aggregate(where: $contact_where) {
      aggregate {
        count
      }
    }
    events_aggregate: events_aggregate(where: $event_where) {
      aggregate {
        count
      }
    }
    users_aggregate: users_aggregate(where: $user_where) {
      aggregate {
        count
      }
    }
    income: vault_activities_aggregate(where: $income_where) {
      aggregate {
        sum {
          price
        }
      }
    }
    expense: vault_activities_aggregate(where: $expense_where) {
      aggregate {
        sum {
          price
        }
      }
    }
    team: teams_by_pk(id: $id) {
      email
      created_at
      tax_name
      website
      subscription_expires
      subscription_type
      tax_title
      address
      logo
      phone
      tax_number
      id
      organization_type
      name
      senders
      sms_count
      notification_control
    }
  }
`

export const INSERT_FORM_ANSWERS = gql`
  mutation INSERT_FORM_ANSWERS($answers: form_answers_insert_input!) {
    insert_form_answers_one(object: $answers) {
      id
    }
  }
`

export const GET_BUNDLES_TYPES = gql`
  query GET_BUNDLES_TYPES {
    bundle_types {
      created_at
      code
      id
      name
    }
  }
`

export const INSERT_BUNDLE = gql`
  mutation INSERT_BUNDLE($bundle: bundles_insert_input!) {
    insert_bundles_one(object: $bundle) {
      id
    }
  }
`

export const GET_BUNDLES = gql`
  query GET_BUNDLES(
    $limit: Int
    $offset: Int
    $where: bundles_bool_exp!
    $order_by: [bundles_order_by]
  ) {
    bundles(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      bundle_payments(order_by: { date: asc }) {
        status
        price
        id
        date
        created_at
        contact_id
        bundle_id
        team_id
        updated_at
        user_id
      }
      bundle_services {
        bundle_id
        amount
        created_at
        id
        service {
          name
          id
        }
        service_id
        team_id
        updated_at
        user_id
      }
      contact_id
      created_at
      description
      id
      name
      price
      team_id
      updated_at
      user_id
      debt: bundle_payments_aggregate(where: { status: { _eq: "PENDING" } }) {
        aggregate {
          sum {
            price
          }
        }
      }
      paid: bundle_payments_aggregate(where: { status: { _eq: "DONE" } }) {
        aggregate {
          sum {
            price
          }
        }
      }
      contact {
        email
        phone
        phone_code
        first_name
        last_name
        id
      }
      event_bundles_aggregate {
        aggregate {
          count
        }
      }
      event_bundles(limit: 1, order_by: { created_at: desc }) {
        id
        event {
          start
        }
      }
    }
  }
`

export const DELETE_BUNDLE = gql`
  mutation DELETE_BUNDLE($id: uuid!) {
    delete_bundles_by_pk(id: $id) {
      id
    }
  }
`

export const UPDATE_BUNDLE = gql`
  mutation UPDATE_BUNDLE($id: uuid!, $bundle: bundles_set_input!) {
    update_bundles_by_pk(pk_columns: { id: $id }, _set: $bundle) {
      id
    }
  }
`

export const INSERT_CONTACT_BUNDLE = gql`
  mutation INSERT_CONTACT_BUNDLE($contact_bundle: contact_bundles_insert_input!) {
    insert_contact_bundles_one(object: $contact_bundle) {
      id
    }
  }
`

export const GET_CONTACT_BUNDLES = gql`
  query GET_CONTACT_BUNDLES($where: contact_bundles_bool_exp!) {
    contact_bundles(where: $where, order_by: { created_at: desc }) {
      bundle_id
      contact_id
      created_at
      id
      bundle {
        id
        amount
        price
        name
        description
        bundle_type {
          name
        }
      }
      price
      team_id
      updated_at
      user_id
      amount
    }
  }
`

export const INSERT_EVENT_BUNDLE = gql`
  mutation INSERT_EVENT_BUNDLE($event_bundles: event_bundles_insert_input!) {
    insert_event_bundles_one(object: $event_bundles) {
      id
    }
  }
`
export const UPDATE_BUNDLE_SERVICE = gql`
  mutation UPDATE_BUNDLE_SERVICE($id: uuid!, $bundle_service: bundle_services_set_input!) {
    update_bundle_services_by_pk(pk_columns: { id: $id }, _set: $bundle_service) {
      id
    }
  }
`

export const GET_BUNDLE_EVENTS = gql`
  query GET_BUNDLE_EVENTS($where: event_bundles_bool_exp!, $limit: Int) {
    event_bundles(where: $where, limit: $limit, order_by: { created_at: desc }) {
      amount
      bundle_id
      bundle_service_id
      bundle {
        name
      }
      contact_id
      created_at
      event_id
      id
      staff_id
      team_id
      updated_at
      user_id
      bundle_service {
        service {
          name
          id
        }
      }
    }
  }
`

export const DELETE_CONTACT_EVENT_BUNDLE = gql`
  mutation DELETE_CONTACT_EVENT_BUNDLE($id: uuid!) {
    delete_event_bundles_by_pk(id: $id) {
      id
    }
  }
`

export const GET_ANSWERS = gql`
  query GET_ANSWERS($where: form_answers_bool_exp!) {
    form_answers(where: $where) {
      contact_id
      created_at
      event_id
      form_id
      id
      product_id
      service_id
      contact {
        first_name
        last_name
      }
      event {
        start
        title
      }
      form {
        name
      }
      service {
        id
        name
      }
      product {
        name
        id
      }
      team_id
      updated_at
      user_id
      form_answer_fields {
        content
        created_at
        event_id
        contact_id
        form_answer_id
        form_field_id
        form_field_name
        form_id
        id
        service_id
        team_id
        updated_at
        user_id
      }
    }
  }
`

export const GET_TOTAL_QUESTIONS_COUNT = gql`
  query GET_TOTAL_QUESTIONS_COUNT($where: form_questions_bool_exp!) {
    total_questions: form_questions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_TOTAL_ANSWERS_COUNT = gql`
  query GET_TOTAL_ANSWERS_COUNT($where: form_answers_bool_exp!) {
    total_answers: form_answers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_MARKETING_SINGLE = gql`
  query GET_MARKETING_SINGLE($id: uuid!) {
    marketing: marketings_by_pk(id: $id) {
      content
      created_at
      filter
      id
      name
      schedule
      status
      subject
      team_id
      type
      updated_at
      user_id
    }
  }
`

export const GET_OUTGOINGS = gql`
  query GET_OUTGOINGS(
    $offset: Int!
    $limit: Int
    $where: outgoings_bool_exp!
    $order_by: [outgoings_order_by!]
  ) {
    outgoings(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
      clicks_count
      contact {
        first_name
        email
        last_name
        phone
        phone_code
      }
      contact_id
      content
      created_at
      id
      last_activity_at
      marketing_id
      opens_count
      process_id
      related
      related_id
      sender
      status
      subject
      team_id
      type
      updated_at
    }
  }
`

export const UPDATE_MARKETING = gql`
  mutation UPDATE_MARKETING($id: uuid!, $marketing: marketings_set_input!) {
    update_marketings_by_pk(pk_columns: { id: $id }, _set: $marketing) {
      id
    }
  }
`

export const GET_BUNDLE_CONTACTS = gql`
  query GET_BUNDLE_CONTACTS(
    $offset: Int
    $limit: Int
    $bundle_id: uuid!
    $where: contact_bundles_bool_exp!
    $order_by: [contact_bundles_order_by!]
  ) {
    contact_bundles(offset: $offset, limit: $limit, order_by: $order_by, where: $where) {
      amount
      bundle_id
      contact_id
      contact {
        first_name
        last_name
        id
        phone
        email
        activities: vault_activities(where: { bundle_id: { _eq: $bundle_id } }) {
          price
          price_type
          id
          type
          contact {
            first_name
            last_name
          }
          created_at
          date
          description
        }
        total: vault_activities_aggregate(where: { bundle_id: { _eq: $bundle_id } }) {
          aggregate {
            sum {
              price
            }
          }
        }
      }
      id
      price
      team_id
      updated_at
      user_id
      created_at
    }
  }
`

export const GET_BUNDLE_SINGLE = gql`
  query GET_BUNDLE_SINGLE($id: uuid!) {
    bundle: bundles_by_pk(id: $id) {
      bundle_payments(order_by: { date: asc }) {
        bundle_id
        contact_id
        created_at
        date
        id
        price
        status
        team_id
        updated_at
        user_id
      }
      bundle_services {
        bundle_id
        amount
        bundle_service_type_id
        created_at
        id
        service {
          name
          id
        }
        service_id
        team_id
        user_id
        updated_at
      }
      contact_id
      created_at
      description
      id
      name
      price
      team_id
      updated_at
      user_id
      paid: bundle_payments_aggregate(where: { status: { _eq: "DONE" } }) {
        aggregate {
          sum {
            price
          }
        }
      }
      debt: bundle_payments_aggregate(where: { status: { _eq: "PENDING" } }) {
        aggregate {
          sum {
            price
          }
        }
      }
      contact {
        email
        phone
        phone_code
        first_name
        last_name
        id
        address
        birth_date
        identification_number
      }
    }
  }
`

export const GET_CONTACT_BUNDLE_TOTAL = gql`
  query GET_CONTACT_BUNDLE_TOTAL($where: contact_bundles_bool_exp!) {
    contact_bundle_total: contact_bundles_aggregate(where: $where) {
      aggregate {
        sum {
          price
        }
      }
    }
  }
`

export const GET_BUNDLE_ACTIVITIES = gql`
  query GET_BUNDLE_ACTIVITIES($where: vault_activities_bool_exp!) {
    vault_activities(where: $where) {
      bundle_id
      contact_id
      contact {
        first_name
        last_name
      }
      date
      description
      created_at
      price
      price_type
      type
      id
    }
  }
`

export const GET_BUNDLE_VAULT_TOTAL = gql`
  query GET_BUNDLE_VAULT_TOTAL($where: vault_activities_bool_exp!) {
    bundle_vault_total: vault_activities_aggregate(where: $where) {
      aggregate {
        sum {
          price
        }
      }
    }
  }
`

export const GET_BUNDLE_TYPE = gql`
  query GET_BUNDLE_TYPE {
    bundle_service_types {
      name
      id
      code
    }
  }
`

export const UPDATE_PAYMENT = gql`
  mutation UPDATE_PAYMENT($id: uuid!, $payment: bundle_payments_set_input!) {
    update_bundle_payments_by_pk(pk_columns: { id: $id }, _set: $payment) {
      id
    }
  }
`

export const GET_EVENTS_LIST = gql`
  query GET_EVENTS(
    $where: events_bool_exp!
    $offset: Int
    $limit: Int
    $order_by: [events_order_by!]
  ) {
    events(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      id
      title
      start
      end
      color
      contact {
        first_name
        last_name
      }
      created_at
      duration
      event_type_id
      notes
      online
      remote_id
      rrule
      source
      status
      team_id
      updated_at
      user_id
      location_id
      event_status {
        code
        id
        name
      }
    }
  }
`

export const GET_BUNDLE_SERVICES = gql`
  query GET_EVENTS($where: bundle_services_bool_exp) {
    bundle_services(where: $where) {
      amount
      id
      bundle_id
      bundle_service_type {
        name
        id
        code
      }
      bundle {
        name
        id
        description
      }
      created_at
      bundle_service_type_id
      service {
        name
        id
      }
      service_id
      team_id
      updated_at
      user_id
    }
  }
`

export const GET_CONTACT_DETAIL = gql`
  query GET_CONTACT_DETAIL(
    $bundle_paid: bundle_payments_bool_exp
    $bundle_dept: bundle_payments_bool_exp
    $contact_paid: vault_activities_bool_exp
    $services_total: event_services_bool_exp
    $product_total: event_products_bool_exp
    $event_total: events_bool_exp
    $id: uuid!
    $outgoings_offset: Int!
    $outgoings_limit: Int
    $outgoings_where: outgoings_bool_exp!
    $outgoings_order_by: [outgoings_order_by!]
    $notes_where: notes_bool_exp!
    $notes_order_by: [notes_order_by!]
  ) {
    contact_vault_paid: vault_activities_aggregate(where: $contact_paid) {
      aggregate {
        sum {
          price
        }
      }
    }
    contact_bundle_paid: bundle_payments_aggregate(where: $bundle_paid) {
      aggregate {
        sum {
          price
        }
      }
    }
    contact_bundle_dept: bundle_payments_aggregate(where: $bundle_dept) {
      aggregate {
        sum {
          price
        }
      }
    }
    total_services_count: event_services_aggregate(where: $services_total) {
      aggregate {
        count
      }
    }
    total_product_count: event_products_aggregate(where: $product_total) {
      aggregate {
        count
      }
    }
    total_event_count: events_aggregate(where: $event_total) {
      aggregate {
        count
      }
    }
    contact: contacts_by_pk(id: $id) {
      phone
      mother_name
      last_name
      job
      identification_number
      id
      gender_id
      first_name
      image
      father_name
      email_notification_status
      email
      alcohol_id
      birth_date
      blood_group_id
      city_id
      contracted_institution_id
      country_id
      created_at
      description
      drug_id
      address
      phone_code
      marital_status_id
      reference
      smoking_id
      sms_notification_status
      team_id
      updated_at
      user_id
      where_to_reach
      subscriptions
      image
      vault_activities(where: { deleted_by: { _is_null: true } }) {
        id
        price
        price_type
        created_at
        description
        type
        created_at
      }
      location_contacts {
        contact_id
        created_at
        id
        location_id
        team_id
        updated_at
        location {
          id
          email
          created_at
          address
          name
        }
      }
    }
    countries {
      id
      name
      native
      phone
      cities {
        id
        name
      }
      languages
      emoji
      currency
      continent
      code
      capital
    }
    genders {
      name
      id
      code
    }
    outgoings(
      where: $outgoings_where
      order_by: $outgoings_order_by
      offset: $outgoings_offset
      limit: $outgoings_limit
    ) {
      clicks_count
      contact {
        first_name
        email
        phone
        phone_code
        last_name
      }
      contact_id
      content
      created_at
      id
      last_activity_at
      marketing_id
      opens_count
      process_id
      related
      related_id
      sender
      status
      subject
      team_id
      type
      updated_at
    }
    notes(where: $notes_where, order_by: $notes_order_by) {
      id
      user_id
      created_at
      content
      contact_id
      team_id
      updated_at
    }
  }
`

export const GET_CONTACT_REPORTS = gql`
  query GET_CONTACT_REPORTS($limit: Int, $offset: Int, $where: contacts_bool_exp) {
    contacts(limit: $limit, offset: $offset, where: $where) {
      bundles {
        bundle_payments(order_by: { date: asc }) {
          status
          price
          id
          date
          created_at
          contact_id
          bundle_id
          team_id
          updated_at
          user_id
        }
        bundle_services {
          bundle_id
          amount
          created_at
          id
          service {
            name
            id
          }
          service_id
          team_id
          updated_at
          user_id
        }
        contact_id
        created_at
        description
        id
        name
        price
        team_id
        updated_at
        user_id
        debt: bundle_payments_aggregate(where: { status: { _eq: "PENDING" } }) {
          aggregate {
            sum {
              price
            }
          }
        }
        paid: bundle_payments_aggregate(where: { status: { _eq: "DONE" } }) {
          aggregate {
            sum {
              price
            }
          }
        }
        contact {
          email
          phone
          phone_code
          first_name
          last_name
          id
        }
      }
      id
      created_at
      first_name
      last_name
      dept_bundle: bundle_payments_aggregate(where: { status: { _eq: "PENDING" } }) {
        aggregate {
          sum {
            price
          }
        }
      }
      bundle_payments_aggregate(where: { status: { _eq: "DONE" } }) {
        aggregate {
          sum {
            price
          }
        }
      }
      bundles_aggregate {
        aggregate {
          sum {
            price
          }
          count
        }
      }
      event_products {
        event_id
        created_at
        name
        price
        product_id
        staff_id
        team_id
        product {
          name
          id
        }
        user {
          first_name
          last_name
        }
        updated_at
        user_id
        contact_id
        id
      }
      event_products_aggregate {
        aggregate {
          sum {
            price
          }
          count
        }
      }
      event_services {
        created_at
        contact_id
        duration
        event_id
        form_id
        id
        price
        service {
          name
          id
        }
        user {
          first_name
          last_name
        }
        service_id
        staff_id
        team_id
        updated_at
        user_id
      }
      event_services_aggregate {
        aggregate {
          count
          sum {
            price
          }
        }
      }
      vault_activities_aggregate(where: { deleted_by: { _is_null: true } }) {
        aggregate {
          count
          sum {
            price
          }
        }
      }
      bundle_payment: vault_activities_aggregate(
        where: { event_payment: { _eq: true }, deleted_by: { _is_null: true } }
      ) {
        aggregate {
          count
          sum {
            price
          }
        }
      }
      tips {
        contact_id
        created_at
        price
        id
        staff {
          first_name
          last_name
        }
      }
      tips_aggregate {
        aggregate {
          count
          sum {
            price
          }
        }
      }
      events(where: { event_status: { code: { _eq: "DONE" } } }) {
        contact_id
        event_product_total_price: event_products_aggregate {
          aggregate {
            sum {
              price
            }
          }
        }
        event_services_total_price: event_services_aggregate {
          aggregate {
            sum {
              price
            }
          }
        }
        vault_total_price: vault_activities_aggregate(where: { deleted_by: { _is_null: true } }) {
          aggregate {
            sum {
              price
            }
          }
        }
        bundle_payment: vault_activities_aggregate(
          where: { event_payment: { _eq: true }, deleted_by: { _is_null: true } }
        ) {
          aggregate {
            count
            sum {
              price
            }
          }
        }
        tips_total_price: tips_aggregate {
          aggregate {
            sum {
              price
            }
          }
        }
      }
    }
  }
`

export const GET_CASE_REPORTS = gql`
  query GET_CASE_REPORTS(
    $income_credi_card: vault_activities_bool_exp
    $income_transfer: vault_activities_bool_exp
    $income_cash: vault_activities_bool_exp
    $income_voucher: vault_activities_bool_exp
    $expense_credi_card: vault_activities_bool_exp
    $expense_transfer: vault_activities_bool_exp
    $expense_cash: vault_activities_bool_exp
    $total_expense: vault_activities_bool_exp
    $total_income: vault_activities_bool_exp
  ) {
    income_credi_card: vault_activities_aggregate(where: $income_credi_card) {
      aggregate {
        sum {
          price
          net_price
        }
      }
    }
    income_transfer: vault_activities_aggregate(where: $income_transfer) {
      aggregate {
        sum {
          price
        }
      }
    }
    income_cash: vault_activities_aggregate(where: $income_cash) {
      aggregate {
        sum {
          price
        }
      }
    }
    income_voucher: vault_activities_aggregate(where: $income_voucher) {
      aggregate {
        sum {
          price
        }
      }
    }
    expense_credi_card: vault_activities_aggregate(where: $expense_credi_card) {
      aggregate {
        sum {
          price
        }
      }
    }
    expense_transfer: vault_activities_aggregate(where: $expense_transfer) {
      aggregate {
        sum {
          price
        }
      }
    }
    expense_cash: vault_activities_aggregate(where: $expense_cash) {
      aggregate {
        sum {
          price
        }
      }
    }
    total_income: vault_activities_aggregate(where: $total_income) {
      aggregate {
        sum {
          price
          net_price
        }
      }
    }
    total_expense: vault_activities_aggregate(where: $total_expense) {
      aggregate {
        sum {
          price
        }
      }
    }
  }
`

export const GET_APPOINTMENT_REPORT = gql`
  query GET_APPOINTMENT_REPORT(
    $limit: Int!
    $offset: Int!
    $where: events_bool_exp!
    $order_by: [events_order_by!]
  ) {
    appointment_reports: events(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      duration
      start
      contact_id
      id
      notes
      status
      event_bundles {
        id
        amount
        created_at
        bundle {
          id
          name
        }
        user {
          first_name
          last_name
        }
        bundle_service {
          service {
            name
            id
          }
        }
      }
      event_services {
        created_at
        price
        id
        user {
          first_name
          last_name
        }
        service {
          id
          name
        }
      }
      contact {
        first_name
        last_name
        id
      }
      event_products {
        created_at
        user {
          first_name
          last_name
        }
        product {
          id
          name
        }
        price
      }
      event_status {
        code
        id
        name
      }
      tips {
        created_at
        price
        id
        staff {
          first_name
          last_name
        }
      }
      total_services_price: event_services_aggregate {
        aggregate {
          sum {
            price
          }
        }
      }
      total_products_price: event_products_aggregate {
        aggregate {
          sum {
            price
          }
        }
      }
      paid: vault_activities_aggregate(where: { deleted_by: { _is_null: true } }) {
        aggregate {
          sum {
            price
          }
        }
      }
      bundle_paid: vault_activities_aggregate(
        where: { event_payment: { _eq: true }, deleted_by: { _is_null: true } }
      ) {
        aggregate {
          sum {
            price
          }
        }
      }
      total_tips_price: tips_aggregate {
        aggregate {
          sum {
            price
          }
        }
      }
    }
  }
`

export const GET_LICENCE_INFO = gql`
  query GET_TEAM($id: uuid!) {
    team: teams_by_pk(id: $id) {
      id
      subscription_expires
      subscription_type
    }
  }
`

export const GET_OUTGOINGS_REPORT = gql`
  query GET_OUTGOINGS_REPORT(
    $offset: Int!
    $limit: Int
    $where: outgoings_bool_exp!
    $order_by: [outgoings_order_by!]
  ) {
    outgoings(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
      clicks_count
      contact {
        first_name
        email
        last_name
      }
      contact_id
      content
      created_at
      id
      last_activity_at
      marketing_id
      opens_count
      process_id
      related
      related_id
      sender
      status
      subject
      team_id
      type
      updated_at
    }
  }
`

export const GET_CONTACT_EVENT_DEPT = gql`
  query GET_CONTACT_EVENT_DEPT($where: events_bool_exp) {
    events(where: $where) {
      id
      contact_id
      start
      pP: event_products_aggregate {
        aggregate {
          sum {
            price
          }
        }
      }
      sP: event_services_aggregate {
        aggregate {
          sum {
            price
          }
        }
      }
      vP: vault_activities_aggregate(where: { deleted_by: { _is_null: true } }) {
        aggregate {
          sum {
            price
          }
        }
      }
      bP: vault_activities_aggregate(
        where: { event_payment: { _eq: true }, deleted_by: { _is_null: true } }
      ) {
        aggregate {
          sum {
            price
          }
        }
      }
    }
  }
`

export const GET_EVENTS_BY_DATE = gql`
  query GET_EVENTS_BY_DATE($start: timestamptz = "", $end: timestamptz = "") {
    events(where: { start: { _gte: $start }, _and: { end: { _lte: $end } } }) {
      id
      location_id
      start
      end
      location {
        name
      }
    }
  }
`

export const GET_CONTACT_BUNDLE_DEPT = gql`
  query GET_CONTACT_BUNDLE_DEPT($where: bundle_payments_bool_exp) {
    contact_bundle_dept: bundle_payments(where: $where) {
      date
      bundle {
        name
      }
      id
      price
    }
  }
`

export const INSERT_VAULT_ACTIVITIES_MULTI = gql`
  mutation INSERT_VAULT_ACTIVITIES_MULTI($activities: [vault_activities_insert_input!]!) {
    insert_vault_activities(objects: $activities) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const INSERT_CHECKOUT = gql`
  mutation INSERT_CHECKOUT(
    $amount: String = ""
    $description: String = ""
    $group: String = ""
    $name: String = ""
    $price_id: String = ""
    $team_id: String = ""
    $type: String = ""
    $user_id: String = ""
  ) {
    checkout(
      amount: $amount
      description: $description
      group: $group
      name: $name
      price_id: $price_id
      team_id: $team_id
      type: $type
      user_id: $user_id
    ) {
      id
      payment_status
      url
      expires_at
      currency
      client_reference_id
      amount_total
    }
  }
`

export const GET_EVENTS_REVIEW = gql`
  query GET_EVENTS_REVIEW($id: uuid!) {
    event: events_by_pk(id: $id) {
      start
      contact_id
      team_id
      location {
        place_id
      }
    }
  }
`
export const GET_REVIEW = gql`
  query GET_REVIEW($where: reviews_bool_exp) {
    reviews(where: $where) {
      comment
      contact_id
      created_at
      event_id
      id
      rating
      reply
      team_id
      updated_at
    }
  }
`

export const INSERT_REVIEW = gql`
  mutation INSERT_REVIEW($review: reviews_insert_input!) {
    insert_reviews_one(object: $review) {
      id
    }
  }
`

export const GET_INVOICES = gql`
  query GET_INVOICES(
    $offset: Int
    $limit: Int
    $where: charge_invoices_bool_exp!
    $order_by: [charge_invoices_order_by!]
  ) {
    charge_invoices(offset: $offset, where: $where, limit: $limit, order_by: $order_by) {
      id
      customer_email
      customer_name
      invoice_pdf
      invoice_url
      status
      subtotal
      total
      period_start
      period_end
      customer_phone
      currency
      created_at
      charge_id
    }
  }
`

export const GET_INVOICES_AGGREGATE = gql`
  query GET_INVOICES_AGGREGATE($where: charge_invoices_bool_exp!) {
    charge_invoices_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_EVENT_BUNDLE_PAID = gql`
  query GET_EVENT_BUNDLE_PAID($where: vault_activities_bool_exp) {
    bundle_paid: vault_activities_aggregate(where: $where) {
      aggregate {
        sum {
          price
        }
      }
    }
  }
`

export const SUBSCRIPTION_CANCEL = gql`
  mutation SUBSCRIPTION_CANCEL($team_id: uuid!) {
    cancelSubscription(team_id: $team_id) {
      result
    }
  }
`

export const GET_UNREAD_ACTIVITIES = gql`
  query GET_UNREAD_ACTIVITIES($where: activities_bool_exp!) {
    activities(where: $where, order_by: { created_at: desc }) {
      activity_types {
        id
        code
        name
      }
      content
      created_at
      id
      event_id
      events {
        id
        start
        end
        contact {
          id
          first_name
          last_name
        }
        event_services {
          id
          start
          service {
            id
            name
          }
          user {
            id
            first_name
            last_name
          }
        }
        location {
          id
          name
        }
        event_status {
          code
          name
          id
        }
      }
    }
  }
`

export const SUBSCRIBE_TO_ACTIVITIES = gql`
  subscription SUBSCRIBE_TO_ACTIVITIES(
    $where: activities_bool_exp!
    $created_at: timestamptz = ""
  ) {
    activities_stream(
      batch_size: 5
      cursor: { initial_value: { created_at: $created_at }, ordering: ASC }
      where: $where
    ) {
      activity_types {
        id
        code
        name
      }
      content
      created_at
      id
      event_id
      events {
        id
        start
        end
        contact {
          id
          first_name
          last_name
        }
        event_services {
          id
          start
          service {
            id
            name
          }
          user {
            id
            first_name
            last_name
          }
        }
        location {
          id
          name
        }
        event_status {
          code
          name
          id
        }
      }
    }
  }
`

export const SUBSCRIBE_TO_ACTIVITIES_AGGREGATE = gql`
  subscription SUBSCRIBE_TO_ACTIVITIES_AGGREGATE($where: activities_bool_exp!) {
    activities_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_ACTIVITIES = gql`
  query GET_ACTIVITIES($where: activities_bool_exp!, $offset: Int!) {
    activities(where: $where, limit: 15, offset: $offset, order_by: { created_at: desc }) {
      activity_types {
        code
        name
      }
      content
      created_at
      id
      event_id
      events {
        id
        start
        end
        contact {
          first_name
          last_name
        }
        event_services {
          start
          service {
            name
          }
          user {
            first_name
            last_name
          }
        }
        location {
          name
        }
        event_status {
          code
          name
          id
        }
      }
    }
  }
`

export const GET_ACTIVITIES_AGGREGATE = gql`
  query GET_ACTIVITIES_AGGREGATE($where: activities_bool_exp!) {
    activities_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const MARK_ACTIVITY_AS_READ = gql`
  mutation MARK_ACTIVITY_AS_READ($data: [activity_readers_insert_input!]!) {
    insert_activity_readers(objects: $data) {
      returning {
        id
      }
    }
  }
`

export const GET_REVIEWS = gql`
  query GET_REVIEWS(
    $where: reviews_bool_exp
    $order_by: [reviews_order_by!]
    $offset: Int
    $limit: Int
  ) {
    reviews(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
      comment
      contact_id
      created_at
      event_id
      id
      rating
      reply
      team_id
      updated_at
      contact {
        first_name
        last_name
      }
      event {
        start
      }
    }
  }
`

export const GET_PERMISSION = gql`
  query GET_PERMISSION($where: user_permissions_bool_exp!) {
    user_permissions(where: $where) {
      id
      name
      permission_id
      description
    }
  }
`

export const GET_PERMISSIONS_LIST = gql`
  query GET_PERMISSIONS_LIST {
    permissions {
      id
      name
      description
    }
  }
`

export const INSERT_PERMISSIONS = gql`
  mutation INSERT_PERMISSIONS($per_list: user_permissions_insert_input!) {
    insert_user_permissions_one(object: $per_list) {
      id
    }
  }
`

export const DELETE_PERMISSION = gql`
  mutation DELETE_PERMISSION($id: uuid!) {
    delete_user_permissions_by_pk(id: $id) {
      id
    }
  }
`

export const GET_DUE_PAYMENT_REPORT = gql`
  query GET_DUE_PAYMENT_REPORT(
    $bundle_where: bundle_payments_bool_exp
    $id: uuid!
    $limit: Int!
    $order_by: [bundle_payments_order_by!]
    $offset: Int!
  ) {
    bundle_payments(where: $bundle_where, limit: $limit, offset: $offset, order_by: $order_by) {
      bundle_id
      contact_id
      created_at
      date
      id
      price
      status
      team_id
      contact {
        first_name
        last_name
      }
      updated_at
      user_id
      bundle {
        name
        id
        price
        done: bundle_payments_aggregate(where: { status: { _eq: "DONE" } }) {
          aggregate {
            sum {
              price
            }
          }
        }
        pending: bundle_payments_aggregate(where: { status: { _eq: "PENDING" } }) {
          aggregate {
            sum {
              price
            }
          }
        }
      }
    }
    team: teams_by_pk(id: $id) {
      email
      created_at
      tax_name
      website
      subscription_expires
      subscription_type
      tax_title
      address
      logo
      phone
      tax_number
      id
      organization_type
      name
      senders
      sms_count
    }
  }
`

export const BUNDLE_SHOW_DETAIL = gql`
  query BUNDLE_SHOW_DETAIL($where: event_bundles_bool_exp, $limit: Int, $offset: Int) {
    event_bundles(where: $where, limit: $limit, offset: $offset) {
      amount
      bundle_id
      bundle_service_id
      contact_id
      contact {
        first_name
        last_name
      }
      created_at
      event_id
      id
      staff_id
      team_id
      updated_at
      user_id
      event {
        start
      }
      user {
        first_name
        last_name
      }
    }
  }
`

export const BUNDLE_AGGREGATE = gql`
  query BUNDLE_AGGREGATE($where: bundles_bool_exp) {
    bundles_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_LOCATION_USER_WORKING_HOURS = gql`
  query getLocationUserWorkingHours(
    $location_id: uuid! = ""
    $date_start: date! = ""
    $date_end: date = ""
  ) {
    users(
      where: {
        location_staffs: { location_id: { _eq: $location_id } }
        role: { _eq: "user" }
        status: { _eq: "ACTIVE" }
      }
    ) {
      id
      first_name
      last_name
      image
      user_group {
        id
        name
      }
      working_hours(
        where: {
          date_start: { _lte: $date_end }
          _and: { date_end: { _gte: $date_start } }
          location_id: { _eq: $location_id }
        }
      ) {
        shift_end_time
        shift_start_time
        second_shift_end_time
        second_shift_start_time
        day_of_week
        date_start
        date_end
        id
      }
    }
  }
`

export const INSERT_LOCATION_USER_WORKING_HOURS = gql`
  mutation INSERT_LOCATION_USER_WORKING_HOURS($data: [user_working_hours_insert_input!]!) {
    insert_user_working_hours(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const INSERT_USER_SHIFTS = gql`
  mutation INSERT_USER_SHIFTS($data: [user_working_hours_insert_input!]!) {
    insert_user_working_hours(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const UPDATE_USER_PAST_SHIFTS = gql`
  mutation UPDATE_USER_PAST_SHIFTS($id: uuid!, $data: user_working_hours_set_input) {
    update_user_working_hours_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`

export const UPDATE_LOCATION_USER_WORKING_HOURS = gql`
  mutation UPDATE_LOCATION_USER_WORKING_HOURS(
    $id: uuid! = ""
    $data: user_working_hours_set_input
  ) {
    update_user_working_hours(where: { id: { _eq: $id } }, _set: $data) {
      returning {
        id
      }
    }
  }
`

export const UPDATE_LOCATION_USER_WORKING_HOURS_SIFT_START_DAY = gql`
  mutation UPDATE_LOCATION_USER_WORKING_HOURS_SIFT_START_DAY(
    $id: uuid! = ""
    $date_start: date! = ""
  ) {
    update_user_working_hours_by_pk(pk_columns: { id: $id }, _set: { date_start: $date_start }) {
      id
    }
  }
`

export const GET_USER_SHIFTS_BY_DAY = gql`
  query GET_USER_SHIFTS_BY_DAY(
    $day_of_week: bpchar = ""
    $user_id: uuid = ""
    $date_start: date = ""
  ) {
    user_working_hours(
      where: {
        day_of_week: { _eq: $day_of_week }
        user_id: { _eq: $user_id }
        date_start: { _gt: $date_start }
      }
    ) {
      id
      shift_end_time
      shift_start_time
      second_shift_end_time
      second_shift_start_time
      day_of_week
      date_start
      date_end
    }
  }
`

export const DELETE_USER_SHIFT = gql`
  mutation DELETE_USER_SHIFT($id: uuid! = "") {
    delete_user_working_hours_by_pk(id: $id) {
      id
    }
  }
`

export const DELETE_USER_SHIFT_BY_WEEK_DAY = gql`
  mutation DELETE_USER_SHIFT_BY_WEEK_DAY($user_id: uuid = "", $day_of_week: bpchar = "") {
    delete_user_working_hours(
      where: { user_id: { _eq: $user_id }, _and: { day_of_week: { _eq: $day_of_week } } }
    ) {
      returning {
        id
      }
    }
  }
`

export const DELETE_USER_UPCOMING_SHIFTS = gql`
  mutation DELETE_USER_UPCOMING_SHIFTS(
    $user_id: uuid = ""
    $day_of_week: bpchar = ""
    $endDate: date = ""
    $startDate: date = ""
  ) {
    delete_user_working_hours(
      where: {
        user_id: { _eq: $user_id }
        day_of_week: { _eq: $day_of_week }
        date_start: { _lte: $endDate, _gte: $startDate }
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const GET_CLOSE_DATES = gql`
  query GET_CLOSE_DATES(
    $limit: Int
    $offset: Int
    $order_by: [closed_dates_order_by!]
    $where: closed_dates_bool_exp
  ) {
    closed_dates(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      start_date
      end_date
      created_at
      id
      locations {
        location_id
        location {
          id
          name
        }
      }
      updated_at
      team_id
      description
    }
  }
`

export const INSERT_CLOSED_DATES = gql`
  mutation INSERT_CLOSED_DATES($data: [closed_dates_insert_input!]!) {
    insert_closed_dates(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const UPDATE_CLOSED_DATES = gql`
  mutation UPDATE_CLOSED_DATES($id: uuid!, $closed_dates: closed_dates_set_input!) {
    update_closed_dates_by_pk(pk_columns: { id: $id }, _set: $closed_dates) {
      id
    }
  }
`

export const DELETE_LOCATION_CLOSED_DATE_BY_CLOSED_DATE_ID = gql`
  mutation DELETE_LOCATION_CLOSED_DATE_BY_CLOSED_DATE_ID($closed_date_id: uuid) {
    delete_closed_date_location(where: { closed_date_id: { _eq: $closed_date_id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const ADD_LOCATION_CLOSED_DATES = gql`
  mutation ADD_LOCATION_CLOSED_DATES($data: [closed_date_location_insert_input!]!) {
    insert_closed_date_location(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const GET_WORKING_HOURS = gql`
  query GET_WORKING_HOURS($team_id: uuid!) {
    working_hours(where: { team_id: { _eq: $team_id }, active: { _eq: true } }) {
      name
      work_start
      work_end
      day
      location_id
      active
      rest_start
      rest_end
    }
  }
`

export const APPOINTMENTS_METRICS = gql`
  query APPOINTMENTS_METRICS(
    $team_id: uuid!
    $location_id: uuid_comparison_exp
    $event_services: event_services_bool_exp
    $start: timestamptz!
    $end: timestamptz!
    $prev: timestamptz!
  ) {
    total_appointments: events_aggregate(
      where: {
        team_id: { _eq: $team_id }
        start: { _gte: $start, _lte: $end }
        location_id: $location_id
        event_services: $event_services
      }
    ) {
      aggregate {
        count
      }
    }
    completed_appointments: events_aggregate(
      where: {
        team_id: { _eq: $team_id }
        event_status: { code: { _eq: "DONE" } }
        start: { _gte: $start, _lte: $end }
        location_id: $location_id
        event_services: $event_services
      }
    ) {
      aggregate {
        count
      }
    }
    not_completed_appointments: events_aggregate(
      where: {
        team_id: { _eq: $team_id }
        event_status: { code: { _neq: "DONE" } }
        start: { _gte: $start, _lte: $end }
        location_id: $location_id
        event_services: $event_services
      }
    ) {
      aggregate {
        count
      }
    }
    cancelled_appointments: events_aggregate(
      where: {
        team_id: { _eq: $team_id }
        event_status: { code: { _eq: "CANCELED" } }
        start: { _gte: $start, _lte: $end }
        location_id: $location_id
        event_services: $event_services
      }
    ) {
      aggregate {
        count
      }
    }
    noshow_appointments: events_aggregate(
      where: {
        team_id: { _eq: $team_id }
        event_status: { code: { _eq: "NOT_COME" } }
        start: { _gte: $start, _lte: $end }
        location_id: $location_id
        event_services: $event_services
      }
    ) {
      aggregate {
        count
      }
    }
    prev_appointments: events_aggregate(
      where: {
        team_id: { _eq: $team_id }
        start: { _gte: $prev, _lte: $start }
        location_id: $location_id
        event_services: $event_services
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const ONLINE_APPOINTMENTS_METRICS = gql`
  query ONLINE_APPOINTMENTS_METRICS(
    $team_id: uuid!
    $location_id: uuid_comparison_exp
    $event_services: event_services_bool_exp
    $start: timestamptz!
    $end: timestamptz!
    $prev: timestamptz!
  ) {
    total_appointments: events_aggregate(
      where: {
        team_id: { _eq: $team_id }
        start: { _gte: $start, _lte: $end }
        online: { _eq: true }
        location_id: $location_id
        event_services: $event_services
      }
    ) {
      aggregate {
        count
      }
    }
    completed_appointments: events_aggregate(
      where: {
        team_id: { _eq: $team_id }
        event_status: { code: { _eq: "DONE" } }
        start: { _gte: $start, _lte: $end }
        online: { _eq: true }
        location_id: $location_id
        event_services: $event_services
      }
    ) {
      aggregate {
        count
      }
    }
    not_completed_appointments: events_aggregate(
      where: {
        team_id: { _eq: $team_id }
        event_status: { code: { _neq: "DONE" } }
        start: { _gte: $start, _lte: $end }
        online: { _eq: true }
        location_id: $location_id
        event_services: $event_services
      }
    ) {
      aggregate {
        count
      }
    }
    cancelled_appointments: events_aggregate(
      where: {
        team_id: { _eq: $team_id }
        event_status: { code: { _eq: "CANCELED" } }
        start: { _gte: $start, _lte: $end }
        online: { _eq: true }
        location_id: $location_id
        event_services: $event_services
      }
    ) {
      aggregate {
        count
      }
    }
    noshow_appointments: events_aggregate(
      where: {
        team_id: { _eq: $team_id }
        event_status: { code: { _eq: "NOT_COME" } }
        start: { _gte: $start, _lte: $end }
        online: { _eq: true }
        location_id: $location_id
        event_services: $event_services
      }
    ) {
      aggregate {
        count
      }
    }
    prev_appointments: events_aggregate(
      where: {
        team_id: { _eq: $team_id }
        start: { _gte: $prev, _lte: $start }
        online: { _eq: true }
        location_id: $location_id
        event_services: $event_services
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const OCCUPANCY_METRICS = gql`
  query OCCUPANCY_METRICS(
    $team_id: uuid!
    $date: date!
    $start: timestamptz!
    $end: timestamptz!
    $prev: timestamptz!
    $location_id: uuid_comparison_exp
    $user_id: uuid_comparison_exp
    $day: bpchar
  ) {
    booked_hours: event_services_aggregate(
      where: {
        team_id: { _eq: $team_id }
        start: { _gte: $start, _lte: $end }
        event: { event_status: { code: { _nin: ["CANCELED"] } }, location_id: $location_id }
        staff_id: $user_id
      }
    ) {
      aggregate {
        sum {
          duration
        }
      }
    }
    prev_booked_hours: event_services_aggregate(
      where: {
        team_id: { _eq: $team_id }
        start: { _gte: $prev, _lte: $start }
        event: { event_status: { code: { _nin: ["CANCELED"] } } }
        staff_id: $user_id
      }
    ) {
      aggregate {
        sum {
          duration
        }
      }
    }
    user_working_hours: user_working_hours(
      where: {
        team_id: { _eq: $team_id }
        date_start: { _lte: $date }
        date_end: { _gte: $date }
        location_id: $location_id
        day_of_week: { _eq: $day }
        user_id: $user_id
      }
    ) {
      date_start
      date_end
      day_of_week
      shift_start_time
      shift_end_time
      second_shift_start_time
      second_shift_end_time
    }
  }
`

export const SALES_METRICS = gql`
  query SALES_METRICS(
    $team_id: uuid!
    $start: timestamptz!
    $end: timestamptz!
    $prev: timestamptz!
    $event: events_bool_exp
  ) {
    total_income: vault_activities_aggregate(
      where: {
        type: { _eq: "income" }
        team_id: { _eq: $team_id }
        deleted_by: { _is_null: true }
        created_at: { _gte: $start, _lte: $end }
        event: $event
      }
    ) {
      aggregate {
        sum {
          price
          net_price
        }
        avg {
          price
          net_price
        }
        count
      }
    }
    prev_total_income: vault_activities_aggregate(
      where: {
        type: { _eq: "income" }
        team_id: { _eq: $team_id }
        deleted_by: { _is_null: true }
        created_at: { _gte: $prev, _lte: $start }
        event: $event
      }
    ) {
      aggregate {
        sum {
          price
          net_price
        }
        avg {
          price
          net_price
        }
        count
      }
    }
  }
`

export const UPDATE_TIPS = gql`
  mutation UPDATE_TIPS($objects: [tips_insert_input!]!, $where: tips_bool_exp!) {
    delete_tips(where: $where) {
      affected_rows
    }
    insert_tips(objects: $objects) {
      affected_rows
    }
  }
`

export const UPDATE_TIP = gql`
  mutation UPDATE_TIP($id: uuid!, $service: event_services_set_input!) {
    update_tips_by_pk(pk_columns: { id: $id }, _set: $service) {
      id
    }
  }
`

export const DELETE_TIP = gql`
  mutation DELETE_TIP($id: uuid!) {
    update_tips_by_pk(id: $id) {
      id
    }
  }
`

export const GET_TIPS_TOTAL_PRICE = gql`
  query GET_TIPS_TOTAL_PRICE($where: tips_bool_exp!) {
    tips_total_price: tips_aggregate(where: $where) {
      aggregate {
        sum {
          price
        }
      }
    }
  }
`

export const GET_STRIPE_ACCOUNTS = gql`
  query GET_STRIPE_ACCOUNTS(
    $limit: Int!
    $offset: Int!
    $where: stripe_accounts_bool_exp!
    $order_by: [stripe_accounts_order_by!]
  ) {
    stripe_accounts(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      id
      account_id
      created_at
    }
  }
`

export const GET_SALES_REPORT = gql`
  query GET_SALES_REPORT(
    $event_services: event_services_bool_exp
    $event_products: event_products_bool_exp
  ) {
    event_services: event_services_aggregate(where: $event_services) {
      aggregate {
        sum {
          price
        }
        count
      }
    }
    event_products: event_products_aggregate(where: $event_products) {
      aggregate {
        sum {
          price
        }
        count
      }
    }
  }
`
