<template>
  <div v-if="bundles && bundles.length">
    <div
      v-for="(bundle, key) in bundles.filter((x) => x.price !== x.paid.aggregate.sum.price)"
      :key="key"
      class="card"
    >
      <div class="card-body">
        <h4 class="card-title">
          {{ bundle.name }}
          <span
            v-for="(service, serviceKey) in bundle.bundle_services"
            :key="serviceKey"
            style="font-size: 13px"
            >({{ service.service.name }})</span
          >
        </h4>
        <div class="table-responsive">
          <table class="table mb-3 table-striped">
            <thead>
              <tr>
                <th>Tarih</th>
                <th>Ödeme</th>
                <th>Durum</th>
                <th>{{ $t('tables.action') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(payment, paymentKey) in bundle.bundle_payments" :key="paymentKey">
                <td>{{ moment(payment.date).format('DD/MM/YYYY') }}</td>
                <td>{{ formatPrice(payment.price) }}</td>
                <td>
                  <p v-if="payment.status === 'PENDING'">
                    ÖDEME BEKLENİYOR
                    <span
                      v-if="
                        moment().format('YYYY-MM-DD') > moment(payment.date).format('YYYY-MM-DD')
                      "
                      class="text-danger"
                      style="font-weight: bold"
                    >
                      - GÜNÜ GEÇTİ <i class="bx bxs-info-circle bx-flashing bx-flip-vertical"></i
                    ></span>
                  </p>
                  <p v-if="payment.status === 'DONE'" class="text-success">ÖDEME YAPILDI</p>
                </td>
                <td>
                  <button
                    v-if="payment.status === 'PENDING'"
                    type="button"
                    title="Taksit Ödemesi"
                    class="btn btn-primary btn-sm"
                    @click="
                      addListPayment[payment.id] = !addListPayment[payment.id]
                      $forceUpdate()
                    "
                  >
                    <i class="bx bx-lira font-size-13 align-middle me-1"></i>Ödeme Yap
                  </button>
                </td>
                <b-modal v-model="addListPayment[payment.id]" hide-footer title="Tahsilat Ekle">
                  <invoice-component
                    :key-i-d="payment.id"
                    :data="payment"
                    @updated="paymentUpdate($event)"
                    @close="addListPayment = {}"
                  ></invoice-component>
                </b-modal>
              </tr>
            </tbody>
          </table>
          <table class="table table-nowrap mb-0">
            <tbody>
              <tr>
                <th scope="row">{{ $t('tables.total_amount') }} :</th>
                <td>{{ formatPrice(bundle.price) }}</td>
              </tr>
              <tr>
                <th scope="row">Tahsil Edilen :</th>
                <td>
                  {{
                    bundle.paid.aggregate.sum.price !== null
                      ? formatPrice(bundle.paid.aggregate.sum.price)
                      : formatPrice(0)
                  }}
                </td>
              </tr>
              <tr>
                <th scope="row">Beklenen Tahsilat :</th>
                <td>
                  {{
                    bundle.debt.aggregate.sum.price !== null
                      ? formatPrice(bundle.debt.aggregate.sum.price)
                      : formatPrice(0)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import invoiceComponent from '@/components/widgets/invoice-payment'
import { GET_BUNDLES, UPDATE_PAYMENT } from '@/router/queries'

export default {
  name: 'ContactDept',
  components: {
    invoiceComponent,
  },
  props: ['event', 'refreshData'],
  data() {
    return {
      moment,
      addListPayment: {},
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  watch: {
    refreshData: function () {
      this.$apollo.queries.bundles.refresh()
    },
  },
  methods: {
    paymentUpdate(id) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_PAYMENT,
        variables: {
          payment: {
            status: 'DONE',
          },
          id: id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$emit('close')
            this.$apollo.queries.bundles.refresh()
            this.$forceUpdate()
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
    },
  },
  apollo: {
    bundles: {
      fetchPolicy: 'no-cache',
      query: GET_BUNDLES,
      variables() {
        return {
          where: {
            contact_id: {
              _eq: this.event.contact_id,
            },
          },
        }
      },
    },
  },
}
</script>
