<template>
  <b-modal
    v-model="showModal"
    :title="$t('navbar.dropdown.quick_menu.add_transaction')"
    hide-footer
    :size="addInvoiceCheck === true ? 'xl' : 'md'"
  >
    <template #modal-header>
      <div class="w-100">
        <div class="row">
          <div class="col-md-6">
            <h4 class="card-title">
              {{ $t('navbar.dropdown.quick_menu.add_transaction') }}
            </h4>
          </div>
          <div class="col-md-6">
            <div class="form-check form-switch float-end">
              <input
                id="flexSwitchCheckDefault"
                v-model="addInvoiceCheck"
                class="form-check-input"
                type="checkbox"
                role="switch"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault">{{
                addInvoiceCheck === true
                  ? $t('navbar.dropdown.quick_menu.customer_transaction')
                  : $t('navbar.dropdown.quick_menu.other_transaction')
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </template>
    <form v-if="contacts" @submit.prevent="handleSubmit">
      <div v-if="addInvoiceCheck === false" class="row">
        <div class="col-md-6">
          <div class="mb-3">
            <label for="voucher_date">{{ $t('case.date') }}</label>
            <!--<b-form-input
                format="DD/MM/YYYY HH:mm"
                id="date-time"
                value-type="YYYY-MM-DD HH:mm"
                type="datetime-local"
               v-model="activities.voucher_date"
                           :class="{
              'is-invalid': submitted && $v.activities.voucher_date.$error,
            }"
              ></b-form-input>
          <div
            v-if="submitted && !$v.activities.voucher_date.required"
            class="invalid-feedback"
          >
            {{ $t('fields.required') }}
          </div>
          -->
            <VueCtkDateTimePicker
              v-model="activities.voucher_date"
              color="#2a3042"
              button-color="#2a3042"
              :button-now-translation="$t('buttons.now')"
              :label="$t('buttons.today')"
              :error="submitted && $v.activities.voucher_date.$error"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="type">{{ $t('case.type') }}</label>
            <multiselect
              v-model="activities.type"
              :placeholder="$t('fields.choose')"
              :show-labels="false"
              :options="valueType.map((x) => x.key)"
              :custom-label="(opt) => valueType.find((x) => x.key == opt).name"
              :class="{
                'is-invalid': submitted && $v.activities.type.$error,
              }"
            >
              <span slot="noResult">
                {{ $t('lists.no_record') }}
              </span>
              <span slot="noOptions">
                {{ $t('lists.no_record') }}
              </span>
            </multiselect>
            <div v-if="submitted && !$v.activities.type.required" class="invalid-feedback">
              {{ $t('fields.required') }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <label for="price">{{ $t('case.price') }}</label>
          <input
            id="price"
            v-model="activities.price"
            placeholder="100"
            class="form-control"
            type="number"
            :class="{
              'is-invalid': submitted && $v.activities.price.$error,
            }"
          />
          <div v-if="submitted && !$v.activities.price.required" class="invalid-feedback">
            {{ $t('fields.required') }}
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-3">
            <label for="payment_method">{{ $t('case.payment_method') }}</label>
            <multiselect
              id="payment_method"
              v-model="activities.payment_method"
              :show-labels="false"
              :placeholder="$t('fields.choose')"
              :options="paymentMethod.map((x) => x.key)"
              :custom-label="(opt) => paymentMethod.find((x) => x.key == opt).name"
              :class="{
                'is-invalid': submitted && $v.activities.payment_method.$error,
              }"
            >
              <span slot="noResult">
                {{ $t('lists.no_record') }}
              </span>
              <span slot="noOptions">
                {{ $t('lists.no_record') }}
              </span>
            </multiselect>
            <div
              v-if="submitted && !$v.activities.payment_method.required"
              class="invalid-feedback"
            >
              {{ $t('fields.required') }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-0">
            <label for="location_id">{{ $t('case.location') }}</label>
            <multiselect
              id="location_id"
              v-if="locationsArr"
              v-model="activities.location_id"
              :placeholder="$t('calendar.location_search')"
              :show-labels="false"
              :options="locationsArr.map((x) => x.id)"
              :custom-label="(opt) => locationsArr.find((x) => x.id === opt).name"
              :class="{
                'is-invalid': submitted && $v.activities.location_id.$error,
              }"
            >
              <span slot="noResult">{{ $t('lists.no_record') }}</span>
              <span slot="noOptions">{{ $t('lists.no_record') }}</span>
            </multiselect>
            <div v-if="submitted && !$v.activities.location_id.required" class="invalid-feedback">
              {{ $t('fields.required') }}
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="mb-3 mt-3">
            <label for="description">{{ $t('case.description') }}</label>
            <b-form-textarea
              id="description"
              v-model="activities.description"
              rows="3"
              :class="{
                'is-invalid': submitted && $v.activities.description.$error,
              }"
            ></b-form-textarea>
            <div v-if="submitted && !$v.activities.description.required" class="invalid-feedback">
              {{ $t('fields.required') }}
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="$emit('close')">{{ $t('buttons.close') }}</b-button>
          <b-button type="submit" variant="success" class="ms-1">{{ $t('buttons.add') }}</b-button>
        </div>
      </div>

      <div v-if="addInvoiceCheck === true" class="row">
        <div class="col-md-4">
          <div class="mb-3">
            <label for="contact_id">{{ $t('case.customer') }}</label>
            <multiselect
              id="contact_id"
              v-model="activitiesC.contact_id"
              :placeholder="$t('fields.choose')"
              :show-labels="false"
              :options="contacts.map((x) => x.id)"
              :custom-label="
                (opt) =>
                  contacts.find((x) => x.id == opt)
                    ? (contacts.find((x) => x.id == opt).first_name || '') +
                      ' ' +
                      (contacts.find((x) => x.id == opt).last_name || '') +
                      ' | ' +
                      (contacts.find((x) => x.id == opt).phone || '-')
                    : 'N/A'
              "
              :searchable="true"
              :internal-search="false"
              :options-limit="300"
              :clear-on-select="false"
              @search-change="filterContacts"
              @select="
                $apollo.queries.contact_event_dept.refresh()
                $apollo.queries.contact_bundle_dept.refresh()
              "
            >
              >
              <span slot="noResult">
                {{ $t('lists.no_record') }}
              </span>
              <span slot="noOptions">
                {{ $t('lists.no_record') }}
              </span>
            </multiselect>
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-3">
            <label for="voucher_date">{{ $t('case.date') }}</label>
            <VueCtkDateTimePicker
              v-model="activities.voucher_date"
              color="#2a3042"
              button-color="#2a3042"
              :button-now-translation="$t('buttons.now')"
              :label="$t('buttons.today')"
              :error="submitted && $v.activities.voucher_date.$error"
            />
          </div>
        </div>
        <div class="col-4">
          <div class="mb-3">
            <label for="type">{{ $t('case.type') }}</label>
            <multiselect
              v-model="activitiesC.type"
              :placeholder="$t('fields.choose')"
              :show-labels="false"
              :options="valueType.map((x) => x.key)"
              :custom-label="(opt) => valueType.find((x) => x.key == opt).name"
              :class="{
                'is-invalid': submitted && $v.activities.type.$error,
              }"
            >
              <span slot="noResult">
                {{ $t('lists.no_record') }}
              </span>
              <span slot="noOptions">
                {{ $t('lists.no_record') }}
              </span>
            </multiselect>
            <div v-if="submitted && !$v.activities.type.required" class="invalid-feedback">
              {{ $t('fields.required') }}
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group mb-0">
            <label for="payment_method">{{ $t('case.payment_method') }}</label>
            <multiselect
              id="payment_method"
              v-model="activitiesC.payment_method"
              :show-labels="false"
              :placeholder="$t('fields.choose')"
              :options="paymentMethod.map((x) => x.key)"
              :custom-label="(opt) => paymentMethod.find((x) => x.key == opt).name"
              :class="{
                'is-invalid': submitted && $v.activities.payment_method.$error,
              }"
            >
              <span slot="noResult">
                {{ $t('lists.no_record') }}
              </span>
              <span slot="noOptions">
                {{ $t('lists.no_record') }}
              </span>
            </multiselect>
            <div
              v-if="submitted && !$v.activities.payment_method.required"
              class="invalid-feedback"
            >
              {{ $t('fields.required') }}
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="mb-3">
            <label for="description">{{ $t('case.description') }}</label>
            <b-form-input
              id="description"
              v-model="activities.description"
              type="text"
              :class="{
                'is-invalid': submitted && $v.activities.description.$error,
              }"
            ></b-form-input>
            <div v-if="submitted && !$v.activities.description.required" class="invalid-feedback">
              {{ $t('fields.required') }}
            </div>
          </div>
        </div>
        <div v-if="events" class="col-md-6 mt-2">
          <h4 class="card-title">{{ $t('case.appointment_debts') }}</h4>
          <table v-if="eventDeps" class="table">
            <thead>
              <tr>
                <th scope="col">{{ $t('case.appointment_time') }}</th>
                <th scope="col">{{ $t('case.amount') }}</th>
                <th scope="col">{{ $t('case.action') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(event, key) in eventDeps" :key="key">
                <td>{{ moment(event.start).format('Do MMMM YYYY HH:ss') }}</td>
                <td>
                  {{
                    formatPrice(
                      event.sP.aggregate.sum.price +
                        event.pP.aggregate.sum.price -
                        (event.vP.aggregate.sum.price - event.bP.aggregate.sum.price)
                    )
                  }}
                </td>
                <td>
                  <span v-if="depts.find((x) => x.event_id === event.id)" class="text-success">{{
                    $t('case.processed')
                  }}</span>
                  <b-button
                    v-if="!depts.find((x) => x.event_id === event.id)"
                    variant="success btn-sm"
                    @click="pay(event)"
                    >{{ $t('case.pay') }}</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <b-alert v-if="!eventDeps.length" show variant="info">{{
            $t('case.warning.no_debt_on_customer')
          }}</b-alert>
        </div>
        <div v-if="contact_bundle_dept" class="col-md-6 mt-2">
          <h4 class="card-title">{{ $t('case.bundle_debt') }}</h4>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ $t('case.bundle_title') }}</th>
                <th scope="col">{{ $t('case.due_date') }}</th>
                <th scope="col">{{ $t('case.amount') }}</th>
                <th scope="col">{{ $t('case.action') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bundle, key) in contact_bundle_dept" :key="key">
                <td>{{ bundle.bundle.name }}</td>
                <td>{{ moment(bundle.date).format('Do MMMM YYYY HH:ss') }}</td>
                <td>{{ formatPrice(bundle.price) }}</td>
                <td>
                  <span
                    v-if="depts.find((x) => x.bundle_payment_id === bundle.id)"
                    class="text-success"
                    >{{ $t('case.processed') }}</span
                  >
                  <b-button
                    v-if="!depts.find((x) => x.bundle_payment_id === bundle.id)"
                    variant="success btn-sm"
                    @click="payBundle(bundle)"
                    >{{ $t('case.pay') }}</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <b-alert v-if="!contact_bundle_dept.length" show variant="info">{{
            $t('case.warning.no_debt_on_customer')
          }}</b-alert>
        </div>
        <div class="col-md-12">
          <h4 class="card-title">{{ $t('case.detail') }}</h4>
          <table v-if="depts.length" class="table">
            <thead>
              <tr>
                <th scope="col">{{ $t('case.date') }}</th>
                <th scope="col">{{ $t('case.currency') }}</th>
                <th scope="col">{{ $t('case.type') }}</th>
                <th scope="col">{{ $t('case.payment_method') }}</th>
                <th scope="col">{{ $t('case.amount') }}</th>
                <th scope="col">{{ $t('case.cancel') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dept, dKey) in depts" :key="dKey">
                <td>
                  {{ moment(dept.voucher_date).format('Do MMMM YYYY HH:ss') }}
                </td>
                <td>{{ dept.price_type }}</td>
                <td>{{ dept.type }}</td>
                <td>{{ dept.payment_method }}</td>
                <td>{{ formatPrice(dept.price) }}</td>
                <td>
                  <b-button variant="success btn-sm" @click="depts.splice(dkey, 1)">{{
                    $t('case.cancel')
                  }}</b-button>
                </td>
              </tr>
              <tr>
                <td class="text-end" colspan="5">{{ $t('case.total') }}:</td>
                <td>{{ formatPrice(totalPrice) }}</td>
              </tr>
            </tbody>
          </table>
          <b-alert v-if="!depts.length" show variant="info">{{
            $t('case.no_invoice_payable')
          }}</b-alert>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="$emit('close')">{{ $t('buttons.close') }}</b-button>
          <b-button type="submit" variant="success" class="ms-1" @click="save">{{
            $t('buttons.add')
          }}</b-button>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
import {
  INSERT_VAULT_ACTIVITIES,
  GET_CONTACTS_AGENDA,
  UPDATE_PAYMENT,
  INSERT_VAULT_ACTIVITIES_MULTI,
  GET_CONTACT_BUNDLE_DEPT,
  GET_CONTACT_EVENT_DEPT,
} from '@/router/queries'

export default {
  name: 'Invoice',
  components: {
    Multiselect,
    VueCtkDateTimePicker,
  },
  props: {
    addInvoiceModal: {
      type: Boolean,
    },
    locationsProp: {
      type: Object,
    },
  },
  data() {
    return {
      submitted: false,
      activities: null,
      activitiesC: null,
      addInvoiceCheck: false,
      depts: [],
      valueType: [
        {
          key: 'income',
          name: this.$t('case.income'),
        },
        {
          key: 'expense',
          name: this.$t('case.expense'),
        },
      ],
      searchContactInput: '',
    }
  },
  computed: {
    ...mapState(['auth']),
    showModal: {
      get() {
        return this.addInvoiceModal
      },
      set() {
        this.$emit('close')
      },
    },
    eventDeps() {
      const data = this.events.filter(
        (x) =>
          x.sP.aggregate.sum.price +
            x.pP.aggregate.sum.price -
            (x.vP.aggregate.sum.price -
              (x.bP.aggregate.sum.price !== null ? x.bP.aggregate.sum.price : 0)) !==
          0
      )
      return data
    },
    totalPrice() {
      let totals = 0
      for (const price of this.depts) {
        const totalPrice = price.price
        totals += totalPrice
      }
      return totals
    },
    paymentMethod() {
      return [
        {
          key: 'credit_card',
          name: this.$t('case.credit_card'),
        },
        {
          key: 'transfer',
          name: this.$t('case.transfer'),
        },
        {
          key: 'cash',
          name: this.$t('case.cash'),
        },
      ]
    },
    searchContact() {
      return {
        _ilike: this.searchContactInput ? '%' + this.searchContactInput + '%' : undefined,
      }
    },
    searchFullName() {
      return {
        _in: this.searchContactInput.split(' '),
      }
    },
    locationsArr() {
      return this.locationsProp.filter((x) => x.name !== 'All Locations')
    },
  },
  created() {
    this.$_loading()
    this.activities = {
      price: null,
      voucher_date: moment().format('YYYY-MM-DD HH:mm'),
      type: null,
      price_type: null,
      description: null,
      invoice_id: null,
      contact_id: null,
      location_id: null,
      user_id: this.auth.user.id,
      team_id: this.auth.user.team_id,
    }
    this.activitiesC = {
      price: null,
      voucher_date: moment().format('YYYY-MM-DD HH:mm'),
      type: 'income',
      price_type: 'EUR',
      description: null,
      invoice_id: null,
      payment_method: 'cash',
      contact_id: null,
      location_id: null,
      user_id: this.auth.user.id,
      team_id: this.auth.user.team_id,
    }
    this.$apollo.queries.contact_event_dept.stop()
    this.$apollo.queries.contact_bundle_dept.stop()
  },
  validations: {
    activities: {
      voucher_date: { required },
      price: { required },
      description: { required },
      payment_method: { required },
      type: { required },
      location_id: { required },
    },
  },
  methods: {
    handleSubmit() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$loading(false)
        return
      } else {
        this.activities.voucher_date = this.convertUtc(this.activitiesC.voucher_date)
        this.$apollo.mutate({
          mutation: INSERT_VAULT_ACTIVITIES,
          variables: {
            vault_activities: this.activities,
          },
          update: () => {
            try {
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$loading(false)
            } catch (e) {
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          },
        })
        this.$emit('close')
      }
      this.submitted = false
    },
    save() {
      this.$apollo.mutate({
        mutation: INSERT_VAULT_ACTIVITIES_MULTI,
        variables: {
          activities: this.depts,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            for (const bundle of this.depts.filter((x) => x.bundle_payment_id !== null)) {
              if (this.depts.filter((x) => x.bundle_payment_id !== null).length) {
                this.$apollo.mutate({
                  mutation: UPDATE_PAYMENT,
                  variables: {
                    payment: {
                      status: 'DONE',
                    },
                    id: bundle.bundle_payment_id,
                  },
                  update: () => {
                    try {
                      this.$_N({
                        type: 'success',
                        position: 'center',
                      })
                      this.$forceUpdate()
                      this.$loading(false)
                    } catch (e) {
                      this.$_N({
                        type: 'error',
                        position: 'center',
                      })
                    }
                  },
                })
              }
            }
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
      this.$emit('close')
    },
    pay(event) {
      this.depts.push({
        price:
          event.sP.aggregate.sum.price +
          event.pP.aggregate.sum.price -
          (event.vP.aggregate.sum.price - event.bP.aggregate.sum.price),
        voucher_date: this.activitiesC.voucher_date,
        type: this.activitiesC.type,
        price_type: this.activitiesC.price_type,
        description: this.activitiesC.description,
        invoice_id: this.activitiesC.invoice_id,
        payment_method: this.activitiesC.payment_method,
        event_id: event.id,
        bundle_payment_id: null,
        location_id: this.activitiesC.location_id,
        contact_id: this.activitiesC.contact_id,
        user_id: this.auth.user.id,
        team_id: this.auth.user.team_id,
      })
      this.$forceUpdate()
    },
    payBundle(bundle) {
      this.depts.push({
        price: bundle.price,
        voucher_date: this.activitiesC.voucher_date,
        type: this.activitiesC.type,
        price_type: this.activitiesC.price_type,
        description: this.activitiesC.description,
        invoice_id: this.activitiesC.invoice_id,
        payment_method: this.activitiesC.payment_method,
        bundle_payment_id: bundle.id,
        location_id: this.activitiesC.location_id,
        contact_id: this.activitiesC.contact_id,
        user_id: this.auth.user.id,
        team_id: this.auth.user.team_id,
      })
      this.$forceUpdate()
    },
    filterContacts(searchKey) {
      if (searchKey) this.searchContactInput = searchKey
    },
  },
  apollo: {
    contacts: {
      fetchPolicy: 'no-cache',
      query: GET_CONTACTS_AGENDA,
      variables() {
        return {
          limit: 15,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            _or: [
              {
                first_name: this.searchContact,
              },
              {
                last_name: this.searchContact,
              },
              {
                email: this.searchContact,
              },
              {
                phone: this.searchContact,
              },
              {
                first_name: this.searchFullName,
              },
              {
                last_name: this.searchFullName,
              },
            ],
          },
        }
      },
    },
    events: {
      fetchPolicy: 'no-cache',
      query: GET_CONTACT_EVENT_DEPT,
      skip: true,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            contact_id: {
              _eq: this.activitiesC.contact_id,
            },
            event_status: {
              code: {
                _eq: 'DONE',
              },
            },
          },
        }
      },
    },
    contact_bundle_dept: {
      fetchPolicy: 'no-cache',
      query: GET_CONTACT_BUNDLE_DEPT,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            status: {
              _eq: 'PENDING',
            },
            contact_id: {
              _eq: this.activitiesC.contact_id,
            },
          },
        }
      },
    },
  },
}
</script>
