<template>
  <div>
    <PageHeader :title="title" />
    <div v-if="stripe" class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 v-if="stripe.data.business_profile" class="card-title mb-3">
              {{ stripe.data.business_profile.name }}
              <span v-if="stripe.data.email" class="fs-6 fst-italic"
                >({{ stripe.data.email }})</span
              >
            </h4>
            <div v-if="stripe.response.status === 200" class="mb-3">
              <b-table hover :items="items">
                <template #cell(title)="{ value }"> {{ $t(`stripe.${value}`) }}</template>
                <template #cell(status)="{ value }">
                  <div v-if="typeof value === 'boolean'">
                    <b-badge
                      :class="value ? 'bg-success' : 'bg-danger'"
                      :variant="value ? 'success' : 'danger'"
                    >
                      {{ value ? 'Enabled' : 'Disabled' }}
                    </b-badge>
                  </div>
                  <div v-else-if="typeof value === 'object'">
                    <ul>
                      <li v-for="(item, key) in value" :key="key">
                        <span class="text-capitalize fw-bold">{{ $t(`stripe.${key}`) }}: </span>
                        <span class="text-capitalize">{{ item }}</span>
                      </li>
                    </ul>
                  </div>
                  <div v-else>{{ value }}</div>
                </template>
              </b-table>
              <div v-if="stripe.data.details_submitted">
                Login to Stripe to edit your account details:
                <b-button type="button" variant="primary" size="sm" @click="stripeLogin">{{
                  $t('buttons.stripeLogin')
                }}</b-button>
              </div>
            </div>
            <div v-else-if="stripe.response.status !== 202">
              {{ stripe.data.message }}
            </div>
            <div v-if="stripe.response.status === 202 || !stripe.data.details_submitted">
              {{ $t('stripe.onboard_message') }}
              <b-button type="button" variant="primary" size="sm" @click="stripeOnboard">{{
                $t('buttons.stripeOnboard')
              }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <b-overlay
          :show="locationsLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-block"
        >
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h4 class="card-title mb-3">Locations</h4>
                <button class="btn btn-success ms-auto mb-2" @click="locationModal = true">
                  Create Location
                </button>
              </div>
              <div v-if="stripeLocations.length > 0">
                <b-table
                  hover
                  :items="stripeLocations"
                  :fields="[
                    {
                      key: 'display_name',
                      label: 'Display Name',
                      sortable: true,
                    },
                    {
                      key: 'address',
                      label: 'Address',
                      sortable: true,
                    },
                    {
                      key: 'actions',
                      label: 'Actions',
                      sortable: true,
                    },
                  ]"
                >
                  <template #cell(address)="{ value }">
                    {{ value.line1 }}, {{ value.city }}, {{ value.state }}, {{ value.country }}
                  </template>
                  <template #cell(actions)="{ item }">
                    <button
                      type="button"
                      class="btn btn-success btn-sm me-2"
                      @click="openAssignModal(item.id)"
                    >
                      <i class="bx bx-location-plus font-size-16 align-middle me-1"></i>
                      Assign
                    </button>
                    <button
                      type="button"
                      class="btn btn-warning btn-sm me-2"
                      @click="
                        isUpdate = true
                        locationModal = true
                        locationForm = {
                          id: item.id,
                          display_name: item.display_name,
                          address: { ...locationForm.address, ...item.address },
                        }
                      "
                    >
                      <i class="bx bx-edit font-size-16 align-middle me-1"></i>
                      {{ $t('buttons.edit') }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      @click="
                        $_C({
                          confirmed(result) {
                            if (result) {
                              locationForm = {
                                ...locationForm,
                                id: item.id,
                              }
                              manageLocation(true)
                            }
                          },
                        })
                      "
                    >
                      <i class="bx bx-trash font-size-16 align-middle"></i>
                      {{ $t('buttons.delete') }}
                    </button>
                  </template>
                </b-table>
              </div>
              <div v-else>No locations found.</div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <b-overlay
          :show="terminalLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-block"
        >
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h4 class="card-title mb-3">Terminal Readers</h4>
                <button class="btn btn-success ms-auto mb-2" @click="terminalModal = true">
                  Register Reader
                </button>
              </div>
              <div v-if="readers.length > 0">
                <b-table
                  hover
                  :items="readers"
                  :fields="[
                    {
                      key: 'label',
                      label: 'Label',
                      sortable: true,
                    },
                    {
                      key: 'device_sw_version',
                      label: 'Device Sw Version',
                      sortable: true,
                    },
                    {
                      key: 'serial_number',
                      label: 'Serial Number',
                      sortable: true,
                    },
                    {
                      key: 'location',
                      label: 'Location',
                      sortable: true,
                    },
                    {
                      key: 'status',
                      label: 'Status',
                      sortable: true,
                    },
                    {
                      key: 'actions',
                      label: 'Actions',
                      sortable: true,
                    },
                  ]"
                >
                  <template #cell(location)="{ value }">
                    {{
                      stripeLocations
                        ? stripeLocations.find((x) => x.id === value).display_name
                        : value
                    }}
                  </template>
                  <template #cell(actions)="{ item }">
                    <!-- <button
                      type="button"
                      class="btn btn-warning btn-sm me-2"
                      @click="
                        isUpdate = true
                        terminalModal = true
                        readerForm = {
                          ...readerForm,
                          id: item.id,
                        }
                      "
                    >
                      <i class="bx bx-edit font-size-16 align-middle me-1"></i>
                      {{ $t('buttons.edit') }}
                    </button> -->
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      @click="
                        $_C({
                          confirmed(result) {
                            if (result) {
                              readerForm = {
                                ...readerForm,
                                id: item.id,
                              }
                              manageReader(true)
                            }
                          },
                        })
                      "
                    >
                      <i class="bx bx-trash font-size-16 align-middle"></i>
                      {{ $t('buttons.delete') }}
                    </button>
                  </template>
                </b-table>
              </div>
              <div v-else>No readers found.</div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>

    <!-- LOCATION MODAL -->
    <b-modal
      v-model="locationModal"
      :title="isUpdate ? 'Update Location' : 'Create New Location'"
      hide-footer
    >
      <form @submit.prevent="manageLocation()">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="display_name">Display Name</label>
              <input
                id="display_name"
                v-model="locationForm.display_name"
                placeholder="A name for the location"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': submitted && !$v.locationForm.display_name.required,
                }"
              />
              <div
                v-if="submitted && !$v.locationForm.display_name.required"
                class="invalid-feedback"
              >
                {{ $t('fields.required') }}
              </div>
            </div>
            <div class="mb-3">
              <label for="city">City</label>
              <input
                id="city"
                v-model="locationForm.address.city"
                placeholder="City, district, suburb, town, or village"
                type="text"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <label for="state">State</label>
              <input
                id="state"
                v-model="locationForm.address.state"
                placeholder="State, county, province, or region"
                type="text"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <label for="country">Country</label>
              <input
                id="country"
                v-model="locationForm.address.country"
                placeholder="Two-letter country code"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': submitted && !$v.locationForm.address.country.required,
                }"
              />
              <div
                v-if="submitted && !$v.locationForm.address.country.required"
                class="invalid-feedback"
              >
                {{ $t('fields.required') }}
              </div>
            </div>
            <div class="mb-3">
              <label for="line1">Line 1</label>
              <input
                id="line1"
                v-model="locationForm.address.line1"
                placeholder="Address line 1 (e.g., street, PO Box, or company name)"
                type="text"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <label for="line2">Line 2 (Optional)</label>
              <input
                id="line2"
                v-model="locationForm.address.line2"
                placeholder="Address line 2 (e.g., apartment, suite, unit, or building)"
                type="text"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <label for="postal_code">Postal Code</label>
              <input
                id="postal_code"
                v-model="locationForm.address.postal_code"
                placeholder="ZIP or postal code"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="locationModal = false">{{
            $t('buttons.close')
          }}</b-button>
          <b-button type="submit" variant="success" class="ms-1">{{
            isUpdate ? 'Update Location' : 'Create Location'
          }}</b-button>
        </div>
      </form>
    </b-modal>

    <!-- TERMINAL MODAL -->
    <b-modal
      v-model="terminalModal"
      :title="isUpdate ? 'Edit Reader Label' : 'Register New Reader'"
      hide-footer
    >
      <form @submit.prevent="manageReader()">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="label">Label</label>
              <input
                id="label"
                v-model="readerForm.label"
                placeholder="Custom label for easier identification"
                type="text"
                class="form-control"
              />
            </div>
            <div class="mb-3">
              <label for="location">Location</label>
              <multiselect
                v-if="stripeLocations"
                v-model="readerForm.location"
                :placeholder="$t('calendar.location_search')"
                :show-labels="false"
                :options="stripeLocations.map((x) => x.id)"
                :custom-label="(opt) => stripeLocations.find((x) => x.id === opt).display_name"
                :class="{
                  'is-invalid': submitted && !$v.readerForm.location.required,
                }"
              >
                <span slot="noResult">{{ $t('lists.no_record') }}</span>
                <span slot="noOptions">{{ $t('lists.no_record') }}</span>
              </multiselect>
              <div v-if="submitted && !$v.readerForm.location.required" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
            </div>
            <div class="mb-3">
              <label for="registration_code">Registration Code</label>
              <input
                id="registration_code"
                v-model="readerForm.registration_code"
                placeholder="Generated by the reader"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': submitted && !$v.readerForm.registration_code.required,
                }"
              />
              <div
                v-if="submitted && !$v.readerForm.registration_code.required"
                class="invalid-feedback"
              >
                {{ $t('fields.required') }}
              </div>
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="terminalModal = false">{{
            $t('buttons.close')
          }}</b-button>
          <b-button type="submit" variant="success" class="ms-1">Register Reader</b-button>
        </div>
      </form>
    </b-modal>

    <!-- ASSIGN MODAL -->
    <b-modal v-model="assignModal" title="Assign Location" hide-footer>
      <form @submit.prevent="assignLocations()">
        <div class="row">
          <div class="col-12">
            <div v-if="locations" class="mb-3">
              <label for="location-assign">{{ $t('title.locations') }}</label>
              <multiselect
                id="location-assign"
                v-model="assignedLocations"
                :placeholder="$t('fields.choose')"
                :multiple="true"
                :show-labels="false"
                :close-on-select="false"
                :clear-on-select="true"
                :options="locations.map((x) => x.id)"
                :custom-label="(opt) => locations.find((x) => x.id === opt).name"
              >
                <span slot="noResult">
                  {{ $t('lists.no_record') }}
                </span>
                <span slot="noOptions">
                  {{ $t('lists.no_record') }}
                </span>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="assignModal = false">{{
            $t('buttons.close')
          }}</b-button>
          <b-button type="submit" variant="success" class="ms-1">Submit</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageHeader from '@/components/page-header'
import { required } from 'vuelidate/lib/validators'
import { GET_LOCATIONS, UPDATE_LOCATIONS } from '@/router/queries'

export default {
  name: 'Personal',
  page: {
    title: 'stripe_account',
  },
  components: { PageHeader },
  data() {
    return {
      title: this.$t('stripe_account'),
      stripe: null,
      stripeLocations: [],
      readers: [],
      items: [],
      assignedLocations: [],
      locationForm: {
        address: {
          line1: '',
          line2: '',
          city: '',
          postal_code: '',
          state: '',
          country: '',
        },
        display_name: '',
      },
      readerForm: {
        location: '',
        registration_code: '',
        label: null,
      },
      locationsLoading: false,
      terminalLoading: false,
      locationModal: false,
      terminalModal: false,
      assignModal: false,
      submitted: false,
      isUpdate: false,
    }
  },
  validations: {
    locationForm: {
      display_name: { required },
      address: { country: { required } },
    },
    readerForm: {
      location: { required },
      registration_code: { required },
    },
  },
  computed: {
    ...mapState(['auth']),
  },
  watch: {
    locationModal(val) {
      if (val === true) return
      this.locationForm = {
        address: {
          line1: '',
          line2: '',
          city: '',
          postal_code: '',
          state: '',
          country: '',
        },
        display_name: '',
      }
      this.submitted = false
      this.isUpdate = false
    },
    terminalModal(val) {
      if (val === true) return
      this.readerForm = {
        location: '',
        registration_code: '',
        label: null,
      }
      this.submitted = false
      this.isUpdate = false
    },
  },
  mounted() {
    this.getStripeAccount()
    this.getStripeLocations()
    this.getReaders()
  },
  methods: {
    async getStripeAccount() {
      this.$loading(true)
      const apiURL = process.env.VUE_APP_API_URL
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          team_id: this.auth.user.team_id,
          account_id: this.$route.params.id,
        }),
      }
      try {
        const response = await fetch(`${apiURL}/stripe-account`, requestOptions)
        const data = await response.json()
        this.stripe = {
          data,
          response,
        }
        if (response.status === 200) this.setTableItems()
        this.$loading(false)
        return data
      } catch {
        this.$_N({
          type: 'error',
          position: 'center',
        })
      }
      this.$loading(false)
    },
    async getStripeLocations() {
      this.locationsLoading = true
      const apiURL = process.env.VUE_APP_API_URL
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          step: 'list',
          account_id: this.$route.params.id,
        }),
      }
      try {
        const response = await fetch(`${apiURL}/stripe-locations`, requestOptions)
        const data = await response.json()
        this.stripeLocations = data.locations.data
        this.locationsLoading = false
        return data
      } catch {
        this.$_N({
          type: 'error',
          position: 'center',
        })
      }
      this.locationsLoading = false
    },
    async getReaders() {
      this.terminalLoading = true
      const apiURL = process.env.VUE_APP_API_URL
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          step: 'list',
          account_id: this.$route.params.id,
        }),
      }
      const response = await fetch(`${apiURL}/stripe-terminal`, requestOptions)
      const data = await response.json()
      this.terminalLoading = false
      this.readers = data.data
      return data
    },
    async stripeOnboard() {
      this.$loading(true)
      const apiURL = process.env.VUE_APP_API_URL
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          team_id: this.auth.user.team_id,
          user_id: this.auth.user.id,
          account_id: this.$route.params.id,
        }),
      }
      const response = await fetch(`${apiURL}/stripe-onboard`, requestOptions)
      const data = await response.json()
      this.$loading(false)
      window.open(data.url, '_self')
    },
    async stripeLogin() {
      this.$loading(true)
      const apiURL = process.env.VUE_APP_API_URL
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          team_id: this.auth.user.team_id,
          account_id: this.$route.params.id,
        }),
      }
      const response = await fetch(`${apiURL}/stripe-login`, requestOptions)
      const data = await response.json()
      this.$loading(false)
      window.open(data.url, 'stripeTab')
      console.log(data)
    },
    async manageLocation(isDelete) {
      this.submitted = true
      this.$loading(true)
      const apiURL = process.env.VUE_APP_API_URL
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          step: isDelete ? 'delete' : this.isUpdate ? 'update' : 'create',
          location: this.locationForm,
          account_id: this.$route.params.id,
        }),
      }
      try {
        const response = await fetch(`${apiURL}/stripe-locations`, requestOptions)
        const data = await response.json()
        if (response.status !== 200) throw Error(data.message)
        this.getStripeLocations()
      } catch (error) {
        this.$_N({
          type: 'error',
          position: 'center',
          title: error.message ? error.message : undefined,
        })
      }
      this.$loading(false)
      this.locationModal = false
    },
    async manageReader(isDelete) {
      this.submitted = true
      this.$v.readerForm.$touch()
      if (this.$v.readerForm.$invalid) {
        return
      }
      this.$loading(true)
      const apiURL = process.env.VUE_APP_API_URL
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          step: isDelete ? 'delete' : this.isUpdate ? 'update' : 'register',
          reader: this.readerForm,
          account_id: this.$route.params.id,
        }),
      }
      try {
        const response = await fetch(`${apiURL}/stripe-terminal`, requestOptions)
        const data = await response.json()
        if (response.status !== 200) throw Error(data.message)
        this.getReaders()
      } catch (error) {
        this.$_N({
          type: 'error',
          position: 'center',
          title: error.message ? error.message : undefined,
        })
      }
      this.terminalModal = false
      this.$loading(false)
    },
    setTableItems() {
      this.items = [
        { title: 'details_submitted', status: this.stripe.data.details_submitted },
        { title: 'charges_enabled', status: this.stripe.data.charges_enabled },
        { title: 'payouts_enabled', status: this.stripe.data.payouts_enabled },
        { title: 'capabilities', status: this.stripe.data.capabilities },
      ]
      if (this.stripe.data.disabled_reason)
        this.items.push({ title: 'disabled_reason', status: this.stripe.data.disabled_reason })
    },
    openAssignModal(id) {
      this.locationForm.id = id
      this.assignedLocations = this.locations
        .filter((loc) => loc.stripe_location_id === id)
        .map((loc) => loc.id)
      this.assignModal = true
    },
    assignLocations() {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_LOCATIONS,
        variables: {
          location: {
            stripe_location_id: this.locationForm.id,
            stripe_account_id: this.$route.params.id,
          },
          id: { _in: this.assignedLocations },
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
          } catch {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
          this.$loading(false)
          this.assignModal = false
          this.$apollo.queries.locations.refresh()
        },
      })
    },
  },
  apollo: {
    locations: {
      fetchPolicy: 'no-cache',
      query: GET_LOCATIONS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            is_disable: { _eq: false },
          },
        }
      },
    },
  },
}
</script>
