<template>
  <div v-if="event_services">
    <PageHeader :title="title" :items="items" />
    <div class="row mt-lg-8">
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3 mt-2">
                <b-form-select v-model="date" class="form-select">
                  <b-form-select-option value="today">{{
                    $t('lists.date_range.today')
                  }}</b-form-select-option>
                  <b-form-select-option value="yesterday">{{
                    $t('lists.date_range.yesterday')
                  }}</b-form-select-option>
                  <b-form-select-option value="month">{{
                    $t('lists.date_range.this_month')
                  }}</b-form-select-option>
                  <b-form-select-option value="last_month">{{
                    $t('lists.date_range.last_month')
                  }}</b-form-select-option>
                  <b-form-select-option value="year">{{
                    $t('lists.date_range.this_year')
                  }}</b-form-select-option>
                  <b-form-select-option value="last_year">{{
                    $t('lists.date_range.last_month')
                  }}</b-form-select-option>
                  <b-form-select-option value="custom">{{
                    $t('lists.date_range.custom')
                  }}</b-form-select-option>
                </b-form-select>
              </div>
              <div class="col-sm-3 col-md-2 mt-2">
                <multiselect
                  v-if="locations"
                  v-model="location"
                  :placeholder="$t('calendar.location_search')"
                  :show-labels="false"
                  :options="locations.map((x) => x.id)"
                  :custom-label="(opt) => locations.find((x) => x.id === opt).name"
                  @input="filterByLocation"
                >
                  <span slot="noResult">{{ $t('lists.no_record') }}</span>
                  <span slot="noOptions">{{ $t('lists.no_record') }}</span>
                </multiselect>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row mb-3">
                  <div v-if="date === 'custom'" class="col-md-3">
                    <date-picker
                      v-model="start"
                      value-type="YYYY-MM-DD"
                      format="DD/MM/YYYY"
                      :first-day-of-week="1"
                      lang="en"
                    ></date-picker>
                  </div>
                  <div v-if="date === 'custom'" class="col-md-3">
                    <date-picker
                      v-model="end"
                      value-type="YYYY-MM-DD"
                      format="DD/MM/YYYY"
                      :first-day-of-week="1"
                      lang="en"
                    ></date-picker>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">{{ $t('reports.sales.type') }}</th>
                        <th scope="row">{{ $t('reports.sales.items_sold') }}</th>
                        <th scope="row">{{ $t('reports.sales.gross_sales') }}</th>
                        <th scope="row">{{ $t('reports.sales.net_sales') }}</th>
                        <th scope="row">{{ $t('reports.sales.total_sales') }}</th>
                      </tr>
                      <tr>
                        <td style="font-weight: 600" scope="row">
                          {{ $t('reports.sales.total') }}
                        </td>
                        <td style="font-weight: 600" scope="row">{{ totalItems }}</td>
                        <td style="font-weight: 600" scope="row">
                          {{ totalPrice !== null ? formatPrice(totalPrice) : formatPrice(0) }}
                        </td>
                        <td style="font-weight: 600" scope="row">
                          {{ totalPrice !== null ? formatPrice(totalPrice) : formatPrice(0) }}
                        </td>
                        <td style="font-weight: 600" scope="row">
                          {{ totalPrice !== null ? formatPrice(totalPrice) : formatPrice(0) }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: 400" scope="row">
                          {{ $t('reports.sales.services') }}
                        </td>
                        <td style="font-weight: 400" scope="row">
                          {{ event_services.aggregate.count }}
                        </td>
                        <td style="font-weight: 400" scope="row">
                          {{
                            event_services.aggregate.sum.price !== null
                              ? formatPrice(event_services.aggregate.sum.price)
                              : formatPrice(0)
                          }}
                        </td>
                        <td style="font-weight: 400" scope="row">
                          {{
                            event_services.aggregate.sum.price !== null
                              ? formatPrice(event_services.aggregate.sum.price)
                              : formatPrice(0)
                          }}
                        </td>
                        <td style="font-weight: 400" scope="row">
                          {{
                            event_services.aggregate.sum.price !== null
                              ? formatPrice(event_services.aggregate.sum.price)
                              : formatPrice(0)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: 400" scope="row">
                          {{ $t('reports.sales.products') }}
                        </td>
                        <td style="font-weight: 400" scope="row">
                          {{ event_products.aggregate.count }}
                        </td>
                        <td style="font-weight: 400" scope="row">
                          {{
                            event_products.aggregate.sum.price !== null
                              ? formatPrice(event_products.aggregate.sum.price)
                              : formatPrice(0)
                          }}
                        </td>
                        <td style="font-weight: 400" scope="row">
                          {{
                            event_products.aggregate.sum.price !== null
                              ? formatPrice(event_products.aggregate.sum.price)
                              : formatPrice(0)
                          }}
                        </td>
                        <td style="font-weight: 400" scope="row">
                          {{
                            event_products.aggregate.sum.price !== null
                              ? formatPrice(event_products.aggregate.sum.price)
                              : formatPrice(0)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import moment from 'moment'
import { GET_SALES_REPORT, GET_PERMISSION, GET_LOCATIONS } from '@/router/queries'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/tr'
import { mapState } from 'vuex'

export default {
  page: {
    title: 'sales_report',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: { PageHeader, DatePicker },
  data() {
    return {
      moment,
      title: this.$t('reports.sales.title'),
      items: [
        {
          text: this.$t('reports.title'),
          href: '/',
        },
        {
          text: this.$t('reports.sales.title'),
          active: true,
        },
      ],
      event_services: null,
      event_products: null,
      date: 'today',
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      staff_locations: undefined,
      locationFilter: undefined,
      location: false,
    }
  },
  computed: {
    ...mapState(['auth']),
    // eslint-disable-next-line vue/return-in-computed-property
    request() {
      const date = new Date()
      if (this.date === 'today') {
        const _and = [
          { created_at: { _gte: moment().format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'yesterday') {
        const _and = [
          { created_at: { _gte: moment().add(-1, 'days').format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'month') {
        const _and = [
          { created_at: { _gte: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_month') {
        const _and = [
          {
            created_at: {
              _gte: moment().month(date.getMonth()).date(1).add(-1, 'month').format('YYYY-MM-DD'),
            },
          },
          { created_at: { _lte: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'week') {
        const _and = [
          { created_at: { _gte: moment().day(1).format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_week') {
        const _and = [
          { created_at: { _gte: moment().day(1).add(-7, 'days').format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().day(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'year') {
        const _and = [
          { created_at: { _gte: moment().month(0).date(1).format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_year') {
        const _and = [
          { created_at: { _gte: moment().month(0).date(1).add(-1, 'year').format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().month(0).date(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'custom') {
        const _and = [
          { created_at: { _gte: moment(this.start).format('YYYY-MM-DD') } },
          { created_at: { _lte: moment(this.end).add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
    },
    totalItems() {
      return this.event_services.aggregate.count + this.event_products.aggregate.count
    },
    totalPrice() {
      return this.event_services.aggregate.sum.price + this.event_products.aggregate.sum.price
    },
  },
  watch: {
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  created() {
    this.$_loading()
  },
  methods: {
    filterByLocation(location) {
      this.locationFilter = location ? location : undefined
      this.$apollo.queries.event_services.refresh()
    },
  },
  apollo: {
    event_services: {
      fetchPolicy: 'no-cache',
      query: GET_SALES_REPORT,
      variables() {
        return {
          event_services: {
            _and: this.request,
            event: {
              location_id: { _eq: this.locationFilter },
              event_status: {
                code: {
                  _eq: 'DONE',
                },
              },
            },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.locationFilter,
                },
              },
            },
          },
          event_products: {
            _and: this.request,
            event: {
              location_id: { _eq: this.locationFilter },
              event_status: {
                code: {
                  _eq: 'DONE',
                },
              },
            },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.locationFilter,
                },
              },
            },
          },
        }
      },
      result({ data }) {
        this.event_services = data.event_services
        this.event_products = data.event_products
      },
    },
    locations: {
      fetchPolicy: 'no-cache',
      query: GET_LOCATIONS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            is_disable: { _eq: false },
            id: {
              _in: this.staff_locations,
            },
          },
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>

<style>
@media only screen and (min-width: 768px) {
  .multiselect {
    min-width: fit-content !important;
  }

  .multiselect .multiselect__content-wrapper {
    min-width: fit-content;
  }

  .multiselect__option {
    width: 100%;
  }

  .multiselect__placeholder {
    margin-right: 30px !important;
  }
}
</style>
