<template>
  <div v-if="contacts && contacts_aggregate">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null"
                >{{ $t('lists.per_page') }}...</b-form-select-option
              >
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto">
            <div class="dataTables_filter text-end">
              <b-input
                v-model="search_input"
                type="search"
                :placeholder="$t('buttons.placeHolderSearch')"
              ></b-input>
            </div>
          </div>
        </b-form>

        <button class="btn btn-success ms-auto" @click="showModal = true">
          {{ $t('buttons.addCustomer') }}
        </button>
      </div>
    </div>

    <!-- add customer -->
    <add-contact
      :add-contact-modal="showModal"
      @close="
        showModal = false
        $apollo.queries.contacts.refresh()
      "
    />

    <div class="col-md-12 col-xs-12">
      <div class="card">
        <div class="card-body">
          <div v-if="contacts" class="table-responsive mb-0">
            <b-table
              responsive
              :items="contacts"
              :fields="fields"
              hover="hover"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter_b"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(created_at)="data">
                {{ moment(data.value).format('Do MMMM YYYY HH:ss') }}
              </template>
              <template #cell(phone)="row">
                {{
                  row.item.phone !== null
                    ? row.item.phone.toString().charAt(0) === '+'
                      ? row.item.phone
                      : '+' + row.item.phone
                    : $t('lists.unknown')
                }}
              </template>
              <template #cell(email)="row">
                {{ row.item.email !== null ? row.item.email : $t('lists.unknown') }}
              </template>
              <template #cell(last)="row">
                {{
                  row.item.event_services.length
                    ? moment(row.item.event_services[0].event.start).format('LLL')
                    : $t('lists.no_record')
                }}
              </template>
              <template #cell(locations)="row">
                <div>
                  <p v-for="(location, key) in row.item.location_contacts" :key="key" class="mb-0">
                    {{ location.location?.name }}
                  </p>
                </div>
              </template>
              <template slot="my-link" slot-scope="row">
                <b-link
                  @click="
                    $router.push({
                      name: 'contact.show',
                      params: { id: row.item.id },
                    })
                  "
                  >link</b-link
                >
              </template>
              <template #cell(name)="row">
                <b-img
                  v-if="row.item.image"
                  :src="row.item.image.thumb"
                  fluid
                  alt="image"
                  class="avatar-xs rounded-circle"
                ></b-img>
                <a
                  href="JavaScript:void(0)"
                  @click="
                    $router.push({
                      name: 'contact.show',
                      params: { id: row.item.id },
                    })
                  "
                >
                  {{ (row.item.first_name || '') + ' ' + (row.item.last_name || '') }}
                </a>
              </template>
              <template #cell(actions)="row">
                <button
                  type="button"
                  class="btn btn-success btn-sm waves-effect waves-light"
                  @change="row"
                  @click="
                    $router.push({
                      name: 'contact.show',
                      params: { id: row.item.id },
                    })
                  "
                >
                  <i class="bx bx-search-alt font-size-16 align-middle me-1"></i>
                  {{ $t('buttons.show') }}
                </button>
              </template>
            </b-table>
            <b-alert
              v-if="contacts_aggregate.aggregate.count === 0"
              :show="contacts_aggregate.aggregate.count === 0"
              class="my-1 text-center"
              variant="warning"
              >"
              {{ $t('contacts.no_record') }}
            </b-alert>
          </div>
        </div>
        <div class="row">
          <div class="col pb-3">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="page"
                  :total-rows="contacts_aggregate.aggregate.count"
                  :per-page="per_page"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="text-center my-3">
          <p>
            {{ $t('customerForm.total') }}
            <b>{{ contacts_aggregate.aggregate.count }}</b>
          </p>
        </div>
      </div>
      <!-- end col-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import AddContact from '@/components/widgets/addContact'

import { ADD_CONTACT, GET_CONTACTS, GET_CONTACTS_AGGREGATE, GET_PERMISSION } from '@/router/queries'

export default {
  page: {
    title: 'customers',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { PageHeader, AddContact },
  data() {
    return {
      title: this.$t('menuitems.customers.list.search'),
      items: [
        {
          text: this.$t('menuitems.dashboards.search'),
          href: '/contacts',
        },
        {
          text: this.$t('menuitems.customers.list.search'),
          active: true,
        },
      ],
      showModal: false,
      submitted: false,
      newContacts: null,
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      search_input: this.$route.query.search || '',
      moment,
      currentPage: 1,
      filterOn: [],
      sortBy: 'value',
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      filter_b: null,
      totalRows: 1,
      fields: [
        {
          key: 'name',
          label: this.$t('tables.customer'),
          sortable: true,
        },
        {
          key: 'phone',
          sortable: true,
          label: this.$t('tables.phone'),
        },
        {
          key: 'email',
          sortable: true,
          label: this.$t('tables.email'),
        },
        {
          key: 'last',
          sortable: true,
          label: this.$t('tables.last'),
        },
        {
          key: 'locations',
          sortable: true,
          label: 'Locations',
        },
        {
          key: 'created_at',
          sortable: true,
          label: this.$t('tables.created_at'),
        },
        {
          key: 'actions',
          sortable: true,
          label: this.$t('tables.action'),
        },
      ],
      type: false,
      staff_locations: undefined,
    }
  },
  validations: {
    newContacts: {
      first_name: { required },
      last_name: { required },
    },
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
    rows() {
      return this.contacts.length
    },
  },
  watch: {
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  created() {
    this.$_loading()
    this.newContacts = {
      first_name: null,
      last_name: null,
      phone: null,
      gender_id: null,
      user_id: this.auth.user.id,
      team_id: this.auth.user.team_id,
      email: null,
      location_contacts: {
        data: [],
      },
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$loading(false)
        return
      } else {
        this.$apollo.mutate({
          mutation: ADD_CONTACT,
          variables: {
            contact: this.newContacts,
          },
          update: () => {
            try {
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$loading(false)
              this.$apollo.queries.contacts.refresh()
            } catch (e) {
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          },
        })
        this.showModal = false
      }
      this.submitted = false
    },
    // eslint-disable-next-line no-unused-vars
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filter() {
      this.$apollo.queries.contacts.start()
      this.$_loading()
    },
    clear() {
      this.per_page = 10
      this.sort_by = { created_at: 'desc' }
      this.search_input = ''
      this.$apollo.queries.contacts.refresh()
      this.$_loading()
    },
  },
  apollo: {
    contacts: {
      fetchPolicy: 'no-cache',
      query: GET_CONTACTS,
      variables() {
        return {
          limit: this.per_page,
          offset: this.offset,
          order_by: this.sort_by,
          where: {
            _or: [
              {
                first_name: this.search,
              },
              {
                last_name: this.search,
              },
              {
                email: this.search,
              },
              {
                phone: this.search,
              },
            ],
            location_contacts: {
              location_id: {
                _in: this.staff_locations,
              },
            },
          },
        }
      },
    },
    contacts_aggregate: {
      fetchPolicy: 'no-cache',
      query: GET_CONTACTS_AGGREGATE,
      variables() {
        return {
          where: {
            _or: [
              {
                first_name: this.search,
              },
              {
                last_name: this.search,
              },
              {
                email: this.search,
              },
              {
                phone: this.search,
              },
            ],
            location_contacts: {
              location: {
                id: {
                  _in: this.staff_locations,
                },
              },
            },
          },
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped></style>
