<script>
import { GET_TEAM } from '@/router/queries'
import { mapState } from 'vuex'
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    ...mapState(['auth']),
  },
  created() {
    setTimeout(() => {
      this.expire_barespace()
    }, 1000)
    setTimeout(() => {
      this.redirectSetup()
    }, 2000)
  },
  methods: {
    redirectSetup() {
      const teamEmail = this.team.email
      const teamPhone = this.team.phone
      const teamAddress = this.team.address
      if ((!teamEmail && !teamPhone) || !teamAddress) {
        this.$router.push({ name: 'team.setup' }).catch(() => {})
      }
    },
    redirect() {
      this.$router.push({ name: 'expired' })
    },
    expire_barespace: function () {
      // eslint-disable-next-line no-unused-vars
      const countDownDate = new Date(this.team.subscription_expires).getTime()
      const now = new Date().getTime()
      const distance = countDownDate - now
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      if (days <= 0) {
        // eslint-disable-next-line no-console
        this.redirect()
      }
      return days
    },
  },
  apollo: {
    team: {
      fetchPolicy: 'no-cache',
      query: GET_TEAM,
      variables() {
        return {
          id: this.auth.user.team_id,
        }
      },
    },
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18">{{ title }}</h4>

        <div class="page-title-right mobile-item">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
<style>
@media (max-width: 500px) {
  .mobile-item {
    display: none !important;
  }
}
</style>
