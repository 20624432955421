<template>
  <div v-if="locations">
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto">
            <b-input
              id="inlineFormInputName2"
              v-model="search_input"
              :placeholder="$t('fields.search')"
              @input="$apollo.queries.locations.stop()"
            ></b-input>
          </div>
          <div class="col-sm-auto">
            <b-button variant="success" @click="filter">{{
              $t('buttons.placeHolderSearch')
            }}</b-button>
          </div>
          <div class="col-sm-auto">
            <b-button variant="danger" @click="clear">{{ $t('buttons.reset') }}</b-button>
          </div>
        </b-form>
        <div class="col-3 d-flex">
          <button class="btn btn-success ms-auto mb-2" @click="locationModal = true">
            {{ $t('locations.add_location') }}
          </button>
        </div>
      </div>
    </div>
    <!-- table  -->
    <div class="col-md-12 col-xs-12">
      <div class="card">
        <div class="card-body">
          <div class="overflow-auto">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>{{ $t('locations.inputs.name') }}</th>
                  <th>{{ $t('locations.inputs.createdAt') }}</th>
                  <th>{{ $t('locations.inputs.email') }}</th>
                  <th>{{ $t('locations.inputs.phone') }}</th>
                  <th>{{ $t('locations.inputs.address') }}</th>
                  <th>{{ $t('locations.inputs.actions') }}</th>
                </tr>
              </thead>
              <draggable
                v-model="locations"
                tag="tbody"
                @end="onDragEnd"
                @start="onDragStart"
                @change="sortChanged"
              >
                <tr v-for="(locationItem, key) in locations" :key="key">
                  <td>
                    <b-img
                      v-if="locationItem.image"
                      :src="locationItem.image.thumb"
                      rounded
                      fluid
                      alt="image"
                      class="avatar-sm"
                    ></b-img>
                    {{ locationItem.name }}
                  </td>
                  <td>
                    {{ moment(locationItem.created_at).format('Do MMMM YYYY HH:ss') }}
                  </td>
                  <td>{{ locationItem.email }}</td>
                  <td>{{ locationItem.phone }}</td>
                  <td>
                    {{ locationItem.address }}
                  </td>
                  <td>
                    <div class="button-items">
                      <button
                        type="button"
                        class="btn btn-warning btn-sm"
                        @click="setUpdateModal(locationItem)"
                      >
                        <i class="bx bx-edit font-size-16 align-middle me-1"></i>
                        {{ $t('buttons.edit') }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm"
                        @click="setStaffWorkingHoursModal(locationItem)"
                      >
                        <i class="bx bx-calendar font-size-16 align-middle me-1"></i>
                        {{ $t('locations.staff_working_hours') }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        style="margin-left: 5px"
                        @click="remove(locationItem)"
                      >
                        <i class="bx bx-trash font-size-16 align-middle me-1"> </i>
                        {{ $t('buttons.delete') }}
                      </button>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
            <b-alert :show="locations.length === 0" variant="warning" class="text-center mt-3">
              {{ $t('locations.location_not_found') }}
            </b-alert>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-3">
      <p>
        {{ $t('case.total') }} <b>{{ locations.length }}</b>
      </p>
    </div>

    <!-- add location modal -->
    <b-modal v-model="locationModal" :title="$t('locations.add_location')" hide-footer size="xl">
      <add-location
        :update-item-data="updateItem"
        @close="
          locationModal = false
          updateItem = {}
        "
        @submitted="$apollo.queries.locations.refresh()"
      />
    </b-modal>

    <!-- edit location modal -->
    <b-modal v-model="locationModal" :title="$t('locations.edit_location')" hide-footer size="xl">
      <add-location
        :update-item-data="updateItem"
        @close="
          locationModal = false
          updateItem = {}
        "
        @submitted="$apollo.queries.locations.refresh()"
      />
    </b-modal>

    <!-- staff working hours modal -->
    <staff-working-hours
      v-if="showStaffWorkingHours"
      :staff-working-hours-modal="showStaffWorkingHours"
      :location-data="updateItem"
      @close="
        showStaffWorkingHours = false
        updateItem = {}
      "
    />
  </div>
</template>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import { mapState } from 'vuex'
import { GET_LOCATIONS_WORKING_HOURS, DELETE_LOCATION, UPDATE_LOCATION } from '@/router/queries'
import moment from 'moment'
import AddLocation from '@/components/widgets/addLocation.vue'
import StaffWorkingHours from '@/components/widgets/staffWorkingHours.vue'
import Draggable from 'vuedraggable'

const setDragCursor = (value) => {
  const html = document.getElementsByTagName('html').item(0)
  html.classList.toggle('grabbing', value)
}

/**
 * Dashboard Component
 */
export default {
  name: 'Locations',
  page: {
    title: 'locations',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    AddLocation,
    StaffWorkingHours,
    Draggable,
  },
  data() {
    return {
      title: 'Locations',
      locationModal: false,
      items: [
        {
          text: this.$t('title.locations'),
          href: '/',
        },
        {
          text: 'Search Location',
          active: true,
        },
      ],
      sort_by: { order: 'asc' },
      search_input: '',
      updateItem: {},
      moment,
      showStaffWorkingHours: false,
    }
  },
  computed: {
    ...mapState(['auth']),
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
    totalCount() {
      return this.locations.length
    },
  },
  watch: {
    locationModal(val) {
      if (!val) this.updateItem = {}
    },
  },
  created() {
    this.$_loading()
  },
  apollo: {
    locations: {
      fetchPolicy: 'no-cache',
      query: GET_LOCATIONS_WORKING_HOURS,
      variables() {
        return {
          order_by: this.sort_by,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            is_disable: { _eq: false },
            _or: [
              {
                name: this.search,
              },
              {
                phone: this.search,
              },
              {
                email: this.search,
              },
              {
                address: this.search,
              },
              {
                place_id: this.search,
              },
            ],
          },
        }
      },
    },
  },
  methods: {
    setUpdateModal(location) {
      this.locationModal = true
      this.updateItem = location
    },
    setStaffWorkingHoursModal(location) {
      this.showStaffWorkingHours = true
      this.updateItem = location
    },
    remove(location) {
      const self = this
      this.$_C({
        confirmed(result) {
          if (result) {
            self.$loading(true)
            self.$apollo.mutate({
              mutation: DELETE_LOCATION,
              variables: {
                id: location.id,
              },
              update: () => {
                try {
                  self.$_N({
                    type: 'success',
                    position: 'center',
                  })
                  self.$loading(false)
                  self.$apollo.queries.locations.refresh()
                } catch (e) {
                  self.$_N({
                    type: 'error',
                    position: 'center',
                  })
                }
              },
            })
          }
        },
      })
    },
    filter() {
      this.$apollo.queries.locations.start()
      this.$_loading()
    },
    clear() {
      this.sort_by = { order: 'asc' }
      this.search_input = ''
      this.$apollo.queries.locations.refresh()
      this.$_loading()
    },
    sortChanged() {
      const self = this
      this.$loading(true)
      this.locations.forEach(function (location, index) {
        self.$apollo
          .mutate({
            mutation: UPDATE_LOCATION,
            variables: {
              location: {
                order: index,
              },
              id: location.id,
            },
          })
          .then(() => {
            return true
          })
          .catch(() => {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            return false
          })
      })
      this.$_N({
        type: 'success',
        position: 'center',
      })
      this.$loading(false)
    },
    onDragStart() {
      setDragCursor(true)
    },
    onDragEnd() {
      setDragCursor(false)
    },
  },
}
</script>
<style>
.grabbing * {
  cursor: grabbing;
}
</style>
