import { authHeader } from './auth-header'
import i18n from '../../i18n'

const apiURL = process.env.VUE_APP_API_URL || 'http://auth:8080/'

async function login(userr) {
  console.log('login')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userr),
  }
  const response = await fetch(`${apiURL}/login`, requestOptions)
  const user = await handleResponse(response)
  if (user.access_token && user.status === 'ACTIVE') {
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('_user_at', `Bearer ${user.access_token}`)
  }
  console.log('user', user)
  return user
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user')
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  }
  return fetch(`${apiURL}/signup`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user
    })
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(`${apiURL}/me`, requestOptions).then(handleResponse)
}

function handleResponse(response) {
  if (response.status === 409) {
    return Promise.reject(i18n.t('notification.user_exist'))
  }
  if (response.status === 400) {
    return Promise.reject(i18n.t('notification.wrong_credentials'))
  }
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        logout()
        location.reload(true)
      }
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}

function forgetPassword(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  }

  return fetch(`${apiURL}/forget-password`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function resetPassword(token) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(token),
  }

  return fetch(`${apiURL}/reset-password`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function getPermissions(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }

  return fetch(`${apiURL}/get-permissions`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

export const userService = {
  login,
  logout,
  register,
  getAll,
  forgetPassword,
  resetPassword,
  getPermissions,
}
