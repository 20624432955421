<template>
  <div>
    <b-modal v-model="smsModal" hide-footer :title="$t('buttons.sms')">
      <form class="mt-3" @submit.prevent="handleSubmit">
        <div class="form-group row">
          <b-form-group :label="$t('tables.smsTo')" class="mb-3">
            <input
              type="email"
              class="form-control"
              :placeholder="customer.phone_code + '' + customer.phone"
              disabled
            />
          </b-form-group>
        </div>

        <div class="form-group row mb-4">
          <b-form-group :label="$t('tables.content')" class="mb-3">
            <textarea
              v-model="outgoing.content"
              required=""
              class="form-control"
              rows="5"
            ></textarea>
          </b-form-group>
        </div>

        <div class="form-group row justify-content-end">
          <div class="col-sm-auto">
            <button type="submit" class="btn btn-primary w-md">{{ $t('buttons.sms') }}</button>
          </div>
        </div>
      </form>
    </b-modal>
    <div>
      <div>
        <div class="row">
          <div class="col-md-4">
            <h4 class="card-title mb-2">{{ $t('customerCard.sendSMS') }}</h4>
          </div>
          <div class="col-md-8 text-end">
            <b-button
              v-if="customer.phone !== null && team.sms_count > 0"
              variant="success btn-sm"
              @click="smsModal = true"
              ><i class="bx bx-message-dots font-size-16 align-middle me-1"></i
              >{{ $t('buttons.sms') }}</b-button
            >
            <span v-if="team.sms_count <= 0" style="color: #e43a16">
              {{ $t('marketing.sms.run_out') }}.
            </span>
          </div>
        </div>
      </div>
      <hr class="mt-2" />

      <div class="table-responsive">
        <table
          v-if="customer.phone !== null"
          class="table align-middle table-nowrap table-hover mb-0"
        >
          <thead>
            <tr>
              <th scope="col">{{ $t('tables.smsTo') }}</th>
              <th scope="col">{{ $t('tables.schedule') }}</th>
              <th scope="col">{{ $t('menuitems.general.list.status') }}</th>
              <th scope="col" colspan="2">{{ $t('menuitems.dashboards.actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(smsItem, key) in sms" :key="key">
              <td>{{ smsItem.contact.phone_code + '' + smsItem.contact.phone }}</td>
              <td>{{ moment(smsItem.created_at).format('LLL') }}</td>
              <td>
                <span class="badge badge-soft-success font-size-13">{{
                  smsItem.status === 'DONE' ? $t('tables.done') : $t('tables.pending')
                }}</span>
              </td>
              <td>
                <b-dropdown
                  toggle-class="font-size-16 text-muted p-0"
                  menu-class="dropdown-menu-end"
                  variant="white"
                  right
                >
                  <template #button-content>
                    <i class="mdi mdi-dots-horizontal"></i>
                  </template>

                  <b-dropdown-item
                    href="#"
                    @click="
                      updateModal[key] = !updateModal[key]
                      $forceUpdate()
                    "
                    >{{ $t('buttons.show') }}</b-dropdown-item
                  >
                </b-dropdown>
              </td>
              <b-modal
                v-model="updateModal[key]"
                :title="$t('marketing.sms.sms_display')"
                hide-footer
              >
                <div>
                  <b-form-group
                    class="mb-3"
                    :label="$t('marketing.contents')"
                    label-for="formrow-email-input"
                  >
                    <b-form-textarea v-model="smsItem.content" disabled rows="8"></b-form-textarea>
                  </b-form-group>
                </div>
                <div class="text-end mt-3">
                  <b-button variant="light" @click="updateModal = {}">{{
                    $t('buttons.close')
                  }}</b-button>
                </div>
              </b-modal>
            </tr>
          </tbody>
        </table>
        <b-alert v-if="customer.phone === null" show variant="danger">{{
          $t('tableInfo.noPhone')
        }}</b-alert>
        <b-alert v-if="!sms.length" show variant="warning">{{ $t('tableInfo.noData') }}</b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { INSERT_OUTGOING, GET_TEAM } from '@/router/queries'
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
export default {
  name: 'SMS',
  props: ['customer', 'outgoings', 'sms'],
  data() {
    return {
      title: 'SMS',
      smsModal: false,
      updateModal: {},
      outgoing: {
        content: '',
        type: 'SMS',
        team_id: this.customer.team_id,
        contact_id: this.$route.params.id,
      },
      moment,
      team: {
        sms_count: null,
      },
    }
  },
  validations: {
    outgoing: {
      content: { required },
    },
  },
  computed: {
    ...mapState(['auth']),
  },
  created() {
    this.$_loading()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$loading(false)
        return
      } else {
        this.$apollo.mutate({
          mutation: INSERT_OUTGOING,
          variables: {
            outgoing: this.outgoing,
          },
          update: () => {
            try {
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$emit('updated')
              this.$loading(false)
            } catch (e) {
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          },
        })
        this.smsModal = false
      }
      this.submitted = false
    },
  },
  apollo: {
    team: {
      fetchPolicy: 'no-cache',
      query: GET_TEAM,
      variables() {
        return {
          id: this.auth.user.team_id,
        }
      },
    },
  },
}
</script>
