<template>
  <div>
    <b-modal
      v-model="showModal"
      :title="isUpdate ? $t('close_dates.edit_closed_dates') : $t('close_dates.add_closed_date')"
      hide-footer
      size="md"
    >
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-6">
            <b-form-group class="mb-3" :label="$t('close_dates.table.start')" for="start">
              <date-picker
                v-model="closedDates.start_date"
                type="date"
                format="YYYY-MM-DD"
                value-type="YYYY-MM-DD"
                placeholder="Select date"
                lang="en"
                :show-week-number="false"
                :class="{
                  'is-invalid': submitted && $v.closedDates.start_date.$error,
                }"
              />
              <div v-if="submitted && !$v.closedDates.start_date.required" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group class="mb-3" :label="$t('close_dates.table.end')" for="end">
              <date-picker
                v-model="closedDates.end_date"
                type="date"
                format="YYYY-MM-DD"
                value-type="YYYY-MM-DD"
                placeholder="Select date"
                lang="en"
                :show-week-number="false"
                :class="{
                  'is-invalid': submitted && $v.closedDates.end_date.$error,
                }"
              />
              <div v-if="submitted && !$v.closedDates.end_date.required" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group class="mb-3" :label="$t('close_dates.table.description')">
              <b-form-input
                id="description"
                v-model="closedDates.description"
                :class="{
                  'is-invalid': submitted && $v.closedDates.description.$error,
                }"
              ></b-form-input>
              <div
                v-if="submitted && !$v.closedDates.description.required"
                class="invalid-feedback"
              >
                {{ $t('fields.required') }}
              </div>
            </b-form-group>
          </div>
          <div class="col-12 mb-3">
            <locations-input
              :input-data="closedDates.locations"
              :is-invalid="submitted && !locationIsValid"
              @setLocations="setLocations"
            />
          </div>
        </div>
        <b-alert class="mt-3 mb-3" :show="eventFoundInCloseDate" variant="warning">
          {{ $t('close_dates.remove_appointments') }}
          <ul class="mt-3">
            <li v-for="item in eventsInSelectedDate" :key="item.id">
              {{
                item.location.name +
                ' - ' +
                moment(item.start).format('DD MMM') +
                ' at ' +
                moment(item.start).format('hh:mm a')
              }}
            </li>
          </ul>
        </b-alert>
        <div class="text-end">
          <b-button type="submit" variant="primary">
            {{ $t('buttons.save') }}
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import {
  INSERT_CLOSED_DATES,
  UPDATE_CLOSED_DATES,
  DELETE_LOCATION_CLOSED_DATE_BY_CLOSED_DATE_ID,
  ADD_LOCATION_CLOSED_DATES,
  GET_EVENTS_BY_DATE,
} from '@/router/queries'
import DatePicker from 'vue2-datepicker'
import moment from 'moment'

export default {
  components: {
    DatePicker,
  },
  props: {
    closedDatesModal: {
      type: Boolean,
    },
    hideSaveButton: {
      type: Boolean,
    },
    updateItemData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      closedDates: {},
      submitted: false,
      isUpdate: false,
      locationIsValid: true,
      locationChanged: false,
      closedDatesLocations: [],
      eventsInSelectedDate: [],
      eventFoundInCloseDate: false,
      moment,
    }
  },
  computed: {
    ...mapState(['auth']),
    showModal: {
      get() {
        return this.closedDatesModal
      },
      set() {
        this.$emit('close')
      },
    },
  },
  watch: {
    updateItemData(val) {
      if (val.id) {
        this.closedDates = { ...val }
        this.isUpdate = true
      } else this.initData()
    },
  },
  created() {
    this.initData()
  },
  validations: {
    closedDates: {
      start_date: { required },
      end_date: { required },
      description: { required },
    },
  },
  methods: {
    setLocations(val) {
      this.locationChanged = true
      if (this.isUpdate) {
        this.closedDatesLocations = []
        val.forEach((el) => {
          this.closedDatesLocations.push({
            location_id: el.id,
            closed_date_id: this.closedDates.id,
          })
        })
      } else {
        this.closedDates.locations.data = []
        val.forEach((el) => {
          this.closedDates.locations.data.push({
            location_id: el.id,
            closed_date_id: this.closedDates.id,
          })
        })
      }
    },
    initData() {
      this.isUpdate = false
      this.closedDates = {
        start_date: null,
        end_date: null,
        description: null,
        team_id: this.auth.user.team_id,
        locations: {
          data: [],
        },
      }
    },
    async handleSubmit() {
      this.submitted = true
      this.$v.$touch()
      if (this.isUpdate && this.locationChanged && this.closedDatesLocations.length === 0) {
        this.locationIsValid = false
      } else if (!this.isUpdate && this.closedDates.locations.data.length === 0) {
        this.locationIsValid = false
      } else this.locationIsValid = true
      if (this.$v.$invalid || !this.locationIsValid) return
      await this.checkEvents()
      if (!this.eventFoundInCloseDate) {
        if (this.isUpdate) {
          if (this.locationChanged) {
            await this.removeClosedDatesLocation()
            await this.addClosedDatesLocations()
          }
          this.update()
          return
        }
        this.create()
      }
    },
    async checkEvents() {
      this.$loading(true)
      await this.$apollo
        .mutate({
          mutation: GET_EVENTS_BY_DATE,
          variables: {
            start: this.closedDates.start_date + 'T00:00:00',
            end: this.closedDates.end_date + 'T23:59:59',
          },
        })
        .then((res) => {
          this.$loading(false)
          if (res.data.events.length > 0) {
            this.eventFoundInCloseDate = true
            this.eventsInSelectedDate = res.data.events
          }
        })
        .catch(() => {
          this.$loading(false)
          this.$_N({
            type: 'error',
            position: 'center',
          })
        })
    },
    create() {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: INSERT_CLOSED_DATES,
        variables: {
          data: this.closedDates,
        },
        update: () => {
          try {
            this.$emit('close')
            this.$emit('submitted')
          } catch {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$loading(false)
          }
        },
      })
      this.submitted = false
    },
    update() {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_CLOSED_DATES,
        variables: {
          closed_dates: this.$_ER(this.closedDates, ['__typename', 'locations']),
          id: this.closedDates.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
            this.$emit('close')
            this.$emit('submitted')
          } catch {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$loading(false)
          }
        },
      })
      this.updateModal = {}
      this.submitted = false
      this.locationChanged = false
    },
    async removeClosedDatesLocation() {
      await this.$apollo
        .mutate({
          mutation: DELETE_LOCATION_CLOSED_DATE_BY_CLOSED_DATE_ID,
          variables: {
            closed_date_id: this.closedDates.id,
          },
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
        })
    },
    async addClosedDatesLocations() {
      await this.$apollo
        .mutate({
          mutation: ADD_LOCATION_CLOSED_DATES,
          variables: {
            data: this.closedDatesLocations,
          },
        })
        .then(() => {
          this.closedDatesLocations = []
        })
        .catch(() => {
          this.closedDatesLocations = []
          this.$_N({
            type: 'error',
            position: 'center',
          })
        })
    },
  },
}
</script>
