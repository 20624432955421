<template>
  <div>
    <b-modal
      v-model="showModal"
      :title="$t('locations.staff_working_hours_title', { location: locationData.name })"
      hide-footer
      :size="'xl'"
    >
      <div class="row">
        <div class="col-6">
          <locations-input v-if="false" />
        </div>
        <div class="col-6 d-flex align-items-center">
          <button class="btn btn-outline-dark mx-2 ms-auto" @click="getCurrentWeek">
            {{ $t('staff.working_hours.this_week') }}
          </button>
          <div class="d-flex align-items-center">
            <button class="btn btn-outline-dark next-btn" @click="nextWeek">
              <i class="bx bx-left-arrow font-size-14 align-middle me-1" />
            </button>
            <div class="week-picker font-size-14">
              {{
                moment(week.start).format('DD MMM') +
                ' - ' +
                moment(week.end).format('DD MMM') +
                ', ' +
                moment(week.end).format('YYYY')
              }}
            </div>
            <button class="btn btn-outline-dark previous-btn" @click="previousWeek">
              <i class="bx bx-right-arrow font-size-14 align-middle me-1" />
            </button>
          </div>
        </div>
      </div>
      <!-- staff working hours table -->
      <div class="row mt-4">
        <div class="col-12">
          <table>
            <thead>
              <tr>
                <th>{{ $t('staff.working_hours.team_member') }}</th>
                <th v-for="i in 7" :key="i">
                  {{
                    moment(week.start)
                      .add(i - 1, 'day')
                      .format('ddd, DD MMM')
                  }}
                </th>
                <th>{{ $t('staff.working_hours.no_preference') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td class="user-info">
                  <div>
                    <img
                      v-if="user.image"
                      :src="user.image.thumb"
                      class="rounded-circle header-profile-user me-1"
                      alt="Header Avatar"
                    />
                    <img
                      v-else
                      class="rounded-circle header-profile-user me-1"
                      src="@/assets/images/users/avatar-1.jpg"
                      alt="Header Avatar"
                    />
                    <p class="user-name">
                      {{ user.first_name + ' ' + user.last_name }}
                    </p>
                  </div>
                </td>
                <td v-for="day in daysOfWeek" :key="day.index">
                  <div
                    v-for="(userShift, working_hours_index) in user.working_hours.filter(
                      (el) => el.day_of_week === day.index
                    )"
                    :key="working_hours_index"
                  >
                    <div
                      v-if="location.timezone"
                      class="hour-range"
                      @click="setStaffWorkingHours(user, day.index, userShift)"
                    >
                      {{
                        moment(userShift.shift_start_time, 'HH:mm:ss').format('hh:mm A') +
                        ' - ' +
                        moment(userShift.shift_end_time, 'HH:mm:ss').format('hh:mm A')
                      }}
                    </div>
                    <div
                      v-if="userShift.second_shift_start_time"
                      class="hour-range"
                      @click="setStaffWorkingHours(user, day.index, userShift)"
                    >
                      {{
                        moment(userShift.second_shift_start_time, 'HH:mm:ss').format('hh:mm A') +
                        ' - ' +
                        moment(userShift.second_shift_end_time, 'HH:mm:ss').format('hh:mm A')
                      }}
                    </div>
                  </div>
                  <div
                    v-if="
                      user.working_hours.filter((el) => el.day_of_week === day.index).length === 0
                    "
                    class="add-new-item"
                    @click="setStaffWorkingHours(user, day.index)"
                  >
                    <i class="bx bx-plus-circle font-size-24 align-middle me-1 text-info" />
                  </div>
                </td>
                <td>
                  <div class="custom-checkbox" @click="setNoPreferenceStaff(user)">
                    <i
                      v-show="locationData.no_preference === user.id"
                      class="bx bx-check custom-tick"
                    ></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {{ location.id }}
    </b-modal>

    <!-- editStaffWorkingHours -->
    <edit-staff-working-hours
      v-if="editStaffModal"
      :edit-staff-working-hours="editStaffModal"
      :item-data="itemData"
      :location-id="locationData.id"
      :all-shifts="users"
      @close="
        editStaffModal = false
        itemData = {}
      "
      @submitted="
        getWorkingHoursList()
        editStaffModal = false
        itemData = {}
      "
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import locationsInput from '@/components/widgets/locationsInput.vue'
import EditStaffWorkingHours from './editStaffWorkingHours.vue'
import { GET_LOCATION_USER_WORKING_HOURS, UPDATE_LOCATION } from '@/router/queries'

export default {
  components: { locationsInput, EditStaffWorkingHours },
  props: ['staffWorkingHoursModal', 'locationData'],
  data() {
    return {
      moment,
      week: {
        start: '',
        end: '',
      },
      editStaffModal: false,
      itemData: {},
      users: [],
      daysOfWeek: [
        {
          name: 'mon',
          index: '1',
        },
        {
          name: 'tue',
          index: '2',
        },
        {
          name: 'wed',
          index: '3',
        },
        {
          name: 'thu',
          index: '4',
        },
        {
          name: 'fri',
          index: '5',
        },
        {
          name: 'sat',
          index: '6',
        },
        {
          name: 'sun',
          index: '7',
        },
      ],
    }
  },
  computed: {
    ...mapState(['auth', 'location']),
    showModal: {
      get() {
        return this.staffWorkingHoursModal
      },
      set() {
        this.$emit('close')
      },
    },
  },
  created() {
    this.getCurrentWeek()
    this.getWorkingHoursList()
    this.$_loading()
  },
  methods: {
    getWorkingHoursList() {
      this.$loading(true)
      this.$apollo
        .mutate({
          mutation: GET_LOCATION_USER_WORKING_HOURS,
          variables: {
            location_id: this.locationData.id,
            date_start: moment(this.week.start).format('YYYY-MM-DD'),
            date_end: moment(this.week.end).format('YYYY-MM-DD'),
          },
        })
        .then((result) => {
          this.users = result.data.users
          this.$loading(false)
        })
        .catch(() => {
          this.$_N({
            position: 'center',
            type: 'error',
          })
          this.$loading(false)
        })
    },
    getCurrentWeek() {
      this.week.start = moment().startOf('isoweek').toDate()
      this.week.end = moment().endOf('isoweek').toDate()
      this.getWorkingHoursList()
    },
    nextWeek() {
      const dayBeforeFirstDayOfWeek = moment(this.week.start).subtract(1, 'days')
      this.week.start = moment(dayBeforeFirstDayOfWeek).startOf('isoweek').toDate()
      this.week.end = moment(dayBeforeFirstDayOfWeek).endOf('isoweek').toDate()
      this.getWorkingHoursList()
    },
    previousWeek() {
      const dayAfterLastDayOfWeek = moment(this.week.end).add(1, 'day')
      this.week.start = moment(dayAfterLastDayOfWeek).startOf('isoweek').toDate()
      this.week.end = moment(dayAfterLastDayOfWeek).endOf('isoweek').toDate()
      this.getWorkingHoursList()
    },
    setStaffWorkingHours(user, dayIndex, shifts) {
      this.editStaffModal = true
      this.itemData = { user, week: this.week, day_of_week: dayIndex, shifts }
    },
    setNoPreferenceStaff(user) {
      const location = this.locationData
      location.no_preference = user.id
      this.updateLocation(location)
    },
    updateLocation(location) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_LOCATION,
        variables: {
          location: this.$_ER(location, ['__typename', 'location_working_hours']),
          id: location.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
          } catch {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$loading(false)
          }
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.week-picker {
  border: 1px solid #343a40;
  padding: 7px 20px;
  border-right: none;
  border-left: none;
}
.next-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.previous-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

table {
  width: 100%;
  table-layout: fixed;
  position: relative;
  background-color: #fff;
  border-collapse: collapse;
  border-radius: 8px;

  thead {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;

    tr {
      th {
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        padding: 15px 10px;
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #eff2f7;
      transition: background-color 200ms;
      background-color: #fff;

      td {
        border-radius: 8px;
        border: 1px solid #eff2f7;
        position: relative;
        text-align: center;

        .hour-range {
          margin: 7px 10px;
          font-size: 12px;
          cursor: pointer;
        }

        .add-new-item {
          opacity: 0;
          transition: 200ms ease-in all;
          width: 100%;
          height: 57px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        &:hover {
          .add-new-item {
            opacity: 1;
          }
        }
      }
    }
  }

  .hour-range {
    background-color: #fffadf;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px;
    border-radius: 4px;
  }

  .user-info {
    font-weight: 600;
    text-align: center;
    padding-left: 5px;

    .user-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      margin: 5px 0 0;
    }
  }
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  margin: auto;
  border: 1px solid #888e98;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.custom-tick {
  font-size: 35px;
  top: -9px;
  left: -8px;
  position: absolute;
  color: #02bc9c;
}
</style>
