<template>
  <div v-if="bundles && services && bundle_service_types && contacts">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="sort_by" class="form-select">
              <b-form-select-option :value="{ created_at: 'desc' }">{{
                $t('lists.new')
              }}</b-form-select-option>
              <b-form-select-option :value="{ created_at: 'asc' }">{{
                $t('lists.old')
              }}</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto">
            <b-input
              id="inlineFormInputName2"
              v-model="search_input"
              :placeholder="$t('lists.search')"
              @input="$apollo.queries.bundles.stop()"
            ></b-input>
          </div>
          <div class="col-sm-auto">
            <b-button variant="success" @click="filter">{{ $t('buttons.search') }}</b-button>
          </div>
          <div class="col-sm-auto">
            <b-button variant="danger" @click="clear">{{ $t('buttons.reset') }}</b-button>
          </div>
        </b-form>
        <div class="col-3 d-flex">
          <button class="btn btn-success ms-auto mb-2" @click="showModal = true">
            {{ $t('bundle.add') }}
          </button>
        </div>
      </div>
    </div>

    <b-modal v-model="showModal" :title="$t('bundle.add')" hide-footer size="xl">
      <form @submit.prevent="check">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xl-6 col-xs-12 col-sm-12">
            <div class="row">
              <div class="col-12 mb-3">
                <label style="margin-right: 7px">{{ $t('bundle.customer') }}</label>
                <multiselect
                  v-model="bundle.contact_id"
                  :placeholder="$t('bundle.customer')"
                  :show-labels="false"
                  :options="contacts.map((x) => x.id)"
                  :custom-label="
                    (opt) =>
                      contacts.find((x) => x.id == opt)
                        ? (contacts.find((x) => x.id == opt).first_name || '') +
                          ' ' +
                          (contacts.find((x) => x.id == opt).last_name || '') +
                          ' | ' +
                          (contacts.find((x) => x.id == opt).phone || '-')
                        : 'N/A'
                  "
                  :class="{
                    'is-invalid': submitted && $v.bundle.contact_id.$error,
                  }"
                >
                  <span slot="noResult">
                    <b-button variant="success" size="sm" @click="addContact = true"
                      ><i class="bx bx-plus"></i> {{ $t('bundle.add_as_new_customer') }}</b-button
                    >
                    <add-contact
                      :add-contact-modal="addContact"
                      @close="
                        addContact = false
                        $apollo.queries.contacts.refresh()
                      "
                    />
                  </span>
                  <span slot="noOptions">
                    <b-button variant="success" size="sm" @click="addContact = true"
                      ><i class="bx bx-plus"></i> {{ $t('bundle.add_as_new_customer') }}</b-button
                    >
                  </span>
                </multiselect>
                <div v-if="submitted && !$v.bundle.contact_id.required" class="invalid-feedback">
                  {{ $t('fields.required') }}
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="first_name">{{ $t('bundle.title') }}</label>
                  <input
                    id="first_name"
                    v-model="bundle.name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.bundle.name.$error,
                    }"
                  />
                  <div v-if="submitted && !$v.bundle.name.required" class="invalid-feedback">
                    {{ $t('fields.required') }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="price">{{ $t('bundle.price') }}</label>
                  <input
                    id="price"
                    v-model="bundle.price"
                    type="number"
                    step="0.01"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.bundle.price.$error,
                    }"
                  />
                  <div v-if="submitted && !$v.bundle.price.required" class="invalid-feedback">
                    {{ $t('fields.required') }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="mb-3">
                  <label for="description ">{{ $t('bundle.description') }}</label>
                  <input
                    id="description "
                    v-model="bundle.description"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-12 mb-2">
                <div class="row">
                  <div class="col-9">
                    <label>{{ $t('bundle.services') }}</label>
                  </div>
                  <div class="col-3 text-end">
                    <b-button variant="success btn-sm" @click="addService">
                      {{ $t('bundle.add_service') }}
                    </b-button>
                  </div>
                </div>
              </div>
              <div
                v-for="(service, key) in bundle.bundle_services.data"
                :key="key"
                class="col-12 mb-3"
              >
                <div class="row">
                  <div class="col-12 mb-1">
                    <multiselect
                      v-model="service.service_id"
                      :placeholder="$t('bundle.services')"
                      :show-labels="false"
                      :options="services.map((x) => x.id)"
                      :custom-label="(opt) => services.find((x) => x.id == opt).name"
                    >
                      <span slot="noResult">
                        {{ $t('lists.no_record') }}
                      </span>
                      <span slot="noOptions">
                        {{ $t('lists.no_record') }}
                      </span>
                    </multiselect>
                  </div>
                  <div v-if="service.service_id !== null" class="col-6">
                    <multiselect
                      v-model="service.bundle_service_type_id"
                      :placeholder="$t('bundle.type')"
                      :show-labels="false"
                      :options="bundle_service_types.map((x) => x.id)"
                      :custom-label="(opt) => bundle_service_types.find((x) => x.id == opt).name"
                    >
                      <span slot="noResult">
                        {{ $t('lists.no_record') }}
                      </span>
                      <span slot="noOptions">
                        {{ $t('lists.no_record') }}
                      </span>
                    </multiselect>
                  </div>
                  <div v-if="service.service_id !== null" class="col-6">
                    <input
                      id="price"
                      v-model="service.amount"
                      :placeholder="
                        service.bundle_service_type_id !== null
                          ? bundle_service_types.find((x) => x.id == service.bundle_service_type_id)
                              .name +
                            ' ' +
                            $t('bundle.count')
                          : $t('bundle.choose_type')
                      "
                      step="0.01"
                      type="number"
                      class="form-control"
                    />
                  </div>
                  <div
                    v-if="bundle.bundle_services.data[0] !== bundle.bundle_services.data[key]"
                    class="col-12 mt-1"
                  >
                    <b-button
                      variant="danger btn-sm"
                      @click="bundle.bundle_services.data.splice(-1, key)"
                      ><i class="bx bxs-trash-alt"></i
                    ></b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="my-4 barespace" />
          <div v-if="bundle.contact_id" class="col-md-6 col-lg-6 col-xl-6 col-xs-12 col-sm-12">
            <div class="row">
              <div class="col-9">
                <h4 class="card-title">{{ $t('bundle.payment') }}</h4>
                <b-form-group :label="$t('bundle.period')">
                  <b-form-input
                    v-model="period"
                    type="number"
                    class="form-control w-25"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-3 text-end">
                <b-button variant="success btn-sm" @click="addPayment">
                  {{ $t('bundle.add_installment') }}
                </b-button>
              </div>
              <div class="col-12 table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">{{ $t('bundle.date') }}</th>
                      <th scope="col">{{ $t('bundle.price') }}</th>
                      <th scope="col">{{ $t('bundle.action') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(payment, key) in bundle.bundle_payments.data" :key="key">
                      <th scope="row">
                        <date-picker
                          v-model="payment.date"
                          type="datetime"
                          value-type="YYYY-MM-DD"
                          format="DD/MM/YYYY"
                        ></date-picker>
                      </th>
                      <td>
                        <input
                          id="description "
                          v-model="payment.price"
                          type="number"
                          class="form-control btn-outline"
                          step="0.01"
                        />
                      </td>
                      <td>
                        <p v-if="payment.status === 'DONE'" class="text-success">
                          {{ $t('bundle.paid') }}
                        </p>
                        <!--                        <b-button
                          v-if="payment.status === 'PENDING'"
                          @click="
                            paymentModal[key] = !paymentModal[key];
                            $forceUpdate();
                          "
                          variant="info btn-sm"
                          style="margin-top:5px;"
                          v-b-tooltip.hover
                          title="Taksit Ödemesi"
                          >{{ companyCurrency }}</b-button
                        >-->
                        <b-modal
                          v-model="paymentModal[key]"
                          hide-footer
                          :title="$t('bundle.installment_payment')"
                        >
                          <invoice-component
                            :key-i-d="key"
                            :data="payment"
                            @updated="changeStatus($event)"
                            @close="paymentModal = {}"
                          ></invoice-component>
                        </b-modal>
                        <b-button
                          v-if="
                            bundle.bundle_payments.data[0] !== bundle.bundle_payments.data[key] &&
                            payment.status !== 'DONE'
                          "
                          v-b-tooltip.hover
                          variant="danger btn-sm"
                          style="margin-left: 3px; margin-top: 5px"
                          :title="$t('bundle.installment_payment')"
                          @click="bundle.bundle_payments.data.splice(-1, key)"
                          ><i class="bx bxs-trash-alt"></i>
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="showModal = false">{{ $t('buttons.close') }}</b-button>
          <b-button type="submit" variant="success" class="ms-1">{{ $t('buttons.save') }}</b-button>
        </div>
      </form>
    </b-modal>

    <div class="mb-3">
      <div class="col-md-12 col-xs-12">
        <div class="card p-2">
          <div class="card-body">
            <div style="overflow-x: auto">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t('bundle.customer') }}</th>
                    <th>{{ $t('bundle.title') }}</th>
                    <th>{{ $t('bundle.price') }}</th>
                    <th>{{ $t('bundle.installment') }}</th>
                    <th>{{ $t('bundle.paid') }}</th>
                    <th>{{ $t('bundle.pending_payment') }}</th>
                    <th>{{ $t('bundle.created_at') }}</th>
                    <th>{{ $t('bundle.amount_of_use') }}</th>
                    <th>{{ $t('bundle.expiry_time') }}</th>
                    <th>{{ $t('bundle.action') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(bundleItem, key) in bundles" :key="key">
                    <td>
                      {{ bundleItem.contact.first_name + ' ' + bundleItem.contact.last_name }}
                    </td>
                    <td>{{ bundleItem.name }}</td>
                    <td>{{ bundleItem.price }}</td>
                    <td>
                      {{ bundleItem.bundle_payments.length + ' ' + $t('bundle.installment') }}
                    </td>
                    <td>
                      {{
                        bundleItem.paid.aggregate.sum.price !== null
                          ? formatPrice(bundleItem.paid.aggregate.sum.price)
                          : formatPrice(0)
                      }}
                    </td>
                    <td>
                      {{
                        bundleItem.debt.aggregate.sum.price !== null
                          ? formatPrice(bundleItem.debt.aggregate.sum.price)
                          : formatPrice(0)
                      }}
                    </td>
                    <td>
                      {{ moment(bundleItem.created_at).from() }}
                    </td>
                    <td>
                      {{ bundleItem.event_bundles_aggregate.aggregate.count }}
                      {{ $t('bundle.session') }}
                    </td>
                    <td>
                      {{
                        bundleItem.event_bundles.length
                          ? moment(bundleItem.event_bundles[0].event.start).fromNow()
                          : '-'
                      }}
                    </td>
                    <td>
                      <div class="button-items">
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          @click="
                            $router.push({
                              name: 'bundle.show',
                              params: { key: bundleItem.id },
                            })
                          "
                        >
                          <i class="bx bx-search-alt font-size-16 align-middle me-1"></i
                          >{{ $t('buttons.show') }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="
                            paymentDetail[key] = !paymentDetail[key]
                            $forceUpdate()
                          "
                        >
                          <i class="bx bx-info-circle font-size-16 align-middle me-1"></i
                          >{{ $t('bundle.payment_detail') }}
                        </button>
                      </div>
                    </td>
                    <b-modal
                      v-model="paymentDetail[key]"
                      size="xl"
                      hide-footer
                      :title="$t('bundle.payment_detail')"
                    >
                      <table class="table mb-3 table-striped">
                        <thead>
                          <tr>
                            <th>{{ $t('bundle.date') }}</th>
                            <th>{{ $t('bundle.payment') }}</th>
                            <th>{{ $t('bundle.status') }}</th>
                            <th>{{ $t('bundle.action') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(payment, paymentKey) in bundleItem.bundle_payments"
                            :key="paymentKey"
                          >
                            <td>
                              {{ moment(payment.date).format('DD/MM/YYYY') }}
                            </td>
                            <td>{{ formatPrice(payment.price) }}</td>
                            <td>
                              <p v-if="payment.status === 'PENDING'">
                                {{ $t('bundle.pending') }}
                                <span
                                  v-if="
                                    moment().format('YYYY-MM-DD') >
                                    moment(payment.date).format('YYYY-MM-DD')
                                  "
                                  class="text-danger"
                                  style="font-weight: bold"
                                >
                                  - {{ $t('bundle.passed') }}
                                  <i class="bx bxs-info-circle bx-flashing bx-flip-vertical"></i
                                ></span>
                              </p>
                              <p v-if="payment.status === 'DONE'" class="text-success">
                                {{ $t('bundle.paid') }}
                              </p>
                            </td>
                            <td>
                              <button
                                v-if="payment.status === 'PENDING'"
                                type="button"
                                class="btn btn-primary btn-sm"
                                @click="
                                  addListPayment[paymentKey] = !addListPayment[paymentKey]
                                  $forceUpdate()
                                "
                              >
                                <i class="bx bx-lira font-size-16 align-middle me-1"></i
                                >{{ $t('bundle.pay') }}
                              </button>
                            </td>
                            <b-modal
                              v-model="addListPayment[paymentKey]"
                              hide-footer
                              :title="$t('bundle.add_payment')"
                            >
                              <invoice-component
                                :key-i-d="payment.id"
                                :data="payment"
                                @updated="paymentUpdate($event)"
                                @close="addListPayment = {}"
                              ></invoice-component>
                            </b-modal>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">{{ $t('bundle.total_price') }} :</th>
                            <td>{{ formatPrice(bundleItem.price) }}</td>
                          </tr>
                          <tr>
                            <th scope="row">{{ $t('bundle.payment_price') }} :</th>
                            <td>
                              {{
                                bundleItem.paid.aggregate.sum.price !== null
                                  ? formatPrice(bundleItem.paid.aggregate.sum.price)
                                  : formatPrice(0)
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">{{ $t('bundle.pending_payment') }} :</th>
                            <td>
                              {{
                                bundleItem.debt.aggregate.sum.price !== null
                                  ? formatPrice(bundleItem.debt.aggregate.sum.price)
                                  : formatPrice(0)
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="text-end mt-3">
                        <b-button variant="light" @click="paymentDetail = {}">{{
                          $t('buttons.close')
                        }}</b-button>
                      </div>
                    </b-modal>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-alert :show="bundles.length === 0" variant="warning" class="text-center my-1">
              {{ $t('bundle.no_bundle') }}
            </b-alert>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="text-center my-3">
          <p>
            {{ $t('bundle.total') }} <b>{{ bundles.length }}</b>
          </p>
        </div>
      </div>
      <!-- end col-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageHeader from '@/components/page-header'
import Swal from 'sweetalert2'
import appConfig from '@/app.config'
import { required } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import invoiceComponent from '@/components/widgets/invoice-payment'
import AddContact from '@/components/widgets/addContact'
import {
  // eslint-disable-next-line no-unused-vars
  INSERT_BUNDLE,
  GET_BUNDLES,
  UPDATE_BUNDLE,
  UPDATE_PAYMENT,
  DELETE_BUNDLE,
  GET_SERVICES,
  GET_BUNDLE_TYPE,
  GET_CONTACTS_AGENDA,
} from '@/router/queries'

export default {
  name: 'Bundles',
  page: {
    title: 'bundles',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    PageHeader,
    Multiselect,
    DatePicker,
    invoiceComponent,
    AddContact,
  },
  data() {
    return {
      moment,
      title: this.$t('bundle.bundles'),
      items: [
        {
          text: this.$t('bundle.bundles'),
          href: '/',
        },
        {
          text: this.$t('bundle.bundle_list'),
          active: true,
        },
      ],
      showModal: false,
      submitted: false,
      paymentModal: {},
      addListPayment: {},
      paymentDetail: {},
      contact_id: null,
      bundle: null,
      period: 30,
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      search_input: '',
      updateModal: {},
      addContact: false,
      error1: false,
      error2: false,
      error3: false,
    }
  },
  validations: {
    bundle: {
      name: { required },
      price: { required },
      contact_id: { required },
    },
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
    paymentTotal() {
      const price = this.bundle.price / this.bundle.bundle_payments.data.length
      const ınt = parseInt(price)
      Math.round(ınt)
      return price
    },
    totalPrice() {
      let result = 0
      for (const q of this.bundle.bundle_payments.data) {
        const price = parseInt(q.price)
        result += price
      }
      return result
    },
  },
  watch: {
    'bundle.contact_id': function (val) {
      this.contact_id = val
      this.bundle.bundle_payments.data[0].contact_id = val
    },
    'bundle.price': function () {
      for (const q of this.bundle.bundle_payments.data) {
        q.price = this.paymentTotal
      }
    },
    'bundle.bundle_payments.data': function () {
      for (const q of this.bundle.bundle_payments.data) {
        q.price = this.paymentTotal
      }
    },
  },
  created() {
    this.$_loading()
    this.bundle = {
      name: null,
      contact_id: null,
      price: null,
      description: null,
      user_id: this.auth.user.id,
      team_id: this.auth.user.team_id,
      bundle_services: {
        data: [
          {
            amount: null,
            service_id: null,
            bundle_service_type_id: null,
            user_id: this.auth.user.id,
            team_id: this.auth.user.team_id,
          },
        ],
      },
      bundle_payments: {
        data: [
          {
            date: moment().format('YYYY-MM-DD'),
            price: null,
            user_id: this.auth.user.id,
            contact_id: null,
            team_id: this.auth.user.team_id,
            status: 'PENDING',
          },
        ],
      },
    }
  },
  methods: {
    check() {
      for (const q of this.bundle.bundle_services.data) {
        if (q.service_id === null) {
          Swal.fire(this.$t('bundle.warning.choose_service'))
          this.error1 = true
        } else {
          this.error1 = false
        }
        if (q.bundle_service_type_id === null) {
          Swal.fire(this.$t('bundle.warning.choose_service_type'))
          this.error2 = true
        } else {
          this.error2 = false
        }
        if (q.amount === null) {
          Swal.fire(this.$t('bundle.warning.choose_amount'))
          this.error3 = true
        } else {
          this.error3 = false
        }
      }
      if (!this.error1 && !this.error2 && !this.error3) {
        this.handleSubmit()
      }
    },
    handleSubmit() {
      const price = parseInt(this.bundle.price)
      if (price === this.totalPrice) {
        this.$loading(true)
        this.submitted = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$loading(false)
          return
        } else {
          this.$apollo.mutate({
            mutation: INSERT_BUNDLE,
            variables: {
              bundle: this.bundle,
            },
            update: (cache, { data: { insert_bundles_one } }) => {
              try {
                this.$_N({
                  type: 'success',
                  position: 'center',
                })
                this.$router.push({
                  name: 'bundle.show',
                  params: { key: insert_bundles_one.id },
                })
                this.$loading(false)
              } catch (e) {
                this.$_N({
                  type: 'error',
                  position: 'center',
                })
              }
            },
          })
          this.showModal = false
        }
        this.submitted = false
      } else {
        Swal.fire(this.$t('bundle.warning.not_same_price'))
      }
    },
    update(bundle) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_BUNDLE,
        variables: {
          bundle: this.$_ER(bundle, ['__typename', 'bundle_type']),
          id: bundle.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$apollo.queries.bundles.refresh()
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
      this.updateModal = {}
    },
    remove(bundle) {
      const self = this
      this.$_C({
        confirmed(result) {
          if (result) {
            self.$loading(true)
            self.$apollo.mutate({
              mutation: DELETE_BUNDLE,
              variables: {
                id: bundle.id,
              },
              update: () => {
                try {
                  self.$_N({
                    type: 'success',
                    position: 'center',
                  })
                  self.$loading(false)
                  self.$apollo.queries.bundles.refresh()
                } catch (e) {
                  self.$_N({
                    type: 'error',
                    position: 'center',
                  })
                }
              },
            })
          }
        },
      })
    },
    filter() {
      this.$apollo.queries.bundles.start()
      this.$_loading()
    },
    clear() {
      this.per_page = 10
      this.sort_by = { created_at: 'desc' }
      this.search_input = ''
      this.$apollo.queries.bundles.refresh()
      this.$_loading()
    },
    addService() {
      this.bundle.bundle_services.data.push({
        amount: null,
        service_id: null,
        bundle_service_type_id: null,
        user_id: this.auth.user.id,
        team_id: this.auth.user.team_id,
      })
    },
    addPayment() {
      const date =
        this.bundle.bundle_payments.data[this.bundle.bundle_payments.data.length - 1].date
      this.bundle.bundle_payments.data.push({
        date: moment(date).add(this.period, 'day').format('YYYY-MM-DD'),
        price: null,
        contact_id: this.contact_id,
        user_id: this.auth.user.id,
        team_id: this.auth.user.team_id,
        status: 'PENDING',
      })
      this.$forceUpdate()
    },
    changeStatus(key) {
      this.bundle.bundle_payments.data[key].status = 'DONE'
    },
    paymentUpdate(id) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_PAYMENT,
        variables: {
          payment: {
            status: 'DONE',
          },
          id: id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$apollo.queries.bundles.refresh()
            this.$forceUpdate()
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
    },
  },
  apollo: {
    bundles: {
      fetchPolicy: 'no-cache',
      query: GET_BUNDLES,
      variables() {
        return {
          offset: this.offset,
          limit: this.per_page,
          order_by: this.sort_by,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            _or: [
              {
                name: this.search,
              },
              {
                description: this.search,
              },
            ],
          },
        }
      },
    },
    services: {
      fetchPolicy: 'no-cache',
      query: GET_SERVICES,
      variables() {
        return {
          offset: this.offset,
          limit: this.per_page,
          order_by: this.sort_by,
          where: {
            is_disabled: {
              _eq: false,
            },
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
    bundle_service_types: {
      fetchPolicy: 'no-cache',
      query: GET_BUNDLE_TYPE,
    },
    contacts: {
      fetchPolicy: 'no-cache',
      query: GET_CONTACTS_AGENDA,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 700px) {
  .barespace {
    display: none;
  }
}
</style>
