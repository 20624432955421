<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="item in menuItems">
        <li v-if="item.isTitle && showMenu" :key="item.id" class="menu-title">
          {{ $t(item.label) }}
        </li>
        <li
          v-if="
            showMenu &&
            !item.isTitle &&
            !item.isLayout &&
            (!item.permissions ||
              (user_permissions.user_permissions.length > 0 &&
                user_permissions.user_permissions.some((t) => item.permissions.includes(t.name))))
          "
          :key="item.id"
        >
          <a
            v-if="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent"
            :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
          >
            <i v-if="item.icon" :class="`bx ${item.icon}`"></i>
            <span>{{ $t(item.label) }}</span>
            <span
              v-if="item.badge"
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              >{{ $t(item.badge.text) }}</span
            >
          </a>

          <router-link v-if="!hasItems(item)" :to="item.link" class="side-nav-link-ref">
            <i v-if="item.icon" :class="`bx ${item.icon}`"></i>
            <span>{{ $t(item.label) }}</span>
            <span
              v-if="item.badge"
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              >{{ $t(item.badge.text) }}</span
            >
          </router-link>

          <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li v-for="(subitem, index) of item.subItems" :key="index">
              <router-link
                v-if="
                  !hasItems(subitem) &&
                  (!subitem.permissions ||
                    (user_permissions.user_permissions.length > 0 &&
                      user_permissions.user_permissions.some((t) =>
                        subitem.permissions.includes(t.name)
                      )))
                "
                :to="subitem.link"
                class="side-nav-link-ref"
              >
                {{ $t(subitem.label) }}
              </router-link>
              <a
                v-if="hasItems(subitem)"
                class="side-nav-link-a-ref has-arrow"
                href="javascript:void(0);"
                >{{ $t(subitem.label) }}</a
              >
              <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li v-for="(subSubitem, subSubitemKey) of subitem.subItems" :key="subSubitemKey">
                  <router-link :to="subSubitem.link" class="side-nav-link-ref">{{
                    $t(subSubitem.label)
                  }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </div>
  <!-- Sidebar -->
</template>

<script>
import MetisMenu from 'metismenujs/dist/metismenujs'
import { GET_PERMISSION, GET_USER } from '@/router/queries'
import { menuItems } from './menu'
import { mapState } from 'vuex'

/**
 * Sidenav component
 */
export default {
  data() {
    return {
      menuItems: menuItems,
      menuData: null,
      showMenu: false,
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  mounted: function () {
    //
  },
  apollo: {
    user: {
      fetchPolicy: 'no-cache',
      query: GET_USER,
      variables() {
        return {
          id: this.auth.user.id,
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
      update(data) {
        this.showMenu = true
        this.initMenu()
        return data
      },
    },
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false
    },

    toggleMenu(event) {
      event.currentTarget.nextElementSibling.classList.toggle('mm-show')
    },

    initMenu() {
      setTimeout(function () {
        // eslint-disable-next-line no-unused-vars
        var menuRef = new MetisMenu('#side-menu')
        var links = document.getElementsByClassName('side-nav-link-ref')
        var matchingMenuItem = null
        const paths = []

        for (var i = 0; i < links.length; i++) {
          paths.push(links[i]['pathname'])
        }
        var itemIndex = paths.indexOf(window.location.pathname)
        if (itemIndex === -1) {
          const strIndex = window.location.pathname.lastIndexOf('/')
          const item = window.location.pathname.substr(0, strIndex).toString()
          matchingMenuItem = links[paths.indexOf(item)]
        } else {
          matchingMenuItem = links[itemIndex]
        }

        if (matchingMenuItem) {
          matchingMenuItem.classList.add('active')
          var parent = matchingMenuItem.parentElement

          if (parent) {
            const parent2 = parent.parentElement.closest('ul')
            if (parent2 && parent2.id !== 'side-menu') {
              parent2.classList.add('mm-show')

              const parent3 = parent2.parentElement
              if (parent3) {
                parent3.classList.add('mm-active')
                var childAnchor = parent3.querySelector('.has-arrow')
                var childDropdown = parent3.querySelector('.has-dropdown')
                if (childAnchor) childAnchor.classList.add('mm-active')
                if (childDropdown) childDropdown.classList.add('mm-active')

                const parent4 = parent3.parentElement
                if (parent4 && parent4.id !== 'side-menu') {
                  parent4.classList.add('mm-show')
                  const parent5 = parent4.parentElement
                  if (parent5 && parent5.id !== 'side-menu') {
                    parent5.classList.add('mm-active')
                    const childanchor = parent5.querySelector('.is-parent')
                    if (childanchor && parent5.id !== 'side-menu') {
                      childanchor.classList.add('mm-active')
                    }
                  }
                }
              }
            }
          }
        }
      }, 500)
    },
  },
}
</script>
