<template>
  <div v-if="events && events_aggregate">
    <div v-if="events" class="table-responsive mb-0">
      <b-table
        :items="events"
        :fields="fields"
        responsive="sm"
        hover="hover"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="per_page"
        :filter="filter_b"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(start)="data">
          {{ moment(data.value).format('Do MMMM YYYY HH:ss') }}
        </template>
        <template #cell(totalPrice)="row">
          <span v-if="row.item.event_status.code === 'DONE'">
            {{
              row.item.total_services_price.aggregate.sum.price !== null ||
              row.item.total_products_price.aggregate.sum.price !== null ||
              row.item.total_tips_price.aggregate.sum.price !== null
                ? formatPrice(
                    row.item.total_products_price.aggregate.sum.price +
                      row.item.bundle_payment.aggregate.sum.price +
                      row.item.total_services_price.aggregate.sum.price +
                      row.item.total_tips_price.aggregate.sum.price
                  )
                : formatPrice(0)
            }}
          </span>
          <span v-if="row.item.event_status.code !== 'DONE'"> {{ formatPrice(0) }} </span>
        </template>
        <template #cell(totalServices)="row">
          <span v-if="row.item.event_status.code === 'DONE'">
            {{
              row.item.total_services_price.aggregate.sum.price !== null
                ? formatPrice(row.item.total_services_price.aggregate.sum.price)
                : formatPrice(0)
            }}
          </span>
          <span v-if="row.item.event_status.code !== 'DONE'"> {{ formatPrice(0) }} </span>
        </template>
        <template #cell(totalTips)="row">
          <span v-if="row.item.event_status.code === 'DONE'">
            {{
              row.item.total_tips_price.aggregate.sum.price !== null
                ? formatPrice(row.item.total_tips_price.aggregate.sum.price)
                : formatPrice(0)
            }}
          </span>
          <span v-if="row.item.event_status.code !== 'DONE'"> {{ formatPrice(0) }} </span>
        </template>
        <template #cell(totalBundlePayment)="row">
          <span v-if="row.item.event_status.code === 'DONE'">
            {{
              row.item.bundle_payment.aggregate.sum.price !== null
                ? formatPrice(row.item.bundle_payment.aggregate.sum.price)
                : formatPrice(0)
            }}
          </span>
          <span v-if="row.item.event_status.code !== 'DONE'"> {{ formatPrice(0) }} </span>
        </template>
        <template #cell(totalProducts)="row">
          <span v-if="row.item.event_status.code === 'DONE'">
            {{
              row.item.total_products_price.aggregate.sum.price !== null
                ? formatPrice(row.item.total_products_price.aggregate.sum.price)
                : formatPrice(0)
            }}
          </span>
          <span v-if="row.item.event_status.code !== 'DONE'"> {{ formatPrice(0) }} </span>
        </template>
        <template #cell(totalPaid)="row">
          <span v-if="row.item.event_status.code === 'DONE'">
            {{
              row.item.paid.aggregate.sum.price !== null
                ? formatPrice(row.item.paid.aggregate.sum.price)
                : formatPrice(0)
            }}
          </span>
          <span v-if="row.item.event_status.code !== 'DONE'"> {{ formatPrice(0) }} </span>
        </template>
        <template #cell(totalDept)="row">
          <span v-if="row.item.event_status.code === 'DONE'">
            <a
              v-if="
                row.item.total_products_price.aggregate.sum.price +
                  row.item.total_services_price.aggregate.sum.price +
                  row.item.total_services_price.aggregate.sum.price -
                  (row.item.paid.aggregate.sum.price -
                    row.item.bundle_payment.aggregate.sum.price) !==
                0
              "
              href="javascript: void(0);"
              @click="
                invoiceModal[row.item.id] = !invoiceModal[row.item.id]
                $forceUpdate()
              "
            >
              {{
                row.item.total_services_price.aggregate.sum.price !== null ||
                row.item.total_products_price.aggregate.sum.price !== null ||
                row.item.total_tips_price.aggregate.sum.price !== null
                  ? formatPrice(
                      row.item.total_products_price.aggregate.sum.price +
                        row.item.total_services_price.aggregate.sum.price +
                        row.item.total_tips_price.aggregate.sum.price -
                        (row.item.paid.aggregate.sum.price -
                          row.item.bundle_payment.aggregate.sum.price)
                    )
                  : formatPrice(0)
              }}
              <i style="font-size: 15px" class="bx bx-link"></i>
            </a>
            <span
              v-if="
                row.item.total_products_price.aggregate.sum.price +
                  row.item.total_services_price.aggregate.sum.price -
                  row.item.total_tips_price.aggregate.sum.price -
                  (row.item.paid.aggregate.sum.price -
                    row.item.bundle_payment.aggregate.sum.price) ===
                0
              "
              >{{ formatPrice(0) }}</span
            >
            <b-modal
              v-model="invoiceModal[row.item.id]"
              :title="$t('menuitems.systems.list.payment')"
              hide-footer
            >
              <invoice-component
                :event-i-d="row.item.id"
                :contact-i-d="row.item.contact.id"
                :price="
                  row.item.total_products_price.aggregate.sum.price +
                  row.item.total_services_price.aggregate.sum.price +
                  row.item.total_tips_price.aggregate.sum.price -
                  (row.item.paid.aggregate.sum.price - row.item.bundle_payment.aggregate.sum.price)
                "
                @close="
                  invoiceModal = {}
                  $apollo.queries.events.refresh()
                  $emit('updated')
                "
              ></invoice-component>
            </b-modal>
          </span>
          <span v-if="row.item.event_status.code !== 'DONE'"> {{ formatPrice(0) }} </span>
        </template>
        <template #cell(actions)="row">
          <button
            type="button"
            class="btn btn-success btn-sm waves-effect waves-light"
            @change="row"
            @click="
              $router.push({
                name: 'agenda.detail',
                params: { key: row.item.id },
              })
            "
          >
            <i class="bx bx-search-alt font-size-16 align-middle me-1"></i>{{ $t('buttons.show') }}
          </button>
        </template>
      </b-table>
      <b-alert :show="events_aggregate.aggregate.count === 0" class="text-center" variant="warning">
        <p class="mb-0"></p>
        {{ $t('tableInfo.noAppointment') }}
      </b-alert>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="page"
              :total-rows="events_aggregate.aggregate.count"
              :per-page="per_page"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import invoiceComponent from './invoiceAppointment'
import { GET_EVENTS_AGGREGATE, GET_EVENTS_CONTACT_DETAIL } from '@/router/queries'
import moment from 'moment'

export default {
  name: 'Appoint',
  components: {
    invoiceComponent,
  },
  props: ['contact'],
  data() {
    return {
      moment,
      currentPage: 1,
      filterOn: [],
      sortBy: 'value',
      sortDesc: false,
      sort_by: { created_at: 'desc' },
      filter_b: null,
      totalRows: 1,
      per_page: 5,
      page: 1,
      invoiceModal: {},
      fields: [
        {
          key: 'title',
          label: this.$t('customerCard.tables.appointment'),
          sortable: true,
        },
        {
          key: 'event_status.name',
          sortable: true,
          label: this.$t('customerCard.tables.status'),
        },
        {
          key: 'start',
          sortable: true,
          label: this.$t('customerCard.tables.date'),
        },
        {
          key: 'totalPrice',
          sortable: true,
          label: this.$t('customerCard.tables.total'),
        },
        {
          key: 'totalPaid',
          sortable: true,
          label: this.$t('customerCard.tables.collection'),
        },
        {
          key: 'totalDept',
          sortable: true,
          label: this.$t('customerCard.tables.balance'),
        },
        {
          key: 'totalServices',
          sortable: true,
          label: this.$t('customerCard.tables.services'),
        },
        {
          key: 'totalTips',
          sortable: true,
          label: this.$t('customerCard.tables.tips'),
        },
        {
          key: 'totalProducts',
          sortable: true,
          label: this.$t('customerCard.tables.products'),
        },
        {
          key: 'totalBundlePayment',
          sortable: true,
          label: this.$t('customerCard.tables.package'),
        },
        {
          key: 'actions',
          sortable: true,
          label: this.$t('tables.actions'),
        },
      ],
    }
  },
  computed: {
    ...mapState(['auth']),
    rows() {
      return this.events.length
    },
    offset() {
      return (this.page - 1) * this.per_page
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  apollo: {
    events_aggregate: {
      fetchPolicy: 'no-cache',
      query: GET_EVENTS_AGGREGATE,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            contact_id: {
              _eq: this.$route.params.id,
            },
          },
        }
      },
    },
    events: {
      fetchPolicy: 'no-cache',
      query: GET_EVENTS_CONTACT_DETAIL,
      variables() {
        return {
          limit: this.per_page,
          offset: this.offset,
          order_by: this.sort_by,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            contact_id: {
              _eq: this.$route.params.id,
            },
          },
        }
      },
    },
  },
}
</script>
