<template>
  <div v-if="services">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto">
            <b-input
              id="inlineFormInputName2"
              v-model="search_input"
              :placeholder="$t('fields.search')"
              @input="$apollo.queries.services.stop()"
            ></b-input>
          </div>
          <div class="col-sm-auto">
            <b-button variant="success" @click="filter">
              {{ $t('buttons.placeHolderSearch') }}</b-button
            >
          </div>
          <div class="col-sm-auto">
            <b-button variant="danger" @click="clear"> {{ $t('buttons.reset') }}</b-button>
          </div>
        </b-form>

        <div class="col-3 d-flex">
          <button class="btn btn-warning ms-auto mb-2" @click="categoriesModal = true">
            {{ $t('buttons.categories') }}
          </button>
          <button class="btn btn-success ms-auto mb-2" @click="servicesModal = true">
            {{ $t('buttons.add_service') }}
          </button>
        </div>
      </div>
    </div>

    <add-service
      :add-service-modal="servicesModal"
      :update-item-data="updateItem"
      :categories="categories_services"
      :user-groups="user_groups"
      @close="
        servicesModal = false
        updateItem = {}
      "
      @submitted="$apollo.queries.services.refresh()"
    />
    <categories
      :show="categoriesModal"
      mode="services"
      @close="categoriesModal = false"
      @submitted="
        $apollo.queries.categories_services.refresh()
        $apollo.queries.services.refresh()
      "
    />

    <div class="col-md-12 col-xs-12">
      <div class="card">
        <div class="card-body">
          <div class="overflow-auto">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>{{ $t('service.service_name') }}</th>
                  <th>{{ $t('tables.created_at') }}</th>
                  <th>{{ $t('tables.price') }}</th>
                  <th>{{ $t('service.duration') }}</th>
                  <th>{{ $t('tables.locations') }}</th>
                  <th>{{ $t('staff.staff_groups') }}</th>
                  <th>{{ $t('tables.categories') }}</th>
                  <th>{{ $t('service.form') }}</th>
                  <th>{{ $t('tables.action') }}</th>
                </tr>
              </thead>
              <draggable
                v-model="services"
                tag="tbody"
                @end="onDragEnd"
                @start="onDragStart"
                @change="sortChanged"
              >
                <tr v-for="(serviceItem, key) in services" :key="key">
                  <td>{{ serviceItem.name }}</td>
                  <td>
                    {{ moment(serviceItem.created_at).format('Do MMMM YYYY') }}
                  </td>
                  <td>{{ formatPrice(serviceItem.price) }}</td>
                  <td>{{ serviceItem.duration }} MIN</td>
                  <td>
                    <p
                      v-for="(location, locationServicesKey) in serviceItem.location_services"
                      :key="locationServicesKey"
                      class="mb-0"
                    >
                      {{ location.location.name }}
                    </p>
                  </td>
                  <td>
                    <p
                      v-for="service_group in serviceItem.service_groups"
                      :key="service_group.id"
                      class="mb-0"
                    >
                      {{ service_group.group.name }}
                    </p>
                  </td>
                  <td>
                    <p
                      v-for="(category, categoryServicesKey) in serviceItem.service_categories"
                      :key="categoryServicesKey"
                      class="mb-0"
                    >
                      {{ category.categories.name }}
                    </p>
                  </td>
                  <td>
                    {{
                      serviceItem.form_id !== null && serviceItem.form
                        ? serviceItem.form.name
                        : $t('form.form_not_found')
                    }}
                  </td>
                  <td>
                    <div class="button-items">
                      <button
                        type="button"
                        class="btn btn-warning btn-sm"
                        @click="setUpdateModal(serviceItem)"
                      >
                        <i class="bx bx-edit font-size-16 align-middle me-1"></i
                        >{{ $t('buttons.edit') }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        style="margin-left: 5px"
                        @click="remove(serviceItem)"
                      >
                        <i class="bx bx-trash font-size-16 align-middle me-1"></i
                        >{{ $t('buttons.delete') }}
                      </button>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
            <b-alert :show="services.length === 0" variant="warning" class="text-center mt-3">
              {{ $t('service.no_service_added') }}
            </b-alert>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-3">
      <p>
        {{ $t('customerForm.total') }} <b>{{ services.length }}</b>
      </p>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import { mapState } from 'vuex'
import {
  GET_SERVICES,
  UPDATE_SERVICE,
  GET_CATEGORIES_SERVICES,
  GET_USER_GROUPS,
} from '@/router/queries'
import moment from 'moment'
import AddService from '@/components/widgets/addService.vue'
import Categories from '@/components/widgets/categories.vue'
import Draggable from 'vuedraggable'

const setDragCursor = (value) => {
  const html = document.getElementsByTagName('html').item(0)
  html.classList.toggle('grabbing', value)
}

export default {
  name: 'Services',
  page: {
    title: 'services',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    AddService,
    Categories,
    Draggable,
  },
  data() {
    return {
      title: this.$t('service.services'),
      servicesModal: false,
      submitted: false,
      items: [
        {
          text: this.$t('service.services'),
          href: '/',
        },
        {
          text: this.$t('service.search_services'),
          active: true,
        },
      ],
      sort_by: { order: 'asc' },
      search_input: '',
      updateModal: {},
      locationModal: {},
      locationServices: [],
      moment,
      addStaffModal: false,
      categoriesModal: false,
      updateItem: {},
    }
  },
  computed: {
    ...mapState(['auth']),
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
    totalCount() {
      return this.services.length
    },
  },
  created() {
    this.$_loading()
  },
  methods: {
    setUpdateModal(service) {
      console.log('service :>> ', service)
      this.servicesModal = true
      this.updateItem = { ...service, price: service.price.toFixed(2) }
    },
    remove(service) {
      const self = this
      this.$_C({
        confirmed(result) {
          if (result) {
            self.$loading(true)
            self.$apollo.mutate({
              mutation: UPDATE_SERVICE,
              variables: {
                service: {
                  is_disabled: true,
                },
                id: service.id,
              },
              update: () => {
                try {
                  self.$_N({
                    type: 'success',
                    position: 'center',
                  })
                  self.$apollo.queries.services.refresh()
                  self.$loading(false)
                } catch (e) {
                  self.$_N({
                    type: 'error',
                    position: 'center',
                  })
                  self.$loading(false)
                }
              },
            })
          }
        },
      })
    },
    filter() {
      this.$apollo.queries.services.start()
      this.$_loading()
    },
    clear() {
      this.sort_by = { order: 'asc' }
      this.search_input = ''
      this.$apollo.queries.services.refresh()
      this.$_loading()
    },
    sortChanged() {
      const self = this
      this.$loading(true)
      this.services.forEach(function (service, index) {
        self.$apollo
          .mutate({
            mutation: UPDATE_SERVICE,
            variables: {
              service: {
                order: index,
              },
              id: service.id,
            },
          })
          .then(() => {
            return true
          })
          .catch(() => {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            return false
          })
      })
      this.$_N({
        type: 'success',
        position: 'center',
      })
      this.$apollo.queries.services.refresh()
      this.$loading(false)
    },
    onDragStart() {
      setDragCursor(true)
    },
    onDragEnd() {
      setDragCursor(false)
    },
  },
  apollo: {
    services: {
      fetchPolicy: 'no-cache',
      query: GET_SERVICES,
      variables() {
        return {
          order_by: this.sort_by,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.auth.user.id,
            },
            is_disabled: {
              _eq: false,
            },
            _or: [
              {
                name: this.search,
              },
            ],
          },
        }
      },
    },
    categories_services: {
      fetchPolicy: 'no-cache',
      query: GET_CATEGORIES_SERVICES,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
          },
        }
      },
    },
    user_groups: {
      fetchPolicy: 'no-cache',
      query: GET_USER_GROUPS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
          },
        }
      },
    },
  },
}
</script>
<style>
.grabbing * {
  cursor: grabbing;
}
</style>
