<template>
  <div v-if="marketing && tags && outgoings">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-4">
        <div class="card" style="height: 100%">
          <div class="card-body">
            <div class="media">
              <div class="media-body">
                <div class="text-muted">
                  <h5>{{ marketing.name }}</h5>
                  <p class="mb-1">
                    <span class="text-dark">{{ $t('marketing.sms.sender') }}:</span>
                    Barespace
                  </p>
                  <p class="mb-1">
                    <span class="text-dark"
                      >{{ $t('marketing.sms.shipping_time') }}:
                      {{ moment(marketing.schedule).format('LLL') }}</span
                    >
                  </p>
                  <p class="mb-1">
                    <span class="text-dark"
                      >{{ $t('tables.created_at') }}:
                      {{ moment(marketing.created_at).format('Do MMMM YYYY HH:ss') }}</span
                    >
                  </p>
                  <p class="mb-0">
                    <span class="text-dark">{{ $t('tables.status') }}:</span>
                    {{
                      marketing.status === 'DONE'
                        ? $t('marketing.sms.complete')
                        : $t('marketing.sms.not_complete')
                    }}
                  </p>
                  <div class="col-auto">
                    <b-button class="mt-3" variant="success btn-sm" @click="detailModal = true"
                      ><i class="bx bx-search-alt font-s ize-16 align-middle me-1"></i>
                      {{ $t('form.view_details') }}
                    </b-button>
                    <b-modal
                      v-model="detailModal"
                      :title="$t('marketing.sms_marketing')"
                      hide-footer
                      size="xl"
                    >
                      <form @submit.prevent="update">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="row">
                              <div class="col-12">
                                <div class="mb-3">
                                  <label for="name">{{ $t('marketing.marketing_name') }}</label>
                                  <input
                                    id="name"
                                    v-model="marketing.name"
                                    :disabled="marketing.status === 'DONE'"
                                    type="text"
                                    class="form-control"
                                  />
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="mb-3">
                                  <label for="schedule">{{ $t('marketing.date') }}</label>
                                  <date-picker
                                    v-model="marketing.schedule"
                                    :disabled="marketing.status === 'DONE'"
                                    format="DD/MM/YYYY HH:mm"
                                    value-type="YYYY-MM-DDTHH:mm"
                                    type="datetime"
                                  ></date-picker>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="mb-3">
                                  <label>{{ $t('marketing.tags') }}</label>
                                  <multiselect
                                    v-model="marketing.filter.tags"
                                    :disabled="marketing.status === 'DONE'"
                                    tag-placeholder="Add this as new tag"
                                    :placeholder="$t('marketing.sms.tag_to_send')"
                                    label="name"
                                    track-by="id"
                                    :options="
                                      tags.map((x) => {
                                        return { name: x.name, id: x.id }
                                      })
                                    "
                                    :multiple="true"
                                    :taggable="true"
                                  ></multiselect>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-8">
                            <div class="row">
                              <div class="col-12">
                                <div class="mb-3">
                                  <label for="content">{{ $t('marketing.contents') }}</label>
                                  <textarea
                                    id="content"
                                    v-model="marketing.content"
                                    cols="30"
                                    rows="10"
                                    class="form-control"
                                    :disabled="marketing.status === 'DONE'"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="text-end mt-3">
                          <b-button variant="light" @click="detailModal = false">{{
                            $t('buttons.close')
                          }}</b-button>
                          <b-button
                            :disabled="marketing.status === 'DONE'"
                            type="submit"
                            variant="success"
                            class="ms-1"
                            @click="update"
                            >{{ $t('buttons.update') }}</b-button
                          >
                        </div>
                      </form>
                    </b-modal>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body border-top">
            <p class="text-muted mb-4">{{ $t('marketing.sms.sms_stats') }}</p>
            <div class="text-center">
              <div class="row">
                <div class="col-sm-4">
                  <div>
                    <div class="font-size-24 text-primary mb-2">
                      <i class="bx bx-send" style="color: #018c5c"></i>
                    </div>

                    <p class="text-muted mb-2">{{ $t('marketing.sms.successful') }}</p>
                    <h5>
                      {{
                        outgoings.length > 0
                          ? outgoings.filter((x) => x.status === 'DONE').length
                          : 0
                      }}
                    </h5>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mt-4 mt-sm-0">
                    <div class="font-size-24 text-primary mb-2">
                      <i class="bx bx-error-circle" style="color: #ff0000"></i>
                    </div>

                    <p class="text-muted mb-2">{{ $t('marketing.sms.unsuccessful') }}</p>
                    <h5>
                      {{
                        outgoings.length > 0
                          ? outgoings.filter((x) => x.status === 'FAIL').length
                          : 0
                      }}
                    </h5>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mt-4 mt-sm-0">
                    <div class="font-size-24 text-primary mb-2">
                      <i class="bx bx-menu"></i>
                    </div>

                    <p class="text-muted mb-2">{{ $t('case.total') }}</p>
                    <h5>{{ outgoings.length > 0 ? outgoings.length : 0 }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-8">
        <div class="card" style="height: 100%">
          <div class="card-body">
            <h4 class="card-title mb-3">{{ $t('marketing.sms.chart') }}</h4>

            <div>
              <apexchart
                class="apex-charts"
                height="320"
                type="pie"
                dir="ltr"
                :series="series"
                :options="chartOptions"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('marketing.sms.sent_sms') }}</h4>
            <b-tabs content-class="mt-3" nav-class="nav-tabs-custom">
              <b-tab :title="$t('case.all')" active>
                <div v-if="outgoings.length" class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center fw-normal">
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          class="form-control form-select form-select-sm"
                        ></b-form-select
                        >&nbsp;{{ $t('case.total') }}
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('fields.search') }}:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ms-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div v-if="outgoings.length" class="table-responsive table-hover mb-0">
                  <b-table
                    :items="outgoings"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template #cell(created_at)="data">
                      {{ moment(data.value).format('LLL') }}
                    </template>
                    <template #cell(name)="row">
                      {{
                        row.item.contact_id !== null
                          ? row.item.contact.first_name + ' ' + row.item.contact.last_name
                          : 'N/A'
                      }}
                    </template>
                    <template #cell(phone)="row">
                      {{
                        row.item.contact_id !== null
                          ? row.item.contact.phone_code + '' + row.item.contact.phone
                          : 'N/A'
                      }}
                    </template>
                    <template #cell(status)="row">
                      {{
                        row.item.status === 'DONE'
                          ? $t('marketing.sms.completed')
                          : $t('marketing.sms.pending')
                      }}
                    </template>
                  </b-table>
                </div>
                <div v-if="outgoings.length" class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
                <b-alert v-if="!outgoings.length" show variant="warning"
                  >{{ $t('marketing.sms.sent_sms_not_found') }}!</b-alert
                >
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import { GET_MARKETING_SINGLE, GET_TAGS, GET_OUTGOINGS, UPDATE_MARKETING } from '@/router/queries'
import moment from 'moment'
import { activitiesData, pieChart } from './data'
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import DatePicker from 'vue2-datepicker'

export default {
  page: {
    title: 'sms_marketing',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      activitiesData: activitiesData,
      chartOptions: {
        labels: [this.$t('marketing.sms.successful'), this.$t('marketing.sms.unsuccessful')],
        colors: ['#018c5c', '#FF0000'],
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
          verticalAlign: 'middle',
          floating: false,
          fontSize: '14px',
          offsetX: 0,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
      moment,
      detailModal: false,
      pieChart: pieChart,
      title: this.$t('marketing.sms_marketing'),
      editor: ClassicEditor,
      items: [
        {
          text: this.$t('marketing.sms_marketing'),
          href: '/',
        },
        {
          text: 'SMS',
          active: true,
        },
      ],
      totalRows: 1,
      sort_by: { created_at: 'desc' },
      currentPage: 1,
      search_input: '',
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      page: 1,
      per_page: 10,
      filterOn: [],
      sortBy: 'id',
      sortDesc: false,
      fields: [
        {
          key: 'name',
          label: this.$t('customerForm.name'),
          sortable: true,
        },
        {
          key: 'phone',
          label: this.$t('customerForm.phone'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('tables.status'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('marketing.sms.sent_date'),
          sortable: true,
        },
      ],
    }
  },
  computed: {
    ...mapState(['auth']),
    rows() {
      return this.activitiesData.length
    },
    offset() {
      return (this.page - 1) * this.per_page
    },
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
    series() {
      if (this.outgoings.length !== 0) {
        const success = this.outgoings.filter((x) => x.status === 'DONE').length
        const fail = this.outgoings.filter((x) => x.status === 'FAIL').length
        return [success, fail]
      }
      return [0]
    },
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    update() {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_MARKETING,
        variables: {
          marketing: this.$_ER(this.marketing, ['__typename', 'contact']),
          id: this.marketing.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$loading(false)
          }
        },
      })
      this.detailModal = false
    },
  },
  apollo: {
    marketing: {
      fetchPolicy: 'no-cache',
      query: GET_MARKETING_SINGLE,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
    },
    tags: {
      fetchPolicy: 'no-cache',
      query: GET_TAGS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
    outgoings: {
      fetchPolicy: 'no-cache',
      query: GET_OUTGOINGS,
      variables() {
        return {
          order_by: this.sort_by,
          offset: this.offset,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.auth.user.id,
            },
            marketing_id: {
              _eq: this.$route.params.id,
            },
            type: {
              _eq: 'SMS',
            },
          },
        }
      },
    },
  },
}
</script>
