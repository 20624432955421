<template>
  <div>
    <b-modal v-model="noteModal" hide-footer :title="$t('buttons.addNote')">
      <form @submit.prevent="handleSubmit">
        <b-form-group class="mb-3">
          <textarea v-model="note.content" required="" class="form-control" rows="5"></textarea>
        </b-form-group>
        <div class="form-group row justify-content-end">
          <div class="col-sm-auto">
            <button type="submit" class="btn btn-primary w-md">{{ $t('buttons.save') }}</button>
          </div>
        </div>
      </form>
    </b-modal>
    <div>
      <div>
        <div class="row">
          <div class="col-md-8">
            <h4 class="card-title mb-2">{{ $t('customerCard.notes') }}</h4>
          </div>
          <div class="col-md-4 text-end">
            <b-button variant="success btn-sm" @click="noteModal = true"
              ><i class="bx bx-notepad font-size-16 align-middle me-1"></i
              >{{ $t('buttons.addNote') }}</b-button
            >
          </div>
        </div>
      </div>
      <hr class="mt-2" />

      <b-list-group>
        <b-list-group-item
          v-for="(noteItem, key) in notes"
          :key="key"
          class="flex-column align-items-start"
        >
          <p class="mb-1">
            {{ noteItem.content }}
          </p>
          <div class="d-flex w-100 justify-content-between">
            <b-dropdown
              toggle-class="font-size-16 text-muted p-0"
              menu-class="dropdown-menu-end"
              variant="white"
              left
            >
              <template #button-content>
                <i class="mdi mdi-dots-horizontal"></i>
              </template>

              <b-dropdown-item
                href="#"
                @click="
                  updateModal[key] = !updateModal[key]
                  $forceUpdate()
                "
              >
                {{ $t('buttons.edit') }}
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="remove(noteItem)">
                {{ $t('buttons.delete') }}
              </b-dropdown-item>
            </b-dropdown>
            <small>{{ moment(noteItem.created_at).format('Do MMMM YYYY HH:s') }}</small>
          </div>

          <b-modal v-model="updateModal[key]" :title="$t('customerCard.edit_note')" hide-footer>
            <form @submit.prevent="update(noteItem)">
              <div>
                <b-form-group
                  class="mb-3"
                  :label="$t('customerCard.note')"
                  label-for="formrow-email-input"
                >
                  <b-form-textarea v-model="noteItem.content" rows="8"></b-form-textarea>
                </b-form-group>
              </div>
              <div class="text-end mt-3">
                <b-button variant="light" @click="updateModal = {}">
                  {{ $t('buttons.close') }}
                </b-button>
                <b-button type="submit" variant="success" class="ms-1">
                  {{ $t('buttons.save') }}
                </b-button>
              </div>
            </form>
          </b-modal>
        </b-list-group-item>
      </b-list-group>

      <b-alert v-if="!notes.length" show variant="warning">
        {{ $t('customerCard.no_note_records') }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import { INSERT_NOTES, UPDATE_NOTE, DELETE_NOTE } from '@/router/queries'
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
export default {
  name: 'NOTE',
  props: ['customer', 'remarks'],
  data() {
    return {
      title: 'NOTE',
      noteModal: false,
      updateModal: {},
      note: {
        content: '',
        team_id: this.customer.team_id,
        contact_id: this.$route.params.id,
        user_id: this.customer.user_id,
      },
      moment,
    }
  },
  validations: {
    note: {
      content: { required },
    },
  },
  computed: {
    ...mapState(['auth']),
    notes() {
      return this.remarks
    },
  },
  created() {
    this.$_loading()
  },
  methods: {
    handleSubmit() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$loading(false)
        return
      } else {
        this.$apollo.mutate({
          mutation: INSERT_NOTES,
          variables: {
            note: this.note,
          },
          update: () => {
            try {
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$loading(false)
              this.$emit('updated')
            } catch (e) {
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          },
        })
        this.noteModal = false
      }
      this.submitted = false
    },
    update(note) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_NOTE,
        variables: {
          id: note.id,
          note: this.$_ER(note, ['__typename']),
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
            this.$emit('updated')
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
      this.updateModal = {}
    },
    remove(note) {
      const self = this
      this.$_C({
        confirmed(result) {
          if (result) {
            self.$loading(true)
            self.$apollo.mutate({
              mutation: DELETE_NOTE,
              variables: {
                id: note.id,
              },
              update: () => {
                try {
                  self.$_N({
                    type: 'success',
                    position: 'center',
                  })
                  self.$loading(false)
                  self.$emit('updated')
                } catch (e) {
                  self.$_N({
                    type: 'error',
                    position: 'center',
                  })
                }
              },
            })
          }
        },
      })
    },
  },
  apollo: {},
}
</script>
