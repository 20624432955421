export const state = {
  locationDetails: {},
  timezone: '',
}

export const getters = {
  locationDetails: (state) => state.locationDetails,
  timezone: (state) => state.timezone,
}

export const mutations = {
  setLocationDetails(state, data) {
    state.locationDetails = data
  },
  setTimezone(state, data) {
    state.timezone = data
  },
}
