<template>
  <div
    v-if="events && users && services"
    class="d-flex flex-column"
    style="height: calc(100vh - 168px); min-height: 30rem"
  >
    <!-- <PageHeader :title="title" :items="items" /> -->
    <b-collapse v-show="mode !== 'edit'" v-model="showFilters">
      <div class="card row flex-row mb-3 mx-0 p-3 gy-2 gx-3 align-items-center">
        <div class="col-md-2">
          <multiselect
            v-if="locations"
            v-model="locationFilter"
            :placeholder="$t('calendar.location_search')"
            :show-labels="false"
            :options="locations.map((x) => x.id)"
            :custom-label="(opt) => locations.find((x) => x.id === opt).name"
            @input="
              user_select_id = false
              $apollo.subscriptions.events.stop()
              $apollo.queries.staticEvents.stop()
              filter()
            "
          >
            <span slot="noResult">{{ $t('lists.no_record') }}</span>
            <span slot="noOptions">{{ $t('lists.no_record') }}</span>
          </multiselect>
        </div>
        <div class="col-md-2">
          <multiselect
            v-model="user_select_id"
            :placeholder="$t('calendar.staff_search')"
            :show-labels="false"
            :options="
              users
                .filter((x) => (!locationFilter ? true : x.locationIds.includes(locationFilter)))
                .map((x) => x.id)
            "
            :custom-label="
              (opt) =>
                users.find((x) => x.id === opt).first_name +
                ' ' +
                users.find((x) => x.id === opt).last_name
            "
            @input="
              $apollo.subscriptions.events.stop()
              $apollo.queries.staticEvents.stop()
            "
          >
            <span slot="noResult">{{ $t('lists.no_record') }}</span>
            <span slot="noOptions">{{ $t('lists.no_record') }}</span>
          </multiselect>
        </div>
        <div class="col-md-2">
          <multiselect
            v-model="is_online"
            :placeholder="$t('calendar.appointment_type')"
            :show-labels="false"
            :options="onlineOptions.map((x) => x.value)"
            :custom-label="(opt) => onlineOptions.find((x) => x.value === opt).label"
            @input="
              $apollo.subscriptions.events.stop()
              $apollo.queries.staticEvents.stop()
            "
          >
            <span slot="noResult">{{ $t('lists.no_record') }}</span>
            <span slot="noOptions">{{ $t('lists.no_record') }}</span>
          </multiselect>
        </div>
        <div class="col-md-2">
          <b-input
            v-model="search_input"
            :placeholder="$t('calendar.customer')"
            @input="
              $apollo.subscriptions.events.stop()
              $apollo.queries.staticEvents.stop()
            "
          ></b-input>
        </div>
        <div class="col-sm-auto">
          <b-button variant="success" @click="filter">{{ $t('buttons.search') }}</b-button>
        </div>
        <div class="col-sm-auto">
          <b-button variant="danger" @click="clear()">{{ $t('buttons.reset') }}</b-button>
        </div>
      </div>
    </b-collapse>

    <div class="row flex-grow-1">
      <div class="col-12 px-0 px-sm-2">
        <div class="card h-100">
          <div class="card-body">
            <div class="app-calendar h-100">
              <FullCalendar ref="fullCalendar" :options="calendarOptions" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-if="datePickerModal"
      v-model="datePickerModal"
      :title="$t('calendar.select_date')"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
    >
      <div>
        <VueCtkDateTimePicker
          id="event-date"
          v-model="datePicker"
          only-date
          formatted="LL"
          color="#2a3042"
          button-color="#2a3042"
          :button-now-translation="$t('buttons.now')"
          :label="$t('fields.choose')"
          @input="changeDate"
        />
      </div>
    </b-modal>

    <!-- Create Modal -->
    <add-event
      v-if="addEventModal"
      :add-event-modal="addEventModal"
      :appointment-data="eventData"
      @closeModal="handleCloseModal"
    />
  </div>
</template>

<script>
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import FullCalendar from '@fullcalendar/vue'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import Swal from 'sweetalert2'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import appConfig from '@/app.config'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

import { mapState } from 'vuex'
import {
  DELETE_EVENT,
  GET_SERVICES_AGENDA,
  UPDATE_EVENT_SERVICE,
  GET_USER_AGENDA,
  GET_LOCATIONS,
  SUBSCRIBE_TO_EVENTS,
  GET_PERMISSION,
  UPDATE_EVENT,
  UPDATE_EVENT_SERVICES,
  GET_EVENTS,
} from '../../queries'
import AddEvent from '@/components/widgets/addEvent'

export default {
  name: 'Agenda',
  page: {
    title: 'calendar',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    FullCalendar,
    Multiselect,
    AddEvent,
    VueCtkDateTimePicker,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    eventDateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      moment,
      title: this.$t('calendar.title'),
      items: [
        {
          text: this.$t('calendar.title'),
        },
        {
          text: this.$t('calendar.list'),
          active: true,
        },
      ],
      eventsByServices: [],
      datePickerModal: false,
      datePicker: null,
      calendarOptions: {
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'datePicker filters',
        },
        customButtons: {
          datePicker: {
            text: this.$t('calendar.select_date'),
            click: this.showDatePicker,
          },
          datePickerIcon: {
            icon: 'date',
            click: this.showDatePicker,
          },
          filters: {
            icon: 'filter',
            click: () => {
              this.showFilters = !this.showFilters
            },
          },
        },
        locale: 'en',
        timeZone: 'UTC',
        plugins: [interactionPlugin, bootstrapPlugin, listPlugin, resourceTimeGridPlugin],
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
        },
        titleFormat: {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
          weekday: 'long',
        },
        initialView: 'resourceTimeGridDay',
        themeSystem: 'flatly',
        slotDuration: '00:15:00',
        slotLabelInterval: '01:00',
        scrollTime: '06:00:00',
        scrollTimeReset: false,
        resourceLabelContent: this.labelContent,
        resources: [],
        events: [],
        editable: true,
        droppable: true,
        datesSet: this.datesSet,
        eventResizableFromStart: true,
        dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        eventDrop: this.handleDrop,
        weekends: true,
        selectable: true,
        selectMirror: true,
        // nowIndicator: true,
        dayMaxEvents: false,
        selectOverlap: false,
        allDaySlot: false,
        expandRows: true,
        slotMinWidth: 125,
        height: '100%',
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      },
      currentEvents: [],
      addEventModal: false,
      search_input: this.$route.query.search || '',
      editModal: false,
      eventData: null,
      submitted: false,
      showFilters: false,
      is_online: '',
      user_select_id: false,
      submit: false,
      edit: {},
      deleteId: {},
      onlineOptions: [
        {
          value: 'true',
          label: this.$t('appointment.online'),
        },
        {
          value: 'false',
          label: this.$t('appointment.in_person'),
        },
      ],
      contact_name: null,
      services_list: [],
      bundle_services: null,
      locationFilter: false,
      currentDate: null,
      events: [],
      staff_locations: undefined,
    }
  },
  computed: {
    ...mapState(['auth']),
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
    savedFilters() {
      return {
        location: localStorage.getItem('location-filter'),
      }
    },
    eventsVariables() {
      return {
        where: {
          start: {
            _gte: moment(this.currentDate || new Date()).format('YYYY-MM-DDT00:00:00Z'),
            _lte: moment(this.currentDate || new Date())
              .add(1, 'days')
              .format('YYYY-MM-DDT00:00:00Z'),
          },
          team_id: {
            _eq: this.auth.user.team_id,
          },
          user_id: {
            _eq: this.user_select_id || undefined,
          },
          location_id: {
            _in: this.locationFilter || undefined,
          },
          online: {
            _eq: this.is_online || undefined,
          },
          event_status: {
            code: {
              _neq: 'CANCELED',
            },
          },
          _or: [
            {
              contact: {
                first_name: this.search,
              },
            },
            {
              contact: {
                last_name: this.search,
              },
            },
          ],
        },
      }
    },
  },
  watch: {
    users: function (val) {
      this.updateResources(val)
    },
    locations: function (val) {
      const locationIds = val.map((location) => location.id)
      this.locationFilter =
        this.savedFilters.location && locationIds.includes(this.savedFilters.location)
          ? this.savedFilters.location
          : locationIds[0]
      this.filter()
    },
    'eventData.start': function (val) {
      this.eventData.end = moment(val).add(this.eventData.duration, 'minutes').format()
    },
    'eventData.duration': function (val) {
      this.eventData.end = moment(this.eventData.start).add(val, 'minutes').format()
    },
    events: function (val) {
      this.eventsByServices = []
      val.forEach((event) => {
        let className = 'bg-light text-dark'
        if (event.event_status.code === 'NOT_COME') className = event.event_status.color_class
        else if (event.event_status.code === 'DONE') className = 'bg-event-light text-dark'
        else className = event.online ? 'bg-event-success text-dark' : 'bg-event-info text-dark'
        event.event_services.forEach((item) => {
          this.eventsByServices.push({
            ...event,
            id: item.id,
            event_id: event.id,
            className,
            // backgroundColor: event.user.color,
            resourceId: item.user.id,
            start: item.start,
            end: moment(item.start).add(item.duration, 'm').toDate(),
            title:
              item.service.name +
              ' - ' +
              (event.contact.first_name || '') +
              ' ' +
              (event.contact.last_name || ''),
          })
        })
      })
      this.calendarOptions.events = this.eventsByServices
      if (this.currentDate) this.datesSet(this.currentDate, true)
    },
    'eventData.user_id': function (val) {
      if (this.eventData.user_id !== null) {
        this.eventData.color = this.users.find((x) => x.id === val).color
        // this.eventData.event_type_id = this.event_types.find((x) => x.code === 'NONE').id
      }
    },
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  created() {
    this.eventData = {
      duration: 0,
      contact_id: this.$route.params.id || null,
      start: null,
      color: null,
      title: null,
      notes: null,
      event_type_id: null,
      end: null,
      event_services: {
        data: [
          {
            service_id: null,
            price: 0,
            staff_id: null,
            duration: 30,
            team_id: this.auth.user.team_id,
            user_id: this.auth.user.id,
          },
        ],
      },
      user_id: null,
      team_id: this.auth.user.team_id,
    }
  },
  mounted() {
    if (window.innerWidth > 988) this.showFilters = true
    else {
      ;(this.calendarOptions.headerToolbar = {
        left: 'prev next',
        center: 'title',
        right: 'datePickerIcon filters',
      }),
        (this.calendarOptions.titleFormat = {
          month: 'short',
          year: 'numeric',
          day: 'numeric',
          weekday: 'short',
        })
    }
    const initialDate = sessionStorage.getItem('calendar-date')
    if (initialDate) this.calendarOptions.initialDate = initialDate
  },
  methods: {
    handleDrop(info) {
      console.log(info)
      Swal.fire({
        title: this.$t('appointment.change_time_warning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: this.$t('buttons.submit'),
        cancelButtonText: this.$t('buttons.cancel'),
      }).then((result) => {
        if (result.value) {
          this.$loading(true)
          const start = moment(info.event.startStr, moment.HTML5_FMT.DATETIME_LOCAL).format(
            'YYYY-MM-DD HH:mm'
          )
          const end = moment(info.event.endStr, moment.HTML5_FMT.DATETIME_LOCAL).format(
            'YYYY-MM-DD HH:mm'
          )
          const service = {
            start,
            end,
          }
          if (info.newResource) service.staff_id = info.newResource.id
          this.$apollo.mutate({
            mutation: UPDATE_EVENT_SERVICE,
            variables: {
              id: info.event.id,
              service,
            },
            update: () => {
              try {
                this.$_N({
                  type: 'success',
                  position: 'center',
                })
                this.$apollo.subscriptions.events.refresh()
                this.$apollo.queries.staticEvents.refresh()
                this.$loading(false)
              } catch (e) {
                this.$_N({
                  type: 'error',
                  position: 'center',
                })
              }
            },
          })
        } else {
          info.revert()
          Swal.fire(this.$t('appointment.cancelled'))
        }
      })
    },
    deleteEvent(event) {
      const self = this
      this.$_C({
        confirmed(result) {
          if (result) {
            self.$loading(true)
            self.$apollo.mutate({
              mutation: DELETE_EVENT,
              variables: {
                id: event,
              },
              update: () => {
                try {
                  self.$_N({
                    type: 'success',
                    position: 'center',
                  })
                  self.$loading(false)
                  self.$apollo.subscriptions.events.refresh()
                  this.$apollo.queries.staticEvents.refresh()
                } catch (e) {
                  self.$_N({
                    type: 'error',
                    position: 'center',
                  })
                }
              },
            })
            self.editModal = false
          }
        },
      })
    },
    dateClicked(e) {
      if (!e.dateStr) return false
      if (this.mode === 'edit') {
        this.editEventDate(e)
        return
      }
      const userLocation = this.users.find((user) => user.id === e.resource.id).locationIds[0]
      this.eventData = {
        duration: 30,
        contact_id: null,
        start: moment(e.dateStr, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm'),
        color: null,
        title: null,
        notes: null,
        event_type_id: null,
        end: null,
        event_services: {
          data: [
            {
              service_id: null,
              price: null,
              staff_id: e.resource.id,
              duration: null,
              team_id: this.auth.user.team_id,
              user_id: this.auth.user.id,
              time: moment(e.dateStr, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm'),
            },
          ],
        },
        user_id: e.resource.id,
        team_id: this.auth.user.team_id,
        location_id: this.locationFilter ? this.locationFilter : userLocation,
      }
      this.addEventModal = true
    },
    editEvent(info) {
      if (this.mode === 'edit') return
      this.edit = info.event
      // this.eventData.title = this.edit.title
      // this.eventData.start = this.edit.start
      // this.eventData.end = this.edit.end
      // this.eventData.notes = this.edit.extendedProps.notes
      // this.eventData.contact_id = this.edit.extendedProps.contact_id
      // this.eventData.online = info.event.extendedProps.online
      // this.eventData.user_id = info.event.extendedProps.user_id
      this.contact_name =
        (info.event.extendedProps.contact.first_name || '') +
        ' ' +
        (info.event.extendedProps.contact.last_name || '')
      this.services_list = info.event.extendedProps.event_services
      this.$router.push({
        name: 'agenda.detail',
        params: { key: this.edit.extendedProps.event_id },
      })
    },
    editEventDate(e) {
      const event = this.eventDateData
      event.start = moment(e.dateStr, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm')
      event.end = moment(event.start).add(event.duration, 'minutes').format('YYYY-MM-DD HH:mm')
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            id: event.id,
            event: this.$_ER(event, [
              '__typename',
              'contact',
              'event_type',
              'event_status',
              'event_services',
              'location',
              'user',
              'tips',
            ]),
          },
        })
        .then(() => {
          this.updateServices(event)
        })
        .catch((e) => {
          console.log('errrrr :>> ', e)
          this.$_N({
            type: 'error',
            position: 'center',
          })
          this.$loading(false)
        })
    },
    updateServices(event) {
      const services = [...event.event_services]
      services.forEach((service, index) => {
        if (index === 0) {
          service.start = event.start
        } else {
          service.start = services[index - 1].end
        }
        service.event_id = event.id
        service.contact_id = event.contact_id
        service.end = moment(service.start, 'YYYY-MM-DD HH:mm')
          .add(service.duration, 'minutes')
          .format('YYYY-MM-DD HH:mm')
        delete service.time
        delete service['service']
        delete service['__typename']
      })
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT_SERVICES,
          variables: {
            where: {
              event_id: {
                _eq: event.id,
              },
            },
            objects: services,
          },
        })
        .then((e) => {
          console.log('e :>> ', e)
          this.$loading(false)
          this.$_N({
            type: 'success',
            position: 'center',
          })
          this.$apollo.subscriptions.events.refresh()
          this.$apollo.queries.staticEvents.refresh()
        })
        .catch((e) => {
          console.log(e)
          this.$_N({
            type: 'error',
            position: 'center',
          })
          this.$loading(false)
        })
      this.updateModal = false
    },
    handleEvents(events) {
      this.currentEvents = events
    },
    clear() {
      this.user_select_id = false
      //this.locationFilter = false
      this.updateResources(this.users)
      this.search_input = ''
      this.is_online = ''
      localStorage.removeItem('location-filter')
      this.$apollo.subscriptions.events.refresh()
      this.$apollo.queries.staticEvents.refresh()
      this.$_loading()
    },
    filter() {
      localStorage.setItem('location-filter', this.locationFilter)
      this.$apollo.subscriptions.events.start()
      this.$apollo.queries.staticEvents.start()
      this.$_loading()
      this.currentDate = null
      this.updateResources(this.users)
    },
    handleCloseModal() {
      this.addEventModal = false
      this.$apollo.subscriptions.events.refresh()
      this.$apollo.queries.staticEvents.refresh()
    },
    updateResources(val, date = new Date()) {
      if (!val) return false
      this.calendarOptions.resources = []
      val.forEach((user) => {
        if (this.user_select_id && this.user_select_id !== user.id) return false
        if (this.locationFilter && !user.locationIds.includes(this.locationFilter)) return false
        this.calendarOptions.resources.push({
          id: user.id,
          title: user.first_name + ' ' + user.last_name,
          businessHours: this.setWorkingHours(user, date),
          image: user.image,
        })
      })
    },
    setWorkingHours(user, date = new Date()) {
      const dateObject = moment(date, moment.HTML5_FMT.DATETIME_LOCAL)
      const businessHours = []
      user.working_hours
        .filter((wh) => wh.location_id === this.locationFilter)
        .forEach((wh) => {
          if (dateObject.isBefore(wh.date_start) || dateObject.isAfter(wh.date_end)) return false
          businessHours.push({
            daysOfWeek: [wh.day_of_week == 7 ? 0 : parseInt(wh.day_of_week)],
            startTime: wh.shift_start_time,
            endTime: wh.shift_end_time,
          })
          if (wh.second_shift_start_time && wh.second_shift_end_time) {
            businessHours.push({
              daysOfWeek: [wh.day_of_week == 7 ? 0 : parseInt(wh.day_of_week)],
              startTime: wh.second_shift_start_time,
              endTime: wh.second_shift_end_time,
            })
          }
        })
      return businessHours
    },
    scrollT(date) {
      const cApi = this.$refs.fullCalendar.getApi()
      const now = new Date()
      const currentHour = now.getHours()
      if (this.locationFilter) {
        const location = this.locations.find(({ id }) => this.locationFilter === id)
        const WH = location.location_working_hours.find(({ day }) => date.getDay() === day)
        if (!WH.work_start || !WH.work_end) return cApi.scrollToTime(`${currentHour}:00`)
        const startHour = parseInt(WH.work_start.substring(0, 2))
        const endHour = parseInt(WH.work_end.substring(0, 2))
        if (currentHour >= startHour && currentHour < endHour) {
          return cApi.scrollToTime(`${currentHour}:00`)
        }
        return cApi.scrollToTime(WH.work_start)
      }
      cApi.scrollToTime(`${currentHour}:00`)
    },
    datesSet(info, force = false) {
      if (!info.startStr || (!force && info.startStr === this.currentDate)) return false
      // setTimeout(() => {
      //   if (info.start) this.scrollT(info.start)
      // }, 300)
      const dayOfWeek = moment(info.startStr).format('E')
      this.currentDate = info.startStr
      sessionStorage.setItem('calendar-date', this.currentDate)
      this.updateResources(
        this.users.filter(
          (user) =>
            this.eventsByServices.map((x) => x.resourceId).includes(user.id) ||
            user.working_hours.find(
              (wh) =>
                wh.location_id === this.locationFilter &&
                wh.day_of_week === dayOfWeek &&
                moment(this.currentDate).isBetween(wh.date_start, wh.date_end, 'day', '[]')
            )
        ),
        info.startStr
      )
    },
    labelContent({ resource }) {
      const imageStr = resource.extendedProps.image
        ? `<img
        src="${resource.extendedProps.image.thumb}"
        alt="image"
        class="avatar-xs rounded-circle img-fluid mt-2"
      ></img>`
        : ''
      const titleStr = `<p class="m-1">${resource.title}</p>`
      return { html: imageStr + titleStr }
    },
    showDatePicker() {
      this.datePicker = this.currentDate
      this.datePickerModal = true
    },
    changeDate() {
      const cApi = this.$refs.fullCalendar.getApi()
      cApi.gotoDate(this.datePicker.substring(0, 10))
      this.datePickerModal = false
    },
  },
  apollo: {
    $subscribe: {
      events: {
        query: SUBSCRIBE_TO_EVENTS,
        variables() {
          return this.eventsVariables
        },
        result({ data }) {
          this.events = data.events
        },
      },
    },
    staticEvents: {
      fetchPolicy: 'no-cache',
      query: GET_EVENTS,
      variables() {
        return this.eventsVariables
      },
      update(results) {
        return results.events
      },
      result({ data }) {
        this.events = data.events
      },
    },
    users: {
      fetchPolicy: 'no-cache',
      query: GET_USER_AGENDA,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            role: {
              _eq: 'user',
            },
            status: {
              _eq: 'ACTIVE',
            },
          },
        }
      },
      update(results) {
        results.users.forEach((user) => {
          user.locationIds = user.location_staffs.map((item) => item.location_id)
        })
        return results.users
      },
    },
    services: {
      fetchPolicy: 'no-cache',
      query: GET_SERVICES_AGENDA,
      variables() {
        return {
          where: {
            is_disabled: {
              _eq: false,
            },
            team_id: {
              _eq: this.auth.user.team_id,
            },
          },
        }
      },
    },
    locations: {
      fetchPolicy: 'no-cache',
      query: GET_LOCATIONS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            is_disable: { _eq: false },
            id: {
              _in: this.staff_locations,
            },
          },
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>

<style>
.bg-event-success {
  background-color: #d5ffd0 !important;
  color: black !important;
}
.bg-event-danger {
  background-color: #ffdfdf !important;
  color: black !important;
}
.bg-event-info {
  background-color: #afd3f8 !important;
  color: black !important;
}
.bg-event-light {
  background-color: #d3d3d3 !important;
  color: black !important;
}
.fc-day-today {
  background: #ffffff !important;
}
.fc-event-title {
  color: black !important;
}
.fc-event-time {
  color: black !important;
}
.fc-scroller {
  scroll-behavior: smooth;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0 !important;
}
.fc-icon-filter,
.fc-icon-date {
  font-family: 'boxicons' !important;
}
.fc-icon-filter:before {
  content: '\ead9';
}
.fc-icon-date:before {
  content: '\ed00';
}
/* .fc-timegrid-event-short {
  display: none !important;
} */
</style>
