<template>
  <div>
    <div v-if="users && users_aggregate">
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="d-flex justify-content-between mb-3">
          <b-form inline class="row gy-2 gx-3 align-items-center">
            <div class="col-sm-auto">
              <b-input
                id="inlineFormInputName2"
                v-model="search_input"
                :placeholder="$t('fields.search')"
                @input="
                  $apollo.queries.users.stop()
                  $apollo.queries.users_aggregate.stop()
                "
              ></b-input>
            </div>
            <div class="col-sm-auto">
              <b-button variant="success" @click="filter">{{
                $t('buttons.placeHolderSearch')
              }}</b-button>
            </div>
            <div class="col-sm-auto">
              <b-button variant="danger" @click="clear">{{ $t('buttons.reset') }}</b-button>
            </div>
          </b-form>
          <div class="col-3 d-flex">
            <button class="btn btn-success ms-auto mb-2" @click="addStaffModal = true">
              {{ $t('staff.add_staff') }}
            </button>
          </div>
        </div>
      </div>

      <add-staff
        :add-staff-modal="addStaffModal"
        :update-item-data="updateItem"
        @close="
          addStaffModal = false
          updateItem = {}
        "
        @submitted="$apollo.queries.users.refresh()"
      />

      <div class="col-md-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div style="overflow-x: auto">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t('staff.color') }}</th>
                    <th>{{ $t('staff.staff') }}</th>
                    <th>{{ $t('customerForm.email') }}</th>
                    <th>{{ $t('customerForm.phone') }}</th>
                    <th>{{ $t('title.locations') }}</th>
                    <th>{{ $t('staff.staff_group') }}</th>
                    <th>{{ $t('staff.point') }}</th>
                    <th>{{ $t('staff.action') }}</th>
                  </tr>
                </thead>
                <draggable
                  v-model="users"
                  tag="tbody"
                  @end="onDragEnd"
                  @start="onDragStart"
                  @change="sortChanged"
                >
                  <tr v-for="(user, key) in users" :key="key">
                    <td>
                      <input
                        v-model="user.color"
                        type="color"
                        class="form-control"
                        @focusout="update(user)"
                      />
                    </td>
                    <td>
                      <div class="d-flex">
                        <b-img
                          v-if="user.image"
                          :src="user.image.thumb"
                          fluid
                          alt="image"
                          class="avatar-xs rounded-circle"
                        ></b-img>
                        <div style="margin-left: 0.5rem">
                          {{ user.first_name }} {{ user.last_name }}
                          <br />
                          {{ user.title ?? '' }}
                        </div>
                      </div>
                    </td>
                    <td>
                      {{ user.email }}
                    </td>
                    <td>
                      {{ user.phone }}
                    </td>
                    <td>
                      <p
                        v-for="(location, locationStaffsKey) in user.location_staffs"
                        :key="locationStaffsKey"
                        class="mb-0"
                      >
                        {{ location.location.name }}
                      </p>
                    </td>
                    <td>
                      {{ user.user_group === null ? 'N/A' : user.user_group.name }}
                    </td>
                    <td>{{ user.points }}</td>
                    <td>
                      <div class="button-items">
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          @click="
                            $router.push({
                              name: 'staff.show',
                              params: { key: user.id },
                            })
                          "
                        >
                          <i class="bx bx-search-alt font-size-16 align-middle me-1"></i>
                          {{ $t('buttons.view') }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          @click="setUpdateModal(user)"
                        >
                          <i class="bx bx-edit font-size-16 align-middle me-1"></i>
                          {{ $t('buttons.edit') }}
                        </button>
                      </div>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-alert :show="users.length === 0" variant="light" class="text-center my-5">
            <p class="mb-0"><i class="mdi mdi-rocket-outline display-4"></i></p>
            {{ $t('staff.no_staff_added') }}
          </b-alert>

          <div class="text-center my-3">
            <p>
              {{ $t('customerForm.total') }} <b>{{ users.length }}</b>
            </p>
          </div>
        </div>
        <!-- end col-->
      </div>
    </div>
    <!--    <div v-if="permission != 'administrator'" class="container">
      &lt;!&ndash; end row &ndash;&gt;
      <div class="row justify-content-center account-pages my-5 pt-sm-5">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card">
            <div class="card-body">
              <div class="p-2">
                <div class="text-center">
                  <div class="avatar-md mx-auto">
                    <div class="avatar-title rounded-circle bg-light">
                      <i class="bx bx-error-alt h1 mb-0 text-warning"></i>
                    </div>
                  </div>
                  <div class="p-2 mt-4">
                    <h4>{{ $t('staff.checking_authorization') }}</h4>
                    <p class="text-muted">
                      {{ $t('staff.not_authorized') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import AddStaff from '@/components/widgets/addStaff'
import Draggable from 'vuedraggable'

import { GET_USERS_AGGREGATE, GET_USERS, GET_PERMISSION, UPDATE_USER } from '@/router/queries'

const setDragCursor = (value) => {
  const html = document.getElementsByTagName('html').item(0)
  html.classList.toggle('grabbing', value)
}

export default {
  name: 'Personal',
  page: {
    title: 'staff',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { PageHeader, AddStaff, Draggable },
  data() {
    return {
      title: this.$t('staff.search_staff'),
      items: [
        {
          text: this.$t('title.staff'),
          href: '/',
        },
        {
          text: this.$t('staff.staff_list'),
          active: true,
        },
      ],
      addStaffModal: false,
      countries: [],
      sort_by: { order: 'asc' },
      search_input: '',
      updateItem: {},
      staff_locations: undefined,
    }
  },
  computed: {
    permission() {
      if (this.user_permissions) {
        const permission_name = this.user_permissions.length
          ? this.user_permissions.find(
              (x) => x.permission_id === 'd441bef4-e019-4346-ba96-0ded106da760'
            ).name
          : null
        return permission_name
      } else {
        return null
      }
    },
    ...mapState(['auth']),
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
  },
  watch: {
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  created() {
    this.$_loading()
  },
  methods: {
    setUpdateModal(staff) {
      this.addStaffModal = true
      this.updateItem = staff
    },
    filter() {
      this.$apollo.queries.users.start()
      this.$apollo.queries.users_aggregate.start()
      this.$_loading()
    },
    clear() {
      this.sort_by = { order: 'asc' }
      this.search_input = ''
      this.$apollo.queries.users.refresh()
      this.$apollo.queries.users_aggregate.refresh()
      this.$_loading()
    },
    sortChanged() {
      console.log('here')
      const self = this
      this.$loading(true)
      this.users.forEach(function (user, index) {
        self.$apollo
          .mutate({
            mutation: UPDATE_USER,
            variables: {
              user: {
                order: index,
              },
              id: user.id,
            },
          })
          .then(() => {
            return true
          })
          .catch(() => {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            return false
          })
      })
      this.$_N({
        type: 'success',
        position: 'center',
      })
      this.$apollo.queries.users.refresh()
      this.$loading(false)
    },
    onDragStart() {
      setDragCursor(true)
    },
    onDragEnd() {
      setDragCursor(false)
    },
  },
  apollo: {
    users: {
      fetchPolicy: 'no-cache',
      query: GET_USERS,
      variables() {
        return {
          order_by: this.sort_by,
          where: {
            _or: [
              {
                email: this.search,
              },
            ],
            role: {
              _eq: 'user',
            },
            location_staffs: this.staff_locations
              ? {
                  location_id: {
                    _in: this.staff_locations,
                  },
                }
              : undefined,
          },
        }
      },
    },
    users_aggregate: {
      fetchPolicy: 'no-cache',
      query: GET_USERS_AGGREGATE,
      variables() {
        return {
          where: {
            _or: [
              {
                email: this.search,
              },
              {
                first_name: this.search,
              },
              {
                last_name: this.search,
              },
            ],
            location_staffs: this.staff_locations
              ? {
                  location_id: {
                    _in: this.staff_locations,
                  },
                }
              : undefined,
          },
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
<style>
.grabbing * {
  cursor: grabbing;
}
</style>
