<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto pt-1">
            <div class="dataTables_filter text-end">
              <b-input
                v-model="filter_b"
                type="search"
                :placeholder="$t('fields.search')"
              ></b-input>
            </div>
          </div>
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="date" class="form-select">
              <b-form-select-option value="today">{{
                $t('lists.date_range.today')
              }}</b-form-select-option>
              <b-form-select-option value="yesterday">{{
                $t('lists.date_range.yesterday')
              }}</b-form-select-option>
              <b-form-select-option value="month">{{
                $t('lists.date_range.this_month')
              }}</b-form-select-option>
              <b-form-select-option value="last_month">{{
                $t('lists.date_range.last_month')
              }}</b-form-select-option>
              <b-form-select-option value="week">{{
                $t('lists.date_range.this_week')
              }}</b-form-select-option>
              <b-form-select-option value="last_week">{{
                $t('lists.date_range.last_week')
              }}</b-form-select-option>
              <b-form-select-option value="year">{{
                $t('lists.date_range.this_year')
              }}</b-form-select-option>
              <b-form-select-option value="last_year">{{
                $t('lists.date_range.last_year')
              }}</b-form-select-option>
              <b-form-select-option value="custom">{{
                $t('lists.date_range.custom')
              }}</b-form-select-option>
            </b-form-select>
          </div>
          <div v-if="date === 'custom'" class="col-sm-auto pt-1">
            <date-picker
              v-model="start"
              value-type="YYYY-MM-DD"
              format="DD/MM/YYYY"
              :first-day-of-week="1"
              lang="en"
            ></date-picker>
          </div>
          <div v-if="date === 'custom'" class="col-sm-auto pt-1">
            <date-picker
              v-model="end"
              value-type="YYYY-MM-DD"
              format="DD/MM/YYYY"
              :first-day-of-week="1"
              lang="en"
            ></date-picker>
          </div>
        </b-form>
      </div>
    </div>
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <div class="col-md-12 col-xs-12">
          <div class="card p-2">
            <div v-if="bundle_payments" class="table-responsive mb-0">
              <b-table
                :items="bundle_payments"
                :fields="fields"
                responsive="sm"
                hover="hover"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter_b"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(price)="data">
                  {{ formatPrice(data.item.price) }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.contact.first_name }}
                  {{ data.item.contact.last_name }}
                </template>
                <template #cell(date)="data">
                  <p
                    v-if="
                      moment(data.value).format('Do MMMM YYYY') !== moment().format('Do MMMM YYYY')
                    "
                    class="text-danger"
                  >
                    {{ moment(data.value).format('Do MMMM YYYY') }} -
                    {{ $t('payment_status.passed') }}
                    <i class="bx bxs-info-circle bx-flashing bx-flip-vertical"></i>
                  </p>
                </template>
                <template #cell(action)="data">
                  <b-button
                    variant="success btn-sm"
                    @click="
                      $router.push({
                        name: 'bundle.show',
                        params: { key: data.item.bundle.id },
                      })
                    "
                  >
                    <i class="bx bx-search-alt font-size-16 align-middle me-1"></i>
                    {{ $t('buttons.view') }}
                  </b-button>
                </template>
              </b-table>
              <b-alert
                class="mt-3 mb-3 text-center"
                :show="bundle_payments.length == 0"
                variant="warning"
                >{{ $t('reports.no_report') }}
              </b-alert>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="page"
                      :total-rows="bundle_payments?.length ?? 0"
                      :per-page="per_page"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import { GET_DUE_PAYMENT_REPORT, GET_PERMISSION } from '../../queries'
import { mapState } from 'vuex'
import moment from 'moment'
import { GET_TEAM } from '@/router/queries'
import DatePicker from 'vue2-datepicker'

export default {
  page: {
    title: 'overdue_payments',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: { PageHeader, DatePicker },
  data() {
    return {
      title: this.$t('reports.overdue.title'),
      items: [
        {
          text: this.$t('reports.title'),
          href: '/',
        },
        {
          text: this.$t('reports.overdue.title'),
          active: true,
        },
      ],
      moment,
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      bundle_payments: null,
      search_input: '',
      currentPage: 1,
      filterOn: [],
      sortBy: 'value',
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      filter_b: null,
      totalRows: 1,
      date: 'month',
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      fields: [
        {
          key: 'name',
          label: this.$t('tables.customer'),
          sortable: true,
        },
        {
          key: 'bundle.name',
          label: this.$t('tables.package'),
          sortable: true,
        },
        {
          key: 'price',
          label: this.$t('tables.price'),
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'date',
          label: this.$t('tables.due_date'),
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'action',
          label: this.$t('tables.action'),
          sortable: true,
          class: 'text-center',
        },
      ],
      staff_locations: undefined,
    }
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    // eslint-disable-next-line vue/return-in-computed-property
    request() {
      const date = new Date()
      if (this.date === 'today') {
        const _and = [
          { start: { _gte: moment().format('YYYY-MM-DD') } },
          { start: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'yesterday') {
        const _and = [
          { start: { _gte: moment().add(-1, 'days').format('YYYY-MM-DD') } },
          { start: { _lte: moment().format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'month') {
        const _and = [
          { start: { _gte: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD') } },
          { start: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_month') {
        const _and = [
          {
            start: {
              _gte: moment().month(date.getMonth()).date(1).add(-1, 'month').format('YYYY-MM-DD'),
            },
          },
          { start: { _lte: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'week') {
        const _and = [
          { start: { _gte: moment().day(1).format('YYYY-MM-DD') } },
          { start: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_week') {
        const _and = [
          { start: { _gte: moment().day(1).add(-7, 'days').format('YYYY-MM-DD') } },
          { start: { _lte: moment().day(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'year') {
        const _and = [
          { start: { _gte: moment().month(0).date(1).format('YYYY-MM-DD') } },
          { start: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_year') {
        const _and = [
          { start: { _gte: moment().month(0).date(1).add(-1, 'year').format('YYYY-MM-DD') } },
          { start: { _lte: moment().month(0).date(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'custom') {
        const _and = [
          { start: { _gte: moment(this.start).format('YYYY-MM-DD') } },
          { start: { _lte: moment(this.end).add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
    },
  },
  watch: {
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  created() {
    this.$_loading()
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  apollo: {
    team: {
      fetchPolicy: 'no-cache',
      query: GET_TEAM,
      variables() {
        return {
          id: this.auth.user.team_id,
        }
      },
    },
    today_appointment: {
      fetchPolicy: 'no-cache',
      query: GET_DUE_PAYMENT_REPORT,
      variables() {
        return {
          limit: this.per_page,
          offset: this.offset,
          order_by: this.sort_by,
          bundle_where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            status: {
              _eq: 'PENDING',
            },
            _and: [
              {
                date: {
                  _gte: moment().add(-3, 'year').format('YYYY-MM-DD'),
                },
              },
              {
                date: {
                  _lte: moment().add(1, 'days').format('YYYY-MM-DD'),
                },
              },
            ],
            contact: {
              location_contacts: {
                location_id: {
                  _in: this.staff_locations,
                },
              },
            },
          },
          id: this.auth.user.team_id,
        }
      },
      result({ data }) {
        this.bundle_payments = data.bundle_payments
        this.team = data.team
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
