<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto pt-1">
            <div class="dataTables_filter text-end">
              <b-input v-model="filter_b" type="search" :placeholder="$t('lists.search')"></b-input>
            </div>
          </div>
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="date" class="form-select">
              <b-form-select-option value="today">{{
                $t('lists.date_range.today')
              }}</b-form-select-option>
              <b-form-select-option value="yesterday">{{
                $t('lists.date_range.yesterday')
              }}</b-form-select-option>
              <b-form-select-option value="month">{{
                $t('lists.date_range.this_month')
              }}</b-form-select-option>
              <b-form-select-option value="last_month">{{
                $t('lists.date_range.last_month')
              }}</b-form-select-option>
              <b-form-select-option value="week">{{
                $t('lists.date_range.this_week')
              }}</b-form-select-option>
              <b-form-select-option value="last_week">{{
                $t('lists.date_range.last_week')
              }}</b-form-select-option>
              <b-form-select-option value="year">{{
                $t('lists.date_range.this_year')
              }}</b-form-select-option>
              <b-form-select-option value="last_year">{{
                $t('lists.date_range.last_year')
              }}</b-form-select-option>
              <b-form-select-option value="custom">{{
                $t('lists.date_range.custom')
              }}</b-form-select-option>
            </b-form-select>
          </div>
          <div v-if="date === 'custom'" class="col-sm-auto pt-1">
            <date-picker
              v-model="start"
              value-type="YYYY-MM-DD"
              format="DD/MM/YYYY"
              :first-day-of-week="1"
              lang="en"
            ></date-picker>
          </div>
          <div v-if="date === 'custom'" class="col-sm-auto pt-1">
            <date-picker
              v-model="end"
              value-type="YYYY-MM-DD"
              format="DD/MM/YYYY"
              :first-day-of-week="1"
              lang="en"
            ></date-picker>
          </div>
        </b-form>
      </div>
    </div>
    <b-alert show dismissible variant="secondary">{{
      $t('reports.appointment.information')
    }}</b-alert>
    <div v-if="appointment_reports" class="col-md-12 col-xs-12">
      <div class="card table-responsive">
        <div class="card-body">
          <div style="overflow-x: auto">
            <b-table
              :items="appointment_reports"
              :fields="fields"
              responsive="sm"
              hover="hover"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter_b"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(start)="data">
                <router-link :to="{ name: 'agenda.detail', params: { key: data.item.id } }">
                  {{ moment(data.value).format('Do MMMM YYYY HH:ss') }}
                </router-link>
              </template>
              <template #cell(status)="row">
                <span
                  class="badge badge-pill badge-soft-success font-size-12"
                  :class="
                    row.item.event_status.code === 'DONE'
                      ? 'badge-soft-success'
                      : 'badge-soft-danger'
                  "
                  >{{ row.item.event_status.name }}</span
                >
                <span
                  v-if="row.item.event_status.code !== 'DONE'"
                  style="margin-left: 2px"
                  class="badge badge-pill bg-info font-size-12"
                  @click="
                    notesModal[row.item.id] = !notesModal[row.item.id]
                    $forceUpdate()
                  "
                >
                  <i class="bx bx-info-circle"></i>
                </span>
                <b-modal
                  v-model="notesModal[row.item.id]"
                  hide-footer
                  :title="$t('reports.appointment.reason_for_not_coming')"
                >
                  <div>
                    <textarea
                      id=""
                      v-model="row.item.notes"
                      class="form-control"
                      disabled
                      cols="30"
                      rows="10"
                      :placeholder="row.item.notes !== null ? '' : '-'"
                    ></textarea>
                  </div>
                  <div class="text-end mt-2">
                    <b-button @click="notesModal = {}">{{ $t('buttons.close') }}</b-button>
                  </div>
                </b-modal>
              </template>
              <template #cell(services)="row">
                <a
                  href="javascript: void(0);"
                  @click="
                    servicesModal[row.item.id] = !servicesModal[row.item.id]
                    $forceUpdate()
                  "
                >
                  {{
                    row.item.event_services.length
                      ? row.item.event_services.length + '  - ' + $t('reports.appointment.services')
                      : '0 ' + $t('reports.appointment.service')
                  }}
                  <i style="font-size: 15px" class="bx bx-link"></i>
                </a>
                <b-modal
                  v-model="servicesModal[row.item.id]"
                  hide-footer
                  :title="$t('reports.appointment.service_detail')"
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('reports.appointment.service') }}</th>
                        <th scope="col">{{ $t('reports.appointment.price') }}</th>
                        <th scope="col">{{ $t('reports.appointment.staff') }}</th>
                        <th scope="col">{{ $t('reports.appointment.date') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(service, key) in row.item.event_services" :key="key">
                        <td>{{ service.service.name }}</td>
                        <td>
                          {{
                            service.price !== 0
                              ? formatPrice(service.price)
                              : $t('reports.appointment.package_usage')
                          }}
                        </td>
                        <td>
                          {{ service.user.first_name + ' ' + service.user.last_name }}
                        </td>
                        <td>
                          {{ moment(service.created_at).format('Do MMMM YYYY HH:ss') }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-alert v-if="!row.item.event_services.length" show variant="warning">{{
                    $t('reports.appointment.package_usage')
                  }}</b-alert>
                  <div class="text-end">
                    <b-button @click="servicesModal = {}">{{ $t('buttons.close') }}</b-button>
                  </div>
                </b-modal>
              </template>
              <template #cell(tips)="row">
                <a
                  href="javascript: void(0);"
                  @click="
                    tipsModal[row.item.id] = !tipsModal[row.item.id]
                    $forceUpdate()
                  "
                >
                  {{
                    row.item.tips.length
                      ? row.item.tips.length + '  - ' + $t('reports.appointment.tips')
                      : '0 ' + $t('reports.appointment.tip')
                  }}
                  <i style="font-size: 15px" class="bx bx-link"></i>
                </a>
                <b-modal
                  v-model="tipsModal[row.item.id]"
                  hide-footer
                  :title="$t('reports.appointment.tip_detail')"
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('reports.appointment.price') }}</th>
                        <th scope="col">{{ $t('reports.appointment.staff') }}</th>
                        <th scope="col">{{ $t('reports.appointment.date') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(tip, key) in row.item.tips" :key="key">
                        <td>
                          {{
                            tip.price !== 0
                              ? formatPrice(tip.price)
                              : $t('reports.appointment.tip_price')
                          }}
                        </td>
                        <td>
                          {{ tip.staff.first_name + ' ' + tip.staff.last_name }}
                        </td>
                        <td>
                          {{ moment(tip.created_at).format('Do MMMM YYYY HH:ss') }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-alert v-if="!row.item.tips.length" show variant="warning">{{
                    $t('reports.appointment.tip_price')
                  }}</b-alert>
                  <div class="text-end">
                    <b-button @click="tipsModal = {}">{{ $t('buttons.close') }}</b-button>
                  </div>
                </b-modal>
              </template>
              <template #cell(name)="row">
                <router-link :to="{ name: 'contact.show', params: { id: row.item.contact_id } }">
                  {{ row.item.contact.first_name + '  ' + row.item.contact.last_name }}
                </router-link>
              </template>
              <template #cell(products)="row">
                <a
                  href="javascript: void(0);"
                  @click="
                    productsModal[row.item.id] = !productsModal[row.item.id]
                    $forceUpdate()
                  "
                >
                  {{
                    row.item.event_products.length
                      ? row.item.event_products.length + ' - ' + $t('reports.appointment.products')
                      : '0 - ' + $t('reports.appointment.product')
                  }}
                  <i style="font-size: 15px" class="bx bx-link"></i>
                </a>
                <b-modal
                  v-model="productsModal[row.item.id]"
                  hide-footer
                  :title="$t('reports.appointment.product_detail')"
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('reports.appointment.product') }}</th>
                        <th scope="col">{{ $t('reports.appointment.price') }}</th>
                        <th scope="col">{{ $t('reports.appointment.staff') }}</th>
                        <th scope="col">{{ $t('reports.appointment.date') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(product, key) in row.item.event_products" :key="key">
                        <td>{{ product.product.name }}</td>
                        <td>{{ formatPrice(product.price) }}</td>
                        <td>
                          {{ product.user.first_name + ' ' + product.user.last_name }}
                        </td>
                        <td>
                          {{ moment(product.created_at).format('Do MMMM YYYY HH:ss') }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-alert v-if="!row.item.event_products.length" show variant="warning">{{
                    $t('reports.appointment.not_found_product')
                  }}</b-alert>
                  <div class="text-end">
                    <b-button @click="productsModal = {}">{{ $t('buttons.close') }}</b-button>
                  </div>
                </b-modal>
              </template>
              <template #cell(bundles)="row">
                <a
                  href="javascript: void(0);"
                  @click="
                    bundlesModal[row.item.id] = !bundlesModal[row.item.id]
                    $forceUpdate()
                  "
                >
                  {{
                    row.item.event_bundles.length
                      ? row.item.event_bundles.length +
                        ' - ' +
                        $t('reports.appointment.not_found_product')
                      : ' 0 - ' + $t('reports.appointment.not_found_product')
                  }}
                  <i style="font-size: 15px" class="bx bx-link"></i>
                </a>
                <b-modal
                  v-model="bundlesModal[row.item.id]"
                  hide-footer
                  :title="$t('reports.appointment.not_found_product')"
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('reports.appointment.package') }}</th>
                        <th scope="col">{{ $t('reports.appointment.service') }}</th>
                        <th scope="col">{{ $t('reports.appointment.amount') }}</th>
                        <th scope="col">{{ $t('reports.appointment.staff') }}</th>
                        <th scope="col">{{ $t('reports.appointment.date') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(bundle, key) in row.item.event_bundles" :key="key">
                        <td>{{ bundle.bundle.name }}</td>
                        <td>{{ bundle.bundle_service.service.name }}</td>
                        <td>{{ bundle.amount }} {{ $t('reports.appointment.session') }}</td>
                        <td>
                          {{ bundle.user.first_name + ' ' + bundle.user.last_name }}
                        </td>
                        <td>
                          {{ moment(bundle.created_at).format('Do MMMM YYYY HH:ss') }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-alert v-if="!row.item.event_bundles.length" show variant="warning">{{
                    $t('reports.appointment.package_usage_not_found')
                  }}</b-alert>
                  <div class="text-end">
                    <b-button @click="bundlesModal = {}">{{ $t('buttons.close') }}</b-button>
                  </div>
                </b-modal>
              </template>
              <template #cell(totalPrice)="row">
                {{
                  row.item.event_status.code === 'DONE'
                    ? row.item.total_services_price.aggregate.sum.price !== null ||
                      row.item.total_products_price.aggregate.sum.price !== null
                      ? formatPrice(
                          row.item.total_products_price.aggregate.sum.price +
                            row.item.total_services_price.aggregate.sum.price +
                            row.item.total_tips_price.aggregate.sum.price +
                            row.item.bundle_paid.aggregate.sum.price
                        )
                      : formatPrice(0)
                    : formatPrice(0)
                }}
              </template>
              <template #cell(totalServices)="row">
                {{
                  row.item.event_status.code === 'DONE'
                    ? row.item.total_services_price.aggregate.sum.price !== null
                      ? formatPrice(row.item.total_services_price.aggregate.sum.price)
                      : formatPrice(0)
                    : formatPrice(0)
                }}
              </template>
              <template #cell(totalTips)="row">
                {{
                  row.item.event_status.code === 'DONE'
                    ? row.item.total_tips_price.aggregate.sum.price !== null
                      ? formatPrice(row.item.total_tips_price.aggregate.sum.price)
                      : formatPrice(0)
                    : formatPrice(0)
                }}
              </template>
              <template #cell(totalBundlePayment)="row">
                {{
                  row.item.bundle_paid.aggregate.sum.price !== null
                    ? formatPrice(row.item.bundle_paid.aggregate.sum.price)
                    : formatPrice(0)
                }}
              </template>
              <template #cell(totalProducts)="row">
                {{
                  row.item.event_status.code === 'DONE'
                    ? row.item.total_products_price.aggregate.sum.price !== null
                      ? formatPrice(row.item.total_products_price.aggregate.sum.price)
                      : formatPrice(0)
                    : formatPrice(0)
                }}
              </template>
              <template #cell(totalPaid)="row">
                {{
                  row.item.event_status.code === 'DONE'
                    ? row.item.paid.aggregate.sum.price !== null
                      ? formatPrice(row.item.paid.aggregate.sum.price)
                      : formatPrice(0)
                    : formatPrice(0)
                }}
              </template>
              <template #cell(totalDept)="row">
                <a
                  v-if="
                    row.item.total_products_price.aggregate.sum.price +
                      row.item.total_services_price.aggregate.sum.price +
                      row.item.total_tips_price.aggregate.sum.price -
                      (row.item.paid.aggregate.sum.price -
                        row.item.bundle_paid.aggregate.sum.price) !==
                      0 && row.item.event_status.code === 'DONE'
                  "
                  href="javascript: void(0);"
                  @click="
                    invoiceModal[row.item.id] = !invoiceModal[row.item.id]
                    $forceUpdate()
                  "
                >
                  {{
                    row.item.event_status.code === 'DONE'
                      ? row.item.total_services_price.aggregate.sum.price !== null ||
                        row.item.total_products_price.aggregate.sum.price !== null ||
                        row.item.total_tips_price.aggregate.sum.price !== null
                        ? formatPrice(
                            row.item.total_products_price.aggregate.sum.price +
                              row.item.total_services_price.aggregate.sum.price +
                              row.item.total_tips_price.aggregate.sum.price -
                              (row.item.paid.aggregate.sum.price -
                                row.item.bundle_paid.aggregate.sum.price)
                          )
                        : formatPrice(0)
                      : 0
                  }}
                  <i style="font-size: 15px" class="bx bx-link"></i>
                </a>
                <span
                  v-if="
                    row.item.total_products_price.aggregate.sum.price +
                      row.item.total_services_price.aggregate.sum.price +
                      row.item.total_tips_price.aggregate.sum.price -
                      (row.item.paid.aggregate.sum.price -
                        row.item.bundle_paid.aggregate.sum.price) ===
                      0 || row.item.event_status.code !== 'DONE'
                  "
                  >{{ formatPrice(0) }}</span
                >
                <b-modal v-model="invoiceModal[row.item.id]" hide-footer>
                  <invoice-component
                    :event-i-d="row.item.id"
                    :contact-i-d="row.item.contact.id"
                    :price="
                      row.item.total_products_price.aggregate.sum.price +
                      row.item.total_services_price.aggregate.sum.price +
                      row.item.total_tips_price.aggregate.sum.price -
                      (row.item.paid.aggregate.sum.price - row.item.bundle_paid.aggregate.sum.price)
                    "
                    @close="
                      invoiceModal = {}
                      $apollo.queries.appointment_reports.refresh()
                    "
                  ></invoice-component>
                </b-modal>
              </template>
            </b-table>
            <b-alert
              class="mt-3 mb-3 text-center"
              :show="appointment_reports.length == 0"
              variant="warning"
              >{{ $t('reports.appointment.no_report') }}</b-alert
            >
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="page"
                    :total-rows="appointment_reports.length"
                    :per-page="per_page"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="card p-2 table-responsive" v-if="this.$apollo.queries.contacts_reports.refresh()">
            <h3 class="mb-3 mt-3 text-center"><small class="text-muted">Kayıt Bulunamadı!</small></h3>
          </div>-->
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import { GET_APPOINTMENT_REPORT, GET_PERMISSION } from '../../queries'
import { mapState } from 'vuex'
import moment from 'moment'
import invoiceComponent from './components/invoiceAppointment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/tr'

export default {
  page: {
    title: 'appointment_reports',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: { PageHeader, invoiceComponent, DatePicker },
  data() {
    return {
      title: this.$t('reports.appointment.title'),
      invoiceModal: {},
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      items: [
        {
          text: this.$t('reports.title'),
          href: '/',
        },
        {
          text: this.$t('reports.appointment.title'),
          active: true,
        },
      ],
      currentPage: 1,
      filterOn: [],
      sortBy: 'value',
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      filter_b: null,
      totalRows: 1,
      fields: [
        {
          key: 'start',
          label: this.$t('reports.appointment.date'),
          sortable: true,
        },
        {
          key: 'name',
          sortable: true,
          label: this.$t('reports.appointment.customer'),
        },
        {
          key: 'services',
          sortable: true,
          label: this.$t('reports.appointment.services'),
        },
        {
          key: 'tips',
          sortable: true,
          label: this.$t('reports.appointment.tips'),
        },
        {
          key: 'products',
          sortable: true,
          label: this.$t('reports.appointment.products'),
        },
        {
          key: 'bundles',
          sortable: true,
          label: this.$t('reports.appointment.package_usage'),
        },
        {
          key: 'totalServices',
          sortable: true,
          label: this.$t('reports.appointment.service_total'),
        },
        {
          key: 'totalTips',
          sortable: true,
          label: this.$t('reports.appointment.tip_total'),
        },
        {
          key: 'totalProducts',
          sortable: true,
          label: this.$t('reports.appointment.product_total'),
        },
        {
          key: 'totalBundlePayment',
          sortable: true,
          label: this.$t('reports.appointment.package_total'),
        },
        {
          key: 'totalPrice',
          sortable: true,
          label: this.$t('reports.appointment.total'),
        },
        {
          key: 'totalPaid',
          sortable: true,
          label: this.$t('reports.appointment.paid'),
        },
        {
          key: 'totalDept',
          sortable: true,
          label: this.$t('reports.appointment.dept'),
        },
        {
          key: 'status',
          sortable: true,
          label: this.$t('reports.appointment.status'),
        },
      ],
      moment,
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      date: 'today',
      servicesModal: {},
      tipsModal: {},
      productsModal: {},
      bundlesModal: {},
      notesModal: {},
      search_input: '',
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      staff_locations: undefined,
    }
  },
  watch: {
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    rows() {
      return this.appointment_reports.length
    },
    // eslint-disable-next-line vue/return-in-computed-property
    request() {
      const date = new Date()
      if (this.date === 'today') {
        const _and = [
          { start: { _gte: moment().format('YYYY-MM-DD') } },
          { start: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'yesterday') {
        const _and = [
          { start: { _gte: moment().add(-1, 'days').format('YYYY-MM-DD') } },
          { start: { _lte: moment().format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'month') {
        const _and = [
          { start: { _gte: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD') } },
          {
            start: {
              _lte: moment().month(date.getMonth()).date(1).add(1, 'month').format('YYYY-MM-DD'),
            },
          },
        ]
        return _and
      }
      if (this.date === 'last_month') {
        const _and = [
          {
            start: {
              _gte: moment().month(date.getMonth()).date(1).add(-1, 'month').format('YYYY-MM-DD'),
            },
          },
          { start: { _lte: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'week') {
        const _and = [
          { start: { _gte: moment().day(1).format('YYYY-MM-DD') } },
          { start: { _lte: moment().day(1).add(7, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_week') {
        const _and = [
          { start: { _gte: moment().day(1).add(-7, 'days').format('YYYY-MM-DD') } },
          { start: { _lte: moment().day(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'year') {
        const _and = [
          { start: { _gte: moment().month(0).date(1).format('YYYY-MM-DD') } },
          { start: { _lte: moment().month(0).date(1).add(1, 'year').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_year') {
        const _and = [
          { start: { _gte: moment().month(0).date(1).add(-1, 'year').format('YYYY-MM-DD') } },
          { start: { _lte: moment().month(0).date(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'custom') {
        const _and = [
          { start: { _gte: moment(this.start).format('YYYY-MM-DD') } },
          { start: { _lte: moment(this.end).add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
    },
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  apollo: {
    appointment_reports: {
      fetchPolicy: 'no-cache',
      query: GET_APPOINTMENT_REPORT,
      variables() {
        return {
          offset: this.offset,
          limit: this.per_page,
          order_by: this.sort_by,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            _and: this.request,
            contact: {
              location_contacts: {
                location_id: {
                  _in: this.staff_locations,
                },
              },
            },
          },
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
