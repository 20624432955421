var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"title":_vm.isUpdate ? _vm.$t('close_dates.edit_closed_dates') : _vm.$t('close_dates.add_closed_date'),"hide-footer":"","size":"md"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('close_dates.table.start'),"for":"start"}},[_c('date-picker',{class:{
                'is-invalid': _vm.submitted && _vm.$v.closedDates.start_date.$error,
              },attrs:{"type":"date","format":"YYYY-MM-DD","value-type":"YYYY-MM-DD","placeholder":"Select date","lang":"en","show-week-number":false},model:{value:(_vm.closedDates.start_date),callback:function ($$v) {_vm.$set(_vm.closedDates, "start_date", $$v)},expression:"closedDates.start_date"}}),(_vm.submitted && !_vm.$v.closedDates.start_date.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-6"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('close_dates.table.end'),"for":"end"}},[_c('date-picker',{class:{
                'is-invalid': _vm.submitted && _vm.$v.closedDates.end_date.$error,
              },attrs:{"type":"date","format":"YYYY-MM-DD","value-type":"YYYY-MM-DD","placeholder":"Select date","lang":"en","show-week-number":false},model:{value:(_vm.closedDates.end_date),callback:function ($$v) {_vm.$set(_vm.closedDates, "end_date", $$v)},expression:"closedDates.end_date"}}),(_vm.submitted && !_vm.$v.closedDates.end_date.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-12"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('close_dates.table.description')}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.closedDates.description.$error,
              },attrs:{"id":"description"},model:{value:(_vm.closedDates.description),callback:function ($$v) {_vm.$set(_vm.closedDates, "description", $$v)},expression:"closedDates.description"}}),(_vm.submitted && !_vm.$v.closedDates.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-12 mb-3"},[_c('locations-input',{attrs:{"input-data":_vm.closedDates.locations,"is-invalid":_vm.submitted && !_vm.locationIsValid},on:{"setLocations":_vm.setLocations}})],1)]),_c('b-alert',{staticClass:"mt-3 mb-3",attrs:{"show":_vm.eventFoundInCloseDate,"variant":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('close_dates.remove_appointments'))+" "),_c('ul',{staticClass:"mt-3"},_vm._l((_vm.eventsInSelectedDate),function(item){return _c('li',{key:item.id},[_vm._v(" "+_vm._s(item.location.name + ' - ' + _vm.moment(item.start).format('DD MMM') + ' at ' + _vm.moment(item.start).format('hh:mm a'))+" ")])}),0)]),_c('div',{staticClass:"text-end"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }