<template>
  <div v-if="event" class="account-pages my-5 pt-5">
    <div v-if="reviews && !reviews.find((x) => x.event_id === $route.params.key)" class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="text-center mb-5">
            <img width="50%" class="mb-4" src="/notification-feedback.png" />
            <h4 class="text-uppercase">{{ $t('review.hello') }}</h4>
            <p class="text-dark" style="font-size: 15px">
              {{ $t('review.description') }}
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <div>
            <div class="card">
              <div class="card-body">
                <b-form-group
                  :label="$t('review.rating')"
                  :description="$t('review.vote_description')"
                  class="mb-3"
                >
                  <b-form-rating
                    v-model="review.rating"
                    size="lg"
                    variant="warning"
                    class="mb-2 text-center"
                  ></b-form-rating>
                </b-form-group>
                <b-form-group :label="$t('review.comment')" class="mb-3">
                  <b-form-textarea v-model="review.comment" rows="7" />
                </b-form-group>
                <div class="text-end">
                  <b-button variant="success" @click="save()">{{ $t('buttons.save') }}</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="reviews && reviews.find((x) => x.event_id === $route.params.key)" class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="text-center mb-5">
            <img width="50%" class="mb-4" src="/notification-feedback.png" />
            <h4 class="text-uppercase">{{ $t('review.thanks') }}</h4>
            <p class="text-dark" style="font-size: 15px">
              {{ $t('review.thank_description') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="thanksModal" hide-header hide-footer>
      <div style="text-align: center">
        <h5 class="my-3" style="line-height: 30px">
          {{ $t('review.thanks_review') }}
        </h5>
        <a :href="googleReviewUrl" target="_blank" class="btn btn-primary my-3" @click="closeModal">
          {{ $t('review.review') }}
        </a>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { GET_EVENTS_REVIEW, INSERT_REVIEW, GET_REVIEW } from '../../queries'
export default {
  name: 'Review',
  data() {
    return {
      review: null,
      googleReviewUrl: '',
      thanksModal: false,
    }
  },
  watch: {
    event: function () {
      this.review.contact_id = this.event.contact_id
      this.review.team_id = this.event.team_id
    },
  },
  created() {
    this.$_loading()
    this.setData()
  },
  methods: {
    setData() {
      this.review = {
        contact_id: null,
        team_id: null,
        event_id: this.$route.params.key,
        rating: 5,
        comment: null,
      }
    },
    save() {
      this.$apollo.mutate({
        mutation: INSERT_REVIEW,
        variables: {
          review: this.review,
        },
        update: () => {
          try {
            if (this.review.rating === 5) {
              this.thanksModal = true
              this.googleReviewUrl =
                'https://search.google.com/local/writereview?placeid=' +
                this.event.location.place_id
              return
            }
            this.$_N({
              position: 'center',
              type: 'success',
            })
            this.$apollo.queries.reviews.refresh()
            this.$loading(false)
          } catch (e) {
            this.$_N({
              position: 'center',
              type: 'error',
            })
          }
        },
      })
    },
    closeModal() {
      setTimeout(() => {
        this.thanksModal = false
      }, 500)
    },
  },
  apollo: {
    event: {
      fetchPolicy: 'no-cache',
      query: GET_EVENTS_REVIEW,
      variables() {
        return {
          id: this.$route.params.key,
        }
      },
    },
    reviews: {
      fetchPolicy: 'no-cache',
      query: GET_REVIEW,
    },
  },
}
</script>
