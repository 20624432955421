<template>
  <div>
    <div v-if="notifications && team" class="row justify-content-center mt-lg-6">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('notification.notifications') }}</h4>

            <b-alert show variant="primary">
              {{ $t('notification.customize_notification') }}
            </b-alert>

            <div v-for="(notification, key) in notifications" :key="key" class="row mt-2">
              <div class="col-lg-4">
                <h5 class="font-size-14">
                  {{ notification.name }}
                  <i class="mdi mdi-arrow-right text-primary"></i>
                </h5>
                <div class="form-check form-switch form-switch-md mb-3">
                  <input
                    id="SwitchCheckSizemd"
                    v-model="notification.active"
                    class="form-check-input"
                    type="checkbox"
                  />
                </div>
              </div>
              <div class="col-lg-8">
                <div class="row">
                  <div class="col-md-9">
                    <b-form-group id="input-group-1" class="mb-3" :description="notification.hint">
                      <b-form-textarea
                        id="formrow-inputState"
                        v-model="notification.message"
                        rows="4"
                        :placeholder="notification.message"
                      ></b-form-textarea>
                    </b-form-group>
                  </div>
                  <div class="col-md-3">
                    <b-button variant="primary" @click="update(notification)">{{
                      $t('buttons.save')
                    }}</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('notification.auto_email_settings') }}</h4>
            <div class="form-check form-checkbox-outline form-check-primary mb-3">
              <input
                id="customCheckcolor1"
                v-model="team.notification_control"
                class="form-check-input"
                type="checkbox"
                checked=""
                @change="save(team)"
              />
              <label class="form-check-label" for="customCheckcolor1">
                {{ $t('notification.appointment_reminder') }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('notification.email_settings') }}</h4>

            <b-alert show variant="primary">
              {{ $t('notification.change_email_settings') }}
            </b-alert>

            <form class="form-horizontal" role="form">
              <!--<b-form-group
                class="mb-3"
                id="example text"
                label-cols-sm="3"
                label-cols-lg="3"
                label="Host"
                label-for="host"
              >
                <b-form-input
                  for="text"
                  v-model="team.senders.email.host"
                  :disabled="!checked"
                ></b-form-input>
              </b-form-group>
              -->

              <!--<b-form-group
                class="mb-3"
                id="port"
                label-cols-sm="3"
                label-cols-lg="3"
                label="Port"
                label-for="port"
              >
                <b-form-input
                  id="port"
                  type="text"
                  v-model="team.senders.email.port"
                  :disabled="!checked"
                ></b-form-input>
              </b-form-group>
              
              <b-form-group
                class="mb-3"
                id="protocol"
                label-cols-sm="3"
                label-cols-lg="3"
                label="Protokol"
                label-for="protocol"
              >
                <b-form-select
                  class="form-control"
                  v-model="team.senders.email.protocol"
                  :options="options"
                  :disabled="!checked"
                ></b-form-select>
              </b-form-group>
              -->

              <b-form-group
                id="email_sender_name"
                class="mb-3"
                label-cols-sm="3"
                label-cols-lg="3"
                :label="$t('notification.sender_header')"
                label-for="email_sender_name"
              >
                <b-form-input
                  id="email_sender_name"
                  v-model="team.senders.email.sender_name"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="email_sender_email"
                class="mb-3"
                label-cols-sm="3"
                label-cols-lg="3"
                :label="$t('notification.sender')"
                label-for="email_sender_email"
              >
                <b-form-input
                  id="email_sender_email"
                  v-model="team.senders.email.sender_email"
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>

              <!--<b-form-group
                class="mb-3"
                id="username"
                label-cols-sm="3"
                label-cols-lg="3"
                label="Kullanıcı Adı"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  type="text"
                  v-model="team.senders.email.username"
                  :disabled="!checked"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="password"
                label-cols-sm="3"
                label-cols-lg="3"
                label="Şifre"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  type="password"
                  v-model="team.senders.email.password"
                  :disabled="!checked"
                ></b-form-input>
              </b-form-group>
              -->
            </form>
            <div class="col-md-12 d-flex justify-content-end">
              <b-button variant="primary" @click="save(team)">{{ $t('buttons.save') }}</b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('notification.sms_settings') }}</h4>

            <b-alert show variant="primary">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="$t('notification.sms_defaults')"></div>
            </b-alert>
            <b-form-group
              class="mb-3"
              label-cols-sm="3"
              label-cols-lg="3"
              :label="$t('notification.sms_sender_id')"
              label-for="email_sender_email"
            >
              <b-form-input
                v-model="team.senders.sms.sender_id"
                :class="{
                  'is-invalid': !validSenderId,
                }"
                @input="
                  () => {
                    validSenderId = senderIdPattern.test(team.senders.sms.sender_id)
                  }
                "
              />
              <div v-if="!validSenderId" class="invalid-feedback">
                {{ $t('notification.not_valid_sender_id') }}
              </div>
            </b-form-group>
            <div class="col-md-12 d-flex justify-content-end">
              <b-button variant="primary" @click="save(team)">{{ $t('buttons.save') }}</b-button>
            </div>
            <!--
            <div class="form-check form-switch form-switch-md mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="private_settings_sms"
                name="private_settings_sms"
                v-model="checked_sms"
              />
              <label class="form-check-label" for="SwitchCheckSizemd"
                >Özel Ayarlar
              </label>
            </div>

            <form class="form-horizontal" role="form">
              <b-form-group
                class="mb-3"
                id="protocol"
                label-cols-sm="3"
                label-cols-lg="3"
                label="Sağlayıcı"
                label-for="protocol"
              >
                <b-form-select
                  class="form-control"
                  v-model="team.senders.sms.provider"
                  :options="provider"
                  :disabled="!checked_sms"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="sender_name"
                label-cols-sm="3"
                label-cols-lg="3"
                label="Gönderici Başlığı"
                label-for="sender_name"
              >
                <b-form-input
                  id="sender_name"
                  type="text"
                  v-model="team.senders.sms.sender_name"
                  :disabled="!checked_sms"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="username"
                label-cols-sm="3"
                label-cols-lg="3"
                label="Kullanıcı Adı"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  type="text"
                  v-model="team.senders.sms.username"
                  :disabled="!checked_sms"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="pwd"
                label-cols-sm="3"
                label-cols-lg="3"
                label="Şifre"
                label-for="pwd"
              >
                <b-form-input
                  id="pwd"
                  type="password"
                  v-model="team.senders.sms.password"
                  :disabled="!checked_sms"
                ></b-form-input>
              </b-form-group>
            </form>
            <div class="col-md-12 d-flex justify-content-end">
              <b-button variant="primary" @click="save(team)">{{ $t('buttons.save') }}</b-button>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import { mapState } from 'vuex'
import { GET_NOTIFICATIONS, GET_TEAM, UPDATE_NOTIFICATION, UPDATE_SENDERS } from '@/router/queries'

export default {
  page: {
    title: 'notifications_settings',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: this.$t('notification.notifications_settings'),
      checked_sms: false,
      items: [
        {
          text: this.$t('notification.notifications_settings'),
          href: '/',
        },
        {
          text: 'Default',
          active: true,
        },
      ],
      options: [{ value: 'TLS', text: 'TLS' }],
      provider: [{ value: 'NETGSM', text: 'NETGSM' }],
      submitted: false,
      validSenderId: true,
      senderIdPattern: /^[a-zA-Z0-9\s]{0,11}$/,
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  created() {
    this.$_loading()
  },
  methods: {
    update(notification) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_NOTIFICATION,
        variables: {
          notification: this.$_ER(notification, ['__typename', 'name']),
          id: notification.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$loading(false)
          }
        },
      })
    },
    save(team) {
      this.submitted = true
      this.$loading(true)
      if (!this.validSenderId) {
        this.$loading(false)
        return
      }
      this.$apollo.mutate({
        mutation: UPDATE_SENDERS,
        variables: {
          senders: this.$_ER(team, ['__typename', 'sender_name', 'sender_email', 'provider']),
          id: team.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$loading(false)
          }
          this.validSenderId = true
        },
      })
    },
  },
  apollo: {
    notifications: {
      fetchPolicy: 'no-cache',
      query: GET_NOTIFICATIONS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
    team: {
      fetchPolicy: 'no-cache',
      query: GET_TEAM,
      variables() {
        return {
          id: this.auth.user.team_id,
        }
      },
    },
  },
}
</script>
