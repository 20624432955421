<template>
  <div v-if="sources">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="sort_by" class="form-select">
              <b-form-select-option :value="{ created_at: 'desc' }">{{
                $t('sort.newest')
              }}</b-form-select-option>
              <b-form-select-option :value="{ created_at: 'asc' }">{{
                $t('sort.oldest')
              }}</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto">
            <b-input
              id="inlineFormInputName2"
              v-model="search_input"
              placeholder="Kaynak Türü adı"
            ></b-input>
          </div>
        </b-form>

        <button class="btn btn-success ms-auto mb-2" @click="showModal = true">
          Kaynak Türü Ekle
        </button>
      </div>
    </div>

    <b-modal v-model="showModal" title="Kaynak Türü Ekle" hide-footer>
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="source_name">Kaynak Türü ismi</label>
              <input
                id="source_name"
                v-model="source.name"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.source.name.$error,
                }"
              />
              <div v-if="submitted && !$v.source.name.required" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="showModal = false">{{ $t('buttons.close') }}</b-button>
          <b-button type="submit" variant="success" class="ms-1">Yeni Kaynak Türü Oluştur</b-button>
        </div>
      </form>
    </b-modal>

    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <div class="col-md-12 col-xs-12">
          <div class="card p-2 table-responsive">
            <table class="table mb-0 table-striped">
              <thead class="thead-light">
                <tr>
                  <th>Kaynak Adı</th>
                  <th>{{ $t('tables.created_at') }}</th>
                  <th>{{ $t('tables.action') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(sourceItem, key) in sources" :key="key">
                  <td>{{ sourceItem.name }}</td>
                  <td>
                    {{ moment(sourceItem.created_at).from() }}
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      @click="
                        updateModal[key] = !updateModal[key]
                        $forceUpdate()
                      "
                    >
                      {{ $t('buttons.edit') }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      style="margin-left: 5px"
                      @click="remove(sourceItem)"
                    >
                      {{ $t('buttons.delete') }}
                    </button>
                  </td>
                  <b-modal v-model="updateModal[key]" title="Kaynak Türü Ekle" hide-footer>
                    <form @submit.prevent="update(sourceItem)">
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="source_name">Kaynak Türü ismi</label>
                            <input
                              id="source_name"
                              v-model="sourceItem.name"
                              type="text"
                              class="form-control"
                              placeholder="source"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="text-end mt-3">
                        <b-button variant="light" @click="updateModal = {}">{{
                          $t('buttons.close')
                        }}</b-button>
                        <b-button type="submit" variant="success" class="ms-1">{{
                          $t('buttons.save')
                        }}</b-button>
                      </div>
                    </form>
                  </b-modal>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-alert :show="sources.length === 0" variant="light" class="text-center my-5">
          <p class="mb-0"><i class="mdi mdi-rocket-outline display-4"></i></p>
          Sorry, no records found
        </b-alert>

        <div class="text-center my-3">
          <p>
            Total <b>{ {{ sources.length }} }</b>
          </p>
        </div>
      </div>
      <!-- end col-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import { INSERT_SOURCE, GET_SOURCES, UPDATE_SOURCE, DELETE_SOURCE } from '@/router/queries'

export default {
  name: 'Sources',
  page: {
    title: 'Kaynaklar',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { PageHeader },
  data() {
    return {
      title: 'Kaynak Türü Ara',
      items: [
        {
          text: 'Kaynak Türüler',
          href: '/',
        },
        {
          text: 'Kaynak Türü Ara',
          active: true,
        },
      ],
      showModal: false,
      submitted: false,
      source: null,
      countries: [],
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      search_input: '',
      updateModal: {},
      moment,
    }
  },
  validations: {
    source: {
      name: { required },
    },
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
  },
  watch: {
    'source.name': function (val) {
      this.source.code = val
    },
  },
  created() {
    this.$_loading()
    this.source = {
      name: null,
      code: null,
      user_id: this.auth.user.id,
      team_id: this.auth.user.team_id,
    }
  },
  methods: {
    handleSubmit() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$loading(false)
        return
      } else {
        this.$apollo.mutate({
          mutation: INSERT_SOURCE,
          variables: {
            source: this.source,
          },
          update: () => {
            try {
              this.$apollo.queries.sources.refresh()
              this.$_N({
                position: 'center',
                type: 'success',
              })
              this.$loading(false)
            } catch (e) {
              this.$_N({
                position: 'center',
                type: 'error',
              })
              this.$loading(false)
            }
          },
        })
        this.showModal = false
      }
      this.submitted = false
    },
    update(source) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_SOURCE,
        variables: {
          id: source.id,
          source: this.$_ER(source, ['__typename']),
        },
        update: () => {
          try {
            this.$_N({
              position: 'center',
              type: 'success',
            })
            this.$loading(false)
          } catch (e) {
            this.$_N({
              position: 'center',
              type: 'error',
            })
            this.$loading(false)
          }
        },
      })
      this.updateModal = {}
    },
    remove(source) {
      const self = this
      this.$_C({
        confirmed(result) {
          if (result) {
            self.$loading(true)
            self.$apollo.mutate({
              mutation: DELETE_SOURCE,
              variables: {
                id: source.id,
              },
              update: () => {
                try {
                  self.$_N({
                    type: 'success',
                    position: 'center',
                  })
                  self.$loading(false)
                  self.$apollo.queries.sources.refresh()
                } catch (e) {
                  self.$_N({
                    type: 'error',
                    position: 'center',
                  })
                }
              },
            })
          }
        },
      })
    },
  },
  apollo: {
    sources: {
      fetchPolicy: 'no-cache',
      query: GET_SOURCES,
      variables() {
        return {
          limit: this.per_page,
          offset: this.offset,
          order_by: this.sort_by,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            _or: [
              {
                name: this.search,
              },
            ],
          },
        }
      },
    },
  },
}
</script>
