<template>
  <div>
    <input
      :id="uniqueId"
      v-model="inputValue"
      class="form-control"
      type="text"
      :class="{ 'is-invalid': isInvalid }"
      @input="updateInput"
    />
    <div v-if="isInvalid" class="invalid-feedback">
      {{ $t('setup.field_required') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: '',
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isLocationName: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    inputValue: '',
    addressInput: null,
  }),
  computed: {
    uniqueId() {
      return Math.floor(Math.random() * 10000000) + '-address-input'
    },
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.inputValue = newVal
      }
    },
  },
  mounted() {
    window.addEventListener('load', this.initialize())
    if (this.value && !this.inputValue) this.inputValue = this.value
  },
  methods: {
    updateInput() {
      this.$emit('input', this.inputValue)
    },
    initialize() {
      setTimeout(() => {
        const input = document.getElementById(this.uniqueId)
        // eslint-disable-next-line no-undef
        new google.maps.places.Autocomplete(input)
        // eslint-disable-next-line no-undef
        const autocomplete = new google.maps.places.Autocomplete(input)
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace()
          this.setPlaceAddress(place)
          this.setPlaceDetails(place)
        })
      }, 500)
    },
    setPlaceAddress(place) {
      this.inputValue = this.isLocationName ? place.name : place.formatted_address
      this.$emit('input', this.inputValue)
    },
    setPlaceDetails(place) {
      this.$store.commit('location/setLocationDetails', place)
    },
  },
}
</script>

<style>
.pac-container {
  z-index: 1051 !important;
}
</style>
