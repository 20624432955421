<template>
  <b-form v-if="genders && marital_statuses">
    <form @submit.prevent="handleSubmit()">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <b-form-group class="mb-3" :label="$t('customerForm.name')" for="first_name">
              <b-form-input
                id="first_name"
                v-model="contact.first_name"
                type="text"
                :class="{
                  'is-invalid': submitted && $v.contact.first_name.$error,
                }"
              ></b-form-input>
              <div v-if="submitted && !$v.contact.first_name.required" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
              <div v-if="submitted && !$v.contact.first_name.minLength" class="invalid-feedback">
                {{ $t('customerForm.warning_first_name') }}
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <b-form-group class="mb-3" :label="$t('customerForm.surname')" for="last_name">
              <b-form-input id="last_name" v-model="contact.last_name" type="text"></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <b-form-group
              class="mb-3"
              :label="$t('customerForm.birthday')"
              label-for="formrow-password-input"
            >
              <!--<b-form-input
                id="formrow-password-input"
                type="date"
                v-model="contact.birth_date"
              ></b-form-input>-->
              <VueCtkDateTimePicker
                v-model="contact.birth_date"
                color="#2a3042"
                button-color="#2a3042"
                :button-now-translation="$t('buttons.now')"
                :label="$t('buttons.placeHolderSelect')"
                position="bottom"
                only-date
                formatted="ll"
              />
            </b-form-group>
          </div>
        </div>
        <div class="col-md-3">
          <b-form-group
            class="mb-3"
            :label="$t('customerForm.gender')"
            label-for="formrow-email-input"
          >
            <multiselect
              v-model="contact.gender_id"
              :placeholder="$t('buttons.placeHolderSelect')"
              :show-labels="false"
              :options="genders.map((x) => x.id)"
              :custom-label="
                (opt) => {
                  return $t(`gender.${genders.find((x) => x.id == opt).name}`)
                }
              "
              :multiple="false"
            >
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <b-form-group
              class="mb-3"
              :label="$t('customerForm.phone')"
              label-for="formrow-password-input"
            >
              <tel-input v-model="contact.phone" />
            </b-form-group>
          </div>
        </div>
        <div class="col-md-3">
          <b-form-group class="mb-3" :label="$t('customerForm.email')" for="email">
            <b-form-input
              id="email"
              v-model="contact.email"
              type="email"
              :class="{
                'is-invalid': submitted && $v.contact.email.$error,
              }"
            ></b-form-input>
            <div v-if="submitted && !$v.contact.email.required" class="invalid-feedback">
              {{ $t('fields.required') }}
            </div>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <b-form-group
              class="mb-3"
              :label="$t('customerForm.marital')"
              label-for="formrow-password-input"
            >
              <multiselect
                v-model="contact.marital_status_id"
                :placeholder="$t('buttons.placeHolderSelect')"
                :show-labels="false"
                :options="marital_statuses.map((x) => x.id)"
                :custom-label="
                  (opt) => {
                    return $t(`marital_statuses.${marital_statuses.find((x) => x.id == opt).name}`)
                  }
                "
                :multiple="false"
              ></multiselect>
            </b-form-group>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <b-form-group
              class="mb-3"
              :label="$t('customerForm.job')"
              label-for="formrow-password-input"
            >
              <b-form-input
                id="formrow-password-input"
                v-model="contact.job"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="col-md-3">
          <b-form-group
            class="mb-3"
            :label="$t('customerForm.referer')"
            label-for="formrow-email-input"
          >
            <b-form-input
              id="formrow-email-input"
              v-model="contact.where_to_reach"
              type="text"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <b-form-group
              class="mb-3"
              :label="$t('customerForm.reference')"
              label-for="formrow-password-input"
            >
              <b-form-input
                id="formrow-password-input"
                v-model="contact.reference"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <b-form-group class="mb-3" :label="$t('teams.profile.address')">
              <Address v-model="contact.address" />
            </b-form-group>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <locations-input
            :input-data="contact.location_contacts"
            :is-invalid="submitted && !locationIsValid"
            @setLocations="setLocations"
          />
        </div>
        <div class="col-12 col-lg-6">
          <b-form-group class="mb-3" :label="$t('customerForm.image')" for="fileinput">
            <b-form-file
              id="fileinput"
              v-model="imageFile"
              :state="Boolean(imageFile)"
              accept="image/jpeg, image/png, image/jpg"
              placeholder="Choose an image or drop it here..."
              drop-placeholder="Drop file here..."
              @input="fileUpload"
            ></b-form-file>
          </b-form-group>
        </div>
        <!-- notifications -->
        <div v-if="contact.subscriptions" class="col-12 mt-3">
          <h4 class="card-title">{{ $t('notification.notifications') }}</h4>
        </div>
        <div v-if="contact.subscriptions" class="col-12 col-lg-6">
          <label class="mb-3">{{ $t('notification.appointment_reminders') }}</label>
          <div class="form-check form-switch">
            <input
              id="client_email"
              v-model="contact.subscriptions.COMMON.email"
              class="form-check-input"
              type="checkbox"
              role="switch"
            />
            <label class="form-check-label" for="client_email">
              {{ $t('notification.email_allow') }}
            </label>
          </div>
          <div class="form-check form-switch">
            <input
              id="client_sms"
              v-model="contact.subscriptions.COMMON.sms"
              class="form-check-input"
              type="checkbox"
              role="switch"
            />
            <label class="form-check-label" for="client_sms">
              {{ $t('notification.sms_allow') }}
            </label>
          </div>
        </div>
        <div v-if="contact.subscriptions" class="col-12 col-lg-6">
          <label class="mb-3">{{ $t('notification.marketing') }}</label>
          <div class="form-check form-switch">
            <input
              id="marketing_email"
              v-model="contact.subscriptions.MARKETING.email"
              class="form-check-input"
              type="checkbox"
              role="switch"
            />
            <label class="form-check-label" for="marketing_email">
              {{ $t('notification.email_marketing_allow') }}
            </label>
          </div>
          <div class="form-check form-switch">
            <input
              id="marketing_sms"
              v-model="contact.subscriptions.MARKETING.sms"
              class="form-check-input"
              type="checkbox"
              role="switch"
            />
            <label class="form-check-label" for="marketing_sms">
              {{ $t('notification.sms_marketing_allow') }}
            </label>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <b-button type="submit" variant="primary">{{
          isUpdate ? $t('buttons.update_customer') : $t('buttons.addCustomer')
        }}</b-button>
      </div>
    </form>
  </b-form>
</template>

<script>
import {
  GET_CONTACT_STATUSES,
  GET_GENDERS,
  ADD_CONTACT,
  GET_LOCATIONS,
  UPDATE_CONTACT,
  DELETE_LOCATION_CONTACT_BY_CONTACT_ID,
  ADD_LOCATION_CONTACTS,
  CREATE_USER_WITH_CONTACT,
  CHECK_USER,
  UPDATE_USER,
} from '@/router/queries'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import { required, minLength, email } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
import { uploadService } from '@/helpers/upload.service.js'

export default {
  components: {
    Multiselect,
    VueCtkDateTimePicker,
  },
  props: {
    customer: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      contact: null,
      submitted: false,
      locationChanged: false,
      locationIsValid: true,
      isUpdate: null,
      imageFile: null,
      contactID: null,
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  watch: {
    customer: {
      handler(val) {
        console.log('val :>> ', val)
        if (val && val.id) {
          console.log('this.contact :>> ', this.contact)
          this.contact = { ...val }
          this.isUpdate = true
          console.log('this.contact :>> ', this.contact)
        } else this.initData()
      },
      immediate: true,
    },
  },
  validations: {
    contact: {
      first_name: { required, minLength: minLength(2) },
      email: { required, email },
    },
  },
  // created() {
  //   this.initData()
  // },
  methods: {
    initData() {
      this.isUpdate = false
      this.contact = {
        first_name: null,
        last_name: null,
        mother_name: null,
        father_name: null,
        tags: null,
        job: null,
        alcohol_id: null,
        birth_date: null,
        blood_group_id: null,
        city_id: null,
        address: null,
        marital_status_id: null,
        reference: null,
        smoking_id: null,
        sms_notification_status: null,
        where_to_reach: null,
        phone: null,
        gender_id: null,
        team_id: this.auth.user.team_id,
        email: null,
        image: null,
        location_contacts: {
          data: [],
        },
        subscriptions: {
          COMMON: { sms: true, call: true, push: true, email: true, whatsapp: true },
          MARKETING: { sms: true, call: true, push: true, email: true, whatsapp: true },
          NEWSLETTER: { sms: true, call: true, push: true, email: true, whatsapp: true },
          COMPANY_NEWS: { sms: true, call: true, push: true, email: true, whatsapp: true },
        },
      }
    },
    setLocations(val) {
      this.locationChanged = true
      const locations = []
      val.forEach((el) => {
        locations.push({
          location_id: el.id,
          contact_id: this.contact.id,
          team_id: this.auth.user.team_id,
        })
      })
      console.log('this.contact.location_contacts :>> ', this.contact)
      this.contact.location_contacts.data = locations
    },
    async handleSubmit() {
      if (this.isUpdate) {
        if (this.locationChanged) {
          await this.removeLocationContact()
          await this.addLocationContacts()
        }
        this.update()
        this.locationChanged = false
        return
      }
      this.create()
    },
    async create() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.contact.location_contacts.data.length === 0) {
        this.locationIsValid = false
      } else this.locationIsValid = true
      if (this.$v.$invalid || !this.locationIsValid) {
        this.$loading(false)
        return
      } else {
        if (this.contact.email) {
          if ((await this.checkUser()) === false) {
            this.$loading(false)
            this.$_D({
              type: 'error',
              position: 'center',
              text: this.$t('customerCard.email_registered'),
            })
            return
          }
          this.contact.password = (Math.random() + 1).toString(36).substring(2)
          this.contact.need_update_profile = true
          this.$apollo.mutate({
            mutation: CREATE_USER_WITH_CONTACT,
            variables: {
              ...this.contact,
            },
            update: (cache, { data: { user } }) => {
              try {
                this.contactID = user.contact.id
                this.updateUserContactId(user.id)
              } catch (e) {
                this.$_N({
                  type: 'error',
                  position: 'center',
                })
              }
            },
          })
        } else {
          this.contact.user = this.auth.user.id
          this.$apollo.mutate({
            mutation: ADD_CONTACT,
            variables: {
              contact: this.contact,
            },
            update: (cache, { data: { insert_contacts_one } }) => {
              try {
                this.contactID = insert_contacts_one.id
                this.$_N({
                  type: 'success',
                  position: 'center',
                })
                this.$router.push({
                  name: 'contact.show',
                  params: { id: this.contactID },
                })
              } catch (e) {
                this.$_N({
                  type: 'error',
                  position: 'center',
                })
              }
            },
          })
        }
      }
    },
    update() {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_CONTACT,
        variables: {
          contact: this.$_ER(this.contact, ['__typename', 'vault_activities', 'location_contacts']),
          id: this.contact.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$emit('close')
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
    },
    async removeLocationContact() {
      this.$loading(true)
      await this.$apollo
        .mutate({
          mutation: DELETE_LOCATION_CONTACT_BY_CONTACT_ID,
          variables: {
            contact_id: this.contact.id,
          },
        })
        .then(() => {
          console.log('remove')
          return
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
        })
    },
    async addLocationContacts() {
      this.$loading(true)
      await this.$apollo
        .mutate({
          mutation: ADD_LOCATION_CONTACTS,
          variables: {
            locationContacts: this.contact.location_contacts.data,
          },
        })
        .then((ddd) => {
          console.log('ddd :>> ', ddd)
          this.contact.location_contacts.data = []
          return
        })
        .catch((e) => {
          console.log('e :>> ', e)
          this.contact.location_contacts.data = []
          this.$_N({
            type: 'error',
            position: 'center',
          })
        })
    },
    async fileUpload(file) {
      this.$loading(true)
      try {
        const image = await uploadService({
          file: file,
          name: this.contact.last_name + '-contact-image',
          team_id: this.auth.user.team_id,
          user_id: this.auth.user.id,
        })
        this.contact.image = image
      } catch (err) {
        this.$_N({
          type: 'error',
          position: 'center',
        })
      }
      this.$loading(false)
    },
    async checkUser() {
      let status = false
      await this.$apollo
        .mutate({
          mutation: CHECK_USER,
          variables: {
            email: this.contact.email,
          },
        })
        .then((res) => {
          if (res.data.user.aggregate.count > 0) {
            status = false
          } else status = true
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
          status = false
        })
      return status
    },
    async updateUserContactId(userId) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: {
          user: {
            contact_id: this.contactID,
          },
          id: userId,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$router.push({
              name: 'contact.show',
              params: { id: this.contactID },
            })
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
    },
  },
  apollo: {
    marital_statuses: {
      fetchPolicy: 'no-cache',
      query: GET_CONTACT_STATUSES,
    },
    genders: {
      fetchPolicy: 'no-cache',
      query: GET_GENDERS,
    },
    locations: {
      fetchPolicy: 'no-cache',
      query: GET_LOCATIONS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            is_disable: { _eq: false },
          },
        }
      },
      result() {
        if (this.locations.length === 1) {
          const obj = {
            location_id: this.locations[0].id,
            team_id: this.auth.user.team_id,
          }
          this.contact.location_contacts.data.push(obj)
        }
      },
    },
  },
}
</script>
