<template>
  <div v-if="marketings && tags">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <div>
          <b-form inline class="row gy-2 gx-3 align-items-center">
            <div class="col-sm-auto pt-1">
              <b-form-select v-model="per_page" class="form-select">
                <b-form-select-option :value="null">{{
                  $t('lists.per_page')
                }}</b-form-select-option>
                <b-form-select-option :value="1">1</b-form-select-option>
                <b-form-select-option :value="10">10</b-form-select-option>
                <b-form-select-option :value="25">25</b-form-select-option>
                <b-form-select-option :value="50">50</b-form-select-option>
                <b-form-select-option :value="100">100</b-form-select-option>
              </b-form-select>
            </div>
            <div class="col-sm-auto pt-1">
              <b-form-select v-model="sort_by" class="form-select">
                <b-form-select-option :value="{ created_at: 'desc' }">{{
                  $t('sort.newest')
                }}</b-form-select-option>
                <b-form-select-option :value="{ created_at: 'asc' }">{{
                  $t('sort.oldest')
                }}</b-form-select-option>
              </b-form-select>
            </div>
            <div class="col-sm-auto">
              <b-input
                id="inlineFormInputName2"
                v-model="search_input"
                :placeholder="$t('fields.search')"
              ></b-input>
            </div>
          </b-form>
        </div>
        <button class="btn btn-success ms-auto mb-2" @click="showModal = true">
          {{ $t('marketing.send_bulk_email') }}
        </button>
      </div>
    </div>

    <email-marketing-modal :add-email-marketing-modal="showModal" @close="showModal = false" />

    <div class="col-md-12 col-xs-12">
      <div class="card">
        <div class="card-body">
          <div v-if="marketings" class="table-responsive mb-0">
            <div style="overflow-x: auto">
              <b-table
                :items="marketings"
                :fields="fields"
                responsive="sm"
                hover="hover"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter_b"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(created_at)="data">
                  {{ moment(data.value).format('LLL') }}
                </template>
                <template #cell(schedule)="data">
                  {{ moment(data.value).format('LLL') }}
                </template>
                <template #cell(status)="row">
                  <span
                    class="badge badge-pill badge-soft-success font-size-12"
                    :class="row.item.status === 'DONE' ? 'badge-soft-success' : 'badge-soft-danger'"
                    >{{
                      row.item.status === 'DONE' ? $t('tables.done') : $t('tables.pending')
                    }}</span
                  >
                </template>
                <template #cell(actions)="row">
                  <button
                    v-if="row.item.status === 'DONE'"
                    type="button"
                    class="btn btn-primary btn-sm waves-effect waves-light"
                    style="margin-right: 3px"
                    @change="row"
                    @click="
                      cloneModal[row.item.id] = !cloneModal[row.item.id]
                      $forceUpdate()
                    "
                  >
                    <i class="bx bx-copy font-size-16 align-middle me-1"></i
                    >{{ $t('buttons.copy') }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-success btn-sm waves-effect waves-light"
                    @change="row"
                    @click="
                      $router.push({
                        name: 'marketing.email.show',
                        params: { id: row.item.id },
                      })
                    "
                  >
                    <i class="bx bx-search-alt font-size-16 align-middle me-1"></i>
                    {{ $t('buttons.show') }}
                  </button>
                  <email-marketing-modal
                    :add-email-marketing-modal="cloneModal[row.item.id]"
                    :data="row.item"
                    @close="
                      cloneModal = {}
                      $apollo.queries.marketings.refresh()
                    "
                  />
                </template>
              </b-table>
            </div>
            <b-alert :show="rows === 0" class="my-1 text-center" variant="warning">
              {{ $t('marketing.marketing_not_found') }}
            </b-alert>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="page"
                    :total-rows="rows"
                    :per-page="per_page"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="text-center my-3">
          <p>
            {{ $t('customerForm.total') }} <b>{{ rows }}</b>
          </p>
        </div>
      </div>
      <!-- end col-->
    </div>
  </div>
</template>

<script>
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { GET_MARKETINGS, GET_TAGS } from '@/router/queries'
import { mapState } from 'vuex'
import moment from 'moment'
import emailMarketingModal from '@/components/widgets/emailMarketing.vue'

export default {
  name: 'Marketing',
  page: {
    title: 'email_marketing',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    PageHeader,
    emailMarketingModal,
  },
  data() {
    return {
      title: this.$t('marketing.email_marketing'),
      items: [
        {
          text: this.$t('marketing.title'),
          href: '/',
        },
        {
          text: this.$t('marketing.email_marketing'),
          active: true,
        },
      ],
      filterOn: [],
      sortBy: 'value',
      totalRows: 1,
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      filter_b: null,
      fields: [
        {
          key: 'name',
          label: this.$t('tables.name'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('tables.status'),
          sortable: true,
        },
        {
          key: 'schedule',
          sortable: true,
          label: this.$t('tables.date'),
        },
        {
          key: 'created_at',
          sortable: true,
          label: this.$t('tables.created_at'),
        },
        {
          key: 'actions',
          sortable: true,
          label: this.$t('tables.action'),
        },
      ],
      showModal: false,
      submitted: false,
      cloneModal: {},
      marketing: null,
      editor: ClassicEditor,
      editorConfig: {
        autoParagraph: false,
      },
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      moment,
      search_input: '',
    }
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
    rows() {
      return this.marketings.length
    },
  },
  created() {
    this.$_loading()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.page = 1
    },
  },
  apollo: {
    marketings: {
      fetchPolicy: 'no-cache',
      query: GET_MARKETINGS,
      variables() {
        return {
          offset: this.offset,
          limit: this.per_page,
          order_by: this.sort_by,
          where: {
            type: {
              _eq: 'email',
            },
            _or: [
              {
                name: this.search,
              },
              {
                subject: this.search,
              },
              {
                content: this.search,
              },
            ],
          },
        }
      },
    },
    tags: {
      fetchPolicy: 'no-cache',
      query: GET_TAGS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
