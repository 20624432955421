const apiURL = process.env.VUE_APP_API_URL || 'http://auth:8080/'

async function upload(form) {
  const formdata = new FormData()

  formdata.append('file', form.file, form.file.name)
  formdata.append('team_id', form.team_id)
  formdata.append('user_id', form.user_id)
  formdata.append('name', form.name)

  const requestOptions = {
    method: 'POST',
    body: formdata,
  }
  try {
    const response = await fetch(`${apiURL}/upload`, requestOptions)
    const image = await response.json()
    return image
  } catch (error) {
    return Promise.reject(error)
  }
}

export const uploadService = upload
