var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Loader',{attrs:{"loading":_vm.forms === undefined}},[(_vm.forms)?_c('div',[_c('b-modal',{attrs:{"title":_vm.isUpdate ? _vm.$t('service.edit_service') : _vm.$t('service.add_service'),"hide-footer":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('service.service_name'),"for":"service_name"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.service.name.$error,
              },attrs:{"id":"service_name","type":"text"},model:{value:(_vm.service.name),callback:function ($$v) {_vm.$set(_vm.service, "name", $$v)},expression:"service.name"}}),(_vm.submitted && !_vm.$v.service.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('service.service_price'),"for":"service_price"}},[_c('b-form-input',{staticClass:"form-control",class:{
                'is-invalid': _vm.submitted && _vm.$v.service.price.$error,
              },attrs:{"id":"service_price","type":"number","step":"0.01","lazy-formatter":"","formatter":(val) => parseFloat(val).toFixed(2)},model:{value:(_vm.service.price),callback:function ($$v) {_vm.$set(_vm.service, "price", $$v)},expression:"service.price"}}),(_vm.submitted && !_vm.$v.service.price.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('service.service_duration'),"for":"service_duration"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.service.duration.$error,
              },attrs:{"id":"service_duration","type":"number"},model:{value:(_vm.service.duration),callback:function ($$v) {_vm.$set(_vm.service, "duration", $$v)},expression:"service.duration"}}),(_vm.submitted && !_vm.$v.service.duration.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('title.categories')}},[_c('multiselect',{class:{
                'is-invalid': _vm.submitted && _vm.$v.selectedCategories.$error,
              },attrs:{"placeholder":_vm.$t('service.service_category'),"show-labels":false,"multiple":true,"close-on-select":false,"clear-on-select":true,"options":_vm.categories.map((x) => x.id),"custom-label":(opt) => _vm.categories.find((x) => x.id == opt).name},model:{value:(_vm.selectedCategories),callback:function ($$v) {_vm.selectedCategories=$$v},expression:"selectedCategories"}}),(_vm.submitted && _vm.$v.selectedCategories.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('staff.staff_groups')}},[_c('multiselect',{class:{
                'is-invalid': _vm.submitted && _vm.$v.selectedGroups.$error,
              },attrs:{"placeholder":_vm.$t('service.service_category'),"show-labels":false,"multiple":true,"close-on-select":false,"clear-on-select":true,"options":_vm.userGroups.map((x) => x.id),"custom-label":(opt) => _vm.userGroups.find((x) => x.id == opt).name},model:{value:(_vm.selectedGroups),callback:function ($$v) {_vm.selectedGroups=$$v},expression:"selectedGroups"}}),(_vm.submitted && _vm.$v.selectedGroups.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"label":"Form"}},[_c('multiselect',{attrs:{"placeholder":_vm.$t('service.service_forms'),"show-labels":false,"options":_vm.forms.map((x) => x.id),"custom-label":(opt) => _vm.forms.find((x) => x.id == opt).name},model:{value:(_vm.service.form_id),callback:function ($$v) {_vm.$set(_vm.service, "form_id", $$v)},expression:"service.form_id"}})],1),_c('locations-input',{attrs:{"input-data":_vm.service.location_services,"is-invalid":_vm.submitted && !_vm.locationIsValid},on:{"setLocations":_vm.setLocations}}),_c('div',{staticClass:"mt-4"},[_c('b-checkbox',{attrs:{"inline":"","plain":""},model:{value:(_vm.service.show_online),callback:function ($$v) {_vm.$set(_vm.service, "show_online", $$v)},expression:"service.show_online"}},[_vm._v(" "+_vm._s(_vm.$t('service.show_online'))+" ")])],1),_c('div',{staticClass:"text-end mt-3"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('buttons.close')))]),_c('b-button',{staticClass:"ms-1",attrs:{"type":"submit","variant":"success"}},[_vm._v(_vm._s(_vm.$t('buttons.save')))])],1)],1)])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }