<template>
  <div v-if="team">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{ $t('package.sms.sms_packages') }}</h4>

          <div class="page-title-right">
            <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-2">{{ $t('package.sms.sms_packages_information') }}</h4>
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      <p class="mb-0">{{ $t('package.sms.license_end_date') }}</p>
                    </td>
                    <td>
                      <h6 class="mb-0">
                        {{ moment(team.subscription_expires).format('Do MMMM YYYY') }}
                      </h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="mb-0">{{ $t('package.sms.remaining_sms_credit') }}</p>
                    </td>
                    <td>
                      <h6 class="mb-0">
                        {{ team.sms_count }}
                      </h6>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ $t('package.sms.sms_packages') }}</h4>

              <div class="mb-1">
                <div class="row">
                  <div class="mt-2">
                    <!--<b-alert show variant="primary">
                    Paketiniz aylık olarak otomatik yenilenecektir. İster 1
                    yıllık ister aylık ödeme periyotları ile Barespace'i
                    kullanabilirsiniz.
                  </b-alert>-->
                  </div>
                  <div
                    v-for="listUsing of using"
                    :key="listUsing.id"
                    class="col-xl-2 col-sm-4 col-xs-4 col-md-2"
                  >
                    <b-card
                      text-variant="white"
                      :header="listUsing.name"
                      class="text-center barespace-package"
                    >
                      <b-card-text>
                        <h3 class="text-white">{{ formatPrice(listUsing.amount) }}</h3>
                      </b-card-text>
                      <b-button variant="outline-dark" @click="setPriceValue(listUsing)"
                        ><i class="bx bx-cart-alt"></i>
                        {{ $t('buttons.placeHolderSelect') }}</b-button
                      >
                    </b-card>
                  </div>
                </div>
              </div>

              <!-- <div class="mb-2">
              <h4 class="card-title mb-1">Diğer</h4>
              <div class="row">
                <div
                  v-for="listOther of other"
                  :key="listOther.id"
                  class="col-xl-2 col-sm-4"
                >
                  <div class="mb-3" @click="setPriceValue(listOther)">
                    <label class="card-radio-label mb-2">
                      <input
                        type="radio"
                        name="currency"
                        id="buycurrencyoption1"
                        class="card-radio-input"
                      />

                      <div class="card-radio">
                        <div>
                          <i
                            class="mdi mdi-rocket-outline font-size-24 text-info align-middle me-2"
                          ></i>
                          <span>{{ listOther.name }}</span>
                          <p class="text-muted mb-1">
                            {{ formatPrice(listOther.amount) }}
                          </p>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
              <div class="mb-2">
                <label>Ödeme Yöntemi :</label>

                <div class="row">
                  <div class="col-xl-3 col-sm-4">
                    <label class="card-radio-label mb-3">
                      <input
                        type="radio"
                        name="pay-method"
                        id="pay-methodoption1"
                        class="card-radio-input"
                        checked
                      />
                      <div class="card-radio">
                        <i
                          class="fab fa-cc-mastercard font-size-24 text-primary align-middle me-2"
                        ></i>
                        <span>Kredi Kartı / Debit Kart</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div> -->
              <p v-if="current_price != null" class="mb-3">
                <strong>{{ $t('package.payment_info') }}:</strong>
                {{
                  $t('package.payment_desc', {
                    description: current_price.description,
                    amount: formatPrice(current_price.amount),
                  })
                }}
              </p>
              <div class="mt-1">
                <b-button variant="info" @click="submit">
                  {{ $t('package.buy') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import gql from 'graphql-tag'
import moment from 'moment'
// eslint-disable-next-line no-unused-vars
import { GET_TEAM, INSERT_CHECKOUT, UPDATE_TEAM, SUBSCRIPTION_CANCEL } from '../../queries'
import { mapState } from 'vuex'

export default {
  page: {
    title: 'sms_packages',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      title: this.$t('package.sms.sms_packages'),
      items: [
        {
          text: this.$t('package.sms.sms_packages'),
          href: '/packages/sms',
        },
        {
          text: this.$t('package.buy'),
          active: true,
        },
      ],
      moment,
      loading: false,
      current_price: null,
    }
  },
  computed: {
    ...mapState(['auth']),
    expire_date: function () {
      const countDownDate = new Date(this.team.subscription_expires).getTime()
      const now = new Date().getTime()
      const distance = countDownDate - now
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      return days
    },
  },
  methods: {
    setPriceValue(val) {
      this.current_price = val
    },
    submit() {
      /* var x = window.matchMedia("(max-width: 700px)") */
      this.$_loading(true)
      this.$apollo.mutate({
        mutation: INSERT_CHECKOUT,
        variables: {
          name: this.current_price.name,
          price_id: this.current_price.price_id,
          team_id: this.auth.user.team_id,
          user_id: this.auth.user.id,
          group: this.current_price.group,
          description: this.current_price.description,
          amount: String(this.current_price.amount),
          type: this.current_price.type,
        },
        update: (cache, { data: { checkout } }) => {
          try {
            /* eslint-disable no-console */
            window.location.replace(checkout.url)
            /*if (x.matches) {
              window.location.replace(checkout.url);
            } else {
              window.open(checkout.url);
            } */
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
    },
    cancelMethod() {
      const self = this
      this.$_BUNDLE_C({
        title: self.expire_date + ' Gün kalan süreniz bulunmaktadır.onaylıyor musunuz ?',
        confirmText: 'Onaylıyorum',
        confirmed(result) {
          if (result) {
            self.$loading(true)
            self.$apollo.mutate({
              mutation: SUBSCRIPTION_CANCEL,
              variables: {
                team_id: self.team.id,
              },
              update: () => {
                try {
                  self.$_N({
                    type: 'success',
                    position: 'center',
                  })
                  self.$apollo.queries.team.refresh()
                  self.$loading(false)
                } catch (e) {
                  self.$_N({
                    type: 'error',
                    position: 'center',
                  })
                  self.$loading(false)
                }
              },
            })
          }
        },
      })
    },
  },
  apollo: {
    team: {
      fetchPolicy: 'no-cache',
      query: GET_TEAM,
      variables() {
        return {
          id: this.auth.user.team_id,
        }
      },
    },
    using: {
      query: gql`
        query getUsingPackages {
          using: packages(where: { group: { _eq: "SMS" } }) {
            group
            id
            name
            amount
            price_id
            description
            type
          }
        }
      `,
    },
    other: {
      query: gql`
        query getOtherPackages {
          other: packages(where: { group: { _eq: "Diğer" } }) {
            group
            id
            name
            amount
            price_id
            description
            type
          }
        }
      `,
    },
  },
}
</script>

<style>
.card-header {
  font-size: 15px;
  font-weight: bold;
}
.barespace-package {
  --bs-bg-opacity: 1;
  border-radius: 8%;
  background: rgb(77, 111, 149);
  background: linear-gradient(40deg, rgba(77, 111, 149, 1) 0%, rgba(131, 189, 222, 1) 100%);
}
</style>
