import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import _ from 'lodash'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from 'v-click-outside'
import router from './router/index'
import Scrollspy from 'vue2-scrollspy'
import VueSweetalert2 from 'vue-sweetalert2'
import Swal from 'sweetalert2'
import VueApollo from 'vue-apollo'
import VueLoading from 'vuejs-loading-plugin'
import ApolloClient from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
// For WS
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'

import '../src/design/app.scss'
import store from '@/state/store'
import App from './App.vue'
import i18n from './i18n'
import tinymce from 'vue-tinymce-editor'
import Guide from 'vue-site-guide'
import VueHtmlToPaper from 'vue-html-to-paper'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import TelInput from '@/components/widgets/tel-input.vue'
import Address from '@/components/widgets/address'
import locationsInput from '@/components/widgets/locationsInput'
import moment from 'moment'
import Multiselect from 'vue-multiselect'

Vue.use(VueHtmlToPaper)
Vue.component('Guide', Guide)
Vue.component('Tinymce', tinymce)
Vue.use(VueRouter)
Vue.use(vco)
Vue.use(Scrollspy)
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.use(VueTelInput)

Vue.config.productionTip = false

Vue.prototype.$_ER = function (object, excludes = []) {
  for (const exclude of excludes) {
    object = _.omit(object, exclude)
  }
  return object
}
Vue.prototype.$_loading = function () {
  const self = this
  setTimeout(function () {
    self.$loading(true)
    setTimeout(function () {
      self.$loading(false)
    }, 1600)
  }, 0)
}
Vue.prototype.$_N = function ({ position, type, title }) {
  Swal.fire({
    position: position,
    icon: type,
    title: title ? title : type === 'success' ? 'Successfully Completed' : 'Something went wrong',
    showConfirmButton: false,
    timer: title ? 2000 : 1000,
  })
}
Vue.prototype.$_C = function ({ confirmed, title, text }) {
  Swal.fire({
    title: title ? title : 'Are you sure you want to delete?',
    showDenyButton: true,
    confirmButtonText: text ? text : `Delete`,
    denyButtonText: `Cancel`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      return confirmed(true)
    } else if (result.isDenied) {
      return confirmed(false)
    }
  })
}

Vue.prototype.$_BUNDLE_C = function ({ confirmed, title, confirmText }) {
  Swal.fire({
    title: title,
    showDenyButton: true,
    confirmButtonText: confirmText,
    denyButtonText: `İptal`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      return confirmed(true)
    } else if (result.isDenied) {
      return confirmed(false)
    }
  })
}

Vue.prototype.$_D = function ({ position, type, text }) {
  Swal.fire({
    position: position,
    icon: type,
    title: text,
    showConfirmButton: false,
    timer: 1000,
  })
}

Vue.prototype.convertUtc = (input) => {
  const fmt = 'YYYY-MM-DD h:mm:ss A'
  const zone = store.state.location.timezone
  const formatted = moment.tz(input, fmt, zone).utc().format(fmt)
  return formatted
}
// currencies list
Vue.prototype.$_CURRENCIES = ['EUR', 'GBP']

Vue.prototype.formatPrice = (price) => {
  const currency = store.state.currency.companyCurrency
  const formattedPrice = price - Math.round(price) === 0 ? price : price.toFixed(2)
  return formattedPrice + ' ' + currency
}

const getHeaders = () => {
  const headers = {}
  const token = window.localStorage.getItem('_user_at')
  if (token) {
    headers.authorization = token
  } else {
    headers['X-Hasura-Role'] = 'anonymous'
  }
  return headers
}
const httpLink = new HttpLink({
  uri: process.env.VUE_APP_CORE_URL,
  fetch,
  headers: getHeaders(),
})
const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_WS_URL,
  options: {
    reconnect: true,
  },
})
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: true,
  }),
})
const apolloProvider = new VueApollo({
  defaultClient: client,
  defaultOptions: {
    $query: {
      loadingKey: 'loader',
    },
  },
})

Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Loading', // default 'Loading'
  loading: true, // default false
  background: 'rgb(255,255,255, .3)', // set custom background
  classes: ['myclass'], // array, object or string
})
Vue.use(BootstrapVue)
Vue.use(VueApollo)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueSweetalert2)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true,
})

Vue.component('Apexchart', VueApexCharts)
Vue.component('Multiselect', Multiselect)
Vue.component('TelInput', TelInput)
Vue.component('Address', Address)
Vue.component('LocationsInput', locationsInput)

new Vue({
  router,
  store,
  i18n,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app')
