export const menuItems = [
  {
    id: 1,
    label: 'menuitems.menu.text',
    isTitle: true,
  },

  {
    id: 2,
    label: 'menuitems.dashboards.text',
    icon: 'bx-heart',
    link: '/',
  },

  {
    id: 4,
    label: 'menuitems.dashboards.search',
    icon: 'bx bx-group',
    link: '/contacts',
    permissions: ['administrator', 'clients'],
  },
  {
    id: 53,
    label: 'menuitems.dashboards.calendar',
    icon: 'bx-calendar',
    link: '/calendar',
  },
  {
    id: 51,
    label: 'menuitems.general.list.activities',
    icon: 'bx bx-bell',
    link: '/activities',
  },
  {
    id: 54,
    label: 'menuitems.systems.list.bundles',
    icon: 'bx-package',
    link: '/bundles',
    permissions: ['administrator'],
  },
  {
    id: 56,
    label: 'menuitems.accounting.text',
    icon: 'bx bx-briefcase',
    link: '/accounting/case',
    permissions: ['administrator', 'accounting.case'],
  },
  {
    id: 6,
    isLayout: true,
  },
  {
    id: 7,
    label: 'menuitems.apps.text',
    isTitle: true,
  },
  {
    id: 20,
    label: 'menuitems.reporting.text',
    icon: 'bx-bar-chart-alt-2',
    permissions: ['administrator', 'reporting'],
    subItems: [
      {
        id: 21,
        label: 'menuitems.reporting.list.appointment',
        link: '/reporting/appointment',
        parentId: 20,
      },
      {
        id: 22,
        label: 'menuitems.reporting.list.customer',
        link: '/reporting/customer',
        parentId: 20,
      },
      {
        id: 23,
        label: 'menuitems.reporting.list.credit',
        link: '/reporting/credit',
        parentId: 20,
      },
      {
        id: 24,
        label: 'menuitems.reporting.list.review',
        link: '/reporting/reviews',
        parentId: 20,
      },
      {
        id: 24,
        label: 'menuitems.reporting.list.due',
        link: '/reporting/due-payment',
        parentId: 20,
      },
      {
        id: 25,
        label: 'menuitems.reporting.list.bundle',
        link: '/reporting/bundles',
        parentId: 21,
        permissions: ['administrator'],
      },
      {
        id: 26,
        label: 'menuitems.reporting.list.metrics',
        link: '/reporting/metrics',
        parentId: 21,
        permissions: ['administrator'],
      },
      {
        id: 27,
        label: 'menuitems.reporting.list.sales',
        link: '/reporting/sales',
        parentId: 20,
      },
    ],
  },

  {
    id: 25,
    label: 'menuitems.marketings.text',
    icon: 'bx-share-alt',
    permissions: ['administrator', 'marketing'],
    subItems: [
      {
        id: 26,
        label: 'menuitems.marketings.list.sms',
        link: '/marketings/sms',
        parentId: 25,
      },
      {
        id: 27,
        label: 'menuitems.marketings.list.email',
        link: '/marketings/email',
        parentId: 25,
      },
    ],
  },

  {
    id: 30,
    label: 'menuitems.systems.text',
    icon: 'bx-server',
    permissions: ['administrator', 'system.settings'],
    subItems: [
      {
        id: 31,
        label: 'menuitems.systems.list.staff',
        link: '/staff',
        parentId: 30,
      },
      {
        id: 32,
        label: 'menuitems.systems.list.staff_groups',
        link: '/staff-groups',
        parentId: 30,
      },
      {
        id: 33,
        label: 'menuitems.systems.list.team_detail',
        link: '/teams/profile',
        parentId: 30,
      } /*
      {
        id: 40,
        label: "menuitems.systems.list.booking_settings",
        link: "/booking-settings",
        parentId: 30,
      }, */,
      {
        id: 34,
        label: 'menuitems.systems.list.working_hours',
        link: '/working-hours',
        parentId: 30,
      } /*
      {
        id: 35,
        label: "menuitems.systems.list.source_types",
        link: "/source-types",
        parentId: 30,
      },
      {
        path: "/reporting/marketing",
        name: "reporting.marketing",
        meta: {
          authRequired: true,
        },
        component: () => import("@/router/views/reporting/marketing"),
      },
      */,
      {
        id: 36,
        label: 'menuitems.systems.list.services',
        link: '/services',
        parentId: 30,
      },
      {
        id: 36,
        label: 'menuitems.systems.list.forms',
        link: '/forms',
        parentId: 30,
      },
      {
        id: 41,
        label: 'menuitems.systems.list.bundles',
        link: '/bundles',
        parentId: 30,
        permissions: ['administrator'],
      },
      {
        id: 37,
        label: 'menuitems.systems.list.products',
        link: '/products',
        parentId: 30,
      },
      {
        id: 38,
        label: 'menuitems.systems.list.notifications',
        link: '/notifications',
        parentId: 30,
      },
      {
        id: 31,
        label: 'menuitems.systems.list.locations',
        link: '/locations',
        parentId: 30,
      },
      {
        id: 42,
        label: 'menuitems.systems.list.close_dates',
        link: '/close-dates',
        parentId: 30,
      },
    ],
  },

  {
    id: 45,
    label: 'menuitems.general.text',
    icon: 'bx-command',
    permissions: ['administrator', 'general'],
    subItems: [
      {
        id: 50,
        label: 'menuitems.general.list.packages',
        link: '/packages',
        parentId: 45,
      },
      {
        id: 52,
        label: 'menuitems.general.list.sms',
        link: '/packages/sms',
        parentId: 45,
      },
      {
        id: 55,
        label: 'stripe.settings',
        link: '/stripe',
        parentId: 45,
      },
      {
        id: 57,
        label: 'menuitems.general.list.tracking',
        link: '/tracking',
        parentId: 45,
      },
    ],
  },
]
