<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center mt-lg-6">
      <div class="card">
        <div class="card-body">
          <div v-if="team != null" class="col-xl-12 col-sm-12">
            <b-form>
              <form @submit.prevent="update">
                <div class="row">
                  <div class="col-md-12">
                    <div class="d-flex flex-row gap-3">
                      <b-img
                        v-if="team.logo"
                        :src="team.logo.thumb"
                        fluid
                        alt="image"
                        class="ratio ratio-1x1"
                        style="max-width: 240px; min-width: 240px"
                      ></b-img>
                      <div class="col-12">
                        <b-form-group
                          class="mb-3"
                          :label="$t('teams.profile.logo')"
                          for="fileinput"
                        >
                          <b-form-file
                            id="fileinput"
                            v-model="imageFile"
                            :state="Boolean(imageFile)"
                            accept="image/jpeg, image/png, image/jpg"
                            placeholder="Choose an image or drop it here..."
                            drop-placeholder="Drop file here..."
                            @input="fileUpload"
                          ></b-form-file>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <b-form-group
                      class="mb-3"
                      :label="$t('teams.profile.company_name')"
                      label-for="form-row-text-input"
                    >
                      <b-form-input
                        id="form-row-text-input"
                        v-model="team.name"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': editFormSubmitted && userValidation.name,
                        }"
                      >
                      </b-form-input>
                      <div v-if="editFormSubmitted && userValidation.name" class="invalid-feedback">
                        {{ $t('fields.required') }}
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-group
                        class="mb-3"
                        :label="$t('teams.profile.bill_title')"
                        label-for="formrow-text-input"
                      >
                        <b-form-input
                          id="formrow-text-input"
                          v-model="team.tax_title"
                          type="text"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-group
                        class="mb-3"
                        :label="$t('teams.profile.tax_office')"
                        label-for="formrow-text-input"
                      >
                        <b-form-input
                          id="formrow-text-input"
                          v-model="team.tax_name"
                          type="text"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-group
                        class="mb-3"
                        :label="$t('teams.profile.tax_number')"
                        label-for="formrow-text-input"
                      >
                        <b-form-input
                          id="formrow-nubmer-input"
                          v-model="team.tax_number"
                          type="number"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <b-form-group
                      class="mb-3"
                      :label="$t('teams.profile.website')"
                      label-for="formrow-text-input"
                    >
                      <b-form-input
                        id="formrow-text-input"
                        v-model="team.website"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-group
                        class="mb-3"
                        :label="$t('customerForm.email')"
                        label-for="formrow-text-input"
                      >
                        <b-form-input
                          id="formrow-text-input"
                          v-model="team.email"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': editFormSubmitted && userValidation.email,
                          }"
                        >
                        </b-form-input>
                        <div
                          v-if="editFormSubmitted && userValidation.email"
                          class="invalid-feedback"
                        >
                          {{ $t('fields.required') }}
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-group
                        class="mb-3"
                        :label="$t('customerForm.phone')"
                        label-for="formrow-password-input"
                      >
                        <tel-input
                          v-model="team.phone"
                          :is-invalid="editFormSubmitted && userValidation.phone"
                          :class="{
                            'is-invalid': editFormSubmitted && userValidation.phone,
                          }"
                        />
                        <div
                          v-if="editFormSubmitted && userValidation.phone"
                          class="invalid-feedback"
                        >
                          {{ $t('fields.required') }}
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-group
                        class="mb-3"
                        :label="$t('customerForm.currency')"
                        label-for="formrow-text-input"
                      >
                        <multiselect
                          id="currency"
                          v-model="team.currency"
                          :show-labels="false"
                          :placeholder="$t('buttons.placeHolderSelect')"
                          :options="$_CURRENCIES"
                          :class="{
                            'is-invalid': editFormSubmitted && userValidation.currency,
                          }"
                        ></multiselect>
                        <div
                          v-if="editFormSubmitted && userValidation.currency"
                          class="invalid-feedback"
                        >
                          {{ $t('fields.required') }}
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-group class="mb-3" :label="$t('teams.profile.timezone')">
                        <timezone-list v-model="team.timezone" />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="form-group">
                      <b-form-group class="mb-3" :label="$t('teams.profile.address')">
                        <Address v-model="team.address" />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-group
                        class="mb-3"
                        :label="$t('customerForm.slug')"
                        label-for="formrow-text-input"
                      >
                        <div
                          :data-tooltip="$t('customerForm.slug_hint')"
                          style="position: absolute; top: 0; left: 45px"
                        >
                          <i
                            class="mdi mdi-information-outline me-2"
                            style="font-size: 14px; color: orange"
                          ></i>
                        </div>
                        <b-form-input
                          id="formrow-text-input"
                          v-model="team.slug"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': editFormSubmitted && userValidation.slug,
                          }"
                        >
                        </b-form-input>
                        <div
                          v-if="editFormSubmitted && userValidation.slug"
                          class="invalid-feedback"
                        >
                          {{ $t('fields.required') }}
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label>{{ $t('teams.profile.contract_text') }}</label>
                      <div>
                        <ckeditor
                          id="agreement"
                          v-model="team.agreement"
                          :editor="editor"
                          :config="editorConfig"
                        ></ckeditor>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <b-button type="submit" variant="primary">{{
                    $t('teams.profile.update')
                  }}</b-button>
                </div>
              </form>
            </b-form>
          </div>
          <b-alert :show="team === null" variant="warning" class="mt-3 mb-3 text-center">
            {{ $t('teams.profile.warning') }}
          </b-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import { mapState, mapGetters } from 'vuex'
import PageHeader from '@/components/page-header'
import { GET_TEAM, UPDATE_TEAM } from '../../queries'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import TimezoneList from '@/components/widgets/timezone-list.vue'
import { uploadService } from '@/helpers/upload.service'

/**
 * Dashboard Component
 */
export default {
  name: 'Team',
  page: {
    title: 'company_information',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Multiselect,
    PageHeader,
    ckeditor: CKEditor.component,
    TimezoneList,
  },
  data() {
    return {
      title: this.$t('teams.profile.company_information'),
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ['bold', 'italic', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList'],
      },
      items: [
        {
          text: this.$t('teams.profile.company_information'),
          href: '/',
        },
        {
          text: this.$t('teams.profile.edit'),
          active: true,
        },
      ],
      showModal: false,
      editFormSubmitted: false,
      userValidation: {
        name: false,
        phone: false,
        email: false,
        currency: false,
        slug: false,
      },
      imageFile: null,
    }
  },
  computed: {
    ...mapState({
      auth: 'auth',
    }),
    ...mapGetters({
      companyCurrency: 'currency/companyCurrency',
    }),
  },
  created() {
    this.$_loading()
  },
  methods: {
    update() {
      this.editFormSubmitted = true
      this.$loading(true)
      let notValid = false
      Object.keys(this.team).forEach((el) => {
        if (this.userValidation[el] !== undefined) {
          if (!this.team[el] && !this.userValidation[el]) this.userValidation[el] = true
          if (this.team[el] && this.userValidation[el]) this.userValidation[el] = false
        }
      })
      Object.values(this.userValidation).forEach((el) => {
        if (el) notValid = true
      })
      if (notValid) {
        this.$loading(false)
        return
      }
      this.$apollo
        .mutate({
          mutation: UPDATE_TEAM,
          variables: {
            team: this.$_ER(this.team, ['__typename']),
            id: this.team.id,
          },
          update: () => {
            try {
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$loading(false)
              // change company currency unit
              this.$store.commit('currency/setCompanyCurrency', this.team.currency)
              this.$store.commit('location/setTimezone', this.team.timezone)
              moment.tz.setDefault(this.team.timezone)
            } catch (e) {
              this.$_N({
                type: 'error',
                position: 'center',
              })
              this.$loading(false)
            }
          },
        })
        .catch((error) => {
          console.log(error)
          console.log(error.message)
          this.$loading(false)
          if (error.message.includes('teams_slug_key')) {
            this.$_N({
              type: 'error',
              position: 'center',
              title: this.$t('customerForm.slug_taken'),
            })
          } else {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        })
    },
    async fileUpload(file) {
      this.$loading(true)
      try {
        const logo = await uploadService({
          file: file,
          name: this.team.name + '-logo',
          team_id: this.auth.user.team_id,
        })
        this.team.logo = logo
      } catch (err) {
        this.$_N({
          type: 'error',
          position: 'center',
        })
      }
      this.$loading(false)
    },
  },
  apollo: {
    team: {
      fetchPolicy: 'no-cache',
      query: GET_TEAM,
      variables() {
        return {
          id: this.auth.user.team_id,
        }
      },
    },
  },
}
</script>
