<template>
  <div v-if="closed_dates">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="sort_by" class="form-select">
              <b-form-select-option :value="{ created_at: 'desc' }">{{
                $t('sort.newest')
              }}</b-form-select-option>
              <b-form-select-option :value="{ created_at: 'asc' }">{{
                $t('sort.oldest')
              }}</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto">
            <b-input
              id="inlineFormInputName2"
              v-model="search_input"
              :placeholder="$t('fields.search')"
              @input="$apollo.queries.closed_dates.stop()"
            ></b-input>
          </div>
          <div class="col-sm-auto">
            <b-button variant="success" @click="filter">{{
              $t('buttons.placeHolderSearch')
            }}</b-button>
          </div>
          <div class="col-sm-auto">
            <b-button variant="danger" @click="clear">{{ $t('buttons.reset') }}</b-button>
          </div>
        </b-form>
        <div class="col-3 d-flex">
          <button class="btn btn-success ms-auto mb-2" @click="updateModal = true">
            {{ $t('close_dates.add_closed_date') }}
          </button>
        </div>
      </div>
    </div>
    <!-- table  -->
    <div class="col-md-12 col-xs-12">
      <div class="card">
        <div class="card-body">
          <div class="overflow-auto">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>{{ $t('close_dates.table.start') }}</th>
                  <th>{{ $t('close_dates.table.end') }}</th>
                  <th>{{ $t('close_dates.table.location') }}</th>
                  <th>{{ $t('close_dates.table.description') }}</th>
                  <th>{{ $t('locations.inputs.actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(locationItem, key) in closed_dates" :key="key">
                  <td>{{ moment(locationItem.start_date).format('ddd, DD MMM YYYY') }}</td>
                  <td>
                    {{ moment(locationItem.end_date).format('ddd, DD MMM YYYY') }}
                  </td>
                  <td>
                    <span v-for="(item, index) in locationItem.locations" :key="item.id">
                      {{ item.location.name }}
                      <span v-show="locationItem.locations.length - 1 > index">,</span>
                    </span>
                  </td>
                  <td>{{ locationItem.description }}</td>
                  <td>
                    <div class="button-items">
                      <button
                        type="button"
                        class="btn btn-warning btn-sm"
                        @click="setUpdateModal(locationItem)"
                      >
                        <i class="bx bx-edit font-size-16 align-middle me-1"></i>
                        {{ $t('buttons.edit') }}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-alert :show="closed_dates.length === 0" variant="warning" class="text-center mt-3">
              {{ $t('lists.no_record') }}
            </b-alert>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-3">
      <p>
        {{ $t('case.total') }} <b>{{ closed_dates.length }}</b>
      </p>
    </div>

    <!-- edit close dates modal -->
    <add-close-dates
      :closed-dates-modal="updateModal"
      :update-item-data="updateItem"
      @close="
        updateModal = false
        updateItem = {}
      "
      @submitted="$apollo.queries.closed_dates.refresh()"
    />
  </div>
</template>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import { mapState } from 'vuex'
import { GET_CLOSE_DATES } from '@/router/queries'
import moment from 'moment'
import AddCloseDates from '@/components/widgets/addCloseDates.vue'

export default {
  name: 'CloseDates',
  page: {
    title: 'close_dates',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    AddCloseDates,
  },
  data() {
    return {
      title: this.$t('title.close_dates'),
      updateModal: false,
      updateItem: {},
      items: [
        {
          text: this.$t('title.close_dates'),
          href: '/',
        },
      ],
      per_page: 10,
      page: 1,
      sort_by: { created_at: 'desc' },
      search_input: '',
      locationIsValid: true,
      moment,
    }
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
    totalCount() {
      return this.closed_dates.length
    },
  },
  created() {
    this.$_loading()
  },
  apollo: {
    closed_dates: {
      fetchPolicy: 'no-cache',
      query: GET_CLOSE_DATES,
      variables() {
        return {
          offset: this.offset,
          limit: this.per_page,
          order_by: this.sort_by,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
          },
        }
      },
    },
  },
  methods: {
    setUpdateModal(closedDate) {
      this.updateModal = true
      this.updateItem = closedDate
    },
    filter() {
      this.$apollo.queries.closed_dates.start()
      this.$_loading()
    },
    clear() {
      this.per_page = 10
      this.sort_by = { created_at: 'desc' }
      this.search_input = ''
      this.$apollo.queries.closed_dates.refresh()
      this.$_loading()
    },
  },
}
</script>
