<template>
  <div v-if="tags && contact_tags">
    <div class="row">
      <div class="col-12">
        <b-tabs content-class="p-3" nav-class="nav-tabs-custom">
          <b-tab :title="$t('customerCard.tag')" active>
            <form @submit.prevent="newContactTag()">
              <multiselect
                v-model="newContactTags.tag_id"
                :placeholder="$t('buttons.placeHolderSelect')"
                :show-labels="false"
                :options="tags.map((x) => x.id)"
                :custom-label="(opt) => tags.find((x) => x.id == opt).name"
              >
                :multiple="false" ></multiselect
              >
              <b-button
                v-if="newContactTags.tag_id !== null"
                class="mt-3"
                type="submit"
                variant="primary"
                >{{ $t('buttons.save') }}</b-button
              >
            </form>
          </b-tab>
          <b-tab :title="$t('customerCard.newTag')">
            <form @submit.prevent="newTag()">
              <input
                id="name"
                v-model="newTags.name"
                type="text"
                class="form-control mb-3"
                :placeholder="$t('customerCard.tagName')"
                :class="{
                  'is-invalid': submitted && $v.newTags.name.$error,
                }"
              />
              <div v-if="submitted && !$v.newTags.name.required" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
              <b-button type="submit" class="mt-1" variant="primary">{{
                $t('buttons.save')
              }}</b-button>
            </form>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h5 style="color: rgb(13, 110, 253); font-size: 15px">
          {{ $t('customerCard.customerTag') }}
        </h5>
        <hr />
        <span
          v-for="(tag, key) in contact_tags"
          :key="key"
          class="badge badge-pill badge-soft-success font-size-14 m-1 p-2"
        >
          <span style="color: rgb(13, 110, 253)">{{ tag.tag.name }}</span>
          <button
            type="button"
            style="font-size: 8px"
            class="btn-close"
            aria-label="Close"
            @click="remove(tag)"
          ></button>
        </span>
        <b-alert v-if="!contact_tags.length" show class="text-center" variant="warning">{{
          $t('customerCard.customerTagNoRecord')
        }}</b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import {
  INSERT_TAG,
  GET_TAGS,
  INSERT_CONTACT_TAG,
  GET_CONTACT_TAGS,
  DELETE_CONTACT_TAGS,
} from '@/router/queries'
import Multiselect from 'vue-multiselect'

export default {
  name: 'TagsTab',
  components: {
    Multiselect,
  },
  data() {
    return {
      newTags: null,
      newContactTags: null,
      submitted: false,
    }
  },
  computed: {
    ...mapState(['auth']),
    contact_tag_mapped() {
      const mapped = this.contact_tags.map((x) => x.tag_id)
      return mapped
    },
  },
  validations: {
    newTags: {
      name: { required },
    },
  },
  created() {
    this.newTags = {
      name: null,
      user_id: this.auth.user.id,
      team_id: this.auth.user.team_id,
    }
    this.newContactTags = {
      tag_id: null,
      contact_id: this.$route.params.id,
      team_id: this.auth.user.team_id,
      user_id: this.auth.user.id,
    }
  },
  methods: {
    newTag() {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } else {
        this.$loading(true)
        this.$apollo.mutate({
          mutation: INSERT_TAG,
          variables: {
            newTag: this.newTags,
          },
          update: (cache, { data: { insert_tags_one } }) => {
            this.$apollo.mutate({
              mutation: INSERT_CONTACT_TAG,
              variables: {
                newContactTag: {
                  tag_id: insert_tags_one.id,
                  contact_id: this.$route.params.id,
                  team_id: this.auth.user.team_id,
                  user_id: this.auth.user.id,
                },
              },
              update: () => {
                this.$_N({
                  type: 'success',
                  position: 'center',
                })
                this.$apollo.queries.tags.refresh()
                this.$apollo.queries.contact_tags.refresh()
                this.$loading(false)
                this.clear()
              },
            })
          },
        })
        this.submitted = false
      }
    },
    newContactTag() {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: INSERT_CONTACT_TAG,
        variables: {
          newContactTag: this.newContactTags,
        },
        update: () => {
          try {
            this.$apollo.queries.contact_tags.refresh()
            this.$loading(false)
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.newContactTags = {
              tag_id: null,
              contact_id: this.$route.params.id,
              team_id: this.auth.user.team_id,
              user_id: this.auth.user.id,
            }
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
    },
    remove(tag) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: DELETE_CONTACT_TAGS,
        variables: {
          id: tag.id,
        },
        update: () => {
          try {
            this.$apollo.queries.contact_tags.refresh()
            this.$loading(false)
            this.$_N({
              type: 'success',
              position: 'center',
            })
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
    },
    clear() {
      this.newTags = {
        name: null,
        user_id: this.auth.user.id,
        team_id: this.auth.user.team_id,
      }
      this.newContactTags = {
        tag_id: null,
        contact_id: this.$route.params.id,
        team_id: this.auth.user.team_id,
        user_id: this.auth.user.id,
      }
    },
  },
  apollo: {
    tags: {
      fetchPolicy: 'no-cache',
      query: GET_TAGS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
    contact_tags: {
      fetchPolicy: 'no-cache',
      query: GET_CONTACT_TAGS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.auth.user.id,
            },
            contact_id: {
              _eq: this.$route.params.id,
            },
          },
        }
      },
    },
  },
}
</script>
