<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div
      v-if="contacts_aggregate && users_aggregate && events_aggregate && income && expense"
      class="row"
    >
      <div class="col-xl-8">
        <div class="row">
          <div class="col-lg-4">
            <router-link to="/contacts" tag="div" class="card mini-stats-wid">
              <div class="card-body">
                <div class="d-flex flex-wrap">
                  <div class="me-3">
                    <div class="avatar-sm mb-2">
                      <div class="avatar-title dashboard-icon-primary rounded-circle font-size-22">
                        <i class="bx bxs-user"></i>
                      </div>
                    </div>
                    <p class="mb-2">
                      {{ $t('dashboard.customer') }}
                    </p>
                    <h4 class="mb-0">
                      {{ contacts_aggregate.aggregate.count }}
                    </h4>
                  </div>

                  <div class="avatar-sm align-self-end ms-auto">
                    <div class="avatar-title dashboard-icon-light rounded-circle font-size-24">
                      <i class="bx bx-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-lg-4">
            <router-link to="/calendar" tag="div" class="card mini-stats-wid">
              <div class="card-body">
                <div class="d-flex flex-wrap">
                  <div class="me-3">
                    <div class="avatar-sm mb-2">
                      <div class="avatar-title dashboard-icon-primary rounded-circle font-size-22">
                        <i class="bx bx-calendar-check"></i>
                      </div>
                    </div>
                    <p class="mb-2">
                      {{ $t('dashboard.appointment') }}
                    </p>
                    <h4 class="mb-0">
                      {{ events_aggregate.aggregate.count }}
                    </h4>
                  </div>

                  <div class="avatar-sm align-self-end ms-auto">
                    <div class="avatar-title dashboard-icon-light rounded-circle font-size-24">
                      <i class="bx bx-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-lg-4">
            <router-link to="/staff" tag="div" class="card mini-stats-wid">
              <div class="card-body">
                <div class="d-flex flex-wrap">
                  <div class="me-3">
                    <div class="avatar-sm mb-2">
                      <div class="avatar-title dashboard-icon-primary rounded-circle font-size-22">
                        <i class="bx bxs-user-badge"></i>
                      </div>
                    </div>
                    <p class="mb-2">
                      {{ $t('dashboard.staff') }}
                    </p>
                    <h4 class="mb-0">
                      {{ users_aggregate.aggregate.count }}
                    </h4>
                  </div>

                  <div class="avatar-sm align-self-end ms-auto">
                    <div class="avatar-title dashboard-icon-light rounded-circle font-size-24">
                      <i class="bx bx-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <!-- end row -->

        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="mt-1">
                <h4 class="card-title">
                  {{ $t('dashboard.today_appointment') }}
                </h4>
                <div style="overflow-x: auto">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('tables.customer') }}</th>
                        <th scope="col">{{ $t('tables.status') }}</th>
                        <th scope="col">{{ $t('tables.date') }}</th>
                        <th scope="col">{{ $t('tables.action') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(event, key) in today_appointment" :key="key">
                        <td>
                          {{ event.contact.first_name + ' ' + event.contact.last_name }}
                        </td>
                        <td>{{ event.event_status.name }}</td>
                        <td>
                          {{ moment(event.start).format('LLL') }}
                        </td>
                        <td>
                          <b-button
                            variant="success btn-sm"
                            @click="
                              $router.push({
                                name: 'agenda.detail',
                                params: { key: event.id },
                              })
                            "
                            ><i class="bx bx-search-alt font-size-16 align-middle me-1"></i
                            >{{ $t('buttons.show') }}</b-button
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <b-alert v-if="!today_appointment.length" show variant="warning">{{
                  $t('tableInfo.noAppointmentToday')
                }}</b-alert>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="mt-1">
                  <h4 class="card-title">{{ $t('dashboard.tomorrow_appointment') }}</h4>
                  <div style="overflow-x: auto">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">{{ $t('tables.customer') }}</th>
                          <th scope="col">{{ $t('tables.status') }}</th>
                          <th scope="col">{{ $t('tables.date') }}</th>
                          <th scope="col">{{ $t('tables.action') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(event, key) in tomorrow_appointment" :key="key">
                          <td>
                            {{ event.contact.first_name + ' ' + event.contact.last_name }}
                          </td>
                          <td>{{ event.event_status.name }}</td>
                          <td>
                            {{ moment(event.start).format('LLL') }}
                          </td>
                          <td>
                            <b-button
                              variant="success btn-sm"
                              @click="
                                $router.push({
                                  name: 'agenda.detail',
                                  params: { key: event.id },
                                })
                              "
                              ><i class="bx bx-search-alt font-size-16 align-middle me-1"></i
                              >{{ $t('buttons.show') }}</b-button
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <b-alert v-if="!tomorrow_appointment.length" show variant="warning">{{
                    $t('tableInfo.noAppointmentTomorrow')
                  }}</b-alert>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="mt-1">
                <h4 class="card-title">{{ $t('dashboard.daily_income') }}</h4>
                <div style="overflow-x: auto">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('tables.customer') }}</th>
                        <th scope="col">{{ $t('tables.desc') }}</th>
                        <th scope="col">{{ $t('tables.payment_type') }}</th>
                        <th scope="col">{{ $t('tables.amount') }}</th>
                        <th scope="col">{{ $t('tables.voucher_date') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(vault, key) in today_total_vault" :key="key">
                        <td>
                          {{ vault?.contact?.first_name + ' ' + vault?.contact?.last_name }}
                        </td>
                        <td>{{ vault.description }}</td>
                        <td>{{ vault.payment_method }}</td>
                        <td>{{ formatPrice(vault.price) }}</td>
                        <td>
                          {{ moment(vault.created_at).format('Do MMMM YYYY HH:ss') }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center" colspan="6">
                          <!-- <strong
                          >Toplam Tutaxr:
                          {{ formatPrice(today_total_price.aggregate.sum.price) }}</strong
                        > -->
                          <a v-if="today_total_vault.length" href="/reporting/credit"
                            >{{ $t('dashboard.view_all') }} <i class="bx bx-right-arrow-alt"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <b-alert v-if="!today_total_vault.length" show variant="warning">{{
                  $t('tableInfo.noPaymentToday')
                }}</b-alert>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="mt-1">
                <h4 class="card-title">{{ $t('dashboard.daily_expense') }}</h4>
                <div style="overflow-x: auto">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('tables.by_spending') }}</th>
                        <th scope="col">{{ $t('tables.desc') }}</th>
                        <th scope="col">{{ $t('tables.payment_type') }}</th>
                        <th scope="col">{{ $t('tables.amount') }}</th>
                        <th scope="col">{{ $t('tables.voucher_date') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(vault, key) in today_expense_vault" :key="key">
                        <td>
                          {{
                            vault.contact_id !== null
                              ? vault.contact.first_name + ' ' + vault.contact.last_name
                              : $t('dashboard.fail')
                          }}
                        </td>
                        <td>{{ vault.description }}</td>
                        <td>{{ vault.payment_method }}</td>
                        <td>{{ formatPrice(vault.price) }}</td>
                        <td>
                          {{ moment(vault.created_at).format('Do MMMM YYYY HH:ss') }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center" colspan="6">
                          <!--<strong
                          >{{ $t('tables.total_amount') }}:
                          {{
                           formatPrice(today_expense_price.aggregate.sum.price)
                          }}
                          </strong
                        >-->
                          <a v-if="today_expense_vault.length" href="/reporting/credit"
                            >{{ $t('dashboard.view_all') }} <i class="bx bx-right-arrow-alt"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <b-alert v-if="!today_expense_vault.length" show variant="warning">{{
                  $t('tableInfo.noExpenseToday')
                }}</b-alert>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ $t('dashboard.daily_packages') }}</h4>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">{{ $t('tables.customer') }}</th>
                    <th scope="col">{{ $t('tables.package') }}</th>
                    <th scope="col">{{ $t('tables.amount') }}</th>
                    <th scope="col">{{ $t('tables.buy_date') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(bundle, key) in bundles" :key="key">
                    <td>
                      {{ bundle.contact.first_name + ' ' + bundle.contact.last_name }}
                    </td>
                    <td>
                      {{ bundle.name }}
                    </td>
                    <td>{{ formatPrice(bundle.price) }}</td>
                    <td>
                      {{ moment(bundle.created_at).format('DD/MM/YYYY HH:mm') }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center" colspan="4">
                      <!-- <strong
                   >Toplam Tutaxr:
                   {{ formatPrice(today_total_price.aggregate.sum.price) }}</strong
                 > -->
                      <a v-if="bundles.length" href="/reporting/bundles"
                        >{{ $t('dashboard.view_all') }}<i class="bx bx-right-arrow-alt"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-alert v-if="!bundles.length" show variant="warning">{{
                $t('tableInfo.noSalesToday')
              }}</b-alert>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="mt-1">
                <h4 class="card-title">{{ $t('dashboard.due_payments') }}</h4>
                <div style="overflow-x: auto">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('tables.customer') }}</th>
                        <th scope="col">{{ $t('tables.package') }}</th>
                        <th scope="col">{{ $t('tables.collection') }}</th>
                        <th scope="col">{{ $t('tables.payment_date') }}</th>
                        <th scope="col">{{ $t('tables.action') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(payment, key) in bundle_payments" :key="key">
                        <td>
                          {{ payment.contact.first_name + ' ' + payment.contact.last_name }}
                        </td>
                        <td>{{ payment.bundle.name }}</td>
                        <td>{{ formatPrice(payment.price) }}</td>
                        <td>
                          <p
                            v-if="
                              moment(payment.date).format('Do MMMM YYYY') ===
                              moment().format('Do MMMM YYYY')
                            "
                            class="text-success"
                          >
                            {{ moment(payment.date).format('Do MMMM YYYY') }}
                          </p>
                          <p
                            v-if="
                              moment(payment.date).format('Do MMMM YYYY') !==
                              moment().format('Do MMMM YYYY')
                            "
                            class="text-danger"
                          >
                            {{ moment(payment.date).format('Do MMMM YYYY') }} -
                            {{ $t('tableInfo.aged_debt') }}
                            <i class="bx bxs-info-circle bx-flashing bx-flip-vertical"></i>
                          </p>
                        </td>
                        <td>
                          <b-button
                            variant="success btn-sm"
                            @click="
                              $router.push({
                                name: 'bundle.show',
                                params: { key: payment.bundle.id },
                              })
                            "
                            ><i class="bx bx-search-alt font-size-16 align-middle me-1"></i
                            >{{ $t('buttons.show') }}</b-button
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center" colspan="7">
                          <!--<strong
                          >Alacak:
                          {{ formatPrice(total_bundle_price.aggregate.sum.price) }}</strong
                        >-->
                          <a v-if="bundle_payments.length" href="/reporting/due-payment"
                            >{{ $t('dashboard.view_all') }} <i class="bx bx-right-arrow-alt"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <b-alert v-if="!bundle_payments.length" show variant="warning">{{
                  $t('tableInfo.noPaymentToday')
                }}</b-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->

      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="me-3">
                <img
                  src="@/assets/images/users/avatar-1.jpg"
                  alt=""
                  class="avatar-sm rounded-circle img-thumbnail"
                />
              </div>
              <div class="media-body">
                <div class="media">
                  <div class="media-body">
                    <div class="text-muted">
                      <h5 class="mb-1">
                        {{ team ? team.name : 'İşletme Adı' }}
                      </h5>
                      <p class="mb-0">
                        {{ team ? team.tax_title : 'İşletme Ünvanı' }} -
                        {{ team ? team.tax_number : 'Vergi No' }}
                      </p>
                    </div>
                  </div>

                  <b-dropdown
                    toggle-class="btn-sm"
                    variant="light"
                    right
                    class="ms-2"
                    menu-class="dropdown-menu-end"
                  >
                    <template #button-content>
                      <i class="bx bxs-cog align-middle me-1"></i>
                      {{ $t('buttons.settings') }}
                    </template>
                    <router-link
                      v-slot="{ navigate }"
                      class="dropdown-item"
                      to="/teams/profile"
                      custom
                    >
                      <span
                        style="cursor: pointer"
                        role="link"
                        @click="navigate"
                        @keypress.enter="navigate"
                        >{{ $t('buttons.company') }}</span
                      >
                    </router-link>
                    <router-link v-slot="{ navigate }" class="dropdown-item" to="/staff" custom>
                      <span
                        style="cursor: pointer"
                        role="link"
                        @click="navigate"
                        @keypress.enter="navigate"
                        >{{ $t('buttons.staff') }}</span
                      >
                    </router-link>
                  </b-dropdown>
                </div>
                <hr />
                <div class="row">
                  <div class="col-6">
                    <div>
                      <p class="text-muted mb-2">
                        {{ $t('dashboard.total_income') }}
                      </p>
                      <h5 class="mb-0">
                        {{
                          income.aggregate.sum.price !== null
                            ? formatPrice(income.aggregate.sum.price)
                            : formatPrice(0)
                        }}
                      </h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div>
                      <p class="text-muted mb-2">
                        {{ $t('dashboard.total_expense') }}
                      </p>
                      <h5 class="mb-0">
                        {{
                          expense.aggregate.sum.price !== null
                            ? formatPrice(expense.aggregate.sum.price)
                            : formatPrice(0)
                        }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="/packages">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-wrap">
                <h5 class="card-title mb-2 me-2">
                  {{ $t('dashboard.subscription') }}
                </h5>
              </div>

              <div class="d-flex flex-wrap">
                <div v-if="expire_barespace < 0">
                  <p class="text-muted mb-2">
                    {{ $t('dashboard.package_alert') }}
                  </p>
                  <b-progress :value="expire_barespace" :max="max" animated></b-progress>
                </div>
                <div v-if="expire_barespace > 0">
                  <p class="text-muted mb-2">
                    {{ $t('dashboard.package_info') }}
                    <span class="text-dark">{{ expire_barespace }}</span>
                  </p>
                  <b-progress :value="expire_barespace" :max="max" animated></b-progress>
                </div>
                <div class="ms-auto align-self-end">
                  <i class="bx bx-buildings display-4 text-light"></i>
                </div>
              </div>
            </div>
          </div>
          <b-alert variant="warning" :show="expire_barespace <= 3">
            {{ $t('dashboard.package_alert2') }}
            <b-button to="/packages" class="ml-3 btn-sm" variant="outline-success">{{
              $t('buttons.renew')
            }}</b-button>
          </b-alert>
        </a>
        <a href="/packages/sms">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-wrap">
                <h5 class="card-title mb-2 me-2">{{ $t('dashboard.sms') }}</h5>
              </div>

              <div class="d-flex flex-wrap">
                <div v-if="team.sms_count <= 0">
                  <p class="text-muted mb-2" style="color: #e43a16 !important">
                    {{ $t('dashboard.sms_alert') }}
                  </p>
                  <b-progress :value="team.sms_count" :max="max_sms" animated></b-progress>
                </div>
                <div v-if="team.sms_count > 0">
                  <p class="text-muted mb-2">
                    {{ $t('dashboard.sms_info') }}:
                    <span class="text-dark">{{ team.sms_count }}</span>
                  </p>
                  <b-progress :value="team.sms_count" :max="max_sms" animated></b-progress>
                </div>
                <div class="ms-auto align-self-end">
                  <i class="bx bx-message-dots display-4 text-light"></i>
                </div>
              </div>
            </div>
          </div>
        </a>
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-wrap">
              <h5 class="card-title mb-2 me-2">
                {{ $t('dashboard.documents') }}
              </h5>
            </div>
            <p class="text-muted">
              {{ $t('dashboard.supportInfo') }}
              <a target="_blank" href="https://docs.barespace.app">{{
                $t('dashboard.supportLink')
              }}</a>
            </p>
          </div>
        </div>
        <b-alert variant="warning" :show="team.notification_control != true">
          Otomatik bildirimler kapalı.
          <b-button to="/notifications" class="ml-3 btn-sm" variant="outline-success"
            >Bildirimleri Aç</b-button
          >
        </b-alert>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
</template>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import { GET_BUNDLES, GET_TEAM, GET_DASHBOARD_DATA, GET_PERMISSION } from '../../queries'
import { mapState } from 'vuex'
import moment from 'moment'

/**
 * Dashboard Component
 */
export default {
  page: {
    title: 'dashboard',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: { PageHeader },
  data() {
    return {
      title: this.$t('dashboard.welcome'),
      moment,
      per_page: 5,
      sort_by: { created_at: 'desc' },
      vault_order_by: { created_at: 'desc' },
      page: 1,
      buy_date: 25,
      max: 30,
      max_sms: 100,
      search_input: '',
      start: moment().format('YYYY-MM-DD'),
      end: moment().add(1, 'days').format('YYYY-MM-DD'),
      tomarrow_start: moment().add(1, 'days').format('YYYY-MM-DD'),
      tomarrow_end: moment().add(2, 'days').format('YYYY-MM-DD'),
      tomorrow_appointment: null,
      today_total_vault: null,
      today_expense_vault: null,
      today_total_price: null,
      today_expense_price: null,
      bundle_payments: null,
      total_bundle_price: null,
      contacts_aggregate: null,
      events_aggregate: null,
      users_aggregate: null,
      income: null,
      expense: null,
      team: null,
      items: [
        {
          text: moment().format('Do MMMM YYYY'),
        },
        {
          text: moment().format('dddd'),
          active: true,
        },
      ],
      staff_locations: undefined,
    }
  },
  computed: {
    ...mapState(['auth']),
    today: function () {
      const now = moment().format('Do MMMM YYYY')
      return now
    },
    expire_barespace: function () {
      // eslint-disable-next-line no-unused-vars
      const countDownDate = new Date(this.team.subscription_expires).getTime()
      const now = new Date().getTime()
      const distance = countDownDate - now
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      if (days <= 0) {
        // eslint-disable-next-line no-console
        this.redirect()
      }
      return days
    },
    offset() {
      return (this.page - 1) * this.per_page
    },
    name: function () {
      return this.auth.user.email
    },
  },
  watch: {
    subs(val) {
      this.team.subscription_expires = val
    },
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  created() {
    this.$forceUpdate()
    this.$_loading()
  },
  methods: {
    redirect() {
      this.$router.push({ name: 'expired' })
    },
  },
  apollo: {
    team: {
      fetchPolicy: 'no-cache',
      query: GET_TEAM,
      variables() {
        return {
          id: this.auth.user.team_id,
        }
      },
    },
    today_appointment: {
      fetchPolicy: 'no-cache',
      query: GET_DASHBOARD_DATA,
      variables() {
        return {
          today_offset: this.offset,
          today_limit: this.per_page,
          today_order_by: this.sort_by,
          vault_order_by: this.vault_order_by,
          today_where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            start: {
              _gte: this.start,
              _lte: this.end,
            },
            location_id: {
              _in: this.staff_locations,
            },
          },
          tomorrow_where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            start: {
              _gte: this.tomarrow_start,
              _lte: this.tomarrow_end,
            },
            location_id: {
              _in: this.staff_locations,
            },
          },
          vault_activities_where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            date: {
              _gt: this.start,
            },
            type: {
              _eq: 'income',
            },
            deleted_by: { _is_null: true },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.staff_locations,
                },
              },
            },
          },
          vault_activities_expense_where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            date: {
              _gt: this.start,
            },
            type: {
              _eq: 'expense',
            },
            deleted_by: { _is_null: true },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.staff_locations,
                },
              },
            },
          },
          bundle_where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            status: {
              _eq: 'PENDING',
            },
            _and: [
              { date: { _gte: moment().add(-3, 'year').format('YYYY-MM-DD') } },
              { date: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
            ],
            contact: {
              location_contacts: {
                location_id: {
                  _in: this.staff_locations,
                },
              },
            },
          },
          income_where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            type: {
              _eq: 'income',
            },
            deleted_by: { _is_null: true },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.staff_locations,
                },
              },
            },
          },
          expense_where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            type: {
              _eq: 'expense',
            },
            deleted_by: { _is_null: true },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.staff_locations,
                },
              },
            },
          },
          user_where: {
            role: {
              _eq: 'user',
            },
            location_staffs: {
              location_id: {
                _in: this.staff_locations || undefined,
              },
            },
          },
          contact_where: {
            location_contacts: {
              location_id: {
                _in: this.staff_locations,
              },
            },
          },
          event_where: {
            location_id: {
              _in: this.staff_locations,
            },
          },
          id: this.auth.user.team_id,
        }
      },
      result({ data }) {
        this.tomorrow_appointment = data.tomorrow_appointment
        this.today_total_vault = data.today_total_vault
        this.today_total_price = data.today_total_price
        this.today_expense_price = data.today_expense_price
        this.today_expense_vault = data.today_expense_vault
        this.bundle_payments = data.bundle_payments
        this.total_bundle_price = data.total_bundle_price
        this.contacts_aggregate = data.contacts_aggregate
        this.events_aggregate = data.events_aggregate
        this.users_aggregate = data.users_aggregate
        this.income = data.income
        this.expense = data.expense
        this.team = data.team
      },
    },
    bundles: {
      fetchPolicy: 'no-cache',
      query: GET_BUNDLES,
      variables() {
        return {
          limit: this.per_page,
          offset: this.offset,
          where: {
            _and: [
              { created_at: { _gte: moment().format('YYYY-MM-DD') } },
              {
                created_at: {
                  _lte: moment().add(1, 'days').format('YYYY-MM-DD'),
                },
              },
            ],
            contact: {
              location_contacts: {
                location_id: {
                  _in: this.staff_locations,
                },
              },
            },
          },
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped></style>
