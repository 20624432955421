<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">Per Page...</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="sort_by" class="form-select">
              <b-form-select-option :value="{ created_at: 'desc' }"
                >Date Descending</b-form-select-option
              >
              <b-form-select-option :value="{ created_at: 'asc' }"
                >Date Ascending</b-form-select-option
              >
            </b-form-select>
          </div>
          <div class="col-sm-auto">
            <b-input
              id="inlineFormInputName2"
              v-model="search_input"
              placeholder="Protokol adı"
            ></b-input>
          </div>
        </b-form>

        <button class="btn btn-success ms-auto mb-2" @click="showModal = true">
          Protokol Ekle
        </button>
      </div>
    </div>

    <b-modal v-model="showModal" title="Protokol Ekle" hide-footer>
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="name">Müşteri</label>
              <input
                id="name"
                v-model="eventData.title"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.eventData.title.$error,
                }"
              />
              <div v-if="submitted && !$v.eventData.title.required" class="invalid-feedback">
                This value is required.
              </div>
            </div>
          </div>

          <div class="col-6">
            <label class="control-label">Randevu Tarihi</label>
            <date-picker v-model="eventData.start" type="datetime"></date-picker>
          </div>

          <div class="col-12">
            <label class="control-label">Notlar</label>
            <textarea v-model="eventData.notes" class="form-control" rows="2"></textarea>
          </div>
        </div>

        <h4 class="card-title mt-3 pb-2 mb-3 border-bottom">{{ $t('service.services') }}</h4>

        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="name">Personel</label>
              <input
                id="name"
                v-model="eventData.title"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.eventData.title.$error,
                }"
              />
              <div v-if="submitted && !$v.eventData.title.required" class="invalid-feedback">
                This value is required.
              </div>
            </div>
          </div>

          <div class="col-6">
            <label class="control-label">{{ $t('service.service') }}</label>
            <date-picker v-model="eventData.start" type="datetime"></date-picker>
          </div>
        </div>

        <div class="text-end pt-5 mt-0">
          <b-button variant="light" @click="closeModal">{{ $t('buttons.close') }}</b-button>
          <b-button type="submit" variant="success" class="ms-1"> Randevu Oluştur </b-button>
        </div>
      </form>
    </b-modal>

    <div class="row">
      <div v-for="(event, key) in event_types" :key="key" class="col-xl-3 col-sm-6">
        <div class="card text-center">
          <div class="card-body">
            <div class="avatar-sm mx-auto mb-4">
              <span
                class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16"
                >{{ event.name.charAt(0).toUpperCase() }}</span
              >
            </div>
            <h5 class="font-size-15 mb-1">
              <a
                href="javascript: void(0);"
                class="text-dark"
                @click="
                  updateModal[event.id] = !updateModal[event.id]
                  $forceUpdate()
                "
              >
                {{ event.name }}</a
              >
            </h5>
            <small class="text-muted mb-0">{{ moment(event.created_at).from() }}</small>
          </div>
          <div class="card-footer bg-transparent border-top">
            <div class="contact-links d-flex font-size-20">
              <div class="flex-fill">
                <i
                  class="bx bx-edit"
                  style="cursor: pointer"
                  @click="
                    updateModal[event.id] = !updateModal[event.id]
                    $forceUpdate()
                  "
                ></i>
                <!-- update Modal -->
                <b-modal v-model="updateModal[event.id]" title="Protokol Düzenle" hide-footer>
                  <form @submit.prevent="update(event)">
                    <div class="row">
                      <div class="col-12">
                        <div class="mb-3">
                          <input
                            v-model="event.name"
                            type="text"
                            class="form-control"
                            placeholder="col"
                            :class="{
                              'is-invalid': submitted && $v.event_type.name.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.event_type.name.required"
                            class="invalid-feedback"
                          >
                            This value is required.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-end mt-3">
                      <b-button
                        variant="light"
                        @click="
                          updateModal[event.id] = !updateModal[event.id]
                          $forceUpdate()
                        "
                        >{{ $t('buttons.close') }}</b-button
                      >
                      <b-button type="submit" variant="success" class="ms-1">{{
                        $t('buttons.update')
                      }}</b-button>
                    </div>
                  </form>
                </b-modal>
                <!-- update Modal -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>

    <div class="row">
      <div class="col-12">
        <b-alert :show="event_types.length === 0" variant="light" class="text-center my-5">
          <p class="mb-0"><i class="mdi mdi-rocket-outline display-4"></i></p>
          Sorry, no records found
        </b-alert>

        <div class="text-center my-3">
          <p>
            Total <b>{ {{ event_types.length }} }</b>
          </p>
        </div>
      </div>
      <!-- end col-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'

import { INSERT_EVENT_TYPE, UPDATE_EVENT_TYPE } from '@/router/queries'

export default {
  page: {
    title: 'Müşteriler',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { PageHeader, DatePicker },
  data() {
    return {
      title: 'İşlemler',
      items: [
        {
          text: 'İşlemler',
          href: '/',
        },
        {
          text: 'İşlem Ara',
          active: true,
        },
      ],
      showModal: false,
      updateModal: {},
      eventData: {},
      submitted: false,
      event_type: null,
      countries: [],
      options: ['Photoshop', 'illustrator', 'Html', 'Css', 'Php', 'Java', 'Python', 'Ruby'],
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      search_input: '',
      moment,
    }
  },
  validations: {
    event_type: {
      name: { required },
    },
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
  },
  created() {
    this.$_loading()
    this.event_type = {
      name: null,
      user_id: this.auth.user.id,
      team_id: this.auth.user.team_id,
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      } else {
        this.$apollo.mutate({
          mutation: INSERT_EVENT_TYPE,
          variables: {
            event_type: this.event_type,
          },
          update: () => {
            try {
              this.$apollo.queries.event_types.refresh()
              this.$loading(false)
              this.$_N({
                position: 'center',
                type: 'success',
              })
              this.showModal = false
            } catch (e) {
              this.$_N({
                position: 'center',
                type: 'error',
              })
              this.showModal = false
              this.updateModal = false
            }
          },
        })
      }
      this.submitted = false
    },
    update(event) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_EVENT_TYPE,
        variables: {
          id: event.id,
          event: this.$_ER(event, ['__typename']),
        },
        update: () => {
          try {
            this.$loading(false)
            this.$apollo.queries.event_types.refresh()
            this.$_N({
              position: 'center',
              type: 'success',
            })
            this.updateModal = {}
          } catch (e) {
            this.$_N({
              position: 'center',
              type: 'error',
            })
          }
        },
      })
    },
  },
}
</script>
