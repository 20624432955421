var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"title":_vm.setTitle(),"hide-footer":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"product_name"}},[_vm._v(_vm._s(_vm.$t('product.product_name')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.name),expression:"product.name"}],staticClass:"form-control",class:{
                'is-invalid': _vm.submitted && _vm.$v.product.name.$error,
              },attrs:{"id":"product_name","type":"text"},domProps:{"value":(_vm.product.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.product, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.product.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"price"}},[_vm._v(_vm._s(_vm.$t('tables.price')))]),_c('b-input-group',[_c('b-form-input',{staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.product.price.$error,
                },attrs:{"id":"price","type":"number","step":"0.01","lazy-formatter":"","formatter":(val) => parseFloat(val).toFixed(2)},model:{value:(_vm.product.price),callback:function ($$v) {_vm.$set(_vm.product, "price", $$v)},expression:"product.price"}}),(_vm.submitted && !_vm.$v.product.price.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1)],1)]),_c('b-form-group',{staticClass:"mb-3",attrs:{"label":_vm.$t('title.categories')}},[_c('multiselect',{class:{
              'is-invalid': _vm.submitted && _vm.$v.selectedCategories.$error,
            },attrs:{"placeholder":_vm.$t('product.product_category'),"show-labels":false,"multiple":true,"close-on-select":false,"clear-on-select":true,"options":_vm.categories.map((x) => x.id),"custom-label":(opt) => _vm.categories.find((x) => x.id == opt).name},model:{value:(_vm.selectedCategories),callback:function ($$v) {_vm.selectedCategories=$$v},expression:"selectedCategories"}}),(_vm.submitted && !_vm.$v.selectedCategories.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1),_c('locations-input',{attrs:{"input-data":_vm.product.location_products},on:{"setLocations":_vm.setLocations}})],1),_c('div',{staticClass:"text-end mt-3"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('buttons.close')))]),_c('b-button',{staticClass:"ms-1",attrs:{"type":"submit","variant":"success"}},[_vm._v(_vm._s(_vm.$t('buttons.save')))])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }