export const menuItems = [
  // ID 8, 10, 11 Are free to use!
  {
    id: 1,
    label: 'menuitems.menu.text',
    isTitle: true,
  },

  {
    id: 2,
    label: 'menuitems.dashboards.text',
    icon: 'bx-heart',
    link: '/',
  },

  {
    id: 3,
    label: 'menuitems.dashboards.new',
    icon: 'bx-plus',
    link: '/contact/create',
  },

  {
    id: 4,
    label: 'menuitems.dashboards.search',
    icon: 'bx-search-alt',
    link: '/contacts',
  },
  {
    id: 5,
    label: 'menuitems.dashboards.booking',
    icon: 'bx-time-five',
    link: '/bookings',
  },
  {
    id: 5,
    label: 'menuitems.dashboards.calendar',
    icon: 'bx-calendar',
    link: '/calendar',
  },
  {
    id: 6,
    isLayout: true,
  },
  {
    id: 7,
    label: 'menuitems.apps.text',
    isTitle: true,
  },

  {
    id: 15,
    label: 'menuitems.accounting.text',
    icon: 'bx-briefcase',
    subItems: [
      {
        id: 16,
        label: 'menuitems.accounting.list.vault',
        link: '/accounting/case',
        parentId: 15,
      },
      {
        id: 18,
        label: 'menuitems.accounting.list.invoices',
        link: '/invoices',
        parentId: 15,
      },
    ],
  },

  {
    id: 20,
    label: 'menuitems.reporting.text',
    icon: 'bx-bar-chart-alt-2',
    subItems: [
      {
        id: 21,
        label: 'menuitems.reporting.list.graphic',
        link: '/reporting/graphic',
        parentId: 20,
      },
      {
        id: 22,
        label: 'menuitems.reporting.list.list',
        link: '/reporting/list',
        parentId: 20,
      },
      {
        id: 23,
        label: 'menuitems.reporting.list.group',
        link: '/reporting/group',
        parentId: 20,
      },
    ],
  },

  {
    id: 25,
    label: 'menuitems.marketings.text',
    icon: 'bx-share-alt',
    subItems: [
      {
        id: 26,
        label: 'menuitems.marketings.list.sms',
        link: '/marketings/sms',
        parentId: 25,
      },
      {
        id: 27,
        label: 'menuitems.marketings.list.email',
        link: '/marketings/email',
        parentId: 25,
      },
    ],
  },

  {
    id: 30,
    label: 'menuitems.systems.text',
    icon: 'bx-server',
    subItems: [
      {
        id: 31,
        label: 'menuitems.systems.list.locations',
        link: '/staff',
        parentId: 30,
      },
      {
        id: 31,
        label: 'menuitems.systems.list.staff',
        link: '/staff',
        parentId: 30,
      },
      {
        id: 32,
        label: 'menuitems.systems.list.staff_groups',
        link: '/staff-groups',
        parentId: 30,
      },
      {
        id: 33,
        label: 'menuitems.systems.list.team_detail',
        link: '/teams/profile',
        parentId: 30,
      },
      {
        id: 39,
        label: 'menuitems.systems.list.event_types',
        link: '/event-types',
        parentId: 30,
      },
      {
        id: 40,
        label: 'menuitems.systems.list.booking_settings',
        link: '/booking-settings',
        parentId: 30,
      },
      {
        id: 34,
        label: 'menuitems.systems.list.working_hours',
        link: '/working-hours',
        parentId: 30,
      },
      {
        id: 35,
        label: 'menuitems.systems.list.source_types',
        link: '/source-types',
        parentId: 30,
      },
      {
        id: 36,
        label: 'menuitems.systems.list.services',
        link: '/services',
        parentId: 30,
      },
      {
        id: 36,
        label: 'menuitems.systems.list.forms',
        link: '/forms',
        parentId: 30,
      },
      {
        id: 41,
        label: 'menuitems.systems.list.bundles',
        link: '/bundles',
        parentId: 30,
      },
      {
        id: 37,
        label: 'menuitems.systems.list.products',
        link: '/products',
        parentId: 30,
      },
      {
        id: 38,
        label: 'menuitems.systems.list.notifications',
        link: '/notifications',
        parentId: 30,
      },
    ],
  },

  {
    id: 45,
    label: 'menuitems.general.text',
    icon: 'bx-command',
    subItems: [
      {
        id: 50,
        label: 'menuitems.general.list.packages',
        link: '/packages',
        parentId: 45,
      },
      {
        id: 51,
        label: 'menuitems.general.list.data_management',
        link: '/data-sync',
        parentId: 45,
      },
      {
        id: 52,
        label: 'menuitems.general.list.status',
        link: '/status',
        parentId: 45,
      },
    ],
  },
]
