<template>
  <div>
    <Loader :loading="forms === undefined">
      <div v-if="forms">
        <b-modal
          v-model="showModal"
          :title="isUpdate ? $t('service.edit_service') : $t('service.add_service')"
          hide-footer
        >
          <form @submit.prevent="handleSubmit">
            <b-form-group class="mb-3" :label="$t('service.service_name')" for="service_name">
              <b-form-input
                id="service_name"
                v-model="service.name"
                type="text"
                :class="{
                  'is-invalid': submitted && $v.service.name.$error,
                }"
              ></b-form-input>
              <div v-if="submitted && !$v.service.name.required" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
            </b-form-group>
            <b-form-group class="mb-3" :label="$t('service.service_price')" for="service_price">
              <b-form-input
                id="service_price"
                v-model="service.price"
                type="number"
                step="0.01"
                lazy-formatter
                :formatter="(val) => parseFloat(val).toFixed(2)"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.service.price.$error,
                }"
              ></b-form-input>
              <div v-if="submitted && !$v.service.price.required" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
            </b-form-group>
            <b-form-group
              class="mb-3"
              :label="$t('service.service_duration')"
              for="service_duration"
            >
              <b-form-input
                id="service_duration"
                v-model="service.duration"
                type="number"
                :class="{
                  'is-invalid': submitted && $v.service.duration.$error,
                }"
              ></b-form-input>
              <div v-if="submitted && !$v.service.duration.required" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
            </b-form-group>
            <b-form-group :label="$t('title.categories')" class="mb-3">
              <multiselect
                v-model="selectedCategories"
                :placeholder="$t('service.service_category')"
                :show-labels="false"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="true"
                :options="categories.map((x) => x.id)"
                :custom-label="(opt) => categories.find((x) => x.id == opt).name"
                :class="{
                  'is-invalid': submitted && $v.selectedCategories.$error,
                }"
              >
              </multiselect>
              <div v-if="submitted && $v.selectedCategories.$error" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
            </b-form-group>
            <b-form-group :label="$t('staff.staff_groups')" class="mb-3">
              <multiselect
                v-model="selectedGroups"
                :placeholder="$t('service.service_category')"
                :show-labels="false"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="true"
                :options="userGroups.map((x) => x.id)"
                :custom-label="(opt) => userGroups.find((x) => x.id == opt).name"
                :class="{
                  'is-invalid': submitted && $v.selectedGroups.$error,
                }"
              >
              </multiselect>
              <div v-if="submitted && $v.selectedGroups.$error" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
            </b-form-group>
            <b-form-group label="Form" class="mb-3">
              <multiselect
                v-model="service.form_id"
                :placeholder="$t('service.service_forms')"
                :show-labels="false"
                :options="forms.map((x) => x.id)"
                :custom-label="(opt) => forms.find((x) => x.id == opt).name"
              >
              </multiselect>
            </b-form-group>
            <locations-input
              :input-data="service.location_services"
              :is-invalid="submitted && !locationIsValid"
              @setLocations="setLocations"
            />
            <div class="mt-4">
              <b-checkbox v-model="service.show_online" inline plain>
                {{ $t('service.show_online') }}
              </b-checkbox>
            </div>
            <div class="text-end mt-3">
              <b-button variant="light" @click="$emit('close')">{{ $t('buttons.close') }}</b-button>
              <b-button type="submit" variant="success" class="ms-1">{{
                $t('buttons.save')
              }}</b-button>
            </div>
          </form>
        </b-modal>
      </div>
    </Loader>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import {
  INSERT_SERVICES,
  UPDATE_SERVICE,
  ADD_SERVICES_LOCATION,
  DELETE_LOCATION_SERVICE_BY_SERVICE_ID,
  GET_FORMS,
  INSERT_SERVICE_CATEGORIES,
  UPDATE_SERVICE_CATEGORIES,
  INSERT_SERVICE_GROUPS,
  UPDATE_SERVICE_GROUPS,
} from '@/router/queries'
import Loader from './loader'

export default {
  components: {
    Loader,
  },
  props: {
    addServiceModal: {
      type: Boolean,
    },
    updateItemData: {
      type: Object,
      default: () => {},
    },
    categories: {
      type: Array,
      default: () => [],
    },
    userGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      service: null,
      submitted: false,
      isUpdate: null,
      locationChanged: false,
      locationIsValid: true,
      selectedCategories: [],
      selectedGroups: [],
    }
  },
  validations: {
    service: {
      name: { required },
      price: { required },
      duration: { required },
    },
    selectedCategories: { required },
    selectedGroups: { required },
  },
  computed: {
    ...mapState(['auth']),
    showModal: {
      get() {
        return this.addServiceModal
      },
      set() {
        this.$emit('close')
      },
    },
  },
  watch: {
    updateItemData(val) {
      if (val.id) {
        this.service = { ...val }
        this.isUpdate = true
        this.selectedCategories = this.service.service_categories.map((item) => item.categories.id)
        this.selectedGroups = this.service.service_groups.map((item) => item.group.id)
      } else {
        this.selectedCategories = []
        this.selectedGroups = []
        this.initData()
      }
    },
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.isUpdate = false
      this.service = {
        name: null,
        price: null,
        user_id: this.auth.user.id,
        team_id: this.auth.user.team_id,
        form_id: null,
        duration: null,
        location_services: {
          data: [],
        },
        show_online: true,
      }
    },
    async handleSubmit() {
      if (this.isUpdate) {
        if (this.locationChanged) {
          await this.removeLocationService()
          await this.addLocationServices()
        }
        await this.updateCategories(this.service.id)
        await this.updateGroups(this.service.id)
        this.update()
        this.locationChanged = false
        return
      }
      this.create()
    },
    setLocations(val) {
      this.locationChanged = true
      const locations = []
      val.forEach((el) => {
        locations.push({
          location_id: el.id,
          service_id: this.service.id,
          team_id: this.auth.user.team_id,
        })
      })
      this.service.location_services.data = locations
    },
    create() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.service.location_services.data.length === 0) {
        this.locationIsValid = false
      } else this.locationIsValid = true
      if (this.$v.$invalid || !this.locationIsValid) {
        this.$loading(false)
        return
      } else {
        this.$apollo.mutate({
          mutation: INSERT_SERVICES,
          variables: {
            services: this.service,
          },
          update: async (cache, result) => {
            try {
              await this.updateCategories(result.data.insert_services_one.id)
              await this.updateGroups(result.data.insert_services_one.id)
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$loading(false)
              this.$emit('close')
              this.$emit('submitted')
            } catch (e) {
              this.$_N({
                type: 'error',
                position: 'center',
              })
              this.$loading(false)
            }
          },
        })
      }
      this.submitted = false
    },
    update() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (
        this.service.location_services.data &&
        this.service.location_services.data.length === 0 &&
        this.service.location_services.length === 0
      ) {
        this.locationIsValid = false
      } else this.locationIsValid = true
      if (this.$v.$invalid || !this.locationIsValid) {
        this.$loading(false)
        return
      } else {
        this.$apollo.mutate({
          mutation: UPDATE_SERVICE,
          variables: {
            service: this.$_ER(this.service, [
              '__typename',
              'form',
              'location_services',
              'service_categories',
              'service_groups',
            ]),
            id: this.service.id,
          },
          update: () => {
            try {
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$loading(false)
              this.$emit('close')
              this.$emit('submitted')
            } catch (e) {
              this.$_N({
                type: 'error',
                position: 'center',
              })
              this.$loading(false)
            }
          },
        })
      }
    },
    async removeLocationService() {
      this.$loading(true)
      await this.$apollo
        .mutate({
          mutation: DELETE_LOCATION_SERVICE_BY_SERVICE_ID,
          variables: {
            service_id: this.service.id,
          },
        })
        .then(() => {
          return
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
        })
    },
    async addLocationServices() {
      this.$loading(true)
      await this.$apollo
        .mutate({
          mutation: ADD_SERVICES_LOCATION,
          variables: {
            locationServices: this.service.location_services.data,
          },
        })
        .then(() => {
          this.service.location_services.data = []
          return
        })
        .catch(() => {
          this.service.location_services.data = []
          this.$_N({
            type: 'error',
            position: 'center',
          })
        })
    },
    async updateCategories(service_id) {
      const mutation = this.isUpdate ? UPDATE_SERVICE_CATEGORIES : INSERT_SERVICE_CATEGORIES
      const objects = this.selectedCategories.map((category_id) => ({
        team_id: this.auth.user.team_id,
        category_id,
        service_id,
      }))
      let vars = {
        objects,
      }
      if (this.isUpdate) {
        vars = {
          where: {
            service_id: {
              _eq: this.updateItemData.id,
            },
          },
          objects,
        }
      }
      await this.$apollo
        .mutate({
          mutation,
          variables: vars,
        })
        .then(() => {
          return true
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
          return false
        })
      return
    },
    async updateGroups(service_id) {
      const mutation = this.isUpdate ? UPDATE_SERVICE_GROUPS : INSERT_SERVICE_GROUPS
      const objects = this.selectedGroups.map((group_id) => ({
        team_id: this.auth.user.team_id,
        group_id,
        service_id,
      }))
      let vars = {
        objects,
      }
      if (this.isUpdate) {
        vars = {
          where: {
            service_id: {
              _eq: this.updateItemData.id,
            },
          },
          objects,
        }
      }
      await this.$apollo
        .mutate({
          mutation,
          variables: vars,
        })
        .then(() => {
          return true
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
          return false
        })
      return
    },
  },
  apollo: {
    forms: {
      fetchPolicy: 'no-cache',
      query: GET_FORMS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
          },
        }
      },
    },
  },
}
</script>
