<template>
  <div v-if="total_income">
    <PageHeader :title="title" :items="items" />
    <div class="row mt-lg-8">
      <!-- <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h6 class="mb-3 fw-bold font-size-14">
              {{ $t('calendar.filters') }}
            </h6>
            <div class="row">
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-12 col-md-8">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <b-form-select v-model="date" class="form-select">
                  <b-form-select-option value="today">{{
                    $t('lists.date_range.today')
                  }}</b-form-select-option>
                  <b-form-select-option value="yesterday">{{
                    $t('lists.date_range.yesterday')
                  }}</b-form-select-option>
                  <b-form-select-option value="month">{{
                    $t('lists.date_range.this_month')
                  }}</b-form-select-option>
                  <b-form-select-option value="last_month">{{
                    $t('lists.date_range.last_month')
                  }}</b-form-select-option>
                  <b-form-select-option value="year">{{
                    $t('lists.date_range.this_year')
                  }}</b-form-select-option>
                  <b-form-select-option value="last_year">{{
                    $t('lists.date_range.last_month')
                  }}</b-form-select-option>
                  <b-form-select-option value="custom">{{
                    $t('lists.date_range.custom')
                  }}</b-form-select-option>
                </b-form-select>
              </div>
              <div class="col-sm-12 col-md-9">
                <multiselect
                  v-if="locations"
                  v-model="location"
                  :placeholder="$t('calendar.location_search')"
                  :show-labels="false"
                  :options="locations.map((x) => x.id)"
                  :custom-label="(opt) => locations.find((x) => x.id === opt).name"
                  @input="filterByLocation"
                >
                  <span slot="noResult">{{ $t('lists.no_record') }}</span>
                  <span slot="noOptions">{{ $t('lists.no_record') }}</span>
                </multiselect>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row mb-3">
                  <div v-if="date === 'custom'" class="col-md-3">
                    <date-picker
                      v-model="start"
                      value-type="YYYY-MM-DD"
                      format="DD/MM/YYYY"
                      :first-day-of-week="1"
                      lang="en"
                    ></date-picker>
                  </div>
                  <div v-if="date === 'custom'" class="col-md-3">
                    <date-picker
                      v-model="end"
                      value-type="YYYY-MM-DD"
                      format="DD/MM/YYYY"
                      :first-day-of-week="1"
                      lang="en"
                    ></date-picker>
                  </div>
                </div>
                <table class="table table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">{{ $t('reports.payment.total_revenue') }} :</th>
                      <td>
                        {{
                          total_income.aggregate.sum.net_price !== null
                            ? formatPrice(total_income.aggregate.sum.net_price)
                            : formatPrice(0)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th style="padding-left: 30px; font-weight: 400" scope="row">
                        {{ $t('reports.payment.card') }} :
                      </th>
                      <td>
                        {{
                          income_credi_card.aggregate.sum.net_price !== null
                            ? formatPrice(income_credi_card.aggregate.sum.net_price)
                            : formatPrice(0)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th style="padding-left: 30px; font-weight: 400" scope="row">
                        {{ $t('reports.payment.transfer') }} :
                      </th>
                      <td>
                        {{
                          income_transfer.aggregate.sum.price !== null
                            ? formatPrice(income_transfer.aggregate.sum.price)
                            : formatPrice(0)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th style="padding-left: 30px; font-weight: 400" scope="row">
                        {{ $t('reports.payment.cash') }} :
                      </th>
                      <td>
                        {{
                          income_cash.aggregate.sum.price !== null
                            ? formatPrice(income_cash.aggregate.sum.price)
                            : formatPrice(0)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th style="padding-left: 30px; font-weight: 400" scope="row">
                        {{ $t('reports.payment.voucher') }} :
                      </th>
                      <td>
                        {{
                          income_voucher.aggregate.sum.price !== null
                            ? formatPrice(income_voucher.aggregate.sum.price)
                            : formatPrice(0)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('reports.payment.total_cost') }} :</th>
                      <td>
                        {{
                          total_expense.aggregate.sum.price !== null
                            ? formatPrice(total_expense.aggregate.sum.price)
                            : formatPrice(0)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th style="padding-left: 30px; font-weight: 400" scope="row">
                        {{ $t('reports.payment.card') }} :
                      </th>
                      <td>
                        {{
                          expense_credi_card.aggregate.sum.price !== null
                            ? formatPrice(expense_credi_card.aggregate.sum.price)
                            : formatPrice(0)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th style="padding-left: 30px; font-weight: 400" scope="row">
                        {{ $t('reports.payment.transfer') }} :
                      </th>
                      <td>
                        {{
                          expense_transfer.aggregate.sum.price !== null
                            ? formatPrice(expense_transfer.aggregate.sum.price)
                            : formatPrice(0)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th style="padding-left: 30px; font-weight: 400" scope="row">
                        {{ $t('reports.payment.cash') }} :
                      </th>
                      <td>
                        {{
                          expense_cash.aggregate.sum.price !== null
                            ? formatPrice(expense_cash.aggregate.sum.price)
                            : formatPrice(0)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t('reports.payment.balance') }} :</th>
                      <td>
                        {{
                          total_income.aggregate.sum.net_price !== null
                            ? formatPrice(
                                total_income.aggregate.sum.net_price -
                                  total_expense.aggregate.sum.price
                              )
                            : formatPrice(0)
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import moment from 'moment'
import { GET_CASE_REPORTS, GET_PERMISSION, GET_LOCATIONS } from '@/router/queries'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/tr'
import { mapState } from 'vuex'

export default {
  page: {
    title: 'payment_report',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: { PageHeader, DatePicker },
  data() {
    return {
      moment,
      title: this.$t('reports.payment.title'),
      items: [
        {
          text: this.$t('reports.title'),
          href: '/',
        },
        {
          text: this.$t('reports.payment.title'),
          active: true,
        },
      ],
      income_cash: null,
      income_transfer: null,
      expense_transfer: null,
      expense_cash: null,
      total_income: null,
      expense_credi_card: null,
      total_expense: null,
      date: 'today',
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      staff_locations: undefined,
      locationFilter: undefined,
      location: false,
    }
  },
  computed: {
    ...mapState(['auth']),
    // eslint-disable-next-line vue/return-in-computed-property
    request() {
      const date = new Date()
      if (this.date === 'today') {
        const _and = [
          { created_at: { _gte: moment().format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'yesterday') {
        const _and = [
          { created_at: { _gte: moment().add(-1, 'days').format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'month') {
        const _and = [
          { created_at: { _gte: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_month') {
        const _and = [
          {
            created_at: {
              _gte: moment().month(date.getMonth()).date(1).add(-1, 'month').format('YYYY-MM-DD'),
            },
          },
          { created_at: { _lte: moment().month(date.getMonth()).date(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'week') {
        const _and = [
          { created_at: { _gte: moment().day(1).format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_week') {
        const _and = [
          { created_at: { _gte: moment().day(1).add(-7, 'days').format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().day(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'year') {
        const _and = [
          { created_at: { _gte: moment().month(0).date(1).format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'last_year') {
        const _and = [
          { created_at: { _gte: moment().month(0).date(1).add(-1, 'year').format('YYYY-MM-DD') } },
          { created_at: { _lte: moment().month(0).date(1).format('YYYY-MM-DD') } },
        ]
        return _and
      }
      if (this.date === 'custom') {
        const _and = [
          { created_at: { _gte: moment(this.start).format('YYYY-MM-DD') } },
          { created_at: { _lte: moment(this.end).add(1, 'days').format('YYYY-MM-DD') } },
        ]
        return _and
      }
    },
  },
  watch: {
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  created() {
    this.$_loading()
  },
  methods: {
    filterByLocation(location) {
      this.locationFilter = location ? location : undefined
      this.$apollo.queries.income_credi_card.refresh()
    },
  },
  apollo: {
    income_credi_card: {
      fetchPolicy: 'no-cache',
      query: GET_CASE_REPORTS,
      variables() {
        return {
          income_cash: {
            payment_method: {
              _eq: 'cash',
            },
            type: {
              _eq: 'income',
            },
            deleted_by: { _is_null: true },
            _and: this.request,
            event: { location_id: { _eq: this.locationFilter } },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.locationFilter,
                },
              },
            },
          },
          income_transfer: {
            payment_method: {
              _eq: 'transfer',
            },
            type: {
              _eq: 'income',
            },
            _and: this.request,
            event: { location_id: { _eq: this.locationFilter } },
            deleted_by: { _is_null: true },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.locationFilter,
                },
              },
            },
          },
          income_credi_card: {
            payment_method: {
              _eq: 'credit_card',
            },
            type: {
              _eq: 'income',
            },
            deleted_by: { _is_null: true },
            _and: this.request,
            event: { location_id: { _eq: this.locationFilter } },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.locationFilter,
                },
              },
            },
          },
          income_voucher: {
            payment_method: {
              _eq: 'voucher',
            },
            type: {
              _eq: 'income',
            },
            deleted_by: { _is_null: true },
            _and: this.request,
            event: { location_id: { _eq: this.locationFilter } },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.locationFilter,
                },
              },
            },
          },
          expense_transfer: {
            payment_method: {
              _eq: 'transfer',
            },
            type: {
              _eq: 'expense',
            },
            deleted_by: { _is_null: true },
            _and: this.request,
            event: { location_id: { _eq: this.locationFilter } },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.locationFilter,
                },
              },
            },
          },
          expense_credi_card: {
            payment_method: {
              _eq: 'credit_card',
            },
            type: {
              _eq: 'expense',
            },
            deleted_by: { _is_null: true },
            _and: this.request,
            event: { location_id: { _eq: this.locationFilter } },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.locationFilter,
                },
              },
            },
          },
          expense_cash: {
            payment_method: {
              _eq: 'cash',
            },
            type: {
              _eq: 'expense',
            },
            deleted_by: { _is_null: true },
            _and: this.request,
            event: { location_id: { _eq: this.locationFilter } },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.locationFilter,
                },
              },
            },
          },
          total_income: {
            type: {
              _eq: 'income',
            },
            deleted_by: { _is_null: true },
            _and: this.request,
            event: { location_id: { _eq: this.locationFilter } },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.locationFilter,
                },
              },
            },
          },
          total_expense: {
            payment_method: {
              _neq: 'fees',
            },
            type: {
              _eq: 'expense',
            },
            deleted_by: { _is_null: true },
            _and: this.request,
            event: { location_id: { _eq: this.locationFilter } },
            user: {
              location_staffs: {
                location_id: {
                  _in: this.locationFilter,
                },
              },
            },
          },
        }
      },
      result({ data }) {
        this.income_cash = data.income_cash
        this.income_transfer = data.income_transfer
        this.income_voucher = data.income_voucher
        this.expense_transfer = data.expense_transfer
        this.expense_credi_card = data.expense_credi_card
        this.expense_cash = data.expense_cash
        this.total_income = data.total_income
        this.total_expense = data.total_expense
      },
    },
    locations: {
      fetchPolicy: 'no-cache',
      query: GET_LOCATIONS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            is_disable: { _eq: false },
            id: {
              _in: this.staff_locations,
            },
          },
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>

<style>
@media only screen and (min-width: 768px) {
  .multiselect {
    min-width: fit-content !important;
  }

  .multiselect .multiselect__content-wrapper {
    min-width: fit-content;
  }

  .multiselect__option {
    width: 100%;
  }

  .multiselect__placeholder {
    margin-right: 30px !important;
  }
}
</style>
