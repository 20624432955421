<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <!-- end row -->
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card">
            <div class="card-body">
              <div class="p-2">
                <div class="text-center">
                  <div class="avatar-md mx-auto">
                    <div class="avatar-title rounded-circle bg-light">
                      <i class="bx bx-error-alt h1 mb-0 text-warning"></i>
                    </div>
                  </div>
                  <div class="p-2 mt-4">
                    <h4>{{ $t('utility.payment_not_made') }}!</h4>
                    <p class="text-muted">
                      {{ $t('utility.payment_failed') }}
                    </p>
                    <div class="mt-4">
                      <router-link to="/packages" class="btn btn-success">{{
                        $t('utility.try_again')
                      }}</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4 text-center">
            <span class="text-muted">
              {{ $t('utility.having_trouble') }}<i class="mdi mdi-help me-1"></i> <br />
              {{ $t('utility.contact_us') }} info@barespace.io
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'

/**
 * Confirm-mail component
 */
export default {
  page: {
    title: 'payment_failed',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {},
  data() {
    return {}
  },
  created() {
    this.$_loading()
  },
  methods: {},
}
</script>
