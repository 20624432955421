<template>
  <div v-if="!getItem">
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col-xl-9 login-barespace d-none d-xl-block">
          <div class="auth-full-bg pt-lg-5 p-4">
            <div class="w-100">
              <div class="bg-overlay"></div>
              <!--
              <div class="d-flex h-100 flex-column">
                <div class="p-4 mt-auto">
                  <div class="row justify-content-center">
                    <div class="col-lg-7">
                      <div class="text-center">
                        <h4 class="mb-3">
                          <i
                            class="
                              bx
                              bxs-quote-alt-left
                              text-primary
                              h1
                              align-middle
                              mr-3
                              text-white
                            "
                          ></i
                          ><span class="text-primary text-white"
                            >{{ $t('login.testimonial.title') }}
                          </span>
                        </h4>

                        <div dir="ltr" class="owl-theme">
                          <b-carousel
                            id="carousel-1"
                            :interval="4000"
                            indicators
                          >
                            <b-carousel-slide>
                              <p class="font-size-16 mb-4 text-white">
                                {{ $t('login.testimonial.comments.comment_1') }}
                              </p>
                              <div>
                                <h4
                                  class="font-size-16 text-primary text-white"
                                >
                                  {{ $t('login.testimonial.comments.comment_1_name') }}
                                </h4>
                              </div>
                            </b-carousel-slide>

                            <b-carousel-slide>
                              <p class="font-size-16 mb-4 text-white">
                                {{ $t('login.testimonial.comments.comment_2') }}
                              </p>
                              <div>
                                <h4
                                  class="font-size-16 text-primary text-white"
                                >
                                  {{ $t('login.testimonial.comments.comment_2_name') }}
                                </h4>
                              </div>
                            </b-carousel-slide>
                          </b-carousel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-xl-3">
          <div class="auth-full-page-content p-md-5 p-4">
            <div class="w-100">
              <div class="d-flex flex-column h-100">
                <div class="mb-4 mb-md-4">
                  <router-link to="/" class="d-block auth-logo">
                    <img
                      src="@/assets/images/logo_gray_mini.png"
                      alt=""
                      height="37"
                      class="auth-logo-dark"
                    />
                    <img
                      src="@/assets/images/logo_gray_mini.png"
                      alt=""
                      height="37"
                      class="auth-logo-light"
                    />
                  </router-link>
                </div>
                <div class="my-auto">
                  <div>
                    <h5 class="text-primary">{{ $t('register.title') }}</h5>
                    <p class="text-muted">
                      {{ $t('register.sub_title') }}
                    </p>
                  </div>

                  <div class="mt-4">
                    <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>{{
                      $t('register.success_text')
                    }}</b-alert>

                    <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{
                      regError
                    }}</b-alert>

                    <div v-if="notification.message" :class="'alert ' + notification.type">
                      {{ notification.message }}
                    </div>

                    <b-form @submit.prevent="tryToRegisterIn">
                      <b-form-group
                        id="email-group"
                        class="mb-3"
                        :label="$t('register.company_name')"
                        label-for="team_name"
                      >
                        <b-form-input
                          id="team_name"
                          v-model="user.team_name"
                          type="text"
                          :class="{
                            'is-invalid': submitted && $v.user.team_name.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitted && !$v.user.team_name.required"
                          class="invalid-feedback"
                        >
                          {{ $t('register.company_name_required') }}
                        </div>
                      </b-form-group>

                      <b-form-group
                        id="first_name-group"
                        class="mb-3"
                        :label="$t('register.first_name')"
                        label-for="first_name"
                      >
                        <b-form-input
                          id="first_name"
                          v-model="user.first_name"
                          type="text"
                          :class="{
                            'is-invalid': submitted && $v.user.first_name.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitted && !$v.user.first_name.required"
                          class="invalid-feedback"
                        >
                          {{ $t('register.first_name_required') }}
                        </div>
                      </b-form-group>

                      <b-form-group
                        id="last_name-group"
                        class="mb-3"
                        :label="$t('register.last_name')"
                        label-for="last_name"
                      >
                        <b-form-input
                          id="last_name"
                          v-model="user.last_name"
                          type="text"
                          :class="{
                            'is-invalid': submitted && $v.user.last_name.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitted && !$v.user.last_name.required"
                          class="invalid-feedback"
                        >
                          {{ $t('register.last_name_required') }}
                        </div>
                      </b-form-group>

                      <b-form-group
                        id="email-group"
                        class="mb-3"
                        :label="$t('register.email')"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="user.email"
                          type="email"
                          :class="{
                            'is-invalid': submitted && $v.user.email.$error,
                          }"
                        ></b-form-input>
                        <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                          <span v-if="!$v.user.email.required">{{
                            $t('register.email_required')
                          }}</span>
                          <span v-if="!$v.user.email.email">{{
                            $('register.email_not_valid')
                          }}</span>
                        </div>
                      </b-form-group>

                      <b-form-group
                        id="email-group"
                        class="mb-3"
                        :label="$t('register.phone')"
                        label-for="phone"
                      >
                        <tel-input
                          v-model="user.phone"
                          type="text"
                          :is-invalid="submitted && $v.user.phone.$error"
                          :class="{ 'is-invalid': submitted && $v.user.phone.$error }"
                        />
                        <div v-if="submitted && !$v.user.phone.required" class="invalid-feedback">
                          {{ $t('register.phone_required') }}
                        </div>
                      </b-form-group>

                      <b-form-group
                        id="password-group"
                        class="mb-3"
                        :label="$t('register.password')"
                        label-for="password"
                      >
                        <b-form-input
                          id="password"
                          v-model="user.password"
                          type="password"
                          :class="{
                            'is-invalid': submitted && $v.user.password.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitted && !$v.user.password.required"
                          class="invalid-feedback"
                        >
                          {{ $t('register.password_required') }}
                        </div>
                      </b-form-group>

                      <b-form-group>
                        <div class="form-check">
                          <input
                            id="gridCheck"
                            v-model="user.term"
                            class="form-check-input"
                            type="checkbox"
                            :class="{
                              'is-invalid': submitted && $v.user.term.$error,
                            }"
                          />
                          <label class="form-check-label" for="gridCheck">
                            {{ $t('register.term_1') }}:
                            <a target="_blank" href="/terms-conditions/">{{
                              $t('register.term_2')
                            }}</a>
                          </label>
                        </div>
                        <div v-if="submitted && !$v.user.term.required" class="invalid-feedback">
                          {{ $t('register.term_required') }}
                        </div>
                      </b-form-group>

                      <div class="mt-4 d-grid">
                        <b-button type="submit" variant="primary" class="btn-block">{{
                          $t('register.submit')
                        }}</b-button>
                      </div>
                    </b-form>

                    <div class="mt-5 text-center">
                      <p>
                        {{ $t('register.already_have_an_account') }}
                        <router-link to="/login" class="fw-medium text-primary">
                          {{ $t('register.login') }}</router-link
                        >
                      </p>
                    </div>
                  </div>
                </div>

                <div class="mt-4 mt-md-5 text-center">
                  <p class="mb-0">
                    © {{ new Date().getFullYear() }} {{ $t('general.brand_name') }}
                    <i class="mdi mdi-heart text-danger"></i> {{ $t('general.country') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container-fluid -->
  </div>
</template>

<script>
import { authMethods, notificationMethods } from '@/state/helpers'
import appConfig from '@/app.config'
import { mapState } from 'vuex'

import { required, email } from 'vuelidate/lib/validators'

/**
 * Register component
 */
export default {
  page: {
    title: 'register',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      user: {
        team_name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        phone: '',
        term: '',
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    }
  },
  validations: {
    user: {
      team_name: {
        required,
      },
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      password: {
        required,
      },
      term: {
        required,
      },
    },
  },
  computed: {
    ...mapState('authfack', ['status']),
    notification() {
      return this.$store ? this.$store.state.notification : null
    },
    getItem() {
      return localStorage.getItem('user')
    },
  },
  created() {
    this.$_loading()
    this.loginCheck()
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    loginCheck() {
      if (localStorage.getItem('user')) {
        this.$router.push('/')
      }
    },
    tryToRegisterIn() {
      if (this.submitted === true) {
        this.$loading(true)
      }
      this.submitted = true
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      } else {
        const { first_name, last_name, team_name, email, password, phone, term } = this.user
        if (first_name && last_name && team_name && email && password && phone && term) {
          this.registeruser({
            input: this.user,
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .carousel-caption {
  position: relative !important;
  right: 0;
  left: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #495057;
}

::v-deep .carousel-indicators li {
  background-color: rgba(85, 110, 230, 0.25);
}
@media only screen and (max-width: 700px) {
  .login-barespace {
    display: none;
  }
}
</style>
