<template>
  <div v-if="vault_activities && vault_activities_aggregate">
    <div v-if="vault_activities" class="table-responsive mt-3">
      <b-table
        :items="vault_activities"
        :fields="fields"
        responsive="sm"
        hover="hover"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="per_page"
        :filter="filter_b"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(created_at)="data">
          {{ moment(data.value).format('Do MMMM YYYY HH:ss') }}
        </template>
        <template #cell(info)="row">
          {{
            row.item.event_id !== null
              ? $t('appointment.appointment_payment')
              : $t('appoitment.package_payment')
          }}
        </template>
        <template #cell(price)="row">
          {{ formatPrice(row.item.price) }}
        </template>
      </b-table>
      <b-alert
        :show="vault_activities_aggregate.aggregate.count === 0"
        variant="warning"
        class="text-center"
      >
        <p class="mb-0"></p>
        {{ $t('tableInfo.noData') }}
      </b-alert>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="page"
              :total-rows="vault_activities_aggregate.aggregate.count"
              :per-page="per_page"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { GET_VAULT_ACTIVITY_CONTACT, GET_VAULT_ACTIVITIES_AGGREGATE } from '@/router/queries'
export default {
  name: 'Payment',
  props: ['customer'],
  data() {
    return {
      title: 'Payment',
      moment,
      currentPage: 1,
      filterOn: [],
      sortBy: 'value',
      sortDesc: false,
      sort_by: { created_at: 'desc' },
      filter_b: null,
      totalRows: 1,
      per_page: 5,
      page: 1,
      fields: [
        {
          key: 'created_at',
          label: this.$t('customerCard.tables.date'),
          sortable: true,
        },
        {
          key: 'type',
          label: this.$t('customerCard.tables.type'),
          sortable: true,
        },
        {
          key: 'price',
          label: this.$t('tables.amount'),
          sortable: true,
        },
        {
          key: 'description',
          label: this.$t('customerCard.tables.desc'),
          sortable: true,
        },
        {
          key: 'info',
          label: this.$t('customerCard.tables.paymentInfo'),
          sortable: true,
        },
      ],
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  apollo: {
    vault_activities_aggregate: {
      fetchPolicy: 'no-cache',
      query: GET_VAULT_ACTIVITIES_AGGREGATE,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            contact_id: {
              _eq: this.$route.params.id,
            },
          },
        }
      },
    },
    vault_activities: {
      fetchPolicy: 'no-cache',
      query: GET_VAULT_ACTIVITY_CONTACT,
      variables() {
        return {
          where: {
            deleted_by: { _is_null: true },
            team_id: {
              _eq: this.auth.user.team_id,
            },
            contact_id: {
              _eq: this.$route.params.id,
            },
          },
        }
      },
    },
  },
}
</script>
