var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.getItem)?_c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row g-0"},[_vm._m(0),_c('div',{staticClass:"col-xl-3"},[_c('div',{staticClass:"auth-full-page-content p-md-5 p-4"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex flex-column h-100"},[_c('div',{staticClass:"mb-4 mb-md-4"},[_c('router-link',{staticClass:"d-block auth-logo",attrs:{"to":"/"}},[_c('img',{staticClass:"auth-logo-dark",attrs:{"src":require("@/assets/images/logo_gray_mini.png"),"alt":"","height":"37"}}),_c('img',{staticClass:"auth-logo-light",attrs:{"src":require("@/assets/images/logo_gray_mini.png"),"alt":"","height":"37"}})])],1),_c('div',{staticClass:"my-auto"},[_c('div',[_c('h5',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$t('register.title')))]),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t('register.sub_title'))+" ")])]),_c('div',{staticClass:"mt-4"},[_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"success","dismissible":""},model:{value:(_vm.registerSuccess),callback:function ($$v) {_vm.registerSuccess=$$v},expression:"registerSuccess"}},[_vm._v(_vm._s(_vm.$t('register.success_text')))]),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.isRegisterError),callback:function ($$v) {_vm.isRegisterError=$$v},expression:"isRegisterError"}},[_vm._v(_vm._s(_vm.regError))]),(_vm.notification.message)?_c('div',{class:'alert ' + _vm.notification.type},[_vm._v(" "+_vm._s(_vm.notification.message)+" ")]):_vm._e(),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.tryToRegisterIn.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"email-group","label":_vm.$t('register.company_name'),"label-for":"team_name"}},[_c('b-form-input',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.user.team_name.$error,
                        },attrs:{"id":"team_name","type":"text"},model:{value:(_vm.user.team_name),callback:function ($$v) {_vm.$set(_vm.user, "team_name", $$v)},expression:"user.team_name"}}),(_vm.submitted && !_vm.$v.user.team_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('register.company_name_required'))+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"first_name-group","label":_vm.$t('register.first_name'),"label-for":"first_name"}},[_c('b-form-input',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.user.first_name.$error,
                        },attrs:{"id":"first_name","type":"text"},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}}),(_vm.submitted && !_vm.$v.user.first_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('register.first_name_required'))+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"last_name-group","label":_vm.$t('register.last_name'),"label-for":"last_name"}},[_c('b-form-input',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.user.last_name.$error,
                        },attrs:{"id":"last_name","type":"text"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}}),(_vm.submitted && !_vm.$v.user.last_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('register.last_name_required'))+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"email-group","label":_vm.$t('register.email'),"label-for":"email"}},[_c('b-form-input',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.user.email.$error,
                        },attrs:{"id":"email","type":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),(_vm.submitted && _vm.$v.user.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.email.required)?_c('span',[_vm._v(_vm._s(_vm.$t('register.email_required')))]):_vm._e(),(!_vm.$v.user.email.email)?_c('span',[_vm._v(_vm._s(_vm.$('register.email_not_valid')))]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"email-group","label":_vm.$t('register.phone'),"label-for":"phone"}},[_c('tel-input',{class:{ 'is-invalid': _vm.submitted && _vm.$v.user.phone.$error },attrs:{"type":"text","is-invalid":_vm.submitted && _vm.$v.user.phone.$error},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),(_vm.submitted && !_vm.$v.user.phone.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('register.phone_required'))+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"password-group","label":_vm.$t('register.password'),"label-for":"password"}},[_c('b-form-input',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.user.password.$error,
                        },attrs:{"id":"password","type":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),(_vm.submitted && !_vm.$v.user.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('register.password_required'))+" ")]):_vm._e()],1),_c('b-form-group',[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.term),expression:"user.term"}],staticClass:"form-check-input",class:{
                            'is-invalid': _vm.submitted && _vm.$v.user.term.$error,
                          },attrs:{"id":"gridCheck","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.user.term)?_vm._i(_vm.user.term,null)>-1:(_vm.user.term)},on:{"change":function($event){var $$a=_vm.user.term,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.user, "term", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.user, "term", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.user, "term", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"gridCheck"}},[_vm._v(" "+_vm._s(_vm.$t('register.term_1'))+": "),_c('a',{attrs:{"target":"_blank","href":"/terms-conditions/"}},[_vm._v(_vm._s(_vm.$t('register.term_2')))])])]),(_vm.submitted && !_vm.$v.user.term.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('register.term_required'))+" ")]):_vm._e()]),_c('div',{staticClass:"mt-4 d-grid"},[_c('b-button',{staticClass:"btn-block",attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.$t('register.submit')))])],1)],1),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('register.already_have_an_account'))+" "),_c('router-link',{staticClass:"fw-medium text-primary",attrs:{"to":"/login"}},[_vm._v(" "+_vm._s(_vm.$t('register.login')))])],1)])],1)]),_c('div',{staticClass:"mt-4 mt-md-5 text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" "+_vm._s(_vm.$t('general.brand_name'))+" "),_c('i',{staticClass:"mdi mdi-heart text-danger"}),_vm._v(" "+_vm._s(_vm.$t('general.country'))+" ")])])])])])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-9 login-barespace d-none d-xl-block"},[_c('div',{staticClass:"auth-full-bg pt-lg-5 p-4"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"bg-overlay"})])])])
}]

export { render, staticRenderFns }