<template>
  <div v-if="bundles && event_bundles">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 col-xs-12">
            <div class="row">
              <div class="col-md-9">
                <h4 class="card-title mb-2">{{ $t('menuitems.systems.list.bundles') }}</h4>
              </div>
              <div class="col-md-3 text-end">
                <b-button variant="success btn-sm" @click="bundleDetail = true"
                  ><i class="bx bx-search-alt font-size-16 align-middle me-1"></i
                  >{{ $t('customerCard.packageUse') }}</b-button
                >
                <b-modal
                  v-model="bundleDetail"
                  hide-footer
                  :title="$t('customerCard.packageUse')"
                  size="xl"
                >
                  <div class="row">
                    <div class="col-md-3">
                      <label>{{ $t('customerCard.package_filter') }}</label>
                      <multiselect
                        v-model="bundle_id"
                        :placeholder="$t('customerCard.tables.packageName')"
                        :show-labels="false"
                        :options="bundles.map((x) => x.id)"
                        :custom-label="(opt) => bundles.find((x) => x.id == opt).name"
                      >
                      </multiselect>
                    </div>
                    <div class="col-md-3">
                      <label>Limit</label>
                      <b-form-select v-model="per_page" class="form-select">
                        <b-form-select-option :value="null"
                          >{{ $t('lists.per_page') }}...</b-form-select-option
                        >
                        <b-form-select-option :value="1">1</b-form-select-option>
                        <b-form-select-option :value="10">10</b-form-select-option>
                        <b-form-select-option :value="25">25</b-form-select-option>
                        <b-form-select-option :value="50">50</b-form-select-option>
                        <b-form-select-option :value="100">100</b-form-select-option>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th>{{ $t('customerCard.tables.packageName') }}</th>
                          <th>{{ $t('customerCard.tables.seans') }}</th>
                          <th>{{ $t('customerCard.tables.usePackage') }}</th>
                          <th>{{ $t('customerCard.tables.useDate') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(event, key) in event_bundles" :key="key">
                          <td>{{ event.bundle.name }}</td>
                          <td>{{ event.bundle_service.service.name }}</td>
                          <td>{{ event.amount }}</td>
                          <td>
                            {{ moment(event.created_at).format('Do MMMM YYYY HH:ss') }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-alert
                      v-if="!event_bundles.length"
                      show
                      variant="warning"
                      class="text-center"
                    >
                      <p class="mb-0"></p>
                      {{ $t('tableInfo.noData') }}
                    </b-alert>
                  </div>
                  <div class="col-md-12 text-end mt-3">
                    <b-button @click="bundleDetail = false">{{ $t('buttons.close') }}</b-button>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-xs-12">
            <div style="overflow-x: auto">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>{{ $t('customerCard.tables.packageName') }}</th>
                    <th>{{ $t('customerCard.tables.price') }}</th>
                    <th>{{ $t('customerCard.tables.seans') }}</th>
                    <th>{{ $t('customerCard.tables.installment') }}</th>
                    <th>{{ $t('customerCard.tables.collection') }}</th>
                    <th>{{ $t('customerCard.tables.balance') }}</th>
                    <th>{{ $t('customerCard.tables.createdAt') }}</th>
                    <th>{{ $t('customerCard.tables.action') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(bundle, key) in bundles" :key="key">
                    <td>{{ bundle.name }}</td>
                    <td>{{ formatPrice(bundle.price) }}</td>
                    <td>
                      <span
                        v-for="(service, serviceKey) in bundle.bundle_services"
                        :key="serviceKey"
                      >
                        {{ service.service.name + ' (' + service.amount + ')' }} <br
                      /></span>
                    </td>
                    <td>{{ bundle.bundle_payments.length + ' ' + 'Taksit' }}</td>
                    <td>
                      {{
                        bundle.paid.aggregate.sum.price !== null
                          ? formatPrice(bundle.paid.aggregate.sum.price)
                          : formatPrice(0)
                      }}
                    </td>
                    <td>
                      {{
                        bundle.debt.aggregate.sum.price !== null
                          ? formatPrice(bundle.debt.aggregate.sum.price)
                          : formatPrice(0)
                      }}
                    </td>
                    <td>
                      {{ moment(bundle.created_at).from() }}
                    </td>

                    <td>
                      <div class="button-items">
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          @click="$router.push({ name: 'bundle.show', params: { key: bundle.id } })"
                        >
                          <i class="bx bx-search-alt font-size-16 align-middle me-1"></i
                          >{{ $t('buttons.show') }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          @click="
                            paymentDetail[key] = !paymentDetail[key]
                            $forceUpdate()
                          "
                        >
                          <i class="bx bx-edit font-size-16 align-middle me-1"></i
                          >{{ $t('buttons.edit') }}
                        </button>
                      </div>
                    </td>
                    <b-modal
                      v-model="paymentDetail[key]"
                      size="xl"
                      hide-footer
                      title="Tahsilat Detay"
                    >
                      <table class="table mb-3 table-striped">
                        <thead>
                          <tr>
                            <th>Tarih</th>
                            <th>Ödeme</th>
                            <th>Durum</th>
                            <th>{{ $t('tables.action') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(payment, paymentKey) in bundle.bundle_payments"
                            :key="paymentKey"
                          >
                            <td>{{ moment(payment.date).format('DD/MM/YYYY') }}</td>
                            <td>{{ formatPrice(payment.price) }}</td>
                            <td>
                              <p v-if="payment.status === 'PENDING'">
                                ÖDEME BEKLENİYOR
                                <span
                                  v-if="
                                    moment().format('YYYY-MM-DD') >
                                    moment(payment.date).format('YYYY-MM-DD')
                                  "
                                  class="text-danger"
                                  style="font-weight: bold"
                                >
                                  - GÜNÜ GEÇTİ
                                  <i class="bx bxs-info-circle bx-flashing bx-flip-vertical"></i
                                ></span>
                              </p>
                              <p v-if="payment.status === 'DONE'" class="text-success">
                                ÖDEME YAPILDI
                              </p>
                            </td>
                            <td>
                              <button
                                v-if="payment.status === 'PENDING'"
                                type="button"
                                class="btn btn-warning btn-sm"
                                @click="
                                  addListPayment[paymentKey] = !addListPayment[paymentKey]
                                  $forceUpdate()
                                "
                              >
                                <i class="bx bx-edit font-size-16 align-middle me-1"></i
                                >{{ $t('buttons.edit') }}
                              </button>
                            </td>
                            <b-modal
                              v-model="addListPayment[paymentKey]"
                              hide-footer
                              title="Tahsilat Ekle"
                            >
                              <invoice-component
                                :key-i-d="payment.id"
                                :data="payment"
                                @updated="paymentUpdate($event)"
                                @close="addListPayment = {}"
                              ></invoice-component>
                            </b-modal>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">{{ $t('tables.total_amount') }} :</th>
                            <td>{{ formatPrice(bundle.price) }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Tahsil Edilen :</th>
                            <td>
                              {{
                                bundle.paid.aggregate.sum.price !== null
                                  ? formatPrice(bundle.paid.aggregate.sum.price)
                                  : formatPrice(0)
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Beklenen Tahsilat :</th>
                            <td>
                              {{
                                bundle.debt.aggregate.sum.price !== null
                                  ? formatPrice(bundle.debt.aggregate.sum.price)
                                  : formatPrice(0)
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="text-end mt-3">
                        <b-button variant="light" @click="paymentDetail = {}">{{
                          $t('buttons.close')
                        }}</b-button>
                      </div>
                    </b-modal>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-alert v-if="!bundles.length" show variant="warning" class="text-center">
              {{ $t('tableInfo.noData') }}
            </b-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_BUNDLE_EVENTS, GET_BUNDLES, UPDATE_PAYMENT } from '@/router/queries'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import invoiceComponent from '@/components/widgets/invoice-payment'
import { mapState } from 'vuex'
export default {
  name: 'Bundle',
  components: {
    invoiceComponent,
    Multiselect,
  },
  data() {
    return {
      moment,
      bundleModal: false,
      bundleDetail: false,
      addListPayment: {},
      paymentDetail: {},
      bundle_id: false,
      per_page: 10,
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  methods: {
    paymentUpdate(id) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_PAYMENT,
        variables: {
          payment: {
            status: 'DONE',
          },
          id: id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$apollo.queries.bundles.refresh()
            this.$forceUpdate()
            this.$loading(false)
            this.$emit('updated')
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
    },
  },
  apollo: {
    bundles: {
      fetchPolicy: 'no-cache',
      query: GET_BUNDLES,
      variables() {
        return {
          where: {
            contact_id: {
              _eq: this.$route.params.id,
            },
          },
        }
      },
    },
    event_bundles: {
      fetchPolicy: 'no-cache',
      query: GET_BUNDLE_EVENTS,
      variables() {
        return {
          limit: this.per_page,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            contact_id: {
              _eq: this.$route.params.id,
            },
            bundle_id: {
              _eq: this.bundle_id || undefined,
            },
          },
        }
      },
    },
  },
}
</script>
