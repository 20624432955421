<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <contact-form />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/page-header'
import ContactForm from '@/components/widgets/contactForm'
import appConfig from '@/app.config'

/**
 * Form Layouts component
 */
export default {
  page: {
    title: 'customers',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { PageHeader, ContactForm },
  data() {
    return {
      title: this.$t('menuitems.dashboards.new'),
      items: [
        {
          text: this.$t('menuitems.dashboards.search'),
          href: '/',
        },
        {
          text: this.$t('menuitems.dashboards.new'),
          active: true,
        },
      ],
      selected: null,
    }
  },
}
</script>
