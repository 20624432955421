<template>
  <div>
    <b-modal v-model="showModal" :title="setTitle()" hide-footer>
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="product_name">{{ $t('product.product_name') }}</label>
              <input
                id="product_name"
                v-model="product.name"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': submitted && $v.product.name.$error,
                }"
              />
              <div v-if="submitted && !$v.product.name.required" class="invalid-feedback">
                {{ $t('fields.required') }}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="price">{{ $t('tables.price') }}</label>
              <b-input-group>
                <b-form-input
                  id="price"
                  v-model="product.price"
                  type="number"
                  step="0.01"
                  lazy-formatter
                  :formatter="(val) => parseFloat(val).toFixed(2)"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.product.price.$error,
                  }"
                />
                <div v-if="submitted && !$v.product.price.required" class="invalid-feedback">
                  {{ $t('fields.required') }}
                </div>
              </b-input-group>
            </div>
          </div>
          <b-form-group :label="$t('title.categories')" class="mb-3">
            <multiselect
              v-model="selectedCategories"
              :placeholder="$t('product.product_category')"
              :show-labels="false"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="true"
              :options="categories.map((x) => x.id)"
              :custom-label="(opt) => categories.find((x) => x.id == opt).name"
              :class="{
                'is-invalid': submitted && $v.selectedCategories.$error,
              }"
            >
            </multiselect>
            <div v-if="submitted && !$v.selectedCategories.required" class="invalid-feedback">
              {{ $t('fields.required') }}
            </div>
          </b-form-group>
          <locations-input :input-data="product.location_products" @setLocations="setLocations" />
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="$emit('close')">{{ $t('buttons.close') }}</b-button>
          <b-button type="submit" variant="success" class="ms-1">{{ $t('buttons.save') }}</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  INSERT_PRODUCTS,
  UPDATE_PRODUCTS,
  ADD_LOCATION_PRODUCTS,
  DELETE_LOCATION_PRODUCT_BY_PRODUCT_ID,
  INSERT_PRODUCT_CATEGORIES,
  UPDATE_PRODUCT_CATEGORIES,
} from '@/router/queries'
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

export default {
  name: 'AddProduct',
  props: {
    addProductModal: {
      type: Boolean,
    },
    updateItemData: {
      type: Object,
      default: () => {},
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      submitted: false,
      locationChanged: false,
      product: null,
      productID: null,
      isUpdate: null,
      locationProducts: [],
      selectedCategories: [],
    }
  },
  validations: {
    product: {
      name: { required },
      price: { required },
      location_products: {
        data: {
          required,
        },
      },
    },
    selectedCategories: { required },
  },
  computed: {
    ...mapState(['auth']),
    showModal: {
      get() {
        return this.addProductModal
      },
      set() {
        this.$emit('close')
      },
    },
  },
  watch: {
    updateItemData(val) {
      if (val.id) {
        this.product = { ...val }
        this.isUpdate = true
        this.selectedCategories = this.product.product_categories.map((item) => item.categories.id)
      } else {
        this.selectedCategories = []
        this.initData()
      }
    },
  },
  created() {
    this.initData()
  },
  methods: {
    setTitle() {
      if (this.isUpdate) return this.$t('product.edit_product')
      return this.$t('navbar.dropdown.quick_menu.add_product')
    },
    initData() {
      this.isUpdate = false
      this.product = {
        name: null,
        price: null,
        user_id: this.auth.user.id,
        team_id: this.auth.user.team_id,
        location_products: {
          data: [],
        },
      }
    },
    async handleSubmit() {
      if (this.isUpdate) {
        if (this.locationChanged) {
          await this.removeLocationProduct()
          await this.addLocationProducts()
        }
        await this.updateCategories(this.product.id)
        this.update()
        this.locationChanged = false
        return
      }
      this.create()
    },
    setLocations(val) {
      this.locationChanged = true
      if (this.isUpdate) {
        this.locationProducts = []
        val.forEach((el) => {
          this.locationProducts.push({
            location_id: el.id,
            product_id: this.product.id,
            team_id: this.auth.user.team_id,
          })
        })
      } else {
        this.product.location_products.data = []
        val.forEach((el) => {
          this.product.location_products.data.push({
            location_id: el.id,
            team_id: this.auth.user.team_id,
          })
        })
      }
    },
    create() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$loading(false)
        return
      } else {
        this.$apollo.mutate({
          mutation: INSERT_PRODUCTS,
          variables: {
            product: this.product,
          },
          update: async (cache, { data: { insert_products_one } }) => {
            try {
              await this.insertCategories(insert_products_one.id)
              this.$loading(false)
              this.$_N({
                position: 'center',
                type: 'success',
              })
              this.$emit('close', insert_products_one.id)
              this.$emit('submitted')
            } catch (e) {
              this.$_N({
                position: 'center',
                type: 'error',
              })
            }
          },
        })
      }
      this.submitted = false
    },
    async removeLocationProduct() {
      this.$loading(true)
      await this.$apollo
        .mutate({
          mutation: DELETE_LOCATION_PRODUCT_BY_PRODUCT_ID,
          variables: {
            product_id: this.product.id,
          },
        })
        .then(() => {
          return
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
        })
    },
    async addLocationProducts() {
      this.$loading(true)
      await this.$apollo
        .mutate({
          mutation: ADD_LOCATION_PRODUCTS,
          variables: {
            locationProducts: this.locationProducts,
          },
        })
        .then(() => {
          this.locationProducts = []
          return
        })
        .catch(() => {
          this.locationProducts = []
          this.$_N({
            type: 'error',
            position: 'center',
          })
        })
    },
    update() {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_PRODUCTS,
        variables: {
          id: this.product.id,
          product: this.$_ER(this.product, [
            '__typename',
            'location_products',
            'product_categories',
          ]),
        },
        update: () => {
          try {
            this.$loading(false)
            this.$_N({
              position: 'center',
              type: 'success',
            })
            this.$emit('close')
            this.$emit('submitted')
          } catch (e) {
            this.$_N({
              position: 'center',
              type: 'error',
            })
          }
        },
      })
    },
    async insertCategories(product_id) {
      const objects = this.selectedCategories.map((category_id) => ({
        team_id: this.auth.user.team_id,
        category_id,
        product_id,
      }))
      await this.$apollo
        .mutate({
          mutation: INSERT_PRODUCT_CATEGORIES,
          variables: {
            objects,
          },
        })
        .then(() => {
          return true
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
          return false
        })
      return
    },
    async updateCategories(product_id) {
      const objects = this.selectedCategories.map((category_id) => ({
        team_id: this.auth.user.team_id,
        category_id,
        product_id,
      }))
      await this.$apollo
        .mutate({
          mutation: UPDATE_PRODUCT_CATEGORIES,
          variables: {
            where: {
              product_id: {
                _eq: this.updateItemData.id,
              },
            },
            objects,
          },
        })
        .then(() => {
          return true
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
          return false
        })
      return
    },
  },
}
</script>
