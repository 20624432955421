<script>
import appConfig from '@/app.config'
import Stat from '@/components/widgets/stat'

/**
 * Status Component
 */
export default {
  page: {
    title: 'Status',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Stat,
  },
  data() {
    return {
      title: 'Status',
      items: [
        {
          text: 'Statuss',
          href: '/',
        },
        {
          text: 'Default',
          active: true,
        },
      ],
      statData: [
        {
          icon: 'bx bx-copy-alt',
          title: 'Müşteriler',
          value: '1235',
        },
        {
          icon: 'bx bx-archive-in',
          title: 'Faturalar',
          value: '23',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Kasa Sayısı',
          value: '2',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Form Sayısı',
          value: '16',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Disk Kullanımı',
          value: '4.56 GB',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Toplam Email Marketing',
          value: '16.2',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Toplam SMS Marketing',
          value: '33',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Etkinlik Sayısı',
          value: '22',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Kalan SMS',
          value: '34',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Kullanılan SMS',
          value: '345',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Kalan Email',
          value: '546',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Kullanılan Email',
          value: '345',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Kayıt tarihi',
          value: '12',
        },
        {
          icon: 'bx bx-archive-in',
          title: 'e-Fatura Entegrasyonu',
          value: 'Mevcut Gegil',
        },
        {
          icon: 'bx bx-archive-in',
          title: 'Sanal Pos Entegrasyonu',
          value: 'Mevcut Gegil',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Kalan Kullanım Süresi',
          value: '12 gün',
        },
      ],
    }
  },
}
</script>

<template>
  <div class="row justify-content-center mt-lg-6">
    <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
      <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
    </div>
  </div>
</template>
