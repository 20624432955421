<template>
  <div>
    <PageHeader :items="items" :title="title" />
    <div class="checkout-tabs">
      <b-tabs pills vertical nav-class="p-0" nav-wrapper-class="col-xl-2 col-sm-3">
        <b-tab
          v-for="(tab, key) in tabs"
          :key="key"
          @click="$router.push({ name: tab.to, params: { key: $route.params.key } })"
        >
          <template #title>
            <p class="fw-bold mb-2 mt-2">{{ tab.title }}</p>
          </template>
          <b-card-text>
            <div class="card">
              <div class="card-body">
                <div class="tab-pane fade show active">
                  <h4 class="card-title mb-4">
                    {{ tab.title }}
                  </h4>
                  <div class="faq-box media mb-3">
                    <div class="media-body">
                      <router-view></router-view>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { mapState } from 'vuex'

import {} from '@/router/queries'
export default {
  page: {
    title: 'form',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      title: 'FORM DETAYI',
      items: [
        {
          text: 'Formlar',
          href: '/contacts',
        },
        {
          text: 'Form Detay',
          active: true,
        },
      ],
      tabs: [
        {
          title: this.$t('form.details'),
          to: 'form.show',
        },
        {
          title: this.$t('form.questions.questions'),
          to: 'form.questions',
        },
        {
          title: this.$t('form.answers.answers'),
          to: 'form.answers',
        },
        {
          title: this.$t('form.settings.settings'),
          to: 'form.settings',
        },
      ],
      statData: [
        {
          icon: 'bx bx-check-circle',
          title: 'Completed Projects',
          value: '125',
        },
        {
          icon: 'bx bx-hourglass',
          title: 'Pending Projects',
          value: '12',
        },
        {
          icon: 'bx bx-package',
          title: 'Total Revenue',
          value: '$36,524',
        },
      ],
      sort_by: { created_at: 'desc' },
      per_page: 10,
      page: 1,
    }
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
  },
  created() {
    this.$_loading()
  },
  methods: {},
  apollo: {},
}
</script>

<style lang="scss">
.checkout-tabs .nav-pills .nav-link {
  margin-bottom: 7px !important;
}
</style>
