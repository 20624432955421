<template>
  <div v-if="contacts && contacts_aggregate">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <!--          <div class="col-sm-auto pt-1">
            <date-picker
              placeholder="Bugün"
              v-model="start"
              :first-day-of-week="1"
            ></date-picker>
          </div>
          <div class="col-sm-auto">
            <date-picker
              placeholder="Bugün"
              v-model="end"
              :first-day-of-week="1"
            ></date-picker>
          </div>-->
          <div class="col-sm-auto">
            <div class="dataTables_filter text-end">
              <b-input
                v-model="search_input"
                type="search"
                :placeholder="$t('buttons.placeHolderSearch')"
              ></b-input>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <!--
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-3">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <p class="text-muted fw-medium mb-2">Randevu Borçları</p>
                  <h4 class="mb-0">
                    X {{ companyCurrency }}
                  </h4>
                </div>
                <div
                  class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary"
                >
                  <span class="avatar-title">
                    <i class="bx bx-money font-size-24`"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <p class="text-muted fw-medium mb-2">Paket Borçları</p>
                  <h4 class="mb-0">X {{ companyCurrency }}</h4>
                </div>
                <div
                  class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary"
                >
                  <span class="avatar-title">
                    <i class="bx bx-money font-size-24`"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <p class="text-muted fw-medium mb-2">Tahsilat</p>
                  <h4 class="mb-0">
                    dasd {{ companyCurrency }}
                  </h4>
                </div>
                <div
                  class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary"
                >
                  <span class="avatar-title">
                    <i class="bx bx-money font-size-24`"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <p class="text-muted fw-medium mb-2">Alacak</p>
                  <h4 class="mb-0">X {{ companyCurrency }}</h4>
                </div>
                <div
                  class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary"
                >
                  <span class="avatar-title">
                    <i class="bx bx-money font-size-24`"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->

    <div v-if="contacts" class="col-md-12 col-xs-12">
      <div class="card table-responsive">
        <div class="card-body">
          <div style="overflow-x: auto">
            <b-table
              :items="contacts"
              :fields="fields"
              responsive="sm"
              hover="hover"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter_b"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(created_at)="data">
                {{ moment(data.value).format('Do MMMM YYYY HH:ss') }}
              </template>
              <template #cell(totalPrice)="row">
                {{
                  row.item.vault_activities_aggregate.aggregate.sum.price !== null
                    ? formatPrice(
                        row.item.vault_activities_aggregate.aggregate.sum.price +
                          (row.item.dept_bundle.aggregate.sum.price !== null
                            ? row.item.dept_bundle.aggregate.sum.price
                            : 0) +
                          (eventDept(row.item.id) !== undefined ? eventDept(row.item.id) : 0)
                      )
                    : formatPrice(0)
                }}
              </template>
              <template #cell(name)="row">
                <router-link :to="{ name: 'contact.show', params: { id: row.item.id } }">
                  {{ row.item.first_name + ' ' + row.item.last_name }}
                </router-link>
              </template>
              <template #cell(totalPaid)="row">
                {{
                  row.item.vault_activities_aggregate.aggregate.sum.price !== null
                    ? formatPrice(row.item.vault_activities_aggregate.aggregate.sum.price)
                    : formatPrice(0)
                }}
              </template>
              <template #cell(totalDept)="row">
                {{
                  row.item.dept_bundle.aggregate.sum.price !== null ||
                  eventDept(row.item.id) !== null
                    ? formatPrice(row.item.dept_bundle.aggregate.sum.price + eventDept(row.item.id))
                    : formatPrice(0)
                }}
              </template>
              <template #cell(totalBundleDept)="row">
                {{
                  row.item.dept_bundle.aggregate.sum.price !== null
                    ? formatPrice(row.item.dept_bundle.aggregate.sum.price)
                    : formatPrice(0)
                }}
              </template>
              <template #cell(totalEventDept)="row">
                {{
                  eventDept(row.item.id) !== undefined
                    ? formatPrice(eventDept(row.item.id))
                    : formatPrice(0)
                }}
              </template>
              <template #cell(totalServices)="row">
                <a
                  href="javascript: void(0);"
                  @click="
                    servicesModal[row.item.id] = !servicesModal[row.item.id]
                    $forceUpdate()
                  "
                >
                  {{
                    row.item.event_services_aggregate.aggregate.count !== 0
                      ? row.item.event_services_aggregate.aggregate.count +
                        ' - ' +
                        $t('reports.customer.services')
                      : ' 0 - ' + $t('reports.customer.service')
                  }}
                  <i style="font-size: 15px" class="bx bx-link"></i>
                </a>
                <b-modal
                  v-model="servicesModal[row.item.id]"
                  hide-footer
                  :title="$t('reports.customer.service_detail')"
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('reports.customer.service') }}</th>
                        <th scope="col">{{ $t('reports.customer.price') }}</th>
                        <th scope="col">{{ $t('reports.customer.staff') }}</th>
                        <th scope="col">{{ $t('reports.customer.date') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(service, key) in row.item.event_services" :key="key">
                        <td>{{ service.service.name }}</td>
                        <td>{{ service.price }}</td>
                        <td>
                          {{ service.user.first_name + ' ' + service.user.last_name }}
                        </td>
                        <td>
                          {{ moment(service.created_at).format('Do MMMM YYYY HH:ss') }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-alert v-if="!row.item.event_services.length" show variant="warning">{{
                    $t('reports.customer.service_not_found')
                  }}</b-alert>
                  <div class="text-end">
                    <b-button @click="servicesModal = {}">{{ $t('buttons.close') }}</b-button>
                  </div>
                </b-modal>
              </template>
              <template #cell(totalTips)="row">
                <a
                  href="javascript: void(0);"
                  @click="
                    tipsModal[row.item.id] = !tipsModal[row.item.id]
                    $forceUpdate()
                  "
                >
                  {{
                    row.item.tips_aggregate.aggregate.count !== 0
                      ? row.item.tips_aggregate.aggregate.count +
                        ' - ' +
                        $t('reports.customer.tips')
                      : ' 0 - ' + $t('reports.customer.tips')
                  }}
                  <i style="font-size: 15px" class="bx bx-link"></i>
                </a>
                <b-modal
                  v-model="tipsModal[row.item.id]"
                  hide-footer
                  :title="$t('reports.customer.tip_detail')"
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('reports.customer.price') }}</th>
                        <th scope="col">{{ $t('reports.customer.staff') }}</th>
                        <th scope="col">{{ $t('reports.customer.date') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(tip, key) in row.item.tips" :key="key">
                        <td>{{ tip.price }}</td>
                        <td>
                          {{ tip.staff.first_name + ' ' + tip.staff.last_name }}
                        </td>
                        <td>
                          {{ moment(tip.created_at).format('Do MMMM YYYY HH:ss') }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-alert v-if="!row.item.tips.length" show variant="warning">{{
                    $t('reports.customer.tip_not_found')
                  }}</b-alert>
                  <div class="text-end">
                    <b-button @click="tipsModal = {}">{{ $t('buttons.close') }}</b-button>
                  </div>
                </b-modal>
              </template>
              <template #cell(totalProducts)="row">
                <a
                  href="javascript: void(0);"
                  @click="
                    productsModal[row.item.id] = !productsModal[row.item.id]
                    $forceUpdate()
                  "
                >
                  {{
                    row.item.event_products_aggregate.aggregate.count !== 0
                      ? row.item.event_products_aggregate.aggregate.count +
                        ' - ' +
                        $t('reports.customer.products')
                      : ' 0 - ' + $t('reports.customer.product')
                  }}
                  <i style="font-size: 15px" class="bx bx-link"></i>
                </a>
                <b-modal
                  v-model="productsModal[row.item.id]"
                  hide-footer
                  :title="$t('reports.customer.product_detail')"
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t('reports.customer.product') }}</th>
                        <th scope="col">{{ $t('reports.customer.price') }}</th>
                        <th scope="col">{{ $t('reports.customer.staff') }}</th>
                        <th scope="col">{{ $t('reports.customer.date') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(product, key) in row.item.event_products" :key="key">
                        <td>{{ product.product.name }}</td>
                        <td>{{ product.price }}</td>
                        <td>
                          {{ product.user.first_name + ' ' + product.user.last_name }}
                        </td>
                        <td>
                          {{ moment(product.created_at).format('Do MMMM YYYY HH:ss') }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-alert v-if="!row.item.event_products.length" show variant="warning">{{
                    $t('reports.customer.product_not_found')
                  }}</b-alert>
                  <div class="text-end">
                    <b-button @click="productsModal = {}">{{ $t('buttons.close') }}</b-button>
                  </div>
                </b-modal>
              </template>
              <template #cell(totalBundles)="row">
                <a
                  href="javascript: void(0);"
                  @click="
                    totalBundles[row.item.id] = !totalBundles[row.item.id]
                    $forceUpdate()
                  "
                >
                  {{
                    row.item.bundles_aggregate.aggregate.count !== 0
                      ? row.item.bundles_aggregate.aggregate.count +
                        '- ' +
                        $t('reports.customer.packages')
                      : '0 - ' + $t('reports.customer.package')
                  }}
                  <i style="font-size: 15px" class="bx bx-link"></i>
                </a>
                <b-modal
                  v-model="totalBundles[row.item.id]"
                  hide-footer
                  :title="$t('reports.customer.package_detail')"
                  size="xl"
                >
                  <table class="table mb-0">
                    <thead>
                      <tr>
                        <th>{{ $t('reports.customer.package_name') }}</th>
                        <th>{{ $t('reports.customer.price') }}</th>
                        <th>{{ $t('reports.customer.installment') }}</th>
                        <th>{{ $t('reports.customer.paid') }}</th>
                        <th>{{ $t('reports.customer.pending') }}</th>
                        <th>{{ $t('reports.customer.created_at') }}</th>
                        <th>{{ $t('reports.customer.action') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(bundle, key) in row.item.bundles" :key="key">
                        <td>{{ bundle.name }}</td>
                        <td>{{ bundle.price }}</td>
                        <td>
                          {{
                            bundle.bundle_payments.length + ' ' + $t('reports.customer.installment')
                          }}
                        </td>
                        <td>
                          {{
                            bundle.paid.aggregate.sum.price !== null
                              ? formatPrice(bundle.paid.aggregate.sum.price)
                              : formatPrice(0)
                          }}
                        </td>
                        <td>
                          {{
                            bundle.debt.aggregate.sum.price !== null
                              ? formatPrice(bundle.debt.aggregate.sum.price)
                              : formatPrice(0)
                          }}
                        </td>
                        <td>
                          {{ moment(bundle.created_at).from() }}
                        </td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-success btn-sm"
                            style="margin-right: 3px"
                            @click="
                              $router.push({
                                name: 'bundle.show',
                                params: { key: bundle.id },
                              })
                            "
                          >
                            <i class="bx bx-search-alt font-size-16 align-middle me-1"></i>
                            {{ $t('buttons.show') }}
                          </button>
                          <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click="
                              paymentDetail[key] = !paymentDetail[key]
                              $forceUpdate()
                            "
                          >
                            <i class="bx bx-info-circle font-size-16 align-middle me-1"></i
                            >{{ $t('reports.customer.installment_detail') }}
                          </button>
                          <b-modal
                            v-model="paymentDetail[key]"
                            size="xl"
                            hide-footer
                            :title="$t('reports.customer.installment_detail')"
                          >
                            <table class="table mb-3 table-striped">
                              <thead>
                                <tr>
                                  <th>{{ $t('reports.customer.date') }}</th>
                                  <th>{{ $t('reports.customer.payment') }}</th>
                                  <th>{{ $t('reports.customer.status') }}</th>
                                  <th>{{ $t('reports.customer.action') }}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(payment, paymentKey) in bundle.bundle_payments"
                                  :key="paymentKey"
                                >
                                  <td>
                                    {{ moment(payment.date).format('DD/MM/YYYY') }}
                                  </td>
                                  <td>{{ formatPrice(payment.price) }}</td>
                                  <td>
                                    <p v-if="payment.status === 'PENDING'">
                                      {{ $t('payment_status.pending') }}
                                      <span
                                        v-if="
                                          moment().format('YYYY-MM-DD') >
                                          moment(payment.date).format('YYYY-MM-DD')
                                        "
                                        class="text-danger"
                                        style="font-weight: bold"
                                      >
                                        - {{ $t('payment_status.passed') }}
                                        <i
                                          class="bx bxs-info-circle bx-flashing bx-flip-vertical"
                                        ></i
                                      ></span>
                                    </p>
                                    <p v-if="payment.status === 'DONE'" class="text-success">
                                      {{ $t('payment_status.paid') }}
                                    </p>
                                  </td>
                                  <td>
                                    <button
                                      v-if="payment.status === 'PENDING'"
                                      type="button"
                                      class="btn btn-primary btn-sm"
                                      @click="
                                        addListPayment[paymentKey] = !addListPayment[paymentKey]
                                        $forceUpdate()
                                      "
                                    >
                                      {{ $t('reports.customer.payment') }}
                                    </button>
                                  </td>
                                  <b-modal
                                    v-model="addListPayment[paymentKey]"
                                    hide-footer
                                    :title="$t('reports.customer.add_payment')"
                                  >
                                    <invoice-component
                                      :key-i-d="payment.id"
                                      :data="payment"
                                      @updated="paymentUpdate($event)"
                                      @close="addListPayment = {}"
                                    ></invoice-component>
                                  </b-modal>
                                </tr>
                              </tbody>
                            </table>
                            <table class="table table-nowrap mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">{{ $t('reports.customer.total') }} :</th>
                                  <td>{{ formatPrice(bundle.price) }}</td>
                                </tr>
                                <tr>
                                  <th scope="row">{{ $t('reports.customer.paid') }} :</th>
                                  <td>
                                    {{
                                      bundle.paid.aggregate.sum.price !== null
                                        ? formatPrice(bundle.paid.aggregate.sum.price)
                                        : formatPrice(0)
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">{{ $t('reports.customer.pending') }} :</th>
                                  <td>
                                    {{
                                      bundle.debt.aggregate.sum.price !== null
                                        ? formatPrice(bundle.debt.aggregate.sum.price)
                                        : formatPrice(0)
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="text-end mt-3">
                              <b-button variant="light" @click="paymentDetail = {}">{{
                                $t('buttons.close')
                              }}</b-button>
                            </div>
                          </b-modal>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-alert v-if="!row.item.bundles.length" show variant="warning">{{
                    $t('reports.customer.package_not_found')
                  }}</b-alert>
                  <div class="text-end mt-2">
                    <b-button @click="totalBundles = {}">{{ $t('buttons.close') }}</b-button>
                  </div>
                </b-modal>
              </template>
            </b-table>

            <b-alert class="mt-3 mb-3 text-center" :show="contacts.length == 0" variant="warning">{{
              $t('reports.customer.warning_date_range')
            }}</b-alert>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="page"
                    :total-rows="contacts_aggregate.aggregate.count"
                    :per-page="per_page"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="text-center my-3">
            <p>
              {{ $t('customerForm.total') }}
              <b>{{ contacts_aggregate.aggregate.count }}</b>
            </p>
          </div>
        </div>
        <!-- end col-->
      </div>
      <!--<div class="card p-2 table-responsive" v-if="this.$apollo.queries.contacts_reports.refresh()">
            <h3 class="mb-3 mt-3 text-center"><small class="text-muted">Kayıt Bulunamadı!</small></h3>
          </div>-->
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import PageHeader from '@/components/page-header'
import {
  GET_CONTACT_REPORTS,
  GET_PERMISSION,
  GET_CONTACTS_AGGREGATE,
  UPDATE_PAYMENT,
} from '../../queries'
import { mapState } from 'vuex'
import invoiceComponent from '@/components/widgets/invoice-payment'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'

export default {
  page: {
    title: 'customer_report',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  // eslint-disable-next-line vue/no-unused-components
  components: { PageHeader, DatePicker, invoiceComponent },
  data() {
    return {
      title: this.$t('reports.customer.title'),
      items: [
        {
          text: this.$t('reports.title'),
          href: '/',
        },
        {
          text: this.$t('reports.customer.title'),
          active: true,
        },
      ],
      currentPage: 1,
      filterOn: [],
      sortBy: 'value',
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      paymentDetail: {},
      filter_b: null,
      detailContact: {},
      productsModal: {},
      addListPayment: {},
      totalBundles: {},
      totalRows: 1,
      fields: [
        {
          key: 'name',
          label: this.$t('reports.customer.customer'),
          sortable: true,
        },
        {
          key: 'totalServices',
          label: this.$t('reports.customer.service'),
          sortable: true,
        },
        {
          key: 'totalTips',
          label: this.$t('reports.customer.tip'),
          sortable: true,
        },
        {
          key: 'totalProducts',
          label: this.$t('reports.customer.product'),
          sortable: true,
        },
        {
          key: 'totalBundles',
          label: this.$t('reports.customer.package'),
          sortable: true,
        },
        {
          key: 'totalEventDept',
          label: this.$t('reports.customer.pending'),
          sortable: true,
        },
        {
          key: 'totalBundleDept',
          label: this.$t('reports.customer.package_pending'),
          sortable: true,
        },
        {
          key: 'totalPrice',
          label: this.$t('reports.customer.total'),
          sortable: true,
        },
        {
          key: 'totalPaid',
          label: this.$t('reports.customer.paid'),
          sortable: true,
        },
        {
          key: 'totalDept',
          label: this.$t('reports.customer.balance'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('reports.customer.created_at'),
          sortable: true,
        },
      ],
      moment,
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      search_input: this.$route.query.search || '',
      servicesModal: {},
      tipsModal: {},
      staff_locations: undefined,
    }
  },
  watch: {
    user_permissions(permissions) {
      if (permissions.some((t) => t.name === 'administrator')) {
        this.staff_locations = undefined
      } else {
        this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
      }
    },
  },
  computed: {
    ...mapState(['auth']),
    test() {
      let totals = 0
      for (const event of this.contacts.find().events) {
        const vPrice = event.vault_total_price.aggregate.sum.price
        const sPrice = event.event_services_total_price.aggregate.sum.price
        const pPrice = event.event_product_total_price.aggregate.sum.price
        const tPrice = event.tips_total_price.aggregate.sum.price
        totals += sPrice + pPrice + tPrice - vPrice
      }
      return totals
    },
    offset() {
      return (this.page - 1) * this.per_page
    },
    rows() {
      return this.contacts.length
    },
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
  },
  mounted() {},
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    paymentUpdate(id) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_PAYMENT,
        variables: {
          payment: {
            status: 'DONE',
          },
          id: id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$apollo.queries.contacts.refresh()
            this.$forceUpdate()
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
          }
        },
      })
    },
    eventDept(id) {
      let totals = 0
      for (const event of this.contacts.find((x) => x.id === id).events) {
        const vPrice = event.vault_total_price.aggregate.sum.price
        const sPrice = event.event_services_total_price.aggregate.sum.price
        const pPrice = event.event_product_total_price.aggregate.sum.price
        const tPrice = event.tips_total_price.aggregate.sum.price
        // eslint-disable-next-line no-unused-vars
        const bPrice = event.bundle_payment.aggregate.sum.price
        totals += sPrice + pPrice + tPrice - (vPrice - bPrice)
      }
      return totals
    },
  },
  apollo: {
    contacts: {
      fetchPolicy: 'no-cache',
      query: GET_CONTACT_REPORTS,
      variables() {
        return {
          where: {
            _or: [
              {
                first_name: this.search,
              },
              {
                last_name: this.search,
              },
              {
                email: this.search,
              },
              {
                phone: this.search,
              },
            ],
            location_contacts: this.staff_locations
              ? {
                  location_id: {
                    _in: this.staff_locations,
                  },
                }
              : undefined,
          },
          limit: this.per_page,
          offset: this.offset,
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
    contacts_aggregate: {
      fetchPolicy: 'no-cache',
      query: GET_CONTACTS_AGGREGATE,
      variables() {
        return {
          where: {
            _or: [
              {
                first_name: this.search,
              },
              {
                last_name: this.search,
              },
              {
                email: this.search,
              },
              {
                phone: this.search,
              },
            ],
            location_contacts: this.staff_locations
              ? {
                  location_id: {
                    _in: this.staff_locations,
                  },
                }
              : undefined,
          },
        }
      },
    },
  },
}
</script>
