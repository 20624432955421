<template>
  <div>
    <div v-for="(work, key) in workingHours" :key="key">
      <div class="row">
        <div class="col-md-2 col-xs-12">
          <h5 class="font-size-15 mt-2">
            {{ work.name }}
            <i class="mdi mdi-arrow-right text-primary"></i>
          </h5>
          <div class="form-check form-switch form-switch-md mb-3">
            <input
              id="SwitchCheckSizemd"
              v-model="work.active"
              class="form-check-input"
              type="checkbox"
            />
          </div>
        </div>
        <div class="col-md-10 col-xs-12">
          <div class="row">
            <div class="col-lg-3">
              <b-form-group
                id="example-time"
                class="mb-3"
                :label="$t('locations.inputs.workingHours.workStart')"
                label-for="time"
              >
                <b-form-input id="time" v-model="work.work_start" type="time"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-3">
              <b-form-group
                id="input-group-1"
                class="mb-3"
                :label="$t('locations.inputs.workingHours.workEnd')"
                label-for="time"
              >
                <b-form-input id="time" v-model="work.work_end" type="time"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-3">
              <b-form-group
                class="mb-3"
                :label="$t('locations.inputs.workingHours.restStart')"
                label-for="formrow-inputCity"
              >
                <b-form-input
                  id="formrow-inputCity"
                  v-model="work.rest_start"
                  type="time"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-lg-3">
              <b-form-group
                id="input-group-1"
                class="mb-3"
                :label="$t('locations.inputs.workingHours.restEnd')"
                label-for="formrow-inputState"
              >
                <b-form-input
                  id="formrow-inputState"
                  v-model="work.rest_end"
                  type="time"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['workingHoursData', 'locationId'],
  data: () => ({
    workingHours: [],
  }),
  computed: {
    ...mapState(['auth']),
  },
  watch: {
    workingHours: {
      handler(val) {
        this.$emit('setWorkingHours', val)
      },
      deep: true,
    },
  },
  created() {
    if (this.workingHoursData.length > 0) this.workingHours = this.workingHoursData
    else this.setWorkingHours()
  },
  methods: {
    selectHandler(val) {
      this.$emit('setLocations', val)
    },
    setWorkingHours() {
      this.workingHours = [
        {
          active: false,
          work_start: null,
          work_end: null,
          rest_start: null,
          rest_end: null,
          day: 0,
          name: this.$t('locations.inputs.workingHours.sunday'),
          location_id: this.locationId,
          team_id: this.auth.user.team_id,
        },
        {
          active: true,
          work_start: '08:00',
          work_end: '18:00',
          rest_start: '12:30',
          rest_end: '13:30',
          day: 1,
          name: this.$t('locations.inputs.workingHours.monday'),
          location_id: this.locationId,
          team_id: this.auth.user.team_id,
        },
        {
          active: true,
          work_start: '08:00',
          work_end: '18:00',
          rest_start: '12:30',
          rest_end: '13:30',
          day: 2,
          name: this.$t('locations.inputs.workingHours.tuesday'),
          location_id: this.locationId,
          team_id: this.auth.user.team_id,
        },
        {
          active: true,
          work_start: '08:00',
          work_end: '18:00',
          rest_start: '12:30',
          rest_end: '13:30',
          day: 3,
          name: this.$t('locations.inputs.workingHours.wednesday'),
          location_id: this.locationId,
          team_id: this.auth.user.team_id,
        },
        {
          active: true,
          work_start: '08:00',
          work_end: '18:00',
          rest_start: '12:30',
          rest_end: '13:30',
          day: 4,
          name: this.$t('locations.inputs.workingHours.thursday'),
          location_id: this.locationId,
          team_id: this.auth.user.team_id,
        },
        {
          active: true,
          work_start: '08:00',
          work_end: '18:00',
          rest_start: '12:30',
          rest_end: '13:30',
          day: 5,
          name: this.$t('locations.inputs.workingHours.friday'),
          location_id: this.locationId,
          team_id: this.auth.user.team_id,
        },
        {
          active: true,
          work_start: '08:00',
          work_end: '18:00',
          rest_start: '12:30',
          rest_end: '13:30',
          day: 6,
          name: this.$t('locations.inputs.workingHours.saturday'),
          location_id: this.locationId,
          team_id: this.auth.user.team_id,
        },
      ]
      this.$emit('setWorkingHours', this.workingHours)
    },
  },
}
</script>
