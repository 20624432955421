var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.contacts && _vm.contacts_aggregate)?_c('div',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('b-form',{staticClass:"row gy-2 gx-3 align-items-center",attrs:{"inline":""}},[_c('div',{staticClass:"col-sm-auto pt-1"},[_c('b-form-select',{staticClass:"form-select",model:{value:(_vm.per_page),callback:function ($$v) {_vm.per_page=$$v},expression:"per_page"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v(_vm._s(_vm.$t('lists.per_page'))+"...")]),_c('b-form-select-option',{attrs:{"value":1}},[_vm._v("1")]),_c('b-form-select-option',{attrs:{"value":10}},[_vm._v("10")]),_c('b-form-select-option',{attrs:{"value":25}},[_vm._v("25")]),_c('b-form-select-option',{attrs:{"value":50}},[_vm._v("50")]),_c('b-form-select-option',{attrs:{"value":100}},[_vm._v("100")])],1)],1),_c('div',{staticClass:"col-sm-auto"},[_c('div',{staticClass:"dataTables_filter text-end"},[_c('b-input',{attrs:{"type":"search","placeholder":_vm.$t('buttons.placeHolderSearch')},model:{value:(_vm.search_input),callback:function ($$v) {_vm.search_input=$$v},expression:"search_input"}})],1)])]),_c('button',{staticClass:"btn btn-success ms-auto",on:{"click":function($event){_vm.showModal = true}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.addCustomer'))+" ")])],1)]),_c('add-contact',{attrs:{"add-contact-modal":_vm.showModal},on:{"close":function($event){_vm.showModal = false
      _vm.$apollo.queries.contacts.refresh()}}}),_c('div',{staticClass:"col-md-12 col-xs-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.contacts)?_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table',{attrs:{"responsive":"","items":_vm.contacts,"fields":_vm.fields,"hover":"hover","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"filter":_vm.filter_b,"filter-included-fields":_vm.filterOn},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.moment(data.value).format('Do MMMM YYYY HH:ss'))+" ")]}},{key:"cell(phone)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.phone !== null ? row.item.phone.toString().charAt(0) === '+' ? row.item.phone : '+' + row.item.phone : _vm.$t('lists.unknown'))+" ")]}},{key:"cell(email)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.email !== null ? row.item.email : _vm.$t('lists.unknown'))+" ")]}},{key:"cell(last)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.event_services.length ? _vm.moment(row.item.event_services[0].event.start).format('LLL') : _vm.$t('lists.no_record'))+" ")]}},{key:"cell(locations)",fn:function(row){return [_c('div',_vm._l((row.item.location_contacts),function(location,key){return _c('p',{key:key,staticClass:"mb-0"},[_vm._v(" "+_vm._s(location.location?.name)+" ")])}),0)]}},{key:"my-link",fn:function(row){return [_c('b-link',{on:{"click":function($event){return _vm.$router.push({
                    name: 'contact.show',
                    params: { id: row.item.id },
                  })}}},[_vm._v("link")])]}},{key:"cell(name)",fn:function(row){return [(row.item.image)?_c('b-img',{staticClass:"avatar-xs rounded-circle",attrs:{"src":row.item.image.thumb,"fluid":"","alt":"image"}}):_vm._e(),_c('a',{attrs:{"href":"JavaScript:void(0)"},on:{"click":function($event){return _vm.$router.push({
                    name: 'contact.show',
                    params: { id: row.item.id },
                  })}}},[_vm._v(" "+_vm._s((row.item.first_name || '') + ' ' + (row.item.last_name || ''))+" ")])]}},{key:"cell(actions)",fn:function(row){return [_c('button',{staticClass:"btn btn-success btn-sm waves-effect waves-light",attrs:{"type":"button"},on:{"change":row,"click":function($event){return _vm.$router.push({
                    name: 'contact.show',
                    params: { id: row.item.id },
                  })}}},[_c('i',{staticClass:"bx bx-search-alt font-size-16 align-middle me-1"}),_vm._v(" "+_vm._s(_vm.$t('buttons.show'))+" ")])]}}],null,false,4024778133)}),(_vm.contacts_aggregate.aggregate.count === 0)?_c('b-alert',{staticClass:"my-1 text-center",attrs:{"show":_vm.contacts_aggregate.aggregate.count === 0,"variant":"warning"}},[_vm._v("\" "+_vm._s(_vm.$t('contacts.no_record'))+" ")]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pb-3"},[_c('div',{staticClass:"dataTables_paginate paging_simple_numbers float-end"},[_c('ul',{staticClass:"pagination pagination-rounded mb-0"},[_c('b-pagination',{attrs:{"total-rows":_vm.contacts_aggregate.aggregate.count,"per-page":_vm.per_page},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"text-center my-3"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('customerForm.total'))+" "),_c('b',[_vm._v(_vm._s(_vm.contacts_aggregate.aggregate.count))])])])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }