var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"title":_vm.$t('marketing.email_marketing'),"hide-footer":"","size":"xl"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.tags)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('marketing.marketing_name')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.marketing.name),expression:"marketing.name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.marketing.name.$error,
                  },attrs:{"id":"name","type":"text"},domProps:{"value":(_vm.marketing.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.marketing, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.marketing.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"schedule"}},[_vm._v(_vm._s(_vm.$t('marketing.date')))]),_c('VueCtkDateTimePicker',{attrs:{"color":"#2a3042","button-color":"#2a3042","button-now-translation":_vm.$t('buttons.now'),"label":_vm.$t('marketing.date'),"error":_vm.submitted && _vm.$v.marketing.schedule.$error},model:{value:(_vm.marketing.schedule),callback:function ($$v) {_vm.$set(_vm.marketing, "schedule", $$v)},expression:"marketing.schedule"}})],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t('marketing.tags')))]),_c('multiselect',{attrs:{"placeholder":_vm.$t('buttons.placeHolderSelect'),"tag-placeholder":_vm.$t('marketing.sms.tag_placeholder'),"show-labels":false,"label":"name","track-by":"id","options":_vm.tags.map((x) => {
                      return { name: x.name, id: x.id }
                    }),"multiple":true,"taggable":true},on:{"select":(opt) => _vm.addTagFilter(opt)},model:{value:(_vm.marketing.filter.tags),callback:function ($$v) {_vm.$set(_vm.marketing.filter, "tags", $$v)},expression:"marketing.filter.tags"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(" "+_vm._s(_vm.$t('lists.no_record'))+" ")]),_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(" "+_vm._s(_vm.$t('lists.no_record'))+" ")])])],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"subject"}},[_vm._v(_vm._s(_vm.$t('marketing.email.topic_title')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.marketing.subject),expression:"marketing.subject"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.marketing.subject.$error,
                  },attrs:{"id":"subject","type":"text"},domProps:{"value":(_vm.marketing.subject)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.marketing, "subject", $event.target.value)}}}),(_vm.submitted && !_vm.$v.marketing.subject.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()])])])]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"content"}},[_vm._v(_vm._s(_vm.$t('marketing.content')))]),_c('ckeditor',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.marketing.content.$error,
                  },attrs:{"id":"content","editor":_vm.editor},model:{value:(_vm.marketing.content),callback:function ($$v) {_vm.$set(_vm.marketing, "content", $$v)},expression:"marketing.content"}}),(_vm.submitted && !_vm.$v.marketing.content.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('fields.required'))+" ")]):_vm._e()],1)])])])]),_c('div',{staticClass:"text-end mt-3"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('buttons.close')))]),_c('b-button',{staticClass:"ms-1",attrs:{"type":"submit","variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('buttons.submit'))+" ")])],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }