<template>
  <div v-if="genders">
    <b-modal
      v-model="showModal"
      :title="$t('navbar.dropdown.quick_menu.add_customer')"
      hide-footer
      :size="advancedForm ? 'xl' : 'md'"
    >
      <template #modal-header>
        <div class="w-100">
          <div class="row">
            <div class="col-md-6">
              <h4 class="card-title">{{ $t('buttons.addCustomer') }}</h4>
            </div>
            <div class="col-md-6">
              <div class="form-check form-switch float-end">
                <input
                  id="flexSwitchCheckDefault"
                  v-model="advancedForm"
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                />
                <label class="form-check-label" for="flexSwitchCheckDefault">
                  {{ advancedForm ? $t('contacts.advanced') : $t('contacts.basic') }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </template>
      <Loader :loading="updating">
        <contact-form v-if="advancedForm" />
        <form v-else @submit.prevent="handleSubmit">
          <div class="row">
            <div class="col-6">
              <div class="mb-3">
                <label for="first_name">{{ $t('customerForm.name') }}</label>
                <input
                  id="first_name"
                  v-model="newContacts.first_name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.newContacts.first_name.$error,
                  }"
                />
                <div
                  v-if="submitted && !$v.newContacts.first_name.required"
                  class="invalid-feedback"
                >
                  {{ $t('fields.required') }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="last_name">{{ $t('customerForm.surname') }}</label>
                <input
                  id="last_name"
                  v-model="newContacts.last_name"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <b-form-group
                  class="mb-3"
                  :label="$t('customerForm.phone')"
                  label-for="formrow-password-input"
                >
                  <tel-input v-model="newContacts.phone" />
                </b-form-group>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="email">{{ $t('customerForm.email') }}</label>
                <input
                  id="email"
                  v-model="newContacts.email"
                  type="email"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.newContacts.email.$error,
                  }"
                />
                <div v-if="submitted && !$v.newContacts.email.required" class="invalid-feedback">
                  {{ $t('fields.required') }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <label for="gender_id">{{ $t('customerForm.gender') }}</label>
              <multiselect
                v-model="newContacts.gender_id"
                :show-labels="false"
                :placeholder="$t('customerForm.gender')"
                :options="genders.map((x) => x.id)"
                :custom-label="
                  (opt) => {
                    return $t(`gender.${genders.find((x) => x.id == opt).name}`)
                  }
                "
                :multiple="false"
              >
                <span slot="noResult">
                  {{ $t('lists.no_record') }}
                </span>
                <span slot="noOptions">
                  {{ $t('lists.no_record') }}
                </span>
              </multiselect>
            </div>
            <div class="col-6">
              <locations-input
                :input-data="newContacts.location_contacts"
                :is-invalid="submitted && !locationIsValid"
                @setLocations="setLocations"
              />
            </div>
            <div class="col-12">
              <b-form-group class="my-3" :label="$t('customerForm.image')" for="fileinput">
                <b-form-file
                  id="fileinput"
                  v-model="imageFile"
                  :state="Boolean(imageFile)"
                  accept="image/jpeg, image/png, image/jpg"
                  placeholder="Choose an image or drop it here..."
                  drop-placeholder="Drop file here..."
                  @input="fileUpload"
                ></b-form-file>
              </b-form-group>
            </div>
            <!-- notifications -->
            <div v-if="newContacts.subscriptions" class="col-12">
              <h4 class="card-title">{{ $t('notification.notifications') }}</h4>
            </div>
            <div class="col-12 col-lg-6">
              <label class="mb-3">{{ $t('notification.appointment_reminders') }}</label>
              <div class="form-check form-switch">
                <input
                  id="client_email"
                  v-model="newContacts.subscriptions.COMMON.email"
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                />
                <label class="form-check-label" for="client_email">
                  {{ $t('notification.email_allow') }}
                </label>
              </div>
              <div class="form-check form-switch">
                <input
                  id="client_sms"
                  v-model="newContacts.subscriptions.COMMON.sms"
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                />
                <label class="form-check-label" for="client_sms">
                  {{ $t('notification.sms_allow') }}
                </label>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <label class="mb-3">{{ $t('notification.marketing') }}</label>
              <div class="form-check form-switch">
                <input
                  id="marketing_email"
                  v-model="newContacts.subscriptions.MARKETING.email"
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                />
                <label class="form-check-label" for="marketing_email">
                  {{ $t('notification.email_marketing_allow') }}
                </label>
              </div>
              <div class="form-check form-switch">
                <input
                  id="marketing_sms"
                  v-model="newContacts.subscriptions.MARKETING.sms"
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                />
                <label class="form-check-label" for="marketing_sms">
                  {{ $t('notification.sms_marketing_allow') }}
                </label>
              </div>
            </div>
          </div>
          <div class="text-end mt-3">
            <b-button variant="light" @click="$emit('close')">{{ $t('buttons.close') }}</b-button>
            <b-button type="submit" variant="success" class="ms-1">{{
              $t('customerForm.create_customer')
            }}</b-button>
          </div>
        </form>
      </Loader>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import Loader from './loader'
import ContactForm from '@/components/widgets/contactForm'
import {
  ADD_CONTACT,
  CHECK_USER,
  CREATE_USER_WITH_CONTACT,
  GET_GENDERS,
  GET_LOCATIONS,
} from '@/router/queries'
import { uploadService } from '@/helpers/upload.service.js'

export default {
  components: { Multiselect, Loader, ContactForm },
  props: {
    addContactModal: {
      type: Boolean,
    },
    updating: {
      type: Boolean,
    },
  },
  data() {
    return {
      newContacts: null,
      submitted: false,
      contactID: null,
      advancedForm: null,
      locationChanged: false,
      locationIsValid: true,
      imageFile: null,
    }
  },
  validations: {
    newContacts: {
      first_name: { required },
      email: { required, email },
    },
  },
  computed: {
    ...mapState(['auth']),
    showModal: {
      get() {
        return this.addContactModal
      },
      set() {
        this.$emit('close')
      },
    },
  },
  created() {
    this.newContacts = {
      first_name: null,
      last_name: null,
      phone: null,
      gender_id: null,
      team_id: this.auth.user.team_id,
      email: null,
      image: null,
      location_contacts: {
        data: [],
      },
      subscriptions: {
        COMMON: { sms: true, call: true, push: true, email: true, whatsapp: true },
        MARKETING: { sms: true, call: true, push: true, email: true, whatsapp: true },
        NEWSLETTER: { sms: true, call: true, push: true, email: true, whatsapp: true },
        COMPANY_NEWS: { sms: true, call: true, push: true, email: true, whatsapp: true },
      },
    }
  },
  methods: {
    setLocations(val) {
      this.locationChanged = true
      const locations = []
      val.forEach((el) => {
        locations.push({
          location_id: el.id,
          team_id: this.auth.user.team_id,
        })
      })
      this.newContacts.location_contacts.data = locations
    },
    async handleSubmit() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.newContacts.location_contacts.data.length === 0) {
        this.locationIsValid = false
      } else this.locationIsValid = true
      if (this.$v.$invalid || !this.locationIsValid) {
        this.$loading(false)
        return
      } else {
        if (this.newContacts.email) {
          if ((await this.checkUser()) === false) {
            this.$loading(false)
            this.$_D({
              type: 'error',
              position: 'center',
              text: this.$t('customerCard.email_registered'),
            })
            return
          }
          this.newContacts.password = (Math.random() + 1).toString(36).substring(2)
          this.newContacts.need_update_profile = true
          this.newContacts.user_id = this.auth.user.id
          this.$apollo.mutate({
            mutation: CREATE_USER_WITH_CONTACT,
            variables: {
              ...this.newContacts,
            },
            update: (cache, { data: { user } }) => {
              try {
                this.contactID = user.contact.id
                this.$_N({
                  type: 'success',
                  position: 'center',
                })
                this.$emit('close', this.contactID)
                this.$loading(false)
                this.$delete(this.newContacts, 'password')
                this.$delete(this.newContacts, 'need_update_profile')
              } catch (e) {
                this.$_N({
                  type: 'error',
                  position: 'center',
                })
              }
            },
          })
        } else {
          this.newContacts.user_id = this.auth.user.id
          this.$apollo.mutate({
            mutation: ADD_CONTACT,
            variables: {
              contact: this.newContacts,
            },
            update: (cache, { data: { insert_contacts_one } }) => {
              try {
                this.$loading(false)
                this.contactID = insert_contacts_one.id
                this.$_N({
                  type: 'success',
                  position: 'center',
                })
                this.$emit('close', this.contactID)
                this.$loading(false)
              } catch (e) {
                this.$_N({
                  type: 'error',
                  position: 'center',
                })
              }
            },
          })
        }
      }
      this.submitted = false
    },
    async fileUpload(file) {
      this.$loading(true)
      try {
        const image = await uploadService({
          file: file,
          name: this.newContacts.last_name + '-contact-image',
          team_id: this.auth.user.team_id,
          user_id: this.auth.user.id,
        })
        this.newContacts.image = image
      } catch (err) {
        this.$_N({
          type: 'error',
          position: 'center',
        })
      }
      this.$loading(false)
    },
    async checkUser() {
      let status = false
      await this.$apollo
        .mutate({
          mutation: CHECK_USER,
          variables: {
            email: this.newContacts.email,
          },
        })
        .then((res) => {
          if (res.data.user.aggregate.count > 0) {
            status = false
          } else status = true
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
          status = false
        })
      return status
    },
  },
  apollo: {
    genders: {
      fetchPolicy: 'no-cache',
      query: GET_GENDERS,
      // pollInterval: 5000,
    },
    locations: {
      fetchPolicy: 'no-cache',
      query: GET_LOCATIONS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            is_disable: { _eq: false },
          },
        }
      },
      result() {
        if (this.locations.length === 1) {
          const obj = {
            location_id: this.locations[0].id,
            team_id: this.auth.user.team_id,
          }
          this.newContacts.location_contacts.data.push(obj)
        }
      },
    },
  },
}
</script>
