<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-12">
        <div class="mb-3">
          <label for="last_name">{{ $t('bundle.invoice.trader') }}</label>
          <input type="text" class="form-control" disabled :value="auth.user.email" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="voucher_date">{{ $t('tables.voucher_date') }}</label>
          <date-picker
            v-model="activities.voucher_date"
            type="datetime"
            value-type="YYYY-MM-DD HH:mm"
            format="DD/MM/YYYY HH:mm"
            :class="{
              'is-invalid': submitted && $v.activities.voucher_date.$error,
            }"
          ></date-picker>
          <div v-if="submitted && !$v.activities.voucher_date.required" class="invalid-feedback">
            {{ $t('fields.required') }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <label for="price">{{ $t('tables.amount') }}</label>
        <input
          id="price"
          v-model="activities.price"
          :placeholder="$t('fields.example') + ': 100'"
          class="form-control"
          type="number"
          step="0.01"
          :class="{
            'is-invalid': submitted && $v.activities.price.$error,
          }"
        />
        <div v-if="submitted && !$v.activities.price.required" class="invalid-feedback">
          {{ $t('fields.required') }}
        </div>
      </div>
      <div class="col-6">
        <div class="form-group mb-0">
          <label for="price_type">{{ $t('customerForm.currency') }}</label>
          <multiselect
            id="price_type"
            v-model="activities.price_type"
            :show-labels="false"
            :placeholder="$t('buttons.placeHolderSelect')"
            :options="$_CURRENCIES"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.activities.price_type.$error,
            }"
          ></multiselect>
          <div v-if="submitted && !$v.activities.price_type.required" class="invalid-feedback">
            {{ $t('fields.required') }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group mb-0">
          <label for="payment_method">{{ $t('case.payment_method') }}</label>
          <multiselect
            id="payment_method"
            v-model="activities.payment_method"
            :show-labels="false"
            :placeholder="$t('buttons.placeHolderSelect')"
            :options="paymentMethod.map((x) => x.key)"
            :custom-label="(opt) => paymentMethod.find((x) => x.key == opt).name"
            :class="{
              'is-invalid': submitted && $v.activities.payment_method.$error,
            }"
          ></multiselect>
          <div v-if="submitted && !$v.activities.payment_method.required" class="invalid-feedback">
            {{ $t('fields.required') }}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3 mt-3">
          <label for="description">{{ $t('appointment.explanation') }}</label>
          <b-form-textarea
            id="description"
            v-model="activities.description"
            :placeholder="$t('bundle.invoice.enter_installment_details')"
            rows="3"
            :class="{
              'is-invalid': submitted && $v.activities.description.$error,
            }"
          ></b-form-textarea>
          <div v-if="submitted && !$v.activities.description.required" class="invalid-feedback">
            {{ $t('fields.required') }}
          </div>
        </div>
      </div>
    </div>
    <div class="text-end mt-3">
      <b-button variant="light" @click="$emit('close')">{{ $t('buttons.close') }}</b-button>
      <b-button type="submit" variant="success" class="ms-1"> {{ $t('buttons.add') }}</b-button>
    </div>
  </form>
</template>

<script>
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'
import { INSERT_VAULT_ACTIVITIES, GET_CONTACTS } from '@/router/queries'
export default {
  name: 'Invoice',
  components: {
    Multiselect,
    DatePicker,
  },
  props: ['data', 'keyID'],
  data() {
    return {
      submitted: false,
      activities: null,
      moment,
    }
  },
  computed: {
    ...mapState(['auth']),
    paymentMethod() {
      return [
        {
          key: 'credit_card',
          name: this.$t('case.credit_card'),
        },
        {
          key: 'transfer',
          name: this.$t('case.transfer'),
        },
        {
          key: 'cash',
          name: this.$t('case.cash'),
        },
      ]
    },
  },
  created() {
    this.$_loading()
    this.activities = {
      price: this.data.price,
      voucher_date: moment().format('YYYY-MM-DD HH:mm'),
      type: 'income',
      price_type: null,
      description: null,
      payment_method: null,
      bundle_payment_id: this.data.id,
      contact_id: this.data.contact_id,
      user_id: this.auth.user.id,
      team_id: this.auth.user.team_id,
    }
  },
  validations: {
    activities: {
      voucher_date: { required },
      price: { required },
      description: { required },
      price_type: { required },
      payment_method: { required },
    },
  },
  methods: {
    handleSubmit() {
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$loading(false)
        return
      } else {
        this.$apollo.mutate({
          mutation: INSERT_VAULT_ACTIVITIES,
          variables: {
            vault_activities: this.activities,
          },
          update: () => {
            try {
              this.$_N({
                type: 'success',
                position: 'center',
              })
              this.$loading(false)
            } catch (e) {
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          },
        })
        this.$emit('updated', this.keyID)
        this.$emit('close')
      }
      this.submitted = false
    },
  },
  apollo: {
    contacts: {
      fetchPolicy: 'no-cache',
      query: GET_CONTACTS,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>
