var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.events && _vm.users && _vm.services)?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"calc(100vh - 168px)","min-height":"30rem"}},[_c('b-collapse',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode !== 'edit'),expression:"mode !== 'edit'"}],model:{value:(_vm.showFilters),callback:function ($$v) {_vm.showFilters=$$v},expression:"showFilters"}},[_c('div',{staticClass:"card row flex-row mb-3 mx-0 p-3 gy-2 gx-3 align-items-center"},[_c('div',{staticClass:"col-md-2"},[(_vm.locations)?_c('multiselect',{attrs:{"placeholder":_vm.$t('calendar.location_search'),"show-labels":false,"options":_vm.locations.map((x) => x.id),"custom-label":(opt) => _vm.locations.find((x) => x.id === opt).name},on:{"input":function($event){_vm.user_select_id = false
            _vm.$apollo.subscriptions.events.stop()
            _vm.$apollo.queries.staticEvents.stop()
            _vm.filter()}},model:{value:(_vm.locationFilter),callback:function ($$v) {_vm.locationFilter=$$v},expression:"locationFilter"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(_vm._s(_vm.$t('lists.no_record')))]),_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('lists.no_record')))])]):_vm._e()],1),_c('div',{staticClass:"col-md-2"},[_c('multiselect',{attrs:{"placeholder":_vm.$t('calendar.staff_search'),"show-labels":false,"options":_vm.users
              .filter((x) => (!_vm.locationFilter ? true : x.locationIds.includes(_vm.locationFilter)))
              .map((x) => x.id),"custom-label":(opt) =>
              _vm.users.find((x) => x.id === opt).first_name +
              ' ' +
              _vm.users.find((x) => x.id === opt).last_name},on:{"input":function($event){_vm.$apollo.subscriptions.events.stop()
            _vm.$apollo.queries.staticEvents.stop()}},model:{value:(_vm.user_select_id),callback:function ($$v) {_vm.user_select_id=$$v},expression:"user_select_id"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(_vm._s(_vm.$t('lists.no_record')))]),_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('lists.no_record')))])])],1),_c('div',{staticClass:"col-md-2"},[_c('multiselect',{attrs:{"placeholder":_vm.$t('calendar.appointment_type'),"show-labels":false,"options":_vm.onlineOptions.map((x) => x.value),"custom-label":(opt) => _vm.onlineOptions.find((x) => x.value === opt).label},on:{"input":function($event){_vm.$apollo.subscriptions.events.stop()
            _vm.$apollo.queries.staticEvents.stop()}},model:{value:(_vm.is_online),callback:function ($$v) {_vm.is_online=$$v},expression:"is_online"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(_vm._s(_vm.$t('lists.no_record')))]),_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('lists.no_record')))])])],1),_c('div',{staticClass:"col-md-2"},[_c('b-input',{attrs:{"placeholder":_vm.$t('calendar.customer')},on:{"input":function($event){_vm.$apollo.subscriptions.events.stop()
            _vm.$apollo.queries.staticEvents.stop()}},model:{value:(_vm.search_input),callback:function ($$v) {_vm.search_input=$$v},expression:"search_input"}})],1),_c('div',{staticClass:"col-sm-auto"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.filter}},[_vm._v(_vm._s(_vm.$t('buttons.search')))])],1),_c('div',{staticClass:"col-sm-auto"},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.clear()}}},[_vm._v(_vm._s(_vm.$t('buttons.reset')))])],1)])]),_c('div',{staticClass:"row flex-grow-1"},[_c('div',{staticClass:"col-12 px-0 px-sm-2"},[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"app-calendar h-100"},[_c('FullCalendar',{ref:"fullCalendar",attrs:{"options":_vm.calendarOptions}})],1)])])])]),(_vm.datePickerModal)?_c('b-modal',{attrs:{"title":_vm.$t('calendar.select_date'),"title-class":"text-black font-18","body-class":"p-3","hide-footer":""},model:{value:(_vm.datePickerModal),callback:function ($$v) {_vm.datePickerModal=$$v},expression:"datePickerModal"}},[_c('div',[_c('VueCtkDateTimePicker',{attrs:{"id":"event-date","only-date":"","formatted":"LL","color":"#2a3042","button-color":"#2a3042","button-now-translation":_vm.$t('buttons.now'),"label":_vm.$t('fields.choose')},on:{"input":_vm.changeDate},model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}})],1)]):_vm._e(),(_vm.addEventModal)?_c('add-event',{attrs:{"add-event-modal":_vm.addEventModal,"appointment-data":_vm.eventData},on:{"closeModal":_vm.handleCloseModal}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }