<template>
  <div class="table-responsive mt-3">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">{{ $t('form.answers.user') }}</th>
          <th scope="col">{{ $t('form.form') }}</th>
          <th scope="col">{{ $t('form.answers.reply_date') }}</th>
          <th scope="col">{{ $t('form.answers.service_name') }}</th>
          <th scope="col">{{ $t('form.answers.appointment_details') }}</th>
          <th scope="col">{{ $t('tables.action') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(answer, key) in form_answers" :key="key">
          <td>{{ answer.contact.first_name + ' ' + answer.contact.last_name }}</td>
          <td>{{ answer.form.name }}</td>
          <td>{{ moment(answer.created_at).format('Do MMMM YYYY HH:ss') }}</td>
          <td>{{ answer.service_id !== null ? answer.service.name : answer.product.name }}</td>
          <td>{{ moment(answer.event.start).format('Do MMMM YYYY HH:ss') }}</td>
          <td>
            <button
              type="button"
              class="btn btn-success btn-sm waves-effect waves-light"
              @click="
                answerModal[key] = !answerModal[key]
                $forceUpdate()
              "
            >
              <i class="bx bx-show font-size-14 align-middle mr-2"></i>
            </button>
            <b-modal
              v-model="answerModal[key]"
              hide-footer
              :title="$t('form.answers.answer_details')"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">{{ $t('form.questions.question') }}</th>
                    <th scope="col">{{ $t('form.answers.answer') }}</th>
                    <th scope="col">{{ $t('form.answers.reply_date') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(answerDetail, detailKey) in answer.form_answer_fields"
                    :key="detailKey"
                  >
                    <td>{{ answerDetail.form_field_name }}</td>
                    <td>{{ answerDetail.content }}</td>
                    <td>{{ moment(answerDetail.created_at).format('Do MMMM YYYY HH:ss') }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="text-end">
                <b-button @click="answerModal = {}">{{ $t('buttons.close') }}</b-button>
              </div>
            </b-modal>
          </td>
        </tr>
      </tbody>
    </table>
    <b-alert v-if="!form_answers.length" show variant="warning">{{
      $t('form.answers.no_answer')
    }}</b-alert>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { GET_ANSWERS } from '@/router/queries'

export default {
  name: 'Forms',
  data() {
    return {
      moment,
      answerModal: {},
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  created() {
    this.$_loading()
  },
  apollo: {
    form_answers: {
      fetchPolicy: 'no-cache',
      query: GET_ANSWERS,
      variables() {
        return {
          where: {
            form_id: {
              _eq: this.$route.params.key,
            },
          },
        }
      },
    },
  },
}
</script>
