import { userService } from '../../helpers/authservice/user.service'
import router from '../../router/index'

const user = JSON.parse(localStorage.getItem('user'))
export const state = user ? { status: { loggeduser: true }, user } : { status: {}, user: null }

export const actions = {
  // Logs in the user.
  // eslint-disable-next-line no-unused-vars
  login({ dispatch, commit }, user) {
    commit('loginRequest', user)

    return userService
      .login(user)
      .then((user) => {
        commit('loginSuccess', user)
        dispatch('notification/success', 'Login successful. You are being redirected..', {
          root: true,
        })
        setTimeout(function () {
          if (user.need_update_profile) {
            window.location.href = `${window.location.origin}/staff/${user.id}/profile`
            router.push({
              name: 'staff.show',
              params: { id: user.id },
            })
          } else {
            window.location.href = window.location.origin
            router.push('/')
          }
        }, 2000)
      })
      .catch((error) => {
        console.log('error')
        commit('loginFailure', error)
        dispatch('notification/error', error, { root: true })
      })
  },

  // Logout the user
  logout({ commit }) {
    userService.logout()
    commit('logout')
  },

  // register the user
  registeruser({ dispatch, commit }, user) {
    commit('registerRequest', user)
    userService.register(user).then(
      (user) => {
        commit('registerSuccess', user)
        dispatch('notification/success', 'Registration successful', {
          root: true,
        })
        router.push('/login')
      },
      (error) => {
        commit('registerFailure', error)
        dispatch('notification/error', error, { root: true })
      }
    )
  },

  forgetPassword({ dispatch, commit }, user) {
    userService.forgetPassword(user).then(
      (data) => {
        console.log('user in forgetPassword store :>> ', data)
        commit('registerSuccess')
        dispatch('notification/success', data.message, {
          root: true,
        })
      },
      (error) => {
        commit('loginFailure', error)
        dispatch('notification/error', { root: true })
      }
    )
  },

  resetPassword({ dispatch, commit }, user) {
    userService.resetPassword(user).then(
      (data) => {
        console.log('user in resetPassword store :>> ', data)
        commit('registerSuccess')
        dispatch('notification/success', data.message, {
          root: true,
        })
        // setTimeout(function () {
        //   window.location.href = window.location.origin
        //   router.push('/')
        // }, 2000)
      },
      (error) => {
        commit('loginFailure', error)
        dispatch('notification/error', { root: true })
      }
    )
  },
}

export const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true }
    state.user = user
  },
  loginSuccess(state, user) {
    state.status = { loggeduser: true }
    state.user = user
  },
  loginFailure(state) {
    state.status = {}
    state.user = null
  },
  logout(state) {
    state.status = {}
    state.user = null
  },
  registerRequest(state) {
    state.status = { registering: true }
  },
  registerSuccess(state) {
    state.status = {}
    state.user = null
  },
  registerFailure(state) {
    state.status = {}
    state.user = null
  },
}
