<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-10 col-lg-10 col-xl-8">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-3 pt-5">
                  <h4 class="text-primary">Barespace</h4>
                  <p>Online Randevu Sistemi</p>
                  <p>adresss</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="@/assets/images/img-4.png" alt height="60" />
                </span>
              </div>
            </div>
            <b-form-group id="input-group-1" class="mb-3" label="Email" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="email"
                type="text"
                placeholder="Enter email"
                :class="{ 'is-invalid': submitted && $v.email.$error }"
              ></b-form-input>
              <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                <span v-if="!$v.email.required">Email is required.</span>
                <span v-if="!$v.email.email">Please enter valid email.</span>
              </div>
            </b-form-group>

            <vue-meeting-selector
              v-model="meeting"
              class="simple-example__meeting-selector"
              :date="date"
              :loading="loading"
              :class-names="classNames"
              :meetings-days="meetingsDays"
              @next-date="nextDate"
              @previous-date="previousDate"
            />
            <p>
              meeting Selected:
              {{ meeting ? meeting : 'No Meeting selected' }}
            </p>

            <div class="mt-3 d-grid">
              <b-button type="submit" variant="primary" class="btn-block">Log In</b-button>
            </div>
          </div>
        </div>

        <div class="mt-5 text-center">
          <p>
            © {{ new Date().getFullYear() }} Barespace
            <i class="mdi mdi-heart text-danger"></i>
          </p>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/auth'
import appConfig from '@/app.config'
import VueMeetingSelector from 'vue-meeting-selector'
import data from './data.json'

function getNewDates() {
  return data
}

export default {
  page: {
    title: 'Book',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    VueMeetingSelector,
  },
  data() {
    return {
      date: new Date(),
      meetingsDays: [],
      meeting: { date: '2021-09-4T08:00:00.000Z' },
      loading: true,
      nbDaysToDisplay: 5,
    }
  },
  computed: {
    classNames() {
      return {
        tabLoading: 'loading-div',
      }
    },
  },
  async created() {
    this.meetingsDays = await getNewDates(this.date)
    this.loading = false
    this.$loading(false)
  },
  methods: {
    async nextDate() {
      this.loading = true
      this.meetingsDays = await getNewDates(this.date)
      this.loading = false
    },
    async previousDate() {
      this.loading = true
      this.meetingsDays = await getNewDates(this.date)
      this.loading = false
    },
  },
}
</script>

<style lang="scss" module>
.simple-example {
  margin-top: 3em;
  &__meeting-selector {
    max-width: 542px;
  }
}
// since our scss is scoped we need to use ::v-deep
::v-deep .loading-div {
  top: 58px !important;
}
</style>
