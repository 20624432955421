<template>
  <div>
    <b-modal
      v-model="showModal"
      :title="$t('staff.edit_staff_working_hours', { user: itemData.user.first_name })"
      hide-footer
    >
      <div class="row">
        <div class="col-12 h5 mb-4">
          {{ showDayFormat(getDay()) }}
        </div>
      </div>
      <form @submit.prevent="handleSubmit">
        <div
          v-for="(shift, index) in workingHoursData"
          :key="index"
          class="d-flex align-items-center justify-content-center mb-4"
        >
          <div class="col-5">
            <date-picker
              v-model="shift.start"
              type="time"
              format="hh:mm A"
              value-type="HH:mm:ss"
              :minute-step="5"
              placeholder="Select date"
              :time-picker-options="{
                start: '00:00',
                step: '00:05',
                end: shift.end ? shift.end : '23:55',
                format: 'hh:mm A',
              }"
            />
          </div>
          <div class="text-center mx-3">-</div>
          <div class="col-5">
            <date-picker
              v-model="shift.end"
              type="time"
              format="hh:mm A"
              value-type="HH:mm:ss"
              :minute-step="5"
              placeholder="Select date"
              :time-picker-options="{
                start: shift.start ? shift.start : '00:00',
                step: '00:05',
                end: '23:55',
                format: 'hh:mm A',
              }"
            />
          </div>
          <div class="col-1 text-center ms-2">
            <i
              v-if="workingHoursData.length === 1"
              class="bx bx-plus-circle font-size-24 align-middle text-info"
              style="cursor: pointer"
              @click="addShift"
            />
            <i
              v-else-if="index === workingHoursData.length - 1"
              class="bx bx-minus-circle font-size-24 align-middle text-secondary"
              style="cursor: pointer"
              @click="workingHoursData.pop()"
            />
          </div>
        </div>
        <div class="row">
          <div :class="repeat === 'weekly' ? 'col-6' : 'col-12'">
            <label>{{ $t('staff.working_hours.repeat') }}</label>
            <multiselect
              v-model="repeatType"
              :show-labels="false"
              :placeholder="$t('fields.choose')"
              :options="repeatTypes"
              label="title"
              track-by="value"
              :clear-on-select="false"
              :multiple="false"
              :allow-empty="false"
              @input="(el) => (repeat = el.value)"
            >
              <span slot="noResult">
                {{ $t('lists.no_record') }}
              </span>
              <span slot="noOptions">
                {{ $t('lists.no_record') }}
              </span>
            </multiselect>
          </div>
          <div v-if="repeat === 'weekly'" class="col-6">
            <label>{{ $t('staff.working_hours.end_repeat') }}</label>
            <date-picker
              v-if="endRepeat === 'specific_date'"
              v-model="endRepeatDate"
              type="date"
              format="YYYY-MM-DD"
              value-type="YYYY-MM-DD"
              placeholder="Select date"
              lang="en"
              :show-week-number="false"
            />
            <multiselect
              v-else
              v-model="endRepeatType"
              :show-labels="false"
              :placeholder="$t('fields.choose')"
              :options="endRepeatTypes"
              label="title"
              track-by="value"
              :clear-on-select="false"
              :multiple="false"
              :allow-empty="false"
              @input="(el) => (endRepeat = el.value)"
            >
              <span slot="noResult">
                {{ $t('lists.no_record') }}
              </span>
              <span slot="noOptions">
                {{ $t('lists.no_record') }}
              </span>
            </multiselect>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-4">
          <div>
            <b-button variant="danger" @click="handleDelete">
              {{ $t('buttons.delete') }}
            </b-button>
          </div>
          <div>
            <b-button variant="light" @click="showModal = false">
              {{ $t('buttons.close') }}
            </b-button>
            <b-button
              type="submit"
              variant="success"
              class="ms-1"
              :disabled="repeat === 'weekly' && endRepeat === 'specific_date' && !endRepeatDate"
              >{{ $t('buttons.save') }}</b-button
            >
          </div>
        </div>
      </form>
    </b-modal>
    <!-- select update type modal -->
    <b-modal
      v-model="updateTypeModal"
      :title="$t('staff.update_staff_working_hours', { date: showDayFormat(getDay()) })"
      hide-footer
    >
      <form @submit.prevent="update">
        <div class="my-3 d-flex">
          <input
            id="this_shift"
            v-model="updateType"
            type="radio"
            name="update_type"
            value="this_shift"
          />
          <label for="this_shift" style="margin: 0 7px">
            {{ $t('staff.working_hours.update_this_shift_only') }}
          </label>
        </div>
        <div class="my-3 d-flex">
          <input
            id="upcoming"
            v-model="updateType"
            type="radio"
            name="update_type"
            value="upcoming"
          />
          <label for="upcoming" style="margin: 0 7px">
            {{ $t('staff.working_hours.update_upcoming_shifts') }}
          </label>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="updateTypeModal = false">
            {{ $t('buttons.close') }}
          </b-button>
          <b-button type="submit" variant="success" :disabled="!updateType" class="ms-1">
            {{ $t('buttons.save') }}
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- select delete type modal -->
    <b-modal
      v-model="deleteTypeModal"
      :title="$t('staff.delete_staff_working_hours', { date: showDayFormat(getDay()) })"
      hide-footer
    >
      <form @submit.prevent="deleteShift">
        <div class="my-3 d-flex">
          <input
            id="this_shift"
            v-model="deleteType"
            type="radio"
            name="update_type"
            value="this_shift"
          />
          <label for="this_shift" style="margin: 0 7px">
            {{ $t('staff.working_hours.delete_this_shift_only') }}
          </label>
        </div>
        <div class="my-3 d-flex">
          <input
            id="upcoming"
            v-model="deleteType"
            type="radio"
            name="update_type"
            value="upcoming"
          />
          <label for="upcoming" style="margin: 0 7px">
            {{ $t('staff.working_hours.delete_upcoming_shifts') }}
          </label>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="deleteTypeModal = false">
            {{ $t('buttons.close') }}
          </b-button>
          <b-button type="submit" variant="success" :disabled="!deleteType" class="ms-1">
            {{ $t('buttons.save') }}
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'
import moment from 'moment'
import {
  INSERT_LOCATION_USER_WORKING_HOURS,
  UPDATE_LOCATION_USER_WORKING_HOURS,
  UPDATE_LOCATION_USER_WORKING_HOURS_SIFT_START_DAY,
  GET_USER_SHIFTS_BY_DAY,
  DELETE_USER_SHIFT,
  INSERT_USER_SHIFTS,
  UPDATE_USER_PAST_SHIFTS,
  DELETE_USER_UPCOMING_SHIFTS,
} from '@/router/queries'

export default {
  components: { DatePicker },
  props: {
    editStaffWorkingHours: {
      type: Boolean,
    },
    itemData: {
      type: Object,
      default: () => {},
    },
    locationId: {
      type: String,
      default: '',
    },
    allShifts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      endRepeatDate: '',
      updateType: '',
      isUpdate: false,
      updateTypeModal: false,
      deleteType: '',
      deleteTypeModal: false,
      updatedShift: false,
      workingHoursData: [
        {
          start: '09:00:00',
          end: '17:00:00',
        },
      ],
      repeatType: {
        title: this.$t('staff.working_hours.weekly'),
        value: 'weekly',
      },
      repeat: 'weekly',
      endRepeatType: {
        title: this.$t('staff.working_hours.on_going'),
        value: 'on_going',
      },
      endRepeat: 'on_going',
      repeatTypes: [
        {
          title: this.$t('staff.working_hours.weekly'),
          value: 'weekly',
        },
        {
          title: this.$t('staff.working_hours.no_repeat'),
          value: 'no_repeat',
        },
      ],
      endRepeatTypes: [
        {
          title: this.$t('staff.working_hours.on_going'),
          value: 'on_going',
        },
        {
          title: this.$t('staff.working_hours.specific_date'),
          value: 'specific_date',
        },
      ],
    }
  },
  computed: {
    ...mapState(['auth']),
    showModal: {
      get() {
        return this.editStaffWorkingHours
      },
      set() {
        this.$emit('close')
      },
    },
  },
  created() {
    if (this.itemData.shifts !== undefined) {
      this.isUpdate = true
      this.workingHoursData = [
        {
          start: this.itemData.shifts.shift_start_time,
          end: this.itemData.shifts.shift_end_time,
        },
      ]
      if (this.itemData.shifts.second_shift_start_time) {
        this.workingHoursData.push({
          start: this.itemData.shifts.second_shift_start_time,
          end: this.itemData.shifts.second_shift_end_time,
        })
      }
      if (this.itemData.shifts.date_end !== '2222-12-30') {
        if (
          this.itemData.shifts.date_start === this.isoDayFormat(this.itemData.week.start) &&
          this.itemData.shifts.date_end === this.isoDayFormat(this.itemData.week.end)
        ) {
          this.updatedShift = true
          this.repeat = 'no_repeat'
          this.repeatType = {
            title: this.$t('staff.working_hours.no_repeat'),
            value: 'no_repeat',
          }
        } else {
          this.repeat = 'weekly'
          this.endRepeat = 'specific_date'
          this.endRepeatDate = moment(this.itemData.shifts.date_end)
            .subtract(7, 'day')
            .format('YYYY-MM-DD')
        }
      } else {
        this.repeat = 'weekly'
        this.endRepeat = 'on_going'
      }
    }
  },
  methods: {
    handleSubmit() {
      this.setEndDate()
      if (this.isUpdate) {
        if (this.updatedShift) this.update()
        else this.updateTypeModal = true
      } else this.create()
    },
    async create() {
      this.$loading(true)
      await this.deleteUpComingShifts()
      await this.$apollo
        .mutate({
          mutation: INSERT_LOCATION_USER_WORKING_HOURS,
          variables: {
            data: {
              location_id: this.locationId,
              team_id: this.auth.user.team_id,
              user_id: this.itemData.user.id,
              day_of_week: this.itemData.day_of_week + '',
              date_start: this.isoDayFormat(this.itemData.week.start),
              date_end: this.setEndDate(),
              shift_start_time: this.workingHoursData[0].start,
              shift_end_time: this.workingHoursData[0].end,
              second_shift_start_time: this.workingHoursData[1]
                ? this.workingHoursData[1].start
                : null,
              second_shift_end_time: this.workingHoursData[1] ? this.workingHoursData[1].end : null,
            },
          },
        })
        .then(() => {
          this.$emit('submitted')
          this.$loading(false)
          this.$_N({
            position: 'center',
            type: 'success',
          })
        })
        .catch(() => {
          this.$_N({
            position: 'center',
            type: 'error',
          })
          this.$loading(false)
        })
    },
    update() {
      if (this.updatedShift) {
        this.editUpdatedShift()
      } else {
        if (this.updateType === 'this_shift') this.updateShift()
        else this.updateUpComingShifts()
      }
    },
    async updateShift() {
      this.$loading(true)
      await this.createEditedDayShift()
      await this.createPastShifts()
      this.$apollo
        .mutate({
          mutation: UPDATE_LOCATION_USER_WORKING_HOURS_SIFT_START_DAY,
          variables: {
            id: this.itemData.shifts.id,
            date_start: moment(this.itemData.week.start).add(7, 'day').format('YYYY-MM-DD'),
          },
        })
        .then(() => {
          this.$emit('submitted')
          this.$loading(false)
          this.$_N({
            position: 'center',
            type: 'success',
          })
        })
        .catch(() => {
          this.$_N({
            position: 'center',
            type: 'error',
          })
          this.$loading(false)
        })
    },
    async createEditedDayShift() {
      await this.$apollo.mutate({
        mutation: INSERT_LOCATION_USER_WORKING_HOURS,
        variables: {
          data: {
            location_id: this.locationId,
            team_id: this.auth.user.team_id,
            user_id: this.itemData.user.id,
            day_of_week: this.itemData.day_of_week + '',
            date_start: this.isoDayFormat(this.itemData.week.start),
            date_end: this.isoDayFormat(this.itemData.week.end),
            shift_start_time: this.workingHoursData[0].start,
            shift_end_time: this.workingHoursData[0].end,
            second_shift_start_time: this.workingHoursData[1]
              ? this.workingHoursData[1].start
              : null,
            second_shift_end_time: this.workingHoursData[1] ? this.workingHoursData[1].end : null,
          },
        },
      })
    },
    async createPastShifts() {
      await this.$apollo.mutate({
        mutation: INSERT_USER_SHIFTS,
        variables: {
          data: {
            location_id: this.locationId,
            team_id: this.auth.user.team_id,
            user_id: this.itemData.user.id,
            day_of_week: this.itemData.day_of_week + '',
            date_start: this.itemData.shifts.date_start,
            date_end: moment(this.itemData.week.start).subtract(1, 'day').format('YYYY-MM-DD'),
            shift_start_time: this.itemData.shifts.shift_start_time,
            shift_end_time: this.itemData.shifts.shift_end_time,
            second_shift_start_time: this.itemData.shifts.second_shift_start_time,
            second_shift_end_time: this.itemData.shifts.second_shift_end_time,
          },
        },
      })
    },
    async updatePastShifts() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_USER_PAST_SHIFTS,
          variables: {
            id: this.itemData.shifts.id,
            data: {
              location_id: this.locationId,
              team_id: this.auth.user.team_id,
              user_id: this.itemData.user.id,
              day_of_week: this.itemData.day_of_week + '',
              date_start: this.itemData.shifts.date_start,
              date_end: moment(this.itemData.week.start).subtract(1, 'day').format('YYYY-MM-DD'),
              shift_start_time: this.itemData.shifts.shift_start_time,
              shift_end_time: this.itemData.shifts.shift_end_time,
              second_shift_start_time: this.itemData.shifts.second_shift_start_time,
              second_shift_end_time: this.itemData.shifts.second_shift_end_time,
            },
          },
        })
        .then(() => {
          this.$emit('submitted')
          this.$loading(false)
          this.$_N({
            position: 'center',
            type: 'success',
          })
        })
        .catch(() => {
          this.$_N({
            position: 'center',
            type: 'error',
          })
          this.$loading(false)
        })
    },
    async createUpcomingShifts(newShifts) {
      const shifts = newShifts ? newShifts : this.itemData.shifts
      const start = newShifts
        ? moment(this.itemData.week.start).format('YYYY-MM-DD')
        : moment(this.itemData.week.start).add(1, 'week').format('YYYY-MM-DD')
      await this.$apollo.mutate({
        mutation: INSERT_USER_SHIFTS,
        variables: {
          data: {
            location_id: this.locationId,
            team_id: this.auth.user.team_id,
            user_id: this.itemData.user.id,
            day_of_week: this.itemData.day_of_week + '',
            date_start: start,
            date_end: newShifts ? this.setEndDate() : this.itemData.shifts.date_end,
            shift_start_time: shifts.shift_start_time,
            shift_end_time: shifts.shift_end_time,
            second_shift_start_time: shifts.second_shift_start_time,
            second_shift_end_time: shifts.second_shift_end_time,
          },
        },
      })
    },
    async updateUpComingShifts() {
      this.$loading(true)
      await this.deleteUpComingShifts()
      const newShifts = JSON.parse(JSON.stringify(this.itemData.shifts))
      newShifts.date_start = this.isoDayFormat(this.itemData.week.start)
      newShifts.date_end = this.setEndDate()
      newShifts.shift_start_time = this.workingHoursData[0].start
      newShifts.shift_end_time = this.workingHoursData[0].end
      newShifts.second_shift_start_time = this.workingHoursData[1]
        ? this.workingHoursData[1].start
        : null
      newShifts.second_shift_end_time = this.workingHoursData[1]
        ? this.workingHoursData[1].end
        : null
      await this.createUpcomingShifts(newShifts)
      this.updatePastShifts()
    },
    async editUpdatedShift() {
      this.$loading(true)
      const newShifts = JSON.parse(JSON.stringify(this.itemData.shifts))
      newShifts.date_start = this.itemData.shifts.date_start
      newShifts.date_end = this.itemData.shifts.date_end
      newShifts.shift_start_time = this.workingHoursData[0].start
      newShifts.shift_end_time = this.workingHoursData[0].end
      newShifts.second_shift_start_time = this.workingHoursData[1]
        ? this.workingHoursData[1].start
        : null
      newShifts.second_shift_end_time = this.workingHoursData[1]
        ? this.workingHoursData[1].end
        : null
      this.$apollo
        .mutate({
          mutation: UPDATE_LOCATION_USER_WORKING_HOURS,
          variables: {
            id: this.itemData.shifts.id,
            data: this.$_ER(newShifts, ['__typename']),
          },
        })
        .then(() => {
          this.$emit('submitted')
          this.$loading(false)
          this.$_N({
            position: 'center',
            type: 'success',
          })
        })
        .catch(() => {
          this.$_N({
            position: 'center',
            type: 'error',
          })
          this.$loading(false)
        })
    },
    handleDelete() {
      this.updatedShift ? this.deleteUpdatedShift() : (this.deleteTypeModal = true)
    },
    async deleteUpdatedShift() {
      this.$loading(true)
      this.$apollo
        .mutate({
          mutation: DELETE_USER_SHIFT,
          variables: {
            id: this.itemData.shifts.id,
          },
        })
        .then(() => {
          this.$emit('submitted')
          this.$loading(false)
          this.$_N({
            position: 'center',
            type: 'success',
          })
        })
        .catch(() => {
          this.$_N({
            position: 'center',
            type: 'error',
          })
          this.$loading(false)
        })
    },
    async deleteSameDayShifts(usedId) {
      let userWorkingHours = []
      await this.$apollo
        .mutate({
          mutation: GET_USER_SHIFTS_BY_DAY,
          variables: {
            day_of_week: this.itemData.day_of_week + '',
            user_id: this.itemData.user.id,
          },
        })
        .then((res) => {
          userWorkingHours = res.data.user_working_hours
        })
        .catch(() => {
          this.$_N({
            position: 'center',
            type: 'error',
          })
        })
      const sameDayShifts = userWorkingHours.filter((el) => el.id !== usedId)
      if (sameDayShifts.length > 0) {
        sameDayShifts.forEach((element) => {
          this.$apollo.mutate({
            mutation: DELETE_USER_SHIFT,
            variables: {
              id: element.id,
            },
          })
        })
      }
    },
    async deleteUpComingShifts() {
      await this.$apollo.mutate({
        mutation: DELETE_USER_UPCOMING_SHIFTS,
        variables: {
          day_of_week: this.itemData.day_of_week + '',
          user_id: this.itemData.user.id,
          startDate: this.getDay(),
          endDate: this.setEndDate(),
        },
      })
    },
    deleteShift() {
      if (this.deleteType === 'this_shift') this.deleteCurrentShift()
      else this.updatePastShifts()
    },
    async deleteCurrentShift() {
      await this.createUpcomingShifts()
      this.updatePastShifts()
    },
    addShift() {
      this.workingHoursData.push({
        start: '',
        end: '',
        repeat: '',
      })
    },
    isoDayFormat(val) {
      return moment(val).format('YYYY-MM-DD')
    },
    isoDayFormatAddDay(val) {
      return moment(val).add(1, 'day').format('YYYY-MM-DD')
    },
    showDayFormat(val) {
      return moment(val).format('ddd, DD MMMM')
    },
    getDay() {
      return moment(this.itemData.week.start).add(this.itemData.day_of_week - 1, 'day')
    },
    setEndDate() {
      if (this.repeat === 'no_repeat') return this.isoDayFormat(this.itemData.week.end)
      if (this.endRepeat === 'on_going') return '2222-12-30'
      if (this.endRepeat === 'specific_date') return moment(this.endRepeatDate).add(7, 'day')
      return this.isoDayFormat(this.itemData.week.end)
    },
  },
}
</script>
