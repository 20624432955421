<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto">
            <div class="dataTables_filter text-end">
              <b-input
                v-model="filter_b"
                type="search"
                :placeholder="$t('fields.search')"
              ></b-input>
            </div>
          </div>
        </b-form>

        <button class="btn btn-success ms-auto mb-2" @click="addFormModal = true">
          {{ $t('form.add_form') }}
        </button>
      </div>
    </div>

    <add-form :add-form-modal="addFormModal" @close="addFormModal = false" />

    <div v-if="forms" class="row">
      <div class="d-flex justify-content-between mb-3">
        <div class="col-md-12 col-xs-12">
          <div class="card p-2 table-responsive">
            <b-table
              :items="forms"
              :fields="fields"
              responsive="sm"
              hover="hover"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter_b"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(created_at)="data">
                {{ moment(data.value).format('Do MMMM YYYY HH:ss') }}
              </template>
              <template #cell(actions)="row">
                <button
                  type="button"
                  class="btn btn-success btn-sm waves-effect waves-light"
                  @change="row"
                  @click="
                    $router.push({
                      name: 'form.show',
                      params: { key: row.item.id },
                    })
                  "
                >
                  <i class="bx bx-search-alt font-size-16 align-middle me-1"></i>
                  {{ $t('buttons.show') }}
                </button>
              </template>
            </b-table>
            <b-alert class="mt-1 mb-3 text-center" :show="forms.length == 0" variant="warning">
              {{ $t('form.no_form_added') }}
            </b-alert>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="page"
                      :total-rows="forms.length"
                      :per-page="per_page"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-3">
            <p>
              {{ $t('customerForm.total') }} <b>{{ forms.length }}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import { GET_FORMS } from '@/router/queries'
import AddForm from '@/components/widgets/addForm.vue'

export default {
  name: 'Form',
  page: {
    title: 'forms',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { PageHeader, AddForm },
  data() {
    return {
      moment,
      title: this.$t('form.forms'),
      items: [
        {
          text: this.$t('form.forms'),
          href: '/',
        },
        {
          text: this.$t('form.form_list'),
          active: true,
        },
      ],
      fields: [
        {
          key: 'name',
          label: this.$t('form.form_name'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('tables.created_at'),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('tables.action'),
          sortable: true,
        },
      ],
      currentPage: 1,
      filterOn: [],
      sortBy: 'value',
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      filter_b: null,
      totalRows: 1,
      addFormModal: false,
      form: null,
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      search_input: '',
      updateModal: {},
    }
  },
  validations: {
    form: {
      name: { required },
    },
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    search() {
      return {
        _ilike: '%' + this.search_input + '%',
      }
    },
    rows() {
      return this.forms.length
    },
  },
  created() {
    this.$_loading()
    this.form = {
      name: null,
      user_id: this.auth.user.id,
      team_id: this.auth.user.team_id,
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filter() {
      this.$apollo.queries.forms.start()
      this.$_loading()
    },
    clear() {
      this.per_page = 10
      this.sort_by = { created_at: 'desc' }
      this.search_input = ''
      this.$apollo.queries.forms.refresh()
      this.$_loading()
    },
  },
  apollo: {
    forms: {
      fetchPolicy: 'no-cache',
      query: GET_FORMS,
      variables() {
        return {
          offset: this.offset,
          limit: this.per_page,
          order_by: this.sort_by,
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            user_id: {
              _eq: this.auth.user.id,
            },
            _or: [
              {
                name: this.search,
              },
            ],
          },
        }
      },
    },
  },
}
</script>
