<template>
  <div v-if="team && bundle && event_bundles">
    <table class="header">
      <tbody>
        <tr class="text-center">
          <td width="35%">
            <img src="https://www.barespace.app/images/logo_gray.png" alt="" width="20%" />
            <h4 style="margin-top: 5px; color: #2a3042">
              {{ team.name }}
            </h4>
            <p>{{ team.name }} - {{ team.address }} - {{ team.phone }}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="information w-100">
      <tbody>
        <tr>
          <td width="50%" class="p-10">
            <h4 style="color: #2a3042">MÜŞTERİ BİLGİLERİ</h4>
            <table>
              <tbody>
                <tr class="border-bottom">
                  <td width="40%">Adı Soyadı</td>
                  <td>:</td>
                  <td>
                    {{ bundle.contact.first_name + ' ' + bundle.contact.last_name }}
                  </td>
                </tr>
                <tr class="border-bottom">
                  <td>TC Kimlik Numarası</td>
                  <td>:</td>
                  <td>{{ bundle.contact.identification_number }}</td>
                </tr>
                <tr class="border-bottom">
                  <td>Telefon</td>
                  <td>:</td>
                  <td>{{ bundle.contact.phone }}</td>
                </tr>
                <tr class="border-bottom">
                  <td>Adres</td>
                  <td>:</td>
                  <td>{{ bundle.contact.address }}</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td class="p-10" valign="top">
            <h3 style="color: #2a3042">PAKET BİLGİLERİ</h3>
            <table>
              <tbody>
                <tr class="border-bottom">
                  <td width="40%">{{ $t('package.package_name') }}</td>
                  <td>:</td>
                  <td>{{ bundle.name }}</td>
                </tr>
                <tr class="border-bottom">
                  <td>{{ $t('tables.price') }}</td>
                  <td>:</td>
                  <td>{{ formatPrice(bundle.price) }}</td>
                </tr>
                <tr class="border-bottom">
                  <td>Tarih</td>
                  <td>:</td>
                  <td>
                    {{ moment(bundle.created_at).format('Do MMMM YYYY HH:ss') }}
                  </td>
                </tr>
                <tr class="border-bottom">
                  <td>{{ $t('service.services') }}</td>
                  <td>:</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="4" class="p-2 pt-0">
            <h4 style="margin-top: 5px; color: #2a3042">TAKSİT DETAYLARI</h4>
            <div>
              <h6
                v-for="(payment, key) in bundle.bundle_payments"
                :key="key"
                style="
                  display: inline-block;
                  border: 1px solid #ddd;
                  margin-right: 25px;
                  padding: 5px 15px;
                "
              >
                {{ moment(payment.date).format('DD/MM/YYYY') }}
                <span class="text-muted" style="margin-left: 15px">
                  {{ formatPrice(payment.price) }}
                </span>
              </h6>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="description" v-html="team.agreement"></div>
    <table class="table tablelist" align="center">
      <tr>
        <td colspan="5" style="text-align: right">
          <table class="table-person" style="width: 100%; margin-top: 10px">
            <tr>
              <td>
                Müşteri<br />
                {{ bundle.contact.first_name + ' ' + bundle.contact.last_name }}
              </td>

              <td>{{ team.name }}</td>
            </tr>
          </table>

          <br />
        </td>
      </tr>
    </table>

    <p class="text-center">{{ team.name }} - {{ team.address }} - {{ team.phone }}</p>
  </div>
</template>

<script>
import { GET_BUNDLE_SINGLE, BUNDLE_SHOW_DETAIL, GET_TEAM } from '@/router/queries'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      moment,
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  created() {
    setTimeout(() => {
      this.printWindow()
    }, 1000)
  },
  methods: {
    printWindow: function () {
      //  window.print();
    },
  },
  apollo: {
    bundle: {
      fetchPolicy: 'no-cache',
      query: GET_BUNDLE_SINGLE,
      variables() {
        return {
          id: this.$route.params.key,
        }
      },
    },
    event_bundles: {
      query: BUNDLE_SHOW_DETAIL,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          bundle_id: this.$route.params.id,
        }
      },
    },
    team: {
      fetchPolicy: 'no-cache',
      query: GET_TEAM,
      variables() {
        return {
          id: this.auth.user.team_id,
        }
      },
    },
  },
}
</script>
<style scoped>
body {
  font-family: DejaVu Sans, sans-serif;
  font-size: 10px;
}
h3.title {
  padding: 2px;
  text-align: center;
}
.description {
  font-family: DejaVu Sans, sans-serif;
  font-size: 9px;
}
h1.title {
  border: 1px solid #555;
  padding: 2px;
  text-align: center;
}
.table {
  width: 100%;
}
.information {
  border: 1px solid #555;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}
tr.border-bottom td {
  border-bottom: 0.5pt solid rgb(184, 184, 184);
  border-spacing: 2px;
}
.table tr th {
  padding: 3px;
  border: 1px solid #555;
}
.table tr td {
  padding: 2px;
  border: 1px solid #555;
}
.tablelist tr td {
  padding: 2px;
  border: 1px solid #555;
  text-align: center;
  border-radius: 10px !important;
}
.table-person tr td {
  border: 0;
  text-align: center;
  padding-bottom: 10px;
}
.p-10 {
  padding: 10px;
}
</style>
