<template>
  <div>
    <PageHeader :title="title" />
    <div v-if="team" class="row">
      <div class="col-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <h5>{{ $t('tracking.facebook') }}</h5>
            <form class="my-3 row" @submit.prevent="setPixelCode">
              <div class="col-9">
                <input
                  v-model="team.fb_pixel_id"
                  type="text"
                  class="form-control"
                  :placeholder="$t('tracking.pixel_id')"
                />
              </div>
              <button class="btn btn-primary col-3">{{ $t('buttons.submit') }}</button>
            </form>
            <a href="https://www.facebook.com/business/help/171689010314963" target="_blank">
              {{ $t('tracking.how_get') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageHeader from '@/components/page-header'
import { GET_TEAM, UPDATE_TEAM } from '../../queries'

export default {
  name: 'Personal',
  page: {
    title: 'stripe',
  },
  components: { PageHeader },
  data() {
    return {
      title: this.$t('title.tracking'),
      pixelCode: '',
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  methods: {
    setPixelCode() {
      this.$apollo.mutate({
        mutation: UPDATE_TEAM,
        variables: {
          team: this.$_ER(this.team, ['__typename']),
          id: this.team.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$loading(false)
          }
        },
      })
    },
  },
  apollo: {
    team: {
      fetchPolicy: 'no-cache',
      query: GET_TEAM,
      variables() {
        return {
          id: this.auth.user.team_id,
        }
      },
    },
  },
}
</script>
