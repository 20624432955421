<template>
  <div>
    <Loader :loading="categories === undefined">
      <div v-if="categories">
        <b-modal v-model="showModal" :title="$t('title.edit_categories')" hide-footer>
          <form @submit.prevent="handleSubmit">
            <div v-for="item in categories" :key="item.name" class="mb-3 row">
              <div class="col-10">
                <b-form-input v-model="item.name" type="text" disabled></b-form-input>
              </div>
              <div class="col-2 mx-sm">
                <button type="button" class="btn btn-danger btn-md" @click="removeCategory(item)">
                  <i class="bx bx-trash font-size-16 align-middle"></i>
                </button>
              </div>
            </div>
            <div class="mb-3 row" for="service_name">
              <div class="col-10">
                <b-form-input
                  id="service_name"
                  v-model="newCategory"
                  type="text"
                  :placeholder="$t('service.service_name')"
                ></b-form-input>
              </div>
              <div class="col-2 mx-sm">
                <button
                  type="button"
                  class="btn btn-success btn-md"
                  :disabled="!newCategory"
                  @click="addCategory"
                >
                  <i class="bx bx-plus font-size-16 align-middle"></i>
                </button>
              </div>
            </div>
            <div class="text-end mt-3">
              <b-button variant="light" @click="$emit('close')">{{ $t('buttons.close') }}</b-button>
              <b-button type="submit" variant="success" class="ms-1">{{
                $t('buttons.save')
              }}</b-button>
            </div>
          </form>
        </b-modal>
      </div>
    </Loader>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import {
  GET_CATEGORIES_SERVICES,
  INSERT_CATEGORIES_SERVICES,
  DELETE_CATEGORIES_SERVICES,
  GET_CATEGORIES_PRODUCTS,
  INSERT_CATEGORIES_PRODUCTS,
  DELETE_CATEGORIES_PRODUCTS,
} from '@/router/queries'
import Loader from './loader'

export default {
  components: {
    Loader,
  },
  props: {
    show: {
      type: Boolean,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newCategory: null,
      categories: [],
      categoriesToInsert: [],
      categoriesToDelete: [],
      mutations: {
        get: null,
        insert: null,
        delete: null,
      },
    }
  },
  validations: {
    newCategory: { required },
  },
  computed: {
    ...mapState(['auth']),
    showModal: {
      get() {
        return this.show
      },
      set() {
        this.$emit('close')
      },
    },
  },
  created() {
    this.mutations =
      this.mode === 'services'
        ? {
            get: GET_CATEGORIES_SERVICES,
            insert: INSERT_CATEGORIES_SERVICES,
            delete: DELETE_CATEGORIES_SERVICES,
          }
        : {
            get: GET_CATEGORIES_PRODUCTS,
            insert: INSERT_CATEGORIES_PRODUCTS,
            delete: DELETE_CATEGORIES_PRODUCTS,
          }
    this.getCategories()
  },
  methods: {
    async handleSubmit() {
      this.$loading(true)
      await this.insertCategories()
      await this.deleteCategories()
      this.$emit('submitted')
      this.$emit('close')
      this.$loading(false)
    },
    async getCategories() {
      this.$loading(true)
      await this.$apollo
        .query({
          fetchPolicy: 'no-cache',
          query: this.mutations.get,
          variables: {
            where: {
              team_id: {
                _eq: this.auth.user.team_id,
              },
            },
          },
        })
        .then((result) => {
          this.categories =
            this.mode === 'services'
              ? result.data.categories_services
              : result.data.categories_products
          return true
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
          return false
        })
      return this.$loading(true)
    },
    async insertCategories() {
      await this.$apollo
        .mutate({
          mutation: this.mutations.insert,
          variables: {
            categories: this.categoriesToInsert,
          },
        })
        .then(() => {
          this.categoriesToInsert = []
          return true
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
          return false
        })
      return
    },
    async deleteCategories() {
      await this.$apollo
        .mutate({
          mutation: this.mutations.delete,
          variables: {
            where: {
              id: {
                _in: this.categoriesToDelete,
              },
            },
          },
        })
        .then(() => {
          this.categoriesToDelete = []
          return true
        })
        .catch(() => {
          this.$_N({
            type: 'error',
            position: 'center',
          })
          return false
        })
      return
    },
    addCategory() {
      this.$v.$touch()
      if (this.$v.$invalid) return false
      const newCat = { name: this.newCategory, team_id: this.auth.user.team_id }
      this.categoriesToInsert.push(newCat)
      this.categories.push(newCat)
      this.newCategory = null
    },
    removeCategory(item) {
      if (!item.id) {
        this.categoriesToInsert = this.categoriesToInsert.filter((x) => x.name !== item.name)
        this.categories = this.categories.filter((x) => x.name !== item.name)
        return
      }
      this.categoriesToDelete.push(item.id)
      this.categories = this.categories.filter((x) => x.id !== item.id)
    },
  },
}
</script>
