<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center mt-lg-6">
      <div class="card">
        <div class="card-body">
          <div class="col-xl-12 col-sm-12">
            <b-form>
              <form @submit.prevent="update">
                <div class="row">
                  <div class="col-md-2">
                    <b-form-group
                      class="mb-3"
                      :label="$t('customerForm.name')"
                      label-for="formrow-text-input"
                    >
                      <b-form-input
                        id="formrow-text-input"
                        v-model="user.first_name"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <b-form-group
                        class="mb-3"
                        :label="$t('customerForm.surname')"
                        label-for="formrow-text-input"
                      >
                        <b-form-input
                          id="formrow-text-input"
                          v-model="user.last_name"
                          type="text"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <b-form-group
                        class="mb-3"
                        :label="$t('customerForm.password')"
                        label-for="formrow-text-input"
                      >
                        <b-form-input
                          id="formrow-text-input"
                          v-model="user.password"
                          type="text"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-group
                        class="mb-3"
                        :label="$t('customerForm.phone')"
                        label-for="formrow-password-input"
                      >
                        <tel-input v-model="user.phone" />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b-form-group
                        class="mb-3"
                        :label="$t('customerForm.email')"
                        label-for="formrow-text-input"
                      >
                        <b-form-input
                          id="formrow-text-input"
                          v-model="user.email"
                          type="Email"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="col-xl-12 col-sm-12 mt-4">
                  <h4 class="card-title">
                    {{ $t('staff.details.authorization') }}
                  </h4>
                  <p class="card-title-desc">
                    {{ $t('staff.details.define_menus') }}
                  </p>
                  <div class="row mt-3 mb-3">
                    <div class="col-md-6 col-xs-12 rightBorder">
                      <h4 class="card-title">
                        {{ $t('staff.details.system_authorizations') }}
                      </h4>
                      <b-table bordered striped hover :fields="fields" :items="permissions">
                        <template #cell(description)="row">
                          {{ $t(`permission.${row.item.description}`) }}
                        </template>
                        <template #cell(action)="row">
                          <b-button
                            v-if="!user_permissions.find((x) => x.permission_id === row.item.id)"
                            variant="primary btn-sm"
                            @click="addRole(row.item)"
                          >
                            <i class="bx bx-plus font-size-16 align-middle me-1"></i>
                            {{ $t('buttons.add') }}
                          </b-button>
                          <b-button
                            v-if="user_permissions.find((x) => x.permission_id === row.item.id)"
                            variant="success btn-sm"
                            ><i class="bx bx-check font-size-16 align-middle me-1"></i
                            >{{ $t('buttons.added') }}</b-button
                          >
                        </template>
                      </b-table>
                    </div>
                    <div class="col-md-6 col-xs-12">
                      <h4 class="card-title">
                        {{ $t('staff.details.staff_authorizations') }}
                      </h4>
                      <b-table bordered striped hover :fields="fields" :items="user_permissions">
                        <template #cell(action)="row">
                          <b-button variant="danger btn-sm" @click="deletePermission(row.item)"
                            ><i class="bx bx-minus font-size-16 align-middle me-1"></i
                            >{{ $t('buttons.cancel') }}</b-button
                          >
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
                <div>
                  <b-button type="submit" variant="primary">{{ $t('buttons.update') }}</b-button>
                </div>
              </form>
            </b-form>
            <!--            <h4 class="py-3 border-bottom mt-3">Working Hours</h4>
            <div v-for="(work, key) in user.staff_working_hours" :key="key">
              <div class="row">
                <div class="col-md-2 col-xs-12">
                  <h5 class="font-size-15 mt-2">
                    {{ work.name }}
                    <i class="mdi mdi-arrow-right text-primary"></i>
                  </h5>
                  <div class="form-check form-switch form-switch-md mb-3">
                    <input
                      id="SwitchCheckSizemd"
                      v-model="work.active"
                      class="form-check-input"
                      type="checkbox"
                      @change="updateWork(work)"
                    />
                  </div>
                </div>
                <div class="col-md-10 col-xs-12">
                  <div class="row">
                    <div class="col-lg-3">
                      <b-form-group
                        id="example-time"
                        class="mb-3"
                        :label="$t('locations.inputs.workingHours.workStart')"
                        label-for="time"
                      >
                        <b-form-input
                          id="time"
                          v-model="work.work_start"
                          type="time"
                          @focusout="updateWork(work)"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-lg-3">
                      <b-form-group
                        id="input-group-1"
                        class="mb-3"
                        :label="$t('locations.inputs.workingHours.workEnd')"
                        label-for="time"
                      >
                        <b-form-input
                          id="time"
                          v-model="work.work_end"
                          type="time"
                          @focusout="updateWork(work)"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-lg-3">
                      <b-form-group
                        class="mb-3"
                        :label="$t('locations.inputs.workingHours.restStart')"
                        label-for="formrow-inputCity"
                      >
                        <b-form-input
                          id="formrow-inputCity"
                          v-model="work.rest_start"
                          type="time"
                          @focusout="updateWork(work)"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-lg-3">
                      <b-form-group
                        id="input-group-1"
                        class="mb-3"
                        :label="$t('locations.inputs.workingHours.restEnd')"
                        label-for="formrow-inputState"
                      >
                        <b-form-input
                          id="formrow-inputState"
                          v-model="work.rest_end"
                          type="time"
                          @focusout="updateWork(work)"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from '@/app.config'
import { mapState } from 'vuex'
import PageHeader from '@/components/page-header'
import {
  GET_USER,
  INSERT_PERMISSIONS,
  UPDATE_USER,
  GET_PERMISSION,
  GET_PERMISSIONS_LIST,
  DELETE_PERMISSION,
  UPDATE_WORK_HOURS,
} from '../../queries'

/**
 * Dashboard Component
 */
export default {
  name: 'User',
  page: {
    title: 'user_information',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      title: this.$t('staff.user_information'),
      items: [
        {
          text: this.$t('staff.user_information'),
          href: '/',
        },
        {
          text: this.$t('buttons.edit'),
          active: true,
        },
      ],
      fields: [
        {
          key: 'description',
          sortable: true,
          label: this.$t('staff.authority'),
        },
        {
          key: 'action',
          sortable: true,
          label: this.$t('tables.action'),
        },
      ],
      showModal: false,
      per_list: null,
      user: {
        first_name: null,
      },
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  created() {
    this.$_loading()
    this.per_list = {
      team_id: this.auth.user.team_id,
      user_id: this.$route.params.key,
      permission_id: null,
      name: null,
    }
  },
  methods: {
    update() {
      this.user.need_update_profile = false
      this.$loading(true)
      delete this.user.staff_working_hours
      this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: {
          user: this.$_ER(this.user, ['__typename']),
          id: this.user.id,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
          } catch (e) {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$loading(false)
          }
        },
      })
    },
    addRole(item) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: INSERT_PERMISSIONS,
        variables: {
          per_list: {
            team_id: this.auth.user.team_id,
            user_id: this.$route.params.key,
            permission_id: item.id,
            name: item.name,
            description: item.description,
          },
        },
        update: () => {
          this.$apollo.queries.user_permissions.refresh()
          this.$loading(false)
          this.$_N({
            position: 'center',
            type: 'success',
          })
        },
      })
    },
    deletePermission(item) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: DELETE_PERMISSION,
        variables: {
          id: item.id,
        },
        update: () => {
          this.$apollo.queries.permissions.refresh()
          this.$apollo.queries.user_permissions.refresh()
          this.$loading(false)
          this.$_N({
            position: 'center',
            type: 'success',
          })
        },
      })
    },
    updateWork(work) {
      this.$loading(true)
      this.$apollo.mutate({
        mutation: UPDATE_WORK_HOURS,
        variables: {
          work_hours: this.$_ER(work, ['__typename', 'name']),
          id: work.id,
        },
        update: () => {
          this.$loading(false)
        },
      })
    },
  },
  apollo: {
    user: {
      fetchPolicy: 'no-cache',
      query: GET_USER,
      variables() {
        return {
          id: this.$route.params.key,
        }
      },
    },
    permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSIONS_LIST,
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.$route.params.key,
            },
          },
        }
      },
    },
  },
}
</script>
<style>
.rightBorder {
  border-right: 1px solid rgb(219, 219, 219);
}
</style>
