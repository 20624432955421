<template>
  <div>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <p class="text-center mb-4 w-75 mb-2 mx-auto">
              {{ $t('setup.start_text') }}
            </p>
            <form-wizard
              color="#1efed3"
              :back-button-text="$t('setup.back')"
              :next-button-text="$t('setup.next')"
              :finish-button-text="$t('setup.finish')"
              validate-on-back
              @on-complete="onComplete"
            >
              <tab-content icon="mdi mdi-account-circle" :before-change="validateInformationForm()">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group row">
                      <label class="col-md-3 col-form-label" for="taxTitle">{{
                        $t('setup.logo')
                      }}</label>
                      <div class="col-md-2">
                        <b-form-group class="mb-3" for="fileinput">
                          <b-form-file
                            id="fileinput"
                            v-model="imageFile"
                            :state="Boolean(imageFile)"
                            accept="image/jpeg, image/png, image/jpg"
                            placeholder="Choose an image or drop it here..."
                            drop-placeholder="Drop file here..."
                            @input="fileUpload"
                          ></b-form-file>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="teamName">{{
                        $t('setup.company_name')
                      }}</label>
                      <div class="col-md-9">
                        <input
                          id="teamName"
                          v-model="team.name"
                          type="text"
                          class="form-control"
                          name="teamName"
                          :class="{
                            'is-invalid': submitted && $v.team.name.$error,
                          }"
                        />
                        <div v-if="submitted && !$v.team.name.required" class="invalid-feedback">
                          {{ $t('setup.field_required') }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="taxTitle">{{
                        $t('setup.bill_title')
                      }}</label>
                      <div class="col-md-9">
                        <input
                          id="taxTitle"
                          v-model="team.tax_title"
                          type="text"
                          name="taxTitle"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="taxName">{{
                        $t('setup.tax_office')
                      }}</label>
                      <div class="col-md-9">
                        <input
                          id="taxName"
                          v-model="team.tax_name"
                          type="text"
                          name="taxName"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="taxNo">{{
                        $t('setup.tax_number')
                      }}</label>
                      <div class="col-md-9">
                        <input
                          id="taxNo"
                          v-model="team.tax_number"
                          type="text"
                          name="taxNo"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="email">{{
                        $t('setup.email')
                      }}</label>
                      <div class="col-md-9">
                        <input
                          id="email"
                          v-model="team.email"
                          type="email"
                          name="password"
                          class="form-control"
                          :class="{
                            'is-invalid': submitted && $v.team.email.$error,
                          }"
                        />
                        <div v-if="submitted && !$v.team.email.required" class="invalid-feedback">
                          {{ $t('setup.field_required') }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="phone">{{
                        $t('setup.phone')
                      }}</label>
                      <div class="col-md-9">
                        <tel-input
                          v-model="team.phone"
                          :is-invalid="submitted && $v.team.phone.$error"
                          :class="{
                            'is-invalid': submitted && $v.team.phone.$error,
                          }"
                        />
                        <div v-if="submitted && !$v.team.phone.required" class="invalid-feedback">
                          {{ $t('setup.field_required') }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="currency">{{
                        $t('customerForm.currency')
                      }}</label>
                      <div class="col-md-9">
                        <multiselect
                          id="currency"
                          v-model="team.currency"
                          :show-labels="false"
                          :placeholder="$t('buttons.placeHolderSelect')"
                          :options="$_CURRENCIES"
                          :class="{
                            'is-invalid': submitted && $v.team.currency.$error,
                          }"
                        ></multiselect>
                        <div
                          v-if="submitted && !$v.team.currency.required"
                          class="invalid-feedback"
                        >
                          {{ $t('setup.field_required') }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="currency">
                        {{ $t('teams.profile.timezone') }}
                      </label>
                      <div class="col-md-9">
                        <timezone-list v-model="team.timezone" />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="address">
                        {{ $t('setup.address') }}
                      </label>
                      <div class="col-md-9">
                        <Address
                          v-model="team.address"
                          :is-invalid="submitted && $v.team.address.$error"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </tab-content>
              <tab-content icon="mdi mdi-map-marker-plus" :before-change="submitLocationForm()">
                <div class="row">
                  <div class="col-12 h5 my-3">{{ $t('setup.add_first_location') }}</div>
                </div>
                <add-location
                  ref="locationForm"
                  hide-save-button
                  :place-id-required="false"
                  :update-item-data="locationData"
                  @submitted="updateLocationData"
                />
              </tab-content>
              <tab-content icon="mdi mdi-email">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="senderName">{{
                        $t('setup.marketing_sender_title')
                      }}</label>
                      <div class="col-md-9">
                        <input
                          v-model="team.senders.email.sender_name"
                          type="text"
                          name="senderName"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="senderEmail">{{
                        $t('setup.from_email')
                      }}</label>
                      <div class="col-md-9">
                        <input
                          v-model="team.senders.email.sender_email"
                          disabled
                          type="email"
                          name="senderEmail"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="senderEmail">
                        {{ $t('notification.sms_sender_id') }}
                      </label>
                      <div class="col-md-9">
                        <input
                          v-model="team.senders.sms.sender_id"
                          type="email"
                          name="senderEmail"
                          class="form-control"
                          :class="{
                            'is-invalid': !validSenderId,
                          }"
                          @input="
                            () => {
                              validSenderId = senderIdPattern.test(team.senders.sms.sender_id)
                            }
                          "
                        />
                        <div v-if="!validSenderId" class="invalid-feedback">
                          {{ $t('notification.not_valid_sender_id') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tab-content>
              <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                <div class="row">
                  <div class="col-12">
                    <div class="text-center">
                      <h2 class="mt-0">
                        <i class="mdi mdi-check-all"></i>
                      </h2>
                      <h3 class="mt-0">{{ $t('setup.thanks') }}</h3>
                      <p
                        :class="{
                          'is-invalid': submitted && $v.team.name.$error,
                        }"
                      ></p>
                      <p
                        :class="{
                          'is-invalid': submitted && $v.team.email.$error,
                        }"
                      ></p>
                      <div v-if="submitted && !$v.team.email.required" class="invalid-feedback">
                        {{ $t('setup.missing_fields') }}
                      </div>
                      <p class="w-75 mb-2 mx-auto">
                        {{ $t('setup.thanks_description') }}
                      </p>
                    </div>
                  </div>
                </div>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ADD_LOCATION_STAFFS, GET_TEAM, UPDATE_TEAM } from '../../queries'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import AddLocation from '@/components/widgets/addLocation.vue'
import TimezoneList from '@/components/widgets/timezone-list.vue'
import { uploadService } from '@/helpers/upload.service'

/**
 * Form wizard component
 */
export default {
  components: { Multiselect, PageHeader, FormWizard, TabContent, TimezoneList, AddLocation },
  page: {
    title: 'complete_setup',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      title: this.$t('setup.title'),
      items: [
        {
          text: '',
          href: '/',
        },
        {
          text: '',
          active: true,
        },
      ],
      team: {
        name: null,
        email: null,
        senders: {
          email: {
            sender_name: null,
          },
        },
      },
      submitted: false,
      validSenderId: true,
      senderIdPattern: /^[a-zA-Z0-9\s]{0,11}$/,
      locationData: {},
      imageFile: null,
      slugNumber: 0,
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  validations: {
    team: {
      name: { required },
      email: { required },
      phone: { required },
      currency: { required },
      timezone: { required },
      address: { required },
    },
  },
  created() {
    this.$_loading()
  },
  methods: {
    submitLocationForm() {
      const validation = () => {
        const locationForm = this.$refs.locationForm
        this.$loading(true)
        locationForm.handleSubmit()
        locationForm.submitted = true
        locationForm.$v.$touch()
        if (locationForm.$v.$invalid) {
          this.$loading(false)
          return false
        }
        this.$loading(false)
        return true
      }
      return validation
    },
    validateInformationForm() {
      const validation = () => {
        this.$loading(true)
        this.submitted = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$loading(false)
          return false
        }
        this.$loading(false)
        return true
      }
      return validation
    },
    updateLocationData() {
      this.locationData = {
        location_id: this.$refs.locationForm.createdId,
        user_id: this.auth.user.id,
        team_id: this.team.id,
      }
    },
    async onComplete() {
      this.setTeamSlug()
      this.$loading(true)
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid || !this.validSenderId) {
        this.$loading(false)
        return
      } else {
        console.log('this.team in req :>> ', this.team)
        this.$apollo
          .mutate({
            mutation: UPDATE_TEAM,
            variables: {
              team: this.$_ER(this.team, ['__typename']),
              id: this.team.id,
            },
            update: () => {
              try {
                this.$_N({
                  type: 'success',
                  position: 'center',
                })
                this.$loading(false)
                this.$store.commit('location/setTimezone', this.team.timezone)
                moment.tz.setDefault(this.team.timezone)
                this.addStaffLocation()
                this.$router.push({ name: 'default' })
              } catch {
                this.$_N({
                  type: 'error',
                  position: 'center',
                })
                this.$loading(false)
              }
            },
          })
          .catch((error) => {
            this.$loading(false)
            if (error.message.includes('teams_slug_key')) {
              this.slugNumber++
              this.onComplete()
            } else {
              this.$_N({
                type: 'error',
                position: 'center',
              })
            }
          })
      }
    },
    setTeamSlug() {
      if (this.slugNumber > 0) {
        this.team.slug = this.team.slug + this.slugNumber
        return
      }
      this.team.slug = this.team.name.toLowerCase().split(' ').join('_')
    },
    addStaffLocation() {
      this.$apollo.mutate({
        mutation: ADD_LOCATION_STAFFS,
        variables: {
          locationStaffs: this.locationData,
        },
        update: () => {
          try {
            this.$_N({
              type: 'success',
              position: 'center',
            })
            this.$loading(false)
          } catch {
            this.$_N({
              type: 'error',
              position: 'center',
            })
            this.$loading(false)
          }
        },
      })
    },
    async fileUpload(file) {
      this.$loading(true)
      try {
        const logo = await uploadService({
          file: file,
          name: this.team.name + '-logo',
          team_id: this.auth.user.team_id,
        })
        this.team.logo = logo
      } catch (err) {
        this.$_N({
          type: 'error',
          position: 'center',
        })
      }
      this.$loading(false)
    },
  },
  apollo: {
    team: {
      fetchPolicy: 'no-cache',
      query: GET_TEAM,
      variables() {
        return {
          id: this.auth.user.team_id,
        }
      },
    },
  },
}
</script>
