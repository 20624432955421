export const state = {
  companyCurrency: '',
}

export const getters = {
  companyCurrency: (state) => state.companyCurrency,
}

export const mutations = {
  setCompanyCurrency(state, data) {
    state.companyCurrency = data
  },
}
