<template>
  <header id="page-topbar">
    <Guide v-model="guide" :config="guideConfig">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box pt-0">
            <a href="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo_colorful_mini.png" alt height="30" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo_colorful.png" alt height="40" />
              </span>
            </a>

            <a href="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo_colorful_mini.png" alt height="29" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo_colorful.png" alt height="37" />
              </span>
            </a>
          </div>

          <button
            id="vertical-menu-btn"
            type="button"
            class="btn btn-sm px-3 font-size-16 header-item"
            @click="toggleMenu"
          >
            <i class="fa fa-fw fa-bars turquoise-icon"></i>
          </button>

          <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('navbar.search.text')"
            />
            <span class="bx bx-search-alt"></span>
          </div>
        </form> -->
        </div>

        <div class="d-flex">
          <!--<b-dropdown
            class="d-inline-block d-lg-none ms-2"
            variant="black"
            menu-class="dropdown-menu-lg p-0"
            toggle-class="header-item noti-icon"
            right
          >
            <template v-slot:button-content>
              <i class="mdi mdi-magnify"></i>
            </template>

            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search ..."
                    aria-label="Recipient's username"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </b-dropdown> 
          -->

          <!-- <b-dropdown variant="white" right toggle-class="header-item">
            <template v-slot:button-content>
              <img class :src="flag" alt="Header Language" height="16" />
            </template>
            <b-dropdown-item
              v-for="(entry, i) in languages"
              :key="`Lang${i}`"
              class="notify-item"
              :value="entry"
              :class="{ active: lan === entry.language }"
              @click="setLanguage(entry.language, entry.title, entry.flag)"
            >
              <img :src="`${entry.flag}`" alt="user-image" class="me-1" height="12" />
              <span class="align-middle">{{ entry.title }}</span>
            </b-dropdown-item>
          </b-dropdown> -->

          <!-- <div class="step1">
            <div class="dropdown d-none d-lg-inline-block ms-1">
              <a target="_blank" href="https://www.youtube.com/channel/UC1XfSW-ssGBFADiCxoIQLIw">
                <button type="button" class="btn header-item noti-icon" title="Yotube">
                  <i class="bx bxl-youtube"></i>
                </button>
              </a>
            </div>
          </div> -->

          <div class="dropdown d-none d-lg-inline-block ms-1">
            <a class="btn header-item noti-icon" :href="setRedirectLink()" target="_blank">
              <i class="bx bx-calendar-edit turquoise-icon" style="margin-top: 0.875rem"></i>
            </a>
          </div>
          <b-dropdown
            id="notifications-dropdown"
            right
            menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
            toggle-class="header-item noti-icon"
            variant="black"
          >
            <template #button-content>
              <i class="bx bx-help-circle turquoise-icon"></i>
            </template>

            <div class="p-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="m-0">
                    {{ $t('navbar.dropdown.introduction.title') }}
                  </h6>
                </div>
                <div class="col-auto">
                  <a target="_blank" href="https://docs.barespace.app" class="small">{{
                    $t('navbar.dropdown.introduction.documentation')
                  }}</a>
                </div>
              </div>
            </div>
            <simplebar style="max-height: 230px">
              <a
                href="javascript: void(0);"
                class="text-reset notification-item"
                @click="guide = true"
              >
                <div class="media">
                  <div class="avatar-xs me-3">
                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                      <i class="bx bxs-notification"></i>
                    </span>
                  </div>
                  <div class="media-body">
                    <h6 class="mt-0 mb-1">
                      {{ $t('navbar.dropdown.introduction.text') }}
                    </h6>
                    <div class="font-size-12 text-muted">
                      <p class="mb-1">
                        {{ $t('navbar.dropdown.introduction.subtext') }}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </simplebar>
            <div class="p-2 border-top d-grid"></div>
          </b-dropdown>
          <div v-click-outside="showNotificationsDropdown" class="step2" style="position: relative">
            <div class="custom-dropdown" @click.stop="showNotificationsDropdown('button')">
              <i
                class="bx bx-bell turquoise-icon font-size-22"
                :class="{ 'bx-tada': activities.length }"
              ></i>
              <span
                class="badge bg-danger rounded-pill"
                style="position: absolute; right: 0px; top: 19px"
              >
                {{ activities ? activities.length : '0' }}
              </span>
            </div>

            <div v-show="notificationsDropdown" class="custom-dropdown-container">
              <div class="p-3">
                <div class="row align-items-center">
                  <div class="col">
                    <h6 class="m-0">
                      {{ $t('navbar.dropdown.notification.text') }}
                    </h6>
                  </div>
                  <div class="col-auto">
                    <a href="/activities" class="small">
                      {{ $t('navbar.dropdown.notification.subtext') }}
                    </a>
                  </div>
                </div>
              </div>
              <div style="max-height: 230px; overflow: auto">
                <div
                  v-for="activity in activities"
                  :key="activity.id"
                  class="text-reset notification-item"
                >
                  <div class="media">
                    <div class="avatar-xs me-3">
                      <span class="avatar-title bg-primary rounded-circle font-size-16">
                        <i class="bx bxs-notification"></i>
                      </span>
                    </div>
                    <div class="media-body">
                      <div class="row">
                        <h6 class="mt-0 mb-1">
                          {{ setActivityTitle(activity) }}
                        </h6>
                        <p class="mb-2 font-size-11">
                          <i class="mdi mdi-clock-outline"></i>
                          {{ setNotificationDate(activity.created_at) }}
                        </p>
                      </div>
                      <div class="font-size-12 text-muted">
                        <div v-if="activity.events" class="mb-1">
                          <p class="mb-1">
                            {{
                              activity.events.contact.first_name +
                              ' ' +
                              activity.events.contact.last_name
                            }}
                          </p>
                          <p
                            v-for="(service, index) in activity.events.event_services"
                            :key="index"
                            class="mb-1"
                          >
                            {{
                              service.service.name +
                              ' - ' +
                              service.user.first_name +
                              ' ' +
                              service.user.last_name
                            }}
                          </p>
                          <p class="mb-1">
                            {{ activity.events.location.name }}
                          </p>
                          <p class="mb-1">
                            {{ setEventTime(activity.events) }}
                          </p>
                          <p class="mb-1">
                            {{ setEventDate(activity.events) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-2 border-top d-grid">
                <a class="btn btn-sm btn-link font-size-14 text-center" href="/activities">
                  <i class="mdi mdi-arrow-right-circle me-1"></i>
                  {{ $t('navbar.dropdown.notification.button') }}
                </a>
              </div>
            </div>
          </div>
          <div class="step4">
            <div class="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                class="btn header-item noti-icon"
                :title="$t('navbar.dropdown.quick_menu.full_screen')"
                @click="initFullScreen"
              >
                <i class="bx bx-fullscreen turquoise-icon"></i>
              </button>
            </div>
          </div>
          <div class="step5">
            <b-dropdown
              right
              variant="black"
              toggle-class="header-item"
              menu-class="dropdown-menu-end"
            >
              <template #button-content>
                <img
                  class="rounded-circle header-profile-user"
                  src="@/assets/images/users/avatar-1.jpg"
                  alt=""
                />
                <span class="d-none d-xl-inline-block ms-1 turquoise-icon"
                  >{{ auth.user.email }}
                </span>
                <i class="mdi mdi-chevron-down d-none d-xl-inline-block turquoise-icon"></i>
              </template>
              <a :href="'/staff/' + auth.user.id + '/profile'" class="dropdown-item text-dark">
                <i class="bx bxs-user-account font-size-16 align-middle me-1"></i>
                {{ $t('navbar.dropdown.quick_menu.edit_profile') }}
              </a>
              <a
                v-if="
                  user_permissions &&
                  user_permissions.some(
                    (t) => t.name === 'administrator' || t.name === 'accounting.case'
                  )
                "
                href="/invoices"
                class="dropdown-item text-dark"
              >
                <i class="bx bx-copy-alt font-size-16 align-middle me-1"></i>
                {{ $t('navbar.dropdown.quick_menu.invoices') }}
              </a>
              <a
                v-if="
                  user_permissions &&
                  user_permissions.some(
                    (t) => t.name === 'administrator' || t.name === 'system.settings'
                  )
                "
                href="/teams/profile"
                class="dropdown-item text-dark"
              >
                <i class="bx bx-cog font-size-16 align-middle me-1"></i>
                {{ $t('navbar.dropdown.quick_menu.company_information') }}
              </a>
              <a
                v-if="
                  user_permissions &&
                  user_permissions.some((t) => t.name === 'administrator' || t.name === 'packages')
                "
                href="/packages"
                class="dropdown-item text-dark"
              >
                <i class="bx bx-briefcase font-size-16 align-middle me-1"></i>
                {{ $t('navbar.dropdown.quick_menu.packages') }}
              </a>
              <a
                v-if="
                  user_permissions &&
                  user_permissions.some((t) => t.name === 'administrator' || t.name === 'packages')
                "
                href="/packages/sms"
                class="dropdown-item text-dark"
              >
                <i class="bx bx-message-rounded-dots font-size-16 align-middle me-1"></i>
                {{ $t('navbar.dropdown.quick_menu.sms_packages') }}
              </a>
              <a href="javascript:void(0)" class="dropdown-item text-danger" @click="logoutUser">
                <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                {{ $t('navbar.dropdown.profile.logout') }}
              </a>
            </b-dropdown>
          </div>
        </div>
      </div>
    </Guide>
  </header>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import i18n from '../i18n'
import simplebar from 'simplebar-vue'
import {
  GET_UNREAD_ACTIVITIES,
  SUBSCRIBE_TO_ACTIVITIES,
  GET_TEAM,
  GET_PERMISSION,
  MARK_ACTIVITY_AS_READ,
} from '@/router/queries'

/**
 * Nav-bar Component
 */
export default {
  name: 'Header',
  components: {
    simplebar,
  },
  data() {
    return {
      languages: [
        {
          flag: require('@/assets/images/flags/tr.png'),
          language: 'tr',
          title: 'Türkçe',
        },
        {
          flag: require('@/assets/images/flags/us.jpg'),
          language: 'en',
          title: 'English',
        },
        /*
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "Spanish",
        },
        {
          flag: require("@/assets/images/flags/chaina.png"),
          language: "zh",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic",
        }, */
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      activities: [],
      addEventModal: false,
      guide: false,
      staff_locations: undefined,
      notificationsDropdown: false,
      moment,
      guideConfig: {
        tooltipRadius: '15px',
        nextButtonText: this.$t('guide.forward'),
        prevButtonText: this.$t('guide.back'),
        initialStep: 0,
        backdropClickToClose: true,
        skipButtonText: this.$t('guide.close'),
        tooltipAlign: 'center',
        steps: [
          {
            selector: '.step1',
            title: this.$t('guide.help_videos'),
            message: this.$t('guide.help_videos_msg'),
          },
          {
            selector: '.step2',
            title: this.$t('guide.do_not_be_unaware'),
            message: this.$t('guide.do_not_be_unaware_msg'),
          },
          {
            selector: '.step4',
            title: this.$t('guide.full_screen'),
            message: this.$t('guide.full_screen_msg'),
          },
          {
            selector: '.step5',
            title: this.$t('guide.profile'),
            message: this.$t('guide.profile_msg'),
          },
        ],
      },
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  watch: {
    team(newVal) {
      this.$store.commit('currency/setCompanyCurrency', newVal.currency)
      this.$store.commit('location/setTimezone', newVal.timezone)
      moment.tz.setDefault(newVal.timezone)
    },
    user_permissions() {
      this.staff_locations = JSON.parse(localStorage.getItem('user')).location_staffs
    },
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale)
    this.text = this.value.title
    this.flag = this.value.flag
    moment.locale(i18n.locale)
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu()
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar()
    },
    initFullScreen() {
      document.body.classList.toggle('fullscreen-enable')
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale
      this.text = country
      this.flag = flag
      i18n.locale = locale
      moment.locale(locale)
    },
    logoutUser() {
      window.location.href = '/'
      localStorage.removeItem('user')
      localStorage.removeItem('_user_at')
    },
    setRedirectLink() {
      return `https://${
        window.location.hostname.includes('dev') ? 'dev-' : ''
      }booking.barespace.app/${this.team?.slug}`
    },
    setNotificationDate(date) {
      const format = 'YYYY-MM-DDTHH:mm:ssz'
      return moment(date, format).format('D MMM HH:mm')
    },
    setActivityTitle(activity) {
      return this.$t(`activity.${activity.activity_types.code}`)
    },
    setActivityDetails(activity) {
      if (!activity.events) return ''
      return ` <br>
      ${activity.events.location.name}`
    },
    setEventTime(event) {
      const format = 'YYYY-MM-DDTHH:mm:ssz'
      return (
        moment(event.start, format).format('HH:mm') +
        '-' +
        moment(event.end, format).format('HH:mm') +
        ' / ' +
        moment(event.start, format).format('dddd')
      )
    },
    setEventDate(event) {
      const format = 'YYYY-MM-DDTHH:mm:ssz'
      return moment(event.start, format).format('DD MMMM YYYY')
    },
    showNotificationsDropdown(buttonClicked) {
      if (this.notificationsDropdown) {
        this.markAsRead()
      }
      if (buttonClicked === 'button') this.notificationsDropdown = !this.notificationsDropdown
      else if (this.notificationsDropdown) this.notificationsDropdown = false
    },
    markAsRead() {
      const readItems = []
      for (let i = 0; i < this.activities.length; i++) {
        const element = this.activities[i]
        readItems.push({
          activity_id: element.id,
          user_id: this.auth.user.id,
        })
      }
      this.$apollo.mutate({
        mutation: MARK_ACTIVITY_AS_READ,
        variables: {
          data: [...readItems],
        },
      })
    },
  },
  apollo: {
    $subscribe: {
      activities: {
        query: SUBSCRIBE_TO_ACTIVITIES,
        variables() {
          return {
            created_at: new Date(),
            where: {
              team_id: {
                _eq: this.auth.user.team_id,
              },
              location_id: {
                _in: this.staff_locations,
              },
              _not: {
                readers: {
                  user_id: { _eq: this.auth.user.id },
                },
              },
            },
          }
        },
        result({ data }) {
          this.activities.unshift(...data.activities_stream)
        },
      },
    },
    activities: {
      query: GET_UNREAD_ACTIVITIES,
      variables() {
        return {
          where: {
            team_id: {
              _eq: this.auth.user.team_id,
            },
            location_id: {
              _in: this.staff_locations,
            },
            _not: {
              readers: {
                user_id: { _eq: this.auth.user.id },
              },
            },
          },
        }
      },
      result({ data }) {
        this.activities = data.activities
      },
    },
    team: {
      fetchPolicy: 'no-cache',
      query: GET_TEAM,
      variables() {
        return {
          id: this.auth.user.team_id,
        }
      },
    },
    user_permissions: {
      fetchPolicy: 'no-cache',
      query: GET_PERMISSION,
      variables() {
        return {
          where: {
            user_id: {
              _eq: this.auth.user.id,
            },
          },
        }
      },
    },
  },
}
</script>

<style>
.turquoise-icon {
  color: #0ee3bd !important;
}
.custom-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  height: 100%;
  position: relative;
  cursor: pointer;
}
.custom-dropdown-container {
  background: white;
  top: 70px;
  right: 0;
  position: absolute;
  -webkit-box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
  width: 310px;
}
</style>
