<template>
  <div v-if="charge_invoices && charge_invoices_aggregate">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <b-form inline class="row gy-2 gx-3 align-items-center">
          <div class="col-sm-auto pt-1">
            <b-form-select v-model="per_page" class="form-select">
              <b-form-select-option :value="null">{{ $t('lists.per_page') }}</b-form-select-option>
              <b-form-select-option :value="1">1</b-form-select-option>
              <b-form-select-option :value="10">10</b-form-select-option>
              <b-form-select-option :value="25">25</b-form-select-option>
              <b-form-select-option :value="50">50</b-form-select-option>
              <b-form-select-option :value="100">100</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-auto">
            <div class="dataTables_filter text-end">
              <b-input
                v-model="filter_b"
                type="search"
                :placeholder="$t('fields.search')"
              ></b-input>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <div class="col-md-12 col-xs-12">
          <div class="card p-2">
            <div v-if="charge_invoices" class="table-responsive mb-0">
              <b-table
                :items="charge_invoices"
                :fields="fields"
                responsive="sm"
                hover="hover"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter_b"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(created_at)="data">
                  {{ moment(data.value).format('Do MMMM YYYY HH:ss') }}
                </template>
                <template #cell(status)="data">
                  {{
                    (data.value = 'paid'
                      ? (data.value = $t('bundle.paid'))
                      : (data.value = $t('bundle.waiting')))
                  }}
                </template>
                <template #cell(period_start)="data">
                  {{ moment.unix(data.value).format('Do MMMM YYYY HH:ss') }}
                </template>
                <template #cell(invoice_url)="data">
                  <a target="_blank" :href="data.value"
                    ><i class="bx bx-show font-size-20 align-middle mr-2"></i
                  ></a>
                </template>
              </b-table>
              <b-alert
                :show="charge_invoices_aggregate.aggregate.count === 0"
                class="mt-3 mb-3 text-center"
                variant="warning"
              >
                <p class="mb-0"></p>
                {{ $t('case.no_invoice') }}
              </b-alert>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="page"
                      :total-rows="charge_invoices_aggregate.aggregate.count"
                      :per-page="per_page"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import moment from 'moment'
import { GET_INVOICES, GET_INVOICES_AGGREGATE } from '@/router/queries'
export default {
  page: {
    title: 'my_invoices',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      title: this.$t('case.my_bills'),
      items: [
        {
          text: this.$t('case.bills'),
          href: '/',
        },
        {
          text: this.$t('case.pay_list'),
          active: true,
        },
      ],
      moment,
      per_page: 10,
      sort_by: { created_at: 'desc' },
      page: 1,
      filterOn: [],
      sortBy: 'value',
      sortDesc: false,
      filter_b: null,
      totalRows: 1,
      fields: [
        {
          key: 'created_at',
          label: this.$t('tables.created_at'),
          sortable: true,
        },
        {
          key: 'total',
          label: this.$t('tables.amount'),
          sortable: true,
          formatter: (total) =>
            Number(total / 100).toLocaleString('tr-TR', {
              style: 'currency',
              currency: 'TRY',
            }),
        },
        {
          key: 'status',
          label: this.$t('tables.status'),
          sortable: true,
        },
        {
          key: 'invoice_url',
          label: this.$t('case.invoice'),
          sortable: true,
          class: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapState(['auth']),
    offset() {
      return (this.page - 1) * this.per_page
    },
    rows() {
      return this.charge_invoices_aggregate.aggregate.count
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  apollo: {
    charge_invoices: {
      fetchPolicy: 'no-cache',
      query: GET_INVOICES,
      variables() {
        return {
          limit: this.per_page,
          offset: this.offset,
          order_by: this.sort_by,
          where: {
            customer_email: { _eq: this.auth.user.email },
          },
        }
      },
    },
    charge_invoices_aggregate: {
      fetchPolicy: 'no-cache',
      query: GET_INVOICES_AGGREGATE,
      variables() {
        return {
          where: {
            customer_email: { _eq: this.auth.user.email },
          },
        }
      },
    },
  },
}
</script>
